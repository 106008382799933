import React, { useEffect } from "react";
import { setTitle } from "../../../../utils/dom";
import { useFinancialHistoryProps } from "./hooks";
import Table from "../../../common/table";
import IsTruthy from "../../../common/logic/isTruthy";
import IsFalsy from "../../../common/logic/isFalsy";
import LoadingSection, {
  errorTypes,
  loadingTypes,
} from "../../../common/loading";
import FourHundredFourError from "../../../common/errors/404";
import FiveHundredOneError from "../../../common/errors/501";
import { tealiumPageViewEvent } from "../../../../utils/tealium";
import BackToPolicy from "../../../common/backToPolicy";

const FinancialHistory = () => {
  setTitle("ServiceNet - Financial History");

  const {
    requests: {
      agreementRequest,
      financialHistoryRequest,
      billingRequest,
      additionalFinancialHistoryRequest,
    },
    insuredName,
    agreementKey,
    policyId,
    asOfDate,
    table: { columns, rows },
    defaultSortField,
    isUnauthorized,
    email,
    validRequest,
    showFinancialhistoryPage,
    isVariableAnnuityContract,
    policyOrContract,
    nameLabel,
    jointOwnerNameLabel,
    jointOwnerName,
    displayJointOwner,
    isPolicyLoaded,
    isPolicyNotFound,
    hideEdsDiAsOfDate,
  } = useFinancialHistoryProps();

  useEffect(() => {
    tealiumPageViewEvent({
      page: "Financial History",
      category: agreementKey,
      custId: email,
    });
  }, []);

  if (isUnauthorized) {
    return <FiveHundredOneError />;
  }

  if (isPolicyNotFound) {
    return <FourHundredFourError />;
  }

  return (
    <LoadingSection
      request={agreementRequest}
      loadingType={loadingTypes.PARAGRAPH}
      errorType={errorTypes._500}
    >
      <LoadingSection
        request={billingRequest}
        loadingType={loadingTypes.PARAGRAPH}
        errorType={errorTypes._500}
      >
        <IsFalsy value={showFinancialhistoryPage}>
          <FourHundredFourError />
        </IsFalsy>
        <IsTruthy value={isPolicyLoaded && !validRequest}>
          <FourHundredFourError />
        </IsTruthy>
        <IsTruthy value={showFinancialhistoryPage && validRequest}>
          <LoadingSection
            request={financialHistoryRequest}
            loadingType={loadingTypes.PARAGRAPH}
            errorType={errorTypes._500}
          >
            <LoadingSection
              request={additionalFinancialHistoryRequest}
              loadingType={loadingTypes.PARAGRAPH}
              errorType={errorTypes._500}
            >
              <BackToPolicy
                agreementData={agreementRequest.data}
                jumpLink="financialHistory"
              />
              <h1 id="financial_history_header_lbl"> Financial History </h1>
              <IsFalsy value={hideEdsDiAsOfDate}>
                <p id="as_of_date_value" className="mm-sub-copy">
                  As of {asOfDate}
                </p>
              </IsFalsy>
              <div className="row mm-spacing ">
                <div className="col-md-6 col-sm-12">
                  <dl className="mm-key-value-pair--summation">
                    <dt id="insured_name_lbl">{nameLabel}</dt>
                    <dd id="insured_name_value">{insuredName}</dd>
                    <IsTruthy value={displayJointOwner}>
                      <dt id="insured_name_lbl">{jointOwnerNameLabel}</dt>
                      <dd id="insured_name_value">{jointOwnerName}</dd>
                    </IsTruthy>
                    <dt id="policy_id_lbl">{policyOrContract} Number</dt>
                    <dd id="policy_id_value">{policyId}</dd>
                  </dl>
                </div>
              </div>
              <IsTruthy value={isVariableAnnuityContract}>
                <div className="mm-spacing--minor">
                  <div className="alert alert-info mm-spacing" role="alert">
                    Financial History is only available online for this contract
                    back to December 31st of 2013. For financial history beyond
                    this date please contact the Service Center at{" "}
                    <a href="tel:+1-800-272-2216">1-800-272-2216</a>, Mon - Fri
                    8 a.m - 8 p.m ET.
                  </div>
                </div>
              </IsTruthy>
              <div className="row">
                <div className="col-sm">
                  <Table
                    columns={columns}
                    rows={rows}
                    defaultSortColumn={defaultSortField}
                    id="financial_history"
                  />
                </div>
              </div>
            </LoadingSection>
          </LoadingSection>
        </IsTruthy>
      </LoadingSection>
    </LoadingSection>
  );
};
export default React.memo(FinancialHistory);
