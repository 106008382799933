import {
  formatDate,
  getDayFromDate,
  getMonthFromDate,
  getCurrentDate,
  splitDate,
  isBeforeDate,
} from "../../../../utils/dates";
import {
  formatMoney,
  convertToTitleCase,
  formatBaseCoverageStatusCode,
  sortInsuredPeople,
  formatContactUsNumber,
  formatContactUsDateTime,
} from "../../../../utils/format";
import RiderModalCell from "./riderModalCell";
import { useModalState } from "../../../../contexts/modal";
import RiderModal from "./riderModal";
import {
  findDIRiderDescription,
  findRiderDescription,
} from "./findRiderDescription";
import { getUsers, userTypes } from "../../../../utils/findData";
import { hasKey } from "../../../../utils/object";
import {
  compareArrayOfStrings,
  compareStrings,
} from "../../../../utils/string";
import { getTableRatingFromCustomers } from "../../types/life/hooks";
import { DATA_UNAVAILABLE } from "../../../../constants/ui";
import { isDefined } from "../../../../utils/evaluate";
import {
  isCoverpathPolicy,
  isDisabilityPolicy,
} from "../../../../utils/policies";
import { contactUSDetails } from "../../../../data/contactUsDetails";
import { checkHideLisrAlirBilledPremiumValues } from "../../../../utils/coverages";
import { useLDValue } from "../../../common/featureFlags/hooks";

export const ABRandLBRdata = (props) => {
  const {
    agreementCoverages,
    agreementStatus,
    agreementKeyAdmin,
    majorProductCode,
  } = props;
  const baseCoverageToUse = agreementCoverages
    .filter((coverage) => {
      const { type, category } = coverage;
      return compareStrings(category, "B") && compareStrings(type, "BASE");
    })
    .sort((a, b) => new Date(a.effectiveDate) - new Date(b.effectiveDate));
  const MPR_AND_TERM =
    compareStrings(agreementKeyAdmin, "MPR") &&
    compareStrings(majorProductCode, "TERM");

  const termDateConsideringPolicy = MPR_AND_TERM
    ? formatDate(baseCoverageToUse[0]?.termDate, undefined, "Not Applicable")
    : formatDate(baseCoverageToUse[0]?.termDate, undefined, "-");

  return {
    effectiveDate: formatDate(
      baseCoverageToUse[0]?.effectiveDate,
      undefined,
      "-"
    ),
    status: formatBaseCoverageStatusCode(agreementStatus),
    termDate: termDateConsideringPolicy,
  };
};

export const tableRows = (
  agreementCoverages,
  agreementKeyAdmin,
  agreementStatus,
  agreementRequest
) => {
  const getData = (item, index) => {
    const {
      marketingName = "",
      effectiveDate = "",
      termDate = "",
      faceAmount = "",
      category = "",
      productTypeName = "",
      shortName = "",
      majorProductCode = "",
      type = "",
    } = item;

    const { lineOfBusinessCode, issueDate } = agreementRequest.data;
    let formattedFaceAmount = "Not Applicable";
    if (hasKey({ obj: item, key: "faceAmount" })) {
      formattedFaceAmount =
        formatMoney(faceAmount) === ""
          ? "Not Available"
          : formatMoney(faceAmount);
    }

    const isDisabilityPolcyResult = isDisabilityPolicy({ lineOfBusinessCode });

    const NO_MARKET_NAME_KEY =
      compareStrings(agreementKeyAdmin, "MPR") && compareStrings(category, "B");
    const HAP_POLICY = isCoverpathPolicy(agreementKeyAdmin);
    const MPR_AND_TERM =
      compareStrings(agreementKeyAdmin, "MPR") &&
      compareStrings(majorProductCode, "TERM");
    const productName =
      NO_MARKET_NAME_KEY || HAP_POLICY ? productTypeName : marketingName;

    const isTitleCasingRequired = !HAP_POLICY || !compareStrings(category, "B");
    const formattedProductName = isTitleCasingRequired
      ? convertToTitleCase(productName)
      : productName;

    const statusReason = item.statusReason ? item.statusReason : "-";
    const categoryBstatus = compareStrings(category, "B")
      ? formatBaseCoverageStatusCode(agreementStatus)
      : statusReason;
    const categoryRstatus =
      compareStrings(category, "R") && !compareStrings(agreementStatus, "TM")
        ? statusReason
        : "Terminated";
    const termDateConsideringPolicy = MPR_AND_TERM
      ? formatDate(termDate, undefined, "Not Applicable")
      : formatDate(termDate, undefined, "-");

    if (
      (compareStrings(type, "ABR") &&
        compareStrings(agreementKeyAdmin, "OPM")) ||
      (compareStrings(type, "LBR") && compareStrings(agreementKeyAdmin, "MPR"))
    ) {
      const {
        effectiveDate: baseEffectiveDate,
        termDate: baseTermDate,
        status: baseStatus,
      } = ABRandLBRdata({
        agreementCoverages,
        agreementStatus,
        agreementKeyAdmin,
        majorProductCode,
      });

      return {
        ...item,
        id: index,
        marketingName: formattedProductName,
        status: baseStatus,
        effectiveDate: baseEffectiveDate,
        termDate: baseTermDate,
        faceAmount: formattedFaceAmount,
        description: findRiderDescription(shortName, agreementKeyAdmin),
        category,
      };
    }
    if (
      compareStrings(category, "R") &&
      compareStrings(type, "WP") &&
      compareStrings(agreementKeyAdmin, "OPM")
    ) {
      const {
        effectiveDate: baseEffectiveDate,
        termDate: baseTermDate,
        status: baseStatus,
      } = getWPRData({ agreementCoverages, agreementRequest });
      return {
        ...item,
        id: index,
        marketingName: formattedProductName,
        status: baseStatus,
        effectiveDate: baseEffectiveDate,
        termDate: baseTermDate,
        faceAmount: formattedFaceAmount,
        description: findRiderDescription(shortName, agreementKeyAdmin),
        category,
      };
    }
    if (isDisabilityPolcyResult) {
      const {
        incomeAmount,
        benefitPeriodSickInjury,
        eliminationPeriodSickDisability,
      } = getDisabilityPolicyData(item);
      return {
        ...item,
        id: index,
        marketingName: compareStrings(agreementKeyAdmin, "EDS-DI")
          ? marketingName
          : convertToTitleCase(marketingName),
        status: formatBaseCoverageStatusCode(agreementStatus),
        effectiveDate: formatDate(effectiveDate, undefined, "-"),
        termDate: termDateConsideringPolicy,
        incomeAmount,
        benefitPeriodSickInjury,
        eliminationPeriodSickDisability,
        description: compareStrings(category, "R")
          ? findDIRiderDescription(type, agreementKeyAdmin)
          : "",
      };
    }

    if (
      HAP_POLICY &&
      compareStrings(category, "R") &&
      (compareStrings(type, "WP") || compareStrings(type, "AC"))
    ) {
      const currentDate = formatDate(getCurrentDate());
      const { status } = getHapPolicyData(item, issueDate, currentDate);

      return {
        ...item,
        id: index,
        marketingName: formattedProductName,
        status: isDefined(issueDate) && isDefined(termDate) ? status : "-",
        effectiveDate: formatDate(effectiveDate, undefined, "-"),
        termDate: termDateConsideringPolicy,
        faceAmount: formattedFaceAmount,
        description: findRiderDescription(shortName, agreementKeyAdmin),
        category,
      };
    }
    if (
      HAP_POLICY &&
      compareArrayOfStrings(["R", "B"], category) &&
      compareStrings(majorProductCode, "PERM")
    ) {
      return {
        ...item,
        id: index,
        marketingName,
        status: compareStrings(category, "B")
          ? categoryBstatus
          : categoryRstatus,
        effectiveDate: formatDate(effectiveDate, undefined, "-"),
        termDate: termDateConsideringPolicy,
        faceAmount: formattedFaceAmount,
        description: findRiderDescription(shortName, agreementKeyAdmin),
        category,
      };
    }
    return {
      ...item,
      id: index,
      marketingName: formattedProductName,
      status: compareStrings(category, "B") ? categoryBstatus : categoryRstatus,
      effectiveDate: formatDate(effectiveDate, undefined, "-"),
      termDate: termDateConsideringPolicy,
      faceAmount: formattedFaceAmount,
      description: findRiderDescription(shortName, agreementKeyAdmin),
      category,
    };
  };
  if (agreementCoverages) {
    return agreementCoverages.map(getData);
  }
  return [];
};

export const getWPRData = (props) => {
  let wprStatus;
  const { agreementCoverages, agreementRequest } = props;
  const { issueDate } = agreementRequest.data;
  const wprCoverage = agreementCoverages
    .filter((coverage) => {
      const { type, category } = coverage;
      return compareStrings(category, "R") && compareStrings(type, "WP");
    })
    .sort((a, b) => new Date(a.effectiveDate) - new Date(b.effectiveDate));
  const issueDateMonth = getMonthFromDate(issueDate);
  const issueDateDay = getDayFromDate(issueDate);
  const formattedSourceCoverageTermDate = new Date(
    wprCoverage[0]?.sourceCoverageTermDate,
    issueDateMonth,
    issueDateDay
  );
  const isExpiredSourceCoverageTermDate =
    formattedSourceCoverageTermDate < new Date();
  if (
    isDefined(wprCoverage[0]?.sourceCoverageTermDate) &&
    isDefined(issueDate)
  ) {
    wprStatus = isExpiredSourceCoverageTermDate ? "Terminated" : "Active";
  } else {
    wprStatus = "-";
  }
  return {
    effectiveDate: isDefined(wprCoverage[0]?.sourceEffDateText)
      ? wprCoverage[0]?.sourceEffDateText
      : "-",
    status: wprStatus,
    termDate: isDefined(wprCoverage[0]?.sourceCoverageTermDate)
      ? wprCoverage[0]?.sourceCoverageTermDate
      : "-",
  };
};

export const getDisabilityPolicyData = (props, isRiderModalPopCall = false) => {
  const {
    incomeAmount = "",
    benefitPeriodSick = "",
    benefitPeriodInjury = "",
    eliminationPeriodSick = "",
    disabilityWaitPeriod = "",
    type = "",
    nextExerciseDateFIO = "",
    faceAmount,
  } = props;
  let benefitPeriodSickInjury = "";
  let formattedBenefitPeriodInjury = "";
  let waitingPeriodInjury = "";
  let isBenefitPeriodSickandInjurySame = null;
  let isDisabilityWaitPeriodSickandInjurySame = null;
  let eliminationPeriodSickDisability = "";

  if (compareStrings(benefitPeriodSick, benefitPeriodInjury)) {
    benefitPeriodSickInjury = formatSickDisabilityBenefit(benefitPeriodSick);
    isBenefitPeriodSickandInjurySame = true;
  } else {
    benefitPeriodSickInjury = `${formatSickDisabilityBenefit(
      benefitPeriodSick
    )} / ${formatSickDisabilityBenefit(benefitPeriodInjury)}`;
    if (isRiderModalPopCall) {
      isBenefitPeriodSickandInjurySame = false;
      benefitPeriodSickInjury = formatSickDisabilityBenefit(benefitPeriodSick);
      formattedBenefitPeriodInjury =
        formatSickDisabilityBenefit(benefitPeriodInjury);
    }
  }

  if (compareStrings(eliminationPeriodSick, disabilityWaitPeriod)) {
    eliminationPeriodSickDisability =
      formatSickDisabilityBenefit(disabilityWaitPeriod);
    isDisabilityWaitPeriodSickandInjurySame = true;
  } else {
    eliminationPeriodSickDisability = `${formatSickDisabilityBenefit(
      eliminationPeriodSick
    )} / ${formatSickDisabilityBenefit(disabilityWaitPeriod)}`;
    if (isRiderModalPopCall) {
      isDisabilityWaitPeriodSickandInjurySame = false;
      eliminationPeriodSickDisability = formatSickDisabilityBenefit(
        eliminationPeriodSick
      );
      waitingPeriodInjury = formatSickDisabilityBenefit(disabilityWaitPeriod);
    }
  }

  const hasFIORider = compareStrings(type, "FIO");

  return {
    incomeAmount: isDefined(incomeAmount)
      ? formatMoney(incomeAmount, "-")
      : "-",
    benefitPeriodSickInjury,
    eliminationPeriodSickDisability,
    formattedBenefitPeriodInjury,
    waitingPeriodInjury,
    isBenefitPeriodSickandInjurySame,
    isDisabilityWaitPeriodSickandInjurySame,
    nextExerciseDateFIO: formatDate(nextExerciseDateFIO, "", "-"),
    faceAmountFIO: formatMoney(faceAmount, "-"),
    hasFIORider,
  };
};

const formatSickDisabilityBenefit = (periodSick) => {
  let numberValue = null;
  let stringValue = null;
  let formattedString = null;
  if (isDefined(periodSick)) {
    numberValue = periodSick.match(/\d+/g);
    stringValue = periodSick.match(/[a-zA-Z]+/g);
    switch (stringValue[0]?.toUpperCase()) {
      case "Y":
        formattedString = `${numberValue} Years`;
        break;
      case "M":
        formattedString = `${numberValue} Months`;
        break;
      case "D":
        formattedString = `${numberValue} Days Elimination`;
        break;
      case "AGE":
        formattedString = `Coverage to Age ${numberValue}`;
        break;
      case "UNK":
        formattedString = "Unknown";
        break;
      case "LUMP":
        formattedString = "Lump Sum";
        break;
      case "LT":
        formattedString = "Lifetime";
        break;
      default:
        formattedString = "Not Applicable";
    }
  } else {
    formattedString = "Not Applicable";
  }
  return formattedString;
};

export const getHapPolicyData = (item, issueDate, currentDate) => {
  const { termDate } = item;

  if (!isDefined(termDate)) {
    return { status: "" };
  }

  const issueDateMonth = splitDate(issueDate)[1];
  const issueDateDay = splitDate(issueDate)[2];
  const termDateYear = splitDate(termDate)[0];
  const expirationDate = `${issueDateMonth}/${issueDateDay}/${termDateYear}`;

  let status;
  if (
    isBeforeDate(currentDate, expirationDate) ||
    currentDate === expirationDate
  ) {
    status = "Active";
  } else {
    status = "Terminated";
  }
  return { status };
};

export const useRiderModal = ({
  agreementData,
  hideLisrBilledPremiumValues,
  hideLisrAlirBilledPremiumValues,
}) => {
  const { openModal } = useModalState();
  const showModal = (rider) => () => {
    const {
      ltcCurrentMaxMonthlyBenefitAmount,
      ltcResidualDeathBenefitAmount,
      ltcBenefitPoolAmount,
      ltcDividendBenefitPoolAmount,
      ltcMaxMonthlyBenefitIncOptIndicator,
      ltcAdvanceBasePaidUpAddsIndicator,
      inflationProtectionOption,
      agreementKeyAdmin,
      agreementCustomers,
      lineOfBusinessCode,
    } = agreementData;

    const hideLisrValues =
      hideLisrBilledPremiumValues && compareStrings(rider.type, "LISR");

    const hideAlirValues =
      hideLisrAlirBilledPremiumValues && compareStrings(rider.type, "ALIR");

    const roleType = userTypes.INSURED;
    const insuredPeople = sortInsuredPeople(
      getUsers(agreementCustomers, roleType)
    );
    const permanentTableRatingCode1 = getTableRatingFromCustomers({
      agreementCustomers: agreementData.agreementCustomers,
    });

    const isDisabilityPolcyResult = isDisabilityPolicy({ lineOfBusinessCode });
    const isHAPPolicy = isCoverpathPolicy(agreementKeyAdmin);

    const {
      benefitPeriodSickInjury,
      eliminationPeriodSickDisability,
      formattedBenefitPeriodInjury,
      waitingPeriodInjury,
      isBenefitPeriodSickandInjurySame,
      isDisabilityWaitPeriodSickandInjurySame,
      nextExerciseDateFIO,
      faceAmountFIO,
      hasFIORider,
    } = getDisabilityPolicyData(rider, true);

    const modalProps = {
      Component: RiderModal,
      componentProps: {
        ...rider,
        insuredPeople,
        faceAmount: rider.faceAmount,
        foundWaiverOfPremiumAmount: hasKey({
          obj: rider,
          key: "waiverOfPremiumAmount",
        }),
        effectiveDate: rider.effectiveDate,
        termDate: rider.termDate,
        coveragePremiumAmount:
          hideLisrValues || hideAlirValues
            ? "Unavailable"
            : formatMoney(rider.coveragePremiumAmount, DATA_UNAVAILABLE),
        waiverOfPremiumAmount:
          hideLisrValues || hideAlirValues
            ? "Unavailable"
            : formatMoney(rider.waiverOfPremiumAmount),
        crossoverOptionDate: formatDate(rider.crossoverOptionDate),
        ltcCurrentMaxMonthlyBenefitAmount: formatMoney(
          ltcCurrentMaxMonthlyBenefitAmount
        ),
        ltcResidualDeathBenefitAmount: formatMoney(
          ltcResidualDeathBenefitAmount
        ),
        ltcBenefitPoolAmount: formatMoney(ltcBenefitPoolAmount),
        ltcDividendBenefitPoolAmount: formatMoney(ltcDividendBenefitPoolAmount),
        ltcMaxMonthlyBenefitIncOptIndicator,
        ltcAdvanceBasePaidUpAddsIndicator,
        inflationProtectionOption,
        agreementKeyAdmin,
        occupationalRatingClass: rider.occupationalRatingClass,
        permanentTableRatingCode1,
        isHAPPolicy,
        isDisabilityPolcyResult,
        occupationCode: isDefined(rider.occupationalRatingClass)
          ? rider.occupationalRatingClass
          : "None",
        incomeAmount: isDefined(rider.incomeAmount)
          ? formatMoney(rider.incomeAmount, "-")
          : "-",
        annualPremiumAmount: isDefined(rider.coveragePremiumAmount)
          ? formatMoney(rider.coveragePremiumAmount, "-")
          : "-",
        benefitPeriodSickInjury,
        eliminationPeriodSickDisability,
        formattedBenefitPeriodInjury,
        waitingPeriodInjury,
        isBenefitPeriodSickandInjurySame,
        isDisabilityWaitPeriodSickandInjurySame,
        nextExerciseDateFIO,
        faceAmountFIO,
        hasFIORider,
      },
      title: rider.marketingName,
    };
    openModal(modalProps);
  };

  return { showModal };
};

export const useCoverageAndRiderTable = (props) => {
  const { agreementRequest, billingData } = props;
  const { agreementCoverages, agreementKeyAdmin, lineOfBusinessCode } =
    agreementRequest.data;

  const lisrValueHidingEnabled = useLDValue({
    flagName: ["lisrPremiumValuesHiding"],
  });

  const { hideLisrBilledPremiumValues, hideLisrAlirBilledPremiumValues } =
    checkHideLisrAlirBilledPremiumValues({
      paidToDate: billingData?.paidToDate,
      agreementData: agreementRequest.data,
      lisrValueHidingEnabled,
    });

  const formattedContactUsNumber = formatContactUsNumber(
    props?.agreementRequest?.data?.contactUsNumber,
    contactUSDetails.DEFAULT.number
  );
  const formattedContactUsTime = formatContactUsDateTime(
    props?.agreementRequest?.data?.contactUsTime,
    contactUSDetails.DEFAULT.time
  );
  const { showModal: showRiderModal } = useRiderModal({
    agreementData: agreementRequest.data,
    hideLisrBilledPremiumValues,
    hideLisrAlirBilledPremiumValues,
  });

  const rows = tableRows(
    agreementCoverages,
    agreementKeyAdmin,
    agreementRequest.data.status,
    agreementRequest
  );

  const ridersSortedByEffectiveDate = rows.sort(
    (a, b) => new Date(b.effectiveDate) - new Date(a.effectiveDate)
  );

  const isDisabilityPolcyResult = isDisabilityPolicy({ lineOfBusinessCode });
  const coverageandRiderColumns = getCoverageRiderColumns(
    isDisabilityPolcyResult
  );
  const displayPolicyNoRidersMesssage = rows.length < 1;
  const table = {
    columns: coverageandRiderColumns,
    rows: ridersSortedByEffectiveDate,
  };

  return {
    table,
    functions: { showRiderModal },
    displayPolicyNoRidersMesssage,
    formattedContactUsNumber,
    formattedContactUsTime,
  };
};

const getCoverageRiderColumns = (isDisabilityPolcyResult) => {
  let coverageandRiderColumns;
  const commonColumns = [
    {
      accessor: "marketingName",
      label: "Name",
      alignment: "left",
      CustomCell: RiderModalCell,
      sectionAbbreviation: "cr",
    },
    {
      accessor: "status",
      label: "Status",
      alignment: "left",
      sectionAbbreviation: "cr",
    },
    {
      accessor: "effectiveDate",
      label: "Effective Date",
      alignment: "left",
      sectionAbbreviation: "cr",
    },
    {
      accessor: "termDate",
      label: "Expiration Date",
      alignment: "left",
      sectionAbbreviation: "cr",
    },
  ];

  if (isDisabilityPolcyResult) {
    coverageandRiderColumns = [
      ...commonColumns,
      {
        accessor: "incomeAmount",
        label: "Monthly Benefit",
        alignment: "left",
        sectionAbbreviation: "cr",
      },
      {
        accessor: "benefitPeriodSickInjury",
        label: "Benefit Period Sick/Injury",
        alignment: "left",
        sectionAbbreviation: "cr",
      },
      {
        accessor: "eliminationPeriodSickDisability",
        label: "Waiting Period Sick/Injury",
        alignment: "left",
        sectionAbbreviation: "cr",
      },
    ];
  } else {
    coverageandRiderColumns = [
      ...commonColumns,
      {
        accessor: "faceAmount",
        label: "Face Amount",
        alignment: "left",
        sectionAbbreviation: "cr",
      },
    ];
  }
  return coverageandRiderColumns;
};
