import React from "react";
import { Tabs } from "react-blueprint-library";
import LoadingSection, {
  errorTypes,
  loadingTypes,
} from "../../../common/loading";
import Table from "../../../common/table";
import { useInvestmentsProps } from "./hooks";
import IsFalsy from "../../../common/logic/isFalsy";
import IsTruthy from "../../../common/logic/isTruthy";
import { NO_INVESTMENT_INFO_ALERT_TEXT } from "../../../../constants/ui";
import { isDefined } from "../../../../utils/evaluate";
import FundTransferLink from "../../../home/fundTransferLink";

const Investments = (props) => {
  const {
    id,
    showRecalcValues,
    producersRequest,
    fundTransferEligibilityRequest,
    user,
  } = props;
  const {
    requests: { agreementRequest },
    table: { columns, rows },
    contactUsInfo,
    isNonEnvisionAnnuityContract,
    isArtistryContract,
    employeeFundRows,
    employerFundRows,
    recalcAsOfDate,
    showFundTransferButton,
    email,
    trimmedPolicyId,
  } = useInvestmentsProps(props);

  return (
    <div className="mm-spacing">
      <h2 aria-label="Investments" id={id}>
        Investments
      </h2>
      <IsTruthy value={showRecalcValues}>
        <div>
          <p id="recalculation_as_of_date_value" className="mm-sub-copy">
            <mark>As of {recalcAsOfDate}</mark>
          </p>
        </div>
      </IsTruthy>
      <LoadingSection
        request={agreementRequest}
        loadingType={loadingTypes.PARAGRAPH}
        errorType={errorTypes._500}
      >
        <div className="mm-spacing--minor" />
        <div className="row">
          <div className="col-sm">
            <IsTruthy value={rows.length === 0 && isNonEnvisionAnnuityContract}>
              <div
                className="alert alert-info mm-spacing"
                role="alert"
                id="no_investment_info_available_alert"
              >
                {NO_INVESTMENT_INFO_ALERT_TEXT}
                <a href={`tel:+${contactUsInfo?.formattedContactUsNumber}`}>
                  {contactUsInfo?.formattedContactUsNumber}
                </a>
                , {contactUsInfo?.formattedContactUsTime}
              </div>
            </IsTruthy>
            <IsFalsy value={rows.length === 0 && isNonEnvisionAnnuityContract}>
              <IsFalsy value={isArtistryContract}>
                <Table
                  columns={columns}
                  rows={rows}
                  tableWrapperClass="sn-scroll"
                  defaultSortColumn="fundValueAmount"
                  defaultSortDirection="descending"
                  showPagination={false}
                />
              </IsFalsy>
              <IsTruthy value={isArtistryContract}>
                <IsTruthy
                  value={
                    isDefined(employeeFundRows) && isDefined(employerFundRows)
                  }
                >
                  <div className="hide-print">
                    <Tabs defaultKey="1">
                      <Tabs.Tab eventKey="1" key="1" title="Employee Funds">
                        <Table
                          columns={columns}
                          rows={employeeFundRows}
                          tableWrapperClass="sn-scroll"
                          defaultSortColumn="fundValueAmount"
                          defaultSortDirection="descending"
                          showPagination={false}
                        />
                      </Tabs.Tab>

                      <Tabs.Tab eventKey="2" key="2" title="Employer Funds">
                        <Table
                          columns={columns}
                          rows={employerFundRows}
                          tableWrapperClass="sn-scroll"
                          defaultSortColumn="fundValueAmount"
                          defaultSortDirection="descending"
                          showPagination={false}
                        />
                      </Tabs.Tab>
                    </Tabs>
                  </div>

                  <div className="print-only">
                    <p>
                      <b>Employee Funds</b>
                    </p>
                    <Table
                      columns={columns}
                      rows={employeeFundRows}
                      tableWrapperClass="sn-scroll"
                      defaultSortColumn="fundValueAmount"
                      defaultSortDirection="descending"
                      showPagination={false}
                    />
                    <p>
                      <b>Employer Funds</b>
                    </p>
                    <Table
                      columns={columns}
                      rows={employerFundRows}
                      tableWrapperClass="sn-scroll"
                      defaultSortColumn="fundValueAmount"
                      defaultSortDirection="descending"
                      showPagination={false}
                    />
                  </div>
                </IsTruthy>
                <IsTruthy
                  value={
                    isDefined(employeeFundRows) && !isDefined(employerFundRows)
                  }
                >
                  <h3>Employee Funds</h3>
                  <Table
                    columns={columns}
                    rows={employeeFundRows}
                    tableWrapperClass="sn-scroll"
                    defaultSortColumn="fundValueAmount"
                    defaultSortDirection="descending"
                    showPagination={false}
                  />
                </IsTruthy>
                <IsTruthy
                  value={
                    isDefined(employerFundRows) && !isDefined(employeeFundRows)
                  }
                >
                  <h3>Employer Funds</h3>
                  <Table
                    columns={columns}
                    rows={employerFundRows}
                    tableWrapperClass="sn-scroll"
                    defaultSortColumn="fundValueAmount"
                    defaultSortDirection="descending"
                    showPagination={false}
                  />
                </IsTruthy>
              </IsTruthy>
            </IsFalsy>
            <IsTruthy value={showFundTransferButton}>
              <div className="mm-spacing--minor" />
              <FundTransferLink
                data={agreementRequest.data}
                policyId={trimmedPolicyId}
                userEmail={email}
                tealiumCategory="Investment"
                target="_blank"
                cssClassName="btn btn-secondary"
                fundTransferEligibilityData={
                  fundTransferEligibilityRequest?.data
                }
                producersData={producersRequest?.data}
                user={user}
              />
            </IsTruthy>
          </div>
        </div>
      </LoadingSection>
    </div>
  );
};

export default React.memo(Investments);
