import React from "react";
import LabelWithHelpModal from "../../common/forms/labelWithHelpModal";

const DisabilityClaimStatusModalBody = () => (
  <div className="modal-body">
    <hr />
    <h5 id="directLink_DisabilityClaimStatus_ModalTitle">
      Disability Claim Status & Document Hub
    </h5>
    <p>
      The following can be done on the <strong>website</strong>:
    </p>
    <h5>Important:</h5>
    <ul>
      <li>Only send if there is an active claim</li>
    </ul>
    <div className="mm-spacing--minor" />
    <h5 id="directLink_DisabilityClaimStatus_ModalQuickTips">Quick Tips</h5>
    <ul>
      <li>
        Status displayed on MM.com:
        <ul>
          <li>Claim Status = Open and Closed</li>
          <li>
            Claim sub-statuses for Open cases = Appeal Received, Claim Approved,
            Direct Deposit elected for Payments, Claim Pending, Information
            Required – Awaiting Paperwork, Benefit payments made under
            Reservation of Rights, and Waiver Only
          </li>
        </ul>
        <li>
          Customers have the ability to upload documents related to their
          Disability Claim
        </li>
      </li>
    </ul>
  </div>
);

const DisabilityClaimStatusModal = () => (
  <LabelWithHelpModal
    text="Disability Claim Status & Document Hub"
    modalTitle="Disability Claim Status & Document Hub"
    modalId="disabilityClaimStatus"
    modalBodyComponent={DisabilityClaimStatusModalBody}
  />
);

export default DisabilityClaimStatusModal;
