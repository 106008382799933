import { useParams } from "react-router-dom";
import { redirectToUrl } from "../../../utils/dom";
import { isDisabilityPolicy } from "../../../utils/policies";

export const useFormAddressesProps = (props) => {
  const { agreementKey } = useParams();
  const { attention, contactFaxNumber, contactEmail } =
    getLabelTextByLineOfBusinessCode(props);
  const goBackToPolicyPage = () => goBackToPolicy(agreementKey);
  return {
    attention,
    contactFaxNumber,
    contactEmail,
    goBackToPolicyPage,
  };
};
const getLabelTextByLineOfBusinessCode = (props) => {
  const { lineOfBusinessCode } = props;

  const isDisabilityPolicyResult = isDisabilityPolicy({ lineOfBusinessCode });
  const attention = isDisabilityPolicyResult ? "DI Hub" : "Life Hub";
  const contactFaxNumber = isDisabilityPolicyResult
    ? "1-860-562-6130"
    : "1-866-329-4527";
  const contactEmail = isDisabilityPolicyResult
    ? "difax@massmutual.com"
    : "lifefax@massmutual.com";
  return {
    attention,
    contactFaxNumber,
    contactEmail,
  };
};
export const goBackToPolicy = (agreementKey) => {
  redirectToUrl(`/policyDetails/${encodeURIComponent(agreementKey)}`);
};
