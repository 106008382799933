import { compareStrings } from "../../utils/string";
import { isDefined, isObject } from "../../utils/evaluate";

export const processAnnuityData = (props) => {
  const apiData = props?.response?.data;

  const annuity = apiData?.contract || [{}];
  const {
    contractNbr = "",
    ownerName = "",
    jointOwnerName = "",
    annuitantBirthDate = "",
    productPrefix = "",
    productName = "",
    companyCode = "",
    marketType = "",
    producerName = "",
    producerRole = "",
    asofDate = "",
    issueDate = "",
    maturityDate = "",
    guarPeriodStartDate = "",
    guarPeriodEndDate = "",
    surrChargeSchedule = "",
    paymentMode = "",
    nextPayoutDate = "",
    errors = "",
    issueState = "",
    payee = {},
    lifeStatus = {},
    contractValues: { contractValue: contractValueArray } = {},
    contractFeatures = {},
    funds = {},
    billing = {},
    assetAllocModel = {},
    contractValues = {},
    accountBalanceEE = "",
    accountBalanceER = "",
    loanMaxAmt,
    loansOutstanding,
    loanMaxEffDate,
    loans,
    annuityOption = "",
    annuityOptionDescription = "",
    issuingCompany = "",
    residentState = "",
    carrierCode = "",
    surrenderChargePeriod = "",
  } = annuity;

  const totalContributions = getValue(
    contractValueArray,
    "Total Contributions"
  );
  const contractValue = getValue(contractValueArray, "Contract Value");
  const surrenderCharge = getValue(contractValueArray, "Surrender Charges");
  const freeAmount = getValue(contractValueArray, "Free Withdrawal Amount");
  const surrenderValue = getValue(contractValueArray, "Surrender Value");
  const benefitAmount = getValue(contractValueArray, "Benefit Amount");
  const currentDeathBenefit = getValue(
    contractValueArray,
    "Current Death Benefit Value"
  );
  const totalWithdrawals = getValue(contractValueArray, "Total Withdrawals");
  const contributionsEE = getValue(contractValueArray, "EE Contributions");
  const totalDistributions = getValue(
    contractValueArray,
    "Total Distributions"
  );
  const initialPurchase = getValue(contractValueArray, "Initial Purchase");

  const annuityErrorCode = isObject(errors) ? errors?.error?.[0].code : "";

  const contractFeatureArray = contractFeatures?.contractFeature || [];
  const riderInfo = contractFeatureArray?.map((rider) => {
    const {
      name: riderName,
      issueDate: riderIssueDate,
      availWithdrawAmt: riderAvailWithdrawAmt,
      benefitAmt,
      type,
    } = rider;
    return {
      riderName,
      riderIssueDate,
      type,
      benefitAmt,
      riderAvailWithdrawAmt,
    };
  });

  const assetAllocationModel = assetAllocModel?.name || "";

  return {
    contractNbr,
    ownerName,
    jointOwnerName,
    annuitantBirthDate,
    productPrefix,
    productName,
    companyCode,
    marketType,
    funds,
    producerName,
    producerRole,
    issueDate,
    maturityDate,
    asofDate,
    issueState,
    guarPeriodStartDate,
    guarPeriodEndDate,
    totalContributions,
    contractValue,
    surrChargeSchedule: surrChargeSchedule.replace(/\D+/, "").trim(),
    surrenderCharge,
    freeAmount,
    surrenderValue,
    currentDeathBenefit,
    paymentMode,
    nextPayoutDate,
    benefitAmount,
    annuityErrorCode,
    payee,
    lifeStatus,
    riderInfo,
    billing,
    totalWithdrawals,
    assetAllocationModel,
    contractValues,
    accountBalanceEE,
    accountBalanceER,
    loanMaxAmt,
    loansOutstanding,
    loanMaxEffDate,
    loans,
    contributionsEE,
    totalDistributions,
    initialPurchase,
    annuityOption,
    annuityOptionDescription,
    issuingCompany,
    residentState,
    carrierCode,
    surrenderChargePeriod,
  };
};

const getValue = (contractValueArray, elementName) => {
  const contractValue = contractValueArray?.find((element) =>
    compareStrings(element.name, elementName)
  );
  return isDefined(contractValue) ? contractValue.value : "";
};
