import React, { useEffect } from "react";
import { useLDValue } from "../common/featureFlags/hooks";
import { fireTealiumAndFollowLink } from "../../utils/tealium";
import IsTruthy from "../common/logic/isTruthy";
import IsFalsy from "../common/logic/isFalsy";
import { verifyFundTransfer } from "../fundTransfer/restrictions";

const FundTransferLink = (props) => {
  const {
    data,
    setShowFundTransferLink = () => {},
    policyId,
    userEmail,
    tealiumCategory,
    target,
    isRenderAsListItem = false,
    cssClassName = "btn btn-inline",
    fundTransferEligibilityData,
    producersData,
    user,
  } = props;

  const { agreementKey } = data;

  const transactionSupportedPolicies = useLDValue({
    flagName: ["transactionSupportedPolicies"],
  });

  const { isTransactionAllowed, isAuthorized: isAuthorizedForFundAllocation } =
    verifyFundTransfer({
      agreementData: data,
      fundTransferEligibilityData,
      producersData,
      transactionSupportedPolicies,
      user,
    });

  const isEligibleForFundTransfer =
    isTransactionAllowed && isAuthorizedForFundAllocation;

  useEffect(() => {
    setShowFundTransferLink(isEligibleForFundTransfer);
  }, [isEligibleForFundTransfer]);

  return (
    <IsTruthy value={isEligibleForFundTransfer}>
      <IsTruthy value={isRenderAsListItem}>
        <li>
          <button
            type="button"
            id="fund-transfer-link"
            className={cssClassName}
            onClick={() =>
              fireTealiumAndFollowLink({
                url: `/policyDetails/${encodeURIComponent(
                  agreementKey
                )}/fundTransfer`,
                action: "Fund transfer link clicked",
                category: tealiumCategory,
                value: policyId,
                custId: userEmail,
                target,
              })
            }
          >
            Transfer Funds
          </button>
        </li>
      </IsTruthy>
      <IsFalsy value={isRenderAsListItem}>
        <button
          className={cssClassName}
          id="investment-fund-transfer-link"
          type="button"
          onClick={() =>
            fireTealiumAndFollowLink({
              url: `/policyDetails/${encodeURIComponent(
                agreementKey
              )}/fundTransfer`,
              action: "Fund transfer link clicked",
              category: tealiumCategory,
              value: policyId,
              custId: userEmail,
            })
          }
        >
          <span className="icon-transfer" />
          <span>Transfer Funds</span>
        </button>
      </IsFalsy>
    </IsTruthy>
  );
};

export default React.memo(FundTransferLink);
