import React from "react";
import IsFalsy from "../../../../common/logic/isFalsy";
import IsTruthy from "../../../../common/logic/isTruthy";
import { useDeferredSalesChargeScheduleProps } from "./hooks";
import { isDefined } from "../../../../../utils/evaluate";

const DeferredSalesChargeSchedule = ({ props }) => {
  const { isNonEnvisionAnnuityContract } = props;
  const {
    text = "",
    schedule = [],
    startingYear = 1,
  } = useDeferredSalesChargeScheduleProps(props);

  const formattedSchedule = schedule.map((value, num) => (
    <>
      <dt
        key={`year_${num + startingYear}_lbl`}
        id={`year_${num + startingYear}_lbl`}
      >
        Year {num + startingYear}
      </dt>
      <dd
        key={`year_${num + startingYear}_value`}
        id={`year_${num + startingYear}_value`}
      >
        {value}
      </dd>
    </>
  ));

  return (
    <div className="mm-text-center">
      <h3 className="justify-content-center" id="CDSC_modal_title">
        Contingent Deferred Sales Charge Schedule
      </h3>
      <IsFalsy value={isNonEnvisionAnnuityContract}>
        <p id="CDSC_modal_description_envision">
          Contingent Deferred Sales Charge (CDSC) is based on the number of full
          years of each purchase payment.
        </p>
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <dl className="mm-key-value-pair--inverted">
              <dt id="year_0_lbl">Year 0</dt>
              <dd id="year_0_value">7%</dd>
              <dt id="year_1_lbl">Year 1</dt>
              <dd id="year_1_value">7%</dd>
              <dt id="year_2_lbl">Year 2</dt>
              <dd id="year_2_value">7%</dd>
              <dt id="year_3_lbl">Year 3</dt>
              <dd id="year_3_value">6%</dd>
              <dt id="year_4_lbl">Year 4</dt>
              <dd id="year_4_value">5%</dd>
              <dt id="year_5_lbl">Year 5</dt>
              <dd id="year_5_value">4%</dd>
              <dt id="year_6_lbl">Year 6</dt>
              <dd id="year_6_value">3%</dd>
              <dt id="year_7+_lbl">Year 7+</dt>
              <dd id="year_7+_value">0%</dd>
            </dl>
          </div>
        </div>
      </IsFalsy>
      <IsTruthy value={isNonEnvisionAnnuityContract}>
        <IsTruthy value={isDefined(text)}>
          <p id="CDSC_modal_description_non_envision">{text}</p>
        </IsTruthy>
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <dl className="mm-key-value-pair--inverted">{formattedSchedule}</dl>
          </div>
        </div>
      </IsTruthy>
    </div>
  );
};

export default DeferredSalesChargeSchedule;
