import React from "react";
import { useConfirmationProps } from "../hooks";
import { fireTealiumAndFollowLink } from "../../../utils/tealium";
import { isArray } from "../../../utils/evaluate";
import IsTruthy from "../../common/logic/isTruthy";
import { scrollToElement } from "../../../utils/dom";

const Confirmation = (props) => {
  scrollToElement("root");

  const {
    decedentName,
    policyNumbersList,
    receivingFormsNamesList,
    financialProfessionalEmail,
    agreementKey,
    requestDateTime,
    additionalComments,
    hasEligibleLifePolicy,
    hasAnnuityPolicy,
    onPreFillFormButtonClick,
    lineOfBusinessCode,
    transactionName,
    userEmail,
    policyId,
  } = useConfirmationProps(props);
  const receivingFormsNames =
    isArray(receivingFormsNamesList) && receivingFormsNamesList.length > 0 ? (
      receivingFormsNamesList.map((name, i) => (
        <p
          id={`confirmation_receiving_forms_contact_info_value_receivingForms${
            i + 1
          }`}
          key={`receivingForms${i + 1}`}
        >
          {name}
        </p>
      ))
    ) : (
      <dd id="confirmation_receiving_forms_contact_info_value">
        None Selected{" "}
      </dd>
    );
  return (
    <div>
      <div className="row">
        <div className="col-md-9">
          <h1 className="h2">
            <div id="death_claim_initiation_confirmation_submitted_heading">
              Thank you, the Initial Death Claim Notification has been
              submitted.
            </div>
          </h1>
        </div>
        <div className="col-3">
          <div id="printButton" className="justify-content-end">
            <button
              onClick={() => window.print()}
              data-bp-link="default"
              className="btn btn-inline hide-print"
              aria-label="Print"
              type="button"
            >
              <span
                id="death_claim_initiation_confirmation_print_icon"
                className="icon-print"
              />
              <span>Print</span>
            </button>
          </div>
        </div>
      </div>
      <p
        className="mm-spacing--minor"
        id="death_claim_initiation_confirmation_submitted_detailed_message"
      >
        The Initial Death Claim Notification has been successfully submitted to
        the Home Office for processing. Please review Service History for
        processing time and updates. It may take up to one business day for the
        claim notification to be visible in Service History. If you have any
        questions regarding this request, please call the Service Center at
        1-800-272-2216, Monday through Friday, 8:00 AM - 8:00 PM ET, and
        reference the policy/contract number.
      </p>
      <hr className="mm-spacing" />
      <div className="row mm-spacing--minor">
        <div className="col-md-9">
          <dl className="mm-key-value-pair">
            <dt id="confirmation_decedent_name_lbl">Decedent Name</dt>
            <dd id="confirmation_decedent_name_value">{decedentName}</dd>
            <dt id="confirmation_policy_numbers_lbl">
              Policy/Contract Number(s)
            </dt>
            <dd id="confirmation_policy_numbers_value">{policyNumbersList}</dd>
            <dt id="confirmation_claim_form_recipients_lbl">
              Claim Form Recipient(s)
            </dt>
            <dd id="confirmation_claim_form_recipients_value">
              {receivingFormsNames}
            </dd>
            <dt id="confirmation_financial_professional_email_lbl">
              Financial Professional Email{" "}
            </dt>
            <dd id="confirmation_financial_professional_email_value">
              {financialProfessionalEmail}
            </dd>
            <dt id="confirmation_request_dateTime_lbl">Request Date/Time</dt>
            <dd id="confirmation_request_dateTime_value">{requestDateTime}</dd>
            <dt id="confirmation_request_additional_comments_lbl">
              Additional Comments
            </dt>
            <dd id="confirmation_request_additional_comments_value">
              {additionalComments}
            </dd>
          </dl>
          <hr />
        </div>
      </div>
      <IsTruthy value={hasEligibleLifePolicy || hasAnnuityPolicy}>
        <div className="mm-spacing--minor">
          <h4 id="confirmation__prefilled_forms_label">
            Click the below link(s) to access the pre-filled Claim form(s):{" "}
          </h4>
        </div>
        <div className="mm-spacing">
          <IsTruthy value={hasEligibleLifePolicy}>
            <button
              type="button"
              id="confirmation_prefilled_life_form_link_btn"
              data-bp-link="default"
              className="btn btn-secondary hide-print"
              onClick={() => onPreFillFormButtonClick("Life")}
            >
              <span className="icon-download" /> PRE-FILLED LIFE FORM
            </button>
          </IsTruthy>
          <IsTruthy value={hasAnnuityPolicy}>
            <button
              type="button"
              id="confirmation_prefilled_annuity_form_link_btn"
              data-bp-link="default"
              className="btn btn-secondary hide-print"
              onClick={() => onPreFillFormButtonClick("Ann")}
            >
              <span className="icon-download" /> PRE-FILLED ANNUITY FORM
            </button>
          </IsTruthy>
        </div>
      </IsTruthy>
      <div className="mm-spacing--minor">&nbsp;</div>
      <div className="mm-spacing--minor">
        <button
          type="button"
          id="confirmation_serviceHistory_link_btn"
          data-bp-link="default"
          onClick={() =>
            fireTealiumAndFollowLink({
              url: `/policyDetails/${encodeURIComponent(
                agreementKey
              )}/viewServiceHistory`,
              action: `${lineOfBusinessCode}: ${transactionName} View Service History link clicked`,
              category: `${transactionName}`,
              value: policyId,
              custId: userEmail,
            })
          }
          className="btn btn-primary hide-print"
        >
          {" "}
          VIEW SERVICE HISTORY
        </button>
        <button
          type="button"
          id="confirmation_close_button"
          data-bp-link="default"
          onClick={() =>
            fireTealiumAndFollowLink({
              url: `/policyDetails/${encodeURIComponent(agreementKey)}`,
              action: `${lineOfBusinessCode}: ${transactionName} Close button clicked`,
              category: `${transactionName}`,
              value: policyId,
              custId: userEmail,
            })
          }
          className="btn btn-link hide-print"
        >
          {" "}
          Close
        </button>
      </div>
    </div>
  );
};
export default Confirmation;
