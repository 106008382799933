import React from "react";
import { setTitle } from "../../utils/dom";
import EnvisionFundTransfer from "./envision";
import IsTruthy from "../common/logic/isTruthy";
import { useFundTransferProps } from "./hooks";
import LoadingSection, { errorTypes, loadingTypes } from "../common/loading";
import IsFalsy from "../common/logic/isFalsy";
import Header from "../layout/header";
import LdBannerContent from "../common/molecules/ldBannerContent";
import FourHundredFourError from "../common/errors/404";
import Footer from "../layout/footer";
import FiveHundredOneError from "../common/errors/501";
import ErrorPage from "../common/errors/errorPage";
import { FUND_ALLOCATION_NOT_AUTHORIZED_MESSAGE } from "./envision/errors";

const FundTransfer = () => {
  setTitle("ServiceNet - Fund Transfer");
  const {
    isUnauthorized,
    isPolicyNotFound,
    isPolicyLoaded,
    isMmsdOnlyUser,
    showEnvisionPage,
    agreementRequest,
    producerRequest,
    pageNumber,
    setPageNumber,
    nextPage,
    previousPage,
    isTransactionAllowed,
    isEligible,
    fundTransferEligibilityRequest,
    fundAllocationEligibilityError,
    isAuthorizedForFundAllocation,
    lineOfBusinessCode,
  } = useFundTransferProps();

  if (isUnauthorized) {
    return (
      <>
        <Header />
        <div className="container">
          <LdBannerContent />
          <FiveHundredOneError />
        </div>
        <Footer />
      </>
    );
  }

  if (isPolicyNotFound) {
    return (
      <>
        <Header />
        <div className="container">
          <LdBannerContent />
          <FourHundredFourError />
        </div>
        <Footer />
      </>
    );
  }

  return (
    <LoadingSection
      request={agreementRequest}
      loadingType={loadingTypes.SPINNER}
      errorType={errorTypes._500}
      addHeaderFooterToError
    >
      <LoadingSection
        request={producerRequest}
        loadingType={loadingTypes.SPINNER}
        errorType={errorTypes._500}
        addHeaderFooterToError
      >
        <LoadingSection
          request={fundTransferEligibilityRequest}
          loadingType={loadingTypes.SPINNER}
          errorType={errorTypes._500}
          addHeaderFooterToError
        >
          <IsTruthy value={isPolicyLoaded}>
            <IsFalsy value={isTransactionAllowed}>
              <IsTruthy value={isMmsdOnlyUser}>
                <Header />
                <div className="container">
                  <LdBannerContent />
                  <ErrorPage
                    errorTitle="Our records indicate you do not have access to this application."
                    agreementKey={agreementRequest?.agreementKey}
                    errorPageFor="mmsdOnly"
                    imageClass="illustration-error_500"
                  />
                </div>
                <Footer />
              </IsTruthy>
              <IsFalsy value={isMmsdOnlyUser}>
                <Header />
                <div className="container">
                  <LdBannerContent />
                  <FourHundredFourError />
                </div>
                <Footer />
              </IsFalsy>
            </IsFalsy>
            <IsTruthy value={isTransactionAllowed}>
              <IsFalsy value={isAuthorizedForFundAllocation}>
                <Header />
                <div className="container">
                  <LdBannerContent />
                  <ErrorPage
                    errorTitleSize="h2"
                    errorTitle={FUND_ALLOCATION_NOT_AUTHORIZED_MESSAGE}
                    agreementKey={agreementRequest?.data?.agreementKey}
                    errorPageFor="404"
                    imageClass="illustration-error_404"
                    displayBackToPolicy
                    lineOfBusinessCode={lineOfBusinessCode}
                    displayTransactionId
                    transactionId={
                      fundTransferEligibilityRequest?.transactionId
                    }
                  />
                </div>
                <Footer />
              </IsFalsy>
              <IsFalsy value={isEligible}>
                <IsTruthy value={isAuthorizedForFundAllocation}>
                  <Header />
                  <div className="container">
                    <LdBannerContent />
                    <ErrorPage
                      errorTitleSize="h2"
                      errorTitle={fundAllocationEligibilityError}
                      agreementKey={agreementRequest?.data?.agreementKey}
                      errorPageFor="404"
                      imageClass="illustration-error_404"
                      displayBackToPolicy
                      lineOfBusinessCode={lineOfBusinessCode}
                      displayTransactionId
                      transactionId={
                        fundTransferEligibilityRequest?.transactionId
                      }
                    />
                  </div>
                  <Footer />
                </IsTruthy>
              </IsFalsy>
            </IsTruthy>
            <IsTruthy
              value={
                isTransactionAllowed &&
                isEligible &&
                isAuthorizedForFundAllocation
              }
            >
              <IsTruthy value={showEnvisionPage}>
                <EnvisionFundTransfer
                  agreementRequest={agreementRequest}
                  producerRequest={producerRequest}
                  pageNumber={pageNumber}
                  nextPage={nextPage}
                  previousPage={previousPage}
                  setPageNumber={setPageNumber}
                />
              </IsTruthy>
            </IsTruthy>
          </IsTruthy>
        </LoadingSection>
      </LoadingSection>
    </LoadingSection>
  );
};

export default React.memo(FundTransfer);
