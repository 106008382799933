// eslint-disable-next-line import/prefer-default-export
export const processAgentSearchResults = (props) => {
  const apiData = props?.response?.data;

  const messages = apiData?.messages || [
    {
      msgType: "",
      msgCode: "",
      msgDesc: "",
    },
  ];
  const policies = apiData?.agreementPolicies || [];

  return { policies, messages };
};
