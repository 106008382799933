import React from "react";
import IsTruthy from "../../../common/logic/isTruthy";
import { roleNames } from "../../../../utils/translate";
import LoadingSection, {
  errorTypes,
  loadingTypes,
} from "../../../common/loading";
import { LOADING_ICON_CUSTOM_MESSAGE } from "../../../../constants/ui";
import IsFalsy from "../../../common/logic/isFalsy";
import { compareStrings } from "../../../../utils/string";
import { isDefined } from "../../../../utils/evaluate";

const CustomerModal = (props) => {
  const {
    customer: { ssn, formattedDob, age, roles },
    customerData: {
      emailAddress,
      attnLine,
      addrFirstLine,
      addrSecondLine,
      addrThirdLine,
      addrFourthLine,
      phoneNumber,
      formattedPhoneNumber,
      genderCode,
    },
    customerRequest,
    isDisabilityPolicyResult,
    isRetireEase,
    asOfDate,
    showGender,
    lifeStatus,
    showPayeeSection,
    paymentStatus,
    paymentMethod,
    benefitPercentage,
    benefitAmount,
  } = props;
  const onlyShowAddressFieldRoles = [
    roleNames.ASGN,
    roleNames.POA,
    isDisabilityPolicyResult ? roleNames.RCPBEN : roleNames.RCP,
  ];
  return (
    <LoadingSection
      request={customerRequest}
      loadingType={loadingTypes.SPINNER}
      errorType={errorTypes.SECTION}
      customErrorMessage={LOADING_ICON_CUSTOM_MESSAGE}
    >
      <div className="modal-body">
        <dl className="mm-key-value-pair">
          <IsTruthy value={isRetireEase}>
            <dt id="as_of_date_lbl">As of date</dt>
            <dd id="as_of_date_value">{asOfDate}</dd>
          </IsTruthy>
          <dt id="customer_roles_lbl">Role</dt>
          <dd id="customer_roles_value">{roles}</dd>
          <IsTruthy
            value={roles}
            validationFunction={(rolesString) =>
              !onlyShowAddressFieldRoles.includes(rolesString)
            }
          >
            <dt id="customer_phone_number_lbl">Phone</dt>
            <dd id="customer_phone_number_value">
              <IsFalsy
                value={phoneNumber}
                validationFunction={(val) => compareStrings(val, "-")}
              >
                <a href={`tel:+${phoneNumber}`}>{formattedPhoneNumber}</a>
              </IsFalsy>
              <IsTruthy
                value={phoneNumber}
                validationFunction={(val) => compareStrings(val, "-")}
              >
                {formattedPhoneNumber}
              </IsTruthy>
            </dd>
            <dt id="customer_email_lbl">Email</dt>
            <dd id="customer_email_value">
              <IsFalsy
                value={emailAddress}
                validationFunction={(val) => compareStrings(val, "-")}
              >
                <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
              </IsFalsy>
              <IsTruthy
                value={emailAddress}
                validationFunction={(val) => compareStrings(val, "-")}
              >
                {emailAddress}
              </IsTruthy>
            </dd>
            <dt id="customer_dob_lbl">Date of Birth</dt>
            <dd id="customer_dob_value">{formattedDob}</dd>
            <dt id="customer_age_lbl">Age</dt>
            <dd id="customer_age_value">{age}</dd>
            <IsTruthy value={isRetireEase}>
              <dt id="customer_life_status_lbl">Life Status</dt>
              <dd id="customer_life_status_value">{lifeStatus}</dd>
            </IsTruthy>
            <IsTruthy value={showGender}>
              <dt id="customer_gender_lbl">Gender</dt>
              <dd id="customer_gender_value">{genderCode}</dd>
            </IsTruthy>
            <dt id="customer_ssn_lbl">Last 4 SSN</dt>
            <dd id="customer_ssn_value">{ssn}</dd>
          </IsTruthy>
          <dt id="customer_adress_lbl">Address</dt>
          <dd>
            <IsTruthy value={isDefined(attnLine)}>
              <span id="customer_attention_adress_value">{attnLine}</span>
              <br />
            </IsTruthy>
            <span id="customer_first_adress_value">{addrFirstLine}</span>
            <IsTruthy value={isDefined(addrSecondLine)}>
              <br />
              <span id="customer_second_adress_value">{addrSecondLine}</span>
            </IsTruthy>
            <IsTruthy value={isDefined(addrThirdLine)}>
              <br />
              <span id="customer_third_adress_value">{addrThirdLine}</span>
            </IsTruthy>
            <IsTruthy value={isDefined(addrFourthLine)}>
              <br />
              <span id="customer_fourth_adress_value">{addrFourthLine}</span>
            </IsTruthy>
          </dd>
          <IsTruthy value={showPayeeSection}>
            <dt id="payee_payment_status_lbl">Payment Status</dt>
            <dd id="payee_payment_status_value">{paymentStatus}</dd>
            <dt id="payee_benefit_pct_lbl">Percentage of Benefit</dt>
            <dd id="payee_benefit_pct_value">{benefitPercentage}</dd>
            <dt id="payee_taxable_to_lbl">Taxable To</dt>
            <dd id="payee_taxable_to_value">Owner</dd>
            <dt id="payee_benefit_amt_lbl">Amount of Beneift</dt>
            <dd id="payee_benefit_amt_value">{benefitAmount}</dd>
            <dt id="payee_payment_method_lbl">Payment Method</dt>
            <dd id="payee_payment_method_value">{paymentMethod}</dd>
          </IsTruthy>
        </dl>
      </div>
    </LoadingSection>
  );
};
export default CustomerModal;
