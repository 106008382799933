import React from "react";
import { useRadioFormProps } from "./hooks";

const Radio = (props) => {
  const {
    formField: { id, name, value, disabled },
    useHook = useRadioFormProps,
  } = props;
  const { onChange } = useHook(props);

  return (
    <input
      type="radio"
      id={`input-${id}`}
      name={name}
      checked={value}
      onChange={onChange}
      className="custom-control-input"
      data-testid={id}
      disabled={disabled}
    />
  );
};

export default Radio;
