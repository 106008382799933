import React from "react";
import LabelWithHelpModal from "../../common/forms/labelWithHelpModal";

const NameChangeModalBody = () => (
  <div className="modal-body">
    <hr />
    <h5 id="directLink_NameChange_ModalTitle">Name Change</h5>
    <p>
      The following can be done on the <strong>website</strong>:
    </p>
    <ul>
      <li>Name Change can be submitted electronically.</li>
      <li>
        Customer must upload documents verifying the change (e.g. Marriage
        Certificate)
      </li>
    </ul>
    <div className="mm-spacing--minor" />
    <h5 id="directLink_NameChange_ModalQuickTips">Quick Tips</h5>
    <ul>
      <li>Form is typically not required.</li>
    </ul>
  </div>
);

const NameChangeModal = () => (
  <LabelWithHelpModal
    text="Name Change"
    modalTitle="Name Change"
    modalId="nameChange"
    modalBodyComponent={NameChangeModalBody}
  />
);

export default NameChangeModal;
