import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useOktaAuth } from "@okta/okta-react";
import { getBpIdFromUser } from "../utils/findData";
import { useMakeRequest } from "./api";
import { compareArrayOfStrings } from "../utils/string";
import { typesOfAgents } from "../data/typesOfAgents";

export const useGetUser = (props) => {
  const {
    userStateTypes,
    userDispatch,
    agentProfileStateTypes,
    agentProfileDispatch,
  } = props;
  const { authState, oktaAuth } = useOktaAuth();
  const [oktaUserInfo, setOktaUserInfo] = useState({});
  const [agentInfo, setAgentInfo] = useState({});

  const agentProfileRequest = useMakeRequest({
    apiRequest: "fetchAgentProfile",
    apiParams: { businessPartnerId: agentInfo.bpId },
    immediateRequest: false,
    dataSelector: "processAgentProfileData",
    initiateDevModeDispatch: false,
  });

  useEffect(() => {
    if (authState?.isAuthenticated) {
      oktaAuth.getUser().then((userInfo) => {
        setOktaUserInfo({ key: userInfo?.email, custom: userInfo });
        const { isAnAgent, agentType, bpId } = getBpIdFromUser(authState);

        userDispatch({
          type: userStateTypes.LOG_IN_USER,
          userInfo,
          authState,
          isAnAgent,
          agentType,
        });
        setAgentInfo({ isAnAgent, agentType, bpId });

        if (isAnAgent) {
          agentProfileRequest.executeRequest();

          if (
            compareArrayOfStrings(
              [typesOfAgents.MMSD, typesOfAgents.MMSD_MMFA],
              agentType
            )
          ) {
            Cookies.set("mmsd", "true");
          }
        }
      });
    }
  }, [
    oktaAuth,
    authState,
    authState?.isAuthenticated,
    userDispatch,
    agentProfileDispatch,
    userStateTypes.LOG_IN_USER,
    userStateTypes.LOG_OUT_USER,
    agentProfileStateTypes.LOG_IN_AGENT,
    agentProfileStateTypes.LOG_OUT_AGENT,
  ]);

  useEffect(() => {
    agentProfileDispatch({
      type: agentProfileStateTypes.LOG_IN_AGENT,
      agentProfileRequest,
    });
  }, [agentProfileRequest.isLoading]);

  return { oktaUserInfo };
};
