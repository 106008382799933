import React from "react";
import LabelWithHelpModal from "../../common/forms/labelWithHelpModal";

const CashValueQuoteModalBody = () => (
  <div className="modal-body">
    <hr />
    <h5 id="directLink_CashValueQuote_ModalTitle">Cash Value Quote</h5>
    <p>
      The following can be done on the <strong>website </strong>or{" "}
      <strong>mobile</strong> app:
    </p>
    <ul>
      <li>
        Customer can view/print Current Cash Values on company letterhead.
      </li>
    </ul>
    <div className="mm-spacing--minor" />
    <h5 id="directLink_CashValueQuote_ModalQuickTips">Quick Tips</h5>
    <ul>
      <li>
        The Cash Value Quote generated on MassMutual.com can be viewed in the
        documents tab on the mobile app.
      </li>
    </ul>
  </div>
);

const CashValueQuoteModal = () => (
  <LabelWithHelpModal
    text="Cash Value Quote"
    modalTitle="Cash Value Quote"
    modalId="cashValueQuote"
    modalBodyComponent={CashValueQuoteModalBody}
  />
);

export default CashValueQuoteModal;
