import React from "react";
import LoadingSection, {
  errorTypes,
  loadingTypes,
} from "../../../common/loading";
import { usePeopleOnContractProps } from "./hooks";
import Table from "../../../common/table";
import { BeneficiariesDisplay } from "../peopleOnPolicy/beneficiariesDisplay";

const PeopleOnContract = (props) => {
  const { id } = props;
  const {
    requests: { beneficiaryRequest, producerData },
    data: {
      agreementData,
      peopleOnContractColumns,
      peopleList,
      agreementKey,
      columnsBeneficiary,
      beneficiariesSortByBeneficiaryClass,
      complexBeneficiaries,
      displayNoBeneInfoAlert,
      showFootnote,
      policyId,
      contactUsInfo,
      isNonEnvisionAnnuityContract,
      agentTable: { rows, columns },
    },
    functions: { showCustomerModal, showAgentModal, showBeneficiaryModal },
  } = usePeopleOnContractProps(props);

  return (
    <div className="mm-spacing">
      <h2 aria-label="People on Contract" id={id}>
        People on Contract
      </h2>
      <div className="mm-spacing--minor">
        <Table
          id="people_on_contract"
          columns={peopleOnContractColumns}
          rows={peopleList}
          showPagination={false}
          customFunctions={{ showCustomerModal }}
        />
      </div>
      <div className="mm-spacing--minor">
        <LoadingSection
          request={beneficiaryRequest}
          loadingType={loadingTypes.PARAGRAPH}
          errorType={errorTypes.SECTION}
        >
          <BeneficiariesDisplay
            showFootnote={showFootnote}
            agreementKey={agreementKey}
            agreementData={agreementData}
            policyId={policyId}
            columnsBeneficiary={columnsBeneficiary}
            beneficiariesSortByBeneficiaryClass={
              beneficiariesSortByBeneficiaryClass
            }
            complexBeneficiaries={complexBeneficiaries}
            showBeneficiaryModal={{ showBeneficiaryModal }}
            isConvertedHapPolicy={false}
            isNonEnvisionAnnuityContract={isNonEnvisionAnnuityContract}
            hapBeneficiary={[]}
            contactUsInfo={contactUsInfo}
            displayNoBeneInfoAlert={displayNoBeneInfoAlert}
          />
        </LoadingSection>
      </div>
      <div className="mm-spacing--minor">
        <h3 id="people_on_contract_agents_lbl">
          Financial Professionals/Agents
        </h3>
        <LoadingSection
          request={producerData}
          loadingType={loadingTypes.PARAGRAPH}
          errorType={errorTypes.SECTION}
        >
          <div className="row">
            <div className="col-sm">
              <Table
                id="agentsOnAgreement"
                columns={columns}
                rows={rows}
                customFunctions={{ showAgentModal }}
                showPagination={false}
                noResultsText="No information available."
              />
            </div>
          </div>
        </LoadingSection>
      </div>
    </div>
  );
};

export default React.memo(PeopleOnContract);
