import React from "react";
import BasicPolicyAndContactInfo from "./basicPolicyAndContactInfo";
import { usePolicyAndContactInfoDrawerProps } from "./hooks";

const PolicyAndContactInfoDrawer = (props) => {
  const {
    policyId,
    producerData,
    agreementRequest,
    producerRequest,
    annuityRequest,
    billingRequest,
    soaBillingRequest,
  } = props;
  const {
    customerData,
    agreementData,
    customerOwnerFullName,
    customerRequest,
    billingData,
    memberId,
  } = usePolicyAndContactInfoDrawerProps(props);

  return (
    <div>
      <BasicPolicyAndContactInfo
        customerOwnerFullName={customerOwnerFullName}
        customerData={customerData}
        policyId={policyId}
        producerData={producerData}
        agreementData={agreementData}
        agreementRequest={agreementRequest}
        customerRequest={customerRequest}
        producerRequest={producerRequest}
        annuityRequest={annuityRequest}
        billingData={billingData}
        billingRequest={billingRequest}
        target="_self"
        soaBillingRequest={soaBillingRequest}
        memberId={memberId}
      />
    </div>
  );
};
export default React.memo(PolicyAndContactInfoDrawer);
