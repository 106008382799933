import React from "react";
import { useDividendsProps } from "./hooks";
import {
  ANNUAL_DIVIDEND_TOOLTIP_TEXT,
  DATA_UNAVAILABLE,
  DIVIDEND_CASH_VALUE_ADDITIONS_TOOLTIP_TEXT,
  REQUEST_INFORMATION_TOOLTIP_TEXT,
} from "../../../../constants/ui";
import IsFalsy from "../../../common/logic/isFalsy";
import IsTruthy from "../../../common/logic/isTruthy";
import {
  isDisabilityPolicy,
  isTermPolicy,
  isULUVPolicy,
} from "../../../../utils/policies";
import {
  InformationPopover,
  LabelWithInformationPopover,
  RequestInformationLink,
} from "../../../common/popovers/informationPopover";
import FeatureFlag from "../../../common/featureFlags";
import AlirOpmSection from "./alirOpmSection";
import AlirHapOrVntg1Section from "./alirHapOrVntg1Section";
import LisrOpmSection from "./lisrOpmSection";
import LisrVntg1Section from "./lisrVtng1Section";
import SiprOpmSection from "./siprOpmSection";
import { compareStrings } from "../../../../utils/string";
import { checkStatusAndReason } from "../../types/life/hooks";
import LisrHAPSection from "./lisrHapSection";

const Dividends = (props) => {
  const {
    agreementKey,
    id,
    currentDividendTypeDescription = DATA_UNAVAILABLE,
    currentDividendAmount = DATA_UNAVAILABLE,
    currentAdditionsPurchasedAmount = DATA_UNAVAILABLE,
    dividends,
    displayDividentTotalBaseCoverage,
    alirVntg1PolicyCoverages,
    siprOpmPolicyCoverages,
    alirOpmPolicyCoverages,
    lisrOpmPolicyCoverages,
    lisrVntg1PolicyCoverages,
    lisrHapPolicyCoverages,
    alirHapPermPolicyCoverages,
    agreementData,
    showAdditionalRiderDividends,
    isPallmNTLPolicy,
    policyId,
    currentDividendYear,
    showCurrentDividendYear,
    currentDividendDisplayName,
    hidePaidUpAdditionsFaceAmount,
    cashValuePaidUpAddsTotal,
    paidUpAddsFaceAmtTotal,
    dividendAccumAvailable,
    isHAPPolicy,
    isValidStatusReason,
  } = useDividendsProps(props);

  return (
    <IsFalsy
      value={props}
      validationFunction={(propsObj) =>
        isTermPolicy(propsObj) || isULUVPolicy(propsObj) || isPallmNTLPolicy
      }
    >
      <div className="mm-spacing">
        <h2 aria-label="Dividends" id={id}>
          Dividends
        </h2>

        <FeatureFlag flagName={["stable", "policyDetails", "dividends"]}>
          <div className="mm-spacing--minor">
            <IsFalsy
              value={agreementData}
              validationFunction={isDisabilityPolicy}
            >
              <h3 id="annual_dividend_information_lbl">
                Annual Dividend Information
              </h3>
            </IsFalsy>
            <IsTruthy
              value={agreementData}
              validationFunction={isDisabilityPolicy}
            >
              <h3 id="current_anniversary_information_lbl">
                For Current Anniversary
              </h3>
            </IsTruthy>
            <hr />
            <div className="row">
              <div className="col-12 col-md-6">
                <dl className="mm-key-value-pair">
                  <dt id="dividends_dividend_option_label">Dividend Option</dt>
                  <dd id="dividends_dividend_option_value">
                    {currentDividendTypeDescription}
                  </dd>
                  <IsFalsy
                    value={agreementData}
                    validationFunction={isDisabilityPolicy}
                  >
                    <dt
                      id="dividends_annual_dividend_label"
                      className="mm-text-nowrap"
                    >
                      {currentDividendDisplayName}{" "}
                      <InformationPopover
                        popoverId="current_dividend_tooltip"
                        text={ANNUAL_DIVIDEND_TOOLTIP_TEXT}
                      />
                      <sup className="print-only">1</sup>
                    </dt>
                    <dd id="dividends_annual_dividend_value">
                      {currentDividendAmount}
                    </dd>
                  </IsFalsy>
                </dl>
              </div>
              <div className="col-12 col-md-6">
                <dl className="mm-key-value-pair">
                  <IsTruthy
                    value={agreementData}
                    validationFunction={isDisabilityPolicy}
                  >
                    <dt
                      id="dividends_annual_dividend_label"
                      className="mm-text-nowrap"
                    >
                      Current Dividend{" "}
                      <InformationPopover
                        popoverId="current_dividend_tooltip"
                        text="The most recent dividend that was earned on the policy."
                      />
                      <span className="print-only">
                        (The most recent dividend that was earned on the
                        policy.)
                      </span>
                    </dt>
                    <dd id="dividends_annual_dividend_value">
                      {currentDividendAmount}
                    </dd>
                  </IsTruthy>
                  <IsFalsy
                    value={agreementData}
                    validationFunction={isDisabilityPolicy}
                  >
                    <IsTruthy value={showCurrentDividendYear}>
                      <dt id="dividend_year_lbl">Dividend Year</dt>
                      <dd id="dividend_year_value">{currentDividendYear}</dd>
                    </IsTruthy>
                    <IsFalsy value={hidePaidUpAdditionsFaceAmount}>
                      <dt id="dividends_paid_face_amount_label">
                        Paid Up Additions Face Amount - Current Year
                      </dt>
                      <dd id="dividends_paid_face_amount_value">
                        <IsTruthy
                          value={currentAdditionsPurchasedAmount}
                          validationFunction={(v) =>
                            compareStrings(v, DATA_UNAVAILABLE)
                          }
                        >
                          <IsTruthy
                            value={agreementData}
                            validationFunction={checkStatusAndReason}
                          >
                            <IsFalsy value={isHAPPolicy}>
                              <RequestInformationLink
                                popoverId="dividend_face_amount"
                                agreementData={agreementData}
                                agreementKey={agreementKey}
                              />
                            </IsFalsy>
                            <IsTruthy value={isHAPPolicy}>Unavailable</IsTruthy>
                          </IsTruthy>
                          <IsFalsy
                            value={agreementData}
                            validationFunction={checkStatusAndReason}
                          >
                            Unavailable
                          </IsFalsy>
                        </IsTruthy>
                        <IsFalsy
                          value={currentAdditionsPurchasedAmount}
                          validationFunction={(v) =>
                            compareStrings(v, DATA_UNAVAILABLE)
                          }
                        >
                          {currentAdditionsPurchasedAmount}
                        </IsFalsy>
                      </dd>
                    </IsFalsy>
                  </IsFalsy>
                </dl>
              </div>
            </div>
            <hr />
          </div>
          <IsTruthy value={displayDividentTotalBaseCoverage}>
            <div className="mm-spacing--minor">
              <h3 id="dividends_total_base_coverage_lbl">
                Total Base Coverage Dividend
              </h3>
              <IsTruthy
                value={agreementData}
                validationFunction={isDisabilityPolicy}
              >
                <hr />
                <div className="row">
                  <div className="col-12 col-md-6">
                    <dl className="mm-key-value-pair">
                      <dt id="dividend_accum_avail_lbl">
                        Dividend Accumulations Available
                      </dt>
                      <dd id="dividend_accum_avail_value">
                        {dividends[0].dividendAccumAvailableAmount}
                      </dd>
                    </dl>
                  </div>
                </div>
              </IsTruthy>
              <IsFalsy
                value={agreementData}
                validationFunction={isDisabilityPolicy}
              >
                <hr />
                <div className="row">
                  <div className="col-12 col-md-6">
                    <dl className="mm-key-value-pair">
                      <IsTruthy
                        value={compareStrings(
                          cashValuePaidUpAddsTotal,
                          DATA_UNAVAILABLE
                        )}
                      >
                        <IsTruthy value={!isHAPPolicy && isValidStatusReason}>
                          <dt id="cash_value_paidup_adds_total_unavailable_lbl">
                            <LabelWithInformationPopover
                              label="Cash Value of Paid Up Additions - Total"
                              popoverId="cash_value_paidup_adds_total_unavailable_icon"
                              text={REQUEST_INFORMATION_TOOLTIP_TEXT}
                            />
                            <sup className="print-only">2</sup>
                          </dt>
                          <dd id="cash_value_paidup_adds_total_unavailable_link">
                            <RequestInformationLink
                              popoverId="cash_value_paidup_adds_total_unavail_icon"
                              agreementData={agreementData}
                              agreementKey={agreementKey}
                              policyId={policyId}
                            />
                          </dd>
                        </IsTruthy>
                        <IsFalsy value={!isHAPPolicy && isValidStatusReason}>
                          <dt id="cash_value_paidup_adds_total_unavailable_lbl">
                            Cash Value of Paid Up Additions - Total
                          </dt>
                          <dd id="cash_value_paidup_adds_total_unavailable_link">
                            Unavailable
                          </dd>
                        </IsFalsy>
                      </IsTruthy>
                      <IsFalsy
                        value={compareStrings(
                          cashValuePaidUpAddsTotal,
                          DATA_UNAVAILABLE
                        )}
                      >
                        <dt id="cash_value_paidup_adds_total_lbl">
                          <LabelWithInformationPopover
                            label="Cash Value of Paid Up Additions - Total"
                            popoverId="cash_value_paidup_adds_total_lbl_icon"
                            text={DIVIDEND_CASH_VALUE_ADDITIONS_TOOLTIP_TEXT}
                          />
                          <sup className="print-only">3</sup>
                        </dt>
                        <dd id="cash_value_paidup_adds_total_value">
                          {cashValuePaidUpAddsTotal}
                        </dd>
                      </IsFalsy>
                      <IsTruthy
                        value={
                          compareStrings(
                            dividendAccumAvailable,
                            DATA_UNAVAILABLE
                          ) && !isHAPPolicy
                        }
                      >
                        <dt id="dividend_accum_avail_unavail_lbl">
                          <LabelWithInformationPopover
                            label="Dividend Accumulations Available"
                            popoverId="dividend_accum_avail_lbl_icon"
                            text={REQUEST_INFORMATION_TOOLTIP_TEXT}
                          />
                          <sup className="print-only">2</sup>
                        </dt>
                        <dd id="dividend_accum_avail_unavail_link">
                          <RequestInformationLink
                            popoverId="dividend_accum_avail_unavail_icon"
                            agreementData={agreementData}
                            agreementKey={agreementKey}
                            policyId={policyId}
                          />
                        </dd>
                      </IsTruthy>
                      <IsFalsy
                        value={
                          compareStrings(
                            dividendAccumAvailable,
                            DATA_UNAVAILABLE
                          ) && !isHAPPolicy
                        }
                      >
                        <dt id="dividend_accum_available_lbl">
                          Dividend Accumulations Available
                        </dt>
                        <dd id="dividend_accum_available_value">
                          {dividendAccumAvailable}
                        </dd>
                      </IsFalsy>
                    </dl>
                  </div>
                  <div className="col-12 col-md-6">
                    <dl className="mm-key-value-pair">
                      <IsTruthy
                        value={compareStrings(
                          paidUpAddsFaceAmtTotal,
                          DATA_UNAVAILABLE
                        )}
                      >
                        <IsTruthy value={!isHAPPolicy && isValidStatusReason}>
                          <dt id="paidup_adds_face_amt_unavail_lbl">
                            <LabelWithInformationPopover
                              label="Paid Up Additions Face Amount - Total"
                              popoverId="paidup_adds_face_amt_unavail_icon"
                              text={REQUEST_INFORMATION_TOOLTIP_TEXT}
                            />
                            <sup className="print-only">2</sup>
                          </dt>
                          <dd id="paidup_adds_face_amt_unavail_value">
                            <RequestInformationLink
                              popoverId="paidup_adds_face_amt_unavail_link"
                              agreementData={agreementData}
                              agreementKey={agreementKey}
                              policyId={policyId}
                            />
                          </dd>
                        </IsTruthy>
                        <IsFalsy value={!isHAPPolicy && isValidStatusReason}>
                          <dt id="paidup_adds_face_amt_unavail_lbl">
                            Paid Up Additions Face Amount - Total
                          </dt>
                          <dd id="paidup_adds_face_amt_unavail_value">
                            Unavailable
                          </dd>
                        </IsFalsy>
                      </IsTruthy>
                      <IsFalsy
                        value={compareStrings(
                          paidUpAddsFaceAmtTotal,
                          DATA_UNAVAILABLE
                        )}
                      >
                        <dt id="paidup_adds_face_amt_lbl">
                          Paid Up Additions Face Amount - Total
                        </dt>
                        <dd id="paidup_adds_face_amt_value">
                          {paidUpAddsFaceAmtTotal}
                        </dd>
                      </IsFalsy>
                    </dl>
                  </div>
                </div>
              </IsFalsy>
            </div>
          </IsTruthy>
          <div id="print_only_footnotes" className="print-only">
            <IsFalsy
              value={agreementData}
              validationFunction={isDisabilityPolicy}
            >
              <div>1. {ANNUAL_DIVIDEND_TOOLTIP_TEXT}</div>
            </IsFalsy>
            <IsTruthy
              value={
                displayDividentTotalBaseCoverage &&
                !isDisabilityPolicy(agreementData) &&
                (((compareStrings(cashValuePaidUpAddsTotal, DATA_UNAVAILABLE) ||
                  compareStrings(paidUpAddsFaceAmtTotal, DATA_UNAVAILABLE)) &&
                  checkStatusAndReason(agreementData)) ||
                  compareStrings(dividendAccumAvailable, DATA_UNAVAILABLE))
              }
            >
              <div>2. {REQUEST_INFORMATION_TOOLTIP_TEXT}</div>
            </IsTruthy>
            <IsTruthy
              value={
                displayDividentTotalBaseCoverage &&
                !isDisabilityPolicy(agreementData) &&
                !compareStrings(cashValuePaidUpAddsTotal, DATA_UNAVAILABLE)
              }
            >
              <div>3. {DIVIDEND_CASH_VALUE_ADDITIONS_TOOLTIP_TEXT} </div>
            </IsTruthy>
          </div>
          <hr />
          <IsTruthy value={showAdditionalRiderDividends}>
            <h3>Riders with Dividends</h3>

            <IsTruthy value={alirVntg1PolicyCoverages.length > 0}>
              <hr />
              <AlirHapOrVntg1Section
                coverages={alirVntg1PolicyCoverages}
                agreementData={agreementData}
              />
            </IsTruthy>
            <IsTruthy value={alirHapPermPolicyCoverages.length > 0}>
              <hr />
              <AlirHapOrVntg1Section
                coverages={alirHapPermPolicyCoverages}
                agreementData={agreementData}
              />
            </IsTruthy>
            <IsTruthy value={siprOpmPolicyCoverages.length > 0}>
              <hr />
              <SiprOpmSection coverages={siprOpmPolicyCoverages} />
            </IsTruthy>
            <IsTruthy value={alirOpmPolicyCoverages.length > 0}>
              <hr />
              <AlirOpmSection coverages={alirOpmPolicyCoverages} />
            </IsTruthy>
            <IsTruthy value={lisrOpmPolicyCoverages.length > 0}>
              <hr />
              <LisrOpmSection coverages={lisrOpmPolicyCoverages} />
            </IsTruthy>
            <IsTruthy value={lisrVntg1PolicyCoverages.showLisrVNTGSection}>
              <hr />
              <LisrVntg1Section coverages={lisrVntg1PolicyCoverages} />
            </IsTruthy>
            <IsTruthy value={lisrHapPolicyCoverages.length > 0}>
              <hr />
              <LisrHAPSection coverages={lisrHapPolicyCoverages} />
            </IsTruthy>
            <hr />
          </IsTruthy>
        </FeatureFlag>
      </div>
    </IsFalsy>
  );
};

export default React.memo(Dividends);
