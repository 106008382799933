import ServiceHistoryModalCell from "../../pages/viewServiceHistory/serviceHistoryModalCell";
import { compareStrings } from "../../../../utils/string";
import { formatPhoneNumberString } from "../../../../utils/format";
import {
  isAnnuityContract,
  isCoverpathPolicy,
  isEnvisionPolicy,
} from "../../../../utils/policies";
import { isDefined } from "../../../../utils/evaluate";

export const useServiceHistoryProps = (props) => {
  const {
    agreementRequest: {
      data: {
        contactUsNumber,
        contactUsTime,
        agreementKeyAdmin,
        lineOfBusinessCode,
      },
    },
    agreementRequest,
    serviceRequest,
  } = props;

  const { eventsArray } = serviceRequest.data;
  let contactUsNo;
  let contactDateTime;
  const formattedEventsArray = eventsArray?.map((event) => {
    const { status, completionDate } = event;
    let formattedCompletionDate = completionDate;
    if (compareStrings(status, "pending")) {
      formattedCompletionDate = "-";
    }
    return {
      ...event,
      formattedCompletionDate,
    };
  });

  const events = formattedEventsArray?.slice(0, 3) || [];
  const columns = [
    {
      accessor: "eventName",
      label: "Request",
      alignment: "left",
      sectionAbbreviation: "sh",
      CustomCell: ServiceHistoryModalCell,
    },
    {
      accessor: "status",
      label: "Status",
      alignment: "left",
      sectionAbbreviation: "sh",
    },
    {
      accessor: "eventDate",
      label: "Date Received",
      alignment: "left",
      sectionAbbreviation: "sh",
    },
    {
      accessor: "serviceChannel",
      label: "Channel",
      alignment: "left",
      sectionAbbreviation: "sh",
    },
    {
      accessor: "formattedCompletionDate",
      label: "Complete Date",
      alignment: "left",
      sectionAbbreviation: "sh",
    },
  ];

  const IS_ENVISION_POLICY = isEnvisionPolicy(agreementRequest.data);
  if (IS_ENVISION_POLICY) {
    contactUsNo = isDefined(contactUsNumber)
      ? contactUsNumber
      : "1-800-272-2216";
    contactDateTime = isDefined(contactUsTime)
      ? contactUsTime
      : "Mon - Fri 8 a.m. - 8 p.m. ET";
  } else {
    contactDateTime = contactUsTime;
    contactUsNo = contactUsNumber;
  }

  const isHap = isCoverpathPolicy(agreementKeyAdmin);
  const isAnnuityProduct = isAnnuityContract(lineOfBusinessCode);
  const showExtendedPageLink = eventsArray?.length > 3;

  return {
    table: {
      columns,
      rows: events,
    },
    isHap,
    isAnnuityProduct,
    showExtendedPageLink,
    contactUsNumber: formatPhoneNumberString(contactUsNo),
    contactUsTime: contactDateTime,
    IS_ENVISION_POLICY,
  };
};
