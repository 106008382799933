import React from "react";
import LabelWithHelpModal from "../../common/forms/labelWithHelpModal";

const TransactionStatusModalBody = () => (
  <div className="modal-body">
    <hr />
    <h5 id="directLink_TransactionStatus_ModalQuickTips">Transaction Status</h5>
    <p>
      The following transaction status are available on the{" "}
      <strong>website </strong>or <strong>mobile</strong> app:
    </p>
    <ul>
      <li>Address Change</li>
      <li>Loan Request</li>
      <li>Dividend Withdrawal</li>
    </ul>
    <div className="mm-spacing--minor" />
    <h5 id="directLink_TransactionStatus_ModalQuickTips">Quick Tips</h5>
    <ul>
      <li>Status is available regardless if the request was done online</li>
    </ul>
  </div>
);

const TransactionStatusModal = () => (
  <LabelWithHelpModal
    text="Transaction Status"
    modalTitle="Transaction Status"
    modalId="transactionStatus"
    modalBodyComponent={TransactionStatusModalBody}
  />
);

export default TransactionStatusModal;
