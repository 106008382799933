import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { nameCase } from "@foundernest/namecase";
import { useMakeRequest } from "../../hooks/api";
import {
  useConfirmEmailValidation,
  useEmailValidation,
} from "../../utils/validation";
import {
  getBpIdFromUser,
  getCustomerByMemberId,
  getFirstServicingAgent,
  getRequestorInfo,
  getUserBySubType,
  isHomeOfficeUser,
} from "../../utils/findData";
import { formatName, getLastXChars } from "../../utils/format";
import { types, useFormContext } from "../../contexts/form";
import { isDefined } from "../../utils/evaluate";
import { isAnnuityContract } from "../../utils/policies";
import { formatDate, getCurrentDate } from "../../utils/dates";
import { supportedTransactions, userTypes } from "../../utils/translate";
import { useUserState } from "../../contexts/user";
import { AGREEMENT_NOT_FOUND } from "../../constants/ui";
import { compareArrayOfStrings, compareStrings } from "../../utils/string";
import {
  createCatsEvents,
  fetchUserDigitalRegistrationEligibility,
  registerUser,
} from "../../api";
import {
  USER_REGISTRATION_SSN_NOT_IN_FILE_MESSAGE,
  getDisplayTextForError,
} from "./errors";
import { fireTealiumEvent } from "../../utils/tealium";
import { useAddOrRemoveChatLauncher } from "../../utils/genesysChatClient";

export const useDigitalRegistrationProps = () => {
  const { agreementKey, memberId } = useParams();
  const [pageNumber, setPageNumber] = useState(1);
  const [showErrorPage, setShowErrorPage] = useState(false);
  const [error, setError] = useState(null);
  const [showLoadingIcon, setShowLoadingIcon] = useState(false);
  const [userEligibilityResult, setUserEligibilityResult] = useState(false);
  const [amcGuid, setAmcGuid] = useState("");

  const validRequest = (dependentAgreementData) => {
    const { isUnauthorized } = dependentAgreementData;
    const { type, description } = dependentAgreementData?.messages || {};
    return (
      !isUnauthorized &&
      compareStrings(type, "SUCCESS") &&
      !compareStrings(description, AGREEMENT_NOT_FOUND)
    );
  };
  const producersRequest = useMakeRequest({
    apiRequest: "fetchProducersData",
    apiParams: { agreementKey },
    immediateRequest: false,
    dataSelector: "processProducersData",
    canExecuteCallBack: validRequest,
  });

  const agreementRequest = useMakeRequest({
    apiRequest: "fetchAgreementData",
    apiParams: { agreementKey },
    immediateRequest: true,
    dataSelector: "processAgreementRequest",
    dependentRequests: [producersRequest],
  });
  const { user } = useUserState();
  const agreementData = agreementRequest.data || {};
  const { policyId, lineOfBusinessCode } = agreementData;
  const isPolicyDataLoaded =
    isDefined(policyId) && isDefined(lineOfBusinessCode);
  const owner = getCustomerByMemberId({ memberId, agreementData });
  const formattedOwnerName = formatName({
    nameObject: {
      firstName: owner?.firstName,
      lastName: owner?.lastName,
      fullName: owner?.fullName,
    },
    format: "firstNameDisplayedFirst",
  });
  const onSubmit = (formData) => {
    setShowLoadingIcon(true);

    fireTealiumEvent({
      label: "SVNT",
      action: `${lineOfBusinessCode}: Digital Registration Pre-Register button clicked`,
      category: "Digital Registration",
      value: policyId,
      custId: user.email,
    });

    const digitalRegistrationCatsEventBody = makeDirectLinksEmailRequestBody(
      formData,
      user,
      producersRequest,
      agreementData,
      formattedOwnerName
    );

    const headers = {
      Authorization: `Bearer ${user.accessToken}`,
    };

    const registerUserRequestBody = makeRegisterUserRequestBody({
      formData,
      amcGuid,
      owner,
      user,
      producersRequest,
    });

    registerUser({
      headers,
      apiParams: registerUserRequestBody,
    })
      .then((result) => {
        if (result?.status === 200) {
          createCatsEvents({
            headers,
            apiParams: digitalRegistrationCatsEventBody,
          }).catch((err) => {
            console.log({
              err,
              message: `Digital Registration CATs event creation failed for ${agreementKey}. Registration correlation ID ${result?.headers?.correlationid}`,
            });
          });
          setPageNumber(3);
          fireTealiumEvent({
            label: "SVNT",
            action: `${lineOfBusinessCode}: Digital Registration Pre-Registration successful`,
            category: "Digital Registration",
            value: policyId,
            custId: user.email,
          });
        }
      })
      .catch(() => {
        setShowErrorPage(true);
        fireTealiumEvent({
          label: "SVNT",
          action: `${lineOfBusinessCode}: Digital Registration Pre-Registration failed`,
          category: "Digital Registration",
          value: policyId,
          custId: user.email,
        });
      })
      .finally(() => {
        setShowLoadingIcon(false);
      });
  };
  const fields = useDigitalRegistrationFormFields();

  const isUnauthorized = agreementData?.isUnauthorized;

  const shouldDisableSubmit = isHomeOfficeUser(user);

  useAddOrRemoveChatLauncher({
    agreementData,
    page: "Digital Registration transaction",
  });

  return {
    agreementRequest,
    fields,
    ownerName: {
      fullName: formattedOwnerName,
      firstName: owner?.firstName || "Client",
    },
    pageNumber,
    setPageNumber,
    error,
    setError,
    onSubmit,
    showLoadingIcon,
    setShowLoadingIcon,
    lineOfBusinessCode,
    showErrorPage,
    producersRequest,
    agreementKey,
    isPolicyDataLoaded,
    isUnauthorized,
    owner,
    userEligibilityResult,
    setUserEligibilityResult,
    setAmcGuid,
    policyId,
    transactionName: supportedTransactions.DigitalRegistration,
    shouldDisableSubmit,
  };
};

export const useDigitalRegistrationFormFields = () => ({
  emailContactInput: {
    id: "emailContactInput",
    name: "emailContactInput",
    label: "Email Address",
    ariaLabel: "Email input",
    placeholder: "Please enter your client’s email address",
    labelBold: true,
    required: true,
    errors: [],
    postpone: false,
    useValidation: useEmailValidation,
    disabled: false,
    className: "form-control",
  },
  emailContactConfirmInput: {
    id: "emailContactConfirmInput",
    name: "emailContactConfirmInput",
    label: "Confirm Email",
    ariaLabel: "Confirm Email input",
    placeholder: "Please enter your client’s email address",
    labelBold: true,
    required: true,
    value: "",
    uncheckedValue: "",
    errors: [],
    postpone: true,
    useValidation: useConfirmEmailValidation,
    disabled: false,
    disablePaste: true,
    className: "form-control",
  },
  authAcknowledgement: {
    id: "authAcknowledgement",
    name: "authAcknowledgement",
    label:
      "I confirm that the policy/contract owner(s) have authorized me to initiate this pre-registration process.",
    value: false,
    required: false,
  },
});
export const useEmailContactProps = (props) => {
  const { useContainerHook = () => {}, userEligibilityResult } = props;

  const {
    formData: { emailContactInput, emailContactConfirmInput },
    dispatchForm,
  } = useFormContext();

  const confirmEmailValidationErrors = useConfirmEmailValidation({
    formField: emailContactConfirmInput,
  });

  useEffect(() => {
    dispatchForm({
      type: types.REPLACE_FIELD,
      formField: {
        ...emailContactConfirmInput,
        emailFormFieldValue: emailContactInput.value,
        errors: confirmEmailValidationErrors,
        disabled: userEligibilityResult,
      },
    });
  }, [emailContactInput]);

  useContainerHook();

  return {
    emailContactInput,
    emailContactConfirmInput,
  };
};

export const useCanContinue = (props) => {
  const {
    pageNumber,
    setPageNumber,
    owner,
    setError,
    setShowLoadingIcon,
    setUserEligibilityResult,
    userEligibilityResult,
    setAmcGuid,
    lineOfBusinessCode,
    policyId,
  } = props;
  const { formData, dispatchForm } = useFormContext();
  const { emailContactInput } = formData;

  const { user } = useUserState();

  const requestBody = makeUserDigitalRegistrationEligibilityRequestBody({
    owner,
    emailContactInput,
  });

  const hasEmailInputError = useCheckForEmailInputErrors();

  const onContinue = () => {
    const headers = {
      Authorization: `Bearer ${user.accessToken}`,
    };
    setError("");
    setShowLoadingIcon(true);

    fireTealiumEvent({
      label: "SVNT",
      action: `${lineOfBusinessCode}: Digital Registration Check Eligibility button clicked`,
      category: "Digital Registration",
      value: policyId,
      custId: user.email,
    });

    fetchUserDigitalRegistrationEligibility({
      headers,
      apiParams: requestBody,
    })
      .then((result) => {
        if (result?.data?.isEligible) {
          setPageNumber(pageNumber + 1);
          setAmcGuid(result?.data?.amcGuid);
          fireTealiumEvent({
            label: "SVNT",
            action: `${lineOfBusinessCode}: Digital Registration Eligibility check successful - client is eligible`,
            category: "Digital Registration",
            value: policyId,
            custId: user.email,
          });
        }
      })
      .catch((errorData) => {
        fireTealiumEvent({
          label: "SVNT",
          action: `${lineOfBusinessCode}: Digital Registration Eligibility check failed - client is not eligible`,
          category: "Digital Registration",
          value: policyId,
          custId: user.email,
        });

        const eligibilityErrorDisplayText = getDisplayTextForError(
          errorData?.response?.data?.errorCode,
          ""
        );
        setError(eligibilityErrorDisplayText);
        const eligibilityCheckResult = compareArrayOfStrings(
          [
            "identity_exists",
            "other_error",
            "customer_not_found",
            "customer_no_portal_access",
          ],
          errorData?.response?.data?.errorCode
        );
        setUserEligibilityResult(eligibilityCheckResult);
        if (eligibilityCheckResult) {
          dispatchForm({
            type: types.REPLACE_FIELD,
            formField: {
              ...emailContactInput,
              disabled: true,
            },
          });
        }
      })
      .finally(() => {
        setShowLoadingIcon(false);
      });
  };

  const shouldDisableContinue =
    hasEmailInputError === true
      ? hasEmailInputError
      : hasEmailInputError || userEligibilityResult;

  return {
    onContinue,
    shouldDisableContinue,
  };
};

export const useCheckForEmailInputErrors = () => {
  const { formData } = useFormContext();
  const { emailContactInput, emailContactConfirmInput } = formData;

  return (
    !isDefined(emailContactInput.value) ||
    isDefined(emailContactInput.errors[0]?.hasError) ||
    isDefined(emailContactConfirmInput.errors[0]?.hasError)
  );
};

export const useCanSubmit = () => {
  const hasAcknowledgementError = useCheckForAcknowledgementErrors();

  const { user } = useUserState();

  return {
    shouldDisableSubmit: hasAcknowledgementError || isHomeOfficeUser(user),
  };
};

export const useCheckForAcknowledgementErrors = () => {
  const { formData } = useFormContext();

  const { authAcknowledgement } = formData;

  return !authAcknowledgement.value;
};

export const useConfirmationProps = (props) => {
  const { lineOfBusinessCode } = props;
  const { agreementKey } = useParams();

  const { formData } = useFormContext();
  const { emailContactInput } = formData;

  const backToPolicyDisplayText = isAnnuityContract(lineOfBusinessCode)
    ? "Back to Contract"
    : "Back to Policy";

  return {
    agreementKey,
    backToPolicyDisplayText,
    userEmail: emailContactInput?.value,
  };
};

export const makeDirectLinksEmailRequestBody = (
  formData,
  user,
  producersRequest,
  agreementData,
  formattedOwnerName
) => {
  const { emailContactInput } = formData;
  const {
    agreementKeyPrimary,
    agreementKeyAdmin,
    agreementKeyPrefix,
    agreementKey,
    agreementCustomers,
  } = agreementData;
  const requestorName = `${user.firstName} ${user.lastName}`;
  const requestorEmail = user?.email;

  const { producers } = producersRequest?.data || {};
  const servicingAgent = getFirstServicingAgent({
    agents: producers,
  });
  const { homeAgencyId } = servicingAgent;

  const { bpId } = getBpIdFromUser(user);
  const receivedDateTime = formatDate(getCurrentDate());

  const lengthOfAgencyId = 3;
  const { isAnAgent, idNumber } = getBpIdFromUser(user);
  const orgUnit = isAnAgent
    ? getLastXChars(homeAgencyId, lengthOfAgencyId).padStart(
        lengthOfAgencyId,
        0
      )
    : "";

  const { requestorTypeCode } = getRequestorInfo({
    idNumber,
    producersData: producersRequest?.data,
    agreementKey,
  });
  const personInsured = getUserBySubType({
    type: userTypes.INSURED,
    subType: "PRMR",
    people: agreementCustomers,
  });
  const {
    firstName: insuredFirstName = "",
    lastName: insuredLastName = "",
    middleName: insuredMiddleName = "",
  } = personInsured || {};
  const requestHelper = {
    transactionName: "digitalRegistration",
  };

  const recipientEmail = emailContactInput.value;

  const longComment = `Contact Info: \r\n  Name: ${requestorName}\r\n  Method: E-mail\r\n  Detail: ${requestorEmail} \r\n\r\nOperator Name: ${requestorName} ID: ${idNumber} Org Unit: ${orgUnit}\r\n\r\nSent Pre-Registration Email\r\n\r\nRecipient Name:${formattedOwnerName}\r\n\r\nRecipient E-mail:${recipientEmail}`;

  const digitalRegistrationCATSData = {
    workEvent: "11190",
    divCode: "CS",
    deptCode: "SE",
    requestor: bpId.toString(),
    userId: "SERVNET",
    priorityCode: "10",
    requestorTypeCode,
    serviceChannelSourceCode: "19",
    lastUpdatedBy: "SVCNET2",
    loggedByIdent: "SVCNET2",
    completionInd: "Y",
    agreementNumber: agreementKeyPrimary,
    carrierAdminSystem: agreementKeyAdmin,
    agreementNumberPrefix: agreementKeyPrefix,
    agentId: bpId.toString(),
    receivedDate: receivedDateTime,
    insuredFirstName,
    insuredLastName,
    insuredMiddleName,
    shortComment: "Digital Registration",
    requestorName,
    longComment,
  };

  return {
    eventData: digitalRegistrationCATSData,
    requestHelper,
  };
};

export const makeUserDigitalRegistrationEligibilityRequestBody = (props) => {
  const { owner, emailContactInput } = props;

  const {
    firstName = "",
    lastName = "",
    middleName = "",
    maidenName = "",
    governmentId,
    birthDate,
  } = owner;

  const formattedBirthDate = formatDate(birthDate, "YYYY-MM-DD");
  return {
    middleName,
    firstName,
    maidenName,
    lastName,
    governmentId,
    dateOfBirth: formattedBirthDate,
    email: emailContactInput?.value,
  };
};

export const useCheckEligibility = (props) => {
  const { owner, setError, setUserEligibilityResult } = props;
  const { governmentId } = owner;

  const { formData, dispatchForm } = useFormContext();
  const { emailContactInput } = formData;
  useEffect(() => {
    if (!isDefined(governmentId)) {
      setError(USER_REGISTRATION_SSN_NOT_IN_FILE_MESSAGE);
      dispatchForm({
        type: types.REPLACE_FIELD,
        formField: {
          ...emailContactInput,
          disabled: true,
        },
      });
      setUserEligibilityResult(true);
    } else {
      setError("");
      setUserEligibilityResult(false);
    }
  }, [governmentId]);
};

export const makeRegisterUserRequestBody = (props) => {
  const { formData, amcGuid, owner, producersRequest } = props;
  const { emailContactInput } = formData;

  const { producers } = producersRequest?.data || {};
  const servicingAgent = getFirstServicingAgent({
    agents: producers,
  });

  return {
    registerUserData: {
      email: emailContactInput.value,
      amcGuid,
      registrationSource: "servicenet",
      suppressEmail: true,
    },
    emailData: {
      recipient: {
        firstName: nameCase(owner.firstName, { lazy: false }),
        email: emailContactInput.value,
      },
      sender: {
        name: formatName({
          nameObject: servicingAgent,
          format: "firstNameDisplayedFirst",
        }),
        email: servicingAgent.emailAddress,
      },
      copyrightYear: new Date().getFullYear().toString(),
    },
  };
};
