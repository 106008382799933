import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import IsTruthy from "../../../common/logic/isTruthy";
import { formatName } from "../../../../utils/format";

const NotificationsDropdownItem = (props) => {
  const { notification, index } = props;

  const getDaysOldText = (objNotification) => {
    if (objNotification.daysOld === 0) {
      return "Created Today";
    }
    if (objNotification.daysOld === 1) {
      return `${objNotification.daysOld} day ago`;
    }
    return `${objNotification.daysOld} days ago`;
  };
  return (
    <Dropdown.Item
      as="div"
      id={`notification_dropdown_event_${index}`}
      key={`notification_dropdown_event_${index}`}
    >
      <div className="row">
        <div className="col-8 align-items-center">
          <IsTruthy value={notification.isNew}>
            <span
              id={`new_notification_indicator_${index}`}
              className="badge badge-indicator"
              data-testid="new_notification_indicator"
            />
          </IsTruthy>

          <p className="mm-spacing--none">
            <a
              href={`/policyDetails/${encodeURIComponent(
                notification.agreementKey
              )}/viewServiceHistory`}
              id={`notification_modal_link_${index}`}
            >
              {" "}
              <span>
                {" "}
                <strong>{notification.eventName}</strong>
                <br />
                {formatName({
                  nameObject: {
                    fullName: notification.insuredName,
                  },
                  format: "fullNameThenFirstName",
                  defaultReturn: "-",
                })}
              </span>
            </a>
            <br />
            <a
              href={`/policyDetails/${encodeURIComponent(
                notification.agreementKey
              )}`}
              id={`notification_pdp_link_${index}`}
            >
              <strong className="copy-sm">
                Policy {notification.policyNumber}
              </strong>
            </a>
          </p>
        </div>
        <div className="col-4 align-items-center justify-content-center">
          <div>
            <IsTruthy value={notification.isPriority}>
              <span
                id={`notification_high_priority_badge_${index}`}
                className="badge badge-warning"
              >
                High Priority
              </span>
            </IsTruthy>
            <p
              className="caption mm-text-center mm-spacing--none"
              id={`notification_age_indicator_${index}`}
            >
              {getDaysOldText(notification)}
            </p>
          </div>
        </div>
      </div>
    </Dropdown.Item>
  );
};

export default React.memo(NotificationsDropdownItem);
