import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useDeepCompareEffect from "use-deep-compare-effect";
import cloneDeep from "lodash.clonedeep";
import { searchRows } from "../../../../utils/search";
import ServiceHistoryModalCell from "./serviceHistoryModalCell";
import { getCurrentDate } from "../../../../utils/dates";
import { useMakeRequest } from "../../../../hooks/api";
import { getUserBySubType, userTypes } from "../../../../utils/findData";
import { formatName, nameFormattingOptions } from "../../../../utils/format";
import { compareStrings } from "../../../../utils/string";
import { isAnnuityContract } from "../../../../utils/policies";
import { useUserState } from "../../../../contexts/user";
import { AGREEMENT_NOT_FOUND } from "../../../../constants/ui";
import { getJointOwnerInfo } from "../../hooks";
import { useAddOrRemoveChatLauncher } from "../../../../utils/genesysChatClient";

export const useSearchTable = (props) => {
  const {
    searchStringDefault = "",
    showClearIconDefault = false,
    columns = [],
    searchColumns = [],
    agreementKey = "",
    agreementRequest,
  } = props;
  const [searchString, setSearchString] = useState(searchStringDefault);
  const searchTable = ({ target: { value } }) => setSearchString(value);
  const clearSearch = () => setSearchString("");

  const [showClearIcon, setShowClearIcon] = useState(showClearIconDefault);

  const { data, executeRequest, isLoading } = useMakeRequest({
    apiRequest: "fetchCatsEvents",
    apiParams: { agreementKey },
    immediateRequest: false,
    dataSelector: "processServiceHistoryData",
    defaultReturn: [],
  });

  const { isUnauthorized, messages } = agreementRequest?.data || {};

  const validRequest =
    !isUnauthorized &&
    compareStrings(messages.type, "SUCCESS") &&
    !compareStrings(messages.description, AGREEMENT_NOT_FOUND);

  useEffect(() => {
    if (validRequest) {
      executeRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUnauthorized, messages.type]);

  const { eventsArray } = data;

  const formattedEventsArray = eventsArray?.map((event) => {
    const { status, completionDate } = event;
    let formattedCompletionDate = completionDate;
    if (compareStrings(status, "pending")) {
      formattedCompletionDate = "-";
    }

    return {
      ...event,
      formattedCompletionDate,
    };
  });
  const rows = formattedEventsArray || [];

  const [displayedRows, setDisplayedRows] = useState(rows);

  useDeepCompareEffect(() => {
    const searchedRows = searchRows({
      searchString,
      initialRows: cloneDeep(rows),
      columns,
      searchColumns,
    });
    setDisplayedRows(searchedRows);
    setShowClearIcon(searchString.length > 0);
  }, [rows, searchString]);

  return {
    searchBar: {
      searchString,
      showClearIcon,
      searchTable,
      clearSearch,
    },
    table: {
      columns,
      rows: displayedRows,
    },
    catsRequest: {
      data,
    },
    validRequest,
    isLoading,
  };
};

export const useSearchHistoryProps = (props) => {
  const currentDate = getCurrentDate("short");

  const { agreementKey } = useParams();

  const agreementRequest = useMakeRequest({
    apiRequest: "fetchAgreementData",
    apiParams: { agreementKey },
    immediateRequest: true,
    dataSelector: "processAgreementRequest",
  });

  const agreementData = agreementRequest?.data;

  const { policyId, isUnauthorized, lineOfBusinessCode } =
    agreementRequest?.data || {};

  const { agreementCustomers } = agreementRequest?.data || {};

  const personInsured = isAnnuityContract(
    agreementRequest.data.lineOfBusinessCode
  )
    ? getUserBySubType({
        type: userTypes.OWNER,
        subType: "LIST",
        people: agreementCustomers,
      })
    : getUserBySubType({
        type: userTypes.INSURED,
        subType: "PRMR",
        people: agreementCustomers,
      });
  const insuredName = personInsured?.fullName
    ? formatName({
        nameObject: personInsured,
        format: nameFormattingOptions.firstNameDisplayedFirst,
      })
    : "";
  const backButtonText = compareStrings(lineOfBusinessCode, "ANN")
    ? "Back to Contract"
    : "Back to Policy";
  const insuredNameLabel = compareStrings(lineOfBusinessCode, "ANN")
    ? "Owner Name"
    : "Insured Name";

  const { jointOwnerNameLabel, jointOwnerName, displayJointOwner } =
    getJointOwnerInfo({ agreementCustomers, lineOfBusinessCode });
  const policyNoLabel = compareStrings(lineOfBusinessCode, "ANN")
    ? "Contract Number"
    : "Policy Number";
  const columns = [
    {
      accessor: "eventName",
      label: "Request",
      alignment: "left",
      CustomCell: ServiceHistoryModalCell,
      sortable: "String",
    },
    {
      accessor: "status",
      label: "Status",
      alignment: "left",
      sortable: "String",
    },
    {
      accessor: "eventDate",
      label: "Date Received",
      alignment: "left",
      sortable: "Date",
    },
    {
      accessor: "serviceChannel",
      label: "Channel",
      alignment: "left",
      sortable: "String",
    },
    {
      accessor: "formattedCompletionDate",
      label: "Complete Date",
      alignment: "left",
      sortable: "Date",
    },
  ];
  const searchColumns = [
    "eventName",
    "status",
    "eventDate",
    "serviceChannel",
    "completionDate",
  ];

  const searchTableProps = {
    ...props,
    columns,
    searchColumns,
    agreementKey,
    agreementRequest,
  };
  const { searchBar, table, catsRequest, validRequest, isLoading } =
    useSearchTable(searchTableProps);

  const {
    user: { email },
  } = useUserState();

  useAddOrRemoveChatLauncher({ agreementData, page: "Service History page" });

  return {
    requests: { agreementRequest, catsRequest },
    searchBar,
    table,
    currentDate,
    policyId,
    agreementKey,
    insuredName,
    isUnauthorized,
    insuredNameLabel,
    jointOwnerNameLabel,
    jointOwnerName,
    displayJointOwner,
    policyNoLabel,
    email,
    validRequest,
    isLoading,
    backButtonText,
  };
};
