import React, { useCallback, useState, useContext, createContext } from "react";
import { Modal } from "react-bootstrap";
import { isTruthy } from "../utils/evaluate";

const ModalContext = createContext(null);

// TODO: add more documentation
const ModalProvider = (props) => {
  const { children } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [modalProps, setModalProps] = useState({});

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const values = {
    isOpen,
    openModal: useCallback((newModalProps) => {
      setModalProps(newModalProps);
      setIsOpen(true);
    }, []),
    closeModal: useCallback(() => setIsOpen(false), []),
  };

  const modal = isTruthy(isOpen) && (
    <GlobalModal
      isOpen={isOpen}
      closeModal={values.closeModal}
      {...modalProps}
    />
  );
  return (
    <ModalContext.Provider value={values}>
      <>
        {children}
        {modal}
      </>
    </ModalContext.Provider>
  );
};

const GlobalModal = (props) => {
  const {
    title,
    closeModal,
    isOpen,
    Component,
    componentProps,
    useHook = () => ({}),
    modalSize,
  } = props;
  const customModalStyle = {
    zIndex: 100001, // Navbar z index is set to 10000
  };

  const hookData = useHook(componentProps);
  const component = Component && (
    <Component {...componentProps} {...hookData} />
  );

  const defaultHeader = (
    <Modal.Header close="true">
      <h2 className="modal-title">{title}</h2>
      <button
        id="close_modal_btn"
        type="button"
        onClick={closeModal}
        className="close"
      >
        <span className="sr-only">Close</span>
      </button>
    </Modal.Header>
  );
  const modalHeader = defaultHeader; // CustomHeader ? <CustomHeader /> : defaultHeader;

  return (
    <Modal
      size={modalSize}
      style={customModalStyle}
      show={isOpen}
      onHide={closeModal}
      close="true"
    >
      {modalHeader}
      {component}
    </Modal>
  );
};

const useModalState = () => useContext(ModalContext);

export { ModalProvider, useModalState };
