import React from "react";
import Choices from "./choices";
import { useSelectDirectLinksProps } from "./hooks";

const SelectDirectLinks = (props) => {
  const { formData, lineOfBusinessCode } = useSelectDirectLinksProps(props);
  return (
    <div className="mm-spacing">
      <h1 id="header-send-directlink">Send Direct Link to MassMutual.com</h1>
      <p className="mm-spacing" id="note-send-directlink">
        Select the Direct Link(s) that you would like to email to the customer.
        All direct links will be sent in one email.
      </p>
      <Choices formData={formData} lineOfBusinessCode={lineOfBusinessCode} />
    </div>
  );
};

export default SelectDirectLinks;
