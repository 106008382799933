import React from "react";
import { isDefined } from "../../../utils/evaluate";
import { positionCell } from "../../../utils/table";
import CustomComponent from "../logic/customComponent";

const Cell = (props) => {
  const {
    row,
    rowIndex,
    column: { accessor, CustomCell, alignment, dataCell, sectionAbbreviation },
    cellIndex,
  } = props;

  const cellIdValue = isDefined(sectionAbbreviation)
    ? `${sectionAbbreviation}_cell_${rowIndex}_${accessor}`
    : `cell_${rowIndex}_${accessor}`;

  const position = positionCell({ alignment });
  const content = (
    <CustomComponent component={CustomCell} {...props}>
      <span
        id={`${cellIdValue}_value`}
        dangerouslySetInnerHTML={{ __html: row[accessor] }}
      />
    </CustomComponent>
  );

  if (cellIndex === 0 && !dataCell) {
    return (
      <th id={cellIdValue} scope="row">
        {content}
      </th>
    );
  }
  return (
    <td id={cellIdValue} className={`${position}`}>
      {content}
    </td>
  );
};

export default React.memo(Cell);
