import React from "react";
import IsTruthy from "../common/logic/isTruthy";
import Stepper from "../common/forms/stepper";
import { TRANSACTIONS_DISABLED_MESSAGE } from "../../constants/ui";
import LdBannerContent from "../common/molecules/ldBannerContent";

const NewLoanHeader = (props) => {
  const { pageNumber, previousPage, shouldDisableSubmit } = props;
  return (
    <>
      <nav role="navigation" className="mm-navbar--transaction">
        <div className="mm-logo" />
        <IsTruthy value={pageNumber < 6 && pageNumber > 0}>
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <Stepper totalSteps={5} currentStep={pageNumber} />
            </div>
          </div>
        </IsTruthy>
      </nav>
      <IsTruthy value={pageNumber > 1 && pageNumber < 6}>
        <button
          id="go_back_btn"
          type="button"
          className="btn btn-link mm-spacing--minor"
          onClick={previousPage}
        >
          <span className="icon-arrow-circle-spin-left" />
          <span>Previous Step</span>
        </button>
      </IsTruthy>
      <IsTruthy value={pageNumber < 6 && pageNumber > 0}>
        <p className="eyebrow">LOAN APPLICATION: STEP {pageNumber} of 5</p>
        <LdBannerContent />
        <IsTruthy value={shouldDisableSubmit}>
          <div className="alert alert-danger" role="alert">
            {TRANSACTIONS_DISABLED_MESSAGE}
          </div>
        </IsTruthy>
      </IsTruthy>
    </>
  );
};

export default NewLoanHeader;
