import { useRef, useEffect } from "react";
import isEqual from "lodash.isequal";

function useDidUpdateEffect(fn, inputs) {
  const initialInputs = useRef(inputs);

  useEffect(() => {
    const IS_NOT_LAST_INPUTS = !isEqual(initialInputs.current, inputs);

    if (IS_NOT_LAST_INPUTS) {
      initialInputs.current = inputs;
      fn();
    }
  }, [fn, inputs]);
}

export default useDidUpdateEffect;
