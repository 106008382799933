import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { setTitle } from "../../../../utils/dom";
import Table from "../../../common/table";
import { useCashValuesProps } from "./hooks";
import LoadingSection, {
  errorTypes,
  loadingTypes,
} from "../../../common/loading";
import { mecIndicatorText } from "../../types/life/hooks";
import IsTruthy from "../../../common/logic/isTruthy";
import IsFalsy from "../../../common/logic/isFalsy";
import { compareStrings } from "../../../../utils/string";
import FourHundredFourError from "../../../common/errors/404";
import { useHidePageFromUser } from "../../../common/featureFlags/hooks";
import FiveHundredOneError from "../../../common/errors/501";
import { tealiumPageViewEvent } from "../../../../utils/tealium";
import BackToPolicy from "../../../common/backToPolicy";

const CashValues = () => {
  setTitle("ServiceNet - Cash Values");
  const { agreementKey } = useParams();
  const {
    ACCESS_DENIED,
    ENVISION_POLICY,
    requests: { agreementRequest, producersRequest },
    policyId,
    table: {
      columns,
      cashValueTableData,
      latestMonthTableColumns,
      latestMonthTableData,
    },
    insuredName,
    email,
    validRequest,
    latestMonthTableCustomFunctions,
    isPolicyNotFound,
  } = useCashValuesProps();
  const {
    mecIndicator,
    agreementKeyAdmin,
    productTypeCode,
    majorProductCode,
    minorProductCode,
  } = agreementRequest.data;
  const hideCashValuePage = useHidePageFromUser({
    pageName: "cashValuePage",
    agreementKeyAdmin,
    productTypeCode,
    majorProductCode,
    minorProductCode,
  });

  useEffect(() => {
    tealiumPageViewEvent({
      page: "Cash Values",
      category: agreementKey,
      custId: email,
    });
  }, []);

  if (ACCESS_DENIED) {
    return <FiveHundredOneError />;
  }

  if (ENVISION_POLICY || validRequest || isPolicyNotFound) {
    return <FourHundredFourError />;
  }

  return (
    <>
      <IsTruthy value={hideCashValuePage}>
        <LoadingSection
          request={agreementRequest}
          loadingType={loadingTypes.PARAGRAPH}
          errorType={errorTypes._500}
        >
          <FourHundredFourError />
        </LoadingSection>
      </IsTruthy>
      <IsFalsy value={hideCashValuePage}>
        <LoadingSection
          request={agreementRequest}
          loadingType={loadingTypes.PARAGRAPH}
          errorType={errorTypes._500}
        >
          <LoadingSection
            request={producersRequest}
            loadingType={loadingTypes.PARAGRAPH}
            errorType={errorTypes.CHILDREN}
          >
            <BackToPolicy agreementData={agreementRequest.data} />
            <h1 className="mm-spacing"> Cash Values </h1>
            <div className="row mm-spacing">
              <div className="col-md-6 col-sm-12">
                <dl className="mm-key-value-pair--summation">
                  <dt id="insured_name_lbl">Insured Name</dt>
                  <dd id="insured_name_value">{insuredName}</dd>
                  <dt id="policy_id_lbl">Policy Number</dt>
                  <dd id="policy_id_value">{policyId}</dd>
                </dl>
              </div>
            </div>
            <IsTruthy
              value={mecIndicator}
              validationFunction={(val) => compareStrings(val, "Yes")}
            >
              <div
                id="cash_value_mec_indicator_text"
                className="alert alert-info mm-spacing"
                role="alert"
              >
                {mecIndicatorText}
              </div>
            </IsTruthy>
            <h4>Current Cash Value</h4>
            <div className="row">
              <div className="col-sm">
                <Table
                  columns={latestMonthTableColumns}
                  rows={latestMonthTableData}
                  showPagination={false}
                  customFunctions={latestMonthTableCustomFunctions}
                />
              </div>
            </div>
            <h4>Historical Cash Value</h4>
            <div className="row">
              <div className="col-sm">
                <Table
                  columns={columns}
                  rows={cashValueTableData}
                  showPagination={false}
                />
              </div>
            </div>
          </LoadingSection>
        </LoadingSection>
      </IsFalsy>
    </>
  );
};
export default React.memo(CashValues);
