import React from "react";

const CreateNameList = ({ list }) => {
  if (list?.length > 0) {
    return list.map((person) => {
      const { fullName } = person;
      return (
        <div key={fullName}>
          {fullName}
          <br />
        </div>
      );
    });
  }
  return "-";
};

export default CreateNameList;
