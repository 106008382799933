import React from "react";
import IsTruthy from "../../../../common/logic/isTruthy";
import { isTruthy } from "../../../../../utils/evaluate";
import IsFalsy from "../../../../common/logic/isFalsy";
import { useAnnuityContractInfoProps } from "../hooks";
import { formatSupTagIfPresent } from "../../../../../utils/format";

const AnnuityContractInfo = (props) => {
  const {
    data: {
      productTypeName,
      servicingAgent,
      servicingAgentName,
      issueDate,
      insuredPeople,
      contractValue,
      annuityDate,
      totalPurchasePaymentAmount,
      isRetireEaseContractResult,
    },
  } = useAnnuityContractInfoProps(props);
  const insuredList = insuredPeople.map((person, index) => {
    const { fullName } = person;
    return (
      <div id={`annuitant_name_${index}`} key={fullName}>
        {fullName}
        <br />
      </div>
    );
  });

  return (
    <div>
      <dl className="mm-key-value-pair--summation">
        <dt id="annuitant_name_lbl">Annuitant Name</dt>
        <dd id="annuitant_name_value">
          <IsTruthy value={insuredList.length > 0}>{insuredList}</IsTruthy>
          <IsTruthy value={insuredList.length === 0}>-</IsTruthy>
        </dd>
        <dt className="hide-print" id="product_name_lbl">
          Product Name
        </dt>
        <dd className="hide-print" id="product_name_value">
          {formatSupTagIfPresent(productTypeName)}
        </dd>
        <dt className="hide-print" id="servicing_agent_name_lbl">
          Servicing Agent
        </dt>
        <dd className="hide-print" id="servicing_agent_name_value">
          <IsTruthy
            value={servicingAgent.validAgent}
            validationFunction={isTruthy}
          >
            {servicingAgentName}
          </IsTruthy>
          <IsFalsy
            value={servicingAgent.validAgent}
            validationFunction={isTruthy}
          >
            -
          </IsFalsy>
        </dd>
        <IsFalsy value={isRetireEaseContractResult}>
          <dt className="hide-print" id="contract_value_lbl">
            Contract Value
          </dt>
          <dd className="hide-print" id="contract_value_value">
            {contractValue}
          </dd>
        </IsFalsy>
        <dt className="hide-print" id="issue_date_display_title_lbl">
          Issue Date
        </dt>
        <dd className="hide-print" id="issue_date_display_value">
          {issueDate}
        </dd>
        <dt className="hide-print" id="annuity_date_display_title_lbl">
          Annuity Date
        </dt>
        <dd className="hide-print" id="annuity_date_display_value">
          {annuityDate}
        </dd>
        <dt className="hide-print" id="net_premium_amount_title_lbl">
          Total Purchase Payment(s)
        </dt>
        <dd className="hide-print" id="net_premium_amount_value">
          {totalPurchasePaymentAmount}
        </dd>
      </dl>
    </div>
  );
};

export default React.memo(AnnuityContractInfo);
