import React from "react";
import { compareStrings } from "../../../utils/string";
import ChangeAddressLink from "../../home/changeAddressLink";
import IsFalsy from "../logic/isFalsy";
import IsTruthy from "../logic/isTruthy";
import { useCustomerInfo } from "./hooks";
import { isDefined } from "../../../utils/evaluate";
import UserRegisteredSignifier from "../userRegisteredSignifier/userRegisteredSignifier";

const CustomerInfo = (props) => {
  const { agreementData, billingData, annuityData } = props;
  const {
    changeAddressProps,
    attnLine,
    addrFirstLine,
    addrSecondLine,
    addrThirdLine,
    addrFourthLine,
    email,
    lastFourSSN,
    ageAndDOB,
    phone,
    trimmedPolicyId,
    isPallm,
    hasAddressFirstandFourthLineAvailable,
    customer,
    lineOfBusinessCode,
  } = useCustomerInfo(props);

  return (
    <div>
      <dl className="mm-key-value-pair--summation">
        <dt id="user_phone_number_lbl">Phone</dt>
        <dd id="user_phone_number_value">
          <IsFalsy
            value={phone}
            validationFunction={(val) => compareStrings(val, "-")}
          >
            <a href={`tel:${phone}`} id="user_phone_number_link">
              {phone}
            </a>
          </IsFalsy>
          <IsTruthy
            value={phone}
            validationFunction={(val) => compareStrings(val, "-")}
          >
            {phone}
          </IsTruthy>
        </dd>
        <dt id="user_email_lbl">Email</dt>
        <dd id="user_email_value">
          <IsFalsy
            value={email}
            validationFunction={(val) => compareStrings(val, "-")}
          >
            <a href={`mailto:${email}`} id="user_email_link">
              {email}
            </a>
          </IsFalsy>
          <IsTruthy
            value={email}
            validationFunction={(val) => compareStrings(val, "-")}
          >
            {email}
          </IsTruthy>
        </dd>
        <dt id="user_registration_status_lbl">Registration Status</dt>
        <dd id="user_registration_status_value">
          <UserRegisteredSignifier
            customer={customer}
            isCalledFrom="drawer"
            policyId={trimmedPolicyId}
            agreementData={agreementData}
            lineOfBusinessCode={lineOfBusinessCode}
          />
        </dd>
        <dt id="user_dob_lbl">Date of Birth</dt>
        <dd id="user_dob_value">{ageAndDOB}</dd>
        <dt id="user_ssn_lbl">Last 4 SSN</dt>
        <dd id="user_ssn_value">{lastFourSSN}</dd>
        <dt id="user_address_lbl">Address</dt>
        <dd id="user_address_value">
          <IsTruthy value={isDefined(attnLine)}>
            <span id="user_attention_line_address_value">{attnLine}</span>
            <br />
          </IsTruthy>
          <span id="user_first_line_address_value">{addrFirstLine}</span>
          <IsTruthy value={isDefined(addrSecondLine)}>
            <br />
            <span id="user_second_line_address_value">{addrSecondLine}</span>
          </IsTruthy>
          <IsTruthy value={isDefined(addrThirdLine)}>
            <br />
            <span id="user_third_line_address_value">{addrThirdLine}</span>
          </IsTruthy>
          <IsTruthy value={isDefined(addrFourthLine)}>
            <br />
            <span id="user_fourth_line_address_value">{addrFourthLine}</span>
          </IsTruthy>
          <br />
          <IsFalsy value={isPallm}>
            <IsTruthy
              value={
                hasAddressFirstandFourthLineAvailable &&
                !changeAddressProps.unavailable &&
                changeAddressProps.isTransactionAllowed
              }
            >
              <ChangeAddressLink
                data={agreementData}
                policyId={trimmedPolicyId}
                userEmail={email}
                isCalledFrom="drawer"
                target="_blank"
                billingData={billingData}
                annuityData={annuityData}
              />
            </IsTruthy>
          </IsFalsy>
        </dd>
      </dl>
    </div>
  );
};

export default React.memo(CustomerInfo);
