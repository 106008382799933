import React from "react";
import { isDefined } from "../../utils/evaluate";
import IsTruthy from "../common/logic/isTruthy";
import {
  useCanContinue,
  useCanGoToPACForm,
  useModalControlProps,
} from "./hooks";
import { useFormContext } from "../../contexts/form";
import { useCancelModal } from "../common/popup/cancel";

const ModalControl = (props) => {
  const { pageNumber, onSubmit, shouldDisableSubmit, error } = props;
  const { onContinue } = useCanContinue(props);
  const { onClickPACForm } = useCanGoToPACForm(props);
  const { openCancelModal } = useCancelModal();
  const { formData } = useFormContext();

  const { isPacSelected } = useModalControlProps(props);

  return (
    <div className="mm-spacing">
      <IsTruthy value={error} validationFunction={isDefined}>
        {" "}
        <div
          className="alert alert-danger mm-spacing--minor"
          role="alert"
          aria-label={error}
        >
          {error}
        </div>
      </IsTruthy>
      <div className="mm-spacing--minor">
        <IsTruthy value={pageNumber < 2 && !isPacSelected}>
          <button
            onClick={onContinue}
            type="button"
            className="btn btn-primary"
          >
            continue
          </button>
        </IsTruthy>
        <IsTruthy value={pageNumber < 2 && isPacSelected}>
          <button
            onClick={onClickPACForm}
            type="button"
            className="btn btn-primary"
          >
            GO TO FORM
          </button>
        </IsTruthy>
        <IsTruthy value={pageNumber === 2}>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={shouldDisableSubmit}
            onClick={(e) => {
              e.preventDefault();
              onSubmit(formData);
            }}
          >
            Submit
          </button>
        </IsTruthy>
        <IsTruthy value={pageNumber < 3}>
          <button
            type="button"
            className="btn btn-link"
            onClick={openCancelModal}
          >
            Cancel
          </button>
        </IsTruthy>
      </div>
    </div>
  );
};

export default ModalControl;
