import React from "react";
import { scrollToElement } from "../../../utils/dom";
import useDidUpdateEffect from "../../../hooks/useDidUpdateEffect";

const Pagination = (props) => {
  const {
    pagination: { currentPage, totalPages, inputtedPage },
    nextPage,
    previousPage,
    setInputtedPage,
    id,
  } = props;

  useDidUpdateEffect(() => {
    scrollToElement(`table-${id}`);
  }, [currentPage, id]);

  const SHOW_PREVIOUS_PAGE_ICON = currentPage > 1;
  const SHOW_NEXT_PAGE_ICON = currentPage < totalPages;
  const previousPageIcon = SHOW_PREVIOUS_PAGE_ICON && (
    <button
      className="btn btn-link"
      type="button"
      aria-label="Previous"
      onClick={previousPage}
      id={`table-${id}-previous-pagebutton`}
    >
      <span className="prev" />
      <span className="sr-only">Previous Page</span>
    </button>
  );
  const nextPageIcon = SHOW_NEXT_PAGE_ICON && (
    <button
      className="btn btn-link"
      type="button"
      aria-label="Next"
      onClick={nextPage}
      id={`table-${id}-next-pagebutton`}
    >
      <span className="next" />
      <span className="sr-only">Next Page</span>
    </button>
  );
  const preventDefaultReturn = (e) => {
    if (e.key === "Enter" || e.key === " ") {
      e.preventDefault();
    }
  };
  const inputFieldSize = totalPages.toString().length;

  return (
    <div className="pagination--control justify-content-end">
      <form className="form-inline" aria-live="polite">
        {previousPageIcon}
        <span className="form-text" id={`table-${id}-pagelabel`}>
          Page
        </span>
        <input
          className="form-control"
          size={inputFieldSize}
          aria-placeholder="Page Input"
          aria-label="Current Page"
          type="text"
          value={inputtedPage}
          onChange={setInputtedPage}
          onKeyDown={setInputtedPage}
          onKeyPress={preventDefaultReturn}
          id={`table-${id}-currentpage`}
        />
        <span id={`table-${id}-totalPages`} className="form-text">
          of {totalPages}
        </span>
        {nextPageIcon}
      </form>
    </div>
  );
};
export default React.memo(Pagination);
