import {
  isNotFoundPolicy,
  isUnauthorizedPolicy,
} from "../../utils/errorChecking";
import { isCoverpathPolicy } from "../../utils/policies";
// eslint-disable-next-line import/prefer-default-export
export const processAgreementRequest = (props = { response: {} }) => {
  const apiData = props?.response?.data;
  const agreement = apiData?.agreements?.[0] || {};
  const messages = apiData?.messages?.[0] || {};
  const { description = "", code = "", type = "" } = messages;
  const isUnauthorized = isUnauthorizedPolicy(description, code);
  const isPolicyNotFound = isNotFoundPolicy(description, code);
  const {
    agreementCoverages = [],
    agreementCustomers = [],
    agreementFunds = [],
    agreementKey = "",
    agreementKeyAdmin = "",
    agreementKeyPrefix = "",
    agreementKeySuffix = "",
    agreementKeyPrimary = "",
    agreementKeyPrimaryDisplay = "",
    alirCurrentDividendEarnedDivdAmount = "",
    alirCurrentDividendEarnedPremAmount = "",
    alirDividendAvailableAmount = "",
    alirDivOptPaidUpAddsAmount = "",
    alirTotPaidUpAddsCashValAmount = "",
    anniversaryDate = "",
    annualPaymentAmount = "",
    annualPremiumFinanceIndicator = "",
    asOfDate = "",
    autoPremiumLoanIndicator = "",
    basicAmount = "",
    carrierCode = "",
    cashSurrenderAmount = "",
    cashValuePaidUpDividendAmount = "",
    contactUsNumber = "1-800-272-2216",
    contactUsTime = "Mon - Fri 8 a.m. - 8 p.m. ET",
    conversionEndDate = "",
    convertibleIndicator = "",
    currentAdditionsPurchasedAmount = "",
    currentDividendAmount = "",
    currentDividendType = "",
    currentDividendTypeDescription = "",
    currentDividendYear = "",
    currentPayoffBalanceAsOfDate = "",
    deathBenefitAmount = "",
    deathBenefitOption = "",
    discountPercent1 = "",
    dividendAccumAvailableAmount = "",
    dividendAvailableAmount = "",
    dividendPaidUpAddsCashValAmount = "",
    effectiveDate = "",
    faceAmount = "",
    fairMarketValue = "",
    freeWithdrawalProvision = "",
    fundBalanceAmount = "",
    guaranteedMinimumDeathBenefitAmount = "",
    guaranteePeriodEndingIndicator = "",
    groupAccountId = "",
    homeOfficeAgreementSuspenseInd = "",
    inflationProtectionOption = "",
    issueDate = "",
    jurisdictionState = "",
    kindCode = "",
    lineOfBusinessCode = "",
    lineOfBusinessName = "",
    lisrDividendCashValueAmount = "",
    lisrDivOptPaidUpAddsAmount = "",
    loanInterestAmount = "",
    loanInterestAmountDue = "",
    loanInterestDueDate = "",
    loanInterestRate = "",
    loanInterestType = "",
    loanPayoffAmount = "",
    loanPrincipal = "",
    ltcAdvanceBasePaidUpAddsIndicator = "",
    ltcBenefitPoolAmount = "",
    ltcCurrentMaxMonthlyBenefitAmount = "",
    ltcDividendBenefitPoolAmount = "",
    ltcirReduceAdditionsAmount = "",
    ltcirReturnPremiumAmount = "",
    ltcMaxMonthlyBenefitIncOptIndicator = "",
    ltcResidualDeathBenefitAmount = "",
    majorProductCode = "",
    majorProductName = "",
    marketingTypeDescription = "",
    maxFixedAccountTransferValue = "",
    maximumAvailableLoanAmount = "",
    maxPartialSurrenderValue = "",
    mecIndicator = "",
    minimumGuaranteedInterestRate = "",
    minorProductCode = "",
    minorProductName = "",
    monthlyBenefitMaximumAmount = "",
    netAnnualPremiumAmount = "",
    netDeathBenefitAmount = "",
    netDeathBenefitAmtDisplay = "Unavailable",
    netPremiumAmount = "",
    netSurrenderAmount = "",
    netSurrenderValueMonth10Amount = "",
    netSurrenderValueMonth11Amount = "",
    netSurrenderValueMonth12Amount = "",
    netSurrenderValueMonth1Amount = "",
    netSurrenderValueMonth2Amount = "",
    netSurrenderValueMonth3Amount = "",
    netSurrenderValueMonth4Amount = "",
    netSurrenderValueMonth5Amount = "",
    netSurrenderValueMonth6Amount = "",
    netSurrenderValueMonth7Amount = "",
    netSurrenderValueMonth8Amount = "",
    netSurrenderValueMonth9Amount = "",
    nonForfeitureOptionCode = "",
    paidToDate = "",
    paidUpAdditionsFaceAmount = "",
    paidUpAddsAvailableAmount = "",
    partialSurrenderAmtDisplay = "",
    paymentAmount = "",
    paymentModeDescShort = "",
    payoffBalance = "",
    pendingSettlementIndicator = "",
    productTypeCode = "",
    productTypeName = "",
    qualifiedIndicator = "",
    requestedMaturityDate = "",
    restrictionCode = "",
    settlementDividendAmount = "",
    specialCondition2Code = "",
    specialConditionCode = "",
    status = "",
    statusReason = "",
    surrenderChargeAmount = "",
    termDate = "",
    totalAccountValue = "",
    totalAvailableWithdrawalAmount = "",
    totalCashValueAmount = "",
    transactionDeepLinks = [],
    ultimateFaceAmount = "",
    unearnedPremiumPaymentAmount = "",
    valuationMonth10Date = "",
    valuationMonth11Date = "",
    valuationMonth12Date = "",
    valuationMonth1Date = "",
    valuationMonth2Date = "",
    valuationMonth3Date = "",
    valuationMonth4Date = "",
    valuationMonth5Date = "",
    valuationMonth6Date = "",
    valuationMonth7Date = "",
    valuationMonth8Date = "",
    valuationMonth9Date = "",
    activelyAtWorkIndicator = "",
    rateCode = "",
    restrictionCodeReason = "",
    requestAddressChangeIndicator = "",
    requestManageBeneficiaryIndicator = "",
    requestCashValueQuoteIndicator = "",
    requestCashValueQuoteHistoryIndicator = "",
    requestLoanIndicator = "",
    requestNameChangeIndicator = "",
    requestOwnershipChangeIndicator = "",
    requestPayABillIndicator = "",
    requestPolicyVerificationIndicator = "",
    requestTransactionStatusIndicator = "",
    requestDIClaimIndicator = "",
    requestFileDIClaimIndicator = "",
    DIClaimIndicator = "",
  } = agreement;
  const { memberGUID = "" } = agreementCustomers?.[0] || {};
  return {
    messages: { description, code, type },
    agreementCustomers,
    agreementCoverages,
    agreementFunds,
    agreementKey,
    agreementKeyAdmin,
    agreementKeyPrefix,
    agreementKeySuffix,
    agreementKeyPrimary,
    alirCurrentDividendEarnedDivdAmount,
    alirCurrentDividendEarnedPremAmount,
    alirDividendAvailableAmount,
    alirDivOptPaidUpAddsAmount,
    alirTotPaidUpAddsCashValAmount,
    anniversaryDate,
    annualPaymentAmount,
    annualPremiumFinanceIndicator,
    asOfDate,
    autoPremiumLoanIndicator,
    basicAmount,
    carrierCode,
    cashSurrenderAmount,
    cashValuePaidUpDividendAmount,
    contactUsNumber,
    contactUsTime,
    conversionEndDate,
    convertibleIndicator,
    currentAdditionsPurchasedAmount,
    currentDividendAmount,
    currentDividendType,
    currentDividendTypeDescription,
    currentDividendYear,
    currentPayoffBalanceAsOfDate,
    deathBenefitAmount,
    deathBenefitOption,
    discountPercent1,
    dividendAccumAvailableAmount,
    dividendAvailableAmount,
    dividendPaidUpAddsCashValAmount,
    effectiveDate,
    faceAmount,
    fairMarketValue,
    freeWithdrawalProvision,
    fundBalanceAmount,
    guaranteedMinimumDeathBenefitAmount,
    guaranteePeriodEndingIndicator,
    groupAccountId,
    homeOfficeAgreementSuspenseInd,
    inflationProtectionOption,
    issueDate,
    isUnauthorized,
    isPolicyNotFound,
    jurisdictionState,
    kindCode,
    lineOfBusinessCode,
    lineOfBusinessName,
    lisrDividendCashValueAmount,
    lisrDivOptPaidUpAddsAmount,
    loanInterestAmount,
    loanInterestAmountDue,
    loanInterestDueDate,
    loanInterestRate,
    loanInterestType,
    loanPayoffAmount,
    loanPrincipal,
    ltcAdvanceBasePaidUpAddsIndicator,
    ltcBenefitPoolAmount,
    ltcCurrentMaxMonthlyBenefitAmount,
    ltcDividendBenefitPoolAmount,
    ltcirReduceAdditionsAmount,
    ltcirReturnPremiumAmount,
    ltcMaxMonthlyBenefitIncOptIndicator,
    ltcResidualDeathBenefitAmount,
    majorProductCode,
    majorProductName,
    marketingTypeDescription,
    maxFixedAccountTransferValue,
    maximumAvailableLoanAmount,
    maxPartialSurrenderValue,
    mecIndicator,
    memberGUID,
    minimumGuaranteedInterestRate,
    minorProductCode,
    minorProductName,
    monthlyBenefitMaximumAmount,
    netAnnualPremiumAmount,
    netDeathBenefitAmount,
    netDeathBenefitAmtDisplay,
    netPremiumAmount,
    netSurrenderAmount,
    netSurrenderValueMonth10Amount,
    netSurrenderValueMonth11Amount,
    netSurrenderValueMonth12Amount,
    netSurrenderValueMonth1Amount,
    netSurrenderValueMonth2Amount,
    netSurrenderValueMonth3Amount,
    netSurrenderValueMonth4Amount,
    netSurrenderValueMonth5Amount,
    netSurrenderValueMonth6Amount,
    netSurrenderValueMonth7Amount,
    netSurrenderValueMonth8Amount,
    netSurrenderValueMonth9Amount,
    nonForfeitureOptionCode,
    paidToDate,
    paidUpAdditionsFaceAmount,
    paidUpAddsAvailableAmount,
    partialSurrenderAmtDisplay,
    paymentModeDescShort,
    paymentAmount,
    payoffBalance,
    pendingSettlementIndicator,
    policyId: isCoverpathPolicy(agreementKeyAdmin)
      ? agreementKeyPrimary.replace(/^0+/, "")
      : agreementKeyPrimaryDisplay,
    productTypeCode,
    productTypeName,
    qualifiedIndicator,
    requestedMaturityDate,
    restrictionCode,
    settlementDividendAmount,
    specialCondition2Code,
    specialConditionCode,
    status,
    statusReason,
    surrenderChargeAmount,
    termDate,
    totalAccountValue,
    totalAvailableWithdrawalAmount,
    totalCashValueAmount,
    transactionDeepLinks,
    ultimateFaceAmount,
    unearnedPremiumPaymentAmount,
    valuationMonth10Date,
    valuationMonth11Date,
    valuationMonth12Date,
    valuationMonth1Date,
    valuationMonth2Date,
    valuationMonth3Date,
    valuationMonth4Date,
    valuationMonth5Date,
    valuationMonth6Date,
    valuationMonth7Date,
    valuationMonth8Date,
    valuationMonth9Date,
    activelyAtWorkIndicator,
    rateCode,
    restrictionCodeReason,
    requestAddressChangeIndicator,
    requestManageBeneficiaryIndicator,
    requestCashValueQuoteIndicator,
    requestCashValueQuoteHistoryIndicator,
    requestLoanIndicator,
    requestNameChangeIndicator,
    requestOwnershipChangeIndicator,
    requestPayABillIndicator,
    requestPolicyVerificationIndicator,
    requestTransactionStatusIndicator,
    requestDIClaimIndicator,
    requestFileDIClaimIndicator,
    DIClaimIndicator,
    unprocessedAgreementData: apiData,
  };
};
