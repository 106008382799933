import React from "react";
import Table from "../../common/table";
import { useSelectForm } from "../hooks";

const SelectForm = (props) => {
  const { formsTableColumns, formsTableRows } = useSelectForm(props);
  return (
    <>
      <h1 id="header-forms">Docusign Forms</h1>
      <p className="mm-sub-copy" id="required_fields_lbl">
        * = Required Field
      </p>
      <p className="mm-spacing--none" id="note-forms">
        The following forms are available to electronically sign if the below
        criteria are met:
      </p>
      <ul id="criteria-list" className="mm-spacing--minor">
        <li>Policy/Contract must be individually owned</li>
        <li>
          Policy/Contract cannot have an Assignment, Power of Attorney,
          Guarantor, Attorney-in-Fact, or Custodian and must not be subject to a
          divorce obligation
        </li>
        <li>Only one signature is required</li>
      </ul>
      <p id="select-form" className="h3">
        Select Form*
      </p>
      <Table
        id="table-forms"
        columns={formsTableColumns}
        rows={formsTableRows}
        showPagination={false}
      />
    </>
  );
};

export default SelectForm;
