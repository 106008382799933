import React from "react";

const CashCell = (props) => {
  const {
    column: { accessor },
    rowIndex,
    row,
  } = props;
  if (row[accessor]) {
    return row[accessor].includes("-") ? (
      <span id={`cell_${rowIndex}_${accessor}_value`} className="text-danger">
        {row[accessor]}
      </span>
    ) : (
      row[accessor]
    );
  }
  return "";
};

export default React.memo(CashCell);
