import React from "react";
import { TRANSACTIONS_DISABLED_MESSAGE } from "../../../constants/ui";
import IsTruthy from "../../common/logic/isTruthy";
import LdBannerContent from "../../common/molecules/ldBannerContent";

const FillableFormsHeader = (props) => {
  const { shouldDisableSubmit } = props;

  return (
    <>
      <nav role="navigation" className="mm-navbar--transaction">
        <div className="mm-logo" />
      </nav>
      <LdBannerContent />
      <IsTruthy value={shouldDisableSubmit}>
        <div
          className="alert alert-danger"
          role="alert"
          id="alert-transaction-disable"
        >
          {TRANSACTIONS_DISABLED_MESSAGE}
        </div>
      </IsTruthy>
    </>
  );
};

export default FillableFormsHeader;
