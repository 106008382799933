import React from "react";
import { isDefined, isNullOrUndefined } from "../../../../../utils/evaluate";
import IsFalsy from "../../../../common/logic/isFalsy";
import IsTruthy from "../../../../common/logic/isTruthy";

export const PaymentOptionsModalBody = (props) => {
  const {
    lockboxAddresses,
    contactUsNumber,
    contactUsTime,
    hideLoanAddress,
    isArtistryFlexKindCodes,
  } = props;

  if (!lockboxAddresses) return "";
  const contactStatement = () => (
    <>
      Please contact our service center at{" "}
      <a href={`tel:+${contactUsNumber}`}>{contactUsNumber}</a>, {contactUsTime}
      .
    </>
  );
  const loanPayAddresses = () => {
    if (hideLoanAddress) return "";
    return (
      <>
        <IsTruthy value={isArtistryFlexKindCodes}>
          <PaymentSectionHTML paymentType={payByLoanPrincipal} />
        </IsTruthy>
        <IsFalsy value={isArtistryFlexKindCodes}>
          <PaymentSectionHTML paymentType={payByLoanInterest} />
          <PaymentSectionHTML paymentType={payByLoanPrincipal} />
        </IsFalsy>
      </>
    );
  };
  const { payByLoanInterest, payByPremium, payByLoanPrincipal } =
    orderPaymentSections(lockboxAddresses, isArtistryFlexKindCodes);
  const overNightPayByPremiumCity = payByPremium[1]?.city;

  return (
    <div className="modal-body" id="payment_options">
      <h3 id="pay_by_mail_lbl">Pay by Mail</h3>
      <PaymentSectionHTML paymentType={payByPremium} />
      {loanPayAddresses()}
      <hr />
      <p className="copy-md">
        Overnight mail sent to the {overNightPayByPremiumCity} lockbox may take
        up to 2 business days to process. For efficient processing, please
        advise the client to make sure to enclose the payment coupon or write
        the policy or contract number on their check. The payment will be
        processed effective the day it is received.
      </p>
      <h3 id="wire_transfer_lbl">Wire Transfer</h3>
      <p className="mm-sub-copy">{contactStatement()}</p>
      <h3 id="credit_card_lbl">Credit Card</h3>
      <p className="mm-sub-copy">{contactStatement()}</p>
    </div>
  );
};

export const checkPaymentType = (props) => {
  const {
    paymentTypes,
    paymentType,
    paymentAddresses,
    arrayName,
    addressObject,
    isArtistryFlexKindCodes,
  } = props;

  const subHeaderLabel = isArtistryFlexKindCodes ? "Loan" : paymentType;
  return paymentTypes.includes(paymentType)
    ? [
        ...paymentAddresses[arrayName],
        { ...addressObject, paymentType: subHeaderLabel },
      ]
    : paymentAddresses[arrayName];
};

export const orderPaymentSections = (
  lockboxAddresses,
  isArtistryFlexKindCodes
) =>
  Object.values(lockboxAddresses).reduce(
    (paymentAddresses, address) => {
      const {
        categories,
        addressCode,
        line1,
        line2,
        line3,
        line4,
        line5,
        city,
        state,
        postalCode,
        paymentTypes,
      } = address;
      const lines = [line1, line2, line3, line4, line5].filter((line) =>
        isDefined(line)
      );
      const formattedLines = lines.map((line, index) => ({
        addressLine: line,
        id: index,
      }));
      const addressObject = {
        shippingType: categories[0],
        lines: formattedLines,
        city,
        state,
        postalCode,
        addressCode,
      };
      const payByLoanInterest = checkPaymentType({
        paymentTypes,
        paymentType: "Loan Interest",
        paymentAddresses,
        arrayName: "payByLoanInterest",
        addressObject,
      });
      const payByLoanPrincipal = checkPaymentType({
        paymentTypes,
        paymentType: "Loan Principal",
        paymentAddresses,
        arrayName: "payByLoanPrincipal",
        addressObject,
        isArtistryFlexKindCodes,
      });
      const payByPremium = checkPaymentType({
        paymentTypes,
        paymentType: "Premium",
        paymentAddresses,
        arrayName: "payByPremium",
        addressObject,
      });
      return { payByLoanPrincipal, payByLoanInterest, payByPremium };
    },
    {
      payByLoanInterest: [],
      payByPremium: [],
      payByLoanPrincipal: [],
    }
  );

export const PaymentSectionHTML = (props) => {
  const { paymentType } = props;
  if (paymentType && paymentType.length) {
    const paymentTypeLabel = paymentType[0].paymentType;
    return (
      <>
        <hr />
        <h4 id={`${paymentTypeLabel}_lbl`}>{paymentTypeLabel}</h4>
        <div className="row">
          <div
            id={`${paymentTypeLabel}_regular_shipping_lbl`}
            className="col-6"
          >
            <AddressHTML address={paymentType[0]} />
          </div>
          <div
            id={`${paymentTypeLabel}_overnight_shipping_lbl`}
            className="col-6"
          >
            <AddressHTML address={paymentType[1]} />
          </div>
        </div>
      </>
    );
  }
  return "";
};

export const AddressHTML = (props) => {
  const { address } = props;
  if (isNullOrUndefined(address)) return "";

  const {
    shippingType,
    addressCode,
    lines,
    city,
    state,
    postalCode,
    paymentType,
  } = address;
  return (
    <div key={`payment_${addressCode}`} id={`payment_${addressCode}`}>
      <h5 id={`${paymentType}_${shippingType}_lbl`}>
        <strong>{shippingType}</strong>
      </h5>
      <p className="copy-md mm-spacing--none">
        {lines.map((line) => (
          <span
            id={`${paymentType}_address_line_${line.id}`}
            key={`${paymentType}_address_line_${line.id}`}
          >
            {line.addressLine} <br />
          </span>
        ))}
        {city}, {state} {postalCode}
      </p>
    </div>
  );
};

export default PaymentOptionsModalBody;
