import { useLocation } from "react-router-dom";
import { useDevModeContext, types } from "../../../contexts/devMode";
import { hasKey } from "../../../utils/object";
import { arraysAreTheSame, compareStrings } from "../../../utils/string";

export const useDeterminePage = (pageName) => {
  const { pathname } = useLocation();
  const path = pathname.split("/");
  const PAGE_LENGTH_IS_3 = path.length === 3;
  const PAGE_LENGTH_IS_4 = path.length === 4;
  const pathNamesForLength3 = [path[1]];
  const pathNamesForLength4 = [path[1], path[3]];

  if (compareStrings(pageName, "/")) {
    return "HOME_AGENT_PAGE";
  }
  if (compareStrings(pageName, "/homeOffice")) {
    return "HOME_OFFICE_PAGE";
  }
  if (
    PAGE_LENGTH_IS_3 &&
    arraysAreTheSame(pathNamesForLength3, ["policyDetails"])
  ) {
    return "POLICY_DETAIL_PAGE";
  }
  if (
    PAGE_LENGTH_IS_4 &&
    arraysAreTheSame(pathNamesForLength4, ["policyDetails", "cashValues"])
  ) {
    return "CASH_VALUE_PAGE";
  }
  if (
    PAGE_LENGTH_IS_4 &&
    arraysAreTheSame(pathNamesForLength4, ["policyDetails", "documents"])
  ) {
    return "DOCUMENTS_PAGE";
  }
  if (
    PAGE_LENGTH_IS_4 &&
    arraysAreTheSame(pathNamesForLength4, ["policyDetails", "financialHistory"])
  ) {
    return "FINANCIAL_HISTORY_PAGE";
  }
  if (
    PAGE_LENGTH_IS_4 &&
    arraysAreTheSame(pathNamesForLength4, ["policyDetails", "funds"])
  ) {
    return "FUNDS_PAGE";
  }
  if (
    PAGE_LENGTH_IS_4 &&
    arraysAreTheSame(pathNamesForLength4, [
      "policyDetails",
      "viewServiceHistory",
    ])
  ) {
    return "SERVICE_HISTORY_PAGE";
  }
  if (
    PAGE_LENGTH_IS_4 &&
    arraysAreTheSame(pathNamesForLength4, ["policyDetails", "paymentOptions"])
  ) {
    return "PAYMENT_OPTIONS_PAGE";
  }
  if (
    PAGE_LENGTH_IS_4 &&
    arraysAreTheSame(pathNamesForLength4, [
      "policyDetails",
      "requestInformation",
    ])
  ) {
    return "REQUEST_INFORMATION_PAGE";
  }
  if (
    PAGE_LENGTH_IS_4 &&
    arraysAreTheSame(pathNamesForLength4, ["policyDetails", "initiateNewLoan"])
  ) {
    return "INITIADE_NEW_LOAN_PAGE";
  }
  if (
    PAGE_LENGTH_IS_4 &&
    arraysAreTheSame(pathNamesForLength4, [
      "policyDetails",
      "loanPayoffAsOfDate",
    ])
  ) {
    return "LOAN_PAYOFF_PAGE";
  }
  if (
    PAGE_LENGTH_IS_4 &&
    arraysAreTheSame(pathNamesForLength4, ["policyDetails", "changeAddress"])
  ) {
    return "CHANGE_ADDRESS";
  }
  return "NO_IDEA";
};

export const useGetPageApis = (allApis, pageName) => {
  const {
    agreement,
    beneficiaries,
    billing,
    content,
    customer,
    financialHistory,
    homeOfficeSearch,
    loan,
    producer,
    producers,
    serviceHistory,
    contract,
    contractSoa,
    soaBillingArrangement,
    cdaValues,
    valueRecalcRequest,
  } = allApis;

  const PAGE_NAME = useDeterminePage(pageName);
  switch (PAGE_NAME) {
    case "HOME_AGENT_PAGE":
      return {
        allApis,
        pageApis: {},
      };
    case "HOME_OFFICE_PAGE":
      return {
        allApis,
        pageApis: { homeOfficeSearch },
      };
    case "POLICY_DETAIL_PAGE":
      return {
        allApis,
        pageApis: {
          agreement,
          billing,
          beneficiaries,
          content,
          customer,
          loan,
          producer,
          producers,
          serviceHistory,
          contract,
          contractSoa,
          soaBillingArrangement,
          cdaValues,
          valueRecalcRequest,
        },
      };
    case "CASH_VALUE_PAGE":
      return {
        allApis,
        pageApis: {
          agreement,
        },
      };
    case "DOCUMENTS_PAGE":
      return {
        allApis,
        pageApis: {
          content,
          agreement,
        },
      };
    case "FINANCIAL_HISTORY_PAGE":
      return {
        allApis,
        pageApis: {
          agreement,
          financialHistory,
        },
      };
    case "FUNDS_PAGE":
      return {
        allApis,
        pageApis: {
          agreement,
        },
      };
    case "SERVICE_HISTORY_PAGE":
      return {
        allApis,
        pageApis: {
          agreement,
          serviceHistory,
        },
      };
    case "CHANGE_ADDRESS":
      return {
        allApis,
        pageApis: {
          agreement,
        },
      };
    default:
      return {
        allApis,
        pageApis: allApis,
      };
  }
};

export const useFields = (devModeInfo, pageName) => {
  const { isOn, api } = devModeInfo;

  const { allApis, pageApis } = useGetPageApis(api, pageName);
  const isOnField = {
    id: "isOn",
    label: "Turn on All Fake Apis",
    name: "isOn",
    value: isOn,
  };
  return {
    shareData: { isOn: isOnField },
    otherApis: Object.keys(allApis).reduce((collection, key) => {
      const PAGE_API_EXISTS = hasKey({ obj: pageApis, key });
      if (PAGE_API_EXISTS) {
        return collection;
      }
      return {
        ...collection,
        [key]: allApis[key],
      };
    }, {}),
    pageApis,
  };
};

export const useDevModeProps = () => {
  const { devModeInfo } = useDevModeContext();
  const { pathname } = useLocation();
  const { otherApis, pageApis, shareData } = useFields(devModeInfo, pathname);

  return {
    otherApis,
    pageApis,
    shareData,
    isVisible: devModeInfo.isVisible,
  };
};

export const useTextFields = (formFields) => {
  const { isOn, ...textFields } = formFields;
  return {
    isOn,
    textFields,
  };
};

export const useCommonFormProps = (props) => {
  const {
    shareData: { isOn },
  } = props;
  const { dispatchInfo } = useDevModeContext();

  const switchChanged = () => {
    const ALL_API_TOGGLE_IS_ON = isOn.value;
    if (ALL_API_TOGGLE_IS_ON) {
      dispatchInfo({
        type: types.TURN_OFF,
      });
    } else {
      dispatchInfo({
        type: types.TURN_ON,
      });
    }
  };

  const useCustomSwitch = () => ({
    onChange: switchChanged,
    className: "custom-control-input",
  });
  return { isOn, useCustomSwitch };
};

export const useFormProps = (props) => {
  const { formFields } = props;
  const { dispatchInfo } = useDevModeContext();

  const useCustomJsonInput = (useHookProps) => {
    const { formField } = useHookProps;
    const onChange = (json) => {
      dispatchInfo({
        type: types.NEW_SAVE,
        formField,
        newValue: JSON.stringify(json),
      });
    };

    return {
      onChange,
    };
  };
  return {
    formFields,
    useCustomJsonInput,
  };
};
