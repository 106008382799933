import React, { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import { isDefined } from "../../utils/evaluate";
import IsTruthy from "../common/logic/isTruthy";
import { useCancelModal } from "../common/popup/cancel";
import { useFormContext } from "../../contexts/form";
import { useCanContinue, useCanSubmit } from "./hooks";

const ModalControl = (props) => {
  const { pageNumber, error, showLoadingIcon, ownerName, onSubmit } = props;

  const { openCancelModal } = useCancelModal(props);
  const { onContinue, shouldDisableContinue } = useCanContinue(props);

  const { shouldDisableSubmit } = useCanSubmit();

  const { formData } = useFormContext();

  const [className, setClassName] = useState("btn btn-primary");
  const [buttonText, setButtonText] = useState("Check Eligibility");
  const [submitButtonText, setSubmitButtonText] = useState(
    `Pre-Register ${ownerName}`
  );

  useEffect(() => {
    if (showLoadingIcon) {
      setClassName("btn btn-primary-loading");
      setButtonText("");
      setSubmitButtonText("");
    } else {
      setClassName("btn btn-primary");
      setButtonText("Check Eligibility");
      setSubmitButtonText(`Pre-Register ${ownerName}`);
    }
  }, [showLoadingIcon]);

  return (
    <div className="mm-spacing">
      <IsTruthy value={error} validationFunction={isDefined}>
        <div
          className="alert alert-danger mm-spacing--minor"
          role="alert"
          aria-label={error}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(error, {
              ADD_ATTR: ["target"],
            }),
          }}
        />
      </IsTruthy>
      <IsTruthy value={pageNumber} validationFunction={(pn) => pn === 1}>
        <button
          id="continue_btn"
          type="button"
          className={className}
          disabled={shouldDisableContinue}
          onClick={onContinue}
        >
          {buttonText}
        </button>
      </IsTruthy>
      <IsTruthy value={pageNumber} validationFunction={(pn) => pn === 2}>
        <button
          id="submit_btn"
          type="submit"
          className={className}
          disabled={shouldDisableSubmit || showLoadingIcon}
          onClick={(e) => {
            e.preventDefault();
            onSubmit(formData);
          }}
        >
          {submitButtonText}
        </button>
      </IsTruthy>
      <IsTruthy value={pageNumber < 3}>
        <button
          id="cancel_btn"
          type="button"
          className="btn btn-link"
          onClick={openCancelModal}
        >
          Cancel
        </button>
      </IsTruthy>
    </div>
  );
};
export default ModalControl;
