import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { setTitle } from "../../utils/dom";
import Table from "../common/table";
import TableControl from "../common/table/tableControl";
import LandingPageDrawer from "../common/drawers/landingPageDrawer";
import { useGetHomeOfficeProps } from "./hooks";
import IsTruthy from "../common/logic/isTruthy";
import { isDefined } from "../../utils/evaluate";
import Form from "../common/forms/form";
import SearchForm from "./searchForm";
import IsFalsy from "../common/logic/isFalsy";
import LoadingSection, { errorTypes, loadingTypes } from "../common/loading";
import { tealiumPageViewEvent } from "../../utils/tealium";
import { contactUSDetails } from "../../data/contactUsDetails";

const HomeOffice = (props) => {
  setTitle("ServiceNet - Policies");
  const {
    sideBar: { selectedRow, closeDrawer },
    table: {
      columns,
      rows,
      customFunctions,
      filterChanged,
      filterOn,
      noResultsText,
      invalidBpidMessage,
      removeReloadButton,
      filterBar: { filterString, showClearIcon, filterTable, clearFilter },
    },
    search,
    resultFiltering,
    showTable,
    formFields,
    email,
    isRestrictedPolicy,
  } = useGetHomeOfficeProps(props);

  useEffect(() => {
    tealiumPageViewEvent({
      page: "Search",
      category: "Search",
      custId: email,
    });
  }, []);

  const showDrawer = isDefined(selectedRow) && !isRestrictedPolicy;

  return (
    <div>
      <h1>Find a Policy</h1>
      <Form fields={formFields} onSubmit={search.fetchPolicy}>
        <SearchForm search={search} />
      </Form>
      <IsTruthy value={showTable}>
        <LoadingSection
          request={search.agentSearchRequest}
          loadingType={loadingTypes.SPINNER}
          errorType={errorTypes.SECTION}
          customErrorMessage={
            <span dangerouslySetInnerHTML={{ __html: invalidBpidMessage }} />
          }
          removeReloadButton={removeReloadButton}
        >
          <div className="custom-control custom-switch justify-content-start mm-spacing--minor">
            <input
              id="search_toggle_input"
              checked={filterOn}
              onChange={filterChanged}
              type="checkbox"
              className="custom-control-input"
            />
            <label
              htmlFor="search_toggle_input"
              className="custom-control-label"
            >
              Show Active and New Business Policies only
            </label>
          </div>
          <IsTruthy value={resultFiltering}>
            <TableControl
              searchString={filterString}
              searchTable={filterTable}
              showClearIcon={showClearIcon}
              clearSearch={clearFilter}
              placeHolderText="Filter Results"
            />
          </IsTruthy>
          <div className="row">
            <div className="col-sm">
              <Table
                id="policies"
                columns={columns}
                rows={rows}
                customFunctions={customFunctions}
                noResultsText={noResultsText}
              />
            </div>
          </div>
        </LoadingSection>
        <IsTruthy value={showDrawer}>
          <Modal show="true" onHide={closeDrawer} className="modal--drawer">
            <Modal.Header>
              <button
                onClick={closeDrawer}
                type="button"
                className="close"
                aria-label="Close"
              />
            </Modal.Header>
            <Modal.Body id="sideDrawer">
              <LandingPageDrawer row={selectedRow} />
            </Modal.Body>
          </Modal>
        </IsTruthy>

        <p className="copy-xl contact-us">
          At this time, some lines of business will not appear in ServiceNet
          2.0.
          <br />
          Get the service support you need by calling one of our{" "}
          <a target="_blank" href={contactUSDetails.PAGE.link} rel="noreferrer">
            Call Centers
          </a>
          .
        </p>
      </IsTruthy>
      <IsFalsy value={showTable}>
        <div className="mm-text-center">
          <picture>
            <span role="img" className="illustration-search" title="Search" />
          </picture>
        </div>
      </IsFalsy>
    </div>
  );
};

export default React.memo(HomeOffice);
