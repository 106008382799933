import { agreementHasAssignee, getUsers } from "../../utils/findData";
import {
  checkIfTransactionIsAllowed,
  isPolicyInForce,
} from "../../utils/policies";
import { compareStrings } from "../../utils/string";
import { supportedTransactions } from "../../utils/translate";
import { useLDValue } from "../common/featureFlags/hooks";

export const useFillableFormList = (props) => {
  const { agreementData } = props;
  const { lineOfBusinessCode } = agreementData;
  const fillableFormsConfig = useLDValue({
    flagName: ["fillableForms"],
  });
  const { forms: formList } = fillableFormsConfig;
  const assigneeOnAgreement = agreementHasAssignee(agreementData);
  return (
    formList?.filter(
      (form) =>
        compareStrings(form.lineOfBusinessCode, lineOfBusinessCode) &&
        (!assigneeOnAgreement || compareStrings(form.code, "BENE-CHANGE-LIFE"))
    ) || []
  );
};

export const verifyFillableFormLibraryLink = (props) => {
  const { agreementData, transactionSupportedPolicies, fillableFormList } =
    props;

  const { isTransactionAllowed } = checkIfTransactionIsAllowed({
    transactionSupportedPolicies,
    transactionName: supportedTransactions.FillableFormLinks,
    agreementData,
  });

  const returnObject = {
    isEligible: isAgreementDataEligibleForFormLibraryLink({
      fillableFormList,
      agreementData,
    }),
    isTransactionAllowed,
  };

  return returnObject;
};

export const isAgreementDataEligibleForFormLibraryLink = (props) => {
  const { agreementData, fillableFormList } = props;
  const { status } = agreementData;
  const isValidPolicyStatus = isPolicyInForce(status);

  const { agreementCustomers } = agreementData;

  const owners = getUsers(agreementCustomers, "OWNR") || [];

  const atLeastOneFillableForm = fillableFormList.length > 0;

  const isSingleIndividualOwned =
    owners.length === 1 &&
    owners[0].type === "I" &&
    owners[0].roleSubType === "LIST";

  return (
    isValidPolicyStatus && isSingleIndividualOwned && atLeastOneFillableForm
  );
};
