import { nameCase } from "@foundernest/namecase";
import { useModalState } from "../../../../contexts/modal";
import {
  formatMoney,
  formatName,
  convertToTitleCase,
  formatPercentage,
  formatContactUsNumber,
  formatContactUsDateTime,
  maskAccountNumber,
} from "../../../../utils/format";
import {
  compareArrayOfStrings,
  compareStrings,
} from "../../../../utils/string";
import { formatDate } from "../../../../utils/dates";
import { PaymentOptionsModalBody } from "./modals/paymentOptionsModalBody";
import { DaysThirtyTwoToSixtyTwoModal } from "./modals/daysThirtyTwoToSixtyTwo";
import { GracePeriodModalBody } from "./modals/gracePeriodModalBody";
import { FrequencyOptionsModal } from "./modals/frequencyOptionsModal";
import {
  DATA_UNAVAILABLE,
  NOT_APPLICABLE,
  NOT_BILLED,
} from "../../../../constants/ui";
import { isDefined, isNullOrUndefined } from "../../../../utils/evaluate";
import {
  isArtistryAnnuityProduct,
  isDisabilityPolicy,
  isVariableAnnuity,
  isVNTG1CC10112NBL,
  isFlexExtraAnnuityProduct,
  isFlexIVFund1FPOrFund4FP,
  isOdysseyAnnuityProduct,
  isTermCoverPath,
} from "../../../../utils/policies";
import { useViewFrequencyModalLink } from "../kpi/hooks";
import { fireTealiumEvent } from "../../../../utils/tealium";
import { useUserState } from "../../../../contexts/user";
import { getGroupDetailsForDI } from "../../../../utils/findData";
import { convertToNumber } from "../../../../utils/number";
import {
  VARIABLE_ANNUITY_ARTISTRY_KIND_CODES,
  VARIABLE_ANNUITY_FLEX_EXTRA_KIND_CODES,
} from "../../../../constants/logic";
import { contactUSDetails } from "../../../../data/contactUsDetails";
import { useLDValue } from "../../../common/featureFlags/hooks";
import { checkHideLisrAlirBilledPremiumValues } from "../../../../utils/coverages";

export const useBillingModal = (props) => {
  const {
    paymentOptionModalProps,
    frequencyOptionModalProps,
    lineOfBusinessCode,
  } = props;
  const { openModal } = useModalState();
  const {
    user: { email },
  } = useUserState();
  const modalProps = {
    title: "Frequency Options",
    Component: FrequencyOptionsModal,
    componentProps: frequencyOptionModalProps,
    modalSize: "lg",
  };

  const paymentOptionsModalProps = {
    Component: PaymentOptionsModalBody,
    componentProps: paymentOptionModalProps,
    title: "Payment Options",
  };

  const daysThirtyTwoToSixtyTwoProps = {
    title: "Days 32-62",
    Component: DaysThirtyTwoToSixtyTwoModal,
  };

  const gracePeriodProps = {
    title: "Grace Period",
    Component: GracePeriodModalBody,
    componentProps: { lineOfBusinessCode },
  };

  const showModal = () => {
    fireTealiumEvent({
      label: "SVNT",
      action: "View Billing Frequency Button click",
      category: "Policy Details Page",
      custId: email,
    });
    openModal(modalProps);
  };
  const showPaymentOptionsModal = () => openModal(paymentOptionsModalProps);
  const showDaysThirtyTwoToSixtyTwoModal = () =>
    openModal(daysThirtyTwoToSixtyTwoProps);
  const showGracePeriodModal = () => openModal(gracePeriodProps);

  return {
    showGracePeriodModal,
    showDaysThirtyTwoToSixtyTwoModal,
    showModal,
    showPaymentOptionsModal,
  };
};

export const getOptionRows = (props) => {
  const {
    data: {
      options: {
        optionArrangement,
        optionArrangementType,
        paymentArrangement,
        mpoApoNetDueAmount,
        majorProductCode,
      },
    },
    agreementData: { agreementKeyAdmin, lineOfBusinessCode },
  } = props;

  const approvedAdminSystems = ["MPR", "OPM", "VNTG1", "HAP"];
  const approvedLineOfBusinesses = ["LIFE"];
  const approvedProductCodes = ["PERM"];
  const IS_A_SUPPORTED_PRODUCT =
    approvedAdminSystems.includes(agreementKeyAdmin) &&
    approvedLineOfBusinesses.includes(lineOfBusinessCode) &&
    approvedProductCodes.includes(majorProductCode);

  if (IS_A_SUPPORTED_PRODUCT) {
    return [
      {
        paymentArrangement,
        optionArrangement,
        optionArrangementType,
        amountDue: formatMoney(mpoApoNetDueAmount),
      },
    ];
  }
  return [
    {
      paymentArrangement: "None",
      optionArrangement: "None",
      optionArrangementType: "None",
      amountDue: "None",
    },
  ];
};
export const getOptionColumns = (props) => {
  const {
    data: {
      options: { sdoMpoApoCd },
    },
  } = props;
  const optionColumns = [
    {
      accessor: "paymentArrangement",
      label: "Payment Arrangement Type",
      alignment: "left",
      dataCell: true,
    },
    {
      accessor: "optionArrangement",
      label: "Option Arrangement",
      alignment: "left",
    },
    {
      accessor: "optionArrangementType",
      label: "Option Arrangement Type",
      alignment: "left",
    },
  ];
  const SHOULD_ADD_AMOUNT_COLUMN = sdoMpoApoCd === "APO-P";
  if (SHOULD_ADD_AMOUNT_COLUMN) {
    const amountOption = {
      accessor: "amountDue",
      label: "Amount Due",
      alignment: "left",
    };
    return [...optionColumns, amountOption];
  }
  return optionColumns;
};

export const formatData = (props) => {
  const {
    data,
    agreementKeyAdmin,
    productTypeCode,
    hideLisrBilledPremiumValues,
    hideLisrAlirBilledPremiumValues,
  } = props;

  const bankAccountHolderName = compareStrings(data.bankAccountHolder, "-")
    ? "Unavailable"
    : formatName({
        nameObject: { fullName: data.bankAccountHolder },
        format: "firstNameDisplayedFirst",
      });

  const billFrequency = isVNTG1CC10112NBL({
    agreementRequest: { data: { agreementKeyAdmin, productTypeCode } },
    billingRequest: { data: { billingMode: data.billingMode } },
  })
    ? "Single Premium"
    : data.billFrequency;

  const {
    isOnAutoPay,
    isOnAutoPayLoan,
    isOnAutoPayLoanInterest,
    paymentPreference,
    autoPayStatusCode,
    availableFrequencies,
  } = data;
  let autoPayEnabled = "Unavailable";
  if (isDefined(isOnAutoPay)) {
    autoPayEnabled = isOnAutoPay ? "On" : "Off";
  }

  const isFrequencyMonthlyOrQuarterly = compareArrayOfStrings(
    ["monthly", "quarterly"],
    billFrequency
  );

  const isPacEnabled = compareStrings(paymentPreference, "PAC");

  const displayAutoPayEnabled = !(
    isFrequencyMonthlyOrQuarterly &&
    isPacEnabled &&
    isDefined(isOnAutoPay) &&
    !isOnAutoPay
  );

  const displayAutopayInformation = compareStrings(autoPayStatusCode, "ACTV");

  const isOnPremiumInterest =
    isOnAutoPay &&
    availableFrequencies?.some((frequency) =>
      compareStrings(frequency.type, "RecurringPayment")
    );

  return {
    ...data,
    pacAccountNumber:
      agreementKeyAdmin !== "EDS-DI" && isDefined(data.pacAccountNumber)
        ? data.pacAccountNumber
        : DATA_UNAVAILABLE,
    accountType: isDefined(data.accountType)
      ? convertToTitleCase(data.accountType)
      : DATA_UNAVAILABLE,
    amount: formatMoney(data.amount, DATA_UNAVAILABLE),
    billFrequency,
    billedPremium:
      hideLisrBilledPremiumValues || hideLisrAlirBilledPremiumValues
        ? "Unavailable"
        : formatMoney(data.billedPremium, "Unavailable"),
    bankAccountHolder: isDefined(bankAccountHolderName)
      ? bankAccountHolderName
      : DATA_UNAVAILABLE,
    bankName: isDefined(data.bankName)
      ? nameCase(data.bankName)
      : DATA_UNAVAILABLE,
    totalAmount: formatMoney(data.totalAmount, DATA_UNAVAILABLE),
    netPremiumAmountToDate: formatMoney(
      data.netPremiumAmountToDate,
      DATA_UNAVAILABLE
    ),
    gracePeriod: data.paymentDates[0].gracePeriod,
    additionalPaymentPeriod: data.paymentDates[0].additionalPaymentPeriod,
    paidToDate: data.paymentDates[0].paidToDate,
    paymentPreferenceDesc: isDefined(data.paymentPreferenceDesc)
      ? data.paymentPreferenceDesc
      : "Unavailable",
    billDeliveryPreference: isDefined(data.billDeliveryPreference)
      ? data.billDeliveryPreference
      : "Unavailable",
    autoPayEnabled,
    displayAutoPayEnabled,
    routingNumber: isDefined(data.routingNumber)
      ? data.routingNumber
      : DATA_UNAVAILABLE,
    draftDay: isDefined(data.draftDay) ? data.draftDay : DATA_UNAVAILABLE,
    accountNumber: isDefined(data.accountNumber)
      ? data.accountNumber
      : DATA_UNAVAILABLE,
    displayAutopayInformation,
    isOnPremiumInterest,
    isOnAutoPayLoan,
    isOnAutoPayLoanInterest,
  };
};

export const getBilledLoanInterestValues = (props) => {
  const { loanInterestAmountDue, loanInterestDueDate, loanPayoffAmount } =
    props;

  if (
    isNullOrUndefined(loanInterestAmountDue) &&
    isNullOrUndefined(loanInterestDueDate)
  ) {
    return {
      loanInterestAmountDueValue: DATA_UNAVAILABLE,
      loanInterestDueDateValue: DATA_UNAVAILABLE,
    };
  }

  if (
    isNullOrUndefined(loanInterestAmountDue) &&
    isNullOrUndefined(loanInterestDueDate) &&
    loanPayoffAmount > 0
  ) {
    return {
      loanInterestAmountDueValue: NOT_BILLED,
      loanInterestDueDateValue: NOT_BILLED,
    };
  }

  if (
    isNullOrUndefined(loanInterestAmountDue) &&
    isNullOrUndefined(loanInterestDueDate) &&
    (loanPayoffAmount === 0 || isNullOrUndefined(loanPayoffAmount))
  ) {
    return {
      loanInterestAmountDueValue: DATA_UNAVAILABLE,
      loanInterestDueDateValue: DATA_UNAVAILABLE,
    };
  }

  if (loanInterestAmountDue < 0) {
    return {
      loanInterestAmountDueValue: DATA_UNAVAILABLE,
      loanInterestDueDateValue: DATA_UNAVAILABLE,
    };
  }

  if (loanInterestDueDate === "" && loanInterestAmountDue !== undefined) {
    return loanPayoffAmount > 0
      ? {
          loanInterestAmountDueValue: NOT_BILLED,
          loanInterestDueDateValue: NOT_BILLED,
        }
      : {
          loanInterestAmountDueValue: DATA_UNAVAILABLE,
          loanInterestDueDateValue: DATA_UNAVAILABLE,
        };
  }

  return {
    loanInterestAmountDueValue: formatMoney(
      loanInterestAmountDue,
      DATA_UNAVAILABLE
    ),
    loanInterestDueDateValue: formatDate(
      loanInterestDueDate,
      "default",
      "Not Applicable"
    ),
  };
};

export const useBillingHook = (props) => {
  const {
    agreementRequest,
    billingRequest,
    annuityRequest = {},
    soaBillingRequest = {},
  } = props;

  const agreementData = agreementRequest.data;
  const {
    data: {
      agreementKeyAdmin,
      productTypeCode,
      contactUsNumber,
      contactUsTime,
      paidToDate,
      loanPayoffAmount,
      jurisdictionState,
      annualPaymentAmount,
      agreementKey,
      lineOfBusinessCode,
      agreementKeyPrefix,
      statusReason,
      kindCode,
    },
  } = agreementRequest;

  const isFlexIVFund1FPOrFund4FPContract = isFlexIVFund1FPOrFund4FP(kindCode);

  const {
    billingModeDesc,
    billTypeDesc,
    totalBilledPremium,
    totalContributions,
    billedToDate,
    invoiceAccountNbr,
    billType,
  } = selectAnnuityBillingData({
    isFlexIVFund1FPOrFund4FPContract,
    agreementData,
    annuityData: annuityRequest?.data,
    soaBillingData: soaBillingRequest?.data,
  });

  const { data } = billingRequest;
  const optionRows = getOptionRows({ data, agreementData });
  const optionColumns = getOptionColumns({ data });

  const isPolicyTermHap = isTermCoverPath(agreementData);
  const IS_ANNUITY_AND_LIFCOM =
    compareStrings(lineOfBusinessCode, "ANN") &&
    compareStrings(agreementKeyAdmin, "LIFCOM");
  const IS_DISABILITY_POLICY = isDisabilityPolicy({ lineOfBusinessCode });
  const isEDSDIPolicy =
    IS_DISABILITY_POLICY && compareStrings(agreementKeyAdmin, "EDS-DI");
  const variableAnnuity = isVariableAnnuity(agreementRequest);
  const odysseyAnnuity = isOdysseyAnnuityProduct(agreementKeyPrefix);
  const artistryAnnuity = isArtistryAnnuityProduct(agreementKeyPrefix);
  const flexExtraAnnuity = isFlexExtraAnnuityProduct(kindCode);

  const formattedTotalBilledPremium = formatMoney(totalBilledPremium);
  const formattedTotalContributions = formatMoney(totalContributions);

  const artistryTable = getArtistryBillingTable(annuityRequest);

  const { showGroupDetails, groupName, discountPercent, groupAccountId } =
    getGroupDetailsForDI(agreementData);

  const formattedPhoneNumber = formatContactUsNumber(
    contactUsNumber,
    contactUSDetails.DEFAULT.number
  );

  const isArtistryFlexKindCodes =
    compareArrayOfStrings(VARIABLE_ANNUITY_ARTISTRY_KIND_CODES, kindCode) ||
    compareArrayOfStrings(VARIABLE_ANNUITY_FLEX_EXTRA_KIND_CODES, kindCode);

  const hideLoanPaymentAddress = variableAnnuity && !isArtistryFlexKindCodes;

  const paymentOptionModalProps = {
    lockboxAddresses: data.lockboxAddresses,
    contactUsNumber: formattedPhoneNumber,
    contactUsTime,
    hideLoanAddress: hideLoanPaymentAddress,
    isArtistryFlexKindCodes,
  };

  const { loanInterestAmountDueValue, loanInterestDueDateValue } =
    getBilledLoanInterestValues({
      loanInterestAmountDue: data.loan.loanInterestAmount || "",
      loanInterestDueDate: data.loan.loanInterestDueDate || "",
      loanPayoffAmount,
    });

  const modal = useBillingModal({
    paymentOptionModalProps,
    frequencyOptionModalProps: {
      ...data,
      agreementKey,
      lineOfBusinessCode,
      agreementKeyAdmin,
      isEligibilityCheckRequired: false,
    },
    lineOfBusinessCode,
  });
  const isLSTPolicy = (paymentPreference) =>
    compareStrings(paymentPreference, "List Bill");

  const premiumObject = { agreementData, billingData: data };

  const siprOpmRiders = findRidersWithPremiums({
    riderType: "siprOpm",
    ...premiumObject,
  });
  const lisrOpmRiders = findRidersWithPremiums({
    riderType: "lisrOpm",
    ...premiumObject,
  });
  const alirVntg1Riders = findRidersWithPremiums({
    riderType: "alirVntg1",
    ...premiumObject,
  });
  const palirVntg1Riders = findRidersWithPremiums({
    riderType: "palirVntg1",
    ...premiumObject,
  });
  const alirOpmRiders = findRidersWithPremiums({
    riderType: "alirOpm",
    ...premiumObject,
  });
  const lisrVntg1Riders = findRidersWithPremiums({
    riderType: "lisrVntg1",
    ...premiumObject,
  });
  const alirHapRiders = findRidersWithPremiums({
    riderType: "alirHap",
    ...premiumObject,
  });
  const lisrHapRiders = findRidersWithPremiums({
    riderType: "lisrHap",
    ...premiumObject,
  });

  const HAS_ADDITIONAL_RIDER_PREMIUMS =
    siprOpmRiders.length > 0 ||
    lisrOpmRiders.length > 0 ||
    alirVntg1Riders.length > 0 ||
    palirVntg1Riders.length > 0 ||
    alirOpmRiders.length > 0 ||
    lisrVntg1Riders.length > 0 ||
    alirHapRiders.length > 0 ||
    lisrHapRiders.length > 0;

  const ShowDaysThirtyTwoToSixtyTwoSection =
    !compareStrings(jurisdictionState, "CA") || IS_DISABILITY_POLICY;
  const { showLink, enableLink } = useViewFrequencyModalLink({
    agreementRequest,
    billingRequest,
  });
  const showFrequencyOptionsModal = showLink && enableLink;

  const { showBillingAndPayments, showBilledToDate, showVAFranchiseNumber } =
    calculateFieldsDisplayForBillingAndPayments({
      isFlexIVFund1FPOrFund4FPContract,
      agreementKeyAdmin,
      statusReason,
      isVariableAnnuityResult: variableAnnuity,
      billType,
      kindCode,
    });

  const franchiseNumberLabel = isFlexIVFund1FPOrFund4FPContract
    ? "Invoice/Franchise Number"
    : "Franchise Number";

  const displayBillTypeToolTip = isFlexIVFund1FPOrFund4FPContract;

  const lisrValueHidingEnabled = useLDValue({
    flagName: ["lisrPremiumValuesHiding"],
  });

  const { hideLisrBilledPremiumValues, hideLisrAlirBilledPremiumValues } =
    checkHideLisrAlirBilledPremiumValues({
      agreementData,
      paidToDate: billingRequest?.data?.paidToDate,
      lisrValueHidingEnabled,
    });

  return {
    isFlexIVFund1FPOrFund4FPContract,
    franchiseNumberLabel,
    displayBillTypeToolTip,
    requests: {
      billingRequest,
    },
    modal,
    data: formatData({
      data,
      agreementKeyAdmin,
      productTypeCode,
      hideLisrBilledPremiumValues,
      hideLisrAlirBilledPremiumValues,
    }),
    table: {
      optionRows,
      optionColumns,
    },
    annualPaymentAmount: formatMoney(annualPaymentAmount, "Unavailable"),
    isLSTPolicy,
    isEDSDIPolicy,
    showGroupDetails,
    groupName,
    contactUsNumber: formattedPhoneNumber,
    contactUsTime: formatContactUsDateTime(
      contactUsTime,
      contactUSDetails.DEFAULT.time
    ),
    paidToDateAgreement: formatDate(paidToDate, "", DATA_UNAVAILABLE),
    loanInterestAmountDue: loanInterestAmountDueValue,
    loanInterestDueDate: loanInterestDueDateValue,
    siprOpmRiders,
    lisrOpmRiders,
    alirVntg1Riders,
    palirVntg1Riders,
    alirOpmRiders,
    lisrVntg1Riders,
    lisrHapRiders,
    alirHapRiders,
    showAdditionalRiderPremiums: HAS_ADDITIONAL_RIDER_PREMIUMS,
    ShowDaysThirtyTwoToSixtyTwoSection,
    showFrequencyOptionsModal,
    isPolicyTermHap,
    IS_ANNUITY_AND_LIFCOM,
    IS_DISABILITY_POLICY,
    discountPercent: formatPercentage({
      rate: discountPercent,
      decimalPlaces: 0,
    }),
    groupAccountId,
    agreementKeyAdmin,
    variableAnnuity,
    odysseyAnnuity,
    billingModeDesc: isDefined(billingModeDesc)
      ? billingModeDesc
      : "Unavailable",
    billTypeDesc: isDefined(billTypeDesc) ? billTypeDesc : "Unavailable",
    billedToDate:
      isDefined(billedToDate) &&
      !isFlexExtraAnnuityProduct(kindCode) &&
      !isFlexIVFund1FPOrFund4FPContract
        ? formatDate(billedToDate, "", "Unavailable")
        : formatDate(billedToDate, "", "-"),
    invoiceAccountNbr: isDefined(invoiceAccountNbr)
      ? invoiceAccountNbr
      : "Unavailable",
    formattedTotalBilledPremium: isDefined(totalBilledPremium)
      ? formattedTotalBilledPremium
      : "Unavailable",
    formattedTotalContributions: isDefined(totalContributions)
      ? formattedTotalContributions
      : "Unavailable",
    artistryAnnuity,
    artistryTable,
    flexExtraAnnuity,
    showBillingAndPayments,
    showBilledToDate,
    showVAFranchiseNumber,
    isLockboxAddressRemoved: data.isLockboxAddressRemoved,
    lineOfBusinessCode,
    hideLisrBilledPremiumValues,
    hideLisrAlirBilledPremiumValues,
  };
};

export const findRidersWithPremiums = (props) => {
  const { agreementData, billingData, riderType = "" } = props;

  const {
    majorProductCode,
    lineOfBusinessCode,
    agreementKeyAdmin,
    agreementCoverages,
  } = agreementData;

  const {
    siprRiderIndicator,
    lisrRiderIndicator,
    alirRiderIndicator,
    palirRiderIndicator,
    riders,
  } = billingData;

  const IS_LIFE_AND_PERM =
    compareStrings(lineOfBusinessCode, "LIFE") &&
    compareStrings(majorProductCode, "PERM");

  switch (riderType) {
    case "siprOpm": {
      const VALID_RIDER =
        compareStrings(agreementKeyAdmin, "OPM") &&
        compareStrings(siprRiderIndicator, "Y") &&
        IS_LIFE_AND_PERM;

      if (!VALID_RIDER) return [];

      return agreementCoverages.filter((coverage) => {
        const { type = "", category = "" } = coverage;
        return compareStrings(type, "SIPR") && compareStrings(category, "R");
      });
    }
    case "lisrOpm": {
      const VALID_RIDER =
        compareStrings(agreementKeyAdmin, "OPM") &&
        compareStrings(lisrRiderIndicator, "Y") &&
        IS_LIFE_AND_PERM;

      const validCoverages = agreementCoverages?.filter((coverage) => {
        const { type = "", category = "" } = coverage;
        return compareStrings(type, "LISR") && compareStrings(category, "R");
      });

      if (!(VALID_RIDER && validCoverages.length > 0)) return [];

      return riders?.filter((rider) => compareStrings("LISR", rider.riderType));
    }
    case "alirVntg1": {
      const VALID_RIDER =
        compareStrings(agreementKeyAdmin, "VNTG1") &&
        compareStrings(alirRiderIndicator, "Y") &&
        IS_LIFE_AND_PERM;

      if (!VALID_RIDER) return [];

      const possibleTypes = ["ALIR", "ALIRS"];

      const findCoverages = agreementCoverages.filter((coverage) => {
        const { type = "", category = "", status = "" } = coverage;

        return (
          compareStrings(category, "R") &&
          compareArrayOfStrings(possibleTypes, type) &&
          compareStrings("ACT", status)
        );
      });

      if (findCoverages.length > 0) {
        return riders.filter((rider) =>
          compareArrayOfStrings(possibleTypes, rider.riderType)
        );
      }
      return [];
    }
    case "palirVntg1": {
      const VALID_RIDER =
        compareStrings(agreementKeyAdmin, "VNTG1") &&
        compareStrings(palirRiderIndicator, "Y") &&
        IS_LIFE_AND_PERM;

      const findCoverages = agreementCoverages.filter((coverage) => {
        const { type = "", category = "", status = "" } = coverage;
        const possibleStatuses = ["ACT", "PDU"];
        return (
          compareStrings(type, "PALIR") &&
          compareStrings(category, "R") &&
          compareArrayOfStrings(possibleStatuses, status)
        );
      });

      if (!(VALID_RIDER && findCoverages.length > 0)) return [];

      return riders?.filter((rider) =>
        compareStrings("PALIR", rider.riderType)
      );
    }
    case "alirOpm": {
      const VALID_RIDER =
        compareStrings(agreementKeyAdmin, "OPM") &&
        compareStrings(alirRiderIndicator, "Y") &&
        IS_LIFE_AND_PERM;

      const findCoverages = agreementCoverages?.filter((coverage) => {
        const { type = "", category = "" } = coverage;
        return compareStrings(type, "ALIR") && compareStrings(category, "R");
      });

      if (!(VALID_RIDER && findCoverages.length > 0)) return [];

      return riders?.filter((rider) => compareStrings("ALIR", rider.riderType));
    }
    case "lisrVntg1": {
      const VALID_RIDER =
        compareStrings(agreementKeyAdmin, "VNTG1") &&
        compareStrings(lisrRiderIndicator, "Y") &&
        IS_LIFE_AND_PERM;

      const validCoverages = agreementCoverages.filter((coverage) => {
        const { type = "", category = "", status = "" } = coverage;
        const possibleTypes = ["LISR", "LISRS"];
        return (
          compareStrings(category, "R") &&
          compareArrayOfStrings(possibleTypes, type) &&
          compareStrings("ACT", status)
        );
      });

      if (!(VALID_RIDER && validCoverages.length > 0)) return [];

      return riders?.filter((rider) => {
        const possibleTypes = ["LISR", "LISRS"];
        return compareArrayOfStrings(possibleTypes, rider.riderType);
      });
    }
    case "lisrHap": {
      const possibleLisrTypes = ["LISR", "LISRS"];
      const VALID_RIDER =
        compareStrings(agreementKeyAdmin, "HAP") &&
        compareStrings(lisrRiderIndicator, "Y") &&
        IS_LIFE_AND_PERM;

      const validCoverages = agreementCoverages.filter((coverage) => {
        const { type = "", category = "", status = "" } = coverage;
        return (
          compareStrings(category, "R") &&
          compareArrayOfStrings(possibleLisrTypes, type) &&
          compareStrings("ACT", status)
        );
      });
      if (!(VALID_RIDER && validCoverages.length > 0)) return [];

      const billingRiders = riders?.filter((rider) =>
        compareArrayOfStrings(possibleLisrTypes, rider.riderType)
      );

      const billingRidersWithCoveragePremiumAmount = billingRiders.map(
        (billingRider) => {
          const correspondingCoverage = validCoverages.find((coverage) =>
            compareStrings(coverage.type, billingRider.riderType)
          );
          return {
            ...billingRider,
            coveragePremiumAmount: correspondingCoverage?.coveragePremiumAmount,
          };
        }
      );

      return billingRidersWithCoveragePremiumAmount;
    }
    case "alirHap": {
      const VALID_RIDER =
        compareStrings(agreementKeyAdmin, "HAP") &&
        compareStrings(alirRiderIndicator, "Y") &&
        IS_LIFE_AND_PERM;

      if (!VALID_RIDER) return [];

      const possibleAlirTypes = ["ALIR", "ALIRS"];

      const findCoverages = agreementCoverages.filter((coverage) => {
        const { type = "", category = "", status = "" } = coverage;

        return (
          compareStrings(category, "R") &&
          compareArrayOfStrings(possibleAlirTypes, type) &&
          compareStrings("ACT", status)
        );
      });

      if (findCoverages.length > 0) {
        const billingRiders = riders.filter((rider) =>
          compareArrayOfStrings(possibleAlirTypes, rider.riderType)
        );

        const billingRidersWithCoveragePremiumAmount = billingRiders.map(
          (billingRider) => {
            const correspondingCoverage = findCoverages.find((coverage) =>
              compareStrings(coverage.type, billingRider.riderType)
            );
            return {
              ...billingRider,
              coveragePremiumAmount:
                correspondingCoverage?.coveragePremiumAmount,
            };
          }
        );

        return billingRidersWithCoveragePremiumAmount;
      }
      return [];
    }
    default: {
      return [];
    }
  }
};

export const getArtistryBillingTable = (annuityRequest) => {
  const columns = [
    {
      accessor: "row",
      label: "",
      alignment: "left",
    },
    {
      accessor: "employee",
      label: "Employee",
      alignment: "left",
    },
    {
      accessor: "employer",
      label: "Employer",
      alignment: "left",
    },
    {
      accessor: "total",
      label: "Total",
      alignment: "left",
    },
  ];

  const employeeBilledPremium =
    annuityRequest?.data?.billing?.billedPremiumEE &&
    annuityRequest?.data?.billing?.billTypeDesc !== "No Bill"
      ? convertToNumber(annuityRequest?.data?.billing?.billedPremiumEE)
      : 0;
  const employerBilledPremium =
    annuityRequest?.data?.billing?.billedPremiumER &&
    annuityRequest?.data?.billing?.billTypeDesc !== "No Bill"
      ? convertToNumber(annuityRequest?.data?.billing?.billedPremiumER)
      : 0;

  const employeeContributions = annuityRequest?.data?.billing?.contributionsEE
    ? convertToNumber(annuityRequest?.data?.billing?.contributionsEE)
    : 0;
  const employerContributions = annuityRequest?.data?.billing?.contributionsER
    ? convertToNumber(annuityRequest?.data?.billing?.contributionsER)
    : 0;

  const rows = [
    {
      row: "Billed Premium",
      employee: formatMoney(employeeBilledPremium),
      employer: formatMoney(employerBilledPremium),
      total: formatMoney(employeeBilledPremium + employerBilledPremium),
    },
    {
      row: "Contributions",
      employee: formatMoney(employeeContributions),
      employer: formatMoney(employerContributions),
      total: formatMoney(employeeContributions + employerContributions),
    },
  ];

  return { columns, rows };
};

export const calculateFieldsDisplayForBillingAndPayments = ({
  isFlexIVFund1FPOrFund4FPContract,
  agreementKeyAdmin,
  statusReason,
  isVariableAnnuityResult,
  billType,
  kindCode,
}) => {
  let showBillingAndPayments = false;
  let showBilledToDate = false;
  let showVAFranchiseNumber = false;
  const isLIFCOMOrVNTGKC =
    compareStrings(agreementKeyAdmin, "VNTGKC") ||
    compareStrings(agreementKeyAdmin, "LIFCOM");
  if (
    isLIFCOMOrVNTGKC &&
    compareStrings(statusReason, "SN") &&
    isVariableAnnuityResult
  ) {
    showBillingAndPayments = false;
  } else {
    showBillingAndPayments = true;
  }

  if (isLIFCOMOrVNTGKC && !compareStrings(billType, "NBL")) {
    showBilledToDate = true;
  }

  if (
    (isVariableAnnuityResult &&
      compareStrings(billType, "LST") &&
      !compareArrayOfStrings(
        VARIABLE_ANNUITY_FLEX_EXTRA_KIND_CODES,
        kindCode
      )) ||
    isFlexIVFund1FPOrFund4FPContract
  ) {
    showVAFranchiseNumber = true;
  }

  return { showBillingAndPayments, showBilledToDate, showVAFranchiseNumber };
};

export const selectAnnuityBillingData = (props) => {
  const {
    isFlexIVFund1FPOrFund4FPContract,
    annuityData = {},
    agreementData,
    soaBillingData = {},
  } = props;
  const { paymentAmount, paymentModeDescShort, netPremiumAmount } =
    agreementData;

  const { billedToDate: soaBilledToDate, billNumber } = soaBillingData;

  const {
    billingModeDesc,
    billTypeDesc,
    totalBilledPremium,
    totalContributions,
    billedToDate: annuityBilledToDate,
    invoiceAccountNbr,
    billType,
  } = annuityData?.billing ? annuityData.billing : "";

  let billingData = {
    billingModeDesc,
    billTypeDesc,
    totalBilledPremium,
    totalContributions,
    billedToDate: annuityBilledToDate,
    invoiceAccountNbr,
    billType,
  };

  if (isFlexIVFund1FPOrFund4FPContract) {
    billingData = {
      ...billingData,
      billingModeDesc: paymentModeDescShort || "-",
      totalBilledPremium: paymentAmount || "-",
      totalContributions: netPremiumAmount || "-",
      billTypeDesc: "Unavailable",
      invoiceAccountNbr: billNumber || "-",
      billedToDate: soaBilledToDate,
    };
  }

  return billingData;
};

export const useGetRecurringPaymentsData = (props) => {
  const { data, paymentAppliedTo } = props;
  const { premiumBills, nextScheduledPaymentDate, loan, payments = [] } = data;

  let {
    bankAccountHolder,
    bankName,
    accountNumber,
    billedPremium,
    routingNumber,
    accountType,
    billingModeDesc,
    dateOfWithdrawal,
  } = data;

  const premiumBillDue = premiumBills?.find(
    (bill) => bill.currentBillAmountDue
  );

  let totalWithdrawalAmount = isDefined(premiumBillDue)
    ? premiumBillDue.currentBillAmountDue
    : billedPremium;

  if (compareStrings(paymentAppliedTo, "Premium/Interest")) {
    const pendingPayment = payments?.find((paymentEntry) =>
      compareStrings(paymentEntry.status, "PENDING")
    );
    dateOfWithdrawal = isDefined(pendingPayment)
      ? formatDate(
          pendingPayment.requestedPaymentDateAndTime,
          "",
          DATA_UNAVAILABLE
        )
      : formatDate(nextScheduledPaymentDate, "", DATA_UNAVAILABLE);
  }
  if (compareStrings(paymentAppliedTo, "Loan Principal")) {
    const pendingPayment = loan?.payments?.find((paymentEntry) =>
      compareStrings(paymentEntry.status, "PENDING")
    );

    dateOfWithdrawal = isDefined(pendingPayment)
      ? formatDate(
          pendingPayment.requestedPaymentDateAndTime,
          "",
          DATA_UNAVAILABLE
        )
      : formatDate(
          loan?.autoPay?.nextScheduledPaymentDate,
          "",
          DATA_UNAVAILABLE
        );

    bankAccountHolder = loan?.autoPay?.payment?.bankAccountHolder;
    totalWithdrawalAmount = loan?.autoPay?.amount;
    bankName = loan?.autoPay?.payment?.bankName;
    accountNumber = maskAccountNumber(loan?.autoPay?.payment?.accountNumber);
    billedPremium = NOT_APPLICABLE;
    routingNumber = loan?.autoPay?.payment?.routingNumber;
    accountType = loan?.autoPay?.payment?.accountType;
    billingModeDesc = loan?.autoPay?.billingModeDesc;
  }
  if (compareStrings(paymentAppliedTo, "Loan Interest")) {
    bankAccountHolder = loan?.autoPay?.payment?.bankAccountHolder;
    totalWithdrawalAmount = loan?.loanInterestAmount;
    bankName = loan?.autoPay?.payment?.bankName;
    accountNumber = maskAccountNumber(loan?.autoPay?.payment?.accountNumber);
    dateOfWithdrawal = formatDate(
      loan?.loanInterestDueDate,
      "",
      DATA_UNAVAILABLE
    );
    billedPremium = loan?.loanInterestAmount;
    routingNumber = loan?.autoPay?.payment?.routingNumber;
    accountType = loan?.autoPay?.payment?.accountType;
  }
  return {
    bankAccountHolder: formatName({
      nameObject: { fullName: bankAccountHolder },
      format: "firstNameDisplayedFirst",
      defaultReturn: DATA_UNAVAILABLE,
    }),
    totalWithdrawalAmount: formatMoney(totalWithdrawalAmount, DATA_UNAVAILABLE),
    bankName: isDefined(bankName) ? bankName : DATA_UNAVAILABLE,
    accountNumber: isDefined(accountNumber) ? accountNumber : DATA_UNAVAILABLE,
    dateOfWithdrawal,
    billedPremium: formatMoney(billedPremium, DATA_UNAVAILABLE),
    routingNumber: isDefined(routingNumber) ? routingNumber : DATA_UNAVAILABLE,
    accountType: isDefined(accountType)
      ? convertToTitleCase(accountType)
      : DATA_UNAVAILABLE,
    billingModeDesc: isDefined(billingModeDesc)
      ? billingModeDesc
      : DATA_UNAVAILABLE,
  };
};
