import { formatMoney } from "../../../../utils/format";
import {
  compareStrings,
  stringIsEmptyOrNullish,
} from "../../../../utils/string";

export const formatLisrOrSiprOPMData = (props) => {
  const { coverages } = props;

  const {
    currentAmountOYT,
    currentCoverage,
    paidUpInsurancePortionOfSIA,
    totalAnnualPremium,
  } = coverages[0];

  const UNAVAILABLE = "Unavailable";

  return {
    currentAmountOYT: formatMoney(currentAmountOYT, UNAVAILABLE),
    currentCoverage: formatMoney(currentCoverage, UNAVAILABLE),
    paidUpInsurancePortionOfSIA: formatMoney(
      paidUpInsurancePortionOfSIA,
      UNAVAILABLE
    ),
    totalAnnualPremium: formatMoney(totalAnnualPremium, UNAVAILABLE),
  };
};

export const formatAlirHapOrVntg1Data = (props) => {
  const { agreementData } = props;

  const {
    alirCurrentDividendEarnedPremAmount,
    alirCurrentDividendEarnedDivdAmount,
    alirTotPaidUpAddsCashValAmount,
    dividendPaidUpAddsCashValAmount,
    alirDividendAvailableAmount,
    alirDivOptPaidUpAddsAmount,
    currentDividendTypeDescription,
    agreementKeyAdmin,
  } = agreementData;

  let formattedCurrentDividendTypeDescription = currentDividendTypeDescription;
  if (compareStrings(agreementKeyAdmin, "VNTG1")) {
    formattedCurrentDividendTypeDescription = compareStrings(
      "Term / Paid Up Additions",
      currentDividendTypeDescription
    )
      ? "Paid Up Additions"
      : currentDividendTypeDescription;
  }

  return {
    currentDividendTypeDescription: stringIsEmptyOrNullish(
      formattedCurrentDividendTypeDescription
    )
      ? "Unavailable"
      : formattedCurrentDividendTypeDescription,
    alirCurrentDividendEarnedPremAmount: formatMoney(
      alirCurrentDividendEarnedPremAmount,
      "Unavailable"
    ),
    alirCurrentDividendEarnedDivdAmount: formatMoney(
      alirCurrentDividendEarnedDivdAmount,
      "Unavailable"
    ),
    alirTotPaidUpAddsCashValAmount: formatMoney(
      alirTotPaidUpAddsCashValAmount,
      "Unavailable"
    ),
    dividendPaidUpAddsCashValAmount: formatMoney(
      dividendPaidUpAddsCashValAmount,
      "Unavailable"
    ),
    alirDividendAvailableAmount: formatMoney(
      alirDividendAvailableAmount,
      "Unavailable"
    ),
    alirDivOptPaidUpAddsAmount: formatMoney(
      alirDivOptPaidUpAddsAmount,
      "Unavailable"
    ),
  };
};

export const formatAlirOpm = (alirOpmPolicyCoverages) => {
  const UNAVAILABLE = "Unavailable";
  const {
    currentDividendEarned,
    coverageTotalPUADividend,
    currentPUADividend,
    coverageTotalPUAPremium,
    currentPUAPremium,
    dividendTypeDescription,
  } = alirOpmPolicyCoverages?.[0] || [];

  return {
    dividendTypeDescription: dividendTypeDescription || UNAVAILABLE,
    nameField: "ALIR",
    currentDividendEarned: formatMoney(currentDividendEarned, UNAVAILABLE),
    coverageTotalPUADividend: formatMoney(
      coverageTotalPUADividend,
      UNAVAILABLE
    ),
    currentPUADividend: formatMoney(currentPUADividend, UNAVAILABLE),
    coverageTotalPUAPremium: formatMoney(coverageTotalPUAPremium, UNAVAILABLE),
    currentPUAPremium: formatMoney(currentPUAPremium, UNAVAILABLE),
  };
};

export const formatLisrVNTGData = (props) => {
  const {
    riderSumInsured,
    amountOfTermPurchased,
    lisrDividendCashValueAmount,
    lisrDivOptPaidUpAddsAmount,
  } = props;

  const UNAVAILABLE = "Unavailable";

  return {
    riderSumInsured: formatMoney(riderSumInsured, UNAVAILABLE),
    amountOfTermPurchased: formatMoney(amountOfTermPurchased, UNAVAILABLE),
    lisrDividendCashValueAmount: formatMoney(
      lisrDividendCashValueAmount,
      UNAVAILABLE
    ),
    lisrDivOptPaidUpAddsAmount: formatMoney(
      lisrDivOptPaidUpAddsAmount,
      UNAVAILABLE
    ),
  };
};

export const formatLisrHAPData = (coverages) => {
  const {
    currentAmountOYT,
    currentCoverage,
    paidUpInsurancePortionOfSIA,
    totalAnnualPremium,
    riderName,
    riderSumInsured,
    amountOfTermPurchased,
    lisrDividendCashValueAmount,
    lisrDivOptPaidUpAddsAmount,
  } = coverages[0];

  const UNAVAILABLE = "Unavailable";

  return {
    currentAmountOYT: formatMoney(currentAmountOYT, UNAVAILABLE),
    currentCoverage: formatMoney(currentCoverage, UNAVAILABLE),
    paidUpInsurancePortionOfSIA: formatMoney(
      paidUpInsurancePortionOfSIA,
      UNAVAILABLE
    ),
    totalAnnualPremium: formatMoney(totalAnnualPremium, UNAVAILABLE),
    riderName: riderName || UNAVAILABLE,
    riderSumInsured: formatMoney(riderSumInsured, UNAVAILABLE),
    amountOfTermPurchased: formatMoney(amountOfTermPurchased, UNAVAILABLE),
    lisrDividendCashValueAmount: formatMoney(
      lisrDividendCashValueAmount,
      UNAVAILABLE
    ),
    lisrDivOptPaidUpAddsAmount: formatMoney(
      lisrDivOptPaidUpAddsAmount,
      UNAVAILABLE
    ),
  };
};
