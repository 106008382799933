import React from "react";
import IsTruthy from "../logic/isTruthy";
import { isValidDPTxnId } from "../../../utils/evaluate";
import { copyClipboardData } from "../../../utils/copyClipboardData";
import { GetErrorReferenceValue } from "./hooks";
import { useAddOrRemoveChatLauncher } from "../../../utils/genesysChatClient";

const FiveHundredOneError = ({ transactionId }) => {
  const errorReferenceValue = transactionId || GetErrorReferenceValue();

  useAddOrRemoveChatLauncher({ page: "errorPage" });

  return (
    <div className="row">
      <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
        <picture className="justify-content-center mm-spacing">
          <span
            role="img"
            className="illustration-error_403"
            title="Error Site Down"
          />
        </picture>
        <p className="h1 mm-text-center">
          You don&apos;t have permission to access this page.
        </p>
        <div className="justify-content-center">
          <IsTruthy
            value={errorReferenceValue}
            validationFunction={isValidDPTxnId}
          >
            <div className="justify-content-center">
              <span>Error Reference </span>
              <span>&nbsp;</span>
              <span id="transactionId">{errorReferenceValue}</span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <button
                id="copy_icon_btn"
                className="btn btn-inline"
                type="button"
                onClick={() => copyClipboardData(errorReferenceValue)}
              >
                <span
                  className="icon-copy icon-sm"
                  aria-hidden="true"
                  title="Copy Transaction Id"
                />
                <strong>Copy</strong>
              </button>
            </div>
          </IsTruthy>
        </div>
      </div>
    </div>
  );
};

export default FiveHundredOneError;
