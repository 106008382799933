/* eslint-disable import/prefer-default-export */
export const processFinancialHistoryRequest = (props) => {
  const apiData = props?.response?.data || {};
  const agreement = apiData?.financialHistoryResponse?.agreement || {};
  const financialHistory = agreement?.financialHistory || [];

  const { adminSystemCode = "", financialHistoryAvailable = "" } = agreement;
  return {
    financialHistory,
    adminSystemCode,
    financialHistoryAvailable,
  };
};
