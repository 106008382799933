import { useParams } from "react-router-dom";
import { useState } from "react";
import { useMakeRequest } from "../../hooks/api";
import { compareStrings, trimStart } from "../../utils/string";
import { getOwnerFromCustomers } from "../../utils/findData";
import { formatName } from "../../utils/format";
import { isDefined } from "../../utils/evaluate";

export const useHeaderHelpDropdownProps = (props) => {
  const { user } = props;
  const params = useParams();
  const [isInitialClick, setIsInitialClick] = useState(true);

  const { agreementKey } = params;

  const producersRequest = useMakeRequest({
    apiRequest: "fetchProducersData",
    apiParams: { agreementKey },
    immediateRequest: false,
    dataSelector: "processProducersData",
    initiateDevModeDispatch: false,
  });

  const agreementRequest = useMakeRequest({
    apiRequest: "fetchAgreementData",
    apiParams: { agreementKey },
    immediateRequest: false,
    dataSelector: "processAgreementRequest",
    dependentRequests: [producersRequest],
    initiateDevModeDispatch: false,
  });

  const dataQualityRequestHref = createRequestHref({
    requestType: "DATA_QUALITY",
    agreementRequest,
    producersRequest,
    user,
  });

  const clientConsolidationRequestHref = createRequestHref({
    requestType: "CLIENT_CONSOLIDATION",
    agreementRequest,
    producersRequest,
    user,
  });

  const onHeaderHelpDropDownToggle = () => {
    if (isInitialClick) {
      agreementRequest.executeRequest();
      setIsInitialClick(false);
    }
  };

  return {
    requestHrefs: { dataQualityRequestHref, clientConsolidationRequestHref },
    onHeaderHelpDropDownToggle,
    requests: { agreementRequest, producersRequest },
  };
};

export const createRequestHref = (props) => {
  const { requestType, producersRequest, agreementRequest, user } = props;

  const { lineOfBusinessName, policyId, agreementCustomers } =
    agreementRequest?.data || {};

  const email = "ServiceNetRequests@iuo.MassMutual.com";

  const { owner } = getOwnerFromCustomers({ agreementCustomers });
  const ownerName = formatName({
    nameObject: owner,
    format: "firstNameDisplayedFirst",
  });

  const agencyId = getUserHomeAgencyId({ producersRequest, user });

  const requestTypeText = compareStrings(requestType, "DATA_QUALITY")
    ? "Data Quality Request"
    : "Client Consolidation Request";

  const subject = `ServiceNet: ${requestTypeText} from Agency Number: ${agencyId}`;

  const body = `Please provide the following information about the issue/ system function - %0D%0A%0D%0A
  Submitter Name: %0D%0A
  Producer's Name: %0D%0A
  Producer's AA Number: %0D%0A
  Preferred method of response (Phone/Email): %0D%0A
  Phone Number: %0D%0A
  Email Address: %0D%0A
  Line of Business: ${lineOfBusinessName}%0D%0A
  Policy/Contract Number: ${policyId}%0D%0A
  Client's Name(s): ${ownerName}%0D%0A
  Message:%0D%0A%0D%0A
  -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------%0D%0A
  Thank you for your request. The ServiceNet team will make every effort to respond to your request within 3 business days.%0D%0A
      `;

  const requestHref = `mailto:${email}?subject=${subject}&body=${body}`;

  return requestHref;
};

const getUserHomeAgencyId = (props) => {
  const { user, producersRequest } = props;
  const producers = producersRequest?.data?.producers || [];
  const producer = producers.find(
    (prod) =>
      compareStrings(
        prod?.firstName?.toLowerCase(),
        user?.firstName?.toLowerCase()
      ) &&
      compareStrings(
        prod?.lastName?.toLowerCase(),
        user?.lastName?.toLowerCase()
      )
  );

  if (!isDefined(producer)) return "";

  const trimmedAgencyId = trimStart(producer.homeAgencyId, "0");
  const paddedAgencyId = trimmedAgencyId.padStart(3, "0");
  return paddedAgencyId;
};
