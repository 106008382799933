import { useReducer } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import isEqual from "lodash.isequal";
import * as serviceNetApi from "../api";
import * as dataSelectors from "../api/dataSelectors/index";
import { useUserState } from "../contexts/user";
import { types, reducer, initialApiState } from "../reducers/apiReducer";
import { usePrevious } from "./helper";
import { useDevModeContext, types as devModeTypes } from "../contexts/devMode";
import { isDefined } from "../utils/evaluate";
import { compareStrings } from "../utils/string";

export const validateParams = (props) => {
  const { apiParams } = props;
  return Object.keys(apiParams).reduce((isValid, paramKey) => {
    const IS_NOT_VALID = !isValid;
    if (IS_NOT_VALID) {
      return isValid;
    }
    const IS_VALID = !!apiParams[paramKey];
    return IS_VALID;
  }, true);
};

export const useDidApiParamsChange = (props) => {
  const { apiParams, shouldRequest, immediateRequest, initialState, dispatch } =
    props;

  const prevApiParams = usePrevious(apiParams);
  const IS_AUTO_TRIGGERED = immediateRequest;
  const PARAMS_CHANGED = !isEqual(prevApiParams, apiParams);
  const NOT_WAITING = !shouldRequest;

  if (IS_AUTO_TRIGGERED && PARAMS_CHANGED && NOT_WAITING) {
    dispatch({ type: types.RESET, newState: initialState });
  }
};

export const getResponse = async (props) => {
  const { apiRequest, apiParams, headers, devModeInfo, dispatchInfo } = props;

  // Should Ignore Test Data
  const IS_NOT_VISIBLE = !devModeInfo.isVisible;
  const VISIBLE_AND_NOT_ON = devModeInfo.isVisible && !devModeInfo.isOn;
  if (IS_NOT_VISIBLE || VISIBLE_AND_NOT_ON) {
    return serviceNetApi[apiRequest]({
      apiParams,
      headers,
    });
  }
  const field = Object.keys(devModeInfo.api).reduce(
    (foundField, currentFieldKey) => {
      const currentField = devModeInfo.api[currentFieldKey];
      const ALREADY_FOUND_FIELD = isDefined(foundField.name);
      const IS_FOUND_FIELD = compareStrings(
        currentField.apiRequestName,
        apiRequest
      );

      if (ALREADY_FOUND_FIELD) return foundField;

      if (IS_FOUND_FIELD) return currentField;

      return foundField;
    },
    { name: "", value: "" }
  );

  if (compareStrings(field.name, "")) {
    return serviceNetApi[apiRequest]({
      apiParams,
      headers,
    });
  }

  if (devModeInfo.api[field.name].value === "{}") {
    const response = await serviceNetApi[apiRequest]({
      apiParams,
      headers,
    });
    dispatchInfo({
      type: devModeTypes.NEW_SAVE,
      newValue: JSON.stringify(response.data),
      formField: {
        name: field.name,
      },
    });

    return { data: JSON.parse(response.data) };
  }

  return { data: JSON.parse(devModeInfo.api[field.name].value) };
};

export const useMakeRequest = (props) => {
  const {
    apiRequest,
    apiParams = {},
    dataSelector = "processAll",
    immediateRequest,
    dataForSelectors = {},
    defaultReturn = dataSelectors[dataSelector](),
    dependentRequests = [],
    updateRequestCallback,
    canExecuteCallBack,
    initiateDevModeDispatch = true,
  } = props;

  const initialState = {
    ...initialApiState,
    paramsForSelectors: dataForSelectors,
    shouldRequest: immediateRequest,
    data: defaultReturn,
  };
  // const cache = useRef({});
  const [apiState, dispatch] = useReducer(reducer, initialState);
  const {
    isLoading,
    data,
    transactionId,
    error,
    shouldRequest,
    paramsForSelectors,
  } = apiState;
  useDidApiParamsChange({
    apiParams,
    shouldRequest,
    immediateRequest,
    initialState,
    dispatch,
  });

  const executeRequest = () => {
    dispatch({ type: types.START_REQUEST, apiRequest });
  };

  const updateRequest = (dependencyData) => {
    if (updateRequestCallback) {
      const newState = updateRequestCallback(dependencyData);
      dispatch({
        type: types.RESET,
        newState: { ...apiState, ...newState },
        apiRequest,
      });
    }
  };

  const canExecute = (dependencyData) => {
    if (canExecuteCallBack) {
      return canExecuteCallBack(dependencyData);
    }
    return true;
  };

  const hasExecutedAtLeastOnce = () =>
    transactionId !== initialApiState.transactionId;

  // const hasValidParams = validateParams({ apiParams });
  const hasValidDataForSelectors = validateParams({
    apiParams: paramsForSelectors,
  });
  const { user } = useUserState();
  const { devModeInfo, dispatchInfo } = useDevModeContext();

  useDeepCompareEffect(() => {
    dispatch({
      type: types.RESET,
      newState: { ...apiState, paramsForSelectors: dataForSelectors },
      apiRequest,
    });
  }, [dataForSelectors]);

  useDeepCompareEffect(() => {
    let cancelRequest = false;
    const makeRequest = async () => {
      try {
        dispatch({ type: types.STARTED_REQUEST, apiRequest });
        const headers = {
          Authorization: `Bearer ${user.accessToken}`,
        };
        const response = await getResponse({
          apiRequest,
          apiParams,
          headers,
          devModeInfo,
          dispatchInfo,
        });

        const processedData = dataSelectors[dataSelector]({
          paramsForSelectors,
          response,
        });

        if (cancelRequest) return;
        if (dependentRequests.length > 0) {
          dependentRequests.forEach((request) => {
            if (request.canExecute(processedData)) {
              request.updateRequest(processedData);
              request.executeRequest();
            }
          });
        }
        dispatch({
          type: types.SUCCESSFUL_REQUEST,
          apiRequest,
          data: processedData,
          transactionId:
            response.headers === undefined
              ? -1
              : response.headers.correlationid,
        });
      } catch (requestError) {
        if (cancelRequest) return;
        dispatch({
          type: types.FAILED_REQUEST,
          apiRequest,
          error: requestError,
          data: dataSelectors[dataSelector](requestError),
          transactionId:
            requestError?.response?.headers === undefined
              ? -1
              : requestError.response.headers.correlationid,
        });
      } finally {
        dispatch({ type: types.FINISHED_REQUEST, apiRequest });
      }
    };
    // removed hasValidParams from here and dependency array to fix requests triggerd in search
    if (shouldRequest && hasValidDataForSelectors) {
      makeRequest();
    }
    return function cleanup() {
      cancelRequest = true;
    };
  }, [
    shouldRequest,
    apiParams,
    apiRequest,
    dataSelector,
    paramsForSelectors,
    devModeInfo,
  ]);
  useDeepCompareEffect(() => {
    if (devModeInfo.isVisible && initiateDevModeDispatch)
      dispatch({ type: types.START_REQUEST });
  }, [devModeInfo]);
  return {
    isLoading,
    data,
    error,
    transactionId,
    executeRequest,
    updateRequest,
    canExecute,
    hasExecutedAtLeastOnce,
    dispatch,
  };
};

export { types };
