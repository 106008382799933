import React from "react";
import Label from "../../../common/forms/label";
import { useSelectRequestInformationAsOfDate } from "./hooks";
import InputDatePicker from "../../../common/datepicker/inputDatePicker";

const SelectRequestInformationAsOfDate = (props) => {
  const {
    requestInformationAsOfDate,
    datePickerMinDate,
    datePickerMaxDate,
    selectedDate,
    dateHandler,
    invalidDate,
  } = useSelectRequestInformationAsOfDate(props);
  return (
    <div className="row mm-spacing">
      <div className="col-12">
        <p className="h3">Select As of Date *</p>
        <Label formField={requestInformationAsOfDate}>As of Date *</Label>
        <div className="row">
          <div className="col-md-6">
            <InputDatePicker
              id="request_info_date_picker"
              datePickerMinDate={datePickerMinDate}
              datePickerMaxDate={datePickerMaxDate}
              selectedDate={selectedDate}
              dateHandler={dateHandler}
              invalidDate={invalidDate}
              errorId="requestInformationAsOfDate"
              errorMessage="Please select today’s date or a prior date."
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default SelectRequestInformationAsOfDate;
