import React from "react";
import LabelWithHelpModal from "../../common/forms/labelWithHelpModal";

const LoanRequestModalBody = () => (
  <div className="modal-body">
    <hr />
    <h5 id="directLink_LoanRequest_ModalTitle">Loan Request</h5>
    <p>
      The following transaction status are available on the{" "}
      <strong>website </strong>or <strong>mobile</strong> app:
    </p>
    <ul>
      <li>Request a loan (Cash and ACH/Direct Deposite available).</li>
      <li>Customers can check the status of their request</li>
      <li>Loan availability and principal balance available</li>
      <li>Ability to make one-time or recurring payments toward loan</li>
    </ul>
    <div className="mm-spacing--minor" />
    <h5 id="directLink_LoanRequest_ModalQuickTips">Quick Tips</h5>
    <ul>
      <li>Not applicable to annuities</li>
    </ul>
  </div>
);

const LoanRequestModal = () => (
  <LabelWithHelpModal
    text="Loan Request"
    modalTitle="Loan Request"
    modalId="loanRequest"
    modalBodyComponent={LoanRequestModalBody}
  />
);

export default LoanRequestModal;
