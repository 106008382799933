import React from "react";
import Input from "./input";
import Label from "./label";
import Errors from "./error";
import IsFalsy from "../logic/isFalsy";
import IsTruthy from "../logic/isTruthy";
import { stringIsEmptyOrNullish } from "../../../utils/string";
import { hasError } from "../../../utils/validation";
import { isDefined } from "../../../utils/evaluate";

const InputForm = (props) => {
  const { formField, useHook } = props;

  const classString = hasError(formField)
    ? "input-group is-invalid"
    : "input-group";

  return (
    <>
      <IsTruthy value={formField.hideLabel}>
        <IsTruthy value={isDefined(formField.prependText) && !formField.hidden}>
          <div className={classString}>
            <div className="input-group-prepend">
              <span
                id={`prependText_${formField.id}`}
                className="input-group-text"
              >
                {formField.prependText}
              </span>
            </div>
            <Input formField={formField} useHook={useHook} />
          </div>
          <Errors formField={formField} />
        </IsTruthy>
        <IsTruthy value={stringIsEmptyOrNullish(formField.prependText)}>
          <Input formField={formField} useHook={useHook} />
          <Errors formField={formField} />
        </IsTruthy>
      </IsTruthy>
      <IsFalsy value={formField.hideLabel}>
        <div className="form-group">
          <Label formField={formField} />
          <Input formField={formField} useHook={useHook} />
          <Errors formField={formField} />
        </div>
      </IsFalsy>
    </>
  );
};

export default React.memo(InputForm);
