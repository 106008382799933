import React, { useEffect, useState } from "react";
import { isDefined } from "../../utils/evaluate";
import IsTruthy from "../common/logic/isTruthy";
import { useFormContext } from "../../contexts/form";
import { useCancelModal } from "../common/popup/cancel";
import { useCanContinue } from "./hooks";
import { supportedTransactions } from "../../utils/translate";

const ModalControl = (props) => {
  const {
    lineOfBusinessCode,
    pageNumber,
    onSubmit,
    shouldDisableSubmit,
    error,
    showLoadingIcon,
  } = props;

  const { openCancelModal } = useCancelModal({
    transactionName: `${supportedTransactions.FillableFormLinks}`,
    lineOfBusinessCode,
    pageNumber,
  });
  const { formData } = useFormContext();
  const { shouldEnableContinue } = useCanContinue();
  const shouldDisableContinue = !shouldEnableContinue || shouldDisableSubmit;

  const [className, setClassName] = useState("btn btn-primary");
  const [buttonText, setButtonText] = useState("Continue");

  useEffect(() => {
    if (showLoadingIcon) {
      setClassName("btn btn-primary-loading");
      setButtonText("");
    } else {
      setClassName("btn btn-primary");
      setButtonText("Continue");
    }
  }, [showLoadingIcon]);

  return (
    <div className="mm-spacing">
      <IsTruthy value={error} validationFunction={isDefined}>
        <div
          className="alert alert-danger mm-spacing--minor"
          role="alert"
          aria-label={error}
        >
          {error}
        </div>
      </IsTruthy>
      <div className="mm-spacing--minor">
        <IsTruthy value={pageNumber === 1}>
          <button
            type="submit"
            id="btn-submit"
            className={className}
            disabled={shouldDisableContinue || showLoadingIcon}
            onClick={(e) => {
              e.preventDefault();
              onSubmit(formData);
            }}
          >
            {buttonText}
          </button>
        </IsTruthy>
        <IsTruthy value={pageNumber === 1}>
          <button
            type="button"
            id="btn-cancel"
            className="btn btn-link"
            onClick={openCancelModal}
          >
            Cancel
          </button>
        </IsTruthy>
      </div>
    </div>
  );
};

export default ModalControl;
