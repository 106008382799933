import React from "react";
import RadioForm from "../../common/forms/radioForm";
import IsFalsy from "../../common/logic/isFalsy";
import IsTruthy from "../../common/logic/isTruthy";

const BillingFrequencyCard = (props) => {
  const { formField, data } = props;
  const {
    premium,
    annualPremium,
    modalChargeAmount,
    aprPercentage,
    isCurrentBillingMode,
  } = data;

  return (
    <div className="card">
      <div className="card-body">
        <div>
          <IsFalsy value={isCurrentBillingMode}>
            <RadioForm formField={formField} />
          </IsFalsy>
          <IsTruthy value={isCurrentBillingMode}>
            <RadioForm formField={formField} badgeText="Current" />
          </IsTruthy>
        </div>
        <dl>
          <dt id={`policy_premium_${formField.id}_lbl`}>Premium</dt>
          <dd id={`policy_premium_${formField.id}_value`}>{premium}</dd>
          <dt id={`policy_annualizedAmount_${formField.id}_lbl`}>
            Annualized Amount
          </dt>
          <dd id={`policy_annualizedAmount_${formField.id}_value`}>
            {annualPremium}
          </dd>
          <dt id={`policy_modalCharge_${formField.id}_lbl`}>Modal Charge</dt>
          <dd id={`policy_modalCharge_${formField.id}_value`}>
            {modalChargeAmount}
          </dd>
          <dt id={`policy_apr_${formField.id}_lbl`}>APR</dt>
          <dd id={`policy_apr_${formField.id}_value`}>{aprPercentage} </dd>
        </dl>
      </div>
    </div>
  );
};

export default React.memo(BillingFrequencyCard);
