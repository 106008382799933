import React from "react";
import LabelWithHelpModal from "../../common/forms/labelWithHelpModal";

const PayBillModalBody = () => (
  <div className="modal-body">
    <hr />
    <h5 id="directLink_PayaBill_ModalTitle">Pay a Bill</h5>
    <p>
      The following can be done on the <strong>website </strong>or{" "}
      <strong>mobile</strong> app:
    </p>
    <ul>
      <li>One Time Premium, Interest, or Loan Principal Payment</li>
      <li>
        Recurring Premium/Interest or Loan Principal Payment (Set
        Up/Edit/Cancel)
      </li>
      <li>Set Up and Edit PAC (change ACH Bank Info)</li>
    </ul>
    <div className="mm-spacing--minor" />
    <p>
      The following can be done on the <strong>website</strong>:
    </p>
    <ul>
      <li>Manage bank accounts on file</li>
      <li>Turn off Loan Principal AutoPay</li>
      <li>Recurring Loan Interest Payment</li>
    </ul>
  </div>
);

const PayBillModal = () => (
  <LabelWithHelpModal
    text="Pay a Bill"
    modalTitle="Pay a Bill"
    modalId="payBill"
    modalBodyComponent={PayBillModalBody}
  />
);

export default PayBillModal;
