import React from "react";
import { useChangeBillingFrequencyAcknowledgementProps } from "../hooks";
import CheckboxForm from "../../common/forms/checkboxForm";

const ChangeBillingFrequencyAcknowledgement = () => {
  const { formData } = useChangeBillingFrequencyAcknowledgementProps();
  return (
    <div id="ChangeBillingFrequencyAcknowledgement" className="mm-spacing">
      <p className="h3">Acknowledgement</p>
      <CheckboxForm formField={formData.authorizedToChangeBillingFrequency} />
    </div>
  );
};
export default ChangeBillingFrequencyAcknowledgement;
