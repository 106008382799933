import React, { useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import DOMPurify from "dompurify";
import { isDefined, isValidDPTxnId } from "../../../utils/evaluate";
import { tealiumPageViewEvent } from "../../../utils/tealium";
import IsTruthy from "../logic/isTruthy";
import { copyClipboardData } from "../../../utils/copyClipboardData";
import { useUserState } from "../../../contexts/user";
import { isAnnuityContract } from "../../../utils/policies";
import { GetErrorReferenceValue } from "./hooks";
import { useAddOrRemoveChatLauncher } from "../../../utils/genesysChatClient";

const ErrorPage = (props) => {
  const {
    errorTitle,
    errorTitleLine2,
    errorTitleLine3,
    errorSubtitle,
    displayTransactionId = false,
    transactionId,
    agreementKey,
    errorPageFor,
    imageClass,
    errorTitleSize = "h1",
    errorTitleLine2Size = "h1",
    errorTitleLine3Size = "h1",
    displayBackToPolicy = false,
    lineOfBusinessCode,
  } = props;
  const errorReferenceValue = transactionId || GetErrorReferenceValue();
  const { agreementKey: agreementKeyFromUrl } = useParams();

  const {
    user: { email },
  } = useUserState();
  useEffect(() => {
    tealiumPageViewEvent({
      page: errorPageFor,
      category: agreementKey,
      custId: email,
    });
  }, []);

  useAddOrRemoveChatLauncher({ page: "errorPage" });

  const backToPolicyDisplayText = isAnnuityContract(lineOfBusinessCode)
    ? "Back to Contract Details"
    : "Back to Policy Details";

  return (
    <>
      <IsTruthy value={displayBackToPolicy}>
        <div className="container">
          <button id="go_back_btn" type="button" className="btn btn-link">
            <span className="icon-arrow-circle-spin-left" />
            <a
              id="go_back_link"
              href={`/policyDetails/${encodeURIComponent(
                agreementKey || agreementKeyFromUrl
              )}`}
            >
              {backToPolicyDisplayText}
            </a>
          </button>
        </div>
      </IsTruthy>
      <div className="row">
        <div className="col-12 col-sm-8 offset-sm-2 col-lg-8 offset-lg-2">
          <picture className="justify-content-center mm-spacing">
            <span role="img" className={imageClass} title={errorTitle} />
          </picture>
          <p
            className={`${errorTitleSize} justify-content-center mm-text-center`}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(errorTitle),
              }}
            />
          </p>
          <IsTruthy value={errorTitleLine2} validationFunction={isDefined}>
            <p
              className={`${errorTitleLine2Size} justify-content-center mm-text-center`}
            >
              <div dangerouslySetInnerHTML={{ __html: errorTitleLine2 }} />
            </p>
          </IsTruthy>
          <IsTruthy value={errorTitleLine3} validationFunction={isDefined}>
            <p
              className={`${errorTitleLine3Size} justify-content-center mm-text-center`}
            >
              {errorTitleLine3}
            </p>
          </IsTruthy>
          <p className="h4 justify-content-center mm-text-center">
            <span dangerouslySetInnerHTML={{ __html: errorSubtitle }} />
          </p>
          <div className="justify-content-center">
            <IsTruthy value={displayTransactionId}>
              <IsTruthy
                value={errorReferenceValue}
                validationFunction={isValidDPTxnId}
              >
                <div className="justify-content-center">
                  <span>Error Reference </span>
                  <span>&nbsp;</span>
                  <span id="transactionId">{errorReferenceValue}</span>
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <button
                    id="copy_icon_btn"
                    className="btn btn-inline"
                    type="button"
                    onClick={() => copyClipboardData(errorReferenceValue)}
                  >
                    <span
                      className="icon-copy icon-sm"
                      aria-hidden="true"
                      title="Copy Transaction Id"
                    />
                    <strong>Copy</strong>
                  </button>
                </div>
              </IsTruthy>
            </IsTruthy>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
