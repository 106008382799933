import React from "react";
import { JsonEditor as Editor } from "jsoneditor-react";
import { useJsonInputFormProps } from "./hooks";
import "jsoneditor-react/es/editor.min.css";

const JsonInput = (props) => {
  const {
    formField: { name, value },
    useHook = useJsonInputFormProps,
  } = props;
  const { onChange } = useHook(props);

  return (
    <Editor
      name={name}
      value={JSON.parse(value)}
      onChange={onChange}
      mode={Editor.modes.text}
      allowedModes={[Editor.modes.text, Editor.modes.tree]}
      htmlElementProps={{ style: { height: "700px" } }}
    />
  );
};

export default JsonInput;
