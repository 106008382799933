import { useState } from "react";
import { useParams } from "react-router-dom";
import { useUserState } from "../../../../contexts/user";
import { formatDate } from "../../../../utils/dates";

import {
  removeOwnerAndInsuredDuplicates,
  isTermPolicy,
  isULUVPolicy,
  isPallmPolicy,
  isNTLPolicy,
  isCoverpathPolicy,
  isLoanRestrictedSwlPolicy,
} from "../../../../utils/policies";
import { compareStrings } from "../../../../utils/string";
import { useLDValue } from "../../../common/featureFlags/hooks";
import { getUsers } from "../../../../utils/findData";
import { fireTealiumEvent } from "../../../../utils/tealium";
import { isDefined } from "../../../../utils/evaluate";
import { contactUSDetails } from "../../../../data/contactUsDetails";

export const useLifePolicyDetailProps = (props) => {
  const {
    apiRequests: { agreementRequest },
  } = props;

  const agreementData = agreementRequest.data;
  const {
    asOfDate,
    mecIndicator,
    agreementKeyAdmin,
    status,
    guaranteePeriodEndingIndicator,
    policyId,
    lineOfBusinessCode,
    annualPremiumFinanceIndicator,
  } = agreementData;
  const { agreementKey } = useParams();

  const guaranteePeriodEndingIndicatorEnabled = useLDValue({
    flagName: ["guaranteePeriodEndingIndicator"],
  });

  const formattedAsOfDate = formatDate(asOfDate, "monthCommaDayYear");
  const policyInForce = compareStrings(status, "IF");
  const showMecMessaging = compareStrings(mecIndicator, "Yes");
  const showPremiumFinanceIndBanner = compareStrings(
    annualPremiumFinanceIndicator,
    "Y"
  );
  const showCoverpathMigratedAlert = isCoverpathPolicy(agreementKeyAdmin);

  const isPallm = isPallmPolicy(agreementData);
  const isQuickActionUnsupported = isPallm;

  const displayKpi = policyInForce && !isPallm;
  const { user } = useUserState();
  const { email } = user;

  const [isDrawerOpened, setDrawerOpened] = useState(false);
  const [customer, setCustomer] = useState();
  const openDrawer = (customerObject) => () => {
    setCustomer(customerObject);
    setDrawerOpened(true);
  };
  const closeDrawer = () => setDrawerOpened(false);

  const printPDP = () => {
    fireTealiumEvent({
      label: "SVNT",
      action: "Print",
      category: "Policy Details",
      custId: email,
    });
    window.print();
  };

  const {
    COVERPATH: { number: coverpathContactNumber, time: coverpathContactTime },
  } = contactUSDetails;

  return {
    policyId,
    agreementKey,
    asOfDate: formattedAsOfDate,
    hiddenScrollSpySections: getHiddenScrollSpySection({ agreementRequest }),
    printPDP,
    showMecMessaging,
    mecIndicatorText,
    showGuaranteePeriodEndingIndicator:
      guaranteePeriodEndingIndicatorEnabled &&
      isDefined(guaranteePeriodEndingIndicator) &&
      compareStrings(guaranteePeriodEndingIndicator, "Yes"),
    isDrawerOpened,
    customer,
    openDrawer,
    closeDrawer,
    policyInForce,
    email,
    agreementData,
    isPallm,
    displayKpi,
    isQuickActionUnsupported,
    pallmPolicyDetailsText,
    showCoverpathMigratedAlert,
    showPremiumFinanceIndBanner,
    coverpathContactNumber,
    coverpathContactTime,
    lineOfBusinessCode,
  };
};

export const getHiddenScrollSpySection = (props) => {
  let hideLoan = false;
  let hideDividends = false;

  if (isTermPolicy(props)) {
    hideLoan = true;
    hideDividends = true;
  }

  if (isULUVPolicy(props)) {
    hideDividends = true;
  }

  if (isPallmPolicy(props?.agreementRequest?.data) && isNTLPolicy(props)) {
    hideDividends = true;
  }

  if (isLoanRestrictedSwlPolicy(props?.agreementRequest?.data)) {
    hideLoan = true;
  }

  return { hideLoan, hideDividends };
};

export const checkStatusAndReason = (props) => {
  const { status, statusReason } = props;
  if (!compareStrings(status, "IF")) {
    return false;
  }
  if (compareStrings(statusReason, "DP")) {
    return false;
  }
  return true;
};

export const checkStatusReasonAndHap = (props) => {
  const { data } = props;
  if (isCoverpathPolicy(data.agreementKeyAdmin)) {
    return false;
  }
  return checkStatusAndReason(data);
};

export const checkSpecialConditions = (props) => {
  const {
    agreementKeyAdmin,
    agreementCoverages,
    specialConditionCode,
    specialCondition2Code,
  } = props;
  const HAS_LTCIR =
    agreementCoverages.filter((item) => compareStrings(item.type, "LTCIR"))
      .length > 0;
  const NET_CASH_AND_SURRENDER_VAL_SPECIAL_CONDITION =
    compareStrings(agreementKeyAdmin, "VNTG1") &&
    HAS_LTCIR &&
    (compareStrings(specialCondition2Code, "LTCCL") ||
      compareStrings(specialConditionCode, "LTCCL"));
  return !NET_CASH_AND_SURRENDER_VAL_SPECIAL_CONDITION;
};

export const getTableRatingFromCustomers = (props) => {
  const { agreementCustomers } = props;
  const insuredCustomers =
    removeOwnerAndInsuredDuplicates(getUsers(agreementCustomers, "INSD")) || [];
  const selectedInsured = insuredCustomers.filter(
    (insured) => insured.roleSubType === "PRMR"
  );
  return selectedInsured.length > 0 &&
    selectedInsured[0].permanentTableRatingCode1
    ? selectedInsured[0].permanentTableRatingCode1
    : "None";
};

export const mecIndicatorText =
  "This policy is a Modified Endowment Contract (MEC). As a MEC, distributions are taxable to the extent of any gain in the policy and are subject to a 10% tax penalty if the owner is younger than age 59 ½, if the owner is a corporation, or, in some cases, if the owner is a trust. We are required to report any taxable income resulting from any distribution to the Internal Revenue Service on a form 1099-R. A taxable gain quote can be calculated upon your request.";
export const hapWebPolicyText = `Many Term Life insurance policies have and will continue to transition to a new administrative platform.  As a result, ServiceNet will not display certain information, such as beneficiary details, for these transitioned policies at this time. In addition, the Service History and Documents sections will only include information generated before the transition. ServiceNet also will not support online transactions, such as Address and Billing Frequency changes, for the transitioned policies. If you need any additional information, navigate to `;
export const hapPhonePolicyText =
  'Many Term Life insurance policies have and will continue to transition to a new administrative platform.  As a result, ServiceNet will not display certain information, such as beneficiary details, for these transitioned policies at this time. In addition, the Service History and Documents sections will only include information generated before the transition. ServiceNet also will not support online transactions, such as Address and Billing Frequency changes, for the transitioned policies. If you need any additional information, you may contact Coverpath Customer Service at <a href="tel:+1-866-957-5347">866-957-5347</a>, 8:00 AM - 6:00 PM ET, Monday through Friday.';
export const hapExtendedPageText =
  'Many Term Life insurance policies have and will continue to transition to a new administrative platform.  In addition, the Service History and Documents sections will only include information generated before the transition. If you need any additional information, you may contact Coverpath Customer Service at <a href="tel:+1-866-957-5347">866-957-5347</a>, 8:00 AM - 6:00 PM ET, Monday through Friday.';
export const pallmPolicyDetailsText =
  'For additional service or information on this policy please call <a href="tel:+1-844-475-7016">1-844-475-7016</a>, Monday - Friday 9 a.m. - 6 p.m. ET.';
