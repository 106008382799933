import React from "react";
import { format } from "date-fns";
import { useMakeRequest } from "../../../../hooks/api";
import { useModalState } from "../../../../contexts/modal";
import {
  formatDate,
  getDateDifference,
  isAfterDate,
  isSameDate,
} from "../../../../utils/dates";
import { redirectToUrl, scrollToElement } from "../../../../utils/dom";
import {
  compareStrings,
  compareArrayOfStrings,
  stringIsEmptyOrNullish,
} from "../../../../utils/string";
import {
  amountToDisplay,
  formatMoney,
  formatPercentage,
  convertToTitleCase,
  formatSupTagIfPresent,
} from "../../../../utils/format";
import KpiInfoPopover from "./kpiInfoPopover";
import KpiTableModal from "./kpiTableModal";
import {
  isTermPolicy,
  isULUVPolicy,
  isVariableLife,
  isCC1PolicyWithLTCIR,
  isVNTG1CC10112NBL,
  isDisabilityPolicy,
  isLifePolicy,
  isLoanRestrictedSwlPolicy,
  isAnnuityContract,
  isStableVoyageAnnuityProduct,
  isNonEnvisionAnnuity,
  isArtistryAnnuityProduct,
  isEquityEdgeAnnuityProduct,
  isFoundationSinglePremium,
  isFoundationFlexiblePremium,
  isFlexExtraAnnuityProduct,
  isRetireEaseContract,
  isRetireEaseChoiceContract,
  isFlexIVFund1FPOrFund4FP,
  isLifeTrustAnnuityProduct,
  isPanoramaAnnuityProduct,
  isPanoramaPassageAnnuityProduct,
  isPanoramaPlusAnnuityProduct,
  isPanoramaPremierAnnuityProduct,
  isTransitionsSelectAnnuityProduct,
  isTransitionsAnnuityProduct,
  isTransitionsCustomPackageAnnuityProduct,
  isEvolutionAnnuityProduct,
} from "../../../../utils/policies";
import {
  useIsFeatureOn,
  useHidePageFromUser,
  useLDValue,
} from "../../../common/featureFlags/hooks";
import { isDefined, isEmptyObject } from "../../../../utils/evaluate";
import {
  fireTealiumEvent,
  fireTealiumAndFollowLink,
} from "../../../../utils/tealium";
import { FrequencyOptionsModal } from "../billing/modals/frequencyOptionsModal";
import { useUserState } from "../../../../contexts/user";
import {
  getBillingModeData,
  getGroupDetailsForDI,
  getModalPremiumsForPolicy,
} from "../../../../utils/findData";
import { verifyChangeBillingFrequency } from "../../../changeBillingFrequency/restrictions";
import { checkHideLisrAlirBilledPremiumValues } from "../../../../utils/coverages";
import {
  DATA_UNAVAILABLE,
  FREE_AMOUNT_NOT_APPLICABLE_TOOLTIP_TEXT,
  FREE_WITHDRAWAL_NOT_APPLICABLE_TOOLTIP_TEXT,
  LISR_UNAVAILABLE_VALUE_TOOLTIP_TEXT,
  WITHIN_ONE_YEAR_OF_ISSUE_DATE_TOOLTIP_TEXT,
} from "../../../../constants/ui";
import ContractValuesKpiModal from "./annuityModals/contractValuesKpiModal";
import { useViewScheduleModal } from "../contractDetails/hooks";
import {
  useFormatLoanData,
  getMaxLoanAmountPopover,
} from "../loan/annuities/hooks";
import {
  GMAB_RIDER_NAMES,
  GMIB_PLUS_RIDER_NAMES,
} from "../../../../constants/logic";
import { deathBenefitOptionNameWithEeb } from "../../../../utils/translate";
import { convertToNumber } from "../../../../utils/number";

const getCardSizing = (totalCards) => {
  switch (totalCards) {
    case 3:
      return "4";
    case 2:
      return "6";
    case 1:
      return "6";
    default:
      return "12";
  }
};

const FIXED_FUND_NUMBERS = [
  "197",
  "138",
  "139",
  "041",
  "256",
  "276",
  "055",
  "069",
  "001",
];
// TODO: Remove once we have data for all useCashValueBreakdownModalProps sections
export const showDash = () => "-";
export const useCashValueBreakdownModalProps = (props) => {
  const {
    sectionName,
    agreementRequest: { data: agreementData },
  } = props;
  const vntgData = {
    rows: [
      {
        accessor: "cashSurrenderAmount",
        label: "Base Guaranteed Cash Value",
        formatFunction: amountToDisplay,
      },
      {
        accessor: "unearnedPremiumPaymentAmount",
        label: "Unearned/Past Due Premium",
        formatFunction: amountToDisplay,
      },
      {
        accessor: "dividendAccumAvailableAmount",
        label: "Dividend Accumulations",
        formatFunction: amountToDisplay,
      },
      {
        accessor: "netAnnualPremiumAmount",
        label: "Net Annual Premium",
        formatFunction: amountToDisplay,
      },
      {
        accessor: "paidUpAddsAvailableAmount",
        label: "Cash value of Base Paid-up additions purchases by Dividends",
        formatFunction: amountToDisplay,
      },
      {
        accessor: "alirDividendAvailableAmount",
        label: "Paid Up additions/ALIR Value",
        formatFunction: amountToDisplay,
      },
      {
        accessor: "lisrDividendCashValueAmount",
        label: "LISR Value",
        formatFunction: amountToDisplay,
      },
      {
        accessor: "less",
        label: "Less",
        formatFunction: showDash,
      },
      {
        accessor: "loanPayoffAmount",
        label: "Loan Payoff Value",
        formatFunction: amountToDisplay,
      },
    ],
    footer: [
      {
        accessor: "netSurrenderAmount",
        label: "Net Cash Value",
        formatFunction: amountToDisplay,
      },
    ],
  };
  const ltcirData = {
    rows: [
      {
        accessor: "cashSurrenderAmount",
        label: "Base Guaranteed Cash Value",
        formatFunction: amountToDisplay,
      },
      {
        accessor: "ltcirReturnPremiumAmount",
        label: "LTCIR Partial Return of Rider Premium",
        formatFunction: amountToDisplay,
      },
      {
        accessor: "dividendAccumAvailableAmount",
        label: "Dividend Accumulations",
        formatFunction: amountToDisplay,
      },
      {
        accessor: "paidUpAddsAvailableAmount",
        label: "Cash Value of Base Paid-Up Adds Purchased by Dividends",
        formatFunction: amountToDisplay,
      },
      {
        accessor: "less",
        label: "Less",
        formatFunction: showDash,
      },
      {
        accessor: "loanPayoffAmount",
        label: "Loan Payoff Value",
        formatFunction: amountToDisplay,
      },
    ],
    footer: [
      {
        accessor: "totalSurrenderForLtcirDataValue",
        label: "Surrender Value",
        formatFunction: amountToDisplay,
        showPopover: true,
        popoverId: "surrender_value_popover",
        popoverMessage:
          "The displayed Surrender Value includes the LTCIR Partial Return of Rider Premium.",
      },
    ],
    ltcirAdditionalData: [
      {
        accessor: "ltcirReduceAdditionsAmount",
        label: "LTCIR Reduced Paid Up Coverage",
        formatFunction: amountToDisplay,
        showPopover: true,
        popoverId: "ltcir_reduced_paid_up_popover",
        popoverMessage:
          "If the LTCIR Reduced Paid Up Coverage is selected at the time of surrender, the surrender value displayed above would be less the amount of LTCIR Partial Return of Rider Premium. " +
          "<br />The amount displayed in the LTCIR Reduced Paid Up Coverage field would be the remaining amount of Long Term Care coverage the client would be entitled to.",
      },
    ],
  };
  const mprData = {
    rows: [
      {
        accessor: "cashSurrenderAmount",
        label: "Gross Cash Value",
        formatFunction: amountToDisplay,
      },
      {
        accessor: "dividendAvailableAmount",
        label: "Dividend Value",
        formatFunction: amountToDisplay,
      },
      {
        accessor: "less",
        label: "Less",
        formatFunction: showDash,
      },
      {
        accessor: "loanPrincipal",
        label: "Loan Principal",
        formatFunction: amountToDisplay,
      },
      {
        accessor: "loanInterestAmount",
        label: "Loan Interest",
        formatFunction: amountToDisplay,
      },
    ],
    footer: [
      {
        accessor: "netSurrenderAmount",
        label: "Net Cash Value",
        formatFunction: amountToDisplay,
      },
    ],
  };
  const hapData = {
    rows: [
      {
        accessor: "cashSurrenderAmount",
        label: "Gross Cash Value",
        formatFunction: amountToDisplay,
      },
      {
        accessor: "dividendAvailableAmount",
        label: "Dividend Value",
        formatFunction: amountToDisplay,
      },
      {
        accessor: "loanPrincipal",
        label: "Loan Principal",
        formatFunction: amountToDisplay,
      },
      {
        accessor: "loanInterestAmount",
        label: "Loan Interest",
        formatFunction: amountToDisplay,
      },
    ],
    footer: [
      {
        accessor: "netSurrenderAmount",
        label: "Net Cash Value",
        formatFunction: amountToDisplay,
      },
    ],
  };
  const opmData = {
    rows: [
      {
        accessor: "basicAmount",
        label: "Basic Value",
        formatFunction: amountToDisplay,
      },
      {
        accessor: "cashValuePaidUpDividendAmount",
        label: "Dividend Value",
        formatFunction: amountToDisplay,
      },
      {
        accessor: "settlementDividendAmount",
        label: "Settlement Dividend",
        formatFunction: amountToDisplay,
      },
      {
        accessor: "less",
        label: "Less",
        formatFunction: showDash,
      },
      {
        accessor: "loanPrincipal",
        label: "Loan Principal",
        formatFunction: amountToDisplay,
      },
      {
        accessor: "loanInterestAmount",
        label: "Loan Interest",
        formatFunction: amountToDisplay,
      },
    ],
    footer: [
      {
        accessor: "netSurrenderAmount",
        label: "Net Cash Value",
        formatFunction: amountToDisplay,
      },
    ],
  };
  const data = {
    alirDividendAvailableAmount: agreementData.alirDividendAvailableAmount,
    basicAmount: agreementData.basicAmount,
    cashSurrenderAmount: agreementData.cashSurrenderAmount,
    cashValuePaidUpDividendAmount: agreementData.cashValuePaidUpDividendAmount,
    dividendAccumAvailableAmount: agreementData.dividendAccumAvailableAmount,
    dividendAvailableAmount: agreementData.dividendAvailableAmount,
    lisrDividendCashValueAmount: agreementData.lisrDividendCashValueAmount,
    loanInterestAmount: agreementData.loanInterestAmount,
    loanPayoffAmount: agreementData.loanPayoffAmount,
    loanPrincipal: agreementData.loanPrincipal,
    netAnnualPremiumAmount: agreementData.netAnnualPremiumAmount,
    netSurrenderAmount: agreementData.netSurrenderAmount,
    paidUpAddsAvailableAmount: agreementData.paidUpAddsAvailableAmount,
    settlementDividendAmount: agreementData.settlementDividendAmount,
    unearnedPremiumPaymentAmount: agreementData.unearnedPremiumPaymentAmount,
    ltcirReturnPremiumAmount: agreementData.ltcirReturnPremiumAmount,
    ltcirReduceAdditionsAmount: agreementData.ltcirReduceAdditionsAmount,
    totalSurrenderForLtcirDataValue: totalSurrenderForLtcirData(agreementData),
  };
  const vntgDisplayData = isCC1PolicyWithLTCIR({
    agreementRequest: { data: agreementData },
  })
    ? ltcirData
    : vntgData;

  const adminSections = {
    [sectionNames.vntg]: vntgDisplayData,
    [sectionNames.mpr]: mprData,
    [sectionNames.opm]: opmData,
    [sectionNames.hap]: hapData,
    default: { rows: [], footer: [] },
  };
  return {
    asOfDate: formatDate(agreementData.asOfDate, "monthCommaDayYear"),
    displayData: adminSections[sectionName],
    data,
    title: "Cash Values Breakdown",
    idPrefix: "cvb",
    isLTCIR: isCC1PolicyWithLTCIR({
      agreementRequest: { data: agreementData },
    }),
    agreementData,
  };
};

export const useSurrenderModalProps = (props) => {
  const { agreementData, email } = props;
  const policyIsVariableLife = isVariableLife(agreementData);
  const {
    agreementFunds,
    agreementKeyAdmin,
    productTypeCode,
    majorProductCode,
    minorProductCode,
  } = agreementData;
  const fixedFund = agreementFunds?.filter(
    (fund) => fund.investmentType === "FIX"
  );
  let interestRate = "";
  let GPACurrentInterestRate = "Unavailable";

  const partialSurrender = agreementData?.netSurrenderAmount
    ? agreementData.netSurrenderAmount * 0.75
    : undefined;

  if (compareArrayOfStrings(FIXED_FUND_NUMBERS, fixedFund?.[0]?.fundNumber)) {
    interestRate = fixedFund?.[0]?.fundGPAInterestRateFmtd;
  }

  if (!stringIsEmptyOrNullish(interestRate)) {
    GPACurrentInterestRate = formatPercentage({
      rate: parseInt(interestRate, 10),
    });
  }
  const rows = [
    {
      accessor: "partialSurrenderAmtDisplay",
      label: "Partial Surrender Value",
      formatFunction: amountToDisplay,
    },
    {
      accessor: "fundGPAInterestRateFmtd",
      label: "GPA Current Interest Rate",
    },
    {
      accessor: "totalAccountValue",
      label: "Account Value",
      formatFunction: amountToDisplay,
    },
    {
      accessor: "surrenderChargeAmount",
      label: "Surrender Charge",
      formatFunction: amountToDisplay,
    },
    {
      accessor: "loanPrincipal",
      label: "Loan Principal",
      formatFunction: amountToDisplay,
    },
    {
      accessor: "loanInterestAmount",
      label: "Loan Interest",
      formatFunction: amountToDisplay,
    },
  ];
  const footer = [
    {
      accessor: "netSurrenderAmount",
      label: "Surrender Value",
      formatFunction: amountToDisplay,
    },
  ];
  const hideLinksToFundsPage = useHidePageFromUser({
    pageName: "fundPage",
    agreementKeyAdmin,
    productTypeCode,
    majorProductCode,
    minorProductCode,
  });
  return {
    title: "Surrender Value Breakdown",
    idPrefix: "svb",
    asOfDate: formatDate(agreementData.asOfDate, "monthCommaDayYear"),
    displayData: { rows, footer, policyIsVariableLife },
    sentence: policyIsVariableLife &&
      !hideLinksToFundsPage && [
        "For additional funds information, ",
        <button
          className="btn btn-inline"
          id="surrender_value_modal_to_funds_link"
          type="button"
          onClick={() =>
            fireTealiumAndFollowLink({
              url: `/policyDetails/${encodeURIComponent(
                agreementData.agreementKey
              )}/funds`,
              action: "See Funds link clicked",
              category: "PDP",
              value: agreementData.policyId,
              custId: email,
            })
          }
        >
          see Funds.
        </button>,
      ],
    data: {
      fundGPAInterestRateFmtd: GPACurrentInterestRate,
      totalAccountValue: agreementData.totalAccountValue,
      surrenderChargeAmount: agreementData.surrenderChargeAmount,
      loanPrincipal: agreementData.loanPrincipal,
      loanInterestAmount: agreementData.loanInterestAmount,
      netSurrenderAmount: agreementData.netSurrenderAmount,
      partialSurrenderAmtDisplay: partialSurrender,
    },
  };
};
export const useAnnuitySurrenderModalProps = (props) => {
  const { annuityData, soaCdaValuesData, foundationPremiumContract } = props;

  let freeAmount = compareStrings(
    formatMoney(annuityData?.data?.surrenderCharge),
    formatMoney("0.00")
  )
    ? "Not Applicable"
    : formatMoney(annuityData?.data?.freeAmount, "-");
  let surrenderCharge = formatMoney(annuityData?.data?.surrenderCharge, "-");
  let surrenderValue = formatMoney(annuityData?.data?.surrenderValue, "-");

  if (foundationPremiumContract) {
    freeAmount = compareStrings(
      formatMoney(soaCdaValuesData?.data?.surrenderCharge),
      formatMoney("0.00")
    )
      ? "Not Applicable"
      : formatMoney(soaCdaValuesData?.data?.surrenderFreeAmount, "-");
    surrenderCharge = formatMoney(soaCdaValuesData?.data?.surrenderCharge, "-");
    surrenderValue = formatMoney(soaCdaValuesData?.data?.surrenderValue, "-");
  }
  const rows = [
    {
      accessor: "contractValue",
      label: "Contract Value",
    },
    {
      accessor: "freeAmount",
      label: "Free Amount",
    },
    {
      accessor: "surrenderCharge",
      label: "Surrender Charge",
    },
    {
      accessor: "surrenderValue",
      label: "Surrender Value",
    },
  ];

  return {
    title: "Surrender Value Breakdown",
    idPrefix: "asvb",
    displayData: {
      rows,
    },
    data: {
      contractValue: formatMoney(annuityData?.data?.contractValue, "-"),
      freeAmount,
      surrenderCharge,
      surrenderValue,
    },
    popoverId: "free_amount_tooltip",
    popoverText: FREE_AMOUNT_NOT_APPLICABLE_TOOLTIP_TEXT,
    notApplicableFreeAmountOrWithdrawal: compareStrings(
      freeAmount,
      "Not Applicable"
    ),
  };
};

export const useAnnuityValueBreakdownModalProps = (props) => {
  const {
    annuityData,
    agreementRequest,
    soaCdaValuesData,
    isFlexIVFund1OrFund4Contract,
  } = props;

  let surrenderCharge = annuityData?.data?.surrenderCharge;
  let freeAmount = annuityData?.data?.freeAmount;
  let surrenderValue = annuityData?.data?.surrenderValue;

  if (isFlexIVFund1OrFund4Contract) {
    surrenderCharge = soaCdaValuesData?.data?.surrenderCharge;
    freeAmount = soaCdaValuesData?.data?.surrenderFreeAmount;
    surrenderValue = soaCdaValuesData?.data?.surrenderValue;
  }

  const defaultFreeWithdrawalValue = isArtistryAnnuityProduct(
    agreementRequest?.data?.agreementKeyPrefix
  )
    ? "Unavailable"
    : "-";

  const freeWithdrawal = compareStrings(
    formatMoney(surrenderCharge),
    formatMoney("0.00")
  )
    ? "Not Applicable"
    : formatMoney(freeAmount, defaultFreeWithdrawalValue);

  const rows = [
    {
      accessor: "contractValue",
      label: "Contract Value",
    },
    {
      accessor: "contingentDeferredSalesCharge",
      label: "Contingent Deferred Sales Charge",
    },
    {
      accessor: "contractWithdrawalValue",
      label: "Contract Withdrawal Value",
    },
    {
      accessor: "freeWithdrawal",
      label: "Free Withdrawal",
    },
  ];

  const { closeModal } = useModalState();

  const handleSeeFundsButtonClick = async () => {
    await closeModal();
    scrollToElement("investments");
  };

  return {
    title: "Value Breakdown",
    idPrefix: "avb",
    displayData: { rows },
    data: {
      contractValue: formatMoney(annuityData?.data?.contractValue, "-"),
      contingentDeferredSalesCharge: formatMoney(surrenderCharge, "-"),
      contractWithdrawalValue: formatMoney(surrenderValue, "-"),
      freeWithdrawal,
    },
    popoverId: "kpi_free_withdrawal_tooltip",
    popoverText: FREE_WITHDRAWAL_NOT_APPLICABLE_TOOLTIP_TEXT,
    displayFundsLink: true,
    handleSeeFundsButtonClick,
    notApplicableFreeAmountOrWithdrawal: compareStrings(
      freeWithdrawal,
      "Not Applicable"
    ),
  };
};

export const useAnnuityRetireEasePayoutDetailsModalProps = (props) => {
  const { annuityData } = props;

  const rows = [
    {
      accessor: "nextPayoutDate",
      label: "Date of Next Payment",
    },
    {
      accessor: "maturityDate",
      label: "Period Certain End Date",
    },
  ];

  return {
    title: "Annuity Option Breakdown",
    idPrefix: "aob",
    displayData: { rows },
    data: {
      nextPayoutDate: isDefined(annuityData?.data?.nextPayoutDate)
        ? formatDate(annuityData?.data?.nextPayoutDate, "-")
        : "-",
      maturityDate: isDefined(annuityData?.data?.maturityDate)
        ? formatDate(annuityData?.data?.maturityDate, "-")
        : "-",
    },
  };
};

export const useBillingData = (props) => {
  const {
    agreementKey,
    agreementRequest: { data: agreementData },
  } = props;

  const billingRequest = useMakeRequest({
    apiRequest: "fetchBillingInformation",
    apiParams: { agreementKey },
    dataSelector: "processBillingInformation",
    dataForSelectors: {
      issueDate: agreementData.issueDate,
      jurisdictionState: agreementData.jurisdictionState,
    },
    immediateRequest: true,
  });
  return billingRequest;
};

const sectionNames = {
  vntg: "VNTG",
  mpr: "MPR",
  opm: "OPM",
  hap: "HAP",
};

export const totalSurrenderForLtcirData = (agreementData) => {
  const netSurrenderAmountValue = agreementData?.netSurrenderAmount
    ? convertToNumber(agreementData?.netSurrenderAmount)
    : 0;
  const ltcirReturnPremiumAmountValue = agreementData?.ltcirReturnPremiumAmount
    ? convertToNumber(agreementData?.ltcirReturnPremiumAmount)
    : 0;
  let totalSurrenderValueForLtcirData =
    netSurrenderAmountValue + ltcirReturnPremiumAmountValue;
  if (
    !agreementData?.netSurrenderAmount &&
    !agreementData?.ltcirReturnPremiumAmount
  ) {
    totalSurrenderValueForLtcirData = undefined;
  }
  return totalSurrenderValueForLtcirData;
};

export const getCashSectionName = (agreementData) => {
  const SHOW_VNTG_SECTIONS =
    compareStrings(agreementData.agreementKeyAdmin, "VNTG1") &&
    compareStrings(agreementData.majorProductCode, "PERM");
  const SHOW_MPR_SECTIONS =
    compareStrings(agreementData.agreementKeyAdmin, "MPR") &&
    compareStrings(agreementData.majorProductCode, "PERM");
  const SHOW_OPM_SECTIONS =
    compareStrings(agreementData.agreementKeyAdmin, "OPM") &&
    compareStrings(agreementData.majorProductCode, "PERM");
  const SHOW_HAP_SECTIONS =
    compareStrings(agreementData.agreementKeyAdmin, "HAP") &&
    compareStrings(agreementData.majorProductCode, "PERM");
  if (SHOW_VNTG_SECTIONS) {
    return sectionNames.vntg;
  }
  if (SHOW_MPR_SECTIONS) {
    return sectionNames.mpr;
  }
  if (SHOW_OPM_SECTIONS) {
    return sectionNames.opm;
  }
  if (SHOW_HAP_SECTIONS) {
    return sectionNames.hap;
  }
  return "default";
};

export const isCashValueAvailable = (props) => {
  const { agreementData } = props;
  return !(
    compareStrings(agreementData.majorProductCode, "TERM") &&
    compareArrayOfStrings(
      ["OPM", "MPR", "VNTG1", "HAP"],
      agreementData.agreementKeyAdmin
    )
  );
};
export const getAnnuitySurrChargeSchedule = (props) => {
  const { annuityData, agreementData } = props;
  const isStableVoyageProduct = isStableVoyageAnnuityProduct(
    annuityData?.productPrefix
  );

  const isPanoramaPremierContract = isPanoramaPremierAnnuityProduct(
    agreementData.kindCode
  );

  const surrChargeSchedule = annuityData?.surrChargeSchedule
    ? annuityData?.surrChargeSchedule
    : "-";
  let isStableVoyage1Year = false;
  if (isStableVoyageProduct) {
    const foundFund = annuityData?.funds?.fund?.find(
      (fund) => fund.balance > 0
    );
    const displayName = foundFund?.displayName || "";
    isStableVoyage1Year = compareStrings(
      displayName,
      "1 Year Guarantee Period"
    );
  }

  if (isStableVoyage1Year) return "No CDSC";
  if (isPanoramaPremierContract || isLifeTrustAnnuityContract(agreementData))
    return "View Schedule";
  return surrChargeSchedule;
};
export const getDeathBenefitOption = (props) => {
  const { annuityData, isTransitionsContract, isTransitionsCustomContract } =
    props;

  const dbRider = annuityData?.riderInfo?.find((rider) =>
    compareStrings("DB", rider.type)
  );

  const hasEebRider = annuityData?.riderInfo?.some((rider) =>
    compareStrings("EEB", rider.type)
  );

  if ((isTransitionsContract || isTransitionsCustomContract) && hasEebRider) {
    return getDeathBenefitOptionWithEeb({ dbRider }) || "Not Applicable";
  }
  return dbRider?.riderName || "Not Applicable";
};

export const useKpiModals = (props) => {
  const {
    agreementRequest: { data: agreementData },
    email,
    billingRequest: { data },
  } = props;

  const { openModal } = useModalState();
  const editBillingModalProps = {
    title: "Frequency Options",
    Component: FrequencyOptionsModal,
    componentProps: {
      ...data,
      agreementKey: agreementData.agreementKey,
      lineOfBusinessCode: agreementData.lineOfBusinessCode,
      agreementKeyAdmin: agreementData.agreementKeyAdmin,
    },
    modalSize: "lg",
  };

  const cashValueSectionName = getCashSectionName(agreementData);
  const breakdownModalProps = {
    useHook: useCashValueBreakdownModalProps,
    Component: KpiTableModal,
    componentProps: { ...props, sectionName: cashValueSectionName },
    modalSize: "md",
    title: isCC1PolicyWithLTCIR(props)
      ? "Surrender Value Breakdown"
      : "Cash Values Breakdown",
  };
  const surrenderModalProps = {
    useHook: useSurrenderModalProps,
    componentProps: { agreementData, email },
    Component: KpiTableModal,
    title: "Surrender Value Breakdown",
  };

  const showEditBillingModal = () => {
    fireTealiumEvent({
      action: "Billing Frequency Modal link clicked",
      category: "PDP",
      custId: email,
      value: agreementData.policyId,
    });
    openModal(editBillingModalProps);
  };
  const showBreakdownModal = () => {
    fireTealiumEvent({
      action: "Show Breakdown Modal link clicked",
      category: "PDP",
      custId: email,
      value: agreementData.policyId,
    });
    openModal(breakdownModalProps);
  };
  const showSurrenderModal = () => openModal(surrenderModalProps);
  return {
    showBreakdownModal,
    showEditBillingModal,
    showSurrenderModal,
  };
};
export const useAnnuityKpiModals = (props) => {
  const { agreementRequest, annuityRequest, cdaValuesRequest } = props;
  const agreementData = agreementRequest.data || {};
  const annuityData = annuityRequest || {};
  const soaCdaValuesData = cdaValuesRequest || {};

  const foundationPremiumContract = isFoundationPremiumContract(agreementData);
  const isFlexIVFund1OrFund4Contract = isFlexIVFund1FPOrFund4FP(
    agreementData?.kindCode
  );
  const { openModal } = useModalState();
  const annuitySurrenderModalProps = {
    useHook: useAnnuitySurrenderModalProps,
    componentProps: {
      annuityData,
      soaCdaValuesData,
      foundationPremiumContract,
      isFlexIVFund1OrFund4Contract,
    },
    Component: ContractValuesKpiModal,
    title: "Surrender Value Breakdown",
    modalSize: "md",
  };
  const showAnnuitySurrenderModal = () => openModal(annuitySurrenderModalProps);

  const annuityValueBreakdownModalProps = {
    useHook: useAnnuityValueBreakdownModalProps,
    componentProps: {
      annuityData: annuityRequest,
      agreementRequest,
      soaCdaValuesData,
      isFlexIVFund1OrFund4Contract,
    },
    Component: ContractValuesKpiModal,
    title: "Value Breakdown",
    modalSize: "lg",
  };
  const showAnnuityValueBreakdownModal = () =>
    openModal(annuityValueBreakdownModalProps);

  const annuityRetireEasePayoutDetailsModalProps = {
    useHook: useAnnuityRetireEasePayoutDetailsModalProps,
    componentProps: { annuityData: annuityRequest, agreementRequest },
    Component: ContractValuesKpiModal,
    title: "Payout Details",
    modalSize: "md",
  };
  const showAnnuityRetireEasePayoutDetailsModal = () =>
    openModal(annuityRetireEasePayoutDetailsModalProps);

  return {
    showAnnuitySurrenderModal,
    showAnnuityValueBreakdownModal,
    showAnnuityRetireEasePayoutDetailsModal,
  };
};
export const useKpiScrolling = () => {
  const {
    user: { email },
  } = useUserState();
  const scrollToLoan = () => {
    fireTealiumEvent({
      action: "View Loan Payoff link clicked",
      category: "PDP",
      custId: email,
    });
    scrollToElement("loans");
  };
  const scrollToBeneficiary = () => {
    fireTealiumEvent({
      action: "Beneficiary Arrangement link clicked",
      category: "PDP",
      custId: email,
    });
    scrollToElement("peopleOnContract");
  };
  const scrollToGroup = () => {
    fireTealiumEvent({
      action: "View Group Details link clicked",
      category: "PDP",
      custId: email,
    });
    scrollToElement("group_details_title");
  };
  return { scrollToLoan, scrollToGroup, scrollToBeneficiary };
};

export const useViewFrequencyModalLink = (props) => {
  const {
    billingRequest: {
      data: { paymentPreference },
    },
  } = props;

  const agreementData = props.agreementRequest.data || {};
  const billingData = props.billingRequest.data || {};

  const showLink = useIsFeatureOn("viewFrequencyOptionsLink");
  const modalPremiums = getModalPremiumsForPolicy({
    billingData,
    agreementData,
  });
  const { billingModeData } = getBillingModeData(modalPremiums);

  const transactionSupportedPolicies = useLDValue({
    flagName: ["transactionSupportedPolicies"],
  });
  const { isEligible, isTransactionAllowed } = verifyChangeBillingFrequency({
    agreementData,
    billingData,
    billingModeData,
    transactionSupportedPolicies,
  });

  const enableLink = isTransactionAllowed && isEligible;

  const tooltipText =
    !enableLink && compareStrings("LST", paymentPreference)
      ? "This information is not available online for List Billed policies."
      : "The information is not available online for this policy.";

  return { showLink, enableLink, tooltipText };
};
export const isFoundationPremiumContract = (agreementData) =>
  isFoundationSinglePremium(agreementData) ||
  isFoundationFlexiblePremium(agreementData);

export const isLifeTrustAnnuityContract = (agreementData) =>
  isLifeTrustAnnuityProduct(agreementData?.kindCode);
export const useGetKpiData = (props) => {
  const {
    agreementRequest,
    billingRequest,
    annuityRequest,
    cdaValuesRequest,
    modal,
    annuityModal = {},
    scrolling,
  } = props;
  const agreementData = agreementRequest.data;
  const billingData = billingRequest.data;
  const annuityData = annuityRequest?.data;
  const soaCdaValuesData = cdaValuesRequest?.data;
  const SHOW_LOAN_INFO = isEmptyObject(billingData.loan);
  const { kindCode } = agreementData;

  const lisrValueHidingEnabled = useLDValue({
    flagName: ["lisrPremiumValuesHiding"],
  });

  const { hideLisrBilledPremiumValues } = checkHideLisrAlirBilledPremiumValues({
    agreementData,
    paidToDate: billingRequest?.data?.paidToDate,
    lisrValueHidingEnabled,
  });

  const isVNTG1CC10112NBLResult = isVNTG1CC10112NBL({
    agreementRequest: {
      data: {
        agreementKeyAdmin: agreementData.agreementKeyAdmin,
        productTypeCode: agreementData.productTypeCode,
      },
    },
    billingRequest: { data: { billingMode: billingData.billingMode } },
  });
  const isNoneOrEmptyString = (value) => {
    if (
      compareStrings(value, "None") ||
      stringIsEmptyOrNullish(value) ||
      compareStrings(value, "Unavailable")
    ) {
      return "-";
    }
    return value;
  };

  const isArtistryAnnuityContract = isArtistryAnnuityProduct(
    agreementData?.agreementKeyPrefix
  );
  const isFlexExtraContract = isFlexExtraAnnuityProduct(
    agreementData?.kindCode
  );

  const isFlexIVFund1OrFund4Contract = isFlexIVFund1FPOrFund4FP(
    agreementData?.kindCode
  );

  const viewLoanText = SHOW_LOAN_INFO
    ? "View Loan Information"
    : "View Loan Payoff";

  const { termDate = "" } =
    agreementData.agreementCoverages.filter((coverage) =>
      compareStrings(coverage.category, "B")
    )[0] || {};

  let convertibilityStatus;
  if (
    !compareStrings(agreementData.status, "IF") ||
    compareStrings(agreementData.convertibleIndicator, "No")
  ) {
    convertibilityStatus = "Not Convertible";
  } else if (!isDefined(agreementData.convertibleIndicator)) {
    convertibilityStatus = "-";
  } else {
    convertibilityStatus = "Convertible";
  }

  const { showGroupDetails, groupName, discountPercent, groupAccountId } =
    getGroupDetailsForDI(agreementData);

  const { showLink, enableLink, tooltipText } = useViewFrequencyModalLink({
    agreementRequest,
    billingRequest,
  });

  const loanRestrictedSwlPolicy = isLoanRestrictedSwlPolicy(agreementData);

  const isNonEnvisionAnnuityContract = isNonEnvisionAnnuity(
    props?.agreementRequest
  );

  const isPanoramaSeriesContract =
    isPanoramaAnnuityProduct(kindCode) ||
    isPanoramaPassageAnnuityProduct(kindCode) ||
    isPanoramaPlusAnnuityProduct(kindCode) ||
    isPanoramaPremierAnnuityProduct(kindCode);

  const isPanoramaWithNoCdscModal =
    isPanoramaPassageAnnuityProduct(kindCode) ||
    isPanoramaPlusAnnuityProduct(kindCode);

  const isEquityEdgeAnnuityContract = isEquityEdgeAnnuityProduct(
    agreementData?.kindCode
  );

  let annuitySurrenderValue = formatMoney(annuityData?.surrenderValue, "-");
  let annuitySurrenderCharge = formatMoney(annuityData?.surrenderCharge, "-");
  let surrenderChargePeriod = getAnnuitySurrChargeSchedule({
    annuityData,
    agreementData,
  });
  let minimumGuaranteedInterestRate = formatPercentage({
    rate: agreementData.minimumGuaranteedInterestRate,
    decimalPlaces: 1,
  });

  let currentDeathBenefitAmount = formatMoney(
    annuityData?.currentDeathBenefit,
    "-"
  );
  const showSurrenderChargePeriodLink =
    surrenderChargePeriod !== "No CDSC" &&
    !isFlexIVFund1OrFund4Contract &&
    !isPanoramaWithNoCdscModal &&
    !isEquityEdgeAnnuityContract;

  if (isFoundationPremiumContract(agreementData)) {
    annuitySurrenderValue = formatMoney(soaCdaValuesData?.surrenderValue, "-");
    surrenderChargePeriod = isDefined(annuityData?.surrenderChargePeriod)
      ? `${annuityData?.surrenderChargePeriod} Years`
      : "-";
    minimumGuaranteedInterestRate = "4%";
    currentDeathBenefitAmount = formatMoney(
      soaCdaValuesData?.currentDeathBenefit,
      "-"
    );
  }
  if (isFlexIVFund1OrFund4Contract) {
    annuitySurrenderValue = formatMoney(soaCdaValuesData?.surrenderValue, "-");
    annuitySurrenderCharge = formatMoney(
      soaCdaValuesData?.surrenderCharge,
      "-"
    );
    surrenderChargePeriod = isDefined(annuityData?.surrenderChargePeriod)
      ? `${annuityData?.surrenderChargePeriod} Years`
      : "-";
    currentDeathBenefitAmount = formatMoney(
      soaCdaValuesData?.currentDeathBenefit,
      "-"
    );
  }
  const { showModal: showViewScheduleModal } = useViewScheduleModal({
    isNonEnvisionAnnuityContract,
    agreementData: agreementRequest.data,
    annuityData: annuityRequest?.data,
  });

  const isTransitionsSelectContract = isTransitionsSelectAnnuityProduct(
    agreementData?.kindCode
  );
  const isRetireEaseChoiceContractResult =
    isRetireEaseChoiceContract(agreementData);
  const isRetireEaseContractResult = isRetireEaseContract(agreementData);

  const isTransitionsContract =
    isTransitionsAnnuityProduct(agreementData?.kindCode) &&
    !isTransitionsCustomPackageAnnuityProduct(agreementData?.kindCode);
  const isEvolutionContract = isEvolutionAnnuityProduct(
    agreementData?.kindCode
  );

  const isTransitionsCustomContract = isTransitionsCustomPackageAnnuityProduct(
    agreementData?.kindCode
  );

  const { artistryLoanData, flexExtraData } =
    useFormatLoanData({
      annuityData,
      isArtistryAnnuityContract,
      isFlexExtraContract,
    }) || {};

  const maxLoanAmountPopover = getMaxLoanAmountPopover(artistryLoanData);

  const {
    displayFlexExtraLoanSectionUnavailableAlert,
    isActiveLoanOnContract,
    maxLoanAmountPopover: flexExtraLoanPopover,
    formattedMaxLoanAmount,
    formattedRemainingBalance,
    formattedPayoffAmount,
    formattedCurrentInterestRate,
    loanStatus,
  } = flexExtraData;

  let flexExtraLoanDetails;
  if (!displayFlexExtraLoanSectionUnavailableAlert) {
    if (!isActiveLoanOnContract) {
      flexExtraLoanDetails = {
        title: {
          label: "MAX LOAN AMOUNT",
          labelId: "max_loan_amount_lbl",
          value: flexExtraLoanPopover?.displaymaxLoanAmountPopover
            ? "Unavailable"
            : formattedMaxLoanAmount,
          tooltipText: flexExtraLoanPopover?.maxLoanAmountPopoverTexts,
          showTooltip: flexExtraLoanPopover?.displaymaxLoanAmountPopover,
          valueId: "max_loan_amount_value",
          generalId: "max_loan_amount",
        },
        detailOne: {
          label: "Current Loan Interest Rate",
          labelId: "current_loan_interest_rate_lbl",
          link: true,
          onClick: useViewInterestRates,
          value: "See Interest Rates",
          valueId: "current_loan_interest_rate_value",
        },
      };
    } else {
      flexExtraLoanDetails = {
        title: {
          label: "LOAN PRINCIPAL",
          labelId: "loan_principal_lbl",
          value: formattedRemainingBalance,
          valueId: "loan_principal_value",
          generalId: "loan_principal",
        },
        detailOne: {
          label: "Loan Payoff Amount",
          labelId: "loan_payoff_amount_lbl",
          value: formattedPayoffAmount,
          valueId: "loan_payoff_amount_value",
        },
        detailTwo: {
          label: "Loan Interest Rate",
          labelId: "loan_interest_rate_lbl",
          value: formattedCurrentInterestRate,
          valueId: "loan_interest_rate_value",
        },
        detailThree: {
          label: "Loan Status",
          labelId: "loan_status_lbl",
          value: loanStatus,
          valueId: "loan_status_value",
        },
      };
    }
  }

  const netCash = {
    sizing: 4,
    request: agreementRequest,
    title: {
      label: "NET CASH VALUE",
      labelId: "net_cast_value_lbl",
      value: formatMoney(agreementData.netSurrenderAmount, "-"),
      valueId: "net_cash_value",
      generalId: "net_cash",
    },
    detailOne: {
      component: KpiInfoPopover,
      label: "Current Dividend",
      labelId: "dividend_lbl",
      value: formatMoney(agreementData.currentDividendAmount, "-"),
      valueId: "dividend_value",
      popoverId: "current_dividend_tooltip",
      text: "The most recent dividend that was earned on the policy.",
    },
    detailTwo: {
      label: "Est. Death Benefit",
      labelId: "death_benefit_est_lbl",
      value: formatMoney(agreementData.netDeathBenefitAmount, "-"),
      valueId: "death_benefit_est_value",
    },
    button: {
      isVisible: isCashValueAvailable({ agreementData }),
      label: "View Breakdown",
      labelId: "view_breakdown_btn",
      onClick: modal.showBreakdownModal,
    },
  };
  const netCashNoDividend = {
    sizing: 3,
    request: agreementRequest,
    title: {
      label: "NET CASH VALUE",
      labelId: "net_cast_value_lbl",
      value: formatMoney(agreementData.netSurrenderAmount, "-"),
      valueId: "net_cash_value",
      generalId: "net_cash",
    },
    detailOne: {
      label: "Est. Death Benefit",
      labelId: "death_benefit_est_lbl",
      value: formatMoney(agreementData.netDeathBenefitAmount, "-"),
      valueId: "death_benefit_est_value",
    },
    button: {
      isVisible: isCashValueAvailable({ agreementData }),
      label: "View Breakdown",
      labelId: "view_breakdown_btn",
      onClick: modal.showBreakdownModal,
    },
  };
  const surrenderAmount = {
    sizing: 4,
    request: agreementRequest,
    title: {
      label: "Surrender Value",
      labelId: "net_surrender_amount_lbl",
      value: formatMoney(agreementData.netSurrenderAmount, "-"),
      valueId: "net_surrender_amount_value",
      generalId: "surrender_amount",
    },
    detailOne: {
      label: "Account Value",
      labelId: "total_account_value",
      value: formatMoney(agreementData.totalAccountValue, "-"),
      valueId: "total_account_value",
    },
    detailTwo: {
      label: "Death Benefit Opt.",
      labelId: "death_benefit_lbl",
      value: isNoneOrEmptyString(
        convertToTitleCase(agreementData.deathBenefitOption)
      ),
      valueId: "death_benefit_est_value",
    },
    button: {
      isVisible: true,
      label: "View Breakdown",
      labelId: "surrender_view_breakdown_btn",
      onClick: modal.showSurrenderModal,
    },
  };
  const billedPremium = {
    request: billingRequest,
    title: {
      label: "BILLED PREMIUM",
      labelId: "billed_premium_lbl",
      value: hideLisrBilledPremiumValues
        ? "Unavailable"
        : formatMoney(billingData.billedPremium, "-"),
      valueId: "billed_premium_value",
      showTooltip: hideLisrBilledPremiumValues,
      tooltipText: LISR_UNAVAILABLE_VALUE_TOOLTIP_TEXT,
      generalId: "billed_premium",
    },
    detailOne: {
      label: "Paid to Date",
      labelId: "paid_to_date_lbl",
      value: isNoneOrEmptyString(billingData.paidToDate),
      valueId: "paid_to_date_value",
    },
    detailTwo: {
      label: "Frequency",
      labelId: "bill_frequency_lbl",
      value: isVNTG1CC10112NBLResult
        ? "Single Premium"
        : isNoneOrEmptyString(billingData.billFrequency),
      valueId: "bill_frequency_value",
    },
    button: {
      isVisible: showLink,
      label: "View Frequency Options",
      labelId: "view_frequency_options_btn",
      onClick: modal.showEditBillingModal,
      buttonDisabled: !enableLink,
      showTooltip: !enableLink,
      tooltipText,
    },
  };
  const ulVlBilledPremium = {
    request: billingRequest,
    title: {
      label: "BILLED PREMIUM",
      labelId: "billed_premium_lbl",
      value: formatMoney(billingData.billedPremium, "-"),
      valueId: "billed_premium_value",
      generalId: "billed_premium",
    },
    detailOne: {
      label: "Billed to Date",
      labelId: "billed_to_date_lbl",
      value: formatDate(agreementData.paidToDate, "default", "-"),
      valueId: "billed_to_date_value",
    },
    detailTwo: {
      label: "Frequency",
      labelId: "bill_frequency_lbl",
      value: isNoneOrEmptyString(billingData.billFrequency),
      valueId: "bill_frequency_value",
    },
    button: {
      isVisible: showLink,
      label: "View Frequency Options",
      labelId: "view_frequency_options_btn",
      onClick: modal.showEditBillingModal,
      buttonDisabled: !enableLink,
      showTooltip: !enableLink,
      tooltipText,
    },
  };

  const loanAmount = {
    request: agreementRequest,
    title: {
      label: "MAX LOAN AMOUNT AVAILABLE",
      labelId: "max_loan_amount_lbl",
      value: loanRestrictedSwlPolicy
        ? DATA_UNAVAILABLE
        : formatMoney(agreementData.maximumAvailableLoanAmount, "-"),
      valueId: "max_loan_amount_value",
      showTooltip: loanRestrictedSwlPolicy,
      tooltipText: WITHIN_ONE_YEAR_OF_ISSUE_DATE_TOOLTIP_TEXT,
      generalId: "max_loan_amount",
    },
    detailOne: {
      label: "Interest Rate Type",
      labelId: "interest_rate_type_lbl",
      value: loanRestrictedSwlPolicy
        ? DATA_UNAVAILABLE
        : isNoneOrEmptyString(agreementData.loanInterestType),
      valueId: "interest_rate_type_value",
    },
    detailTwo: {
      label: "Current Rate",
      labelId: "current_interest_rate_lbl",
      value: loanRestrictedSwlPolicy
        ? DATA_UNAVAILABLE
        : formatPercentage({
            rate: agreementData.loanInterestRate,
            decimals: 2,
          }),
      valueId: "current_interest_rate_value",
    },
    button: {
      isVisible: !loanRestrictedSwlPolicy,
      label: viewLoanText,
      labelId: "view_loan_btn",
      onClick: scrolling.scrollToLoan,
    },
  };
  const termExpiration = {
    request: agreementRequest,
    title: {
      label: "Convertibility Status",
      labelId: "term_expiration__lbl",
      value: convertibilityStatus,
      valueId: "term_expiration__value",
      generalId: "term_expiration",
    },
    detailOne: {
      label: "Convertibility Exp. Date",
      labelId: "term_expiration__lbl",
      value: formatDate(agreementData.conversionEndDate, "default", "-"),
      valueId: "term_expiration__value",
    },
    detailTwo: {
      label: "Term Expiration Date",
      labelId: "term_expiration__lbl",
      value: formatDate(termDate, "default", "-"),
      valueId: "term_expiration__value",
    },
    button: {
      isVisible: false, // change this to TERM_POLICY_IS_CONVERTABLE when ready
      label: "Get a Term Conversion Quote",
      labelId: "term_expiration_get_quote_btn",
      // eslint-disable-next-line no-console
      onClick: () => console.log("Not working yet"),
    },
  };
  const netCashModified = {
    ...netCash,
    title: {
      label: "Surrender Value w/ LTCIR Partial Rider Premium Refund",
      labelId: "surrender_value_lbl",
      value: formatMoney(totalSurrenderForLtcirData(agreementData), "-"),
      valueId: "surrender_value",
      generalId: "surrender_value",
    },
  };
  const contractValue = {
    sizing: 4,
    request: annuityRequest,
    title: {
      label: "CONTRACT VALUE",
      labelId: "contract_value_lbl",
      value: formatMoney(annuityData?.contractValue, "-"),
      valueId: "contract_value_value",
      generalId: "contract_value",
    },
    detailOne: {
      label: "Contingent Deferred Sales Charge",
      labelId: "contingent_deferred_sales_charge_lbl",
      value: formatMoney(annuityData?.surrenderCharge, "-"),
      valueId: "contingent_deferred_sales_charge_value",
    },
    detailTwo: {
      label: "Contract Withdrawal Value",
      labelId: "contract_withdrawal_value_lbl",
      value: formatMoney(annuityData?.surrenderValue, "-"),
      valueId: "contract_withdrawal_value_value",
    },
    detailThree: {
      label: "CDSC Schedule",
      labelId: "cdsc_schedule_lbl",
      value: surrenderChargePeriod,
      valueId: "cdsc_schedule_value",
      link: showSurrenderChargePeriodLink,
      onClick: showViewScheduleModal,
    },
    button: {
      isVisible: true,
      label: "View Breakdown",
      labelId: "view_breakdown_btn",
      onClick: annuityModal.showAnnuityValueBreakdownModal,
    },
  };
  const contractSurrenderValue = {
    sizing: 4,
    request: annuityRequest,
    title: {
      label: "CONTRACT VALUE",
      labelId: "contract_value_lbl",
      value: formatMoney(annuityData?.contractValue, "-"),
      valueId: "contract_value_value",
      generalId: "contract_value",
    },
    detailOne: {
      label: "Surrender Value",
      labelId: "surrender_value_lbl",
      value: annuitySurrenderValue,
      valueId: "surrender_value_value",
    },
    detailTwo: {
      label: "Surrender Charge Period",
      labelId: "surrender_charge_period_lbl",
      value: surrenderChargePeriod,
      valueId: "surrender_charge_period_value",
      link: showSurrenderChargePeriodLink,
      onClick: showViewScheduleModal,
    },
    detailThree: {
      label: "Current Guaranteed Minimum Interest Rate",
      labelId: "current_guaranteed_minimum_interest_rate_lbl",
      value: minimumGuaranteedInterestRate,
      valueId: "current_guaranteed_minimum_interest_rate_value",
    },
    button: {
      isVisible: true,
      label: "View Surrender Value Breakdown",
      labelId: "view_surrender_value_breakdown_btn",
      onClick: annuityModal.showAnnuitySurrenderModal,
    },
  };
  const contractWithdrawalValue = {
    sizing: 4,
    request: annuityRequest,
    title: {
      label: "CONTRACT WITHDRAWAL VALUE",
      labelId: "contract_withdrawal_value_lbl",
      value: annuitySurrenderValue,
      valueId: "contract_withdrawal_value_value",
      generalId: "contract_withdrawal_value",
    },
    detailOne: {
      label: "Contingent Deferred Sales Charge",
      labelId: "contingent_deferred_sales_charge_lbl",
      value: annuitySurrenderCharge,
      valueId: "contingent_deferred_sales_charge_value",
    },
    detailTwo: {
      label: "CDSC Schedule",
      labelId: "cdsc_schedule_lbl",
      value: surrenderChargePeriod,
      valueId: "cdsc_schedule_value",
      link: showSurrenderChargePeriodLink,
      onClick: showViewScheduleModal,
    },
    button: {
      isVisible: true,
      label: "View Breakdown",
      labelId: "view_breakdown_btn",
      onClick: annuityModal.showAnnuityValueBreakdownModal,
    },
  };

  const annuityLoanAmount = {
    sizing: 4,
    request: annuityRequest,
    title: {
      label: "MAX LOAN AMOUNT",
      labelId: "max_loan_amount_lbl",
      value: maxLoanAmountPopover.displaymaxLoanAmountPopover
        ? "Unavailable"
        : formatMoney(artistryLoanData?.maxLoanAmount, "-"),
      valueId: "max_loan_amount_value",
      generalId: "max_loan_amount_value",
      tooltipText: maxLoanAmountPopover.maxLoanAmountPopoverTexts,
      showTooltip: maxLoanAmountPopover.displaymaxLoanAmountPopover,
    },
    detailOne: {
      label: "Max Loan Quote Effective Date",
      labelId: "max_loan_quote_effective_date_lbl",
      value: artistryLoanData?.maxLoanAsOfDate,
      valueId: "max_loan_quote_effective_date_value",
    },
    detailTwo: {
      label: "Number of Outstanding Loans",
      labelId: "number_outstanding_loans_lbl",
      value: artistryLoanData?.outstandingLoanCount,
      valueId: "number_outstanding_loans_value",
    },
    button: {
      isVisible: artistryLoanData?.outstandingLoanCount > 0,
      label: "Loan Details",
      labelId: "loan_details_btn",
      onClick: scrolling.scrollToLoan,
    },
  };
  const currentDeathBenefit = {
    sizing: 3,
    request: annuityRequest,
    title: {
      label: "CURRENT DEATH BENEFIT",
      labelId: "current_death_benefit_lbl",
      value: currentDeathBenefitAmount,
      valueId: "current_death_benefit_value",
      generalId: "net_cash",
    },
    detailOne: {
      label: "Death Benefit Option",
      labelId: "death_benefit_option_lbl",
      value: getDeathBenefitOption({
        annuityData,
        isTransitionsContract,
        isTransitionsCustomContract,
      }),
      valueId: "death_benefit_option_value",
    },
    button: {
      isVisible: showLink,
      label: "Beneficiary Arrangement",
      labelId: "view_frequency_options_btn",
      onClick: scrolling.scrollToBeneficiary,
    },
  };
  let annuityRetireEasePayOutKPIData = "";
  const asOfDate = agreementData?.asOfDate || format(new Date(), "yyyy-MM-dd");

  if (isRetireEaseContractResult) {
    const title = {
      label: "Annuity Payout Amount",
      labelId: "annuity_payout_amount_lbl",
      value: formatMoney(annuityData?.benefitAmount, "-"),
      valueId: "annuity_payout_amount_value",
    };
    const paymentMode = {
      label: "Payment Mode",
      labelId: "annuity_payment_mode_lbl",
      value: formatMoney(annuityData?.paymentMode, "-"),
      valueId: "annuity_payment_mode_value",
    };
    const annuityOption = {
      label: "Annuity Option",
      labelId: "annuity_option_lbl",
      value: getFormattedAnnuityOption(annuityData),
      valueId: "annuity_option_value",
    };

    if (
      isRetireEaseChoiceContractResult &&
      (isSameDate(annuityData?.maturityDate, asOfDate) ||
        isAfterDate(annuityData?.maturityDate, asOfDate))
    ) {
      annuityRetireEasePayOutKPIData = {
        sizing: 3,
        request: annuityRequest,
        title,
        detailOne: {
          label: "Annuity Date",
          labelId: "annuity_date_lbl",
          value: formatDate(annuityData?.maturityDate, "", "-"),
          valueId: "annuity_date_value",
        },
        detailTwo: paymentMode,
        detailThree: annuityOption,
        button: {
          isVisible: false,
        },
      };
    } else {
      annuityRetireEasePayOutKPIData = {
        sizing: 3,
        request: annuityRequest,
        title,
        detailOne: paymentMode,
        detailTwo: annuityOption,
        button: {
          isVisible: true,
          label: "View Payout Details",
          labelId: "view_annuity_payout_details_btn",
          onClick: annuityModal.showAnnuityRetireEasePayoutDetailsModal,
        },
      };
    }
  }

  const groupDetails = {
    request: agreementRequest,
    title: {
      label: "GROUP DETAILS",
      labelId: "group_details_lbl",
      value: groupName,
      valueId: "group_details_value",
      generalId: "group_details",
    },
    detailOne: {
      label: "Discount",
      labelId: "discount_lbl",
      value: formatPercentage({
        rate: discountPercent,
        decimalPlaces: 0,
      }),
      valueId: "discount_value",
    },
    detailTwo: {
      label: "Group Number",
      labelId: "group_Number_lbl",
      value: groupAccountId,
      valueId: "group_Number_value",
    },
    button: {
      isVisible: true,
      label: "View Group Information",
      labelId: "view_group_btn",
      onClick: scrolling.scrollToGroup,
    },
  };

  const riderInfo = annuityData?.riderInfo?.find(
    (rider) =>
      compareStrings(rider.type, "GMIB") ||
      compareArrayOfStrings(GMAB_RIDER_NAMES, rider.riderName) ||
      compareStrings(rider.type, "GMWB") ||
      compareStrings(rider.type, "LIP")
  );

  let gmibRiderDetails;
  if (
    compareArrayOfStrings(GMIB_PLUS_RIDER_NAMES, riderInfo?.riderName) &&
    isDefined(riderInfo?.riderAvailWithdrawAmt)
  ) {
    gmibRiderDetails = {
      detailOne: {
        label: "Available Withdrawal Amount",
        labelId: "available_withdrawal_amount_lbl",
        value: formatMoney(riderInfo?.riderAvailWithdrawAmt),
        valueId: "available_withdrawal_amount",
      },
      detailTwo: {
        label: "Benefit Amount",
        labelId: "benefit_amount_lbl",
        value: formatMoney(riderInfo?.benefitAmt, "-"),
        valueId: "benefit_amount_value",
      },
      detailThree: {
        label: "Rider Version",
        labelId: "rider_version_lbl",
        value: formatSupTagIfPresent(riderInfo?.riderName),
        valueId: "rider_version_value",
      },
    };
  } else {
    gmibRiderDetails = {
      detailOne: {
        label: "Benefit Amount",
        labelId: "benefit_amount_lbl",
        value: formatMoney(riderInfo?.benefitAmt, "-"),
        valueId: "benefit_amount_value",
      },
      detailTwo: {
        label: "Rider Version",
        labelId: "rider_version_lbl",
        value: formatSupTagIfPresent(riderInfo?.riderName),
        valueId: "rider_version_value",
      },
    };
  }

  const gmibRider = {
    sizing: 4,
    request: annuityRequest,
    title: {
      label: "CONTRACT FEATURES",
      labelId: "contract_features_lbl",
      value: "Guaranteed Minimum Income Benefit",
      valueId: "contract_features_value",
      generalId: "contract_features",
    },
    ...gmibRiderDetails,
    button: {
      isVisible: false,
    },
  };
  const gmabRider = {
    sizing: 4,
    request: annuityRequest,
    title: {
      label: "CONTRACT FEATURES",
      labelId: "contract_features_lbl",
      value: "Guaranteed Minimum Accumulation Benefit",
      valueId: "contract_features_value",
      generalId: "contract_features",
    },
    detailOne: {
      label: "Status",
      labelId: "status_lbl",
      value: "Active",
      valueId: "status_value",
    },
    detailTwo: {
      label: "Rider Version",
      labelId: "rider_version_lbl",
      value: formatSupTagIfPresent(riderInfo?.riderName),
      valueId: "rider_version_value",
    },
    detailThree: {
      label: "Effective Date",
      labelId: "effective_date_lbl",
      value: formatDate(riderInfo?.riderIssueDate, "default", "-"),
      valueId: "effective_date_value",
    },
    button: {
      isVisible: false,
    },
  };
  const gmwbRider = {
    sizing: 4,
    request: annuityRequest,
    title: {
      label: "CONTRACT FEATURES",
      labelId: "contract_features_lbl",
      value: "Guaranteed Minimum Withdrawal Benefit",
      valueId: "contract_features_value",
      generalId: "contract_features",
    },
    detailOne: {
      label: "Available Withdrawal Amount",
      labelId: "available_withdrawal_amount_lbl",
      value: formatMoney(riderInfo?.riderAvailWithdrawAmt, "-"),
      valueId: "available_withdrawal_amount_value",
    },
    detailTwo: {
      label: "Benefit Amount",
      labelId: "benefit_amount_lbl",
      value: formatMoney(riderInfo?.benefitAmt, "-"),
      valueId: "benefit_amount_value",
    },
    detailThree: {
      label: "Rider Version",
      labelId: "rider_version_lbl",
      value: formatSupTagIfPresent(riderInfo?.riderName),
      valueId: "rider_version_value",
    },
    button: {
      isVisible: false,
    },
  };

  const lipRider = {
    sizing: 2,
    request: annuityRequest,
    title: {
      label: "Contract Features",
      labelId: "contract_features_lbl",
      value: "Lifetime Income Protector",
      valueId: "contract_features_value",
      generalId: "contract_features",
    },
    detailOne: {
      label: "Rider Version",
      labelId: "rider_version_lbl",
      value: formatSupTagIfPresent(riderInfo?.riderName),
      valueId: "rider_version_value",
    },
    button: {
      isVisible: false,
    },
  };

  const flexExtraLoan = {
    sizing: 4,
    request: annuityRequest,
    ...flexExtraLoanDetails,
    button: {
      isVisible: false,
    },
  };

  if (isDisabilityPolicy(agreementData)) {
    return showGroupDetails ? [billedPremium, groupDetails] : [billedPremium];
  }
  if (isTermPolicy({ agreementRequest })) {
    return [billedPremium, termExpiration];
  }
  if (isULUVPolicy({ agreementRequest })) {
    return [surrenderAmount, ulVlBilledPremium, loanAmount];
  }
  if (isCC1PolicyWithLTCIR({ agreementRequest })) {
    return [netCashModified, billedPremium, loanAmount];
  }
  if (isLifePolicy(agreementData)) {
    return [netCashNoDividend, billedPremium, loanAmount];
  }
  if (isArtistryAnnuityContract) {
    return [contractWithdrawalValue, currentDeathBenefit, annuityLoanAmount];
  }
  if (
    isFlexIVFund1OrFund4Contract ||
    isPanoramaSeriesContract ||
    isTransitionsContract
  ) {
    return [contractWithdrawalValue, currentDeathBenefit];
  }
  if (
    isEquityEdgeAnnuityContract ||
    isLifeTrustAnnuityContract(agreementData)
  ) {
    return [contractWithdrawalValue, currentDeathBenefit];
  }
  if (
    isTransitionsSelectContract ||
    isEvolutionContract ||
    isTransitionsCustomContract
  ) {
    if (compareStrings(riderInfo?.type, "GMIB")) {
      return [contractWithdrawalValue, gmibRider, currentDeathBenefit];
    }
    if (compareArrayOfStrings(GMAB_RIDER_NAMES, riderInfo?.riderName)) {
      return [contractWithdrawalValue, gmabRider, currentDeathBenefit];
    }
    if (
      compareStrings(riderInfo?.type, "GMWB") &&
      !isTransitionsCustomContract
    ) {
      return [contractWithdrawalValue, gmwbRider, currentDeathBenefit];
    }
    if (
      compareStrings(riderInfo?.type, "LIP") &&
      isTransitionsSelectContract &&
      !isTransitionsCustomContract
    ) {
      return [contractWithdrawalValue, lipRider, currentDeathBenefit];
    }
    return [contractWithdrawalValue, currentDeathBenefit];
  }
  if (isFlexExtraContract) {
    if (!displayFlexExtraLoanSectionUnavailableAlert) {
      return [contractValue, currentDeathBenefit, flexExtraLoan];
    }
    return [contractValue, currentDeathBenefit];
  }
  if (isAnnuityContract(agreementData?.lineOfBusinessCode)) {
    if (isRetireEaseContractResult) {
      return [annuityRetireEasePayOutKPIData];
    }
    return [contractSurrenderValue, currentDeathBenefit];
  }
  return [netCash, billedPremium, loanAmount];
};

export const useKpiProps = (props) => {
  const {
    agreementRequest,
    billingRequest,
    annuityRequest = {},
    cdaValuesRequest = {},
  } = props;

  const modal = useKpiModals(props);
  const annuityModal = useAnnuityKpiModals(props);
  const scrolling = useKpiScrolling();

  const kpiData = useGetKpiData({
    agreementRequest,
    billingRequest,
    annuityRequest,
    cdaValuesRequest,
    modal,
    annuityModal,
    scrolling,
  });

  return {
    kpiData,
    sizing: getCardSizing(kpiData.length),
    agreementRequest,
    annuityRequest,
    cdaValuesRequest,
  };
};

export const getFormattedAnnuityOption = (annuityData) => {
  let formattedAnnuityOption = "";
  const annuityOption = annuityData?.annuityOption || "";
  formattedAnnuityOption = annuityOption;
  if (
    annuityOption?.includes("Period Certain") &&
    isDefined(annuityData?.issueDate) &&
    isDefined(annuityData?.maturityDate)
  ) {
    const lengthOfPeriodCertain =
      getDateDifference(annuityData?.issueDate, annuityData?.maturityDate)
        ?.years || "-";
    return `${annuityOption} for ${lengthOfPeriodCertain} Years`;
  }
  return formattedAnnuityOption || "-";
};
export const useViewInterestRates = () => {
  const viewInterestRates = () => {
    redirectToUrl(
      "https://fieldnet.massmutual.com/fldnet/products/annuities/productperformance.html",
      "_blank"
    );
  };
  return viewInterestRates;
};

export const getDeathBenefitOptionWithEeb = (props) => {
  const { dbRider } = props;

  return deathBenefitOptionNameWithEeb[dbRider?.riderName];
};
