import React from "react";
import IsTruthy from "../../../common/logic/isTruthy";
import LoadingSection, {
  errorTypes,
  loadingTypes,
} from "../../../common/loading";
import { LOADING_ICON_CUSTOM_MESSAGE } from "../../../../constants/ui";
import IsFalsy from "../../../common/logic/isFalsy";
import { compareStrings } from "../../../../utils/string";
import { isDefined } from "../../../../utils/evaluate";

const AgentModal = (props) => {
  const {
    agentProps: {
      businessPartnerId,
      phoneNumber,
      formattedPhoneNumber,
      agencyName,
      status,
      contractType,
      commissionSplit,
    },
    address: {
      attnLine,
      addrFirstLine,
      addrSecondLine,
      addrThirdLine,
      addrFourthLine,
    },
    producerRequest,
    agentProfileRequest,
    emailAddress,
  } = props;

  return (
    <LoadingSection
      request={producerRequest}
      loadingType={loadingTypes.SPINNER}
      errorType={errorTypes.SECTION}
      customErrorMessage={LOADING_ICON_CUSTOM_MESSAGE}
    >
      <div className="modal-body">
        <dl className="mm-key-value-pair">
          <dt id="agent_status_label">Status</dt>
          <dd id="agent_status_value">{status}</dd>
          <dt id="agent_agency_name_label">Agency</dt>
          <dd id="agent_agency_name_value">{agencyName}</dd>
          <dt id="agent_contract_type_label">Contract Type</dt>
          <dd id="agent_contract_type_value">{contractType}</dd>
          <dt id="agent_commission_label">Commission</dt>
          <dd id="agent_commission_value">{commissionSplit}</dd>
          <dt id="agent_producer_id_label">Producer ID</dt>
          <dd id="agent_producer_id_value">{businessPartnerId}</dd>
          <dt id="agent_address_label">Address</dt>
          <dd>
            <IsTruthy value={isDefined(attnLine)}>
              <span id="agent_attention_line_address_value">{attnLine}</span>
              <br />
            </IsTruthy>
            <span id="agent_first_line_address_value">{addrFirstLine}</span>
            <IsTruthy value={isDefined(addrSecondLine)}>
              <br />
              <span id="agent_second_line_address_value">{addrSecondLine}</span>
            </IsTruthy>
            <IsTruthy value={isDefined(addrThirdLine)}>
              <br />
              <span id="agent_third_line_address_value">{addrThirdLine}</span>
            </IsTruthy>
            <IsTruthy value={isDefined(addrFourthLine)}>
              <br />
              <span id="agent_fourth_line_address_value">{addrFourthLine}</span>
            </IsTruthy>
          </dd>
          <dt id="agent_phone_number_label">Phone</dt>
          <dd id="agent_phone_number_value">
            <IsTruthy value={compareStrings(phoneNumber, "-")}>
              {formattedPhoneNumber}
            </IsTruthy>
            <IsFalsy value={compareStrings(formattedPhoneNumber, "-")}>
              <a href={`tel:+${phoneNumber}`}>{formattedPhoneNumber}</a>
            </IsFalsy>
          </dd>
          <dt id="agent_email_label">Email</dt>
          <LoadingSection
            request={agentProfileRequest}
            loadingType={loadingTypes.LINE}
            errorType={errorTypes.CHILDREN}
          >
            <dd id="agent_email_value">
              <IsTruthy value={compareStrings(emailAddress, "-")}>
                {emailAddress}
              </IsTruthy>
              <IsFalsy value={compareStrings(emailAddress, "-")}>
                <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
              </IsFalsy>
            </dd>
          </LoadingSection>
        </dl>
      </div>
    </LoadingSection>
  );
};
export default AgentModal;
