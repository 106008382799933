import React from "react";
import { isValidElementType } from "react-is";
import { isDefined } from "../../../utils/evaluate";

const CustomComponent = (props) => {
  const { component, children } = props;

  if (isDefined(component) && isValidElementType(component)) {
    const ComponentToShow = component;
    return <ComponentToShow {...props} />;
  }
  return children || null;
};

export default CustomComponent;
