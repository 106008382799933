import { DATA_UNAVAILABLE } from "../../constants/ui";
import { isDefined } from "../../utils/evaluate";
import { checkIfTransactionIsAllowed } from "../../utils/policies";
import { compareArrayOfStrings, compareStrings } from "../../utils/string";
import { supportedTransactions } from "../../utils/translate";

export const isAgreementEligibleForFrequencyChange = (agreementData) => {
  const {
    lineOfBusinessCode,
    status,
    statusReason,
    restrictionCodeReason,
    agreementCoverages,
  } = agreementData;

  const restrictedStatusReason = [];
  let coverageLTC = null;

  if (lineOfBusinessCode === "LIFE") {
    restrictedStatusReason.push("DP", "SP");
    coverageLTC = agreementCoverages?.find((coverage) =>
      coverage.shortName?.startsWith("LTC")
    );
  }

  if (lineOfBusinessCode === "DI") {
    restrictedStatusReason.push("DP", "SP", "CR");
  }

  return (
    status === "IF" &&
    !compareArrayOfStrings(restrictedStatusReason, statusReason) &&
    restrictionCodeReason !== "HIGH" &&
    !coverageLTC
  );
};

export const isBillingEligibleForFrequencyChange = (
  billingData,
  agreementData
) => {
  const { billingMode, paymentPreference } = billingData;
  const { lineOfBusinessCode } = agreementData;

  const restrictedPaymentPreference = [];
  const restrictedBillingMode = [];

  if (lineOfBusinessCode === "LIFE") {
    restrictedPaymentPreference.push("LST", "NBL", "MBL", "CC", "PWR");
    restrictedBillingMode.push("NBL", "PDU");
  }

  if (lineOfBusinessCode === "DI") {
    restrictedPaymentPreference.push("LST", "NBL", "PWR");
  }

  return (
    !compareArrayOfStrings(restrictedPaymentPreference, paymentPreference) &&
    !compareArrayOfStrings(restrictedBillingMode, billingMode)
  );
};

export const isAllBillingPaymentDataCompletelyAvailable = (
  billingModeData,
  billingData,
  agreementData
) => {
  const { lineOfBusinessCode } = agreementData;
  const {
    billFrequency,
    billedPremium,
    paymentPreferenceDesc,
    billDeliveryPreferenceDesc,
  } = billingData;
  const { loanInterestAmount, loanInterestDueDate } = billingData.loan || {};
  const areAllBillingPaymentFieldsUnavailable =
    lineOfBusinessCode === "LIFE" &&
    !isDefined(billFrequency) &&
    !isDefined(billedPremium) &&
    (!isDefined(paymentPreferenceDesc) ||
      compareStrings(paymentPreferenceDesc, DATA_UNAVAILABLE)) &&
    (!isDefined(billDeliveryPreferenceDesc) ||
      compareStrings(billDeliveryPreferenceDesc, DATA_UNAVAILABLE)) &&
    !isDefined(loanInterestAmount) &&
    !isDefined(loanInterestDueDate);

  const isAllBillingFrequencyDataCompletelyAvailable = !Object.values(
    billingModeData
  ).some(
    (item) =>
      item.premium === "-" ||
      item.annualPremium === "-" ||
      item.aprPercentage === "-" ||
      item.modalChargeAmount === "-"
  );

  return (
    !areAllBillingPaymentFieldsUnavailable &&
    isAllBillingFrequencyDataCompletelyAvailable
  );
};

export const verifyChangeBillingFrequency = (props) => {
  const {
    agreementData,
    billingData,
    billingModeData,
    transactionSupportedPolicies,
  } = props;

  const { agreementKey } = agreementData;

  const agreementDataEligibleForFrequencyChange =
    isAgreementEligibleForFrequencyChange(agreementData);

  const billingDataEligibleForFrequencyChange =
    isBillingEligibleForFrequencyChange(billingData, agreementData) &&
    isAllBillingPaymentDataCompletelyAvailable(
      billingModeData,
      billingData,
      agreementData
    );

  const { isTransactionAllowed } = checkIfTransactionIsAllowed({
    transactionSupportedPolicies,
    transactionName: supportedTransactions.ChangeBillingFrequency,
    agreementData,
  });

  const isEligible =
    agreementDataEligibleForFrequencyChange &&
    billingDataEligibleForFrequencyChange;

  const changeBillingFrequencyLink = `/policyDetails/${encodeURIComponent(
    agreementKey
  )}/changeBillingFrequency`;

  const returnObject = {
    redirectLink: changeBillingFrequencyLink,
    isEligible,
    isTransactionAllowed,
  };

  return returnObject;
};
