import { supportedAnnuityRiderNames } from "../utils/translate";

export const A21G = "A21G";
export const SEE_APPLICATION = "SEE APPLICATION";
export const SEE_THE_APPLICATION = "SEE THE APPLICATION";
export const PRE_AUTHORIZED_CHECK = "PAC";
export const SEE_A21G = "SEE A21G";
export const FORM_REQUIRED_RESPONSE = "Agreement Loan Submitted- FORM";
export const BILLING_DESCRIPTION_MONTHLY = "Monthly";
export const BILLING_DESCRIPTION_QUARTERLY = "Quarterly";
export const BILLING_DESCRIPTION_SEMI_ANNUAL = "Semi-Annual";
export const BILLING_DESCRIPTION_ANNUAL = "Annual";
export const ANNUITY_STABLE_VOYAGE_PREFIXES = ["STA"];
export const ANNUITY_ODYSSEY_PREFIXES = [
  "ODD",
  "ODN",
  "ODY",
  "ODP",
  "OSL",
  "OD",
];
export const ANNUITY_ARTISTRY_PREFIXES = ["ART", "EXT", "ARN"];
export const ANNUITY_LOAN_DEFAULT_DATE = "01/01/1800";
export const ANNUITY_NON_GLWB_PRODUCT_TYPE_CODE = [
  "NHWRDR",
  "GMDBRP",
  "TIWRDR",
  "GMWB",
  "PPB",
  "LIP",
  "EEB",
  "GMIB",
];
export const ANNUITY_EVOLUTION_PREFIXES = ["EVN"];

export const ANNUITY_NON_GLWB_TITLES = [
  supportedAnnuityRiderNames.EQUALIZER_BENEFIT,
  supportedAnnuityRiderNames.E_DOCUMENTS,
  supportedAnnuityRiderNames.GMAB_10,
  supportedAnnuityRiderNames.GMAB_20,
  supportedAnnuityRiderNames.RATCHET_DB,
  supportedAnnuityRiderNames.BASIC_DEATH,
  supportedAnnuityRiderNames.BASIC_DEATH_COMBO,
  supportedAnnuityRiderNames.BASIC_DEATH_3YR_RESET,
  supportedAnnuityRiderNames.BASIC_DEATH_ROLLUP,
  supportedAnnuityRiderNames.BASIC_DEATH_ANNUAL_RATCHET,
  supportedAnnuityRiderNames.BASIC_DEATH_CONTRACT_VALUE,
  supportedAnnuityRiderNames.NURSING_HOME_BENEFIT,
  supportedAnnuityRiderNames.NURSING_HOME_HEALTH_CARE,
  supportedAnnuityRiderNames.TERMINAL_ILLNESS,
];

export const ANNUITY_ODYSSEY_KIND_CODES = [
  "ODNY05",
  "ODNY07",
  "ODYS05",
  "ODYS07",
];
export const ANNUITY_ODYSSEY_PLUS_KIND_CODES = ["ODPL06", "ODPL08", "ODYP"];
export const ANNUITY_ODYSSEY_SELECT_KIND_CODES = ["ODSL05", "ODSL07", "ODSL09"];
export const ANNUITY_STABLE_VOYAGE_KIND_CODES = ["STABLV"];
export const VARIABLE_ANNUITY_ARTISTRY_KIND_CODES = ["NYARTI", "ARTIST"];

export const VARIABLE_ANNUITY_FLEX_EXTRA_KIND_CODES = [
  "81500",
  "SPPVA3",
  "SPPVA4",
  "81600",
  "FPPVA3",
  "FPPVA4",
];

export const ANNUITY_TRANSITIONS_PREFIXES = ["TRA"];
export const ADDRESS_CHANGE_MAX_RETRY_COUNT = 2;
export const SURVIVORSHIP_NON_TRAD_PE_PRODUCT_NAMES = [
  "Survivorship Universal Life",
  "Survivorship Univ Life Guard",
  "Survivorship Variable UL",
  "Prestige Surv Universal Life",
  "Survivorship Variable UL Guard",
  "Prestige Surv Universal Life",
  "Survivorship Variable ULII",
  "Blue Chip Survivorship UL",
  "Survivorship UL Guaranteed",
  "Survivorship Universal Life+",
  "Survivorship UL w/P10",
];
export const SURVIVORSHIP_TRAD_MPR_PRODUCT_NAMES = [
  "Joint Life",
  "Paid Survivor Joint Life",
  "Survivor/Joint Life",
  "Survivor Paid Life 4%",
  "Paid Joint Lives",
  "Survivorship Whole Life",
  "Family Policy",
];
export const SURVIVORSHIP_TRAD_OPM_PRODUCT_NAMES = [
  "SWL Protector Pd Up Both Alive",
  "Joint Life",
  "Paid Survivor WL -Two Alive",
  "Survivorship WL -Younger Alive",
  "Survivorship WL -Older Alive",
  "Paid Survivor WL -Older Alive",
  "Survivorship WL - 2 Lives",
  "Pd Survivor WL -Younger Alive",
  "SWL Protector Older Alive",
  "SWL Protector Both Alive",
  "SWL Protector Pd Up Younger",
  "SWL Protector Pd Up Younger Alive",
  "Paid Joint Lives",
];

export const SURVIVORSHIP_TRAD_VNTG1_PRODUCT_NAMES = [
  "Survivor Whole Life Plus",
  "Survivorship Whole Life",
  "Survivorship Advantage",
  "Surv Whole Life Legacy 20 Pay",
  "Survivor Whole Life Legacy 100",
  "Surv Whole Life Legacy 12 Pay",
];
export const INITIATE_DEATH_CLAIM_MAX_BENE_CONTACT_COUNT = 5;
export const INITIATE_DEATH_CLAIM_MAX_RECIPIENT_CONTACT_COUNT = 10;
export const GMIB_PLUS_RIDER_NAMES = [
  "MassMutual Guaranteed Income Plus 5<sup>SM</sup> (Pre-2008 Version)",
  "MassMutual Guaranteed Income Plus 5<sup>SM</sup> (2008 Version)",
  "MassMutual Guaranteed Income Plus 6<sup>SM</sup> (Pre-2008 Version)",
  "MassMutual Guaranteed Income Plus 6<sup>SM</sup> (2008 Version)",
];
export const GMAB_RIDER_NAMES = [
  "10 Year Guaranteed Minimum Accumulation Benefit Period with Reset Option",
  "20 Year Guaranteed Minimum Accumulation Benefit Period",
];
