import React from "react";
import FormScreenAddresses from "../../common/formScreenAddresses/index";
import { useCTAFormProps } from "../hooks";

const CTAform = ({ documentId }) => {
  const { generateAndDownloadPDF, disableViewFormButton } =
    useCTAFormProps(documentId);

  const downloadForm = () => {
    generateAndDownloadPDF();
  };

  return (
    <>
      <h2>Please sign and submit your request.</h2>
      <p className="mm-sub-copy">
        The request requires a form FR2066 signed by the policy owner. Please
        follow the steps below to submit the form.
      </p>
      <div className="mm-spacing">
        <button
          onClick={downloadForm}
          type="button"
          className="btn btn-primary"
          disabled={disableViewFormButton}
        >
          <span className="icon-download" /> View Form
        </button>
      </div>
      <div className="mm-spacing">
        <hr />
      </div>
      <div className="row mm-spacing--minor">
        <ol>
          <li>Owner should complete the form and sign.</li>
          <li>
            Once the owner has completed and signed the form, submit one of the
            three ways:
          </li>
        </ol>
      </div>
      <FormScreenAddresses showCheckArrivalCopyMessage />
    </>
  );
};

export default CTAform;
