import { DEFAULT_404_ERROR_MESSAGE } from "../../constants/ui";
import { isDefined } from "../../utils/evaluate";
import { getBpIdFromUser, isHomeOfficeUser } from "../../utils/findData";
import { checkIfTransactionIsAllowed } from "../../utils/policies";
import { compareStrings } from "../../utils/string";
import { supportedTransactions } from "../../utils/translate";
import { getDisplayTextForError } from "./envision/errors";

export const verifyFundTransfer = (props) => {
  const {
    agreementData,
    fundTransferEligibilityData,
    producersData,
    transactionSupportedPolicies,
    user,
  } = props;

  const { bpId } = getBpIdFromUser(user);
  const isHomeOfficeUserResult = isHomeOfficeUser(user);
  const { agreementKey } = agreementData;
  const { producers = [] } = producersData;
  const { isEligible, messages = [] } = fundTransferEligibilityData || {};

  let isProducerOnRecord = false;
  if (!isHomeOfficeUserResult) {
    const matchingBpIdProducer = producers.find(
      (producer) => Number(producer.businessPartnerId) === Number(bpId)
    );

    isProducerOnRecord =
      isDefined(
        matchingBpIdProducer?.agreements.find(
          (agreement) =>
            compareStrings(agreement.roleCode, "AGT") &&
            (compareStrings(agreement.subRoleCode, "SVC") ||
              compareStrings(agreement.subRoleCode, "WRTG"))
        )
      ) || false;
  }

  const { isTransactionAllowed } = checkIfTransactionIsAllowed({
    transactionSupportedPolicies,
    transactionName: supportedTransactions.FundTransfer,
    agreementData,
  });

  const fundTransferLink = `/policyDetails/${encodeURIComponent(
    agreementKey
  )}/fundTransfer`;

  let eligibilityError = "";
  if (!isEligible) {
    const { msgCode } = messages[0] || {};
    eligibilityError = getDisplayTextForError(
      msgCode,
      DEFAULT_404_ERROR_MESSAGE
    );
  }

  const returnObject = {
    redirectLink: fundTransferLink,
    isEligible,
    isAuthorized: isProducerOnRecord || isHomeOfficeUserResult,
    isTransactionAllowed,
    eligibilityError,
  };

  return returnObject;
};
