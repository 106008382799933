import React from "react";
import { setTitle } from "../../utils/dom";
import { useAgentEmailNotificationsProps } from "./hooks";
import LoadingSection, { errorTypes, loadingTypes } from "../common/loading";
import AgentServiceTrackingDetails from "./pages/agentServiceTrackingDetails";
import LdBannerContent from "../common/molecules/ldBannerContent";
import FiveHundredOneError from "../common/errors/501";

const AgentServiceTracking = () => {
  setTitle("ServiceNet - Agent Email Notifications");
  const {
    agentNotificationsRequest,
    isHomeOfficeUserResult,
    updateNotificationsLastViewedRequest,
    numberOfDaysThreshold,
  } = useAgentEmailNotificationsProps();

  if (isHomeOfficeUserResult) {
    return (
      <div className="container">
        <LdBannerContent />
        <FiveHundredOneError />
      </div>
    );
  }

  return (
    <LoadingSection
      request={agentNotificationsRequest}
      loadingType={loadingTypes.SPINNER}
      errorType={errorTypes._500}
    >
      <AgentServiceTrackingDetails
        agentNotificationsRequest={agentNotificationsRequest}
        updateNotificationsLastViewedRequest={
          updateNotificationsLastViewedRequest
        }
        numberOfDaysThreshold={numberOfDaysThreshold}
      />
    </LoadingSection>
  );
};

export default React.memo(AgentServiceTracking);
