import React from "react";
import IsTruthy from "../logic/isTruthy";
import { useFormAddressesProps } from "./hooks";
import SubmitOption from "./submitOption";

const FormScreenAddresses = (props) => {
  const { showCheckArrivalCopyMessage } = props;
  const { attention, contactFaxNumber, contactEmail, goBackToPolicyPage } =
    useFormAddressesProps(props);
  return (
    <>
      <div className="row mm-spacing">
        <SubmitOption
          option="stamp"
          heading="Mail"
          description={[
            "MassMutual Financial Group",
            <br key="MassMutual Financial Group breakpoint" />,
            `Attention: ${attention}`,
            <br key="Attention: Life Hub breakpoint" />,
            "1295 State Street",
            <br key="1295 State Street breakpoint" />,
            "Springfield, MA 01111-0001",
          ]}
        />
        <SubmitOption
          option="printer"
          heading="Fax"
          description={[
            contactFaxNumber,
            <br key="1-866-329-4527 breakpoint" />,
            `Attention: ${attention}`,
          ]}
        />
        <SubmitOption
          option="email"
          heading="Email"
          description={contactEmail}
        />
      </div>
      <IsTruthy value={showCheckArrivalCopyMessage}>
        <p className="copy-lg mm-spacing">
          If the form is received in good order, the check will arrive within
          7-10 business days from the date the form is received.
        </p>
      </IsTruthy>
      <button
        onClick={goBackToPolicyPage}
        type="button"
        className="btn btn-secondary"
      >
        Back to policy
      </button>
    </>
  );
};
export default FormScreenAddresses;
