import { getUsers } from "../../../../utils/findData";
import {
  sortInsuredPeople,
  formatName,
  nameFormattingOptions,
} from "../../../../utils/format";
import { compareStrings } from "../../../../utils/string";
import { isDefined } from "../../../../utils/evaluate";
import { isDisabilityPolicy, isPallmPolicy } from "../../../../utils/policies";

export const useInsuredInformationProps = (props) => {
  const { agreementRequest } = props;
  const agreementData = agreementRequest.data;
  const { agreementCustomers, agreementCoverages, agreementKeyAdmin } =
    agreementData;
  const insuredPeople = getUsers(agreementCustomers, "INSD");
  const sortedInsuredPeople = sortInsuredPeople(insuredPeople);
  const insuredColumns = [
    {
      accessor: "fullName",
      label: "Insured Name",
      alignment: "left",
      dataCell: true,
    },
    {
      accessor: "issueAge",
      label: "Issue Age",
      alignment: "left",
    },
    {
      accessor: "underwritingClass",
      label: "Underwriting Class",
      alignment: "left",
    },
  ];
  if (!isDisabilityPolicy(agreementRequest.data)) {
    insuredColumns.push({
      accessor: "tableRating",
      label: "Table Rating",
      alignment: "left",
    });
  }
  const rows = insuredTableRows(
    agreementData,
    sortedInsuredPeople,
    agreementCoverages,
    agreementKeyAdmin
  );
  const table = { insuredColumns, rows };
  return {
    table,
  };
};
export const insuredTableRows = (agreementData, agreementCustomers) => {
  let tableRatingValue;
  const isPallm = isPallmPolicy(agreementData);
  const getInsuredData = (customerItem, index) => {
    const name = formatName({
      nameObject: customerItem,
      format: nameFormattingOptions.firstNameDisplayedFirst,
      defaultReturn: "-",
    });

    const age = isDefined(customerItem.issueAge)
      ? customerItem.issueAge
      : "Unavailable";

    if (isDefined(customerItem.permanentTableRatingCode1)) {
      tableRatingValue =
        compareStrings(customerItem.roleSubType, "PRMR") ||
        compareStrings(customerItem.roleSubType, "SCND")
          ? customerItem.permanentTableRatingCode1
          : "None";
    } else {
      tableRatingValue = "None";
    }

    const underwritingClassValue = isDefined(
      customerItem.tobaccoPremiumBasisDesc
    )
      ? customerItem.tobaccoPremiumBasisDesc
      : "Unavailable";

    if (isPallm) {
      tableRatingValue = "Unavailable";
    }
    return {
      ...customerItem,
      id: index,
      fullName: name,
      issueAge: age,
      underwritingClass: underwritingClassValue,
      tableRating: tableRatingValue,
    };
  };
  if (agreementCustomers) {
    return agreementCustomers.map(getInsuredData);
  }
  return [];
};
