import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { getCurrentDate } from "../../../../utils/dates";

const getTitle = (props) => {
  const { row, accessor, title = "" } = props;
  // if a custom title is provided for modal
  if (title) {
    return {
      modalLabel: title.replace(/ /g, "_"),
      title,
    };
  }
  if (typeof row[accessor] === "string") {
    return {
      modalLabel: row[accessor].replace(/ /g, "_"),
      title: row[accessor].replace(/<mark>|<\/mark>/g, ""),
    };
  }
  return { modalLabel: "", title: "" };
};
const ModalCell = (props) => {
  const {
    row,
    rowIndex,
    column: { accessor },
    children,
    size,
    showAsOfDate = true,
  } = props;
  const currentDate = getCurrentDate();
  const [show, setShow] = useState(false);
  const closeModal = () => setShow(false);
  const showModal = () => setShow(true);
  const customModalStyle = {
    zIndex: 100001, // Navbar z index is set to 10000
  };

  const { modalLabel, title } = getTitle(props);

  return (
    <>
      <button
        id={`${accessor}_${rowIndex}_btn`}
        onClick={showModal}
        className="btn btn-inline"
        type="button"
        data-target={`#${modalLabel}Modal`}
      >
        <span
          id={`${accessor}_${rowIndex}_value`}
          dangerouslySetInnerHTML={{ __html: row[accessor] }}
        />
      </button>
      <Modal
        size={size}
        id={`${modalLabel}Modal`}
        aria-labelledby={`${modalLabel}ModalLabel`}
        aria-hidden="true"
        style={customModalStyle}
        show={show}
        onHide={closeModal}
        close="true"
      >
        <Modal.Header close="true">
          <h2 className="modal-title">{title}</h2>
          <button type="button" onClick={closeModal} className="close">
            <span className="sr-only">Close</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          {showAsOfDate && <p className="mm-sub-copy">As of {currentDate}</p>}
          {children}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default React.memo(ModalCell);
