import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import { isSameDate } from "./dates";
import { compareStrings } from "./string";

export const checkHideLisrAlirBilledPremiumValues = (props) => {
  const {
    agreementData: { agreementCoverages, anniversaryDate },
    paidToDate,
    lisrValueHidingEnabled,
  } = props;

  const hasActiveLisrRider = agreementCoverages?.some(
    (rider) =>
      compareStrings(rider.status, "ACT") &&
      compareStrings(rider.statusReason, "Active") &&
      compareStrings(rider.productTypeCode, "LISR")
  );

  const hasActiveAlirRider = agreementCoverages?.some(
    (rider) =>
      compareStrings(rider.status, "ACT") &&
      compareStrings(rider.statusReason, "Active") &&
      compareStrings(rider.productTypeCode, "ALIR")
  );

  const daysTilAnniversary = differenceInCalendarDays(
    new Date(anniversaryDate),
    new Date()
  );

  const sixtyOrFewerDaysTilAnniversary =
    daysTilAnniversary > 0 && daysTilAnniversary <= 60;
  const paidToDateIsAnniversary = isSameDate(anniversaryDate, paidToDate);

  const hideLisrBilledPremiumValues =
    sixtyOrFewerDaysTilAnniversary &&
    hasActiveLisrRider &&
    paidToDateIsAnniversary &&
    lisrValueHidingEnabled;

  const hideLisrAlirBilledPremiumValues =
    sixtyOrFewerDaysTilAnniversary &&
    hasActiveAlirRider &&
    hasActiveLisrRider &&
    paidToDateIsAnniversary &&
    lisrValueHidingEnabled;

  return { hideLisrBilledPremiumValues, hideLisrAlirBilledPremiumValues };
};
