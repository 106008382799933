import { useUserState } from "../../../../contexts/user";
import { useRequestorContactEmail } from "../../../../hooks/helper";
import { formattingTypes } from "../../../../utils/formFormatting";
import {
  useGeneralValidation,
  useEmailValidation,
  useConfirmEmailValidation,
} from "../../../../utils/validation";

export const useChooseRequestTypeFields = () => {
  const {
    user: { firstName, lastName = "" },
  } = useUserState();

  const contactChoiceEmail = useRequestorContactEmail();

  return {
    beneficiary: {
      id: "beneficiary",
      label: "Beneficiary",
      name: "beneficiary",
      value: false,
      required: false,
      type: "checkbox",
      show: false,
    },
    dividendAccumulationsAvailable: {
      id: "dividendAccumulationsAvailable",
      label: "Dividend Accumulations Available",
      name: "dividendAccumulationsAvailable",
      value: false,
      required: false,
      type: "checkbox",
      show: false,
    },
    estimatedDeathBenefit: {
      id: "estimatedDeathBenefit",
      label: "Estimated Death Benefit",
      name: "estimatedDeathBenefit",
      value: false,
      required: false,
      type: "checkbox",
      show: false,
    },
    futureLoanPayoffAmount: {
      id: "futureLoanPayoffAmount",
      label: "Future Loan Payoff Amount",
      name: "futureLoanPayoffAmount",
      value: false,
      required: false,
      type: "checkbox",
      show: false,
    },
    loanPayoffAmount: {
      id: "loanPayoffAmount",
      label: "Loan Payoff Amount",
      name: "loanPayoffAmount",
      value: false,
      required: false,
      type: "checkbox",
      show: false,
    },
    maxLoanAmount: {
      id: "maxLoanAmount",
      label: "Max Loan Amount",
      name: "maxLoanAmount",
      value: false,
      required: false,
      type: "checkbox",
      show: false,
    },
    monthEndCashValue: {
      id: "monthEndCashValue",
      label: "Month End Cash Value",
      name: "monthEndCashValue",
      value: false,
      required: false,
      type: "checkbox",
      show: false,
    },
    netCashValue: {
      id: "netCashValue",
      label: "Net Cash Value",
      name: "netCashValue",
      value: false,
      required: false,
      type: "checkbox",
      show: false,
    },
    paidUpAdditionsAvailable: {
      id: "paidUpAdditionsAvailable",
      label: "Paid Up Additions Available",
      name: "paidUpAdditionsAvailable",
      value: false,
      required: false,
      type: "checkbox",
      show: false,
    },
    paidUpAdditionsFaceAmount: {
      id: "paidUpAdditionsFaceAmount",
      label: "Paid Up Additions Face Amount",
      name: "paidUpAdditionsFaceAmount",
      value: false,
      required: false,
      type: "checkbox",
      show: false,
    },
    paidUpAdditionsFaceAmountCurrentYear: {
      id: "paidUpAdditionsFaceAmountCurrentYear",
      label: "Paid Up Additions Face Amount-Current Year",
      name: "paidUpAdditionsFaceAmountCurrentYear",
      value: false,
      required: false,
      type: "checkbox",
      show: false,
    },
    partialSurrenderValue: {
      id: "partialSurrenderValue",
      label: "Partial Surrender Value",
      name: "partialSurrenderValue",
      value: false,
      required: false,
      type: "checkbox",
      show: false,
    },
    surrenderValue: {
      id: "surrenderValue",
      label: "Surrender Value",
      name: "surrenderValue",
      value: false,
      required: false,
      type: "checkbox",
      show: false,
    },
    totalAccountValue: {
      id: "totalAccountValue",
      label: "Account Value",
      name: "totalAccountValue",
      value: false,
      required: false,
      type: "checkbox",
      show: false,
    },
    requestorNameInput: {
      id: "requestorNameInput",
      name: "requestorNameInput",
      value: `${firstName} ${lastName}`,
      formatting: formattingTypes.NAMES,
      useValidation: useGeneralValidation,
      hideLabel: true,
      errors: [],
      postpone: true,
      disabled: true,
    },
    contactChoiceEmailInput: {
      id: "contactChoiceEmailInput",
      hideLabel: true,
      ariaLabel: "Email input",
      placeholder: "Please type your email address",
      name: "contactChoiceEmailInput",
      value: contactChoiceEmail,
      uncheckedValue: "",
      errors: [],
      postpone: true,
      useValidation: useEmailValidation,
      disabled: false,
    },
    contactChoiceConfirmEmailInput: {
      id: "contactChoiceConfirmEmailInput",
      hideLabel: true,
      ariaLabel: "Confirm Email input",
      placeholder: "Please type your email address",
      name: "contactChoiceConfirmEmailInput",
      value: "",
      uncheckedValue: "",
      errors: [],
      postpone: true,
      emailFormFieldValue: contactChoiceEmail,
      useValidation: useConfirmEmailValidation,
      disabled: false,
      disablePaste: true,
    },
    requestInformationAsOfDate: {
      id: "requestInformationAsOfDate",
      label: "As of Date *",
      ariaLabel: "As of Date",
      labelBold: true,
      name: "requestInformationAsOfDate",
      groupName: "requestInformationAsOfDate",
      value: new Date(),
      minDate: "",
      maxDate: "",
    },
  };
};
