import { useState, useEffect } from "react";
import {
  hasVNTGKCAdmin,
  isArtistryAnnuityProduct,
} from "../../../../utils/policies";
import { useLDValue } from "../../../common/featureFlags/hooks";
import { addDaystoDate } from "../../../../utils/dates";
import { isNonEmptyString, compareStrings } from "../../../../utils/string";
import { checkAndSetSelectedDate } from "../../../../utils/datePicker";
import { useUserState } from "../../../../contexts/user";
import { fireTealiumEvent } from "../../../../utils/tealium";

export const useValueRecalculationAsOfDate = (props) => {
  const {
    agreementRequest: {
      data: { agreementKeyAdmin },
    },
    annuityRequest: {
      data: { issueDate, asofDate, productPrefix },
    },
    setAsOfDate,
    showLoadingIcon,
    action,
    hasActiveLoan,
  } = props;

  const { user } = useUserState();
  const fireVNTGKCRecalcTealium = () => {
    let actionName = "Value Recalculation";
    if (action === "LOAN_PAYOFF") {
      actionName = "Loan Payoff";
    }
    fireTealiumEvent({
      label: "SVNT",
      action: actionName,
      category: "PDP - SOA VNTGKC Recalculations",
      custId: user?.email,
    });
  };

  const initialDate = isNonEmptyString(asofDate)
    ? new Date(asofDate)
    : new Date();

  const isArtistryAnnuityContract = isArtistryAnnuityProduct(productPrefix);
  const [selectedDate, setSelectedDate] = useState(new Date(initialDate));
  const isVNTGKCAdmin = hasVNTGKCAdmin(agreementKeyAdmin);
  const recalcAndLoanFlags = useLDValue({
    flagName: ["valuesAndLoanRecalculation"],
  });

  let displayDatePicker = false;
  let datePickerLabel = "";
  let datePickerMinDate;
  let datePickerMaxDate;
  if (compareStrings(action, "VALUE_RECALC")) {
    datePickerLabel = "Get Historical Contract Values";
    datePickerMinDate = new Date(issueDate);
    datePickerMaxDate = new Date(asofDate);
    displayDatePicker = isVNTGKCAdmin && recalcAndLoanFlags.valueRecalc;
  } else if (compareStrings(action, "LOAN_PAYOFF")) {
    datePickerLabel = "Get New Loan Payoff Quote";
    datePickerMinDate = new Date(initialDate);
    datePickerMaxDate = addDaystoDate(datePickerMinDate, 14);
    displayDatePicker =
      isArtistryAnnuityContract &&
      recalcAndLoanFlags.loanPayoff &&
      hasActiveLoan;
  }

  const dateHandler = (date) =>
    checkAndSetSelectedDate(
      date,
      datePickerMinDate,
      datePickerMaxDate,
      setSelectedDate
    );

  const [className, setClassName] = useState("btn btn-primary hide-print");
  const [buttonText, setButtonText] = useState("RECALCULATE");
  useEffect(() => {
    if (showLoadingIcon) {
      setClassName("btn btn-primary-loading");
      setButtonText("");
    } else {
      setClassName("btn btn-primary hide-print");
      setButtonText("RECALCULATE");
    }
  }, [showLoadingIcon]);

  return {
    datePickerMaxDate,
    datePickerMinDate,
    selectedDate,
    displayDatePicker,
    datePickerLabel,
    dateHandler,
    setAsOfDate,
    className,
    buttonText,
    fireVNTGKCRecalcTealium,
  };
};
