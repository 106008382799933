import {
  checkIfTransactionIsAllowed,
  isPolicyInForce,
} from "../../utils/policies";
import { supportedTransactions } from "../../utils/translate";
import { checkIfAtLeastOneUserRegisteredOnMM } from "../../utils/findData";
import { isEmptyArray } from "../../utils/evaluate";

export const verifyDirectLinks = (props) => {
  const { agreementData, transactionSupportedPolicies } = props;

  const { isTransactionAllowed } = checkIfTransactionIsAllowed({
    transactionSupportedPolicies,
    transactionName: supportedTransactions.DirectLinks,
    agreementData,
  });

  const isAtLeastOneUserRegistered =
    checkIfAtLeastOneUserRegisteredOnMM(agreementData);

  const { isUnauthorized } = agreementData;

  const returnObject = {
    isEligible:
      isAtLeastOneUserRegistered &&
      isAgreementDataEligibleForDirectLinks(agreementData),
    isTransactionAllowed,
    isUnauthorized,
  };

  return returnObject;
};

export const isAgreementDataEligibleForDirectLinks = (agreementData) => {
  const { status, transactionDeepLinks = [] } = agreementData;

  const isValidPolicyStatus = isPolicyInForce(status);
  const hasDeepLinks = !isEmptyArray(transactionDeepLinks);

  return isValidPolicyStatus && hasDeepLinks;
};
