/* eslint-disable react/no-array-index-key */
import React from "react";
import { formatLisrVNTG1RiderSectionData } from "./riderSectionHelper";
import { InformationPopover } from "../../../../common/popovers/informationPopover";
import IsTruthy from "../../../../common/logic/isTruthy";
import {
  LISR_UNAVAILABLE_VALUE_TOOLTIP_TEXT,
  LISR_UNAVAILABLE_VALUE_PRINT_TEXT,
  LISR_BILLED_PREMIUM_TEXT,
} from "../../../../../constants/ui";

const LisrVntg1RiderSection = (props) => {
  const { hideLisrBilledPremiumValues } = props;
  const { formattedCoverages } = formatLisrVNTG1RiderSectionData(props);
  const sectionDisplay = formattedCoverages?.map((coverage, index) => (
    <div id={`rider${index}`} key={`rider_${index}`}>
      <div className="row">
        <div className="col-12 col-md-6">
          <dl className="mm-key-value-pair">
            <dt id={`rider_${index}_name_lsirVntg__premium_lbl`}>Rider Name</dt>
            <dd id={`rider_${index}_name_lsirVntg__premium_value`}>
              {coverage.riderType}
            </dd>
            <dt id={`lsirVntg__${index}_premium_reco_premium_lbl`}>
              Recommended Premium
            </dt>
            <dd id={`lsirVntg__${index}_premium_reco_premium_value`}>
              {coverage.recommendedPremium}
              <IsTruthy value={hideLisrBilledPremiumValues}>
                <sup className="print-only">1</sup>{" "}
                <InformationPopover
                  popoverId="billed_premium_unavailable_tooltip"
                  text={LISR_UNAVAILABLE_VALUE_TOOLTIP_TEXT}
                />
              </IsTruthy>
            </dd>
            <dt id={`lsirVntg__${index}_premium_oustanding_dump_in_lbl`}>
              Outstanding Dump-In Premium
            </dt>
            <dd id={`lsirVntg__${index}_premium_oustanding_dump_in_value`}>
              {coverage.outstandingDumpInPremium}
            </dd>
          </dl>
        </div>
        <div className="col-12 col-md-6">
          <dl className="mm-key-value-pair">
            <dt id={`lsirVntg__${index}_premium_billed_premium_lbl`}>
              Billed Premium
              <sup className="print-only">
                {hideLisrBilledPremiumValues ? "2" : "1"}
              </sup>{" "}
              <InformationPopover
                popoverId={`lsirVntg__${index}_premium_billed_premium_popover`}
                text={LISR_BILLED_PREMIUM_TEXT}
              />
            </dt>
            <dd id={`lsirVntg__${index}_premium_billed_premium_value`}>
              {coverage.billedPremium}
              <IsTruthy value={hideLisrBilledPremiumValues}>
                <sup className="print-only">1</sup>{" "}
                <InformationPopover
                  popoverId="billed_premium_unavailable_tooltip"
                  text={LISR_UNAVAILABLE_VALUE_TOOLTIP_TEXT}
                />
              </IsTruthy>
            </dd>

            <dt id={`lsirVntg__${index}_premium_paying_end_Date_lbl`}>
              Prem. Paying End Date
            </dt>
            <dd id={`lsirVntg__${index}_premium_paying_end_Date_value`}>
              {coverage.premiumPayingEndDate}
            </dd>
            <dt id={`lsirVntg__${index}_premium_crossover_options_date_lbl`}>
              Crossover Option Date
            </dt>
            <dd id={`lsirVntg__${index}_premium_crossover_options_date_value`}>
              {coverage.crossoverOptionDate}
            </dd>
          </dl>
        </div>
      </div>

      <ol id="lisr_rider_section_print_footnotes" className="print-only">
        <IsTruthy value={hideLisrBilledPremiumValues}>
          <li>{LISR_UNAVAILABLE_VALUE_PRINT_TEXT}</li>
        </IsTruthy>
        <li>{LISR_BILLED_PREMIUM_TEXT}</li>
      </ol>

      <hr />
    </div>
  ));

  return sectionDisplay;
};

export default React.memo(LisrVntg1RiderSection);
