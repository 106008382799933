import React from "react";
import { stringIsEmptyOrNullish } from "../../../utils/string";
import IsFalsy from "../logic/isFalsy";
import IsTruthy from "../logic/isTruthy";
import { isDefined, isANumber } from "../../../utils/evaluate";

const SectionError = (props) => {
  const {
    retryRequest,
    customErrorMessage = "",
    removeReloadButton = false,
    hideStandardErrorMessage = false,
    disableReloadButton = false,
    setReloadAttempts,
    reloadAttempts,
  } = props;

  const handleClick = () => {
    if (
      isDefined(setReloadAttempts) &&
      isANumber(reloadAttempts) &&
      !disableReloadButton
    ) {
      setReloadAttempts(reloadAttempts + 1);
    }
    retryRequest();
  };

  return (
    <>
      <IsTruthy
        value={
          stringIsEmptyOrNullish(customErrorMessage) &&
          !hideStandardErrorMessage
        }
      >
        <p className="h4">
          Something went wrong on our end. Let&apos;s try that again.
        </p>
      </IsTruthy>
      <IsFalsy
        value={customErrorMessage}
        validationFunction={stringIsEmptyOrNullish}
      >
        <p className="h4">{customErrorMessage}</p>
      </IsFalsy>

      <IsFalsy value={removeReloadButton}>
        <div className="mm-sub-copy">
          <button
            type="button"
            className="btn btn-inline"
            onClick={handleClick}
            disabled={disableReloadButton}
          >
            <span className="icon-reload " /> Reload
          </button>
        </div>
      </IsFalsy>
    </>
  );
};

export default SectionError;
