export const processAgreementCoreData = (props) => {
  const apiData = props?.response?.data;

  const agreementsData = apiData?.agreements;
  const policyInfo = agreementsData?.map((policy) => {
    const {
      productTypeName,
      agreementKeyPrimaryDisplay,
      kindCode,
      productTypeCode,
      lineOfBusinessCode,
      agreementKeyPrefix,
      agreementCustomers,
      agreementKeyAdmin,
    } = policy;
    return {
      productTypeName,
      agreementKeyPrimaryDisplay,
      kindCode,
      productTypeCode,
      lineOfBusinessCode,
      agreementKeyPrefix,
      agreementCustomers,
      agreementKeyAdmin,
    };
  });

  return {
    policyInfo,
  };
};
