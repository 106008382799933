/* eslint-disable import/prefer-default-export */
import isEqual from "lodash.isequal";
import useDeepCompareEffect from "use-deep-compare-effect";
import { types, useFormContext } from "../../../contexts/form";
import { usePrevious } from "../../../hooks/helper";
import { isDefined } from "../../../utils/evaluate";
import { formFormatting } from "../../../utils/formFormatting";
import { stringIsEmptyOrNullish } from "../../../utils/string";
import { hasError } from "../../../utils/validation";

export const useShouldRunValidation = (props) => {
  const { dispatchForm, formData, formField } = props;
  const { useValidation, value } = formField;

  const errors = useValidation({ formField, formData });
  const previousValue = usePrevious(value);

  useDeepCompareEffect(() => {
    const HAS_UNCHECKED_VALUE_CHANGED = !isEqual(previousValue, value);

    if (HAS_UNCHECKED_VALUE_CHANGED) {
      if (errors.length > 0) {
        dispatchForm({ type: types.VALIDATION_FAILURE, formField, errors });
      } else {
        dispatchForm({ type: types.VALIDATION_SUCCESSFUL, formField });
      }
    }
  }, [formField, previousValue, errors]);
};

export const useRadioFormProps = (props) => {
  const { formField } = props;

  const { dispatchForm } = useFormContext();
  const onChange = () => {
    dispatchForm({
      type: types.RADIO_SELECTED,
      formField,
    });
  };
  return {
    onChange,
  };
};

export const useCheckboxProps = (props) => {
  const { formField } = props;

  const { dispatchForm } = useFormContext();
  return {
    onChange: () => dispatchForm({ type: types.UPDATE_TOGGLE, formField }),
    className: "custom-control-input",
  };
};

export const useJsonInputFormProps = (props) => {
  const { formField } = props;
  const { dispatchForm } = useFormContext();

  const onChange = (json) => {
    dispatchForm({
      type: types.UPDATE_FIELD_VALUE,
      formField,
      newValue: JSON.stringify(json),
      postpone: false,
    });
  };

  return { onChange };
};
export const useInputFormProps = (props) => {
  const { formField } = props;
  const { dispatchForm, formData } = useFormContext();
  const onChange = (event) => {
    const newValue = formFormatting({
      value: event.target.value,
      formatting: formField.formatting,
    });

    const FIELD_IS_EMPTY = stringIsEmptyOrNullish(newValue);

    dispatchForm({
      type: types.UPDATE_FIELD_VALUE,
      formField,
      newValue,
      postpone: FIELD_IS_EMPTY,
    });
  };
  const onPaste = (event) => {
    if (formField.disablePaste) {
      event.preventDefault();
      return false;
    }
    return true;
  };
  useShouldRunValidation({ dispatchForm, formData, formField });
  const className = hasError(formField)
    ? "form-control is-invalid"
    : "form-control";
  const onBlur = () => {};
  return { onChange, onPaste, className, onBlur };
};

export const useDatePickerProps = (props) => {
  const { formField } = props;
  const { dispatchForm } = useFormContext();
  const handleDateChangeEvent = (selectedDateValue) => {
    dispatchForm({
      type: types.UPDATE_FIELD_VALUE,
      formField,
      newValue: selectedDateValue,
      postpone: false,
    });
  };
  return { handleDateChangeEvent };
};

export const useDropdown = (props) => {
  const { formField } = props;
  const { dispatchForm } = useFormContext();
  const selectedOption = (event) => {
    const clickedOption = formField.options.reduce(
      (foundOption, currentOption) => {
        const ALREADY_FOUND_MATCH = isDefined(foundOption);
        const FOUND_A_MATCH = currentOption.id === event.target.id;
        if (ALREADY_FOUND_MATCH) {
          return foundOption;
        }
        if (FOUND_A_MATCH) {
          return currentOption;
        }
        return foundOption;
      },
      undefined
    );

    dispatchForm({
      type: types.UPDATE_DROPDOWN,
      formField,
      value: clickedOption.value,
      display: clickedOption.label,
    });
  };

  return {
    functions: { selectedOption },
  };
};
