import React from "react";
import { setTitle } from "../../utils/dom";
import IsTruthy from "../common/logic/isTruthy";
import { ModalProvider } from "../../contexts/modal";
import ModalControl from "./modalControl";
import { useDigitalRegistrationProps } from "./hooks";
import DigitalRegistrationHeader from "./pages/digitalRegistrationHeader";
import CheckEligibility from "./pages/checkEligibility";
import Form from "../common/forms/form";
import LoadingSection, { errorTypes, loadingTypes } from "../common/loading";
import EligibleClient from "./pages/eligibleClient";
import Confirmation from "./pages/confirmation";
import IsFalsy from "../common/logic/isFalsy";
import Header from "../layout/header";
import FiveHundredError from "../common/errors/500";
import Footer from "../layout/footer";
import LdBannerContent from "../common/molecules/ldBannerContent";
import FiveHundredOneError from "../common/errors/501";

const DigitalRegistration = (props) => {
  setTitle("ServiceNet - Digital Registration");

  const {
    agreementRequest,
    fields,
    ownerName,
    owner,
    pageNumber,
    setPageNumber,
    error,
    setError,
    onSubmit,
    showLoadingIcon,
    setShowLoadingIcon,
    lineOfBusinessCode,
    producersRequest,
    showErrorPage,
    agreementKey,
    isUnauthorized,
    isPolicyDataLoaded,
    userEligibilityResult,
    setUserEligibilityResult,
    setAmcGuid,
    policyId,
    transactionName,
    shouldDisableSubmit,
  } = useDigitalRegistrationProps(props);

  if (isUnauthorized) {
    return (
      <>
        <Header />
        <div className="container">
          <LdBannerContent />
          <FiveHundredOneError />
        </div>
        <Footer />
      </>
    );
  }

  return (
    <LoadingSection
      request={agreementRequest}
      loadingType={loadingTypes.SPINNER}
      errorType={errorTypes._500}
    >
      <LoadingSection
        request={producersRequest}
        loadingType={loadingTypes.SPINNER}
        errorType={errorTypes._500}
      >
        <IsTruthy value={isPolicyDataLoaded}>
          <IsTruthy value={showErrorPage}>
            <Header />
            <div className="container">
              <FiveHundredError
                turnOffReload
                title="There was an error processing your request; please try again later."
                agreementKey={agreementKey}
              />
            </div>
            <Footer />
          </IsTruthy>
          <IsFalsy value={showErrorPage}>
            <div className="container">
              <Form fields={fields}>
                <ModalProvider>
                  <DigitalRegistrationHeader
                    pageNumber={pageNumber}
                    shouldDisableSubmit={shouldDisableSubmit}
                  />
                  <IsTruthy value={pageNumber === 1}>
                    <CheckEligibility
                      ownerName={ownerName?.fullName}
                      agreementRequest={agreementRequest}
                      userEligibilityResult={userEligibilityResult}
                      setUserEligibilityResult={setUserEligibilityResult}
                      owner={owner}
                      setError={setError}
                    />
                  </IsTruthy>
                  <IsTruthy value={pageNumber === 2}>
                    <EligibleClient />
                  </IsTruthy>
                  <IsTruthy value={pageNumber === 3}>
                    <Confirmation
                      lineOfBusinessCode={lineOfBusinessCode}
                      policyId={policyId}
                    />
                  </IsTruthy>
                  <ModalControl
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    error={error}
                    setError={setError}
                    onSubmit={onSubmit}
                    showLoadingIcon={showLoadingIcon}
                    setShowLoadingIcon={setShowLoadingIcon}
                    ownerName={ownerName?.firstName}
                    owner={owner}
                    setUserEligibilityResult={setUserEligibilityResult}
                    userEligibilityResult={userEligibilityResult}
                    setAmcGuid={setAmcGuid}
                    lineOfBusinessCode={lineOfBusinessCode}
                    policyId={policyId}
                    transactionName={transactionName}
                  />
                </ModalProvider>
              </Form>
            </div>
          </IsFalsy>
        </IsTruthy>
      </LoadingSection>
    </LoadingSection>
  );
};

export default React.memo(DigitalRegistration);
