import React from "react";
import Checkbox from "../common/forms/checkbox";
import Label from "../common/forms/label";
import IsTruthy from "../common/logic/isTruthy";
import { isAddressEditable } from "./hooks";
import LoadingSection, { errorTypes, loadingTypes } from "../common/loading";
import { LOADING_ICON_CUSTOM_MESSAGE } from "../../constants/ui";

const SelectUsersCheckbox = (props) => {
  const { formField, useHook } = props;
  const { id: customerIndex, policies, agreementsRequest } = formField;
  const disableCheckbox = !isAddressEditable(formField);
  return (
    <>
      <div className="form-group custom-control custom-checkbox">
        <Checkbox
          disabled={disableCheckbox}
          formField={formField}
          useHook={useHook}
        />
        <Label className="custom-control-label" formField={formField} />
      </div>
      <div className="mm-control-indent">
        <LoadingSection
          request={agreementsRequest}
          loadingType={loadingTypes.PARAGRAPH}
          errorType={errorTypes.SECTION}
          customErrorMessage={LOADING_ICON_CUSTOM_MESSAGE}
        >
          {policies?.map((policy) => {
            if (policy) {
              const {
                productTypeName,
                agreementKeyPrimaryDisplay,
                roles = [],
              } = policy;

              return (
                <div
                  key={`${agreementKeyPrimaryDisplay}`}
                  id={`${customerIndex}-policy-${agreementKeyPrimaryDisplay}`}
                >
                  <div className="row">
                    <div className="col-lg-4 col-md-4">
                      <p
                        id={`${customerIndex}-policy-${agreementKeyPrimaryDisplay}-productid`}
                      >{`${productTypeName} #${agreementKeyPrimaryDisplay}`}</p>
                    </div>
                    <div className="col-lg-8 col-md-8">
                      {roles?.map((role) => {
                        if (role) {
                          const {
                            attnLine,
                            addrFirstLine,
                            addrSecondLine,
                            addrThirdLine,
                            addrFourthLine,
                          } = role.formattedAddress;
                          return (
                            <div
                              className="row"
                              key={`roleAddress-${role.key}`}
                              id={`${customerIndex}-policy-${agreementKeyPrimaryDisplay}-roleAddress-${role.key}`}
                            >
                              <div className="col-lg-4">
                                <p
                                  id={`${customerIndex}-policy-${agreementKeyPrimaryDisplay}-roles-${role.key}`}
                                >
                                  {role.roleNames?.join(", ")}
                                </p>
                              </div>
                              <div className="col-lg-8">
                                <p
                                  id={`${customerIndex}-policy-${agreementKeyPrimaryDisplay}-address-${role.key}`}
                                >
                                  <IsTruthy value={attnLine !== ""}>
                                    {attnLine}
                                  </IsTruthy>
                                  {addrFirstLine}
                                  <br />
                                  <IsTruthy value={addrSecondLine !== ""}>
                                    {addrSecondLine}
                                    <br />
                                  </IsTruthy>
                                  <IsTruthy value={addrThirdLine !== ""}>
                                    {addrThirdLine}
                                    <br />
                                  </IsTruthy>
                                  <IsTruthy value={addrFourthLine !== ""}>
                                    {addrFourthLine}
                                  </IsTruthy>
                                </p>
                              </div>
                            </div>
                          );
                        }
                        return "";
                      })}
                    </div>
                  </div>
                </div>
              );
            }
            return "";
          })}
        </LoadingSection>
      </div>
    </>
  );
};

export default React.memo(SelectUsersCheckbox);
