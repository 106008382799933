import React from "react";
import { ANNUITY_LOAN_DEFAULT_DATE } from "../../../../../../constants/logic";
import {
  DATA_UNAVAILABLE,
  NOT_APPLICABLE,
} from "../../../../../../constants/ui";
import { createDateObject, formatDate } from "../../../../../../utils/dates";
import { isEmptyObject } from "../../../../../../utils/evaluate";
import { formatMoney, formatPercentage } from "../../../../../../utils/format";
import { compareStrings } from "../../../../../../utils/string";
import Table from "../../../../../common/table";

const getOriginalLoanAmount = (groupedLoanListValue) =>
  formatMoney(
    (groupedLoanListValue?.EE?.[0]?.origLoanAmt || 0) +
      (groupedLoanListValue?.ER?.[0]?.origLoanAmt || 0)
  );

const getRemainingLoanAmount = (groupedLoanListValue) =>
  formatMoney(
    (groupedLoanListValue?.EE?.[0]?.remainingBalance || 0) +
      (groupedLoanListValue?.ER?.[0]?.remainingBalance || 0)
  );

const getLoanInterestRate = (groupedLoanListValue) =>
  formatPercentage(
    {
      rate:
        (groupedLoanListValue?.EE?.[0]?.currInterestRate ||
          // eslint-disable-next-line no-unsafe-optional-chaining
          groupedLoanListValue?.ER?.[0]?.currInterestRate) * 100,
    },
    DATA_UNAVAILABLE
  );

const getLoanDuration = (groupedLoanListValue) =>
  groupedLoanListValue?.EE?.[0]?.nbrPaymentsRemaining ||
  groupedLoanListValue?.ER?.[0]?.nbrPaymentsRemaining ||
  DATA_UNAVAILABLE;
const isLoanDeemed = (groupedLoanListValue) => {
  const isLoanEmployeeDeemed = compareStrings(
    "Deemed",
    groupedLoanListValue?.EE?.[0]?.status
  );

  const isLoanEmployerDeemed = compareStrings(
    "Deemed",
    groupedLoanListValue?.ER?.[0]?.status
  );

  return isLoanEmployeeDeemed || isLoanEmployerDeemed;
};

const getNextBillDate = (groupedLoanListValue, loanDatesDefaultValue) => {
  if (isLoanDeemed(groupedLoanListValue)) {
    return NOT_APPLICABLE;
  }

  const loanNextBillDate =
    groupedLoanListValue?.EE?.[0]?.nextBillCreateDate ||
    groupedLoanListValue?.ER?.[0]?.nextBillCreateDate;
  return createDateObject(loanNextBillDate) > loanDatesDefaultValue
    ? formatDate(loanNextBillDate, "default", DATA_UNAVAILABLE)
    : DATA_UNAVAILABLE;
};

const getNextDueDate = (groupedLoanListValue, loanDatesDefaultValue) => {
  if (isLoanDeemed(groupedLoanListValue)) {
    return NOT_APPLICABLE;
  }

  const loanNextDueDate =
    groupedLoanListValue?.EE?.[0]?.nextBillDueDate ||
    groupedLoanListValue?.ER?.[0]?.nextBillDueDate;
  return createDateObject(loanNextDueDate) > loanDatesDefaultValue
    ? formatDate(loanNextDueDate, "default", DATA_UNAVAILABLE)
    : DATA_UNAVAILABLE;
};

const getDeemedDate = (groupedLoanListValue) => {
  const loanEmployeeDeemedDate = compareStrings(
    "Deemed",
    groupedLoanListValue?.EE?.[0]?.status
  )
    ? formatDate(groupedLoanListValue?.EE?.[0]?.dateOfDefault)
    : "";

  const loanEmployerDeemedDate = compareStrings(
    "Deemed",
    groupedLoanListValue?.ER?.[0]?.status
  )
    ? formatDate(groupedLoanListValue?.ER?.[0]?.dateOfDefault)
    : "";
  return loanEmployeeDeemedDate || loanEmployerDeemedDate || DATA_UNAVAILABLE;
};

const getLoanSummary = (groupedLoanList) => {
  const loanDatesDefaultValue = createDateObject(ANNUITY_LOAN_DEFAULT_DATE);
  // Loans are grouped by startDate, so the keys will be the distinct startDate for all the loans
  const loanStartDatesAsGroupedLoanListKeys = Object.keys(groupedLoanList);
  const groupedLoanListValues = Object.values(groupedLoanList);
  let displayDeemedDate = false;

  const summaryResult = groupedLoanListValues.reduce(
    (loansSummary, groupedLoanListValue, currentIndex) => {
      const formattedOriginalLoanAmount =
        getOriginalLoanAmount(groupedLoanListValue);
      const formattedRemainingLoanAmount =
        getRemainingLoanAmount(groupedLoanListValue);
      const formattedLoanInterestRate =
        getLoanInterestRate(groupedLoanListValue);
      const formattedLoanDuration = getLoanDuration(groupedLoanListValue);
      const formattedNextBillDate = getNextBillDate(
        groupedLoanListValue,
        loanDatesDefaultValue
      );
      const formattedNextDueDate = getNextDueDate(
        groupedLoanListValue,
        loanDatesDefaultValue
      );
      const formattedDeemedDate = getDeemedDate(groupedLoanListValue);

      // display deemeddate row if at least one loan have deemed status for EE or ER
      displayDeemedDate =
        displayDeemedDate ||
        !compareStrings(formattedDeemedDate, DATA_UNAVAILABLE);

      if (isEmptyObject(loansSummary)) {
        return {
          originalLoanAmount: {
            header: "Original Loan Amount",
            loan1: formattedOriginalLoanAmount,
          },
          remainingLoanAmount: {
            header: "Remaining Loan Amount",
            loan1: formattedRemainingLoanAmount,
          },
          loanInitiationDate: {
            header: "Loan Initiation Date",
            loan1: formatDate(
              loanStartDatesAsGroupedLoanListKeys[0],
              DATA_UNAVAILABLE
            ),
          },
          loanInterestRate: {
            header: "Loan Interest Rate",
            loan1: formattedLoanInterestRate,
          },
          loanDuration: {
            header: "Loan Duration (Repays)",
            loan1: formattedLoanDuration,
          },
          nextBillDate: {
            header: "Next Bill Date",
            loan1: formattedNextBillDate,
          },
          nextDueDate: {
            header: "Next Due Date",
            loan1: formattedNextDueDate,
          },
          deemedDate: {
            header: "Deemed Date",
            loan1: formattedDeemedDate,
          },
        };
      }

      return {
        originalLoanAmount: {
          ...loansSummary.originalLoanAmount,
          [`loan${currentIndex + 1}`]: formattedOriginalLoanAmount,
        },
        remainingLoanAmount: {
          ...loansSummary.remainingLoanAmount,
          [`loan${currentIndex + 1}`]: formattedRemainingLoanAmount,
        },
        loanInitiationDate: {
          ...loansSummary.loanInitiationDate,
          [`loan${currentIndex + 1}`]: formatDate(
            loanStartDatesAsGroupedLoanListKeys[currentIndex],
            DATA_UNAVAILABLE
          ),
        },
        loanInterestRate: {
          ...loansSummary.loanInterestRate,
          [`loan${currentIndex + 1}`]: formattedLoanInterestRate,
        },
        loanDuration: {
          ...loansSummary.loanDuration,
          [`loan${currentIndex + 1}`]: formattedLoanDuration,
        },
        nextBillDate: {
          ...loansSummary.nextBillDate,
          [`loan${currentIndex + 1}`]: formattedNextBillDate,
        },
        nextDueDate: {
          ...loansSummary.nextDueDate,
          [`loan${currentIndex + 1}`]: formattedNextDueDate,
        },
        deemedDate: {
          ...loansSummary.deemedDate,
          [`loan${currentIndex + 1}`]: formattedDeemedDate,
        },
      };
    },
    {}
  );
  const loanSummary = [
    summaryResult.originalLoanAmount,
    summaryResult.remainingLoanAmount,
    summaryResult.loanInitiationDate,
    summaryResult.loanInterestRate,
    summaryResult.loanDuration,
    summaryResult.nextBillDate,
    summaryResult.nextDueDate,
  ];
  if (displayDeemedDate) {
    loanSummary.push(summaryResult.deemedDate);
  }

  return loanSummary;
};

const getLoanSummaryColumns = (outstandingLoanCount) => {
  const summaryColumns = [
    {
      accessor: "header",
      label: "",
      alignment: "left",
    },
    {
      accessor: "loan1",
      label: "Loan 1",
      alignment: "left",
    },
  ];
  if (outstandingLoanCount > 1) {
    summaryColumns.push({
      accessor: "loan2",
      label: "Loan 2",
      alignment: "left",
    });
  }
  if (outstandingLoanCount > 2) {
    summaryColumns.push({
      accessor: "loan3",
      label: "Loan 3",
      alignment: "left",
    });
  }
  return summaryColumns;
};

const LoanSummaryTable = (props) => {
  const { outstandingLoanCount, groupedLoanList } = props;

  const summaryColumns = getLoanSummaryColumns(outstandingLoanCount);
  const summaryRows = getLoanSummary(groupedLoanList);

  return (
    <Table
      id="loan_summary"
      columns={summaryColumns}
      rows={summaryRows}
      showPagination={false}
    />
  );
};

export default LoanSummaryTable;
