import React from "react";
import IsTruthy from "../../../common/logic/isTruthy";
import { compareStrings } from "../../../../utils/string";
import IsFalsy from "../../../common/logic/isFalsy";

const RiderModalCell = (props) => {
  const {
    row,
    rowIndex,
    column: { accessor },
    customFunctions,
  } = props;

  return (
    <>
      <IsFalsy
        value={row.productTypeCode}
        validationFunction={(val) => compareStrings(val, "DB")}
      >
        <button
          id={`${accessor}_${rowIndex}_btn`}
          onClick={customFunctions.showAnnuityModal(row)}
          className="btn btn-inline"
          type="button"
        >
          <span
            id={`${accessor}_${rowIndex}_value`}
            dangerouslySetInnerHTML={{ __html: row[accessor] }}
          />
        </button>
      </IsFalsy>
      <IsTruthy
        value={row.productTypeCode}
        validationFunction={(val) => compareStrings(val, "DB")}
      >
        <span id={`${accessor}_${rowIndex}_value`}>{row[accessor]}</span>
      </IsTruthy>
    </>
  );
};

export default RiderModalCell;
