import React from "react";
import { useConfirmationProps } from "../hooks";
import { fireTealiumAndFollowLink } from "../../../utils/tealium";

const Confirmation = (props) => {
  const {
    selectedFormName,
    policyId,
    agreementKey,
    userEmail,
    lineOfBusinessCode,
    transactionName,
    policyNumberLabelDisplayText,
    envelopeId,
  } = useConfirmationProps(props);

  return (
    <>
      <div className="row">
        <div className="col-md-9">
          <h1 className="h2">
            <div id="docusign_form_sent_heading">
              Thank you! Your Docusign form has been sent.
            </div>
          </h1>
        </div>
        <div className="col-3">
          <div id="printButton" className="justify-content-end">
            <button
              onClick={() => window.print()}
              data-bp-link="default"
              className="btn btn-inline hide-print"
              aria-label="Print"
              type="button"
            >
              <span
                id="docusign_form_sent_confirmation_print_icon"
                className="icon-print"
              />
              <span>Print</span>
            </button>
          </div>
        </div>
      </div>
      <p
        className="mm-spacing--minor"
        id="docusign_form_sent_confirmation_detailed_message"
      >
        The Docusign form has been successfully sent to the client. Once the
        form has been signed and submitted to MassMutual, it may take up to four
        hours to be viewable in the ServiceNet Service History. The request will
        be processed according to standard turn around times.
        <br /> <br /> Please review Service History for processing time and
        updates. If you have any questions regarding this request, please call
        us at 1-800-272-2216 8:00 AM - 8:00 PM ET, Monday through Friday and
        reference the policy number.
      </p>
      <hr className="mm-spacing" />
      <div className="row mm-spacing--minor">
        <div className="col-md-9">
          <dl className="mm-key-value-pair">
            <dt id="confirmation_docusign_form_name_lbl">Docusign Form</dt>
            <dd id="confirmation_docusign_form_name_value">
              {selectedFormName}
            </dd>
            <dt id="confirmation_docusign_form_policy_numbers_lbl">
              {policyNumberLabelDisplayText}
            </dt>
            <dd id="confirmation_docusign_form_policy_numbers_value">
              {policyId}
            </dd>
            <dt id="confirmation_docusign_form_envelopeId_lbl">Envelope Id</dt>
            <dd id="confirmation_docusign_form_envelopeId_value">
              {envelopeId}
            </dd>
          </dl>
        </div>
      </div>

      <div className="mm-spacing--minor">
        <button
          type="button"
          id="docusign_form_confirmation_serviceHistory_link_btn"
          data-bp-link="default"
          onClick={() =>
            fireTealiumAndFollowLink({
              url: `/policyDetails/${encodeURIComponent(
                agreementKey
              )}/viewServiceHistory`,
              action: `${lineOfBusinessCode}: ${transactionName} View Service History link clicked`,
              category: `${transactionName}`,
              value: policyId,
              custId: userEmail,
            })
          }
          className="btn btn-primary hide-print"
        >
          {" "}
          VIEW SERVICE HISTORY
        </button>
        <button
          type="button"
          id="docusign_form_confirmation_close_button"
          data-bp-link="default"
          onClick={() =>
            fireTealiumAndFollowLink({
              url: `/policyDetails/${encodeURIComponent(agreementKey)}`,
              action: `${lineOfBusinessCode}: ${transactionName} Close button clicked`,
              category: `${transactionName}`,
              value: policyId,
              custId: userEmail,
            })
          }
          className="btn btn-link hide-print"
        >
          {" "}
          Close
        </button>
      </div>
    </>
  );
};

export default Confirmation;
