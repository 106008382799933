import React from "react";
import IsTruthy from "../../../../common/logic/isTruthy";
import { isTruthy } from "../../../../../utils/evaluate";
import IsFalsy from "../../../../common/logic/isFalsy";
import { useDisabilityPolicyInfoProps } from "../hooks";

const DisabilityPolicyInfo = (props) => {
  const {
    data: {
      productName,
      productType,
      servicingAgent,
      servicingAgentName,
      monthlyBenefitMaximumAmount,
      issueDate,
      insuredPeople,
    },
  } = useDisabilityPolicyInfoProps(props);
  const insuredList = insuredPeople.map((person, index) => {
    const { fullName } = person;
    return (
      <div id={`insured_name_${index}`} key={fullName}>
        {fullName}
        <br />
      </div>
    );
  });

  return (
    <div>
      <dl className="mm-key-value-pair--summation">
        <dt id="insured_name_lbl">Insured Name</dt>
        <dd id="insured_name_value">
          <IsTruthy value={insuredList.length > 0}>{insuredList}</IsTruthy>
          <IsTruthy value={insuredList.length === 0}>-</IsTruthy>
        </dd>
        <dt className="hide-print" id="product_name_lbl">
          Product Name
        </dt>
        <dd className="hide-print" id="product_name_value">
          {productName}
        </dd>
        <dt className="hide-print" id="product_type_lbl">
          Product Type
        </dt>
        <dd className="hide-print" id="product_type_value">
          {productType}
        </dd>
        <dt className="hide-print" id="servicing_agent_name_lbl">
          Servicing Agent
        </dt>
        <dd className="hide-print" id="servicing_agent_full_name">
          <IsTruthy
            value={servicingAgent.validAgent}
            validationFunction={isTruthy}
          >
            {servicingAgentName}
          </IsTruthy>
          <IsFalsy
            value={servicingAgent.validAgent}
            validationFunction={isTruthy}
          >
            -
          </IsFalsy>
        </dd>
        <dt className="hide-print" id="monthly_benefit_lbl">
          Monthly Benefit for Total Disability
        </dt>
        <dd className="hide-print" id="monthly_benefit_value">
          {monthlyBenefitMaximumAmount}
        </dd>
        <dt className="hide-print" id="policy_date_display_title_lbl">
          Policy Date
        </dt>
        <dd className="hide-print" id="issue_date_display_value">
          {issueDate}
        </dd>
      </dl>
    </div>
  );
};

export default React.memo(DisabilityPolicyInfo);
