import { useState } from "react";
import { useParams } from "react-router-dom";
import { useUserState } from "../../../../contexts/user";
import { formatDate } from "../../../../utils/dates";
import { fireTealiumEvent } from "../../../../utils/tealium";
import { isPolicyInForce } from "../../../../utils/policies";
import { useHideEdsDiAsOfDate } from "../../../../utils/dataMasking";

export const useDisabilityPolicyDetailProps = (props) => {
  const {
    apiRequests: { agreementRequest },
  } = props;

  const { policyId, asOfDate, status, lineOfBusinessCode, agreementKeyAdmin } =
    agreementRequest.data;
  const { agreementKey } = useParams();

  const formattedAsOfDate = formatDate(asOfDate, "monthCommaDayYear");

  const [isDrawerOpened, setDrawerOpened] = useState(false);
  const [customer, setCustomer] = useState();
  const openDrawer = (customerObject) => () => {
    setCustomer(customerObject);
    setDrawerOpened(true);
  };
  const closeDrawer = () => setDrawerOpened(false);

  const {
    user: { email },
  } = useUserState();

  const printPDP = () => {
    fireTealiumEvent({
      label: "SVNT",
      action: "Print",
      category: "Policy Details",
      custId: email,
    });
    window.print();
  };

  const hideEdsDiAsOfDate = useHideEdsDiAsOfDate({ agreementKeyAdmin });

  return {
    policyId,
    agreementKey,
    policyInForce: isPolicyInForce(status),
    asOfDate: formattedAsOfDate,
    printPDP,
    isDrawerOpened,
    customer,
    openDrawer,
    closeDrawer,
    email,
    lineOfBusinessCode,
    hideEdsDiAsOfDate,
  };
};
