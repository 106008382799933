import React from "react";
import ErrorPage from "../common/errors/errorPage";

const LoginError = () => (
  <ErrorPage
    errorTitle="Our records indicate you do not have access to this application."
    errorPageFor="UnAuthorizedLogin"
    imageClass="illustration-error_500"
  />
);

export default React.memo(LoginError);
