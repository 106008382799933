import React, { useEffect } from "react";
import { getConfig, types } from "../../../config";
import { useUserState } from "../../../contexts/user";
import { isAfterDate } from "../../../utils/dates";
import { isMmsdOnlyAgent } from "../../../utils/findData";
import { compareArrayOfStrings } from "../../../utils/string";
import { fireTealiumAndFollowLink } from "../../../utils/tealium";
import { useLDValue } from "../featureFlags/hooks";
import { checkIfTransactionIsAllowed } from "../../../utils/policies";
import { supportedTransactions } from "../../../utils/translate";

const InForceIllustration = (props) => {
  const {
    policyId,
    agreementData = {},
    isQuickLink,
    email,
    isMobileOrTablet,
    setShowInForceIllustrationLink = () => {},
  } = props;

  const { status, issueDate, agreementKeyAdmin } = agreementData;

  const compareDate = new Date(issueDate);
  const classForPDP = isMobileOrTablet
    ? "dropdown-item"
    : "btn btn-inline mm-spacing--none external";
  const className = isQuickLink ? "btn btn-inline" : classForPDP;
  const { user } = useUserState();
  const transactionSupportedPolicies = useLDValue({
    flagName: ["transactionSupportedPolicies"],
  });

  const { isTransactionAllowed } = checkIfTransactionIsAllowed({
    transactionSupportedPolicies,
    transactionName: supportedTransactions.InForceIllustration,
    agreementData,
  });

  useEffect(() => {
    setShowInForceIllustrationLink(isTransactionAllowed);
  }, [isTransactionAllowed]);

  if (!compareArrayOfStrings(["IF", "ACTIVE"], status)) {
    return null;
  }
  if (!isTransactionAllowed) {
    return null;
  }

  const isMmsdOnlyUser = isMmsdOnlyAgent(user);

  if (isMmsdOnlyUser) {
    if (isQuickLink) {
      return null;
    }
    return (
      <li>
        <div className="alert alert-info" role="alert">
          To request an Inforce illustration, please call 1-800-601-9983 option
          5 for our Inforce Servicing area.
        </div>
      </li>
    );
  }

  if (
    !compareArrayOfStrings(["OPM", "MPR", "VNTG1", "PE1"], agreementKeyAdmin)
  ) {
    if (isQuickLink) {
      return null;
    }
    return (
      <li>
        <div className="alert alert-info" role="alert">
          Cannot link this product to a FieldNet Illustration System. Please
          call the IFL Support Team at 1-800-767-1000 ext. 40777.
        </div>
      </li>
    );
  }
  if (
    isAfterDate(
      compareDate.setFullYear(compareDate.getFullYear() + 1),
      new Date()
    )
  ) {
    if (isQuickLink) {
      return null;
    }
    return (
      <li>
        <div className="alert alert-info" role="alert">
          Illustrations are unavailable in the first policy year.
        </div>
      </li>
    );
  }

  return (
    <li>
      <button
        id="inForce-link"
        className={className}
        type="button"
        onClick={() =>
          fireTealiumAndFollowLink({
            url: `${getConfig(
              types.fieldNetUrl
            )}/paar/ifiRouter.do?method=initialLookup&accountNumber=${policyId}`,
            action: "In-Force Illustration link clicked",
            category: isQuickLink ? "QuickLink" : "PDP",
            value: policyId,
            custId: email,
            target: "_blank",
          })
        }
      >
        {" "}
        Run an In-Force Illustration
      </button>
    </li>
  );
};

export default InForceIllustration;
