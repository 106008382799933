export const processAgentProfileData = (props) => {
  const apiData = props?.response?.data;
  const agentData = apiData?.agentProfile?.agent || {};
  const messages = apiData?.messages || [
    {
      msgType: "",
      msgCode: "",
      msgDesc: "",
    },
  ];
  const { communications = [] } = agentData;

  return { communications, messages };
};
