/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { ScrollSpy } from "react-blueprint-library";
import { Modal } from "react-bootstrap";
// eslint-disable-next-line import/no-extraneous-dependencies
import MMLogo from "blueprint-css/images/mm-logo/MM_FullMark_294.svg";
import PolicyAndContactInfoDrawer from "../../../common/drawers/policyAndContactInfoDrawer";
import { setTitle } from "../../../../utils/dom";
import Billing from "../../sections/billing/index";
import CoverageAndRiders from "../../sections/coverageAndRiders";
import Dividends from "../../sections/dividends";
import Detail from "../../sections/detail";
import Documents from "../../sections/documents";
import Loan from "../../sections/loan";
import ServiceHistory from "../../sections/serviceHistory";
import FinancialHistorySection from "../../sections/financialHistory";
import PeopleOnPolicy from "../../sections/peopleOnPolicy";
import KPI from "../../sections/kpi";
import LifePolicyInfoHeader from "../../sections/basicPolicyInfoHeader/life/policyInfoHeader";
import { useLifePolicyDetailProps } from "./hooks";
import LoadingSection, {
  errorTypes,
  loadingTypes,
} from "../../../common/loading";
import "../../../../Print.scss";
import IsTruthy from "../../../common/logic/isTruthy";
import IsFalsy from "../../../common/logic/isFalsy";
import { tealiumPageViewEvent } from "../../../../utils/tealium";
import { isDefined } from "../../../../utils/evaluate";
import {
  AS_OF_DATE_UNAVAILABLE,
  COVERPATH_MIGRATED_ALERT,
  GUARANTEE_PERIOD_ENDING_TEXT,
  PREMIUM_FINANCE_IND_BANNER_TEXT,
} from "../../../../constants/ui";

const LifePolicyDetails = (props) => {
  setTitle("ServiceNet - Policy Details");
  const {
    apiRequests: {
      agreementRequest,
      loanRequest,
      billingRequest,
      producersRequest,
      serviceHistoryRequest,
      deathClaimEligibilityRequest,
    },
    unsupportedPolicy,
  } = props;
  const {
    isPallm,
    policyId,
    agreementKey,
    asOfDate,
    printPDP,
    showMecMessaging,
    mecIndicatorText,
    showGuaranteePeriodEndingIndicator,
    isDrawerOpened,
    customer,
    openDrawer,
    closeDrawer,
    policyInForce,
    email,
    hiddenScrollSpySections: { hideLoan, hideDividends },
    displayKpi,
    isQuickActionUnsupported,
    pallmPolicyDetailsText,
    showCoverpathMigratedAlert,
    coverpathContactNumber,
    coverpathContactTime,
    lineOfBusinessCode,
    showPremiumFinanceIndBanner,
  } = useLifePolicyDetailProps(props);

  useEffect(() => {
    tealiumPageViewEvent({
      page: "Life Policy Details",
      category: agreementKey,
      custId: email,
    });
  }, []);

  return (
    <>
      <div id="print_disclosure" className="print-only">
        <img
          id="mm_footer_logo_img"
          className="mm-logo"
          alt="MassMutual Logo"
          width="150"
          src={MMLogo}
        />{" "}
        <p>
          The information shown is as of {asOfDate} and could differ based on
          factors such as the last premium/purchase payment received, the
          policy/contract status, and any policy/contract changes that may have
          been submitted but not yet processed since that date. This report does
          not replace the official statements that you receive about this
          policy/contract. You should rely on your official statements as the
          most accurate source of information.
        </p>
      </div>

      <LifePolicyInfoHeader
        policyId={policyId}
        openDrawer={openDrawer}
        agreementRequest={agreementRequest}
        loanRequest={loanRequest}
        billingRequest={billingRequest}
        serviceHistoryRequest={serviceHistoryRequest}
        deathClaimEligibilityRequest={deathClaimEligibilityRequest}
        unsupportedPolicy={unsupportedPolicy}
        producerData={producersRequest?.data}
        isQuickActionUnsupported={isQuickActionUnsupported}
        isPallm={isPallm}
        email={email}
        lineOfBusinessCode={lineOfBusinessCode}
      />
      <IsTruthy value={showGuaranteePeriodEndingIndicator}>
        <div
          className="alert alert-info"
          role="alert"
          id="guarantee_period_ending_alert"
        >
          {GUARANTEE_PERIOD_ENDING_TEXT}
        </div>
      </IsTruthy>
      <IsTruthy value={showCoverpathMigratedAlert}>
        <div
          className="alert alert-info"
          role="alert"
          id="migrated_coverpath_alert"
        >
          {COVERPATH_MIGRATED_ALERT}
          <a href={`tel:+${coverpathContactNumber}`}>
            {coverpathContactNumber}
          </a>
          , {coverpathContactTime}.
        </div>
      </IsTruthy>
      <IsTruthy value={showMecMessaging}>
        <div
          id="pdp_mec_indicator_text"
          className="alert alert-info"
          role="alert"
        >
          {mecIndicatorText}
        </div>
      </IsTruthy>
      <IsTruthy value={showPremiumFinanceIndBanner}>
        <div
          id="pdp_premium_finance_indicator_text"
          className="alert alert-info"
          role="alert"
        >
          {PREMIUM_FINANCE_IND_BANNER_TEXT}
        </div>
      </IsTruthy>
      <IsTruthy value={displayKpi}>
        <KPI
          policyId={policyId}
          agreementKey={agreementKey}
          agreementRequest={agreementRequest}
          unsupportedPolicy={unsupportedPolicy}
          billingRequest={billingRequest}
          email={email}
        />
      </IsTruthy>
      <IsTruthy value={isPallm}>
        <div
          className="alert alert-info"
          role="alert"
          dangerouslySetInnerHTML={{
            __html: pallmPolicyDetailsText,
          }}
        />
      </IsTruthy>

      <LoadingSection
        request={agreementRequest}
        loadingType={loadingTypes.PARAGRAPH}
        errorType={errorTypes._500}
        unsupportedPolicy={unsupportedPolicy}
      >
        <IsTruthy value={policyInForce}>
          <IsTruthy value={asOfDate} validationFunction={isDefined}>
            <p id="as_of_date_value" className="copy-md mm-spacing">
              As of {asOfDate}
            </p>
          </IsTruthy>
          <IsFalsy value={asOfDate} validationFunction={isDefined}>
            <p id="as_of_date_value" className="copy-md mm-spacing">
              {AS_OF_DATE_UNAVAILABLE}
            </p>
          </IsFalsy>
        </IsTruthy>
        <hr className="mm-spacing" />
        <div id="printButton" className="justify-content-end">
          <button
            onClick={() => printPDP()}
            data-bp-link="default"
            className="btn btn-inline hide-print"
            aria-label="Print"
            type="button"
          >
            <span className="icon-print" />
            <span>Print</span>
          </button>
        </div>
        <ScrollSpy
          navClass="col-md-3 sn-scrollspy_navigation"
          contentClass="col-md-9 sn-scrollspy_content"
          behavior="smooth"
          placement="auth"
        >
          <div name="Policy Details" data-id="policyDetails">
            <Detail
              id="policyDetails"
              policyId={policyId}
              agreementKey={agreementKey}
              agreementRequest={agreementRequest}
              email={email}
            />
          </div>
          <div name="Coverage/Riders" data-id="coverageAndRiders">
            <CoverageAndRiders
              id="coverageAndRiders"
              agreementKey={agreementKey}
              agreementRequest={agreementRequest}
              billingData={billingRequest.data}
            />
          </div>
          <div name="People On Policy" data-id="peopleOnPolicy">
            <PeopleOnPolicy
              id="peopleOnPolicy"
              agreementRequest={agreementRequest}
              policyId={policyId}
              producerData={producersRequest?.data}
            />
          </div>
          <div name="Financial History" data-id="financialHistory">
            <FinancialHistorySection
              id="financial-History"
              agreementKey={agreementKey}
              agreementRequest={agreementRequest}
            />
          </div>
          <div name="Billing/Payments" data-id="billing-Payment">
            <Billing
              id="billing-Payment"
              agreementKey={agreementKey}
              agreementRequest={agreementRequest}
              policyId={policyId}
              billingRequest={billingRequest}
              email={email}
            />
          </div>
          {!hideDividends && (
            <div name="Dividends" data-id="dividends">
              <Dividends
                id="dividends"
                agreementKey={agreementKey}
                agreementRequest={agreementRequest}
                policyId={policyId}
                billingRequest={billingRequest}
              />
            </div>
          )}
          {!hideLoan && (
            <div name="Loans" data-id="loans">
              <Loan
                id="loans"
                agreementKey={agreementKey}
                agreementRequest={agreementRequest}
                policyId={policyId}
                loanRequest={loanRequest}
                email={email}
              />
            </div>
          )}
          <div name="Service History" data-id="serviceHistory">
            <ServiceHistory
              id="serviceHistory"
              agreementKey={agreementKey}
              agreementRequest={agreementRequest}
              serviceRequest={serviceHistoryRequest}
              policyId={policyId}
              email={email}
            />
          </div>
          <div name="Documents" data-id="documents">
            <Documents
              id="documents"
              agreementKey={agreementKey}
              agreementRequest={agreementRequest}
              policyId={policyId}
              email={email}
            />
          </div>
        </ScrollSpy>
      </LoadingSection>
      <Modal
        show={isDrawerOpened}
        onHide={closeDrawer}
        className="modal--drawer"
      >
        <Modal.Header>
          <button
            onClick={closeDrawer}
            type="button"
            className="close"
            aria-label="Close"
          />
        </Modal.Header>
        <Modal.Body id="sideDrawer">
          <PolicyAndContactInfoDrawer
            policyId={policyId}
            agreementRequest={agreementRequest}
            producerData={producersRequest?.data}
            customer={customer}
            producerRequest={producersRequest}
            billingRequest={billingRequest}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default React.memo(LifePolicyDetails);
