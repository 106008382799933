import { compareStrings } from "../../utils/string";

export const USER_REGISTRATION_NOT_ELIGIBLE_MESSAGE =
  "This client is not eligible for pre-registration through ServiceNet at this time. For troubleshooting, the client can contact our MassMutual.com  Support Line at 1-888-357-0053.";
export const USER_REGISTRATION_SSN_NOT_IN_FILE_MESSAGE =
  "This client is not eligible for pre-registration through ServiceNet at this time. There is no Social Security Number on file for this policy owner. A <a href='http://www.irs.gov/pub/irs-pdf/fw9.pdf' target='_blank' rel='noreferrer'>W-9</a> form must be submitted by the policy owner in order to register an account on MassMutual.com.";

export const USER_ELIGIBILTY_CHECK_ERRORS = [
  {
    statusCode: "409",
    errorCode: "email_in_use",
    displayMessage:
      "The email entered is already associated with a MassMutual.com account. Enter a different email to pre-register the client’s account.",
  },
  {
    statusCode: "404",
    errorCode: "customer_not_found",
    displayMessage: USER_REGISTRATION_NOT_ELIGIBLE_MESSAGE,
  },
  {
    statusCode: "403",
    errorCode: "customer_no_portal_access",
    displayMessage: USER_REGISTRATION_NOT_ELIGIBLE_MESSAGE,
  },
  {
    statusCode: "422",
    errorCode: "other_error",
    displayMessage: USER_REGISTRATION_NOT_ELIGIBLE_MESSAGE,
  },
  {
    statusCode: "409",
    errorCode: "identity_exists",
    displayMessage: "This client is currently registered on MassMutual.com.",
  },
];

export const getDisplayTextForError = (errorCode, defaultReturn = "") =>
  USER_ELIGIBILTY_CHECK_ERRORS.find((error) =>
    compareStrings(error.errorCode.toString(), errorCode)
  )?.displayMessage || defaultReturn;
