import React from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Form from "../common/forms/form";
import { ModalProvider } from "../../contexts/modal";
import { useDirectLinksProps } from "./hooks";

import LoadingSection, { errorTypes, loadingTypes } from "../common/loading";
import SelectDirectLinks from "./selectDirectLinks";
import IsTruthy from "../common/logic/isTruthy";
import IsFalsy from "../common/logic/isFalsy";
import ModalControl from "./modalControl";
import DirectLinksHeader from "./directLinksHeader";
import SelectRecipient from "./selectRecipient";
import Review from "./review";
import Header from "../layout/header";
import Footer from "../layout/footer";
import FourHundredFourError from "../common/errors/404";
import LdBannerContent from "../common/molecules/ldBannerContent";
import Confirmation from "./confirmation";
import { setTitle } from "../../utils/dom";
import FiveHundredError from "../common/errors/500";
import FiveHundredOneError from "../common/errors/501";
import ErrorPage from "../common/errors/errorPage";

const DirectLinks = () => {
  setTitle("ServiceNet - Direct Links");

  const { agreementKey } = useParams();
  const {
    fields,
    agreementRequest,
    billingRequest,
    producerRequest,
    pageNumber,
    nextPage,
    previousPage,
    onSubmit,
    error,
    shouldDisableSubmit,
    lineOfBusinessCode,
    displayDeepLinksFlow,
    showErrorPage,
    errorText,
    showLoadingIcon,
    isUnauthorized,
    isMmsdOnlyUser,
    isPolicyNotFound,
  } = useDirectLinksProps();

  if (isUnauthorized) {
    return (
      <>
        <Header />
        <div className="container">
          <LdBannerContent />
          <FiveHundredOneError />
        </div>
        <Footer />
      </>
    );
  }

  if (isPolicyNotFound) {
    return (
      <>
        <Header />
        <div className="container">
          <LdBannerContent />
          <FourHundredFourError />
        </div>
        <Footer />
      </>
    );
  }

  return (
    <LoadingSection
      request={agreementRequest}
      loadingType={loadingTypes.SPINNER}
      errorType={errorTypes._500}
      addHeaderFooterToError
    >
      <LoadingSection
        request={billingRequest}
        loadingType={loadingTypes.SPINNER}
        errorType={errorTypes.CHILDREN}
      >
        <LoadingSection
          request={producerRequest}
          loadingType={loadingTypes.SPINNER}
          errorType={errorTypes.CHILDREN}
        >
          <IsFalsy value={displayDeepLinksFlow}>
            <IsTruthy value={isMmsdOnlyUser}>
              <Header />
              <div className="container">
                <LdBannerContent />
                <ErrorPage
                  errorTitle="Our records indicate you do not have access to this application."
                  agreementKey={agreementRequest?.agreementKey}
                  errorPageFor="mmsdOnly"
                  imageClass="illustration-error_500"
                />
              </div>
              <Footer />
            </IsTruthy>
            <IsFalsy value={isMmsdOnlyUser}>
              <Header />
              <div className="container">
                <LdBannerContent />
                <FourHundredFourError />
              </div>
              <Footer />
            </IsFalsy>
          </IsFalsy>
          <IsTruthy value={displayDeepLinksFlow}>
            <IsTruthy value={showErrorPage}>
              <Header />
              <div className="container">
                <LdBannerContent />
                <FiveHundredError
                  turnOffReload
                  agreementKey={agreementKey}
                  title={errorText}
                />
              </div>
              <Footer />
            </IsTruthy>
            <IsFalsy value={showErrorPage}>
              <div className="container">
                <Form fields={fields}>
                  <ModalProvider>
                    <DirectLinksHeader
                      shouldDisableSubmit={shouldDisableSubmit}
                      pageNumber={pageNumber}
                      previousPage={previousPage}
                    />
                    <IsTruthy value={pageNumber === 1}>
                      <SelectRecipient />
                    </IsTruthy>
                    <IsTruthy value={pageNumber === 2}>
                      <SelectDirectLinks
                        agreementRequest={agreementRequest}
                        billingRequest={billingRequest}
                      />
                    </IsTruthy>
                    <IsTruthy value={pageNumber === 3}>
                      <Review />
                    </IsTruthy>
                    <IsTruthy value={pageNumber === 4}>
                      <Confirmation lineOfBusinessCode={lineOfBusinessCode} />
                    </IsTruthy>
                    <ModalControl
                      pageNumber={pageNumber}
                      onSubmit={onSubmit}
                      error={error}
                      nextPage={nextPage}
                      shouldDisableSubmit={shouldDisableSubmit}
                      lineOfBusinessCode={lineOfBusinessCode}
                      showLoadingIcon={showLoadingIcon}
                    />
                  </ModalProvider>
                </Form>
              </div>
            </IsFalsy>
          </IsTruthy>
        </LoadingSection>
      </LoadingSection>
    </LoadingSection>
  );
};

export default DirectLinks;
