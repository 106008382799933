import React from "react";
import { ModalProvider } from "../../contexts/modal";
import SelectDecedent from "./pages/selectDecedent/selectDecedent";
import DecedentInformation from "./pages/decedentInformation";
import BeneficiaryInformation from "./pages/beneficiaryInformation";
import ClaimsPacketAndForms from "./pages/claimsPacketAndForms";
import Review from "./pages/review";
import Confirmation from "./pages/confirmation";
import { useInitiateDeathClaimProps } from "./hooks";
import InitiateDeathClaimHeader from "./pages/initiateDeathClaimHeader";
import ModalControl from "./modalControl";
import IsTruthy from "../common/logic/isTruthy";
import IsFalsy from "../common/logic/isFalsy";
import Header from "../layout/header";
import Footer from "../layout/footer";
import LdBannerContent from "../common/molecules/ldBannerContent";
import FourHundredFourError from "../common/errors/404";
import ErrorPage from "../common/errors/errorPage";
import LoadingSection, { errorTypes, loadingTypes } from "../common/loading";
import Form from "../common/forms/form";
import { setTitle } from "../../utils/dom";
import FiveHundredError from "../common/errors/500";

const InitiateDeathClaim = () => {
  setTitle("ServiceNet - Initiate a Death Claim");

  const {
    pageNumber,
    previousPage,
    nextPage,
    onSubmit,
    setPageNumber,
    shouldDisableSubmit,
    shouldDisableContinue,
    setShouldDisableContinue,
    isTransactionAllowed,
    isMmsdOnly,
    agreementRequest,
    producerRequest,
    isPolicyDataLoaded,
    isEligible,
    showLoadingIcon,
    deathClaimFormFields,
    setBeneContactsCount,
    beneContactsCount,
    error,
    setError,
    claimRecipientsContactsCount,
    setClaimRecipientsContactsCount,
    lineOfBusinessCode,
    policyId,
    email,
    transactionName,
    showErrorPage,
    user,
    errorText,
    agreementKey,
    isPolicyNotFound,
    isDiOnlyClaim,
    setIsDiOnlyClaim,
    eligibleCustomers,
    deathClaimEligibilityDataRequest,
  } = useInitiateDeathClaimProps();

  if (isPolicyNotFound) {
    return (
      <>
        <Header />
        <div className="container">
          <LdBannerContent />
          <FourHundredFourError />
        </div>
        <Footer />
      </>
    );
  }

  return (
    <LoadingSection
      request={agreementRequest}
      loadingType={loadingTypes.SPINNER}
      errorType={errorTypes._500}
      addHeaderFooterToError
    >
      <LoadingSection
        request={deathClaimEligibilityDataRequest}
        loadingType={loadingTypes.SPINNER}
        errorType={errorTypes._500}
        addHeaderFooterToError
      >
        <IsTruthy value={isPolicyDataLoaded}>
          <IsFalsy value={isTransactionAllowed}>
            <IsTruthy value={isMmsdOnly}>
              <Header />
              <div className="container">
                <LdBannerContent />
                <ErrorPage
                  errorTitle="Our records indicate you do not have access to this application."
                  agreementKey={agreementRequest?.agreementKey}
                  errorPageFor="mmsdOnly"
                  imageClass="illustration-error_500"
                />
              </div>
              <Footer />
            </IsTruthy>
            <IsFalsy value={isMmsdOnly}>
              <Header />
              <div className="container">
                <LdBannerContent />
                <FourHundredFourError />
              </div>
              <Footer />
            </IsFalsy>
          </IsFalsy>
          <IsTruthy value={isTransactionAllowed}>
            <IsFalsy value={isEligible}>
              <Header />
              <div className="container">
                <LdBannerContent />
                <FourHundredFourError />
              </div>
              <Footer />
            </IsFalsy>
          </IsTruthy>
          <IsTruthy value={showErrorPage}>
            <Header />
            <div className="container">
              <LdBannerContent />
              <FiveHundredError title={errorText} agreementKey={agreementKey} />
            </div>
            <Footer />
          </IsTruthy>
          <IsTruthy
            value={isTransactionAllowed && isEligible && !showErrorPage}
          >
            <div className="container">
              <Form fields={deathClaimFormFields}>
                <ModalProvider>
                  <InitiateDeathClaimHeader
                    pageNumber={pageNumber}
                    previousPage={previousPage}
                    shouldDisableSubmit={shouldDisableSubmit}
                    isDiOnlyClaim={isDiOnlyClaim}
                  />
                  <IsTruthy value={pageNumber === 1}>
                    <SelectDecedent
                      setShouldDisableContinue={setShouldDisableContinue}
                      setIsDiOnlyClaim={setIsDiOnlyClaim}
                      eligibleCustomers={eligibleCustomers}
                    />
                  </IsTruthy>
                  <IsTruthy value={pageNumber === 2}>
                    <DecedentInformation
                      setShouldDisableContinue={setShouldDisableContinue}
                    />
                  </IsTruthy>
                  <IsTruthy value={pageNumber === 3}>
                    <IsFalsy value={isDiOnlyClaim}>
                      <BeneficiaryInformation
                        setBeneContactsCount={setBeneContactsCount}
                        beneContactsCount={beneContactsCount}
                        setShouldDisableContinue={setShouldDisableContinue}
                        claimRecipientsContactsCount={
                          claimRecipientsContactsCount
                        }
                      />
                    </IsFalsy>
                    <IsTruthy value={isDiOnlyClaim}>
                      <ClaimsPacketAndForms
                        producerRequest={producerRequest}
                        claimRecipientsContactsCount={
                          claimRecipientsContactsCount
                        }
                        setClaimRecipientsContactsCount={
                          setClaimRecipientsContactsCount
                        }
                        isDiOnlyClaim={isDiOnlyClaim}
                      />
                    </IsTruthy>
                  </IsTruthy>
                  <IsTruthy value={pageNumber === 4}>
                    <IsFalsy value={isDiOnlyClaim}>
                      <ClaimsPacketAndForms
                        producerRequest={producerRequest}
                        claimRecipientsContactsCount={
                          claimRecipientsContactsCount
                        }
                        setClaimRecipientsContactsCount={
                          setClaimRecipientsContactsCount
                        }
                      />
                    </IsFalsy>
                    <IsTruthy value={isDiOnlyClaim}>
                      <Review
                        setPageNumber={setPageNumber}
                        claimRecipientsContactsCount={
                          claimRecipientsContactsCount
                        }
                        isDiOnlyClaim={isDiOnlyClaim}
                      />
                    </IsTruthy>
                  </IsTruthy>
                  <IsTruthy value={pageNumber === 5}>
                    <IsFalsy value={isDiOnlyClaim}>
                      <Review
                        setPageNumber={setPageNumber}
                        claimRecipientsContactsCount={
                          claimRecipientsContactsCount
                        }
                      />
                    </IsFalsy>
                    <IsTruthy value={isDiOnlyClaim}>
                      <Confirmation
                        setPageNumber={setPageNumber}
                        claimRecipientsContactsCount={
                          claimRecipientsContactsCount
                        }
                        policyId={policyId}
                        userEmail={email}
                        lineOfBusinessCode={lineOfBusinessCode}
                        transactionName={transactionName}
                      />
                    </IsTruthy>
                  </IsTruthy>
                  <IsTruthy value={pageNumber === 6}>
                    <Confirmation
                      setPageNumber={setPageNumber}
                      claimRecipientsContactsCount={
                        claimRecipientsContactsCount
                      }
                      policyId={policyId}
                      userEmail={email}
                      lineOfBusinessCode={lineOfBusinessCode}
                      transactionName={transactionName}
                    />
                  </IsTruthy>
                  <ModalControl
                    pageNumber={pageNumber}
                    nextPage={nextPage}
                    onSubmit={onSubmit}
                    showLoadingIcon={showLoadingIcon}
                    error={error}
                    setError={setError}
                    shouldDisableSubmit={shouldDisableSubmit}
                    shouldDisableContinue={shouldDisableContinue}
                    lineOfBusinessCode={lineOfBusinessCode}
                    policyId={policyId}
                    email={email}
                    transactionName={transactionName}
                    claimRecipientsContactsCount={claimRecipientsContactsCount}
                    user={user}
                    isDiOnlyClaim={isDiOnlyClaim}
                  />
                </ModalProvider>
              </Form>
            </div>
          </IsTruthy>
        </IsTruthy>
      </LoadingSection>
    </LoadingSection>
  );
};

export default InitiateDeathClaim;
