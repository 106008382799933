import React from "react";
import LabelWithHelpModal from "../../common/forms/labelWithHelpModal";

const HistoricalCashValueModalBody = () => (
  <div className="modal-body">
    <hr />
    <h5 id="directLink_HistoricalCashValues_ModalTitle">
      Historical Cash Values
    </h5>
    <p>
      The following can be done on the <strong>website </strong>or{" "}
      <strong>mobile</strong> app:
    </p>
    <ul>
      <li>
        Customer can view/print Month End Cash Values on company letterhead.
      </li>
    </ul>
    <div className="mm-spacing--minor" />
    <h5 id="directLink_HistoricalCashValues_ModalQuickTips">Quick Tips</h5>
    <ul>
      <li>
        The Cash Value Quote generated on MassMutual.com can be viewed in the
        documents tab on the mobile app.
      </li>
    </ul>
  </div>
);

const HistoricalCashValueModal = () => (
  <LabelWithHelpModal
    text="Historical Cash Values"
    modalTitle="Historical Cash Values"
    modalId="historicalCashValues"
    modalBodyComponent={HistoricalCashValueModalBody}
  />
);

export default HistoricalCashValueModal;
