import React from "react";
import DecedentPolicy from "./decedentPolicy";
import RadioForm from "../../../common/forms/radioForm";

const DecedentCard = (props) => {
  const {
    userField,
    useHook,
    setShouldDisableContinue,

    setIsDiOnlyClaim,
    eligibleCustomers,
  } = props;

  const decedentPolicies = userField.policies.map((policy, i) => (
    <DecedentPolicy
      key={policy.agreementKeyPrimaryDisplay}
      productTypeName={policy.productTypeName}
      agreementKeyPrimaryDisplay={policy.agreementKeyPrimaryDisplay}
      roleTypeDisplay={policy.roleTypeDisplay}
      id={i}
    />
  ));

  return (
    <div id="decendentCard" className="mm-spacing col-sm-12 col-md-6">
      <div className="card">
        <div className="card-body">
          <div id="decedent" className="mm-spacing">
            <div className="row">
              <div className="col-9">
                <RadioForm
                  formField={userField}
                  useHook={useHook}
                  customProps={{
                    setShouldDisableContinue,
                    setIsDiOnlyClaim,
                    eligibleCustomers,
                  }}
                />
              </div>
              <div className="col-3 justify-content-end">
                <span className="icon-user icon-lg" />
              </div>
            </div>
          </div>

          <div id="decedentPolicies">{decedentPolicies}</div>
        </div>
      </div>
    </div>
  );
};

export default DecedentCard;
