import axios from "axios";
import { trimStart } from "../utils/string";

// eslint-disable-next-line no-underscore-dangle

const baseUrl = window?._env?.REACT_APP_API_URL || "";
export const fetchAgentSearchResults = (props) => {
  const { headers, apiParams } = props;
  const url = `${baseUrl}/v1/agentSearch/search`;
  return axios.post(url, apiParams, { headers });
};

export const fetchAgreementData = (props) => {
  const {
    apiParams: { agreementKey },
    headers,
  } = props;
  const url = `${baseUrl}/v1/agreements/${encodeURIComponent(agreementKey)}`;
  return axios.get(url, { headers });
};

export const fetchAgreementCoreDataByCustId = (props) => {
  const {
    apiParams: { customerId },
    headers,
  } = props;
  const url = `${baseUrl}/v1/agreements/cores?customerid=${encodeURIComponent(
    customerId
  )}`;
  return axios.get(url, { headers });
};

export const fetchCustomerEligibilityData = (props) => {
  const {
    apiParams: { memberGUID },
    headers,
  } = props;
  const url = `${baseUrl}/v1/customers/${encodeURIComponent(
    memberGUID
  )}/eligibility`;
  return axios.get(url, { headers });
};

export const fetchCustomerData = (props) => {
  const {
    apiParams: { memberGUID },
    headers,
  } = props;
  const url = `${baseUrl}/v1/customers/${encodeURIComponent(memberGUID)}`;
  return axios.get(url, { headers });
};

export const fetchFinancialHistory = (props) => {
  const {
    apiParams: { agreementKey },
    headers,
  } = props;
  const url = `${baseUrl}/v1/financials/${encodeURIComponent(
    agreementKey
  )}/history`;
  return axios.get(url, { headers });
};

export const fetchProducersData = (props) => {
  const {
    apiParams: { agreementKey, extendedApiCall = false },
    headers,
  } = props;

  const url = `${baseUrl}/v1/producers/${encodeURIComponent(
    agreementKey
  )}/${encodeURIComponent(extendedApiCall)}`;
  return axios.get(url, { headers });
};

export const fetchProducerData = (props) => {
  const {
    apiParams: { producerId },
    headers,
  } = props;

  const url = `${baseUrl}/v1/producer/${producerId}`;

  return axios.get(url, { headers });
};

export const fetchLoanData = (props) => {
  const {
    apiParams: { agreementKey },
    headers,
  } = props;
  const url = `${baseUrl}/v1/loans/${encodeURIComponent(agreementKey)}`;
  return axios.get(url, { headers });
};

export const initiateLoan = (props) => {
  const { headers, apiParams, agreementKey } = props;
  const url = `${baseUrl}/v1/loans/${encodeURIComponent(
    agreementKey
  )}/initiateLoan`;
  return axios.post(url, apiParams, { headers });
};

export const fetchBillingInformation = (props) => {
  const {
    apiParams: { agreementKey, showExtended = true },
    headers,
  } = props;
  const url = `${baseUrl}/v1/billing/${encodeURIComponent(
    agreementKey
  )}?showExtended=${encodeURIComponent(showExtended)}`;

  return axios.get(url, { headers });
};

export const fetchImagelistFromContent = (props) => {
  const {
    apiParams: { agreementKey },
    headers,
  } = props;
  const url = `${baseUrl}/v1/content/imagelist/${encodeURIComponent(
    agreementKey
  )}`;

  return axios.get(url, { headers });
};

export const fetchDocumentFromContent = (props) => {
  const {
    apiParams: { agreementKey, docObjStore, docGuid },
    headers,
  } = props;
  const url = `${baseUrl}/v1/content/image/${encodeURIComponent(
    agreementKey
  )}/${encodeURIComponent(docObjStore)}/${encodeURIComponent(docGuid)}`;

  return axios.get(url, { headers });
};

export const fetchPdfFromDocumentId = (props) => {
  const {
    apiParams: { documentId },
    headers,
  } = props;
  const url = `${baseUrl}/v1/pdf/${encodeURIComponent(documentId)}`;

  return axios.get(url, { headers });
};

export const fetchDataByPolicyNumber = (props) => {
  const {
    apiParams: { searchString },
    headers,
  } = props;
  const url = `${baseUrl}/v1/agreements/${encodeURIComponent(
    searchString
  )}/search/policyId`;
  return axios.get(url, { headers });
};

export const fetchDataByGovtId = (props) => {
  const {
    apiParams: { searchString },
    headers,
  } = props;
  const url = `${baseUrl}/v1/agreements/agreementKey/search/ssnOrTin?govtid=${encodeURIComponent(
    searchString
  )}`;
  return axios.get(url, { headers });
};

export const fetchBeneficiaries = (props) => {
  const {
    apiParams: { agreementKey },
    headers,
  } = props;
  const url = `${baseUrl}/v1/beneficiary/beneficiaries/${encodeURIComponent(
    agreementKey
  )}`;
  return axios.get(url, { headers });
};

export const fetchUSPSAddressVerification = (props) => {
  const {
    apiParams: { street, zip, city, state },
    headers,
  } = props;
  const url = `${baseUrl}/v1/verifyAddress/verifyAddress?street=${street}&zip=${zip}&city=${city}&state=${state}`;
  return axios.get(url, { headers });
};

export const fetchCatsEvents = (props) => {
  const {
    apiParams: { agreementKey },
    headers,
  } = props;
  const url = `${baseUrl}/v1/cats/cats/${encodeURIComponent(agreementKey)}`;
  return axios.get(url, { headers });
};

export const createCatsEvents = (props) => {
  const { headers, apiParams } = props;
  const url = `${baseUrl}/v1/cats/createCats`;
  return axios.post(url, apiParams, { headers });
};

export const fetchAnnuityByContractNbr = (props) => {
  const {
    apiParams: { contractNbr },
    headers,
  } = props;
  const url = `${baseUrl}/v1/annuities/${encodeURIComponent(contractNbr)}`;
  return axios.get(url, { headers });
};

export const fetchAgentProfile = (props) => {
  const {
    headers,
    apiParams: { businessPartnerId },
  } = props;
  const url = `${baseUrl}/v1/agents/${encodeURIComponent(
    businessPartnerId
  )}/profile`;
  return axios.get(url, { headers });
};

export const fetchAnnuityByAgreementKey = (props) => {
  const {
    apiParams: { agreementKey },
    headers,
  } = props;
  const url = `${baseUrl}/v1/annuities/soa/${encodeURIComponent(
    trimStart(agreementKey, "0")
  )}`;
  return axios.get(url, { headers });
};

export const updateCustomersAddress = (props) => {
  const { apiParams, headers } = props;
  const url = `${baseUrl}/v1/address/customers`;
  return axios.put(url, apiParams, { headers });
};

export const fetchEncryptedDataUsingCryptor = (props) => {
  const { apiParams, headers } = props;
  const url = `${baseUrl}/v1/encryptData/cryptor/encrypt`;
  return axios.post(url, apiParams, { headers });
};

export const fetchSoaBillingArrangement = (props) => {
  const {
    apiParams: { agreementKey, carrierCode },
    headers,
  } = props;
  const url = `${baseUrl}/v1/annuities/soa/billingArrangement/${encodeURIComponent(
    agreementKey
  )}/${encodeURIComponent(carrierCode)}`;
  return axios.get(url, { headers });
};

export const fetchCdaValues = (props) => {
  const {
    apiParams: { agreementKey, asOfDate, carrierCode },
    headers,
  } = props;
  const url = `${baseUrl}/v1/annuities/soa/cdaValues/${encodeURIComponent(
    agreementKey
  )}/${encodeURIComponent(asOfDate)}/${encodeURIComponent(carrierCode)}`;
  return axios.get(url, { headers });
};

export const fetchRecalculatedVNTGKCValues = (props) => {
  const {
    apiParams: {
      agreementKey,
      formattedSelectedDate,
      carrierCode,
      transactionCode,
    },
    headers,
  } = props;
  const url = `${baseUrl}/v1/annuities/soa/recalc/${encodeURIComponent(
    trimStart(agreementKey, "0")
  )}/${encodeURIComponent(carrierCode)}/${encodeURIComponent(
    formattedSelectedDate
  )}/${encodeURIComponent(transactionCode)}
  `;
  return axios.get(url, { headers });
};

export const fetchFundTransferEligibility = (props) => {
  const { apiParams, headers } = props;
  const { memberGUID, agreementKey, ...requestBody } = apiParams;
  const url = `${baseUrl}/v1/customers/${memberGUID}/${agreementKey}/fundTransferEligibility`;
  return axios.post(url, requestBody, { headers });
};

export const fetchAgreementDataByCustomerMemberGuid = (props) => {
  const {
    apiParams: { customerMemberGuid },
    headers,
  } = props;

  const url = `${baseUrl}/v1/customers/${encodeURIComponent(
    customerMemberGuid
  )}/agreements`;

  return axios.get(url, { headers });
};

export const fetchBeneficiariesByAgreementKeys = (props) => {
  const { apiParams, headers } = props;

  const url = `${baseUrl}/v1/beneficiary/eligiblePoliciesBeneficiaries`;
  return axios.post(url, apiParams, { headers });
};

export const fetchFillablePdfForm = (props) => {
  const { apiParams, headers } = props;
  const url = `${baseUrl}/v1/pdf/fillablePdfForm`;
  return axios.post(url, apiParams, { headers });
};

export const fetchPdfFromContentApi = (props) => {
  const { apiParams, headers } = props;
  const url = `${baseUrl}/v1/content/pdfdocument`;
  return axios.post(url, apiParams, { headers });
};

export const createDeathClaimNotification = (props) => {
  const { apiParams, headers, agreementKey } = props;
  const url = `${baseUrl}/v1/claims/${agreementKey}/initiateDeathClaim`;
  return axios.post(url, apiParams, { headers });
};

export const sendDirectLinksNotificationEmail = (props) => {
  const { apiParams, headers } = props;
  const url = `${baseUrl}/v1/directLinks/emails/sendDirectLinks`;
  return axios.post(url, apiParams, { headers });
};

export const fetchAgentNotifications = (props) => {
  const { apiParams, headers } = props;
  const { extended = false } = apiParams;
  const url = `${baseUrl}/v1/notifications?extended=${encodeURIComponent(
    extended
  )}`;
  return axios.post(url, apiParams, { headers });
};

export const fetchAgentUnseenNotificationsCount = (props) => {
  const { apiParams, headers } = props;
  const url = `${baseUrl}/v1/notifications/unseen/count`;
  return axios.post(url, apiParams, { headers });
};

export const fetchCatsByWorkEventId = (props) => {
  const {
    headers,
    apiParams: { workEventId },
  } = props;
  const url = `${baseUrl}/v1/cats/catsByWorkEventId/${encodeURIComponent(
    workEventId
  )}`;
  return axios.get(url, { headers });
};

export const fetchDeathClaimEligibilityData = (props) => {
  const { apiParams, headers } = props;
  const url = `${baseUrl}/v1/claims/eligibility`;
  return axios.post(url, apiParams, { headers });
};

export const fetchUserDigitalRegistrationEligibility = (props) => {
  const { apiParams, headers } = props;
  const url = `${baseUrl}/v1/digitalRegistration/eligibility`;
  return axios.post(url, apiParams, { headers });
};

export const registerUser = (props) => {
  const { apiParams, headers } = props;
  const url = `${baseUrl}/v1/digitalRegistration/registerUser`;
  return axios.post(url, apiParams, { headers });
};

export const updateNotificationsLastViewed = (props) => {
  const { apiParams, headers } = props;
  const url = `${baseUrl}/v1/notifications/lastViewed`;
  return axios.post(url, apiParams, { headers });
};
export const fetchStatementPreferences = (props) => {
  const { apiParams, headers } = props;
  const url = `${baseUrl}/v1/agreements/statementPreferences`;
  return axios.post(url, apiParams, { headers });
};

export const sendEsignatureEnvelope = (props) => {
  const { apiParams, headers, agreementKey } = props;
  const url = `${baseUrl}/v1/eSignature/${agreementKey}/envelopes`;
  return axios.post(url, apiParams, { headers });
};

export const createEsignatureRecipientView = (props) => {
  const { apiParams, headers, agreementKey, envelopeId } = props;
  const url = `${baseUrl}/v1/eSignature/${agreementKey}/envelopes/${envelopeId}/views/recipient`;
  return axios.post(url, apiParams, { headers });
};
