import React, { useEffect } from "react";
import { useLDValue } from "../common/featureFlags/hooks";
import { fireTealiumAndFollowLink } from "../../utils/tealium";
import IsTruthy from "../common/logic/isTruthy";
import IsFalsy from "../common/logic/isFalsy";
import { verifyDirectLinks } from "../directLinks/restrictions";

const DirectLinksLink = (props) => {
  const {
    data,
    setShowDirectLinksLink = () => {},
    policyId,
    userEmail,
    tealiumCategory,
    target,
    isRenderAsListItem = false,
    cssClassName = "btn btn-inline",
  } = props;

  const { agreementKey } = data;

  const transactionSupportedPolicies = useLDValue({
    flagName: ["transactionSupportedPolicies"],
  });

  const { isEligible, isTransactionAllowed } = verifyDirectLinks({
    agreementData: data,
    transactionSupportedPolicies,
  });

  const isEligibleForDirectLinks = isEligible && isTransactionAllowed;

  useEffect(() => {
    setShowDirectLinksLink(isEligibleForDirectLinks);
  }, [isEligibleForDirectLinks]);

  return (
    <IsTruthy value={isEligibleForDirectLinks}>
      <IsTruthy value={isRenderAsListItem}>
        <li>
          <button
            type="button"
            id="direct-links-link"
            className={cssClassName}
            onClick={() =>
              fireTealiumAndFollowLink({
                url: `/policyDetails/${encodeURIComponent(
                  agreementKey
                )}/sendDirectLinks`,
                action: "Direct Links link clicked",
                category: tealiumCategory,
                value: policyId,
                custId: userEmail,
                target,
              })
            }
          >
            Send Direct Link to MassMutual.com
          </button>
        </li>
      </IsTruthy>
      <IsFalsy value={isRenderAsListItem}>
        <button
          className={cssClassName}
          id="direct-links-link"
          type="button"
          onClick={() =>
            fireTealiumAndFollowLink({
              url: `/policyDetails/${encodeURIComponent(
                agreementKey
              )}/sendDirectLinks`,
              action: "Direct Links link clicked",
              category: tealiumCategory,
              value: policyId,
              custId: userEmail,
            })
          }
        >
          Send Direct Link to MassMutual.com
        </button>
      </IsFalsy>
    </IsTruthy>
  );
};

export default React.memo(DirectLinksLink);
