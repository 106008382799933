import { formatMoney } from "../../../../utils/format";
import { formatDate } from "../../../../utils/dates";
import { isRetireEaseContract } from "../../../../utils/policies";
import {
  containsArrayOfStrings,
  stringIsEmptyOrNullish,
} from "../../../../utils/string";
import { isNumber } from "../../../../utils/number";

export const usePaymentInformationProps = (props) => {
  const { annuityRequest = {}, agreementRequest } = props;
  const annuityData = annuityRequest?.data;
  const agreementData = agreementRequest?.data;

  const isRefundAnnuityOption = containsArrayOfStrings(
    ["Cash Refund", "Installment Refund"],
    annuityData?.annuityOption
  );

  const totalContributions = !stringIsEmptyOrNullish(
    annuityData?.totalContributions
  )
    ? Number(annuityData?.totalContributions)
    : "";
  const totalDistributions = !stringIsEmptyOrNullish(
    annuityData?.totalDistributions
  )
    ? Number(annuityData?.totalDistributions)
    : "";

  const remainingInstallment =
    isNumber(totalContributions) &&
    isNumber(totalDistributions) &&
    totalContributions >= totalDistributions
      ? totalContributions - totalDistributions
      : "-";

  const remainingInstallmentLabel = containsArrayOfStrings(
    ["Cash Refund"],
    annuityData?.annuityOption
  )
    ? "Remaining Cash Refund"
    : "Remaining Installment";

  return {
    isRetireEase: isRetireEaseContract(agreementData),
    disabledDescription: annuityData?.disabled === "Y" ? "Yes" : "No",
    paymentMode: annuityData?.paymentMode,
    nextPaymentDate: formatDate(annuityData?.nextPayoutDate, "MM/DD/YYYY", "-"),
    maturityDate: formatDate(annuityData?.maturityDate, "MM/DD/YYYY", "-"),
    benefitAmount: formatMoney(annuityData?.benefitAmount, "-"),
    remainingInstallment: formatMoney(remainingInstallment, "-"),
    isRefundAnnuityOption,
    remainingInstallmentLabel,
  };
};
