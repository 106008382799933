// import React from "react";
import { formatDate } from "../../../../utils/dates";
import { formatMoney, formatPercentage } from "../../../../utils/format";
import { useMakeRequest } from "../../../../hooks/api";
import {
  stringIsEmptyOrNullish,
  compareArrayOfStrings,
  compareStrings,
} from "../../../../utils/string";
import { DATA_UNAVAILABLE } from "../../../../constants/ui";
import {
  isCoverpathPolicy,
  isPermCoverPath,
  isPermPolicy,
  isPolicyInForce,
  verifyInitiateNewLoan,
} from "../../../../utils/policies";
import { useLDValue } from "../../../common/featureFlags/hooks";
import { checkStatusAndReason } from "../../hooks";

export const useLoanData = (props) => {
  const { agreementKey } = props;

  const billingRequest = useMakeRequest({
    apiRequest: "fetchBillingInformation",
    apiParams: { agreementKey },
    dataSelector: "processBillingInformation",
    immediateRequest: true,
  });
  return billingRequest;
};

export const useFormatLoanData = (props) => {
  const {
    agreementRequest,
    loanData: { loan },
  } = props;
  const {
    twoDayAdvancedPayoffAsOfDate,
    twoDayAdvancedPayoffAmount,
    outstandingPrinicipalBalance,
    advancedPayoffAsOfDate,
    advancedPayoffAmount,
    twoDayAdvancedPayoffPrincipal,
    twoDayAdvancedPayoffInterest,
    payoffAmountAsOfDate,
    payoffAmount,
  } = loan;
  const {
    loanInterestAmount,
    maximumAvailableLoanAmount,
    loanInterestType,
    loanInterestRate,
    asOfDate,
    majorProductCode,
    agreementKeyAdmin,
    minorProductCode,
  } = agreementRequest.data;

  const showLoanPayoffSection = Object.keys(loan).length > 0;

  const isNTLProduct = checkNTLConditions(agreementRequest.data);
  const isHapPerm = isPermCoverPath(agreementRequest.data);

  const isNTLPE1ULProduct =
    compareStrings(majorProductCode, "NTL") &&
    compareStrings("PE1", agreementKeyAdmin) &&
    compareStrings("UL", minorProductCode);

  const isNTLPE1VLProduct =
    compareStrings(majorProductCode, "NTL") &&
    compareStrings("PE1", agreementKeyAdmin) &&
    compareStrings("VL", minorProductCode);

  const validTwoDayAdvancedPayoffAmount =
    parseFloat(twoDayAdvancedPayoffAmount) > 0;
  const validTwoDayAdvancedPayoffPrincipal =
    parseFloat(twoDayAdvancedPayoffPrincipal) > 0;
  const validTwoDayAdvancedPayoffInterest =
    parseFloat(twoDayAdvancedPayoffInterest) > 0;

  let convertedLoanPrincipal;
  let convertedLoanInterestAmount;
  if (isPermPolicy({ agreementRequest }) || isHapPerm || isNTLPE1ULProduct) {
    convertedLoanPrincipal = stringIsEmptyOrNullish(
      twoDayAdvancedPayoffPrincipal
    )
      ? DATA_UNAVAILABLE
      : formatMoney(twoDayAdvancedPayoffPrincipal);
    convertedLoanInterestAmount = stringIsEmptyOrNullish(
      twoDayAdvancedPayoffInterest
    )
      ? DATA_UNAVAILABLE
      : formatMoney(twoDayAdvancedPayoffInterest);
  } else {
    convertedLoanPrincipal = stringIsEmptyOrNullish(
      outstandingPrinicipalBalance
    )
      ? DATA_UNAVAILABLE
      : formatMoney(outstandingPrinicipalBalance);
    convertedLoanInterestAmount = stringIsEmptyOrNullish(loanInterestAmount)
      ? DATA_UNAVAILABLE
      : formatMoney(loanInterestAmount);
  }

  const payOffBalance = isNTLProduct
    ? payoffAmount
    : twoDayAdvancedPayoffAmount;
  const convertedPayoffBalance = stringIsEmptyOrNullish(payOffBalance)
    ? DATA_UNAVAILABLE
    : formatMoney(payOffBalance);
  const convertedLoanInterestType = stringIsEmptyOrNullish(loanInterestType)
    ? DATA_UNAVAILABLE
    : loanInterestType;
  const convertedLoanInterestRate = stringIsEmptyOrNullish(loanInterestRate)
    ? DATA_UNAVAILABLE
    : formatPercentage({ rate: loanInterestRate, decimal: 2 });
  const convertedMaxLoanAmount = stringIsEmptyOrNullish(
    maximumAvailableLoanAmount
  )
    ? DATA_UNAVAILABLE
    : formatMoney(maximumAvailableLoanAmount);
  const convertedMaxLoanAsOfDate = stringIsEmptyOrNullish(asOfDate)
    ? DATA_UNAVAILABLE
    : formatDate(asOfDate);

  const loanPayoffDate = isNTLProduct
    ? payoffAmountAsOfDate
    : twoDayAdvancedPayoffAsOfDate;
  const convertedLoanPayoffAsOfDate = stringIsEmptyOrNullish(loanPayoffDate)
    ? DATA_UNAVAILABLE
    : formatDate(loanPayoffDate);
  const convertedFutureLoanPayoffDate =
    stringIsEmptyOrNullish(advancedPayoffAsOfDate) || isNTLPE1VLProduct
      ? DATA_UNAVAILABLE
      : formatDate(advancedPayoffAsOfDate);
  const convertedFutureLoanPayoffAmount =
    stringIsEmptyOrNullish(advancedPayoffAmount) || isNTLPE1VLProduct
      ? DATA_UNAVAILABLE
      : formatMoney(advancedPayoffAmount);

  if (
    (isPermPolicy({ agreementRequest }) || isHapPerm) &&
    !validTwoDayAdvancedPayoffAmount &&
    !validTwoDayAdvancedPayoffPrincipal &&
    !validTwoDayAdvancedPayoffInterest
  ) {
    return {
      maxLoanAmount: convertedMaxLoanAmount,
      interestRate: convertedLoanInterestRate,
      interestRateType: convertedLoanInterestType,
      loanInterestAmount: DATA_UNAVAILABLE,
      loanPrincipal: DATA_UNAVAILABLE,
      payoffBalance: DATA_UNAVAILABLE,
      maxLoanAsOfDate: convertedMaxLoanAsOfDate,
      loanPayoffAsOfDate: DATA_UNAVAILABLE,
      futureLoanPayoffDate: DATA_UNAVAILABLE,
      futureLoanPayoffAmount: DATA_UNAVAILABLE,
      forceShowUnavailable: true,
      showLoanPayoffSection,
    };
  }

  return {
    maxLoanAmount: convertedMaxLoanAmount,
    interestRate: convertedLoanInterestRate,
    interestRateType: convertedLoanInterestType,
    loanInterestAmount: convertedLoanInterestAmount,
    loanPrincipal: convertedLoanPrincipal,
    payoffBalance: convertedPayoffBalance,
    maxLoanAsOfDate: convertedMaxLoanAsOfDate,
    loanPayoffAsOfDate: convertedLoanPayoffAsOfDate,
    futureLoanPayoffDate: convertedFutureLoanPayoffDate,
    futureLoanPayoffAmount: convertedFutureLoanPayoffAmount,
    showLoanPayoffSection,
    isNTLProduct,
  };
};

export const checkNTLConditions = (agreementData) => {
  const {
    majorProductCode,
    minorProductCode,
    agreementKeyAdmin,
    agreementKeyPrefix,
  } = agreementData;

  const IS_PE1_VL =
    compareStrings("PE1", agreementKeyAdmin) &&
    compareStrings("VL", minorProductCode);
  const IS_LIFCOM_FL =
    compareStrings("LIFCOM", agreementKeyAdmin) &&
    compareStrings("FL", agreementKeyPrefix) &&
    compareArrayOfStrings(["VL", "UL"], minorProductCode);
  const IS_VL_OR_UL =
    compareStrings("LVRGVL", agreementKeyAdmin) &&
    compareArrayOfStrings(["VL", "UL"], minorProductCode);

  if (
    compareStrings(majorProductCode, "NTL") &&
    (IS_PE1_VL || IS_LIFCOM_FL || IS_VL_OR_UL)
  )
    return true;

  return false;
};

// eslint-disable-next-line import/prefer-default-export
export const useLoanProps = (props) => {
  const { agreementRequest } = props;
  const agreementData = agreementRequest?.data;
  const { agreementKeyAdmin } = agreementData;

  const billingRequest = useLoanData(props);
  const isHapPolicy = isCoverpathPolicy(agreementKeyAdmin);
  const isValidStatusAndReason = checkStatusAndReason(agreementData);

  const {
    maxLoanAmount,
    interestRate,
    interestRateType,
    payoffBalanceAsOfDate,
    loanInterestDueDate,
    loanInterestAmount,
    loanPrincipal,
    payoffBalance,
    maxLoanAsOfDate,
    loanPayoffAsOfDate,
    futureLoanPayoffDate,
    futureLoanPayoffAmount,
    showLoanPayoffSection,
    forceShowUnavailable,
    isNTLProduct,
  } = useFormatLoanData({ ...props, loanData: billingRequest.data });
  const transactionSupportedPolicies = useLDValue({
    flagName: ["transactionSupportedPolicies"],
  });
  const { redirectLink, isEligible, isTransactionAllowed } =
    verifyInitiateNewLoan({
      loanData: props.loanRequest.data,
      agreementData: props.agreementRequest.data,
      transactionSupportedPolicies,
    });
  const policyInForce = isPolicyInForce(props.agreementRequest.data.status);
  const loanIsEligible = policyInForce && isEligible;

  return {
    requests: {
      billingRequest,
    },
    data: {
      maxLoanAmount,
      interestRate,
      interestRateType,
      payoffBalanceAsOfDate,
      loanInterestDueDate,
      loanInterestAmount,
      loanPrincipal,
      payoffBalance,
      maxLoanAsOfDate,
      loanPayoffAsOfDate,
      futureLoanPayoffDate,
      futureLoanPayoffAmount,
      showLoanPayoffSection,
      forceShowUnavailable,
      isNTLProduct,
    },
    loanIsEligible,
    redirectLink,
    isTransactionAllowed,
    isHapPolicy,
    isValidStatusAndReason,
  };
};

export const useFormatAgreementData = (agreementData) => {
  const {
    data: { asOfDate, loanPayoffAmount },
  } = agreementData;

  const formattedLoanPayoffAmount =
    formatMoney(loanPayoffAmount) || "Unavailable";

  return {
    asOfDate: formatDate(asOfDate),
    loanPayoffAmount: formattedLoanPayoffAmount,
  };
};
