import React from "react";
import { compareStrings, isNonEmptyString } from "../../../../utils/string";
import { isDefined } from "../../../../utils/evaluate";
import IsTruthy from "../../../common/logic/isTruthy";
import IsFalsy from "../../../common/logic/isFalsy";
import { LabelWithInformationPopover } from "../../../common/popovers/informationPopover";

export const checkDescription = (props) => {
  const { description, category } = props;
  return isNonEmptyString(description) && compareStrings(category, "R");
};

const RiderModal = (props) => {
  const {
    description,
    category,
    insuredPeople,
    faceAmount,
    status,
    statusReason,
    effectiveDate,
    termDate,
    coveragePremiumAmount,
    foundWaiverOfPremiumAmount,
    waiverOfPremiumAmount,
    type,
    crossoverOptionDate,
    ltcCurrentMaxMonthlyBenefitAmount,
    ltcResidualDeathBenefitAmount,
    ltcBenefitPoolAmount,
    ltcDividendBenefitPoolAmount,
    ltcMaxMonthlyBenefitIncOptIndicator,
    ltcAdvanceBasePaidUpAddsIndicator,
    inflationProtectionOption,
    isHAPPolicy,
    isDisabilityPolcyResult,
    occupationCode,
    incomeAmount,
    annualPremiumAmount,
    benefitPeriodSickInjury,
    eliminationPeriodSickDisability,
    formattedBenefitPeriodInjury,
    waitingPeriodInjury,
    isBenefitPeriodSickandInjurySame,
    isDisabilityWaitPeriodSickandInjurySame,
    nextExerciseDateFIO,
    faceAmountFIO,
    hasFIORider,
    agreementKeyAdmin,
  } = props;

  return (
    <div className="modal-body">
      <IsTruthy
        value={category}
        validationFunction={(c) => compareStrings(c, "R")}
      >
        <IsTruthy value={isDefined(description)}>
          <div className="mm-spacing--minor">
            <p id="rider_description_lbl" className="h4">
              <strong>Description</strong>
            </p>
            <p id="rider_description_value">{description}</p>
          </div>
        </IsTruthy>
        <dl className="mm-key-value-pair">
          {insuredPeople.map((insuredPerson) => (
            <React.Fragment key={insuredPerson.fullName}>
              <dt id="rider_insured_name_lbl">Insured Name</dt>
              <dd id="rider_insured_name_value">
                <IsTruthy
                  value={insuredPerson.fullName}
                  validationFunction={isNonEmptyString}
                >
                  {insuredPerson.fullName}
                </IsTruthy>
                <IsFalsy
                  value={insuredPerson.fullName}
                  validationFunction={isNonEmptyString}
                >
                  Unavailable
                </IsFalsy>
              </dd>

              <dt id="rider_issue_age_lbl">Issue Age</dt>
              <dd id="rider_issue_age_value">
                <IsTruthy
                  value={insuredPerson.issueAge}
                  validationFunction={isDefined}
                >
                  {insuredPerson.issueAge}
                </IsTruthy>
                <IsFalsy
                  value={insuredPerson.issueAge}
                  validationFunction={isDefined}
                >
                  Unavailable
                </IsFalsy>
              </dd>
              <dt id="rider_underwriting_class_lbl">Underwriting Class</dt>
              <dd id="rider_underwriting_class_value">
                <IsTruthy
                  value={insuredPerson.tobaccoPremiumBasisDesc}
                  validationFunction={isNonEmptyString}
                >
                  {insuredPerson.tobaccoPremiumBasisDesc}
                </IsTruthy>
                <IsFalsy
                  value={insuredPerson.tobaccoPremiumBasisDesc}
                  validationFunction={isNonEmptyString}
                >
                  Unavailable
                </IsFalsy>
              </dd>
              <IsFalsy value={isHAPPolicy}>
                <IsTruthy value={isDisabilityPolcyResult}>
                  <dt id="table_occupation_code_lbl">Occupation Class</dt>
                  <dd id="table_occupation_code_value">{occupationCode}</dd>
                  <dt id="table_monthly_income_lbl">Monthly Benefit</dt>
                  <dd id="table_monthly_income_value">{incomeAmount}</dd>
                  <dt id="table_annual_premium_lbl">Annual Premium </dt>
                  <dd id="table_annual_premium_value">{annualPremiumAmount}</dd>
                </IsTruthy>
              </IsFalsy>
              <IsFalsy value={isDisabilityPolcyResult}>
                <dt id="table_rating_class_lbl">Table Rating</dt>
                <dd id="table_rating_class_value">
                  <IsTruthy
                    value={insuredPerson.permanentTableRatingCode1}
                    validationFunction={isDefined}
                  >
                    {insuredPerson.permanentTableRatingCode1}
                  </IsTruthy>
                  <IsFalsy
                    value={insuredPerson.permanentTableRatingCode1}
                    validationFunction={isDefined}
                  >
                    None
                  </IsFalsy>
                </dd>
              </IsFalsy>
              <dt />
              <dd />
            </React.Fragment>
          ))}
        </dl>
        <br />
        <dl className="mm-key-value-pair">
          <IsFalsy value={isDisabilityPolcyResult}>
            <dt id="rider_face_amount_lbl">Face Amount</dt>
            <dd id="rider_face_amount_value">{faceAmount}</dd>

            <dt id="rider_coverage_status_effective_date_lbl">
              Coverage Status
            </dt>
            <dd id="rider_coverage_status_effective_date_value">{status}</dd>
          </IsFalsy>
          <dt id="rider_effective_date_lbl">Effective Date</dt>
          <dd id="rider_effective_date_value">{effectiveDate}</dd>

          <dt id="rider_expiration_date_lbl">Expiration Date</dt>
          <dd id="rider_expiration_date_value">{termDate}</dd>
          <IsTruthy value={isDisabilityPolcyResult}>
            <IsTruthy value={isBenefitPeriodSickandInjurySame}>
              <dt id="rider_benefit_period_sick_injury_lbl">
                Benefit Period Sick/Injury
              </dt>
              <dd id="rider_benefit_period_sick_injury_value">
                {benefitPeriodSickInjury}
              </dd>
            </IsTruthy>
            <IsFalsy value={isBenefitPeriodSickandInjurySame}>
              <dt id="rider_benefit_period_sick_lbl">Benefit Period Sick</dt>
              <dd id="rider_benefit_period_sick_value">
                {benefitPeriodSickInjury}
              </dd>
              <dt id="rider_benefit_period_injury_lbl">
                Benefit Period Injury
              </dt>
              <dd id="rider_benefit_period_injury_value">
                {formattedBenefitPeriodInjury}
              </dd>
            </IsFalsy>
            <IsTruthy value={isDisabilityWaitPeriodSickandInjurySame}>
              <dt id="rider_waiting_period_sick_injury_lbl">
                Waiting Period Sick/Injury
              </dt>
              <dd id="rider_waiting_period_sick_injury_value">
                {eliminationPeriodSickDisability}
              </dd>
            </IsTruthy>
            <IsFalsy value={isDisabilityWaitPeriodSickandInjurySame}>
              <dt id="rider_waiting_period_sick_lbl">Waiting Period Sick </dt>
              <dd id="rider_waiting_period_sick_value">
                {eliminationPeriodSickDisability}
              </dd>
              <dt id="rider_waiting_period_injury_lbl">
                Waiting Period Injury
              </dt>
              <dd id="rider_waiting_period_injury_value">
                {waitingPeriodInjury}
              </dd>
            </IsFalsy>
            <IsTruthy
              value={agreementKeyAdmin}
              validationFunction={(t) =>
                hasFIORider && compareStrings(t, "EDS-DI")
              }
            >
              <dt id="next_yearly_base_fio_exercise_lbl">
                Next Yearly Base FIO Exercise Date
              </dt>
              <dd id="next_yearly_base_fio_exercise_value">
                {nextExerciseDateFIO}
              </dd>
              <dt id="remaining_base_fio_amount_lbl">
                Remaining Base FIO Amount
              </dt>
              <dd id="remaining_base_fio_amount_value">{faceAmountFIO}</dd>
            </IsTruthy>
            <IsTruthy
              value={agreementKeyAdmin}
              validationFunction={(t) =>
                hasFIORider && compareStrings(t, "MPR")
              }
            >
              <dt id="remaining_fio_benefit_pool_lbl">
                Remaining FIO Benefit Pool
              </dt>
              <dd id="remaining_fio_benefit_pool_value">Unavailable</dd>
            </IsTruthy>
          </IsTruthy>
          <IsFalsy value={isDisabilityPolcyResult}>
            <dt id="rider_annual_covergae_premium_lbl">
              Annual Coverage Premium
            </dt>
            <dd id="rider_annual_covergae_premium_value">
              {coveragePremiumAmount}
            </dd>
          </IsFalsy>
          <IsFalsy value={isDisabilityPolcyResult}>
            <IsTruthy value={foundWaiverOfPremiumAmount}>
              <dt id="rider_wp_premium_lbl">WP Premium</dt>
              <dd id="rider_wp_premium_value">{waiverOfPremiumAmount}</dd>
            </IsTruthy>
          </IsFalsy>
          <IsTruthy
            value={type}
            validationFunction={(t) => compareStrings(t, "LISR")}
          >
            <dt id="LISR_crossover_date_lbl">LISR Crossover Date</dt>
            <dd id="LISR_crossover_date_value">
              <IsTruthy
                value={crossoverOptionDate}
                validationFunction={isDefined}
              >
                {crossoverOptionDate}
              </IsTruthy>
              <IsFalsy
                value={crossoverOptionDate}
                validationFunction={isDefined}
              >
                Unavailable
              </IsFalsy>
            </dd>
          </IsTruthy>

          <IsTruthy
            value={statusReason}
            validationFunction={(s) => compareStrings(s, "Active")}
          >
            <IsTruthy
              value={type}
              validationFunction={(c) => compareStrings(c, "LTCIR")}
            >
              <dt id="ltcir_benefit_pool_lbl">
                <LabelWithInformationPopover
                  label="LTCIR Benefit Pool"
                  popoverId="ltcir_benefit_pool"
                  text="The LTCIR Benefit Pool is the Maximum Lifetime Rider Benefit (the total amount of the benefits to support claim payments) minus LTC Benefits paid."
                />
              </dt>
              <dd id="ltcir_benefit_pool_value">
                <IsTruthy
                  value={ltcBenefitPoolAmount}
                  validationFunction={isDefined}
                >
                  {ltcBenefitPoolAmount}
                </IsTruthy>
                <IsFalsy
                  value={ltcBenefitPoolAmount}
                  validationFunction={isDefined}
                >
                  Unavailable
                </IsFalsy>
              </dd>
              <dt id="current_max_monthly_benefit_lbl">
                <LabelWithInformationPopover
                  label="Current Maximum"
                  popoverId="current_max_monthly_benefit"
                  text="The Current Maximum Monthly Benefit is the maximum monthly benefit we will pay for covered services incurred in the current month."
                />
                <br />
                Monthly Benefit
              </dt>
              <dd id="current_max_monthly_benefit_value">
                <IsTruthy
                  value={ltcCurrentMaxMonthlyBenefitAmount}
                  validationFunction={isDefined}
                >
                  {ltcCurrentMaxMonthlyBenefitAmount}
                </IsTruthy>
                <IsFalsy
                  value={ltcCurrentMaxMonthlyBenefitAmount}
                  validationFunction={isDefined}
                >
                  Unavailable
                </IsFalsy>
              </dd>
            </IsTruthy>

            <IsTruthy
              value={type}
              validationFunction={(c) => compareStrings(c, "LTCR")}
            >
              <dt id="residual_death_benefit_lbl">
                <LabelWithInformationPopover
                  label="Residual Death Benefit"
                  popoverId="residual_death_benefit"
                  text="The Residual Death Benefit is the portion of the total Base face amount that will not be included in the Base LTC Benefit Pool. This amount is elected at issue and may not be changed post issue."
                />
              </dt>
              <dd id="residual_death_benefit_value">
                <IsTruthy
                  value={ltcResidualDeathBenefitAmount}
                  validationFunction={isDefined}
                >
                  {ltcResidualDeathBenefitAmount}
                </IsTruthy>
                <IsFalsy
                  value={ltcResidualDeathBenefitAmount}
                  validationFunction={isDefined}
                >
                  Unavailable
                </IsFalsy>
              </dd>
              <dt id="base_benefit_pool_lbl">
                <LabelWithInformationPopover
                  label="Base Benefit Pool"
                  popoverId="base_benefit_pool"
                  text="The Base Benefit Pool is the portion of the base face amount that is eligible to be paid as benefits for covered services."
                />
              </dt>
              <dd id="base_benefit_pool_value">
                <IsTruthy
                  value={ltcBenefitPoolAmount}
                  validationFunction={isDefined}
                >
                  {ltcBenefitPoolAmount}
                </IsTruthy>
                <IsFalsy
                  value={ltcBenefitPoolAmount}
                  validationFunction={isDefined}
                >
                  Unavailable
                </IsFalsy>
              </dd>
              <dt id="dividends_benefit_pool_lbl">
                <LabelWithInformationPopover
                  label="Dividends Benefit Pool"
                  popoverId="dividends_benefit_pool"
                  text="The Dividends Benefit Pool is the portion of death benefit provided by any Paid-Up Additions purchased by base policy dividends while the “Adding to LTC Dividends Benefit Pool” option is elected. This amount is also a benefit eligible to be paid for covered services."
                />
              </dt>
              <dd id="dividends_benefit_pool_value">
                <IsTruthy
                  value={ltcDividendBenefitPoolAmount}
                  validationFunction={isDefined}
                >
                  {ltcDividendBenefitPoolAmount}
                </IsTruthy>
                <IsFalsy
                  value={ltcDividendBenefitPoolAmount}
                  validationFunction={isDefined}
                >
                  Unavailable
                </IsFalsy>
              </dd>
              <dt id="current_max_monthly_benefit_lbl">
                <LabelWithInformationPopover
                  label="Current Maximum"
                  popoverId="current_max_monthly_benefit"
                  text="The Current Maximum Monthly Benefit is the maximum monthly benefit we will pay for covered services incurred in the current month."
                />
                <br />
                Monthly Benefit
              </dt>
              <dd id="current_max_monthly_benefit_value">
                <IsTruthy
                  value={ltcCurrentMaxMonthlyBenefitAmount}
                  validationFunction={isDefined}
                >
                  {ltcCurrentMaxMonthlyBenefitAmount}
                </IsTruthy>
                <IsFalsy
                  value={ltcCurrentMaxMonthlyBenefitAmount}
                  validationFunction={isDefined}
                >
                  Unavailable
                </IsFalsy>
              </dd>
              <dt id="mmb_increase_option_lbl">
                <LabelWithInformationPopover
                  label="MMB Increase Option"
                  popoverId="mmb_increase_option"
                  text="The LTC Maximum Monthly Benefit Increase Option, if elected, increases the Maximum Monthly Benefit annually by 4% for 25 years beginning at the insured’s attained age 61 anniversary or beginning on the first anniversary for issue ages 60+. The MMB Increase Option will not increase the LTC Benefit Pools.  It only increases the rate at which the pools may be used for reimbursement of qualified long term care expenses.  This option is only available for election at issue."
                />
              </dt>
              <dd id="mmb_increase_option_value">
                <IsTruthy
                  value={ltcMaxMonthlyBenefitIncOptIndicator}
                  validationFunction={isDefined}
                >
                  {ltcMaxMonthlyBenefitIncOptIndicator}
                </IsTruthy>
                <IsFalsy
                  value={ltcMaxMonthlyBenefitIncOptIndicator}
                  validationFunction={isDefined}
                >
                  Unavailable
                </IsFalsy>
              </dd>
              <dt id="adding_to_ltc_dividends_benefits_pool_lbl">
                <LabelWithInformationPopover
                  label="Adding to LTC Dividends"
                  popoverId="adding_to_ltc_dividends_benefits_pool"
                  text="This is an optional election that can be selected at issue or after. While elected any new base dividend paid up additions earned will be included in the Long Term Care Dividends Benefit Pool."
                />
                <br />
                Benefit Pool
              </dt>
              <dd id="adding_to_ltc_dividends_benefits_pool_value">
                <IsTruthy
                  value={ltcAdvanceBasePaidUpAddsIndicator}
                  validationFunction={isDefined}
                >
                  {ltcAdvanceBasePaidUpAddsIndicator}
                </IsTruthy>
                <IsFalsy
                  value={ltcAdvanceBasePaidUpAddsIndicator}
                  validationFunction={isDefined}
                >
                  Unavailable
                </IsFalsy>
              </dd>
            </IsTruthy>
          </IsTruthy>

          <IsTruthy
            value={type}
            validationFunction={(c) => compareStrings(c, "LTCIR")}
          >
            <dt id="inflation_protection_option_lbl">
              <LabelWithInformationPopover
                label="Inflation Protection"
                popoverId="inflation_protection_option"
                text="On each policy anniversary, if elected, Inflation Protection Option increases the current Maximum Monthly Benefit by 5% and increases the LTCIR Benefit Pool by 5% based on the remaining LTCIR Benefit Pool."
              />
              <br />
              Option
            </dt>
            <dd id="inflation_protection_option_value">
              <IsTruthy
                value={inflationProtectionOption}
                validationFunction={isDefined}
              >
                {inflationProtectionOption}
              </IsTruthy>
              <IsFalsy
                value={inflationProtectionOption}
                validationFunction={isDefined}
              >
                Unavailable
              </IsFalsy>
            </dd>
          </IsTruthy>
        </dl>
      </IsTruthy>
      <IsTruthy
        value={category}
        validationFunction={(c) => compareStrings(c, "B")}
      >
        <dl className="mm-key-value-pair">
          {insuredPeople.map((insuredPerson) => (
            <React.Fragment key={insuredPerson.fullName}>
              <dt id="rider_insured_name_lbl">Insured Name</dt>
              <dd id="rider_insured_name_value">
                <IsTruthy
                  value={insuredPerson.fullName}
                  validationFunction={isNonEmptyString}
                >
                  {insuredPerson.fullName}
                </IsTruthy>
                <IsFalsy
                  value={insuredPerson.fullName}
                  validationFunction={isNonEmptyString}
                >
                  Unavailable
                </IsFalsy>
              </dd>

              <dt id="rider_issue_age_lbl">Issue Age</dt>
              <dd id="rider_issue_age_value">
                <IsTruthy
                  value={insuredPerson.issueAge}
                  validationFunction={isDefined}
                >
                  {insuredPerson.issueAge}
                </IsTruthy>
                <IsFalsy
                  value={insuredPerson.issueAge}
                  validationFunction={isDefined}
                >
                  Unavailable
                </IsFalsy>
              </dd>

              <dt id="rider_underwriting_class_lbl">Underwriting Class</dt>
              <dd id="rider_underwriting_class_value">
                <IsTruthy
                  value={insuredPerson.tobaccoPremiumBasisDesc}
                  validationFunction={isNonEmptyString}
                >
                  {insuredPerson.tobaccoPremiumBasisDesc}
                </IsTruthy>
                <IsFalsy
                  value={insuredPerson.tobaccoPremiumBasisDesc}
                  validationFunction={isNonEmptyString}
                >
                  Unavailable
                </IsFalsy>
              </dd>
              <IsTruthy value={isDisabilityPolcyResult}>
                <dt id="table_occupation_code_lbl">Occupation Class</dt>
                <dd id="table_occupation_code_value">{occupationCode}</dd>
                <dt id="table_monthly_income_lbl">Monthly Benefit</dt>
                <dd id="table_monthly_income_value">{incomeAmount}</dd>
                <dt id="table_annual_premium_lbl">Annual Premium </dt>
                <dd id="table_annual_premium_value">{annualPremiumAmount}</dd>
              </IsTruthy>
              <IsFalsy value={isDisabilityPolcyResult}>
                <dt id="table_rating_class_lbl">Table Rating</dt>
                <dd id="table_rating_class_value">
                  <IsTruthy
                    value={insuredPerson.permanentTableRatingCode1}
                    validationFunction={isDefined}
                  >
                    {insuredPerson.permanentTableRatingCode1}
                  </IsTruthy>
                  <IsFalsy
                    value={insuredPerson.permanentTableRatingCode1}
                    validationFunction={isDefined}
                  >
                    None
                  </IsFalsy>
                </dd>
              </IsFalsy>
              <dt />
              <dd />
            </React.Fragment>
          ))}
        </dl>
        <br />
        <dl className="mm-key-value-pair">
          <IsFalsy value={isDisabilityPolcyResult}>
            <dt id="rider_face_amount_lbl">Face Amount</dt>
            <dd id="rider_face_amount_value">
              <IsTruthy
                value={faceAmount}
                validationFunction={isNonEmptyString}
              >
                {faceAmount}
              </IsTruthy>
              <IsFalsy value={faceAmount} validationFunction={isNonEmptyString}>
                N/A
              </IsFalsy>
            </dd>
            <dt id="rider_coverage_statuseffective_date_lbl">
              Coverage Status
            </dt>
            <dd id="rider_coverage_statuseffective_date_value">
              <IsTruthy value={status} validationFunction={isNonEmptyString}>
                {status}
              </IsTruthy>
              <IsFalsy value={status} validationFunction={isNonEmptyString}>
                Unavailable
              </IsFalsy>
            </dd>
          </IsFalsy>
          <dt id="rider_effective_date_lbl">Effective Date</dt>
          <dd id="rider_effective_date_value">
            <IsTruthy
              value={effectiveDate}
              validationFunction={isNonEmptyString}
            >
              {effectiveDate}
            </IsTruthy>
            <IsFalsy
              value={effectiveDate}
              validationFunction={isNonEmptyString}
            >
              -
            </IsFalsy>
          </dd>
          <dt id="rider_expiration_date_lbl">Expiration Date</dt>
          <dd id="rider_expiration_date_value">{termDate}</dd>
          <IsTruthy value={isDisabilityPolcyResult}>
            <IsTruthy value={isBenefitPeriodSickandInjurySame}>
              <dt id="rider_benefit_period_sick_injury_lbl">
                Benefit Period Sick/Injury
              </dt>
              <dd id="rider_benefit_period_sick_injury_value">
                {benefitPeriodSickInjury}
              </dd>
            </IsTruthy>
            <IsFalsy value={isBenefitPeriodSickandInjurySame}>
              <dt id="rider_benefit_period_sick_lbl">Benefit Period Sick</dt>
              <dd id="rider_benefit_period_sick_value">
                {benefitPeriodSickInjury}
              </dd>
              <dt id="rider_benefit_period_injury_lbl">
                Benefit Period Injury
              </dt>
              <dd id="rider_benefit_period_injury_value">
                {formattedBenefitPeriodInjury}
              </dd>
            </IsFalsy>
            <IsTruthy value={isDisabilityWaitPeriodSickandInjurySame}>
              <dt id="rider_waiting_period_sick_injury_lbl">
                Waiting Period Sick/Injury
              </dt>
              <dd id="rider_waiting_period_sick_injury_value">
                {eliminationPeriodSickDisability}
              </dd>
            </IsTruthy>
            <IsFalsy value={isDisabilityWaitPeriodSickandInjurySame}>
              <dt id="rider_waiting_period_sick_lbl">Waiting Period Sick </dt>
              <dd id="rider_waiting_period_sick_value">
                {eliminationPeriodSickDisability}
              </dd>
              <dt id="rider_waiting_period_injury_lbl">
                Waiting Period Injury
              </dt>
              <dd id="rider_waiting_period_injury_value">
                {waitingPeriodInjury}
              </dd>
            </IsFalsy>
          </IsTruthy>
          <IsFalsy value={isDisabilityPolcyResult}>
            <dt id="rider_annual_covergae_premium_lbl">
              Annual Coverage Premium
            </dt>
            <dd id="rider_annual_covergae_premium_value">
              {coveragePremiumAmount}
            </dd>
          </IsFalsy>
        </dl>
      </IsTruthy>
    </div>
  );
};

export default RiderModal;
