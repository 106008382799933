import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { ScrollSpy } from "react-blueprint-library";
// eslint-disable-next-line import/no-extraneous-dependencies
import MMLogo from "blueprint-css/images/mm-logo/MM_FullMark_294.svg";
import AnnuityContractInfoHeader from "../../sections/basicPolicyInfoHeader/annuity/contractInfoHeader";
import PolicyAndContactInfoDrawer from "../../../common/drawers/policyAndContactInfoDrawer";
import { useAnnuityContractDetailProps } from "./hooks";
import { setTitle } from "../../../../utils/dom";
import LoadingSection, {
  errorTypes,
  loadingTypes,
} from "../../../common/loading";
import PeopleOnContract from "../../sections/peopleOnContract";
import Riders from "../../sections/riders";
import Investments from "../../sections/investments";
import ContractDetails from "../../sections/contractDetails";
import FinancialHistorySection from "../../sections/financialHistory";
import ServiceHistory from "../../sections/serviceHistory";
import Documents from "../../sections/documents";
import { tealiumPageViewEvent } from "../../../../utils/tealium";
import "../../../../Print.scss";

const EnvisionPolicyDetails = (props) => {
  setTitle("ServiceNet - Contract Details");
  const {
    policyId,
    agreementKey,
    agreementRequest,
    billingRequest,
    producersRequest,
    fundTransferEligibilityRequest,
    serviceHistoryRequest,
    deathClaimEligibilityRequest,
    asOfDate,
    email,
    isDrawerOpened,
    openDrawer,
    closeDrawer,
    customer,
    printPDP,
    user,
    lineOfBusinessCode,
  } = useAnnuityContractDetailProps(props);
  const { unsupportedPolicy } = props;

  useEffect(() => {
    tealiumPageViewEvent({
      page: "Envision Policy Details",
      category: agreementKey,
      custId: email,
    });
  }, []);

  return (
    <>
      <div id="print_disclosure" className="print-only">
        <img
          id="mm_footer_logo_img"
          className="mm-logo"
          alt="MassMutual Logo"
          width="150"
          src={MMLogo}
        />{" "}
        <p>
          The information shown is as of {asOfDate} and could differ based on
          factors such as the last premium/purchase payment received, the
          policy/contract status, and any policy/contract changes that may have
          been submitted but not yet processed since that date. This report does
          not replace the official statements that you receive about this
          policy/contract. You should rely on your official statements as the
          most accurate source of information.
        </p>
      </div>

      <AnnuityContractInfoHeader
        policyId={policyId}
        agreementRequest={agreementRequest}
        producersRequest={producersRequest}
        openDrawer={openDrawer}
        unsupportedPolicy={unsupportedPolicy}
        annuityRequest
        billingRequest={billingRequest}
        fundTransferEligibilityRequest={fundTransferEligibilityRequest}
        serviceHistoryRequest={serviceHistoryRequest}
        deathClaimEligibilityRequest={deathClaimEligibilityRequest}
        user={user}
        lineOfBusinessCode={lineOfBusinessCode}
      />
      <LoadingSection
        request={agreementRequest}
        loadingType={loadingTypes.PARAGRAPH}
        errorType={errorTypes.NONE}
      >
        <p id="as_of_date_value" className="copy-md mm-spacing">
          As of {asOfDate}
        </p>
        <hr className="mm-spacing" />
        <div id="printButton" className="justify-content-end">
          <button
            onClick={() => printPDP()}
            data-bp-link="default"
            className="btn btn-inline hide-print"
            aria-label="Print"
            type="button"
          >
            <span className="icon-print" />
            <span>Print</span>
          </button>
        </div>
        <ScrollSpy
          navClass="col-md-3 sn-scrollspy_navigation"
          contentClass="col-md-9 sn-scrollspy_content"
          behavior="smooth"
          placement="auth"
        >
          <div name="Contract Details" data-id="contractDetails">
            <ContractDetails
              id="contractDetails"
              agreementRequest={agreementRequest}
            />
          </div>
          <div name="Investments" data-id="investments">
            <Investments
              id="investments"
              agreementKey={agreementKey}
              agreementRequest={agreementRequest}
              producersRequest={producersRequest}
              fundTransferEligibilityRequest={fundTransferEligibilityRequest}
              user={user}
            />
          </div>
          <div name="Contract Features" data-id="contractFeatures">
            <Riders id="contractFeatures" agreementRequest={agreementRequest} />
          </div>
          <div name="People On Contract" data-id="peopleOnContract">
            <PeopleOnContract
              id="peopleOnContract"
              agreementKey={agreementKey}
              agreementRequest={agreementRequest}
              producerData={producersRequest?.data}
              policyId={policyId}
            />
          </div>
          <div name="Financial History" data-id="financialHistory">
            <FinancialHistorySection
              id="financial-History"
              agreementKey={agreementKey}
              agreementRequest={agreementRequest}
            />
          </div>
          <div name="Service History" data-id="serviceHistory">
            <ServiceHistory
              id="serviceHistory"
              agreementKey={agreementKey}
              agreementRequest={agreementRequest}
              serviceRequest={serviceHistoryRequest}
              policyId={policyId}
              email={email}
            />
          </div>
          <div name="Documents" data-id="documents">
            <Documents
              id="documents"
              agreementKey={agreementKey}
              agreementRequest={agreementRequest}
              policyId={policyId}
              email={email}
            />
          </div>
        </ScrollSpy>
      </LoadingSection>
      <Modal
        show={isDrawerOpened}
        onHide={closeDrawer}
        className="modal--drawer"
      >
        <Modal.Header>
          <button
            onClick={closeDrawer}
            type="button"
            className="close"
            aria-label="Close"
          />
        </Modal.Header>
        <Modal.Body id="sideDrawer">
          <PolicyAndContactInfoDrawer
            policyId={policyId}
            agreementRequest={agreementRequest}
            producerData={producersRequest?.data}
            producerRequest={producersRequest}
            customer={customer}
            billingRequest={billingRequest}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default React.memo(EnvisionPolicyDetails);
