import React from "react";
import { fireTealiumEvent } from "../../../../utils/tealium";
import { useUserState } from "../../../../contexts/user";
import IsFalsy from "../../logic/isFalsy";
import IsTruthy from "../../logic/isTruthy";
import { isSE2Policy } from "../../../../utils/policies";
import { compareStrings } from "../../../../utils/string";

const ownerButtonCell = (props) => {
  const {
    row,
    rowIndex,
    column: { accessor },
    customFunctions,
  } = props;

  const ownersList = row[accessor];
  const isSE2Product = isSE2Policy(row);
  const agreementAdminNotServiced = compareStrings(
    row.agreementKeyAdmin,
    "RPS"
  );

  return ownersList.map((owner) => {
    const ownerDrawer = {
      ...row,
      fullName: owner.name.fullName,
      memberId: owner.memberId,
    };
    const {
      user: { email },
    } = useUserState();

    const openDrawer = (drawer) => () => {
      customFunctions.setSelectedRow(drawer);
      fireTealiumEvent({
        action: "Search Result Table Owner link clicked",
        category: "Search Result Table Owner link",
        value: owner?.name.fullName,
        custId: email,
      });
    };
    return (
      <span className="SN-owner-cell-button" key={`${owner?.name.fullName}`}>
        <IsTruthy value={isSE2Product || agreementAdminNotServiced}>
          {" "}
          <span
            id={`${owner.fullName}_${rowIndex}_value`}
            dangerouslySetInnerHTML={{ __html: owner?.name.fullName }}
          />
        </IsTruthy>
        <IsFalsy value={isSE2Product || agreementAdminNotServiced}>
          <button
            type="button"
            id={`${owner?.name.fullName}_${rowIndex}_btn`}
            onClick={openDrawer(ownerDrawer)}
            className="btn btn-inline"
          >
            <span
              id={`${owner.fullName}_${rowIndex}_value`}
              dangerouslySetInnerHTML={{ __html: owner?.name.fullName }}
            />
          </button>
        </IsFalsy>
      </span>
    );
  });
};

export default React.memo(ownerButtonCell);
