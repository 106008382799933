import { isCoverpathPolicy } from "../../utils/policies";
import { compareStrings } from "../../utils/string";

export const isAgreementDataEligibleforChangeAddress = ({
  agreementData,
  isTransactionAllowed,
}) => {
  let isRestrictedAgreementKeyAdmin = false;

  const { lineOfBusinessCode, agreementKeyAdmin, status } = agreementData;

  if (compareStrings(lineOfBusinessCode, "DI")) {
    isRestrictedAgreementKeyAdmin = isCoverpathPolicy(agreementKeyAdmin);
  }

  const agreementDataEligibilityResult =
    compareStrings(status, "IF") &&
    !isRestrictedAgreementKeyAdmin &&
    isTransactionAllowed;

  return agreementDataEligibilityResult;
};

export const isBillingDataEligibleForChangeAddress = ({
  billingData,
  billType,
}) => {
  const { paymentPreference } = billingData;
  return (
    !compareStrings(paymentPreference, "LST") &&
    !compareStrings(billType, "LST")
  );
};
