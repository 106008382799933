import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { setTitle } from "../../../../utils/dom";
import { useRequestInformationProps } from "./hooks";
import PolicyInformation from "./policyInformation";
import SelectInformationTypes from "./selectInformationTypes";
import Form from "../../../common/forms/form";
import { ModalProvider } from "../../../../contexts/modal";
import FormButtons from "./formButtons";
import IsTruthy from "../../../common/logic/isTruthy";
import ConfirmationPage from "./confirmationPage";
import IsFalsy from "../../../common/logic/isFalsy";
import FiveHundredError from "../../../common/errors/500";
import Header from "../../../layout/header";
import Footer from "../../../layout/footer";
import { TRANSACTIONS_DISABLED_MESSAGE } from "../../../../constants/ui";
import FiveHundredOneError from "../../../common/errors/501";
import FourHundredFourError from "../../../common/errors/404";
import LoadingSection, {
  errorTypes,
  loadingTypes,
} from "../../../common/loading";
import { tealiumPageViewEvent } from "../../../../utils/tealium";
import LdBannerContent from "../../../common/molecules/ldBannerContent";
import RequestorContact from "../../../common/requestorContact";
import { requestorContactLayout } from "../../../common/requestorContact/hooks";
import SelectRequestInformationAsOfDate from "./selectRequestInformationAsOfDate";
import ErrorPage from "../../../common/errors/errorPage";

const RequestInformation = () => {
  setTitle("ServiceNet - Request Information");
  const { agreementKey } = useParams();
  const {
    owners,
    insured,
    productTypeName,
    policyId,
    fields,
    agreementRequest,
    confirmationPage,
    setConfirmationPage,
    confirmationProps,
    setConfirmationProps,
    showErrorPage,
    setShowErrorPage,
    transactionId,
    setTransactionId,
    producersData,
    servicingAgent,
    shouldDisableSubmit,
    IS_UNAUTHORIZED,
    email,
    unsupportedPolicy,
    setError,
    isEligible,
    isTransactionAllowed,
    isPolicyLoaded,
    isMmsdOnlyUser,
    isPolicyNotFound,
  } = useRequestInformationProps(agreementKey);

  useEffect(() => {
    tealiumPageViewEvent({
      page: "Request Information",
      category: agreementKey,
      custId: email,
    });
  }, []);

  if (IS_UNAUTHORIZED) {
    return (
      <>
        {" "}
        <Header />
        <div className="container">
          <LdBannerContent />
          <FiveHundredOneError />
        </div>
        <Footer />
      </>
    );
  }

  if (isPolicyNotFound) {
    return (
      <>
        <Header />
        <div className="container">
          <LdBannerContent />
          <FourHundredFourError />
        </div>
        <Footer />
      </>
    );
  }

  return (
    <LoadingSection
      request={agreementRequest}
      loadingType={loadingTypes.SPINNER}
      errorType={errorTypes._500}
      unsupportedPolicy={unsupportedPolicy}
      addHeaderFooterToError
    >
      <IsTruthy value={isPolicyLoaded}>
        <IsFalsy value={isTransactionAllowed}>
          <IsTruthy value={isMmsdOnlyUser}>
            <Header />
            <div className="container">
              <LdBannerContent />
              <ErrorPage
                errorTitle="Our records indicate you do not have access to this application."
                agreementKey={agreementRequest?.agreementKey}
                errorPageFor="mmsdOnly"
                imageClass="illustration-error_500"
              />
            </div>
            <Footer />
          </IsTruthy>
          <IsFalsy value={isMmsdOnlyUser}>
            <Header />
            <div className="container">
              <LdBannerContent />
              <FourHundredFourError />
            </div>
            <Footer />
          </IsFalsy>
        </IsFalsy>
        <IsTruthy value={isTransactionAllowed}>
          <IsFalsy value={isEligible}>
            <Header />
            <div className="container">
              <LdBannerContent />
              <FourHundredFourError />
            </div>
            <Footer />
          </IsFalsy>
          <IsTruthy value={isEligible}>
            <IsTruthy value={showErrorPage}>
              <Header />
              <div className="container">
                <LdBannerContent />
                <FiveHundredError
                  turnOffReload
                  transactionId={transactionId}
                  agreementKey={agreementKey}
                  title="There was an error processing your request; please try again later."
                />
                <Footer />
              </div>
            </IsTruthy>
            <IsFalsy value={showErrorPage}>
              <IsFalsy value={confirmationPage}>
                <div className="container">
                  <nav
                    role="navigation"
                    className="mm-navbar--transaction show-print"
                  >
                    <div className="mm-logo" />
                  </nav>
                  <h1 id="service_history_header_lbl">Request Information</h1>
                  <LdBannerContent />
                  <IsTruthy value={shouldDisableSubmit}>
                    <div className="alert alert-danger" role="alert">
                      {TRANSACTIONS_DISABLED_MESSAGE}
                    </div>
                  </IsTruthy>
                  <p className="mm-sub-copy mm-spacing">* = Required Field</p>
                  <PolicyInformation
                    owners={owners}
                    insured={insured}
                    productTypeName={productTypeName}
                    policyId={policyId}
                  />
                  <Form fields={fields}>
                    <SelectInformationTypes
                      agreementKey={agreementKey}
                      agreementRequest={agreementRequest}
                    />
                    <br />
                    <SelectRequestInformationAsOfDate
                      agreementRequest={agreementRequest}
                    />
                    <hr />
                    <RequestorContact layout={requestorContactLayout.SECTION} />
                    <br />
                    <ModalProvider>
                      <FormButtons
                        servicingAgent={servicingAgent}
                        agreementKey={agreementKey}
                        agreementRequest={agreementRequest}
                        insured={insured}
                        owners={owners}
                        setConfirmationPage={setConfirmationPage}
                        setConfirmationProps={setConfirmationProps}
                        setShowErrorPage={setShowErrorPage}
                        setTransactionId={setTransactionId}
                        shouldDisableSubmit={shouldDisableSubmit}
                        producersData={producersData}
                        setError={setError}
                      />
                    </ModalProvider>
                  </Form>
                </div>
              </IsFalsy>
              <IsTruthy value={confirmationPage}>
                <div className="container">
                  <nav
                    role="navigation"
                    className="mm-navbar--transaction show-print"
                  >
                    <div className="mm-logo" />
                  </nav>
                  <ConfirmationPage
                    {...confirmationProps}
                    agreementKey={agreementKey}
                    policyId={policyId}
                  />
                </div>
              </IsTruthy>
            </IsFalsy>
          </IsTruthy>
        </IsTruthy>
      </IsTruthy>
    </LoadingSection>
  );
};
export default React.memo(RequestInformation);
