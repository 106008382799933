import React, { useEffect } from "react";
import { setTitle } from "../../../../utils/dom";
import { usePaymentOptionsProps } from "./hooks";
import { tealiumPageViewEvent } from "../../../../utils/tealium";

const PaymentOptions = () => {
  setTitle("ServiceNet - Policy Payment Options");
  const { agreementKey, email } = usePaymentOptionsProps();

  useEffect(() => {
    tealiumPageViewEvent({
      page: "Loan Payoff As Of Date",
      category: agreementKey,
      custId: email,
    });
  }, []);

  return <h1>Payment Options for Policy {agreementKey}</h1>;
};
export default React.memo(PaymentOptions);
