import { isNotArray } from "./evaluate";

export const getFirstItem = (array, defaultReturn = "") => {
  if (isNotArray(array)) return defaultReturn;
  const HAS_ITEMS = array.length > 0;
  return HAS_ITEMS ? array[0] : defaultReturn;
};

export const range = (start, end) => {
  /* generate a range : [start, start+1, ..., end-1, end] */
  const len = end - start + 1;
  const a = new Array(len);
  for (let i = 0; i < len; i += 1) a[i] = start + i;
  return a;
};

export const groupBy = (array, key) => {
  if (isNotArray(array)) {
    return {};
  }
  return array.reduce((group, item) => {
    const groupKey = item[key];
    // eslint-disable-next-line no-param-reassign
    group[groupKey] = group[groupKey] || [];
    group[groupKey].push(item);
    return group;
  }, {});
};

export const sortAlphabeticallyByKey = (array, key) => {
  if (isNotArray(array)) return [];
  return array.sort((a, b) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  });
};
