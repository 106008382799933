import React from "react";
import { formatAlirOpmRiderSectionData } from "./riderSectionHelper";

const AlirOpmRiderSection = (props) => {
  const { riderName, annualCoveragePremium } =
    formatAlirOpmRiderSectionData(props);
  return (
    <>
      <div className="row">
        <div className="col-12 col-md-6">
          <dl className="mm-key-value-pair">
            <dt id="rider_name_alirOpm_premium_lbl">Rider Name</dt>
            <dd id="rider_name_alirOpm_premium_value">{riderName}</dd>
          </dl>
        </div>
        <div className="col-12 col-md-6">
          <dl className="mm-key-value-pair">
            <dt id="annual_coverage_alirOpm_premium_lbl">
              Annual Coverage Premium
            </dt>
            <dd id="annual_coverage_alirOpm_premium_value">
              {annualCoveragePremium}
            </dd>
          </dl>
        </div>
      </div>
      <hr />
    </>
  );
};

export default React.memo(AlirOpmRiderSection);
