import React from "react";
import Table from "../../../common/table";
import { useCoverageAndRiderTable } from "./hooks";
import { NO_COVERAGE_ALERT_TEXT } from "../../../../constants/ui";
import IsTruthy from "../../../common/logic/isTruthy";
import IsFalsy from "../../../common/logic/isFalsy";

const CoverageAndRiders = (props) => {
  const { id } = props;
  const {
    table: { columns, rows },
    functions: { showRiderModal },
    displayPolicyNoRidersMesssage,
    formattedContactUsNumber,
    formattedContactUsTime,
  } = useCoverageAndRiderTable(props);

  return (
    <div className="mm-spacing">
      <h2 aria-label="Coverage/Riders" id={id}>
        Coverage/Riders
      </h2>
      <div className="mm-spacing--minor">
        <IsTruthy value={displayPolicyNoRidersMesssage}>
          <div className="alert alert-info mm-spacing" role="alert">
            {NO_COVERAGE_ALERT_TEXT}
            <a href={`tel:+${formattedContactUsNumber}`}>
              {formattedContactUsNumber}
            </a>
            , {formattedContactUsTime}
          </div>
        </IsTruthy>
        <IsFalsy value={displayPolicyNoRidersMesssage}>
          <Table
            id="Coverage/Riders"
            tableClass="mm-spacing--none"
            columns={columns}
            rows={rows}
            showPagination={false}
            customFunctions={{ showRiderModal }}
          />
        </IsFalsy>
      </div>
    </div>
  );
};

export default React.memo(CoverageAndRiders);
