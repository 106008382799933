export const processAgentUnseenNotificationsCountData = (props) => {
  const apiData = props?.response?.data;
  const unseenNotificationsCount = apiData?.count || 0;
  const unseenNotificationsAsOfDate = apiData?.checkedAt || "";

  return {
    unseenNotificationsCount,
    unseenNotificationsAsOfDate,
  };
};
