import React from "react";

const SubmitOption = (props) => {
  const { option, heading, description } = props;
  return (
    <div className="col-md-3">
      <picture>
        <span role="img" className={`illustration-${option}`} title={option} />
      </picture>
      <h4>{heading}</h4>
      <p>{description}</p>
    </div>
  );
};
export default SubmitOption;
