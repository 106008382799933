/* eslint-disable import/prefer-default-export */
import React from "react";

export const Error = (props) => {
  const {
    formField: { id, errors, postpone, cssClass = "invalid-feedback" },
  } = props;
  if (postpone) {
    return null;
  }
  return errors.map((error, index) => (
    <div id={`${id}-${index}-errors`} className={cssClass} key={error.message}>
      {error.message}
    </div>
  ));
};

export default Error;
