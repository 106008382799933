import React from "react";
import IsTruthy from "../../../../common/logic/isTruthy";
import IsFalsy from "../../../../common/logic/isFalsy";
import {
  NO_LOAN_ALERT_TEXT,
  NO_ACTIVE_LOAN_ALERT_TEXT,
  DATA_UNAVAILABLE,
} from "../../../../../constants/ui";
import { LabelWithInformationPopover } from "../../../../common/popovers/informationPopover";

const annuityProductPerformanceRedirectUrl =
  "https://fieldnet.massmutual.com/fldnet/products/annuities/productperformance.html";

const annuityFormsCenterRedirectUrl =
  "https://fieldnet.massmutual.com/riappexpress/raxfn?GUID=&LAD=A";

const FlexExtraLoanDisplay = (props) => {
  const {
    flexExtraData: {
      formattedMaxLoanAmount,
      formattedContactUsNumber,
      formattedContactUsTime,
      displayFlexExtraLoanSectionUnavailableAlert,
      displayNoActiveLoansAlert,
      formattedMaxLoanAsOfDate,
      formattedRemainingBalance,
      formattedCurrentInterestRate,
      formattedPayoffAmount,
      loanStatus,
      maxLoanAmountPopover: {
        displaymaxLoanAmountPopover,
        maxLoanAmountPopoverTexts,
      },
      isActiveLoanOnContract,
    },
  } = props;

  const maxLoanAmountPopoverContent = maxLoanAmountPopoverTexts.map(
    (text, index) => {
      const popOverContentId = `popOverContentId-${index}`;
      return `<li key=${popOverContentId}>${text}</li>`;
    }
  );
  let maxLoanAmountTextList = "";
  maxLoanAmountPopoverContent.forEach((text) => {
    maxLoanAmountTextList += text;
  });

  const contactUsNumberLink = `<a href="tel:+${formattedContactUsNumber}">
  ${formattedContactUsNumber}</a>`;

  return (
    <>
      <IsTruthy value={displayFlexExtraLoanSectionUnavailableAlert}>
        {" "}
        <div
          className="alert alert-info mm-spacing"
          role="alert"
          id="no_loan_alert"
        >
          {NO_LOAN_ALERT_TEXT}
          <a href={`tel:+${formattedContactUsNumber}`}>
            {formattedContactUsNumber}
          </a>
          , {formattedContactUsTime}.
        </div>
      </IsTruthy>
      <IsFalsy value={displayFlexExtraLoanSectionUnavailableAlert}>
        <div className="mm-spacing--minor">
          <h3 id="loan_availability_lbl">Loan Availability</h3>
          <hr />
          <div className="row mm-spacing--minor">
            <div className="col-12 col-md-6">
              <dl className="mm-key-value-pair">
                <dt id="loan_max_loan_lbl">Max Loan Amount</dt>
                <dd id="loan_max_loan_value" data-testid="loan_max_loan_value">
                  <IsTruthy value={isActiveLoanOnContract}>
                    {" "}
                    <LabelWithInformationPopover
                      label={DATA_UNAVAILABLE}
                      popoverId="policy_max_loan_amount_popover"
                      text={`<div className="row">
                          <div className="col-sm">
                            <ul className="list-unstyled">
                              ${" "}
                              The Max Loan Amount is unavailable because there
                              is already an active loan for this contract. If
                              you have any questions please contact our Service
                              Center at ${contactUsNumberLink},
                                ${formattedContactUsTime}.
                            </ul>
                          </div>
                        </div>`}
                    />
                  </IsTruthy>
                  <IsFalsy value={isActiveLoanOnContract}>
                    <IsFalsy value={displaymaxLoanAmountPopover}>
                      {formattedMaxLoanAmount}
                    </IsFalsy>
                    <IsTruthy value={displaymaxLoanAmountPopover}>
                      <LabelWithInformationPopover
                        label={DATA_UNAVAILABLE}
                        popoverId="policy_max_loan_amount_popover"
                        text={`
                      <div class="row">
                        <div class="col-sm">
                          <ul class="list-unstyled">
                            ${maxLoanAmountTextList}
                          </ul>
                        </div>
                      </div>
                    `}
                      />
                    </IsTruthy>
                  </IsFalsy>
                </dd>
              </dl>
            </div>
            <div className="col-12 col-md-6">
              <dl className="mm-key-value-pair">
                <dt id="loan_current_loan_interest_rate_lbl">
                  Current Loan Interest Rate
                </dt>
                <dd id="loan_current_loan_interest_rate_link">
                  <a
                    id="link_current_interest_rates_initiate"
                    href={annuityProductPerformanceRedirectUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    See Interest Rates
                  </a>
                </dd>
              </dl>
            </div>
          </div>
          <hr />

          <IsTruthy value={displayNoActiveLoansAlert}>
            {" "}
            <div className="alert alert-info mm-spacing" role="alert">
              {NO_ACTIVE_LOAN_ALERT_TEXT}
            </div>
          </IsTruthy>
          <IsFalsy value={displayNoActiveLoansAlert}>
            <h3 id="loan_payoff_information_lbl">Loan Payoff Information</h3>
            <p id="as_of_date_value_loan_payoff" className="copy-lg">
              As of {formattedMaxLoanAsOfDate}
            </p>
            <hr />
            <div className="row mm-spacing--minor">
              <div className="col-12 col-md-6">
                <dl className="mm-key-value-pair">
                  <dt id="loan_principal_lbl">Loan Principal</dt>
                  <dd
                    id="loan_principal_value"
                    data-testid="loan_principal_value"
                  >
                    {formattedRemainingBalance}
                  </dd>
                  <dt id="loan_interest_rate_lbl">Loan Interest Rate</dt>
                  <dd
                    id="loan_interest_rate_value"
                    data-testid="loan_interest_rate_value"
                  >
                    {formattedCurrentInterestRate}
                  </dd>
                </dl>
              </div>
              <div className="col-12 col-md-6">
                <dl className="mm-key-value-pair">
                  <dt id="loan_payoff_amount_lbl">Loan Payoff Amount</dt>
                  <dd
                    id="loan_payoff_amount_value"
                    data-testid="loan_payoff_amount_value"
                  >
                    {formattedPayoffAmount}
                  </dd>
                  <dt id="loan_status_lbl">Loan Status</dt>
                  <dd id="loan_status_value" data-testid="loan_status_value">
                    {loanStatus}
                  </dd>
                </dl>
              </div>
            </div>
            <hr />
          </IsFalsy>
        </div>
      </IsFalsy>{" "}
      <p>
        To initiate a loan go to the{" "}
        <a
          id="link_annuity_loan_initiate"
          href={annuityFormsCenterRedirectUrl}
          target="_blank"
          rel="noreferrer"
        >
          Annuity Forms Center
        </a>{" "}
        to download an application.
      </p>
    </>
  );
};

export default FlexExtraLoanDisplay;
