import React from "react";
import {
  LIFE_DI_GRACE_PERIOD_TOOLTIP_TEXT,
  LIFE_GRACE_PERIOD_TOOLTIP_TEXT,
} from "../../../../../constants/ui";

export const GracePeriodModalBody = (props) => {
  const { lineOfBusinessCode } = props;

  switch (lineOfBusinessCode) {
    case "LIFE": {
      return (
        <div className="modal-body" id="days_32_to_62_modal">
          {LIFE_DI_GRACE_PERIOD_TOOLTIP_TEXT}
          <br />
          <br />
          {LIFE_GRACE_PERIOD_TOOLTIP_TEXT}
        </div>
      );
    }
    case "DI": {
      return (
        <div className="modal-body" id="days_32_to_62_modal">
          {LIFE_DI_GRACE_PERIOD_TOOLTIP_TEXT}
        </div>
      );
    }
    default: {
      return <> </>;
    }
  }
};
