import React from "react";
import { usePaymentInformationProps } from "./hooks";
import IsTruthy from "../../../common/logic/isTruthy";

const PayoutInformation = (props) => {
  const {
    isRetireEase,
    benefitAmount,
    paymentMode,
    nextPaymentDate,
    maturityDate,
    remainingInstallment,
    isRefundAnnuityOption,
    remainingInstallmentLabel,
  } = usePaymentInformationProps(props);

  return (
    <IsTruthy value={isRetireEase}>
      <h3>Payout Information</h3>
      <hr />
      <div className="row mm-spacing--minor">
        <div className="col-12 col-md-6">
          <dl className="mm-key-value-pair">
            <dt id="annuity_payout_lbl">Annuity Payout Amount</dt>
            <dd id="benefit_amount_value">{benefitAmount}</dd>

            <dt id="payment_mode_lbl">Payment Mode</dt>
            <dd id="payment_mode_value">{paymentMode}</dd>
            <IsTruthy value={isRefundAnnuityOption}>
              <dt id="remaining_installment_lbl">
                {" "}
                {remainingInstallmentLabel}
              </dt>
              <dd id="remaining_installment_value">{remainingInstallment}</dd>
            </IsTruthy>
          </dl>
        </div>

        <div className="col-12 col-md-6">
          <dl className="mm-key-value-pair">
            <dt id="next_payment_date_lbl">Date of Next Payment</dt>
            <dd id="next_payment_date_value">{nextPaymentDate}</dd>
            <dt id="period_certain_end_date_lbl">Period Certain End Date</dt>
            <dd id="period_certain_end_date_value">{maturityDate}</dd>
          </dl>
        </div>
      </div>
      <hr />
    </IsTruthy>
  );
};

export default React.memo(PayoutInformation);
