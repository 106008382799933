import {
  getPaymentDates,
  getOptionArrangementType,
} from "../rules/processBillingInformation";
import { isEmptyObject } from "../../utils/evaluate";
import { compareStrings } from "../../utils/string";

// eslint-disable-next-line import/prefer-default-export
export const processBillingInformation = (props) => {
  const apiData = props?.response?.data;
  const { issueDate = "", jurisdictionState = "" } =
    props?.paramsForSelectors || {};

  const agreement = apiData?.billingDetailsResponse?.agreements?.[0] || {
    billing: { extended: { dividends: {}, riders: [] } },
  };

  const messages = apiData?.messages || [];
  const lockboxAddressRemovedMessage = messages.filter(
    (message) =>
      compareStrings(message.msgType, "LOCKBOX ADDRESSES REMOVED") &&
      compareStrings(
        message.msgDescription,
        "Lockbox addresses were determined to not be available. Reason: PayByMail addresses are not available for this agreement."
      )
  );
  const isLockboxAddressRemoved = !isEmptyObject(lockboxAddressRemovedMessage);
  const dividends = agreement?.billing?.extended?.dividends || {};
  const extendedRiders = agreement?.billing?.extended?.riders || [];
  const lineOfBusinessCode = agreement?.lineOfBusiness;
  const {
    billing: {
      autoPay = {},
      isOnAutoPay = "",
      isOnAutoPayLoan = "",
      isOnAutoPayLoanInterest = "",
      billDeliveryPreferenceDesc: billDeliveryPreference = "Unavailable",
      billedPremium = "",
      billingMode = "",
      billingModeDesc: billFrequency = "",
      mpoApoNetDueAmount,
      optionArrangement = "None",
      paidToDate: paidToDateString = "",
      paymentArrangement = "None",
      paymentPreferenceDesc = "Unavailable",
      paymentPreference = "Unavailable",
      sdoMpoApoCd = "None",
      sdoMpoApoPaymentForm = "None",
      invoiceFranchiseNumber = "-",
      apmNumber = "-",
      loan = {},
      payments = [],
      modalPremiums = [],
      paperlessPreferences = {},
      premiumBills = [],
    },
    siprRiderIndicator = "",
    lisrRiderIndicator = "",
    alirRiderIndicator = "",
    palirRiderIndicator = "",
    netPremiumAmountToDate = "",
    majorProductCode = "None",
    lockboxAddresses = [],
    riders = [],
  } = agreement;

  const {
    pacAccountNumber = "-",
    amount = "-",
    draftDay = "-",
    payment = {},
    statusCode = "",
    availableFrequencies = [],
    nextScheduledPaymentDate = "",
  } = autoPay;

  const {
    accountNumber = "-",
    accountType = "-",
    bankAccountHolder = "-",
    bankName = "-",
    totalAmount = "-",
    routingNumber = "-",
  } = payment;

  const {
    optionArrangementTypeOption = "None",
    optionArrangementTypeElection = "None",
  } = dividends;

  const {
    paidToDate,
    gracePeriod,
    additionalPaymentPeriod,
    hideAdditionaPaymentColumn,
  } = getPaymentDates({
    paidToDateString,
    issueDate,
    jurisdictionState,
    billingMode,
    lineOfBusinessCode,
  });
  const optionArrangementType = getOptionArrangementType({
    sdoMpoApoCd,
    sdoMpoApoPaymentForm,
    optionArrangementTypeOption,
    optionArrangementTypeElection,
  });

  return {
    isOnAutoPay,
    isOnAutoPayLoan,
    isOnAutoPayLoanInterest,
    siprRiderIndicator,
    lisrRiderIndicator,
    alirRiderIndicator,
    palirRiderIndicator,
    netPremiumAmountToDate,
    invoiceFranchiseNumber,
    apmNumber,
    accountNumber,
    accountType,
    amount,
    bankAccountHolder,
    bankName,
    billDeliveryPreference,
    billedPremium,
    billFrequency,
    billingMode,
    draftDay,
    hideAdditionaPaymentColumn,
    lockboxAddresses,
    loan,
    pacAccountNumber,
    paidToDate,
    paymentPreference,
    paymentPreferenceDesc,
    options: {
      optionArrangement,
      optionArrangementType,
      paymentArrangement,
      sdoMpoApoCd,
      sdoMpoApoPaymentForm,
      mpoApoNetDueAmount,
      majorProductCode,
    },
    paymentDates: [
      {
        paidToDate,
        gracePeriod,
        additionalPaymentPeriod,
      },
    ],
    riders,
    routingNumber,
    totalAmount,
    extendedRiders,
    modalPremiums,
    isLockboxAddressRemoved,
    paperlessPreferences,
    autoPayStatusCode: statusCode,
    availableFrequencies,
    premiumBills,
    nextScheduledPaymentDate,
    payments,
  };
};
