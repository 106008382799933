import statesAndTerritories from "../data/statesAndTerritories.json";

export const getAddressfromGoogleApiAddress = (address) => {
  let street = address.original.structured_formatting.main_text;
  let addLine1 = "";
  let addLine2 = "";
  let city = "";
  let state = "";
  let zip = "";
  let stateCode = "";
  let streetCount = 0;
  if (address.original.terms.length === 5) {
    addLine1 = `${address.original.terms[0].value} ${address.original.terms[1].value}`;
    city = address.original.terms[2].value;
    stateCode = address.original.terms[3].value;
    state = getStateName({ stateCode: address.original.terms[3].value });
    for (let index = 0; index < address.geocoded.length; index += 1) {
      const item = address.geocoded[index];
      switch (item.types[0]) {
        case "postal_code":
          zip = item.long_name;
          break;
        case "subpremise":
          addLine2 = item.long_name;
          break;
        default:
      }
    }
  } else {
    for (let index = 0; index < address.geocoded.length; index += 1) {
      const item = address.geocoded[index];
      switch (item.types[0]) {
        case "street_number":
          addLine1 += item.long_name;
          streetCount += 1;
          break;
        case "route":
          addLine1 += ` ${item.short_name}`;
          streetCount += 1;
          break;
        case "locality":
        case "neighborhood":
        case "administrative_area_level_3":
          city = item.long_name;
          break;
        case "subpremise":
          addLine2 = item.long_name;
          break;
        case "administrative_area_level_1": {
          stateCode = item.short_name;
          state += getStateName({ stateCode: item.short_name });
          break;
        }
        case "postal_code":
          zip += item.long_name;
          break;
        default:
      }
    }
    if (streetCount === 2) {
      street = addLine1;
    }
  }
  return {
    street,
    addLine2,
    city,
    state,
    stateCode,
    zip,
  };
};
export const getStateName = ({ stateCode }) => {
  let stateName;
  if (statesAndTerritories && stateCode) {
    const statesNameResult = statesAndTerritories.find(
      (state) => state.value === stateCode
    );
    if (statesNameResult) {
      stateName = statesNameResult.label;
    } else {
      stateName = "";
    }
  }
  return stateName;
};
