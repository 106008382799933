import React from "react";
import { isDefined } from "../../../../utils/evaluate";
import { compareStrings } from "../../../../utils/string";
import IsFalsy from "../../../common/logic/isFalsy";
import IsTruthy from "../../../common/logic/isTruthy";
import { NO_DISTRIBUTION_DISPLAYED_MESSAGE } from "../../../../constants/ui";

const BeneficiaryModal = (props) => {
  const {
    beneficiary: {
      beneficiaryClass,
      allocation,
      relationship,
      emailAddress,
      attnLine,
      addrFirstLine,
      addrSecondLine,
      addrThirdLine,
      addrFourthLine,
      phoneNumber,
      formattedPhoneNumber,
      beneficiaryType,
      asOfDate,
      showFootnoteModal,
      isRetireEase,
    },
  } = props;

  return (
    <div className="modal-body">
      <p className="mm-sub-copy">As of {asOfDate}</p>
      <dl className="mm-key-value-pair">
        <dt id="beneficiary_role_lbl">Role</dt>
        <dd id="beneficiary_role_value">Beneficiary</dd>
        <dt id="beneficiary_class_lbl">Class</dt>
        <dd id="beneficiary_class_value">{beneficiaryClass}</dd>
        <dt id="beneficiary_distribution_lbl">Distribution</dt>
        <dd id="beneficiary_distribution_value">
          <IsTruthy value={showFootnoteModal}>-</IsTruthy>
          <IsFalsy value={showFootnoteModal}>{allocation}</IsFalsy>
        </dd>
        <IsTruthy
          value={beneficiaryType}
          validationFunction={(value) => value.toLowerCase() === "individual"}
        >
          <dt id="beneficiary_relationship_lbl">Relationship</dt>
          <dd id="beneficiary_relationship_value">{relationship}</dd>
          <dt id="beneficiary_phone_number_lbl">Phone</dt>
          <dd id="beneficiary_phone_number_value">
            <IsTruthy value={compareStrings(formattedPhoneNumber, "-")}>
              -
            </IsTruthy>
            <IsFalsy value={compareStrings(formattedPhoneNumber, "-")}>
              <a href={`tel:+${phoneNumber}`}>{formattedPhoneNumber}</a>
            </IsFalsy>
          </dd>
          <dt id="beneficiary_email_lbl">Email</dt>
          <dd id="beneficiary_email_value">
            <IsTruthy value={compareStrings(emailAddress, "-")}>-</IsTruthy>
            <IsFalsy value={compareStrings(emailAddress, "-")}>
              <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
            </IsFalsy>
          </dd>
          <dt id="beneficiary_address_lbl">Address</dt>
          <dd>
            <IsTruthy value={isDefined(attnLine)}>
              <span id="beneficiary_attention_address_value">{attnLine}</span>
              <br />
            </IsTruthy>
            <span id="beneficiary_first_address_value">{addrFirstLine}</span>
            <IsTruthy value={isDefined(addrSecondLine)}>
              <br />
              <span id="beneficiary_second_address_value">
                {addrSecondLine}
              </span>
            </IsTruthy>
            <IsTruthy value={isDefined(addrThirdLine)}>
              <br />
              <span id="beneficiary_third_address_value">{addrThirdLine}</span>
            </IsTruthy>
            <IsTruthy value={isDefined(addrFourthLine)}>
              <br />
              <span id="beneficiary_fourth_address_value">
                {addrFourthLine}
              </span>
            </IsTruthy>
          </dd>
        </IsTruthy>
        <IsTruthy
          value={beneficiaryType}
          validationFunction={(value) =>
            value.toLowerCase() !== "individual" &&
            value.toLowerCase() !== "minor"
          }
        >
          <IsFalsy value={isRetireEase}>
            <dt id="beneficiary_type_lbl">Type</dt>
            <dd id="beneficiary_type_value">{beneficiaryType}</dd>
          </IsFalsy>
          <dt id="beneficiary_phone_number_lbl">Phone</dt>
          <dd id="beneficiary_phone_number_value">
            <IsTruthy value={compareStrings(formattedPhoneNumber, "-")}>
              -
            </IsTruthy>
            <IsFalsy value={compareStrings(formattedPhoneNumber, "-")}>
              <a href={`tel:+${phoneNumber}`}>{formattedPhoneNumber}</a>
            </IsFalsy>
          </dd>
          <dt id="beneficiary_address_lbl">Address</dt>
          <dd>
            <span id="beneficiary_first_address_value">{addrFirstLine}</span>
            <br />
            <span id="beneficiary_second_address_value">{addrSecondLine}</span>
          </dd>
        </IsTruthy>
      </dl>
      <IsTruthy value={showFootnoteModal}>
        <p className="copy-md">{NO_DISTRIBUTION_DISPLAYED_MESSAGE}</p>
      </IsTruthy>
    </div>
  );
};
export default BeneficiaryModal;
