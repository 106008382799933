import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import cloneDeep from "lodash.clonedeep";
import useDeepCompareEffect from "use-deep-compare-effect";
import { searchRows } from "../../../../utils/search";
import DownloadCell from "../../../common/table/customCells/download";
import { useMakeRequest, types } from "../../../../hooks/api";
import {
  formatDocumentData,
  formatName,
  nameFormattingOptions,
} from "../../../../utils/format";
import { getUserBySubType, userTypes } from "../../../../utils/findData";
import { isDefined } from "../../../../utils/evaluate";
import {
  downloadPDF,
  convertBase64StringToByteArray,
} from "../../../common/helpers/downloadHelper";
import { isAnnuityContract } from "../../../../utils/policies";
import { compareStrings } from "../../../../utils/string";
import { useUserState } from "../../../../contexts/user";
import { AGREEMENT_NOT_FOUND } from "../../../../constants/ui";
import { getJointOwnerInfo } from "../../hooks";
import { fireTealiumEvent } from "../../../../utils/tealium";
import { useAddOrRemoveChatLauncher } from "../../../../utils/genesysChatClient";

export const useSearchTable = (props) => {
  const {
    searchStringDefault = "",
    showClearIconDefault = false,
    columns = [],
    searchColumns = [],
    apiRequest = "",
    apiParams = {},
    agreementData = {},
  } = props;
  const [searchString, setSearchString] = useState(searchStringDefault);
  const searchTable = ({ target: { value } }) => setSearchString(value);
  const clearSearch = () => setSearchString("");

  const [showClearIcon, setShowClearIcon] = useState(showClearIconDefault);

  const documentRequest = useMakeRequest({
    apiRequest,
    apiParams,
    immediateRequest: false,
    dataSelector: "processContentData",
  });

  const { isUnauthorized, messages, lineOfBusinessCode } = agreementData;

  const validRequest =
    !isUnauthorized &&
    compareStrings(messages.type, "SUCCESS") &&
    !compareStrings(messages.description, AGREEMENT_NOT_FOUND);

  useEffect(() => {
    if (validRequest) {
      documentRequest.executeRequest();
    }
  }, [isUnauthorized, messages.type]);

  const { data } = documentRequest;

  const rows = isDefined(data)
    ? formatDocumentData(agreementData, data, lineOfBusinessCode)
    : [];

  const [displayedRows, setDisplayedRows] = useState(rows);

  useDeepCompareEffect(() => {
    const searchedRows = searchRows({
      searchString,
      initialRows: cloneDeep(rows),
      columns,
      searchColumns,
    });
    setDisplayedRows(searchedRows);
    setShowClearIcon(searchString.length > 0);
  }, [rows, searchString]);

  return {
    documentRequest,
    searchBar: {
      searchString,
      showClearIcon,
      searchTable,
      clearSearch,
    },
    table: {
      columns,
      rows: displayedRows,
    },
    validRequest,
  };
};

export const useDocumentsProps = (props) => {
  const { agreementKey } = useParams();
  const agreementRequest = useMakeRequest({
    apiRequest: "fetchAgreementData",
    apiParams: { agreementKey },
    immediateRequest: true,
    dataSelector: "processAgreementRequest",
  });

  const agreementData = agreementRequest.data;
  const { policyId, lineOfBusinessCode, isPolicyNotFound } = agreementData;
  const { agreementCustomers } = agreementData || {};

  const personInsured = isAnnuityContract(lineOfBusinessCode)
    ? getUserBySubType({
        type: userTypes.OWNER,
        subType: "LIST",
        people: agreementCustomers,
      })
    : getUserBySubType({
        type: userTypes.INSURED,
        subType: "PRMR",
        people: agreementCustomers,
      });
  const insuredName = personInsured?.fullName
    ? formatName({
        nameObject: personInsured,
        format: nameFormattingOptions.firstNameDisplayedFirst,
      })
    : "";

  const backButtonText = compareStrings(lineOfBusinessCode, "ANN")
    ? "Back to Contract"
    : "Back to Policy";

  const columns = [
    {
      accessor: "documentType",
      label: "Type",
      alignment: "left",
      CustomCell: DownloadCell,
      showIcon: true,
      sortable: "String",
      isCalledFrom: "Documents Extended Page",
    },
    {
      accessor: "formattedEffectiveDate",
      label: "Created Date",
      alignment: "left",
      sortable: "Date",
    },
  ];

  const searchColumns = ["documentType", "formattedEffectiveDate"];

  const searchTableProps = {
    ...props,
    columns,
    searchColumns,
    apiParams: { agreementKey },
    apiRequest: "fetchImagelistFromContent",
    agreementData,
  };
  const { searchBar, table, documentRequest, validRequest } =
    useSearchTable(searchTableProps);

  const [docGuid, setGuid] = useState("");
  const [docObjStore, setObjectStore] = useState("");
  const [document, setDocument] = useState({});

  const { data, executeRequest, dispatch, error } = useMakeRequest({
    apiRequest: "fetchDocumentFromContent",
    apiParams: { agreementKey, docObjStore, docGuid },
    immediateRequest: false,
  });

  const { user } = useUserState();

  useEffect(() => {
    if (data?.length && !error) {
      downloadPDF(document, policyId, convertBase64StringToByteArray(data));
    }
  }, [document, policyId, data, dispatch, error]);

  const generateAndDownloadPDF = (row) => {
    dispatch({
      type: types.RESET,
      newState: {
        data: "",
        isLoading: false,
        error: null,
        shouldRequest: false,
        paramsForSelectors: {},
      },
    });
    const { guid, objectStore } = row;
    const { documentType } = row;
    fireTealiumEvent({
      label: "SVNT",
      action: `${lineOfBusinessCode}: Document downloaded ${documentType}`,
      category: "Download Documents link clicked",
      value: policyId,
      custId: user?.email,
    });
    setDocument(row);
    setGuid(guid);
    setObjectStore(objectStore);
    executeRequest();
  };

  const customFunctions = { generateAndDownloadPDF };

  const {
    user: { email },
  } = useUserState();

  const nameLabelText = compareStrings(lineOfBusinessCode, "ANN")
    ? "Owner Name"
    : "Insured Name";

  const { jointOwnerNameLabel, jointOwnerName, displayJointOwner } =
    getJointOwnerInfo({ agreementCustomers, lineOfBusinessCode });
  const policyLabelText = compareStrings(lineOfBusinessCode, "ANN")
    ? "Contract Number"
    : "Policy Number";

  useAddOrRemoveChatLauncher({ agreementData, page: "Documents page" });

  return {
    requests: { agreementRequest, documentRequest },
    policyId,
    agreementKey,
    table,
    searchBar,
    insuredName,
    customFunctions,
    email,
    validRequest,
    backButtonText,
    nameLabelText,
    policyLabelText,
    jointOwnerNameLabel,
    jointOwnerName,
    displayJointOwner,
    isPolicyNotFound,
  };
};
