import produce from "immer";
import { hasKey } from "../utils/object";
import { compareStrings } from "../utils/string";

const types = {
  UPDATE_FIELD_VALUE: "UPDATE_FIELD_VALUE",
  UPDATE_DROPDOWN: "UPDATE_DROPDOWN",
  UPDATE_TOGGLE: "UPDATE_TOGGLE",
  UPDATE_OPTIONS: "UPDATE_OPTIONS",
  VALIDATION_FAILURE: "VALIDATION_FAILURE",
  VALIDATION_SUCCESSFUL: "VALIDATION_SUCCESSFUL",
  TURN_OFF_ALL_POSTPONES: "TURN_OFF_ALL_POSTPONES",
  RADIO_SELECTED: "RADIO_SELECTED",
  REPLACE_FIELD: "REPLACE_FIELD",
};

const initialFormState = {};

// eslint-disable-next-line default-param-last
const reducer = produce((state = initialFormState, action) => {
  switch (action.type) {
    case types.REPLACE_FIELD:
      return {
        ...state,
        [action.formField.name]: action.formField,
      };
    case types.UPDATE_FIELD_VALUE:
      return {
        ...state,
        [action.formField.name]: {
          ...state[action.formField.name],
          value: action.newValue,
          postpone: action.postpone || false,
        },
      };
    case types.UPDATE_OPTIONS:
      return {
        ...state,
        [action.formField.name]: {
          ...state[action.formField.name],
          options: action.options,
        },
      };
    case types.UPDATE_DROPDOWN:
      return {
        ...state,
        [action.formField.name]: {
          ...state[action.formField.name],
          value: action.value,
          display: action.display,
        },
      };
    case types.UPDATE_TOGGLE:
      return {
        ...state,
        [action.formField.name]: {
          ...state[action.formField.name],
          value: !state[action.formField.name].value,
        },
      };
    case types.VALIDATION_SUCCESSFUL:
      return {
        ...state,
        [action.formField.name]: {
          ...state[action.formField.name],
          errors: [],
        },
      };
    case types.VALIDATION_FAILURE:
      return {
        ...state,
        [action.formField.name]: {
          ...state[action.formField.name],
          errors: action.errors,
        },
      };
    case types.RADIO_SELECTED:
      return Object.keys(state).reduce((fields, fieldName) => {
        const SELECTED_RADIO = compareStrings(fieldName, action.formField.name);
        const SAME_RADIO_GROUP = compareStrings(
          state[fieldName]?.groupName,
          action.formField.groupName
        );
        if (SELECTED_RADIO) {
          return {
            ...fields,
            [fieldName]: {
              ...state[fieldName],
              value: true,
            },
          };
        }
        if (SAME_RADIO_GROUP) {
          return {
            ...fields,
            [fieldName]: {
              ...state[fieldName],
              value: false,
            },
          };
        }
        return {
          ...fields,
          [fieldName]: state[fieldName],
        };
      }, {});
    case types.CLEAR_FOLLOWING_VALUE_FIELDS:
      return Object.keys(state).reduce((fields, fieldName) => {
        const SAME_STEP = compareStrings(
          state[fieldName]?.stepName,
          action.formField?.stepName
        );
        const HIGHER_QUESTION_NUMBER =
          state[fieldName]?.stepQuestionNumber >
          action.formField?.stepQuestionNumber;

        const IS_DROPDOWN = compareStrings(state[fieldName].type, "dropdown");

        const resetValue =
          typeof state[fieldName]?.value === "boolean" ? false : "";

        if (SAME_STEP && HIGHER_QUESTION_NUMBER) {
          if (IS_DROPDOWN) {
            return {
              ...fields,
              [fieldName]: {
                ...state[fieldName],
                display: state[fieldName].defaultDisplay,
                value: resetValue,
              },
            };
          }
          return {
            ...fields,
            [fieldName]: {
              ...state[fieldName],
              value: resetValue,
            },
          };
        }
        return {
          ...fields,
          [fieldName]: state[fieldName],
        };
      }, {});
    case types.TURN_OFF_ALL_POSTPONES:
      return Object.keys(state.formFields).reduce((fields, fieldName) => {
        const HAS_POSTPONE = hasKey({
          obj: state[fieldName],
          key: "postpone",
        });
        if (HAS_POSTPONE) {
          return {
            ...fields,
            [fieldName]: {
              ...state[fieldName],
              postpone: false,
            },
          };
        }
        return {
          ...fields,
          [fieldName]: state[fieldName],
        };
      }, {});
    default:
      return state;
  }
});

export { reducer, types, initialFormState };
