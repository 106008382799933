import { useParams } from "react-router-dom";
import { getUrl } from "../../../utils/dom";
import { getTrimmedPolicyIdFromAgreement } from "../../../utils/findData";

export const GetErrorReferenceValue = () => {
  const { agreementKey } = useParams();
  const urlData = getUrl().split("/");
  let currentPageName = urlData.pop();
  if (decodeURIComponent(currentPageName).indexOf("|") > 1) {
    currentPageName = "";
  }
  const { trimmedPolicyId } = getTrimmedPolicyIdFromAgreement(agreementKey);
  return [currentPageName, trimmedPolicyId].filter((val) => val).join(" ");
};
