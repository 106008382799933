import { useEffect, useState } from "react";
import {
  compareArrayOfStrings,
  compareStrings,
} from "../../../../../utils/string";
import { formattingTypes } from "../../../../../utils/formFormatting";
import statesAndTerritories from "../../../../../data/statesAndTerritories.json";
import australianStates from "../../../../../data/australianStates.json";
import canadianStates from "../../../../../data/canadianStates.json";
import {
  hasError,
  useEmailValidation,
  useFaxValidation,
  useGeneralValidation,
  usePhoneValidation,
} from "../../../../../utils/validation";
import { useEditAddressFields } from "../../../../common/editAddress/hooks";
import { types } from "../../../../../reducers/formReducer";
import { useFormContext } from "../../../../../contexts/form";
import { isDefined } from "../../../../../utils/evaluate";
import {
  DROPDOWN_ITEM_FINDER,
  getMatchedDropDownOption,
} from "../../../../../utils/findData";
import { convertToTitleCase } from "../../../../../utils/format";

export const useContactInformationModalProps = (props) => {
  const { formData, dispatchForm } = useFormContext();
  const [error, setError] = useState("");
  const { contactDetails, closeModal, saveContactDetails } = props;
  const {
    name = "",
    phone = "",
    extension = "",
    fax = "",
    country = "",
    addressLine1 = "",
    addressLine2 = "",
    addressLine3 = "",
    city = "",
    zip = "",
    state = "",
    email = "",
  } = contactDetails || {};

  const {
    editName,
    editPhone,
    editExtension,
    editFax,
    editAddressCountry,
    editAddressAddress1,
    editAddressAddress2,
    editAddressAddress3,
    editAddressCity,
    editAddressZipCode,
    editAddressState,
    editEmail,
  } = formData;

  const countryOption = getMatchedDropDownOption(
    editAddressCountry.options,
    country,
    DROPDOWN_ITEM_FINDER.VALUE
  );

  useEffect(() => {
    dispatchForm({
      type: types.REPLACE_FIELD,
      formField: {
        ...editName,
        value: name,
      },
    });
  }, [name]);

  useEffect(() => {
    dispatchForm({
      type: types.REPLACE_FIELD,
      formField: {
        ...editPhone,
        value: phone,
      },
    });
  }, [phone]);

  useEffect(() => {
    dispatchForm({
      type: types.REPLACE_FIELD,
      formField: {
        ...editExtension,
        value: extension,
      },
    });
  }, [extension]);

  useEffect(() => {
    dispatchForm({
      type: types.REPLACE_FIELD,
      formField: {
        ...editFax,
        value: fax,
      },
    });
  }, [fax]);

  useEffect(() => {
    if (countryOption) {
      dispatchForm({
        type: types.UPDATE_DROPDOWN,
        formField: editAddressCountry,
        value: countryOption?.value,
        display: countryOption?.label,
      });
    }
  }, [country]);

  useEffect(() => {
    dispatchForm({
      type: types.REPLACE_FIELD,
      formField: {
        ...editAddressAddress1,
        value: addressLine1,
      },
    });
  }, [addressLine1]);

  useEffect(() => {
    dispatchForm({
      type: types.REPLACE_FIELD,
      formField: {
        ...editAddressAddress2,
        value: addressLine2,
      },
    });
  }, [addressLine2]);

  useEffect(() => {
    dispatchForm({
      type: types.REPLACE_FIELD,
      formField: {
        ...editAddressAddress3,
        value: addressLine3,
      },
    });
  }, [addressLine3]);

  useEffect(() => {
    dispatchForm({
      type: types.REPLACE_FIELD,
      formField: {
        ...editAddressCity,
        value: city,
      },
    });
  }, [city]);

  useEffect(() => {
    dispatchForm({
      type: types.REPLACE_FIELD,
      formField: {
        ...editEmail,
        value: email,
      },
    });
  }, [email]);

  const isCountryUSA = compareArrayOfStrings(
    ["UNITED STATES OF AMERICA", "USA"],
    editAddressCountry.value
  );
  const isCountryCanada = compareStrings("CAN", editAddressCountry.value);
  const isCountryAustralia = compareStrings("AUS", editAddressCountry.value);

  const displayStateAsDropdown =
    isCountryUSA || isCountryCanada || isCountryAustralia;

  useEffect(() => {
    let dropdownOptions;
    const { hasCountryChangedAfterLoad } = editAddressCountry;
    const updatedZipValue = hasCountryChangedAfterLoad
      ? editAddressZipCode.value
      : zip;
    const updatedStateValue = hasCountryChangedAfterLoad
      ? editAddressState.value
      : state;

    if (isCountryCanada) {
      dropdownOptions = canadianStates;
    } else if (isCountryAustralia) {
      dropdownOptions = australianStates;
    } else {
      dropdownOptions = statesAndTerritories;
    }

    if (isCountryUSA) {
      dispatchForm({
        type: types.REPLACE_FIELD,
        formField: {
          ...editAddressZipCode,
          formatting: formattingTypes.ZIPCODE,
          exactLength: 5,
          maxLength: 5,
          value: updatedZipValue,
        },
      });
    } else {
      const { exactLength, ...rest } = editAddressZipCode;
      dispatchForm({
        type: types.REPLACE_FIELD,
        formField: {
          ...rest,
          formatting: formattingTypes.ADDRESS,
          maxLength: 13,
          value: updatedZipValue,
        },
      });
    }

    if (displayStateAsDropdown) {
      const stateOption = getMatchedDropDownOption(
        dropdownOptions,
        updatedStateValue,
        DROPDOWN_ITEM_FINDER.VALUE
      );

      if (stateOption) {
        // Editing previously added address, with selected dropdown state
        dispatchForm({
          type: types.REPLACE_FIELD,
          formField: {
            ...editAddressState,
            value: stateOption.value,
            display: stateOption.label,
            options: dropdownOptions,
          },
        });
      } else {
        // Adding new address or changing country between USA, AUS, CAN of already added address.
        dispatchForm({
          type: types.REPLACE_FIELD,
          formField: {
            ...editAddressState,
            options: dropdownOptions,
            value: "",
            display: "State/Province/Region",
          },
        });
      }
    } else {
      // Adding new address or changing country to anything other than USA, AUS, CAN of already added address.
      dispatchForm({
        type: types.REPLACE_FIELD,
        formField: {
          ...editAddressState,
          value: updatedStateValue,
        },
      });
    }
  }, [editAddressCountry.value]);

  const saveContact = () => {
    const updatedContactDetails = {
      name: editName.value,
      phone: editPhone.value,
      extension: editExtension.value,
      fax: editFax.value,
      country: editAddressCountry.value,
      addressLine1: convertToTitleCase(editAddressAddress1.value),
      addressLine2: convertToTitleCase(editAddressAddress2.value),
      addressLine3: convertToTitleCase(editAddressAddress3.value),
      city: convertToTitleCase(editAddressCity.value),
      zip: editAddressZipCode.value,
      state: editAddressState.value,
      email: editEmail.value,
    };
    const { hasValidationError } = checkForContactInformationError({
      formData,
      setError,
    });
    if (!hasValidationError) {
      saveContactDetails(updatedContactDetails);
      resetContactFormFields({ formData, dispatchForm });
      closeModal();
    }
  };

  return {
    displayStateAsDropdown,
    saveContact,
    formFields: {
      editName,
      editPhone,
      editExtension,
      editFax,
      editAddressCountry,
      editAddressAddress1,
      editAddressAddress2,
      editAddressAddress3,
      editAddressCity,
      editAddressZipCode,
      editAddressState,
      editEmail,
    },
    error,
  };
};

export const useCountryDropdown = (props) => {
  const { formField } = props;
  const { dispatchForm } = useFormContext();
  const selectedOption = (event) => {
    const clickedOption = getMatchedDropDownOption(
      formField.options,
      event.target.id,
      DROPDOWN_ITEM_FINDER.ID
    );
    dispatchForm({
      type: types.REPLACE_FIELD,
      formField: {
        ...formField,
        hasCountryChangedAfterLoad: true,
        value: clickedOption.value,
        display: clickedOption.label,
      },
    });
  };

  return {
    functions: { selectedOption },
  };
};

export const resetContactFormFields = (props) => {
  const { formData, dispatchForm } = props;
  const {
    editName,
    editPhone,
    editExtension,
    editFax,
    editAddressCountry,
    editAddressAddress1,
    editAddressAddress2,
    editAddressAddress3,
    editAddressCity,
    editAddressZipCode,
    editAddressState,
    editEmail,
  } = formData;

  dispatchForm({
    type: types.REPLACE_FIELD,
    formField: {
      ...editName,
      value: "",
    },
  });
  dispatchForm({
    type: types.REPLACE_FIELD,
    formField: {
      ...editPhone,
      value: "",
    },
  });
  dispatchForm({
    type: types.REPLACE_FIELD,
    formField: {
      ...editExtension,
      value: "",
    },
  });
  dispatchForm({
    type: types.REPLACE_FIELD,
    formField: {
      ...editFax,
      value: "",
    },
  });
  dispatchForm({
    type: types.REPLACE_FIELD,
    formField: {
      ...editAddressCountry,
      value: "USA",
      display: "United States",
      hasCountryChangedAfterLoad: false,
    },
  });
  dispatchForm({
    type: types.REPLACE_FIELD,
    formField: {
      ...editAddressAddress1,
      value: "",
    },
  });
  dispatchForm({
    type: types.REPLACE_FIELD,
    formField: {
      ...editAddressAddress2,
      value: "",
    },
  });
  dispatchForm({
    type: types.REPLACE_FIELD,
    formField: {
      ...editAddressAddress3,
      value: "",
    },
  });
  dispatchForm({
    type: types.REPLACE_FIELD,
    formField: {
      ...editAddressCity,
      value: "",
    },
  });
  dispatchForm({
    type: types.REPLACE_FIELD,
    formField: {
      ...editAddressZipCode,
      value: "",
    },
  });
  dispatchForm({
    type: types.REPLACE_FIELD,
    formField: {
      ...editAddressState,
      value: "",
    },
  });
  dispatchForm({
    type: types.REPLACE_FIELD,
    formField: {
      ...editEmail,
      value: "",
    },
  });
};

export const checkForContactInformationError = (props) => {
  const { formData, setError } = props;
  const {
    editName,
    editPhone,
    editExtension,
    editFax,
    editAddressCountry,
    editAddressAddress1,
    editAddressAddress2,
    editAddressAddress3,
    editAddressCity,
    editAddressZipCode,
    editAddressState,
    editEmail,
  } = formData;

  const areRequiredFieldsFilled = isDefined(editName.value);

  const hasAnyFieldValidationError =
    hasError(editName) ||
    hasError(editPhone) ||
    hasError(editExtension) ||
    hasError(editFax) ||
    hasError(editAddressCountry) ||
    hasError(editAddressAddress1) ||
    hasError(editAddressAddress2) ||
    hasError(editAddressAddress3) ||
    hasError(editAddressCity) ||
    hasError(editAddressZipCode) ||
    hasError(editAddressState) ||
    hasError(editEmail);

  let validationError = "";
  if (!areRequiredFieldsFilled) {
    validationError = "Please fill out all required fields.";
  }
  if (hasAnyFieldValidationError) {
    validationError = "Please fix input errors.";
  }
  const ADDRESS2_OR_ADDRESS3_BUT_NO_ADDRESS1 =
    (isDefined(editAddressAddress2.value) ||
      isDefined(editAddressAddress3.value)) &&
    !isDefined(editAddressAddress1.value);
  if (ADDRESS2_OR_ADDRESS3_BUT_NO_ADDRESS1) {
    validationError =
      "Please fill in Address Line 1 before Address Line 2 or 3.";
  }
  setError(validationError);
  return { hasValidationError: isDefined(validationError) };
};

export const useContactInformationFields = () => {
  const editName = {
    id: "editName",
    name: "editName",
    label: "Name",
    required: true,
    labelBold: true,
    placeholder: "",
    value: "",
    errors: [],
    postpone: true,
    formatting: formattingTypes.NAMES,
    useValidation: useGeneralValidation,
    min: 2,
  };
  const editPhone = {
    id: "editPhone",
    name: "editPhone",
    label: "Phone Number",
    required: false,
    labelBold: true,
    placeholder: "",
    value: "",
    errors: [],
    postpone: true,
    formatting: formattingTypes.PHONE_NUMBER,
    useValidation: usePhoneValidation,
    min: 1,
  };
  const editExtension = {
    id: "editExtension",
    name: "editExtension",
    label: "Extension",
    required: false,
    labelBold: true,
    placeholder: "",
    value: "",
    errors: [],
    postpone: true,
    formatting: formattingTypes.NUMBERS,
    useValidation: useGeneralValidation,
    min: 2,
    max: 6,
  };
  const editFax = {
    id: "editFax",
    name: "editFax",
    label: "Fax",
    required: false,
    labelBold: true,
    placeholder: "",
    value: "",
    errors: [],
    postpone: true,
    formatting: formattingTypes.PHONE_NUMBER,
    useValidation: useFaxValidation,
    min: 1,
  };
  const editEmail = {
    id: "editEmail",
    name: "editEmail",
    label: "Email Address",
    required: false,
    labelBold: true,
    placeholder: "",
    value: "",
    errors: [],
    postpone: true,
    useValidation: useEmailValidation,
    min: 1,
    ariaLabel: "Email Address",
    disabled: false,
  };

  const {
    editAddressCountry,
    editAddressAddress1,
    editAddressAddress2,
    editAddressAddress3,
    editAddressCity,
    editAddressState,
    editAddressZipCode,
  } = useEditAddressFields();

  return {
    editName,
    editPhone,
    editExtension,
    editFax,
    editAddressCountry: {
      ...editAddressCountry,
      value: "USA",
      required: false,
      hasCountryChangedAfterLoad: false,
    },
    editAddressAddress1: {
      ...editAddressAddress1,
      useValidation: useGeneralValidation,
      min: 5,
      required: false,
    },
    editAddressAddress2,
    editAddressAddress3,
    editAddressCity: { ...editAddressCity, required: false },
    editAddressZipCode: { ...editAddressZipCode, required: false },
    editAddressState: { ...editAddressState, required: false },
    editEmail,
  };
};
