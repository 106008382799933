import React from "react";
import LabelWithHelpModal from "../../common/forms/labelWithHelpModal";

const BeneficiaryChangeModalBody = () => (
  <div className="modal-body">
    <hr />
    <h5 id="directLink_BeneficiaryChange_ModalTitle">Beneficiary Change</h5>
    <p>
      The following can be done on the <strong>website </strong>or{" "}
      <strong>mobile</strong> app:
    </p>
    <ul>
      <li>Update most beneficiary arrangements real-time</li>
    </ul>
    <div className="mm-spacing--minor" />
    <h5 id="directLink_BeneficiaryChange_ModalQuickTips">Quick Tips</h5>
    <ul>
      <li>
        Changes for annuities & non-individual life policies are not available
        on the app.
      </li>
      <li>
        Customer will be able to download a prefilled form if a form is
        ultimately required.
      </li>
      <li>
        To edit beneficiaries online, (e.g. edit name, add child) customers must
        have updated complete arrangement at least once online.
      </li>
    </ul>
  </div>
);

const BeneficiaryChangeModal = () => (
  <LabelWithHelpModal
    text="Beneficiary Change"
    modalTitle="Beneficiary Change"
    modalId="beneficiaryChange"
    modalBodyComponent={BeneficiaryChangeModalBody}
  />
);

export default BeneficiaryChangeModal;
