import React, { useEffect } from "react";
import { compareStrings } from "../../../utils/string";
import InputForm from "../../common/forms/inputForm";
import RadioForm from "../../common/forms/radioForm";
import IsFalsy from "../../common/logic/isFalsy";
import IsTruthy from "../../common/logic/isTruthy";
import { useLoanDetailProps } from "../hooks";

const LoanDetails = (props) => {
  const {
    formData: { specificAmountRadio, specificAmountInput, maxLoanAmountRadio },
    maxLoanQuoteEffDate,
    currentInterestRate,
    interestRateType,
    maxLoanAmountFormatted,
    maxLoanAmtExceeds200k,
  } = useLoanDetailProps(props);
  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        if (compareStrings(event.target.id, "input-specificAmountInput")) {
          event.preventDefault();
          event.stopPropagation();
          document.getElementById("continueButton").click();
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);
  return (
    <>
      <h1>Loan Details</h1>
      <p className="mm-sub-copy">* = Required Field</p>
      <div className="row mm-spacing">
        <div className="col-8">
          <p className="h3">Loan Amount Requested*</p>
          <p className="mm-sub-copy">As of {maxLoanQuoteEffDate}</p>
          <div className="card--flat">
            <div className="card-body">
              <div className="row">
                <div className="col-6">
                  <p className="mm-spacing--none">
                    <span id="current_interest_rate_label">
                      Current Interest Rate:{" "}
                    </span>
                    <strong id="current_interest_rate_value">
                      {currentInterestRate}
                    </strong>
                  </p>
                </div>
                <div className="col-6">
                  <p className="mm-spacing--none">
                    <span id="interest_rate_type_label">
                      Interest Rate Type:{" "}
                    </span>
                    <strong id="interest_rate_type_value">
                      {interestRateType}
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <IsTruthy value={maxLoanAmtExceeds200k}>
            <p className="caption">
              The Maximum Loan Amount available exceeds the amount that can be
              requested online. Specify an amount less than $200,000.00 or a
              signed loan form is required and will be available for download.
            </p>
          </IsTruthy>

          <div className="row mm-spacing--minor">
            <div className="col-6">
              <RadioForm formField={specificAmountRadio} />
            </div>
            <div className="col-6">
              <InputForm formField={specificAmountInput} />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <RadioForm formField={maxLoanAmountRadio} />
            </div>
            <div className="col-6">
              <p id="max_loan_amount_value">
                <IsTruthy value={maxLoanAmtExceeds200k}>
                  {maxLoanAmountFormatted}
                </IsTruthy>
                <IsFalsy value={maxLoanAmtExceeds200k}>
                  <strong>{maxLoanAmountFormatted}</strong>
                </IsFalsy>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(LoanDetails);
