import React from "react";
import { useContractDetailsProps } from "./hooks";
import { formatSupTagIfPresent } from "../../../../utils/format";
import IsTruthy from "../../../common/logic/isTruthy";
import IsFalsy from "../../../common/logic/isFalsy";
import PayoutInformation from "../payoutInformation";
import { isDefined } from "../../../../utils/evaluate";
import LoadingSection, {
  errorTypes,
  loadingTypes,
} from "../../../common/loading";
import { InformationPopover } from "../../../common/popovers/informationPopover";
import { compareStrings } from "../../../../utils/string";
import {
  FREE_AMOUNT_NOT_APPLICABLE_TOOLTIP_TEXT,
  FREE_WITHDRAWAL_NOT_APPLICABLE_TOOLTIP_TEXT,
} from "../../../../constants/ui";

const ContractDetails = (props) => {
  const { id, annuityRequest, agreementRequest, unsupportedPolicy } = props;
  const {
    deathBenefitAmount,
    freeWithdrawalProvision,
    fundBalanceAmount,
    guaranteedMinimumDeathBenefitAmount,
    issueDate,
    lineOfBusinessName,
    marketingTypeDescription,
    maxPartialSurrenderValue,
    netSurrenderAmount,
    policyId,
    productTypeName,
    requestedMaturityDate,
    showModal,
    status,
    surrenderChargeAmount,
    totalAvailableWithdrawalAmount,
    guarPeriodStartDate,
    guarPeriodEndDate,
    freeAmount,
    ascSurrenderCharge,
    isOdysseyProduct,
    isStableVoyageProduct,
    issueState,
    isFixedAnnuityContract,
    isVariableAnnuityContract,
    displayToolTip,
    displayVAFreeAmountToolTip,
    minimumGuaranteedInterestRate,
    gmibAvailWithdrawalAmount,
    displayGmibAvailWithdrawalAmount,
    gmwbAvailWithdrawalAmount,
    displayGmwbAvailWithdrawalAmount,
    assetAllocModel,
    showAssetAllocationModelField,
    enableSurrChargeScheduleModal,
    isArtistryAnnuityContract,
    employeePayments,
    employeeContractValue,
    employerContractValue,
    vaFreeWithdrawalAmount,
    totalDistributions,
    initialPurchase,
    isRetireEaseContract,
    annuityOption,
    annuityOptionDescription,
    issuingCompany,
    residentState,
    displayLengthOfPeriodCertain,
    lengthOfPeriodCertain,
    totalPurchasePaymentAmount,
    surrenderChargeSchedule,
    isFoundationPremiumContract,
    isFlexIVFund1FPOrFund4FPContract,
    showRecalcValues,
    recalcAsOfDate,
    isRetireEaseChoiceAndPeriodCertainOrRefundAnnuityOption,
    shouldDisplayTotalDistributionsForRetireEase,
    shouldDisplayTotalDistributionsForRetireEaseChoice,
    enableRetireEaseCdscModal,
    surrenderChargeScheduleValue,
  } = useContractDetailsProps(props);

  const guaranteePeriodDates = (
    <>
      <dt id="guar_period_start_date_lbl">Guarantee Period Start Date</dt>
      <dd id="guar_period_start_date_value">{guarPeriodStartDate}</dd>
      <dt id="guar_period_end_date_lbl">Guarantee Period End Date</dt>
      <dd id="guar_period_end_date_value">{guarPeriodEndDate}</dd>
    </>
  );

  const contractDetails = (
    <>
      <h2 aria-label="Contract Details" id={id}>
        Contract Details
      </h2>
      <IsTruthy value={showRecalcValues}>
        <div>
          <p id="recalculation_as_of_date_value" className="mm-sub-copy">
            <mark>As of {recalcAsOfDate}</mark>
          </p>
        </div>
      </IsTruthy>
      <div className="mm-spacing--minor">
        <hr />
        <div className="row mm-spacing--minor">
          <div className="col-12 col-md-6">
            <dl className="mm-key-value-pair">
              <dt id="product_name_lbl">Product Name</dt>
              <dd id="product_name_value">
                {formatSupTagIfPresent(productTypeName)}{" "}
                <IsTruthy value={displayToolTip}>
                  <sup className="print-only">1</sup>{" "}
                  <InformationPopover
                    popoverId="massmutual_one_year_renewal_tooltip"
                    text="At any time during a One-Year Renewal Guarantee Period, you may renew the Contract Value into a Multi-Year Renewal Guarantee Period."
                  />
                </IsTruthy>
              </dd>
              <dt id="product_line_lbl">Product Line</dt>
              <dd id="product_line_value">{lineOfBusinessName}</dd>
              <IsTruthy value={isRetireEaseContract}>
                <dt id="annuity_option_lbl">
                  Annuity Option<sup className="print-only">1</sup>{" "}
                  <InformationPopover
                    popoverId="massmutual_annuity_option_tooltip"
                    text={annuityOptionDescription}
                  />
                </dt>
                <dd id="annuity_option_value">{annuityOption}</dd>
              </IsTruthy>
              <dt id="contract_number_lbl">Contract Number</dt>
              <dd id="contract_number_value">{policyId}</dd>
              <dt id="policy_status_lbl">Status</dt>
              <dd id="policy_status_value">{status}</dd>
              <IsTruthy value={showAssetAllocationModelField}>
                <dt id="asset_alloc_model_lbl">Model</dt>
                <dd id="asset_alloc_value">{assetAllocModel}</dd>
              </IsTruthy>
              <dt id="market_type_lbl">Market Type</dt>
              <dd id="market_type_value">{marketingTypeDescription}</dd>
              <IsTruthy value={enableRetireEaseCdscModal}>
                <dt id="retireease_cdsc_schedule_lbl">
                  Contingent Deferred Sales Charge Schedule
                </dt>
                <dd id="retireease_contingent_deferred_sales_value">
                  <button
                    id="retireease_contingent_deferred_sales_btn"
                    onClick={showModal()}
                    className="btn btn-inline"
                    type="button"
                  >
                    View Schedule
                  </button>
                </dd>
              </IsTruthy>
              <IsTruthy
                value={
                  isFoundationPremiumContract ||
                  isFlexIVFund1FPOrFund4FPContract
                }
              >
                <dt id="current_guaranteed_min_interest_rate_lbl">
                  Current Guaranteed Min Interest Rate
                </dt>
                <dd id="current_guaranteed_min_interest_rate_value">4%</dd>
              </IsTruthy>
              <IsTruthy value={isArtistryAnnuityContract}>
                <IsTruthy value={isDefined(employeePayments)}>
                  <dt id="employee_payments_lbl">Employee Payments</dt>
                  <dd id="employee_payments_value">{employeePayments}</dd>
                </IsTruthy>
              </IsTruthy>

              <IsTruthy
                value={shouldDisplayTotalDistributionsForRetireEaseChoice}
              >
                <dt id="total_distributions_value_lbl">
                  Total Distributions<sup className="print-only">2</sup>{" "}
                  <InformationPopover
                    popoverId="total_distributions_tooltip"
                    text="This is the total of all periodic payments where applicable."
                  />
                </dt>
                <dd id="total_distributions_value">{totalDistributions}</dd>
              </IsTruthy>
            </dl>
          </div>
          <div className="col-12 col-md-6">
            <dl className="mm-key-value-pair">
              <dt id="issue_date_lbl">Issue Date</dt>
              <dd id="issue_date_value">{issueDate}</dd>
              <dt id="annuity_date_lbl">Annuity Date</dt>
              <dd id="annuity_date_value">{requestedMaturityDate}</dd>
              <IsTruthy
                value={
                  isRetireEaseContract ||
                  isFoundationPremiumContract ||
                  isFlexIVFund1FPOrFund4FPContract
                }
              >
                <dt id="issuing_company_lbl">Issuing Company</dt>
                <dd id="issuing_company__value">{issuingCompany}</dd>
              </IsTruthy>
              <IsFalsy value={isArtistryAnnuityContract}>
                <dt id="total_purchase_payment_lbl">
                  Total Purchase Payment(s)
                </dt>
                <dd id="total_purchase_payment_value">
                  {totalPurchasePaymentAmount}
                </dd>
              </IsFalsy>
              <IsFalsy value={isRetireEaseContract}>
                <dt id="contract_val_lbl">Contract Value</dt>
                <dd id="contract_val_value">
                  <IsTruthy value={showRecalcValues}>
                    <mark>{fundBalanceAmount}</mark>
                  </IsTruthy>
                  <IsFalsy value={showRecalcValues}>
                    <span>{fundBalanceAmount}</span>
                  </IsFalsy>
                </dd>
              </IsFalsy>
              <IsTruthy value={isRetireEaseContract}>
                <dt id="resident_state_lbl">Resident State</dt>
                <dd id="resident_state_value">{residentState}</dd>
              </IsTruthy>
              <IsTruthy value={isDefined(annuityRequest)}>
                <dt id="contract_state_lbl">Contract State</dt>
                <dd id="contract_state_value">{issueState}</dd>
                <IsTruthy value={isStableVoyageProduct}>
                  {guaranteePeriodDates}
                </IsTruthy>
                <IsTruthy value={shouldDisplayTotalDistributionsForRetireEase}>
                  <dt id="total_distributions_value_lbl">
                    Total Distributions<sup className="print-only">2</sup>{" "}
                    <InformationPopover
                      popoverId="total_distributions_tooltip"
                      text="This is the total of all periodic payments and withdrawals where applicable."
                    />
                  </dt>
                  <dd id="total_distributions_value">{totalDistributions}</dd>
                </IsTruthy>
              </IsTruthy>
              <IsTruthy value={isArtistryAnnuityContract}>
                <IsTruthy value={isDefined(employeeContractValue)}>
                  <dt id="employee_contractvalue_lbl">
                    Employee Contract Value
                  </dt>
                  <dd id="employee_contractvalue_value">
                    <IsTruthy value={showRecalcValues}>
                      <mark>{employeeContractValue}</mark>
                    </IsTruthy>
                    <IsFalsy value={showRecalcValues}>
                      <span>{employeeContractValue}</span>
                    </IsFalsy>
                  </dd>
                </IsTruthy>
                <IsTruthy value={isDefined(employerContractValue)}>
                  <dt id="employer_contractvalue_lbl">
                    Employer Contract Value
                  </dt>
                  <dd id="employer_contractvalue_value">
                    <IsTruthy value={showRecalcValues}>
                      <mark>{employerContractValue}</mark>
                    </IsTruthy>
                    <IsFalsy value={showRecalcValues}>
                      <span>{employerContractValue}</span>
                    </IsFalsy>
                  </dd>
                </IsTruthy>
              </IsTruthy>
              <IsTruthy
                value={isRetireEaseContract && displayLengthOfPeriodCertain}
              >
                <dt id="length_of_period_certain_lbl">
                  Length Of Period Certain
                </dt>
                <dd id="length_of_period_certain_value">
                  {lengthOfPeriodCertain}
                </dd>
              </IsTruthy>
              <IsTruthy
                value={isRetireEaseChoiceAndPeriodCertainOrRefundAnnuityOption}
              >
                <dt id="initial_purchase_payment_lbl">
                  Initial Purchase Payment
                </dt>
                <dd id="initial_purchase_payment_value">{initialPurchase}</dd>
              </IsTruthy>
            </dl>
          </div>
        </div>
      </div>
      <IsTruthy value={displayToolTip}>
        <ol className="print-only">
          <li>
            At any time during a One-Year Renewal Guarantee Period, you may
            renew the Contract Value into a Multi-Year Renewal Guarantee Period.
          </li>
        </ol>
      </IsTruthy>
      <IsTruthy value={isRetireEaseContract}>
        <ol className="print-only">
          <li>{annuityOptionDescription}</li>
          <IsTruthy
            value={
              shouldDisplayTotalDistributionsForRetireEase ||
              shouldDisplayTotalDistributionsForRetireEaseChoice
            }
          >
            <li>
              This is the total of all periodic payments and withdrawals where
              applicable.
            </li>
          </IsTruthy>
        </ol>
      </IsTruthy>
      <hr />
    </>
  );

  const envisionWithdrawalsAndDeathBenefit = (
    <>
      <h3 id="envision_withdrawals_subsection">Withdrawal(s)</h3>
      <hr />
      <div className="row mm-spacing--minor">
        <div className="col-12 col-md-6">
          <dl className="mm-key-value-pair">
            <dt id="contract_withdrawl_lbl">
              Contract Withdrawal <br /> Value
            </dt>
            <dd id="contract_withdrawl_value">{netSurrenderAmount}</dd>
            <dt id="contingent_deferred_sales_lbl">
              Contingent Deferred Sales Charge Schedule
            </dt>
            <dd id="contingent_deferred_sales_value">
              <button
                id="contingent_deferred_sales_btn"
                onClick={showModal()}
                className="btn btn-inline"
                type="button"
              >
                View Schedule
              </button>
            </dd>
            <dt id="partial_withdrawal_limit_lbl">
              Partial Withdrawal Limit<sup className="print-only">1</sup>
            </dt>
            <dd id="partial_withdrawal_limit_value">
              {maxPartialSurrenderValue}
            </dd>
          </dl>
        </div>

        <div className="col-12 col-md-6">
          <dl className="mm-key-value-pair">
            <dt id="contingent_deferred_sales_charge_lbl">
              Contingent Deferred Sales Charge
            </dt>
            <dd id="contingent_deferred_sales_charge_value">
              {surrenderChargeAmount}
            </dd>
            <dt id="free_withdrawal_lbl">Free Withdrawal</dt>
            <dd id="free_withdrawal_value">{freeWithdrawalProvision}</dd>
            <dt id="total_withdrawal_payment_lbl">
              Total Withdrawal(s)<sup className="print-only">2</sup>
            </dt>
            <dd id="total_withdrawal_payment_value">
              {totalAvailableWithdrawalAmount}
            </dd>
          </dl>
        </div>
      </div>
      <ol className="print-only">
        <li>
          The Partial Withdrawal Limit is the maximum amount that can be taken
          without surrendering the contract; less any applicable CDSC. Please
          refer to your contract or prospectus for more information and
          exception.
        </li>
        <li>The total withdrawals over the life of the contract.</li>
      </ol>
      <hr />
      {/*
       *Death Benefit
       */}
      <h3 id="envision_death_benefit_subsection">Death Benefit</h3>
      <hr />
      <div className="row mm-spacing--minor">
        <div className="col-12 col-md-6">
          <dl className="mm-key-value-pair">
            <dt id="current_death_benefit_lbl">Current Death Benefit</dt>
            <dd id="current_death_benefit_value">{deathBenefitAmount}</dd>
          </dl>
        </div>
        <div className="col-12 col-md-6">
          <dl className="mm-key-value-pair">
            <dt id="guaranteed_min_death_benefit_lbl">
              Guaranteed Minimum Death Benefit
              <sup className="print-only">1</sup>
            </dt>
            <dd id="guaranteed_min_death_benefit_value">
              {guaranteedMinimumDeathBenefitAmount}
            </dd>
          </dl>
        </div>
      </div>
      <ol className="print-only">
        <li>
          The Minimum Death Benefit is the guaranteed portion of the current
          Death Benefit and is not impacted by fees and market performance but
          will be impacted by future withdrawals and purchase payments.
        </li>
      </ol>
      <hr />
    </>
  );

  const annuityWithdrawalsAndDeathBenefit = (
    <IsFalsy value={isRetireEaseContract}>
      <h3>Withdrawals and Death Benefit</h3>
      <hr />
      <div className="row mm-spacing--minor">
        <div className="col-12 col-md-6">
          <dl className="mm-key-value-pair">
            <IsTruthy value={isFixedAnnuityContract}>
              <dt id="surrender_value_lbl">Surrender Value</dt>
            </IsTruthy>
            <IsTruthy value={isVariableAnnuityContract}>
              <dt id="contract_withdrawal_lbl"> Contract Withdrawal Value</dt>
            </IsTruthy>
            <dd id="surrender_and_contract_withdrawal_value_value">
              <IsTruthy value={showRecalcValues}>
                <mark>{netSurrenderAmount}</mark>
              </IsTruthy>
              <IsFalsy value={showRecalcValues}>
                <span>{netSurrenderAmount}</span>
              </IsFalsy>
            </dd>

            <IsTruthy value={isFixedAnnuityContract}>
              <dt id="surrender_charge_period_lbl">Surrender Charge Period</dt>
            </IsTruthy>
            <IsTruthy value={isVariableAnnuityContract}>
              <dt id="cdsc_schedule_lbl">
                Contingent Deferred Sales Charge Schedule
              </dt>
            </IsTruthy>
            <IsFalsy value={enableSurrChargeScheduleModal}>
              <dd id="surrender_charge_period_value">
                {surrenderChargeSchedule}
              </dd>
            </IsFalsy>
            <IsTruthy value={enableSurrChargeScheduleModal}>
              <dd id="contingent_deferred_sales_value">
                <button
                  id="contingent_deferred_sales_btn"
                  onClick={showModal()}
                  className="btn btn-inline"
                  type="button"
                >
                  {surrenderChargeScheduleValue}
                </button>
              </dd>
            </IsTruthy>
            <IsTruthy value={isFixedAnnuityContract}>
              <dt id="surrender_charge_lbl">Surrender Charge</dt>
            </IsTruthy>
            <IsTruthy value={isVariableAnnuityContract}>
              <dt id="contingent_deferred_sales_charge_lbl">
                Contingent Deferred Sales Charge
              </dt>
            </IsTruthy>
            <dd id="surrender_charge_and_cdsc_value">
              <IsTruthy value={showRecalcValues}>
                <mark>{ascSurrenderCharge}</mark>
              </IsTruthy>
              <IsFalsy value={showRecalcValues}>
                <span>{ascSurrenderCharge}</span>
              </IsFalsy>
            </dd>
          </dl>
        </div>

        <div className="col-12 col-md-6">
          <dl className="mm-key-value-pair">
            <IsTruthy value={isFixedAnnuityContract}>
              <dt id="free_amount_lbl">Free Amount</dt>{" "}
              <dd id="free_amount_value">
                <IsTruthy value={showRecalcValues}>
                  <mark>{freeAmount}</mark>
                </IsTruthy>
                <IsFalsy value={showRecalcValues}>
                  <span>{freeAmount}</span>
                </IsFalsy>
                <IsTruthy value={compareStrings(freeAmount, "Not Applicable")}>
                  <sup className="print-only">1</sup>{" "}
                  <InformationPopover
                    popoverId="free_amount_tooltip"
                    text={FREE_AMOUNT_NOT_APPLICABLE_TOOLTIP_TEXT}
                  />
                </IsTruthy>
              </dd>
            </IsTruthy>
            <IsTruthy value={isVariableAnnuityContract}>
              <dt id="free_withdrawal_lbl">Free Withdrawal</dt>{" "}
              <dd id="free_withdrawal_value">
                <IsTruthy value={showRecalcValues}>
                  <mark>{vaFreeWithdrawalAmount}</mark>
                </IsTruthy>
                <IsFalsy value={showRecalcValues}>
                  <span>{vaFreeWithdrawalAmount}</span>
                </IsFalsy>{" "}
                <IsTruthy value={displayVAFreeAmountToolTip}>
                  <sup className="print-only">1</sup>
                  <InformationPopover
                    popoverId="massmutual_free_withdrawal_tooltip"
                    text={FREE_WITHDRAWAL_NOT_APPLICABLE_TOOLTIP_TEXT}
                  />
                </IsTruthy>
              </dd>
            </IsTruthy>

            <dt id="death_benefit_lbl">Current Death Benefit</dt>
            <dd id="death_benefit_value">
              {" "}
              <IsTruthy value={showRecalcValues}>
                <mark>{deathBenefitAmount}</mark>
              </IsTruthy>
              <IsFalsy value={showRecalcValues}>
                <span>{deathBenefitAmount}</span>
              </IsFalsy>
            </dd>
            <IsFalsy
              value={
                isFoundationPremiumContract || isFlexIVFund1FPOrFund4FPContract
              }
            >
              <dt id="total_withdrawal_lbl">Total Withdrawal(s)</dt>
              <dd id="total_withdrawal_value">
                {totalAvailableWithdrawalAmount}
              </dd>
            </IsFalsy>
            <IsTruthy value={displayGmibAvailWithdrawalAmount}>
              <dt id="gmib_avail_withdraw_lbl">
                Guaranteed Minimum Income Benefit Available Withdrawal Amount
              </dt>
              <dd id="gmib_avail_withdraw_value">
                <IsTruthy value={showRecalcValues}>
                  <mark>{gmibAvailWithdrawalAmount}</mark>
                </IsTruthy>
                <IsFalsy value={showRecalcValues}>
                  <span>{gmibAvailWithdrawalAmount}</span>
                </IsFalsy>
              </dd>
            </IsTruthy>
            <IsTruthy value={displayGmwbAvailWithdrawalAmount}>
              <dt id="gmwb_avail_withdraw_lbl">
                Guaranteed Minimum Withdrawal Benefit Available Withdrawal
                Amount
              </dt>
              <dd id="gmwb_avail_withdraw_value">
                <IsTruthy value={showRecalcValues}>
                  <mark>{gmwbAvailWithdrawalAmount}</mark>
                </IsTruthy>
                <IsFalsy value={showRecalcValues}>
                  <span>{gmwbAvailWithdrawalAmount}</span>
                </IsFalsy>
              </dd>
            </IsTruthy>
            <IsTruthy value={isStableVoyageProduct || isOdysseyProduct}>
              <dt id="guar_min_interest_rate_lbl">
                Current Guaranteed Minimum Interest Rate
              </dt>
              <dd id="guar_min_interest_value">
                <IsTruthy value={showRecalcValues}>
                  <mark>{minimumGuaranteedInterestRate}</mark>
                </IsTruthy>
                <IsFalsy value={showRecalcValues}>
                  <span>{minimumGuaranteedInterestRate}</span>
                </IsFalsy>
              </dd>
            </IsTruthy>
          </dl>
        </div>
      </div>
      <IsTruthy value={isFixedAnnuityContract}>
        <IsTruthy value={compareStrings(freeAmount, "Not Applicable")}>
          <ol className="print-only">
            <li>
              Free Amount is not applicable when the Surrender Charge is $0.
            </li>
          </ol>
        </IsTruthy>
        <hr />
      </IsTruthy>
      <IsTruthy value={isVariableAnnuityContract}>
        <IsTruthy value={displayVAFreeAmountToolTip}>
          <ol className="print-only">
            <li>
              The Free Withdrawal is not applicable because the Contingent
              Deferred Sales Charge is $0.00.
            </li>
          </ol>
        </IsTruthy>
        <hr />
      </IsTruthy>
    </IsFalsy>
  );

  return (
    <div className="mm-spacing">
      <IsTruthy value={isDefined(annuityRequest)}>
        <LoadingSection
          request={annuityRequest}
          loadingType={loadingTypes.PARAGRAPH}
          errorType={errorTypes.CHILDREN}
          unsupportedPolicy={unsupportedPolicy}
        >
          {contractDetails}
          {annuityWithdrawalsAndDeathBenefit}
          <PayoutInformation
            annuityRequest={annuityRequest}
            agreementRequest={agreementRequest}
          />
        </LoadingSection>
      </IsTruthy>
      <IsFalsy value={isDefined(annuityRequest)}>
        {contractDetails}
        {envisionWithdrawalsAndDeathBenefit}
      </IsFalsy>
    </div>
  );
};

export default React.memo(ContractDetails);
