import React, { useReducer, useContext, createContext, useMemo } from "react";
import {
  reducer,
  types,
  initialAgentProfileState,
} from "../reducers/agentProfileReducer";

const AgentProfileContext = createContext(null);

const AgentProfileProvider = (props) => {
  const { children, initialState = initialAgentProfileState } = props;

  const [state, dispatch] = useReducer(reducer, initialState);

  const values = useMemo(
    () => ({
      agentProfile: state,
      agentProfileDispatch: dispatch,
    }),
    [state, dispatch]
  );

  return (
    <AgentProfileContext.Provider value={values}>
      {children}
    </AgentProfileContext.Provider>
  );
};

const useAgentProfileState = () => useContext(AgentProfileContext);

export { AgentProfileProvider, useAgentProfileState, types };
