import React, { useEffect } from "react";
import { setTitle } from "../../../../utils/dom";
import Table from "../../../common/table";
import TableControl from "../../../common/table/tableControl";
import { useDocumentsProps } from "./hooks";
import LoadingSection, {
  errorTypes,
  loadingTypes,
} from "../../../common/loading";
import FiveHundredOneError from "../../../common/errors/501";
import IsTruthy from "../../../common/logic/isTruthy";
import { tealiumPageViewEvent } from "../../../../utils/tealium";
import IsFalsy from "../../../common/logic/isFalsy";
import FourHundredFourError from "../../../common/errors/404";
import BackToPolicy from "../../../common/backToPolicy";

const ViewAllDocuments = (props) => {
  setTitle("ServiceNet - View All Documents");

  const {
    requests: { agreementRequest, documentRequest },
    table: { columns, rows },
    searchBar: { searchString, showClearIcon, searchTable, clearSearch },
    policyId,
    agreementKey,
    insuredName,
    customFunctions,
    email,
    validRequest,
    nameLabelText,
    policyLabelText,
    jointOwnerNameLabel,
    jointOwnerName,
    displayJointOwner,
    isPolicyNotFound,
  } = useDocumentsProps(props);

  useEffect(() => {
    tealiumPageViewEvent({
      page: "View All Documents",
      category: agreementKey,
      custId: email,
    });
  }, []);

  if (isPolicyNotFound) {
    return <FourHundredFourError />;
  }

  return (
    <LoadingSection
      request={agreementRequest}
      loadingType={loadingTypes.PARAGRAPH}
      errorType={errorTypes._500}
    >
      <LoadingSection
        request={documentRequest}
        loadingType={loadingTypes.PARAGRAPH}
        errorType={errorTypes._500}
      >
        <IsFalsy value={validRequest}>
          <FiveHundredOneError />
        </IsFalsy>
        <IsTruthy value={validRequest}>
          <BackToPolicy
            agreementData={agreementRequest.data}
            jumpLink="documents"
          />
          <h1 id="documents_header_lbl"> Documents </h1>
          <div className="row mm-spacing">
            <div className="col-md-6 col-sm-12">
              <dl className="mm-key-value-pair--summation">
                <dt id="insured_name_lbl">{nameLabelText}</dt>
                <dd id="insured_name_value">{insuredName}</dd>
                <IsTruthy value={displayJointOwner}>
                  <dt id="insured_name_lbl">{jointOwnerNameLabel}</dt>
                  <dd id="insured_name_value">{jointOwnerName}</dd>
                </IsTruthy>
                <dt id="policy_id_lbl">{policyLabelText}</dt>
                <dd id="policy_id_value">{policyId}</dd>
              </dl>
            </div>
          </div>
          <TableControl
            searchString={searchString}
            searchTable={searchTable}
            showClearIcon={showClearIcon}
            clearSearch={clearSearch}
            placeHolderText="Search"
          />
          <div className="row">
            <div className="col-sm">
              <Table
                columns={columns}
                rows={rows}
                defaultSortColumn="formattedEffectiveDate"
                customFunctions={customFunctions}
              />
            </div>
          </div>
        </IsTruthy>
      </LoadingSection>
    </LoadingSection>
  );
};
export default React.memo(ViewAllDocuments);
