import React from "react";
import CheckboxForm from "../../common/forms/checkboxForm";
import { useFormContext } from "../../../contexts/form";

const Acknowledgement = () => {
  const { formData } = useFormContext();
  const { authAcknowledgement } = formData;

  return (
    <div className="mm-spacing">
      <p className="h3" id="acknowledgement_lbl">
        Acknowledgement*
      </p>
      <CheckboxForm formField={authAcknowledgement} />
    </div>
  );
};
export default React.memo(Acknowledgement);
