import React from "react";
import { Modal } from "react-bootstrap";
import { ScrollSpy } from "react-blueprint-library";
// eslint-disable-next-line import/no-extraneous-dependencies
import MMLogo from "blueprint-css/images/mm-logo/MM_FullMark_294.svg";
import { setTitle } from "../../../../utils/dom";
import DisabilityPolicyInfoHeader from "../../sections/basicPolicyInfoHeader/disability/policyInfoHeader";
import { useDisabilityPolicyDetailProps } from "./hooks";
import Detail from "../../sections/detail";
import PolicyAndContactInfoDrawer from "../../../common/drawers/policyAndContactInfoDrawer";
import PeopleOnPolicy from "../../sections/peopleOnPolicy/index";
import CoverageAndRiders from "../../sections/coverageAndRiders/index";
import Documents from "../../sections/documents";
import LoadingSection, {
  errorTypes,
  loadingTypes,
} from "../../../common/loading";
import Dividends from "../../sections/dividends";
import FinancialHistorySection from "../../sections/financialHistory";
import Billing from "../../sections/billing";
import ServiceHistory from "../../sections/serviceHistory";
import IsTruthy from "../../../common/logic/isTruthy";
import Kpi from "../../sections/kpi";
import { isDefined } from "../../../../utils/evaluate";
import { AS_OF_DATE_UNAVAILABLE } from "../../../../constants/ui";
import IsFalsy from "../../../common/logic/isFalsy";

const DisabilityPolicyDetails = (props) => {
  setTitle("ServiceNet - Policy Details");
  const {
    apiRequests: {
      agreementRequest,
      loanRequest,
      producersRequest,
      billingRequest,
      serviceHistoryRequest,
      deathClaimEligibilityRequest,
    },
    unsupportedPolicy,
  } = props;
  const {
    policyId,
    asOfDate,
    printPDP,
    openDrawer,
    closeDrawer,
    isDrawerOpened,
    customer,
    email,
    agreementKey,
    policyInForce,
    lineOfBusinessCode,
    hideEdsDiAsOfDate,
  } = useDisabilityPolicyDetailProps(props);

  return (
    <>
      <div id="print_disclosure" className="print-only">
        <img
          id="mm_footer_logo_img"
          className="mm-logo"
          alt="MassMutual Logo"
          width="150"
          src={MMLogo}
        />{" "}
        <p>
          The information shown is as of {asOfDate} and could differ based on
          factors such as the last premium/purchase payment received, the
          policy/contract status, and any policy/contract changes that may have
          been submitted but not yet processed since that date. This report does
          not replace the official statements that you receive about this
          policy/contract. You should rely on your official statements as the
          most accurate source of information.
        </p>
      </div>
      <DisabilityPolicyInfoHeader
        policyId={policyId}
        agreementRequest={agreementRequest}
        openDrawer={openDrawer}
        loanRequest={loanRequest}
        billingRequest={billingRequest}
        serviceHistoryRequest={serviceHistoryRequest}
        deathClaimEligibilityRequest={deathClaimEligibilityRequest}
        unsupportedPolicy={unsupportedPolicy}
        producerData={producersRequest?.data}
        email={email}
        lineOfBusinessCode={lineOfBusinessCode}
      />

      <IsTruthy value={policyInForce}>
        <Kpi
          policyId={policyId}
          agreementKey={agreementKey}
          agreementRequest={agreementRequest}
          unsupportedPolicy={unsupportedPolicy}
          billingRequest={billingRequest}
          email={email}
        />
      </IsTruthy>
      <LoadingSection
        request={agreementRequest}
        loadingType={loadingTypes.PARAGRAPH}
        errorType={errorTypes._500}
        unsupportedPolicy={unsupportedPolicy}
      >
        <IsTruthy value={policyInForce}>
          {" "}
          <IsFalsy value={hideEdsDiAsOfDate}>
            <IsTruthy value={asOfDate} validationFunction={isDefined}>
              <p id="as_of_date_value" className="copy-md mm-spacing">
                {`As of ${asOfDate}`}
              </p>
            </IsTruthy>
            <IsFalsy value={asOfDate} validationFunction={isDefined}>
              <p id="as_of_date_value" className="copy-md mm-spacing">
                {AS_OF_DATE_UNAVAILABLE}
              </p>
            </IsFalsy>
          </IsFalsy>
        </IsTruthy>
        <hr className="mm-spacing" />
        <div id="printButton" className="justify-content-end">
          <button
            onClick={() => printPDP()}
            data-bp-link="default"
            className="btn btn-inline hide-print"
            aria-label="Print"
            type="button"
          >
            <span className="icon-print" />
            <span>Print</span>
          </button>
        </div>
        <ScrollSpy
          navClass="col-md-3 sn-scrollspy_navigation"
          contentClass="col-md-9 sn-scrollspy_content"
          behavior="smooth"
          placement="auth"
        >
          <div name="Policy Details" data-id="policyDetails">
            <Detail
              id="policyDetails"
              policyId={policyId}
              agreementKey={agreementKey}
              agreementRequest={agreementRequest}
              email={email}
            />
          </div>
          <div name="Coverage/Riders" data-id="coverageAndRiders">
            <CoverageAndRiders
              id="coverageAndRiders"
              agreementKey={agreementKey}
              agreementRequest={agreementRequest}
            />
          </div>
          <div name="People On Policy" data-id="peopleOnPolicy">
            <PeopleOnPolicy
              id="peopleOnPolicy"
              agreementRequest={agreementRequest}
              policyId={policyId}
              producerData={producersRequest?.data}
            />
          </div>
          <div name="Financial History" data-id="financialHistory">
            <FinancialHistorySection
              id="financial-History"
              agreementKey={agreementKey}
              agreementRequest={agreementRequest}
            />
          </div>
          <div name="Billing/Payments" data-id="billing-Payment">
            <Billing
              id="billing-Payment"
              agreementKey={agreementKey}
              agreementRequest={agreementRequest}
              policyId={policyId}
              billingRequest={billingRequest}
              email={email}
            />
          </div>
          <div name="Dividends" data-id="dividends">
            <Dividends
              id="dividends"
              agreementKey={agreementKey}
              agreementRequest={agreementRequest}
              policyId={policyId}
              billingRequest={billingRequest}
            />
          </div>
          <div name="Service History" data-id="serviceHistory">
            <ServiceHistory
              id="serviceHistory"
              agreementKey={agreementKey}
              agreementRequest={agreementRequest}
              serviceRequest={serviceHistoryRequest}
              policyId={policyId}
              email={email}
            />
          </div>
          <div name="Documents" data-id="documents">
            <Documents
              id="documents"
              agreementKey={agreementKey}
              agreementRequest={agreementRequest}
              policyId={policyId}
              email={email}
            />
          </div>
        </ScrollSpy>
      </LoadingSection>
      <Modal
        show={isDrawerOpened}
        onHide={closeDrawer}
        className="modal--drawer"
      >
        <Modal.Header>
          <button
            onClick={closeDrawer}
            type="button"
            className="close"
            aria-label="Close"
          />
        </Modal.Header>
        <Modal.Body id="sideDrawer">
          <PolicyAndContactInfoDrawer
            policyId={policyId}
            agreementRequest={agreementRequest}
            producerData={producersRequest?.data}
            customer={customer}
            producerRequest={producersRequest}
            billingRequest={billingRequest}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default React.memo(DisabilityPolicyDetails);
