import React from "react";
import Table from "../../../common/table";
import IsTruthy from "../../../common/logic/isTruthy";
import { useBillingHook } from "./hooks";
import FeatureFlag from "../../../common/featureFlags";
import {
  isCoverpathPolicy,
  isLifeNonTerm,
  isPallmPolicy,
  isTermOrEdsDiPolicy,
  isULUVPolicy,
} from "../../../../utils/policies";
import IsFalsy from "../../../common/logic/isFalsy";
import { isDefined } from "../../../../utils/evaluate";
import LoadingSection, {
  errorTypes,
  loadingTypes,
} from "../../../common/loading";
import SiprOpmRiderSection from "./riderWithPremiumSections/siprOpmRiderSection";
import LisrOpmRiderSection from "./riderWithPremiumSections/lisrOpmRiderSection";
import AlirVntg1RiderSection from "./riderWithPremiumSections/alirVntg1RiderSection";
import PalirVntg1RiderSection from "./riderWithPremiumSections/palirVntg1RiderSection";
import AlirOpmRiderSection from "./riderWithPremiumSections/alirOpmRiderSection";
import LisrVntg1RiderSection from "./riderWithPremiumSections/lisrVntg1RiderSection";
import AlirHapRiderSection from "./riderWithPremiumSections/alirHapRiderSection";
import LisrHapRiderSection from "./riderWithPremiumSections/lisrHapRiderSection";
import { InformationPopover } from "../../../common/popovers/informationPopover";
import { compareStrings } from "../../../../utils/string";
import {
  LIFE_DI_GRACE_PERIOD_TOOLTIP_TEXT,
  LIFE_GRACE_PERIOD_TOOLTIP_TEXT,
  LISR_UNAVAILABLE_VALUE_TOOLTIP_TEXT,
  LISR_UNAVAILABLE_VALUE_PRINT_TEXT,
} from "../../../../constants/ui";
import AutopayInformation from "./autopayInformation";

const Billing = (props) => {
  const { id, agreementRequest, billingRequest, annuityRequest } = props;

  const {
    modal: {
      showModal,
      showPaymentOptionsModal,
      showDaysThirtyTwoToSixtyTwoModal,
      showGracePeriodModal,
    },
    table: { optionRows, optionColumns },
    data,
    isLSTPolicy,
    showGroupDetails,
    groupName,
    contactUsNumber,
    contactUsTime,
    paidToDateAgreement,
    loanInterestAmountDue,
    loanInterestDueDate,
    siprOpmRiders,
    lisrOpmRiders,
    alirVntg1Riders,
    palirVntg1Riders,
    alirOpmRiders,
    lisrVntg1Riders,
    alirHapRiders,
    lisrHapRiders,
    showAdditionalRiderPremiums,
    ShowDaysThirtyTwoToSixtyTwoSection,
    showFrequencyOptionsModal,
    isPolicyTermHap,
    annualPaymentAmount,
    IS_DISABILITY_POLICY,
    discountPercent,
    groupAccountId,
    agreementKeyAdmin,
    variableAnnuity,
    billingModeDesc,
    billTypeDesc,
    billedToDate,
    invoiceAccountNbr,
    formattedTotalBilledPremium,
    formattedTotalContributions,
    artistryAnnuity,
    odysseyAnnuity,
    artistryTable: { columns, rows },
    showBillingAndPayments,
    showBilledToDate,
    showVAFranchiseNumber,
    franchiseNumberLabel,
    displayBillTypeToolTip,
    isLockboxAddressRemoved,
    lineOfBusinessCode,
    hideLisrBilledPremiumValues,
    hideLisrAlirBilledPremiumValues,
  } = useBillingHook(props);

  const {
    billFrequency,
    paymentPreferenceDesc,
    billDeliveryPreference,
    billedPremium,
    gracePeriod,
    additionalPaymentPeriod,
    paidToDate,
    invoiceFranchiseNumber,
    apmNumber,
    netPremiumAmountToDate,
    displayAutoPayEnabled,
    autoPayEnabled,
  } = data;

  if (variableAnnuity || odysseyAnnuity) {
    return (
      <div className="mm-spacing">
        <h2 aria-label="Billing/Payments" id={id}>
          Billing/Payments
        </h2>
        <IsTruthy value={showBillingAndPayments}>
          <LoadingSection
            request={annuityRequest}
            loadingType={loadingTypes.PARAGRAPH}
            errorType={errorTypes.SECTION}
          >
            <FeatureFlag flagName={["stable", "policyDetails", "billing"]}>
              <div className="mm-spacing--minor">
                <hr />
                <div className="row">
                  <div className="col-12 col-md-6">
                    <dl className="mm-key-value-pair">
                      <dt id="billing_frequency_lbl">Billing Frequency</dt>
                      <dd
                        id="billing_frequency_value"
                        aria-label={billingModeDesc}
                      >
                        {billingModeDesc}
                      </dd>
                      <dt id="bill_type_lbl">Bill Type</dt>
                      <dd id="bill_type_value">
                        {billTypeDesc}
                        <IsTruthy value={displayBillTypeToolTip}>
                          <sup className="print-only">1</sup>
                          <InformationPopover
                            popoverId="massmutual_one_year_renewal_tooltip"
                            text={`Bill Type is unavailable. Please contact our Service Center at <a href="tel:1-800-272-2216">1-800-272-2216</a>, Mon - Fri 8 a.m. - 8 p.m. ET.`}
                          />
                        </IsTruthy>
                      </dd>
                    </dl>
                  </div>
                  <div className="col-12 col-md-6">
                    <dl className="mm-key-value-pair">
                      <IsFalsy value={artistryAnnuity}>
                        <dt id="billed_premium_lbl">Billed Premium</dt>
                        <dd id="billed_premium_value">
                          {formattedTotalBilledPremium}
                        </dd>
                      </IsFalsy>
                      <IsTruthy value={showBilledToDate}>
                        <dt id="billed_to_date_lbl">Billed to Date</dt>
                        <dd id="billed_to_date_value">{billedToDate}</dd>
                      </IsTruthy>
                      <IsFalsy value={artistryAnnuity}>
                        <dt id="total_contributions_lbl">
                          Total Contributions
                        </dt>
                        <dd id="total_contributions_value">
                          {formattedTotalContributions}
                        </dd>
                      </IsFalsy>
                      <IsTruthy value={showVAFranchiseNumber}>
                        <dt id="franchise_number_lbl">
                          {franchiseNumberLabel}
                        </dt>
                        <dd id="franchise_number_value">{invoiceAccountNbr}</dd>
                      </IsTruthy>
                    </dl>
                  </div>
                </div>
              </div>
              <IsTruthy value={displayBillTypeToolTip}>
                <ol className="print-only">
                  <li>
                    Bill Type is unavailable. Please contact our Service Center
                    at 1-800-272-2216, Mon - Fri 8 a.m. - 8 p.m. ET.
                  </li>
                </ol>
              </IsTruthy>
              <hr />
              <div className="mm-spacing--minor">
                <IsTruthy value={artistryAnnuity}>
                  <Table
                    id="Billing/Payments"
                    tableClass="mm-spacing--none"
                    columns={columns}
                    rows={rows}
                    showPagination={false}
                  />
                </IsTruthy>
              </div>

              <p className="hide-print" id="billing_view_payments_lbl">
                For addresses and cash transfer information for this plan,{" "}
                <IsTruthy value={isLockboxAddressRemoved}>
                  please contact our Service Center at{" "}
                  <a href={`tel:+${contactUsNumber}`}>{contactUsNumber}</a>,{" "}
                  {contactUsTime}.
                </IsTruthy>
                <IsFalsy value={isLockboxAddressRemoved}>
                  <button
                    id="billing_view_payments_btn"
                    onClick={showPaymentOptionsModal}
                    className="btn btn-inline"
                    type="button"
                  >
                    view Payment Options.
                  </button>
                </IsFalsy>
              </p>
            </FeatureFlag>
          </LoadingSection>
        </IsTruthy>
        <IsFalsy value={showBillingAndPayments}>
          <div className="alert alert-info" role="alert">
            The information is not available online for this contract.
          </div>
        </IsFalsy>
      </div>
    );
  }

  return (
    <div className="mm-spacing">
      <h2 aria-label="Billing/Payments" id={id}>
        Billing/Payments
      </h2>
      <LoadingSection
        request={billingRequest}
        loadingType={loadingTypes.PARAGRAPH}
        errorType={errorTypes.SECTION}
      >
        <FeatureFlag flagName={["stable", "policyDetails", "billing"]}>
          <div className="mm-spacing--minor">
            <hr />
            <div className="row">
              <div className="col-12 col-md-6">
                <dl className="mm-key-value-pair">
                  <dt id="billing_frequency_lbl">Billing Frequency</dt>
                  <dd id="billing_frequency_value" aria-label={billFrequency}>
                    <IsTruthy value={showFrequencyOptionsModal}>
                      <button
                        id="billing_frequency_btn"
                        onClick={showModal}
                        className="btn btn-inline"
                        type="button"
                      >
                        <IsTruthy
                          value={billFrequency}
                          validationFunction={isDefined}
                        >
                          {billFrequency}
                        </IsTruthy>
                      </button>
                      <IsFalsy
                        value={billFrequency}
                        validationFunction={isDefined}
                      >
                        Unavailable
                      </IsFalsy>
                    </IsTruthy>
                    <IsFalsy value={showFrequencyOptionsModal}>
                      <IsTruthy
                        value={billFrequency}
                        validationFunction={isDefined}
                      >
                        {billFrequency}
                      </IsTruthy>
                      <IsFalsy
                        value={billFrequency}
                        validationFunction={isDefined}
                      >
                        Unavailable
                      </IsFalsy>
                    </IsFalsy>
                  </dd>
                  <dt id="billing_payment_preference_lbl">
                    Payment Preference
                  </dt>
                  <dd
                    id="billing_payment_preference_value"
                    aria-label={paymentPreferenceDesc}
                  >
                    {paymentPreferenceDesc}
                  </dd>
                  <IsTruthy value={displayAutoPayEnabled}>
                    <dt id="billing_is_on_autopay_lbl">Autopay</dt>
                    <dd
                      id="billing_is_on_autopay_value"
                      aria-label={`Auto Pay ${autoPayEnabled}`}
                    >
                      {autoPayEnabled}
                    </dd>
                  </IsTruthy>
                  <IsTruthy
                    value={props}
                    validationFunction={isTermOrEdsDiPolicy}
                  >
                    <IsFalsy
                      value={agreementRequest.data}
                      validationFunction={isPallmPolicy}
                    >
                      <IsFalsy
                        value={agreementKeyAdmin}
                        validationFunction={isCoverpathPolicy}
                      >
                        <dt id="billing_payment_annual_payment_amt_lbl">
                          Annualized Premium
                        </dt>
                        <dd
                          id="billing_payment_annual_payment_amt_value"
                          aria-label={annualPaymentAmount}
                        >
                          {annualPaymentAmount}
                        </dd>
                      </IsFalsy>
                    </IsFalsy>
                  </IsTruthy>

                  <IsTruthy
                    value={agreementRequest.data}
                    validationFunction={isPallmPolicy}
                  >
                    <dt id="billing_payment_annual_payment_amt_lbl">
                      Annualized Premium
                    </dt>
                    <dd
                      id="billing_payment_annual_payment_amt_value"
                      aria-label={annualPaymentAmount}
                    >
                      Unavailable
                    </dd>
                  </IsTruthy>
                  <IsTruthy value={props} validationFunction={isULUVPolicy}>
                    <dt id="total_premiums_paid_lbl">Total Premiums Paid</dt>
                    <dd
                      id="total_premiums_paid_value"
                      aria-label={netPremiumAmountToDate}
                    >
                      {netPremiumAmountToDate}
                    </dd>
                  </IsTruthy>
                  <IsFalsy value={IS_DISABILITY_POLICY}>
                    <IsTruthy
                      value={paymentPreferenceDesc}
                      validationFunction={isLSTPolicy}
                    >
                      <dt id="billing_invoice_or_fanchise_lbl">
                        Invoice/Franchise Number
                      </dt>
                      <dd
                        id="billing_invoice_or_fanchise_value"
                        aria-label={invoiceFranchiseNumber}
                      >
                        {invoiceFranchiseNumber}
                      </dd>
                    </IsTruthy>
                  </IsFalsy>
                  <IsTruthy
                    value={agreementRequest}
                    validationFunction={isLifeNonTerm}
                  >
                    <dt id="loan_interest_billed_amount_lbl">
                      Interest Billed Amount
                    </dt>
                    <dd id="loan_interest_billed_amount_value">
                      {loanInterestAmountDue}
                    </dd>
                  </IsTruthy>
                </dl>
              </div>
              <div className="col-12 col-md-6">
                <dl className="mm-key-value-pair">
                  <dt id="billing_billed_premium_lbl">Billed Premium</dt>
                  <dd
                    id="billing_billed_premium_value"
                    aria-label={billedPremium}
                  >
                    {billedPremium}
                    <IsTruthy value={hideLisrBilledPremiumValues}>
                      <sup className="print-only">1</sup>{" "}
                      <InformationPopover
                        popoverId="billed_premium_unavailable_tooltip"
                        text={LISR_UNAVAILABLE_VALUE_TOOLTIP_TEXT}
                      />
                    </IsTruthy>
                  </dd>
                  <dt id="billing_delivery_preference_lbl">
                    Bill Delivery Preference
                  </dt>
                  <dd
                    id="billing_delivery_preference_value"
                    aria-label={billDeliveryPreference}
                  >
                    {billDeliveryPreference}
                  </dd>
                  <IsTruthy value={props} validationFunction={isULUVPolicy}>
                    <dt id="billed_to_date_lbl">Billed to Date</dt>
                    <dd id="billed_to_date_value">{paidToDateAgreement}</dd>
                  </IsTruthy>
                  <IsFalsy value={IS_DISABILITY_POLICY}>
                    <IsTruthy
                      value={paymentPreferenceDesc}
                      validationFunction={isLSTPolicy}
                    >
                      <dt id="billing_apm_number_lbl">APM Number</dt>
                      <dd id="billing_apm_number_value" aria-label={apmNumber}>
                        {apmNumber}
                      </dd>
                    </IsTruthy>
                  </IsFalsy>
                  <IsTruthy
                    value={agreementRequest}
                    validationFunction={isLifeNonTerm}
                  >
                    <dt id="loan_billed_due_date_lbl">
                      Interest Billed Due Date
                    </dt>
                    <dd id="loan_billed_due_date_value">
                      {loanInterestDueDate}
                    </dd>
                  </IsTruthy>
                </dl>
              </div>
            </div>
            <ol id="billing_payments_footnotes" className="print-only">
              <IsTruthy value={hideLisrBilledPremiumValues}>
                <li>{LISR_UNAVAILABLE_VALUE_PRINT_TEXT}</li>
              </IsTruthy>
            </ol>
            <hr />
          </div>
          <IsTruthy value={showGroupDetails}>
            <h3 id="group_details_title">Group Details</h3>
            <hr />
            <div className="row">
              <div className="col-12 col-md-6">
                <dl className="mm-key-value-pair">
                  <dt id="group_name_lbl">Group Name</dt>
                  <dd id="group_name_value">{groupName}</dd>
                  <dt id="group_number_lbl">Group Number</dt>
                  <dd id="group_number_value">{groupAccountId}</dd>
                </dl>
              </div>
              <div className="col-12 col-md-6">
                <dl className="mm-key-value-pair">
                  <dt id="discount_lbl">Discount</dt>
                  <dd id="discount_value">{discountPercent}</dd>
                  <dt id="consolidated_bill_type_lbl">
                    Consolidated Bill Type
                  </dt>
                  <dd id="consolidated_bill_type_value">Unavailable</dd>
                </dl>
              </div>
            </div>
            <hr />
          </IsTruthy>
          <AutopayInformation
            data={data}
            agreementKeyAdmin={agreementKeyAdmin}
          />

          <IsTruthy value={showAdditionalRiderPremiums}>
            <h3>Riders with Premiums</h3>
            <hr />
            <IsTruthy value={siprOpmRiders.length > 0}>
              <SiprOpmRiderSection
                coverages={siprOpmRiders}
                billingData={billingRequest.data}
              />
            </IsTruthy>
            <IsTruthy value={lisrOpmRiders.length > 0}>
              <LisrOpmRiderSection riders={lisrOpmRiders} />
            </IsTruthy>
            <IsTruthy value={alirVntg1Riders.length > 0}>
              <AlirVntg1RiderSection
                riders={alirVntg1Riders}
                hideLisrAlirBilledPremiumValues={
                  hideLisrAlirBilledPremiumValues
                }
              />
            </IsTruthy>
            <IsTruthy value={palirVntg1Riders.length > 0}>
              <PalirVntg1RiderSection riders={palirVntg1Riders} />
            </IsTruthy>
            <IsTruthy value={alirOpmRiders.length > 0}>
              <AlirOpmRiderSection riders={alirOpmRiders} />
            </IsTruthy>
            <IsTruthy value={lisrVntg1Riders.length > 0}>
              <LisrVntg1RiderSection
                riders={lisrVntg1Riders}
                hideLisrBilledPremiumValues={hideLisrBilledPremiumValues}
              />
            </IsTruthy>
            <IsTruthy value={lisrHapRiders.length > 0}>
              <LisrHapRiderSection
                riders={lisrHapRiders}
                hideLisrBilledPremiumValues={hideLisrBilledPremiumValues}
              />
            </IsTruthy>
            <IsTruthy value={alirHapRiders.length > 0}>
              <AlirHapRiderSection
                riders={alirHapRiders}
                hideLisrAlirBilledPremiumValues={
                  hideLisrAlirBilledPremiumValues
                }
              />
            </IsTruthy>
          </IsTruthy>
          <IsFalsy value={isPolicyTermHap || IS_DISABILITY_POLICY}>
            <div className="mm-spacing--minor">
              <h3 id="dividends_options_lbl">Payment Options</h3>
              <Table
                id="options"
                tableClass="mm-spacing--none"
                columns={optionColumns}
                rows={optionRows}
                showPagination={false}
              />
            </div>
          </IsFalsy>
          <div className="mm-spacing--minor">
            <IsFalsy value={props} validationFunction={isULUVPolicy}>
              <h3 id="billing_dates_lbl">Dates</h3>
              <hr />
              <div className="row">
                <div className="col-12 col-md-6">
                  <dl className="mm-key-value-pair">
                    <dt id="paid_to_date_lbl">Paid to Date</dt>
                    <dd id="paid_to_date_value">{paidToDate}</dd>
                    <IsTruthy value={ShowDaysThirtyTwoToSixtyTwoSection}>
                      <dt
                        className="hide-print"
                        id="additional_payment_period_lbl"
                      >
                        Days 32-62{" "}
                        <button
                          type="button"
                          className="btn btn-inline"
                          onClick={showDaysThirtyTwoToSixtyTwoModal}
                        >
                          <span
                            className="icon-help icon-sm"
                            id="icon-days_32_to_62"
                            role="button"
                          >
                            <span className="sr-only">Days 32-62 modal</span>
                          </span>
                        </button>
                      </dt>
                      <dd
                        className="hide-print"
                        id="additional_payment_period_value"
                      >
                        {additionalPaymentPeriod}
                      </dd>
                    </IsTruthy>
                  </dl>
                </div>
                <div className="col-12 col-md-6">
                  <dl className="mm-key-value-pair">
                    <dt id="grace_period_lbl">
                      Grace Period{" "}
                      <button
                        type="button"
                        className="btn btn-inline"
                        onClick={showGracePeriodModal}
                      >
                        <span
                          className="icon-help icon-sm"
                          id="icon-grace-period"
                          role="button"
                        >
                          <span className="sr-only">Grace Period modal</span>
                        </span>
                      </button>
                    </dt>
                    <dd id="grace_period__value">
                      {gracePeriod} <sup className="print-only">1</sup>
                    </dd>
                  </dl>
                </div>
              </div>
              <ol id="print_only_footnotes" className="print-only">
                <li>{LIFE_DI_GRACE_PERIOD_TOOLTIP_TEXT}</li>
                <IsTruthy
                  value={lineOfBusinessCode}
                  validationFunction={(val) => compareStrings(val, "LIFE")}
                >
                  {LIFE_GRACE_PERIOD_TOOLTIP_TEXT}
                </IsTruthy>
              </ol>
              <hr />
            </IsFalsy>
            <IsFalsy value={isPolicyTermHap}>
              <p className="hide-print" id="billing_view_payments_lbl">
                For addresses and cash transfer information for this plan,{" "}
                <button
                  id="billing_view_payments_btn"
                  onClick={showPaymentOptionsModal}
                  className="btn btn-inline"
                  type="button"
                >
                  view Payment Options.
                </button>
              </p>
            </IsFalsy>
          </div>
        </FeatureFlag>
      </LoadingSection>
    </div>
  );
};

export default React.memo(Billing);
