import { isAfterDate, isBeforeDate } from "./dates";
import { types } from "../reducers/formReducer";
import { isDefined } from "./evaluate";

export const checkAndSetSelectedDate = (
  date,
  datePickerMinDate,
  datePickerMaxDate,
  setSelectedDate,
  dispatchForm,
  formField
) => {
  let selected = date;
  if (isBeforeDate(date, datePickerMinDate)) {
    selected = datePickerMinDate;
  }
  if (isAfterDate(date, datePickerMaxDate)) {
    selected = datePickerMaxDate;
  }
  setSelectedDate(selected);
  if (isDefined(formField) && isDefined(dispatchForm)) {
    dispatchForm({
      type: types.UPDATE_FIELD_VALUE,
      formField,
      newValue: selected,
      postpone: false,
    });
  }
};
