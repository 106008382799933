import React, { useEffect } from "react";
import { setTitle } from "../../../../utils/dom";
import Table from "../../../common/table";
import TableControl from "../../../common/table/tableControl";
import { useSearchHistoryProps } from "./hooks";
import LoadingSection, {
  errorTypes,
  loadingTypes,
} from "../../../common/loading";
import FiveHundredOneError from "../../../common/errors/501";
import FourHundredFourError from "../../../common/errors/404";
import IsTruthy from "../../../common/logic/isTruthy";
import { tealiumPageViewEvent } from "../../../../utils/tealium";
import IsFalsy from "../../../common/logic/isFalsy";
import BackToPolicy from "../../../common/backToPolicy";

const ViewServiceHistory = (props) => {
  setTitle("ServiceNet - Service History");
  const {
    requests: { agreementRequest, catsRequest },
    policyId,
    agreementKey,
    table: { columns, rows },
    searchBar: { searchString, showClearIcon, searchTable, clearSearch },
    insuredName,
    isUnauthorized,
    insuredNameLabel,
    policyNoLabel,
    email,
    validRequest,
    isLoading,
    jointOwnerName,
    jointOwnerNameLabel,
    displayJointOwner,
  } = useSearchHistoryProps(props);
  useEffect(() => {
    tealiumPageViewEvent({
      page: "View Service History",
      category: agreementKey,
      custId: email,
    });
  }, []);

  if (isUnauthorized) {
    return <FiveHundredOneError />;
  }

  return (
    <LoadingSection
      request={agreementRequest}
      loadingType={loadingTypes.PARAGRAPH}
      errorType={errorTypes._500}
    >
      <LoadingSection
        request={catsRequest}
        loadingType={loadingTypes.PARAGRAPH}
        errorType={errorTypes._500}
      >
        <LoadingSection
          request={{ isLoading }}
          loadingType={loadingTypes.PARAGRAPH}
          errorType={errorTypes._500}
        >
          <IsFalsy value={validRequest}>
            <FourHundredFourError />
          </IsFalsy>
          <IsTruthy value={validRequest}>
            <BackToPolicy
              agreementData={agreementRequest.data}
              jumpLink="serviceHistory"
            />
            <h1 id="service_history_header_lbl"> Service History </h1>

            <div className="row mm-spacing">
              <div className="col-md-6 col-sm-12">
                <dl className="mm-key-value-pair--summation">
                  <dt id="insured_name_lbl">{insuredNameLabel}</dt>
                  <dd id="insured_name_value">{insuredName}</dd>
                  <IsTruthy value={displayJointOwner}>
                    <dt id="insured_name_lbl">{jointOwnerNameLabel}</dt>
                    <dd id="insured_name_value">{jointOwnerName}</dd>
                  </IsTruthy>
                  <dt id="policy_id_lbl">{policyNoLabel}</dt>
                  <dd id="policy_id_value">{policyId}</dd>
                </dl>
              </div>
            </div>
            <TableControl
              searchString={searchString}
              searchTable={searchTable}
              showClearIcon={showClearIcon}
              clearSearch={clearSearch}
              placeHolderText="Search"
            />
            <div className="row">
              <div className="col-sm">
                <Table
                  columns={columns}
                  rows={rows}
                  defaultSortColumn="dateReceived"
                />
              </div>
            </div>
          </IsTruthy>
        </LoadingSection>
      </LoadingSection>
    </LoadingSection>
  );
};
export default React.memo(ViewServiceHistory);
