import React from "react";
import { setTitle } from "../../../utils/dom";
import { fireTealiumAndFollowLink } from "../../../utils/tealium";
import CreateNameList from "../../common/nameList/createNameList";
import { useConfirmationProps } from "../hooks";

const Confirmation = (props) => {
  setTitle("ServiceNet - Billing Frequency Change Confirmation");
  const {
    agreementKey,
    userEmail,
    policyId,
    productTypeName,
    insured,
    owners,
    currentBillFrequency,
    newBillFrequency,
    financialProfessionalName,
    financialProfessionalEmail,
    selectedDate,
    currentDateTimeAndTimezone,
  } = useConfirmationProps(props);

  return (
    <div>
      <div className="row">
        <div className="col-md-9">
          <h1 className="h2">
            <picture>
              <span
                role="img"
                className="illustration-confetti hide-print"
                title="Success Confetti"
              />
            </picture>
            Thank you! Your Frequency Change/PAC Removal Request has been
            submitted.
          </h1>
        </div>
        <div className="col-3">
          <div id="printButton" className="justify-content-end">
            <button
              onClick={() => window.print()}
              data-bp-link="default"
              className="btn btn-inline hide-print"
              aria-label="Print"
              type="button"
            >
              <span className="icon-print" />
              <span>Print</span>
            </button>
          </div>
        </div>
      </div>
      <p className="mm-spacing--minor">
        The Frequency Change Request has been successfully submitted for
        processing. Please review Service History for processing time and
        updates. If you have any questions regarding this request, please call
        us at 1-800-272-2216 8:00 AM - 8:00 PM ET, Monday through Friday and
        reference the policy number.
      </p>
      <p className="mm-spacing--minor">
        Fractional premium(s) may be due as a result of a change in the payment
        frequency. If so, the fractional premium(s) must be paid before the new
        frequency selected becomes effective.
      </p>
      <hr className="mm-spacing" />
      <div className="row mm-spacing--minor">
        <div className="col-md-7">
          <dl className="mm-key-value-pair">
            <dt id="confirmation_frequency_change_lbl">Frequency Change</dt>
            <dd id="confirmation_frequency_change_value">
              <b>{`From ${currentBillFrequency} to ${newBillFrequency}`}</b>
            </dd>
            <dt id="confirmation_policy_number_to_lbl">Policy Number</dt>
            <dd id="confirmation_policy_number_to_value">{policyId}</dd>
            <dt id="confirmation_product_name_lbl">Product Name</dt>
            <dd id="confirmation_product_name_value">{productTypeName}</dd>
            <dt id="confirmation_owner_name_lbl">Owner Name</dt>
            <dd id="confirmation_owner_name_value">
              <CreateNameList list={owners} />
            </dd>
            <dt id="confirmation_insured_name_lbl">Insured Name</dt>
            <dd id="confirmation_insured_name_value">
              <CreateNameList list={insured} />
            </dd>
            <dt id="confirmation_recipient_lbl">Financial Professional</dt>
            <dd id="confirmation_recipient_value">
              {financialProfessionalName}
            </dd>
            <dt id="confirmation_contact_information_lbl">
              Financial Professional Email
            </dt>
            <dd id="confirmation_contact_information_value">
              {financialProfessionalEmail}
            </dd>
            <dt id="confirmation_request_dateTime_lbl">Request Date/Time</dt>
            <dd id="confirmation_request_dateTime_value">
              {currentDateTimeAndTimezone}
            </dd>
            <dt id="confirmation_effective_date_lbl">Selected Start Date</dt>
            <dd id="confirmation_effective_date_value">{selectedDate}</dd>
          </dl>
        </div>
      </div>
      <button
        type="button"
        id="confirmation_serviceHistory_link_btn"
        data-bp-link="default"
        onClick={() =>
          fireTealiumAndFollowLink({
            url: `/policyDetails/${encodeURIComponent(
              agreementKey
            )}/viewServiceHistory`,
            action: "View Service History link clicked",
            category: "Frequency Change",
            value: policyId,
            custId: userEmail,
          })
        }
        className="btn btn-primary hide-print"
      >
        VIEW SERVICE HISTORY
      </button>
      <a
        href={`/policyDetails/${encodeURIComponent(agreementKey)}`}
        className="btn btn-link hide-print"
      >
        Close
      </a>
    </div>
  );
};

export default React.memo(Confirmation);
