import React from "react";

const LineLoading = () => (
  <div className="mm-skeleton--short mm-spacing">
    <span className="sr-only" aria-busy="true" aria-live="polite">
      Content is loading
    </span>
  </div>
);

export default LineLoading;
