import React, { useEffect, useState } from "react";
import { isDefined } from "../../utils/evaluate";
import IsTruthy from "../common/logic/isTruthy";
import { useFormContext } from "../../contexts/form";
import { useCancelModal } from "../common/popup/cancel";
import { shouldEnableContinueButton, useCanContinue } from "./hooks";
import { supportedTransactions } from "../../utils/translate";

const ModalControl = (props) => {
  const {
    lineOfBusinessCode,
    pageNumber,
    onSubmit,
    shouldDisableSubmit,
    error,
    showLoadingIcon,
  } = props;

  const { openCancelModal } = useCancelModal({
    transactionName: `${supportedTransactions.DirectLinks}`,
    lineOfBusinessCode,
    pageNumber,
  });
  const { formData } = useFormContext();
  const shouldDisableContinue = !shouldEnableContinueButton(
    formData,
    pageNumber
  );
  const { onContinue, continueButtonDisplayText } = useCanContinue(props);

  const [className, setClassName] = useState("btn btn-primary");
  const [buttonText, setButtonText] = useState("Send Direct Link Email");

  useEffect(() => {
    if (showLoadingIcon) {
      setClassName("btn btn-primary-loading");
      setButtonText("");
    } else {
      setClassName("btn btn-primary");
      setButtonText("Send Direct Link Email");
    }
  }, [showLoadingIcon]);

  return (
    <div className="mm-spacing">
      <IsTruthy value={error} validationFunction={isDefined}>
        <div
          className="alert alert-danger mm-spacing--minor"
          role="alert"
          aria-label={error}
        >
          {error}
        </div>
      </IsTruthy>
      <div className="mm-spacing--minor">
        <IsTruthy value={pageNumber < 3}>
          <button
            type="button"
            id="btn-continue"
            className="btn btn-primary"
            onClick={onContinue}
            disabled={shouldDisableContinue}
          >
            {continueButtonDisplayText}
          </button>
        </IsTruthy>
        <IsTruthy value={pageNumber === 3}>
          <button
            type="submit"
            id="btn-submit"
            className={className}
            disabled={shouldDisableSubmit}
            onClick={(e) => {
              e.preventDefault();
              onSubmit(formData);
            }}
          >
            {buttonText}
          </button>
        </IsTruthy>
        <IsTruthy value={pageNumber < 4}>
          <button
            type="button"
            id="btn-cancel"
            className="btn btn-link"
            onClick={openCancelModal}
          >
            Cancel
          </button>
        </IsTruthy>
      </div>
    </div>
  );
};

export default ModalControl;
