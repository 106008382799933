import React from "react";
import { useRidersProps } from "./hooks";
import IsTruthy from "../../../common/logic/isTruthy";
import IsFalsy from "../../../common/logic/isFalsy";
import { NO_RIDER_ALERT_TEXT } from "../../../../constants/ui";
import Table from "../../../common/table";

const Riders = (props) => {
  const { id } = props;
  const {
    data: {
      annuityRiders,
      formattedContactUsNumber,
      formattedContactUsTime,
      isRetireEase,
      table: { columns, rows },
      showAnnuityModal,
    },
  } = useRidersProps(props);

  return (
    <div className="mm-spacing">
      <h2 aria-label="Contract Features" id={id}>
        Contract Features
      </h2>
      <div className="mm-spacing--minor">
        <IsTruthy value={annuityRiders?.length < 1}>
          <hr />{" "}
          <div
            className="alert alert-info mm-spacing"
            role="alert"
            id="no_rider_info_available_alert"
          >
            {NO_RIDER_ALERT_TEXT}
            <a href={`tel:+${formattedContactUsNumber}`}>
              {formattedContactUsNumber}
            </a>
            , {formattedContactUsTime}
          </div>
        </IsTruthy>
        <IsFalsy value={isRetireEase}>
          <IsFalsy value={annuityRiders?.length < 1}>
            <Table
              id="riders"
              columns={columns}
              rows={rows}
              showPagination={false}
              customFunctions={{ showAnnuityModal }}
            />
          </IsFalsy>
        </IsFalsy>
        <IsTruthy value={isRetireEase && !(annuityRiders?.length < 1)}>
          <Table
            id="riders"
            columns={columns}
            rows={rows}
            showPagination={false}
          />
        </IsTruthy>
      </div>
    </div>
  );
};

export default React.memo(Riders);
