import { useParams } from "react-router-dom";
import { useUserState } from "../../../../contexts/user";

export const usePaymentOptionsProps = () => {
  const { agreementKey } = useParams();
  const {
    user: { email },
  } = useUserState();

  return {
    agreementKey,
    email,
  };
};
