import React from "react";
import { useEnvisionFundTransferProps } from "./hooks";
import IsTruthy from "../../common/logic/isTruthy";
import IsFalsy from "../../common/logic/isFalsy";
import Form from "../../common/forms/form";
import { ModalProvider } from "../../../contexts/modal";
import FundTransferHeader from "./pages/fundTransferHeader";
import SelectFunds from "./pages/selectFunds";
import Review from "./pages/review";
import Confirmation from "./pages/confirmation";
import ModalControl from "./modalControl";
import ContractInformation from "./pages/contractInformation";
import FundTransfer from "./pages/fundTransfer";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import LdBannerContent from "../../common/molecules/ldBannerContent";
import FiveHundredError from "../../common/errors/500";

const EnvisionFundTransfer = (props) => {
  const { pageNumber, nextPage, previousPage } = props;
  const {
    agreementRequest,
    error,
    setError,
    shouldDisableSubmit,
    owner,
    jointOwner,
    jointAnnuitant,
    productTypeName,
    policyId,
    marketingName,
    fundValueAmount,
    fundTransferFormFields,
    userEmail,
    onSubmit,
    showErrorPage,
    transactionId,
    errorText,
    showLoadingIcon,
  } = useEnvisionFundTransferProps(props);

  return (
    <>
      <IsTruthy value={showErrorPage}>
        <Header />
        <div className="container">
          <LdBannerContent />
          <FiveHundredError
            turnOffReload
            transactionId={transactionId}
            title={errorText}
            agreementKey={agreementRequest?.data?.agreementKey}
          />
        </div>
        <Footer />
      </IsTruthy>
      <IsFalsy value={showErrorPage}>
        <div className="container">
          <Form fields={fundTransferFormFields}>
            <ModalProvider>
              <FundTransferHeader
                pageNumber={pageNumber}
                previousPage={previousPage}
                shouldDisableSubmit={shouldDisableSubmit}
              />
              <IsTruthy value={pageNumber === 1}>
                <SelectFunds />
                <ContractInformation
                  owner={owner}
                  jointOwner={jointOwner}
                  jointAnnuitant={jointAnnuitant}
                  productTypeName={productTypeName}
                  policyId={policyId}
                />
                <FundTransfer
                  marketingName={marketingName}
                  fundValueAmount={fundValueAmount}
                />
              </IsTruthy>
              <IsTruthy value={pageNumber === 2}>
                <Review
                  owner={owner}
                  jointOwner={jointOwner}
                  productTypeName={productTypeName}
                  policyId={policyId}
                  marketingName={marketingName}
                  fundValueAmount={fundValueAmount}
                />
              </IsTruthy>
              <IsTruthy value={pageNumber === 3}>
                <Confirmation
                  owner={owner}
                  jointOwner={jointOwner}
                  jointAnnuitant={jointAnnuitant}
                  marketingName={marketingName}
                  fundValueAmount={fundValueAmount}
                  productTypeName={productTypeName}
                  policyId={policyId}
                  userEmail={userEmail}
                />
              </IsTruthy>
              <ModalControl
                pageNumber={pageNumber}
                nextPage={nextPage}
                error={error}
                setError={setError}
                onSubmit={onSubmit}
                shouldDisableSubmit={shouldDisableSubmit}
                showLoadingIcon={showLoadingIcon}
              />
            </ModalProvider>
          </Form>
        </div>
      </IsFalsy>
    </>
  );
};

export default React.memo(EnvisionFundTransfer);
