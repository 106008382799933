import React from "react";
import Table from "../../../../common/table";
import CurrentFrequencyCell from "./currentFrequencyCell";
import {
  getBillingModeData,
  getModalPremiumsForPolicy,
  getTrimmedPolicyIdFromAgreement,
} from "../../../../../utils/findData";
import EditBillingFrequencyLink from "../../../../home/editBillingFrequencyLink";

export const FrequencyOptionsModal = (data) => {
  const { columns, rows } = getTableData({ data });
  const { agreementKey, lineOfBusinessCode } = data;
  const { trimmedPolicyId } = getTrimmedPolicyIdFromAgreement(agreementKey);

  return (
    <>
      <div className="modal-body mm-spacing--minor">
        <div className="mm-spacing" />
        <Table columns={columns} rows={rows} showPagination={false} />
        <p>
          Values displayed are based upon current premium amounts and do not
          include riders that are billed separately. Actual amounts may be
          different based on policy conditions at the time of the frequency
          change.
        </p>
        <ol className="mm-list--footnotes">
          <li>
            <strong>Pre-Authorized Checking (PAC)</strong>
            If the policy is removed from PAC, the transaction you are
            requesting may have an impact on commissions.{" "}
            <a
              href="https://www.massmutual.com/efiles/life/pdfs/f6445.pdf"
              target="_blank"
              rel="noreferrer"
              id="form_f6445_link"
            >
              Form F6445{" "}
            </a>
            must be submitted to update or put a policy on Pre-Authorized
            Checking (PAC).
          </li>
        </ol>
      </div>
      <div className="modal-footer">
        <EditBillingFrequencyLink
          agreementKey={agreementKey}
          lineOfBusinessCode={lineOfBusinessCode}
          isEligibilityCheckRequired={false}
          id="frequency_modal_edit_billing_frequency_link"
          className="btn btn-primary"
          policyId={trimmedPolicyId}
          tealiumCategory="Billing"
        />
      </div>
    </>
  );
};

export const getTableData = ({ data }) => {
  const columns = [
    {
      label: "Frequency",
      accessor: "frequency",
      CustomCell: CurrentFrequencyCell,
      alignment: "left",
      dataCell: true,
    },
    { label: "Premium", accessor: "premium", alignment: "left" },
    { label: "Annual Premium", accessor: "annualPremium", alignment: "left" },
    { label: "Modal Charge", accessor: "modalChargeAmount", alignment: "left" },
    { label: "APR", accessor: "aprPercentage", alignment: "left" },
  ];

  const rows = [];
  const { lineOfBusinessCode, agreementKeyAdmin } = data;
  const modalPremiums = getModalPremiumsForPolicy({
    billingData: { ...data },
    agreementData: { agreementKeyAdmin, lineOfBusinessCode },
  });
  const { billingModeData } = getBillingModeData(modalPremiums);
  rows.push({ ...billingModeData.monthlyBillingData });
  rows.push({ ...billingModeData.quarterlyBillingData });
  rows.push({ ...billingModeData.semiAnnualBillingData });
  rows.push({ ...billingModeData.annualBillingData });

  return {
    columns,
    rows,
  };
};

export default FrequencyOptionsModal;
