import React from "react";
import { sanitizeData } from "../../../utils/sanitizeData";

const AgentServiceTrackingModalCell = (props) => {
  const {
    row,
    rowIndex,
    column: { accessor },
    customFunctions,
  } = props;

  return (
    <button
      id={`${accessor}_${rowIndex}_btn`}
      className="btn btn-inline"
      type="button"
      onClick={customFunctions.showServiceEventModalModal(row)}
    >
      <span
        id={`${accessor}_${rowIndex}_value`}
        dangerouslySetInnerHTML={{ __html: sanitizeData(row[accessor]) }}
      />
    </button>
  );
};

export default AgentServiceTrackingModalCell;
