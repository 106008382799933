import React from "react";
import EmailContact from "./emailContact";
import { useCheckEligibility } from "../hooks";

const CheckEligibility = (props) => {
  const { ownerName, userEligibilityResult } = props;

  useCheckEligibility(props);

  return (
    <>
      <div className="mm-spacing">
        <p id="header-intro" className="mm-sub-copy">
          Please enter the email address your client would like to use to set up
          their MassMutual.com account. We’ll verify their eligibility by
          checking for qualifying products, ensuring their email isn’t already
          associated with another account, and confirming that we have all
          necessary information. Once the eligibility check is complete, we’ll
          guide you on next steps.
        </p>
      </div>
      <div className="row mm-spacing">
        <div className="col-12 col-lg-3 col-md-3 mm-spacing-minor--stacked">
          <p d="label-owner-fullname">
            <strong>Owner Full Name</strong>
          </p>
          <p id="owner-fullname">{ownerName}</p>
        </div>
        <EmailContact userEligibilityResult={userEligibilityResult} />
      </div>
    </>
  );
};
export default React.memo(CheckEligibility);
