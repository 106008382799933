import React from "react";
import ServiceNetLogo from "../../images/ServiceNetLogoWhite.svg";
// eslint-disable-next-line import/no-cycle
import HeaderHelpDropdown from "../headerHelpDropdown";
import { useUserState } from "../../contexts/user";
import IsTruthy from "../common/logic/isTruthy";
import HeaderNotificationsDropdown from "../notifications/common/headerNotificationsDropdown";

const Header = (props) => {
  const {
    user: { firstName, lastName },
  } = useUserState();
  const { displayDataIntegrityDropdown, displayNotificationsDropdown } = props;

  const userName = `${firstName} ${lastName}`;

  return (
    <nav role="navigation" className="mm-navbar--auth">
      <div className="navbar navbar-expand-lg">
        <div className="container">
          <a id="home_img_link" className="navbar-brand" href="/">
            <img
              id="mm_logo_header_img"
              alt="MassMutual Logo"
              src={ServiceNetLogo}
            />
          </a>
          <div className="navbar-nav">
            {" "}
            <IsTruthy value={displayDataIntegrityDropdown}>
              <HeaderHelpDropdown user={{ firstName, lastName }} />
            </IsTruthy>
            <IsTruthy value={displayNotificationsDropdown}>
              <HeaderNotificationsDropdown />
            </IsTruthy>
            <span className="navbar-text mm-desktop-only" id="logged_in_user">
              {userName}
            </span>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default React.memo(Header);
