import React from "react";
import { formatAlirOpm } from "./dividendHelper";

const AlirOpmSection = (props) => {
  const { coverages } = props;
  const {
    dividendTypeDescription,
    nameField,
    currentDividendEarned,
    coverageTotalPUADividend,
    currentPUADividend,
    coverageTotalPUAPremium,
    currentPUAPremium,
  } = formatAlirOpm(coverages);

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <dl className="mm-key-value-pair">
          <dt id="alir_rider_name_label">Rider Name</dt>
          <dd id="alir_rider_name_value">{nameField}</dd>
          <dt id="alir_current_div_earned_label">
            ALIR Current Dividend Earned
          </dt>
          <dd id="alir_current_div_earned_value">{currentDividendEarned}</dd>
          <dt id="alir_dividend_paid_up_additions_face_amount_curr_year_label">
            ALIR Dividend Paid Up Additions Face Amount - Current Year
          </dt>
          <dd id="alir_dividend_paid_up_additions_face_amount_curr_year_value">
            {currentPUADividend}
          </dd>
          <dt id="alir_payment_paid_up_additions_face_amount_curr_year_label">
            ALIR Payment Paid Up Additions Face Amount - Current year
          </dt>
          <dd id="alir_payment_paid_up_additions_face_amount_curr_year_vale">
            {currentPUAPremium}
          </dd>
        </dl>
      </div>
      <div className="col-12 col-md-6">
        <dl className="mm-key-value-pair">
          <dt id="dividend_option_label">Dividend Option</dt>
          <dd id="dividend_option_value">{dividendTypeDescription}</dd>
          <dt id="alir_dividend_paid_up_additions_face_amount_total_label">
            ALIR Dividend Paid Up Additions Face Amount - Total
          </dt>
          <dd id="alir_dividend_paid_up_additions_face_amount_total_value">
            {coverageTotalPUADividend}
          </dd>
          <dt id="alit_payment_paid_up_additions_face_amount_total_label">
            ALIR Payment Paid Up Additions Face Amount - Total
          </dt>
          <dd id="alit_payment_paid_up_additions_face_amount_total_value">
            {coverageTotalPUAPremium}
          </dd>
        </dl>
      </div>
    </div>
  );
};

export default React.memo(AlirOpmSection);
