import {
  checkIfTransactionIsAllowed,
  isPolicyInForce,
} from "../../utils/policies";
import { compareStrings } from "../../utils/string";
import { supportedTransactions } from "../../utils/translate";

export const verifyPolicyCoverageSummaryLink = (props) => {
  const { agreementData, transactionSupportedPolicies } = props;

  const { isTransactionAllowed } = checkIfTransactionIsAllowed({
    transactionSupportedPolicies,
    transactionName: supportedTransactions.PolicyCoverageSummary,
    agreementData,
  });

  const returnObject = {
    isEligible:
      isAgreementDataEligibleForPolicyCoverageSummaryLink(agreementData),
    isTransactionAllowed,
  };

  return returnObject;
};

export const isAgreementDataEligibleForPolicyCoverageSummaryLink = (
  agreementData
) => {
  const { status, requestPolicyVerificationIndicator } = agreementData;

  const isValidPolicyStatus = isPolicyInForce(status);
  const isTrueIndicatorForPolicyCoverageSummary = compareStrings(
    "Yes",
    requestPolicyVerificationIndicator
  );

  return isValidPolicyStatus && isTrueIndicatorForPolicyCoverageSummary;
};
