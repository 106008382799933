import React, { useEffect } from "react";
import { setTitle } from "../../../../utils/dom";
import { tealiumPageViewEvent } from "../../../../utils/tealium";
import { useLoanPayoffAsOfDateProps } from "./hooks";

const LoanPayoffAsOfDate = () => {
  setTitle("ServiceNet - Loan Payoff As of Date");
  const { agreementKey, email } = useLoanPayoffAsOfDateProps();

  useEffect(() => {
    tealiumPageViewEvent({
      page: "Loan Payoff As Of Date",
      category: agreementKey,
      custId: email,
    });
  }, []);

  return <h1>Loan Payoff As of Date {agreementKey}</h1>;
};
export default React.memo(LoanPayoffAsOfDate);
