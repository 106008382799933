import { isArray } from "./evaluate";

export const stringIsEmptyOrNullish = (str) => {
  if (str === null || str === undefined) return true;
  if (!isString(str)) return false;
  return str.length === 0;
};

export const isNonEmptyString = (str) => isString(str) && str.length > 0;

export const isString = (input) => typeof input === "string";

export const removeAllWhiteSpace = (value) =>
  isString(value) ? value.replace(/\s+/g, "") : "";

export const compareStrings = (firstString, secondString) => {
  const bothStrings = isString(firstString) && isString(secondString);
  if (bothStrings) {
    return (
      removeAllWhiteSpace(firstString.toLowerCase()) ===
      removeAllWhiteSpace(secondString.toLowerCase())
    );
  }

  return false;
};
export const startsWithStrings = (
  stringToSearch,
  searchValue,
  isCaseSensetiveCheck = true
) => {
  const bothStrings = isString(stringToSearch) && isString(searchValue);

  if (bothStrings && !isCaseSensetiveCheck) {
    return stringToSearch.toLowerCase().startsWith(searchValue.toLowerCase());
  }
  if (bothStrings) {
    return stringToSearch.startsWith(searchValue);
  }

  return false;
};

export const compareArrayOfStrings = (arrayOfStrings, comparisonString) => {
  const isValid = isArray(arrayOfStrings) && isString(comparisonString);

  if (isValid) {
    return arrayOfStrings.reduce((oneStringMatches, currentString) => {
      if (oneStringMatches) return true;

      return compareStrings(currentString, comparisonString);
    }, false);
  }

  return false;
};

export const compareArraysForMatch = (firstArray, secondArray) => {
  const isValid = isArray(firstArray) && isArray(secondArray);

  if (isValid) {
    return firstArray.reduce((oneStringMatches, currentString) => {
      if (oneStringMatches) return true;

      return secondArray.reduce((foundString, comparisonString) => {
        if (foundString) return true;
        return compareStrings(currentString, comparisonString);
      }, false);
    }, false);
  }

  return false;
};

export const containsArrayOfStrings = (arrayOfStrings, comparisonString) => {
  const isValid = isArray(arrayOfStrings) && isString(comparisonString);

  if (isValid) {
    return arrayOfStrings.reduce((oneStringMatches, currentString) => {
      if (oneStringMatches) return true;

      return comparisonString.includes(currentString);
    }, false);
  }

  return false;
};

export const arraysAreTheSame = (array1, array2) => {
  const isValid = isArray(array1) && isArray(array2);
  if (isValid) {
    const array2Sorted = array2.slice().sort();
    return (
      array1.length === array2.length &&
      array1
        .slice()
        .sort()
        .every((value, index) => value === array2Sorted[index])
    );
  }

  return false;
};

export const addComma = (value) => (value ? `${value},` : "");
export const prependComma = (value) => (value ? `, ${value}` : "");
export const removeCommas = (value) => value.replace(/,/g, "");

export const extractDigitsfromNumber = (value, startIndex, endIndex) =>
  value.toString().slice(startIndex, endIndex);

export const trimStart = (value, charToTrim) => {
  if (!value) return "";
  if (!charToTrim) {
    return value.trimStart();
  }
  const regEx = new RegExp(`^${charToTrim}+`);
  return value.replace(regEx, "");
};

export const trimEnd = (value, charToTrim) => {
  if (!value) return "";
  if (!charToTrim) {
    return value.trimEnd();
  }
  const regEx = new RegExp(`${charToTrim}+$`);
  return value.replace(regEx, "");
};

export const encodeHtml = (htmlString) => {
  const result = htmlString
    ?.replace("&amp;", "&")
    ?.replace("&quot;", '"')
    ?.replace("&#039;", "'");
  return result;
};
