import React from "react";
import { useFormContext } from "../../../../contexts/form";
import { useDetermineFieldsToShow } from "./hooks";
import Choices from "./choices";

const SelectInformationTypes = (props) => {
  const { agreementRequest, agreementKey } = props;
  const { formData } = useFormContext();

  useDetermineFieldsToShow(agreementRequest, agreementKey);

  return (
    <div>
      <p className="h4">Select Information*</p>
      <Choices formData={formData} />
    </div>
  );
};

export default SelectInformationTypes;
