import React from "react";
import { compareStrings, encodeHtml } from "../../../../utils/string";
import ModalCell from "../../../common/table/customCells/modal";

const ServiceHistoryModalCell = (props) => {
  const { row } = props;

  const completeDateLabel = compareStrings(row.status, "pending")
    ? "Expected Completion Date"
    : "Complete Date";
  return (
    <ModalCell {...props} showAsOfDate={false} title={row?.eventName} size="lg">
      <dl className="mm-key-value-pair">
        <dt id="service_history_modal_status_lbl">Status</dt>
        <dd id="service_history_modal_status_value">{row.status}</dd>
        <dt id="service_history_modal_receive_date_lbl">Receive Date</dt>
        <dd id="service_history_modal_receive_date_value">{row.eventDate}</dd>
        <dt id="service_history_modal_complete_date_lbl">
          {completeDateLabel}
        </dt>
        <dd id="service_history_modal_complete_date_value">
          {row.completionDate}
        </dd>
        <dt id="service_history_modal_initiated_by_lbl">Initiated By</dt>
        <dd id="service_history_modal_initiated_by_value">
          {row.requestorDesc}
        </dd>
        <dt id="service_history_modal_requestor_name_lbl">Requestor Name</dt>
        <dd id="service_history_modal_requestor_name_value">
          {row.requestorName}
        </dd>
        <dt id="service_history_modal_requestor_phone_lbl">
          Requestor Phone Number
        </dt>
        <dd id="service_history_modal_requestor_phone_value">
          {row.requestorPhoneNumber}
        </dd>
        <dt id="service_history_modal_comments_lbl">Comments</dt>
        <dd
          id="service_history_modal_comments_value"
          style={{ whiteSpace: "pre-line" }}
        >
          {encodeHtml(row.extendedComment)}
        </dd>
      </dl>
    </ModalCell>
  );
};

export default React.memo(ServiceHistoryModalCell);
