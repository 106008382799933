import React from "react";
import { Link } from "react-router-dom";
import IsFalsy from "../logic/isFalsy";
import IsTruthy from "../logic/isTruthy";

const ModalPopup = (props) => {
  const {
    closeModal,
    agreementKey,
    modalBodyMessage,
    cancelButtonMessage = "Cancel",
    continueButtonMessage = "Continue",
    hasUrlInMessage = false,
    UrlLink = "",
    UrlName,
    customCancelButton = false,
    cancelButtonFunction,
    cancelPendingTransaction,
  } = props;

  return (
    <>
      <div className="modal-body">
        <IsTruthy value={hasUrlInMessage}>
          <p>
            {modalBodyMessage}{" "}
            <Link to={UrlLink} target="_blank" rel="noopener noreferrer">
              {UrlName}
            </Link>
          </p>
        </IsTruthy>
        <IsFalsy value={hasUrlInMessage}>
          <p>{modalBodyMessage}</p>
        </IsFalsy>
      </div>
      <div className="modal-footer">
        <button
          id="modal_popup_continue_btn"
          onClick={closeModal}
          type="button"
          className="btn btn-primary"
        >
          {continueButtonMessage}
        </button>
        <IsTruthy value={customCancelButton}>
          <button
            id="modal_popup_cancel_btn"
            onClick={cancelButtonFunction}
            type="button"
            className="btn btn-secondary"
          >
            {cancelButtonMessage}
          </button>
        </IsTruthy>
        <IsFalsy value={customCancelButton}>
          <Link
            id="modal_popup_cancel_btn"
            to={`/policyDetails/${encodeURIComponent(agreementKey)}`}
            className="btn btn-secondary"
            onClick={cancelPendingTransaction}
          >
            {cancelButtonMessage}
          </Link>
        </IsFalsy>
      </div>
    </>
  );
};

export default ModalPopup;
