import uniqBy from "lodash.uniqby";
import { DATA_UNAVAILABLE } from "../../../../../constants/ui";
import { formatMoney } from "../../../../../utils/format";
import { formatDate } from "../../../../../utils/dates";
import {
  stringIsEmptyOrNullish,
  compareStrings,
} from "../../../../../utils/string";

export const formatSiprOpmRiderSectionData = (props) => {
  const { coverages, billingData } = props;
  const { coveragePremiumAmount } = coverages?.[0] || {};
  const { riders } = billingData;

  const lisrRiders = riders?.filter((rider) =>
    compareStrings("SIPR", rider?.riderType)
  );
  const { riderType = "" } = lisrRiders?.[0] || [];

  return {
    riderName: stringIsEmptyOrNullish(riderType) ? DATA_UNAVAILABLE : riderType,
    annualCoveragePremium: formatMoney(coveragePremiumAmount, DATA_UNAVAILABLE),
  };
};

export const formatLisrOpmRiderSectionData = (props) => {
  const { riders } = props;

  const { riderType = "", modalPremium = "" } = riders?.[0] || [];

  return {
    riderName: stringIsEmptyOrNullish(riderType) ? DATA_UNAVAILABLE : riderType,
    annualCoveragePremium: formatMoney(modalPremium, DATA_UNAVAILABLE),
  };
};

export const formatLisrVNTG1RiderSectionData = (props) => {
  const { riders, hideLisrBilledPremiumValues } = props;
  const { coverages, riderType } = riders[0];

  const formattedCoverages = coverages
    .filter((rider) => {
      const { cvgStatusCode = "" } = rider;
      return compareStrings(cvgStatusCode, "ACT");
    })
    .sort((a, b) => a.sequenceNumber - b.sequenceNumber)
    .map((coverage) => {
      const {
        billedPremium = "",
        recommendedPremium = "",
        premiumPayingEndDate = "",
        outstandingDumpInPremium = "",
        crossoverOptionDate = "",
        sequenceNumber,
      } = coverage;

      return {
        sequenceNumber,
        riderType: stringIsEmptyOrNullish(riderType) ? "LISR" : riderType,
        billedPremium: hideLisrBilledPremiumValues
          ? "Unavailable"
          : formatMoney(billedPremium, DATA_UNAVAILABLE),
        recommendedPremium: hideLisrBilledPremiumValues
          ? "Unavailable"
          : formatMoney(recommendedPremium, DATA_UNAVAILABLE),
        premiumPayingEndDate: formatDate(
          premiumPayingEndDate,
          "",
          DATA_UNAVAILABLE
        ),
        outstandingDumpInPremium: formatMoney(
          outstandingDumpInPremium,
          DATA_UNAVAILABLE
        ),
        crossoverOptionDate: formatDate(
          crossoverOptionDate,
          "",
          DATA_UNAVAILABLE
        ),
      };
    });

  return {
    formattedCoverages: uniqBy(formattedCoverages, "sequenceNumber"),
  };
};

export const formatAlirOpmRiderSectionData = (props) => {
  const { riders } = props;

  let riderName;
  let coveragePremiumAmount;
  if (riders.length > 0) {
    const { riderType = DATA_UNAVAILABLE, modalPremium = DATA_UNAVAILABLE } =
      riders[0];
    riderName = riderType;
    coveragePremiumAmount = modalPremium;
  }

  const formattedAnnualCoveragePremium = formatMoney(
    coveragePremiumAmount,
    DATA_UNAVAILABLE
  );

  return {
    riderName: stringIsEmptyOrNullish(riderName) ? DATA_UNAVAILABLE : riderName,
    annualCoveragePremium: formattedAnnualCoveragePremium,
  };
};

export const formatAlirVntg1SectionData = (props) => {
  const { riders, hideLisrAlirBilledPremiumValues } = props;

  let riderName;
  let scheduledModalPaymentVal;
  let catchUpAmountAvailableVal;
  let catchUpAmountAppliedVal;
  let modalNonUWIncrAmtAvailableVal;
  let riderCoverages;
  if (riders.length > 0) {
    const {
      riderType = DATA_UNAVAILABLE,
      scheduledModalPayment = DATA_UNAVAILABLE,
      catchUpAmountAvailable = DATA_UNAVAILABLE,
      catchUpAmountApplied = DATA_UNAVAILABLE,
      modalNonUWIncrAmtAvailable = DATA_UNAVAILABLE,
      coverages = [],
    } = riders[0];

    riderCoverages = coverages
      .filter((rider) => {
        const { cvgStatusCode = "" } = rider;
        return compareStrings(cvgStatusCode, "ACT");
      })
      .sort((a, b) => a.sequenceNumber - b.sequenceNumber)
      .map((rider) => {
        const {
          billedPremium = DATA_UNAVAILABLE,
          billingMode = DATA_UNAVAILABLE,
          sequenceNumber,
        } = rider;

        return {
          sequenceNumber,
          billedPremium: hideLisrAlirBilledPremiumValues
            ? DATA_UNAVAILABLE
            : formatMoney(billedPremium, DATA_UNAVAILABLE),
          billingMode,
        };
      });

    riderName = riderType;
    scheduledModalPaymentVal = scheduledModalPayment;
    catchUpAmountAppliedVal = catchUpAmountApplied;
    catchUpAmountAvailableVal = catchUpAmountAvailable;
    modalNonUWIncrAmtAvailableVal = modalNonUWIncrAmtAvailable;
  }
  return {
    riderCoverages: uniqBy(riderCoverages, "sequenceNumber"),
    riderName: stringIsEmptyOrNullish(riderName) ? DATA_UNAVAILABLE : riderName,
    scheduledModalPaymentVal: hideLisrAlirBilledPremiumValues
      ? DATA_UNAVAILABLE
      : formatMoney(scheduledModalPaymentVal, DATA_UNAVAILABLE),
    catchUpAmountAvailableVal: formatMoney(
      catchUpAmountAvailableVal,
      DATA_UNAVAILABLE
    ),
    catchUpAmountAppliedVal: formatMoney(
      catchUpAmountAppliedVal,
      DATA_UNAVAILABLE
    ),
    modalNonUWIncrAmtAvailableVal: formatMoney(
      modalNonUWIncrAmtAvailableVal,
      DATA_UNAVAILABLE
    ),
  };
};

export const formatLisrHapRiderSectionData = (props) => {
  const { riders, hideLisrBilledPremiumValues } = props;
  const { coverages, riderType, coveragePremiumAmount } = riders[0];

  const formattedCoverages = coverages
    .filter((rider) => {
      const { cvgStatusCode = "" } = rider;
      return compareStrings(cvgStatusCode, "ACT");
    })
    .sort((a, b) => a.sequenceNumber - b.sequenceNumber)
    .map((coverage) => {
      const {
        billedPremium = "",
        recommendedPremium = "",
        premiumPayingEndDate = "",
        outstandingDumpInPremium = "",
        crossoverOptionDate = "",
        sequenceNumber,
      } = coverage;

      return {
        coveragePremiumAmount: formatMoney(
          coveragePremiumAmount,
          DATA_UNAVAILABLE
        ),
        sequenceNumber,
        riderType: stringIsEmptyOrNullish(riderType) ? "LISR" : riderType,
        billedPremium: hideLisrBilledPremiumValues
          ? DATA_UNAVAILABLE
          : formatMoney(billedPremium, DATA_UNAVAILABLE),
        recommendedPremium: hideLisrBilledPremiumValues
          ? DATA_UNAVAILABLE
          : formatMoney(recommendedPremium, DATA_UNAVAILABLE),
        premiumPayingEndDate: formatDate(
          premiumPayingEndDate,
          "",
          DATA_UNAVAILABLE
        ),
        outstandingDumpInPremium: formatMoney(
          outstandingDumpInPremium,
          DATA_UNAVAILABLE
        ),
        crossoverOptionDate: formatDate(
          crossoverOptionDate,
          "",
          DATA_UNAVAILABLE
        ),
      };
    });

  return {
    formattedCoverages: uniqBy(formattedCoverages, "sequenceNumber"),
  };
};

export const formatAlirHapSectionData = (props) => {
  const { riders, hideLisrAlirBilledPremiumValues } = props;

  let riderName;
  let scheduledModalPaymentVal;
  let catchUpAmountAvailableVal;
  let catchUpAmountAppliedVal;
  let modalNonUWIncrAmtAvailableVal;
  let riderCoverages;
  let annualCoveragePremium;
  if (riders.length > 0) {
    const {
      riderType = DATA_UNAVAILABLE,
      scheduledModalPayment = DATA_UNAVAILABLE,
      catchUpAmountAvailable = DATA_UNAVAILABLE,
      catchUpAmountApplied = DATA_UNAVAILABLE,
      modalNonUWIncrAmtAvailable = DATA_UNAVAILABLE,
      coveragePremiumAmount,
      coverages = [],
    } = riders[0];

    riderCoverages = coverages
      .filter((rider) => {
        const { cvgStatusCode = "" } = rider;
        return compareStrings(cvgStatusCode, "ACT");
      })
      .sort((a, b) => a.sequenceNumber - b.sequenceNumber)
      .map((rider) => {
        const {
          billedPremium = DATA_UNAVAILABLE,
          billingMode = DATA_UNAVAILABLE,
          sequenceNumber,
        } = rider;

        return {
          sequenceNumber,
          billedPremium: hideLisrAlirBilledPremiumValues
            ? DATA_UNAVAILABLE
            : formatMoney(billedPremium, DATA_UNAVAILABLE),
          billingMode,
        };
      });

    riderName = riderType;
    scheduledModalPaymentVal = scheduledModalPayment;
    catchUpAmountAppliedVal = catchUpAmountApplied;
    catchUpAmountAvailableVal = catchUpAmountAvailable;
    modalNonUWIncrAmtAvailableVal = modalNonUWIncrAmtAvailable;
    annualCoveragePremium = coveragePremiumAmount;
  }
  return {
    riderCoverages: uniqBy(riderCoverages, "sequenceNumber"),
    riderName: stringIsEmptyOrNullish(riderName) ? DATA_UNAVAILABLE : riderName,
    scheduledModalPaymentVal: hideLisrAlirBilledPremiumValues
      ? DATA_UNAVAILABLE
      : formatMoney(scheduledModalPaymentVal, DATA_UNAVAILABLE),
    catchUpAmountAvailableVal: formatMoney(
      catchUpAmountAvailableVal,
      DATA_UNAVAILABLE
    ),
    catchUpAmountAppliedVal: formatMoney(
      catchUpAmountAppliedVal,
      DATA_UNAVAILABLE
    ),
    modalNonUWIncrAmtAvailableVal: formatMoney(
      modalNonUWIncrAmtAvailableVal,
      DATA_UNAVAILABLE
    ),
    coveragePremiumAmount: formatMoney(annualCoveragePremium, DATA_UNAVAILABLE),
  };
};

export const formatPalirVntg1SectionData = (props) => {
  const { riders } = props;

  let riderName;
  let maxAnnualPaymentAmountVal;
  let premiumEndDateVal;
  let premiumStartDateVal;
  let yearToDatePremiumPaidAmountVal;
  let annualPaymentCountVal = DATA_UNAVAILABLE;
  let previousYearPaymentAmountVal;

  if (riders.length > 0) {
    const {
      riderType = DATA_UNAVAILABLE,
      maxAnnualPaymentAmount = DATA_UNAVAILABLE,
      premiumStartDate = DATA_UNAVAILABLE,
      premiumEndDate = DATA_UNAVAILABLE,
      yearToDatePremiumPaidAmount = DATA_UNAVAILABLE,
      annualPaymentCount = DATA_UNAVAILABLE,
      previousYearPaymentAmount = DATA_UNAVAILABLE,
    } = riders[0];

    riderName = riderType;
    maxAnnualPaymentAmountVal = maxAnnualPaymentAmount;
    premiumEndDateVal = premiumEndDate;
    premiumStartDateVal = premiumStartDate;
    yearToDatePremiumPaidAmountVal = yearToDatePremiumPaidAmount;
    annualPaymentCountVal = annualPaymentCount;
    previousYearPaymentAmountVal = previousYearPaymentAmount;
  }

  let remainingPaymentAmount =
    maxAnnualPaymentAmountVal - yearToDatePremiumPaidAmountVal;

  if (remainingPaymentAmount < 0) {
    remainingPaymentAmount = 0;
  }

  return {
    riderType: stringIsEmptyOrNullish(riderName) ? DATA_UNAVAILABLE : riderName,
    maxAnnualPaymentAmount: formatMoney(
      maxAnnualPaymentAmountVal,
      DATA_UNAVAILABLE
    ),
    premiumStartDate: formatDate(premiumStartDateVal, "", DATA_UNAVAILABLE),
    premiumEndDate: formatDate(premiumEndDateVal, "", DATA_UNAVAILABLE),
    yearToDatePremiumPaidAmount: formatMoney(
      yearToDatePremiumPaidAmountVal,
      DATA_UNAVAILABLE
    ),
    remainingPaymentAmount: Number.isNaN(remainingPaymentAmount)
      ? DATA_UNAVAILABLE
      : formatMoney(remainingPaymentAmount, DATA_UNAVAILABLE),
    annualPaymentCount: annualPaymentCountVal,
    previousYearPaymentAmount: formatMoney(
      previousYearPaymentAmountVal,
      DATA_UNAVAILABLE
    ),
  };
};
