import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useHeaderHelpDropdownProps } from "./hooks";
// eslint-disable-next-line import/no-cycle
import LoadingSection, { errorTypes, loadingTypes } from "../common/loading";

const HeaderHelpDropdown = (props) => {
  const {
    requestHrefs: { dataQualityRequestHref, clientConsolidationRequestHref },
    onHeaderHelpDropDownToggle,
    requests: { agreementRequest, producersRequest },
  } = useHeaderHelpDropdownProps(props);

  return (
    <Dropdown className="nav-item" onToggle={onHeaderHelpDropDownToggle}>
      <Dropdown.Toggle variant="none" id="dropdown-basic" className="nav-link">
        <span className="icon-help-white icon-sm" />
        Help
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <LoadingSection
          request={agreementRequest}
          loadingType={loadingTypes.SPINNER}
          errorType={errorTypes.NONE}
        >
          <LoadingSection
            request={producersRequest}
            loadingType={loadingTypes.SPINNER}
            errorType={errorTypes.NONE}
          >
            <Dropdown.Item href={dataQualityRequestHref}>
              Data Quality
            </Dropdown.Item>
            <Dropdown.Item href={clientConsolidationRequestHref}>
              Client Consolidation
            </Dropdown.Item>
          </LoadingSection>
        </LoadingSection>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default React.memo(HeaderHelpDropdown);
