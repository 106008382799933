import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { formatDate } from "../../../../utils/dates";
import { useUserState } from "../../../../contexts/user";
import { fireTealiumEvent } from "../../../../utils/tealium";
import { useMakeRequest } from "../../../../hooks/api";
import {
  compareArrayOfStrings,
  compareStrings,
  containsArrayOfStrings,
} from "../../../../utils/string";
import { isANumber, isDefined } from "../../../../utils/evaluate";
import {
  isArtistryAnnuityProduct,
  isFlexExtraAnnuityProduct,
  isFoundationFlexiblePremium,
  isFoundationSinglePremium,
  isOdysseyAnnuityProduct,
  isPolicyInForce,
  isRetireEaseContract,
  isVariableAnnuity,
  isFlexIVFund1FPOrFund4FP,
  isNonEnvisionAnnuity,
  isFixedVantagekcAnnuityContract,
  isPanoramaAnnuityProduct,
  isPanoramaPassageAnnuityProduct,
  isPanoramaPlusAnnuityProduct,
  isPanoramaPremierAnnuityProduct,
  isTransitionsSelectAnnuityProduct,
  isTransitionsAnnuityProduct,
  isTransitionsCustomPackageAnnuityProduct,
  isEquityEdgeAnnuityProduct,
  isLifeTrustAnnuityProduct,
  isEvolutionAnnuityProduct,
  isAnnuityContract,
  isFlexIVContract,
  isFlexPurchasePayIVorVProduct,
  isFlexPurchaseVaFund1or4Product,
  isFund1FlexiblePremiumContract,
  isFund1SinglePremiumProduct,
  isFund4FlexiblePremiumContract,
  isFund4SinglePremiumProduct,
  isArtistryProduct,
} from "../../../../utils/policies";

export const useAnnuityContractDetailProps = (props) => {
  const { agreementKey } = useParams();
  const {
    agreementRequest,
    producersRequest,
    billingRequest,
    serviceHistoryRequest,
    deathClaimEligibilityRequest,
  } = props?.apiRequests || {};

  const agreementData = agreementRequest?.data || {};

  const { policyId, kindCode, lineOfBusinessCode, status } = agreementData;

  const [isDrawerOpened, setDrawerOpened] = useState(false);
  const [customer, setCustomer] = useState();

  const openDrawer = (customerObject) => () => {
    setCustomer(customerObject);
    setDrawerOpened(true);
  };
  const closeDrawer = () => setDrawerOpened(false);

  const { user } = useUserState();
  const { email } = user;

  const printPDP = () => {
    fireTealiumEvent({
      label: "SVNT",
      action: "Print",
      category: "Contract Details - Annuities",
      custId: email,
    });
    window.print();
  };

  const contractNbr = policyId;
  const shouldUseSoaService =
    isRetireEaseContract(agreementData) ||
    isFoundationFlexiblePremium(agreementData) ||
    isFoundationSinglePremium(agreementData) ||
    isFlexIVFund1FPOrFund4FP(kindCode);

  const annuityRequest = useMakeRequest({
    apiRequest: shouldUseSoaService
      ? "fetchAnnuityByAgreementKey"
      : "fetchAnnuityByContractNbr",
    apiParams: { contractNbr, agreementKey },
    dataSelector: "processAnnuityData",
    immediateRequest: true,
    initiateDevModeDispatch: isNonEnvisionAnnuity(agreementRequest),
  });

  const annuityAsOfDate = shouldUseSoaService
    ? agreementRequest?.data?.asOfDate
    : annuityRequest?.data?.asofDate;

  const formattedAsOfDate = formatDate(
    annuityAsOfDate,
    "monthCommaDayYear",
    "date is unavailable"
  );

  // const { carrierCode } = annuityRequest.data || "";

  const isAnnuityDataLoaded = isDefined(annuityRequest.data.carrierCode);

  const soaBillingRequest = useMakeRequest({
    apiRequest: "fetchSoaBillingArrangement",
    apiParams: { agreementKey, carrierCode: annuityRequest?.data?.carrierCode },
    immediateRequest: false,
    dataSelector: "processSoaBillingArrangement",
    initiateDevModeDispatch: shouldUseSoaService,
  });

  const cdaValuesRequest = useMakeRequest({
    apiRequest: "fetchCdaValues",
    apiParams: {
      agreementKey,
      asOfDate: agreementRequest?.data?.asOfDate,
      carrierCode: annuityRequest?.data?.carrierCode,
    },
    immediateRequest: false,
    dataSelector: "processCdaValues",
    initiateDevModeDispatch: shouldUseSoaService,
  });

  const isDeliveryPreferenceEligibleAnnuity =
    shouldAnnuityDisplayDeliveryPreference(agreementData);

  const statementPreferencesRequest = useMakeRequest({
    apiRequest: "fetchStatementPreferences",
    apiParams: {
      customer: {
        contract: {
          agreementKey,
        },
        preferences: [
          {
            typeCode: "DELIVERY",
            subTypeCode: "STMNT",
          },
        ],
      },
    },
    immediateRequest: isDeliveryPreferenceEligibleAnnuity,
    dataSelector: "processStatementPreferencesData",
    initiateDevModeDispatch: true,
  });

  useEffect(() => {
    if (isAnnuityDataLoaded) {
      soaBillingRequest.executeRequest();
      cdaValuesRequest.executeRequest();
    }
  }, [isAnnuityDataLoaded]);

  const showErrorPage = showAnnuityErrorPage({
    agreementRequest,
    annuityRequest,
  });

  let carrierCode = "65935";

  switch (agreementRequest?.data?.carrierCode) {
    case "100":
      carrierCode = "65935";
      break;
    case "200":
      carrierCode = "93432";
      break;
    case "208":
      carrierCode = "70416";
      break;
    default:
      break;
  }

  const transactionCode = "1013221203";
  const isContractInForce = isPolicyInForce(status);
  const isArtistryAnnuityContract = isArtistryAnnuityProduct(
    agreementRequest?.data?.agreementKeyPrefix
  );
  const isFixedVantagekcAnnuity =
    isFixedVantagekcAnnuityContract(agreementData);

  const isFoundationPremiumContract =
    isFoundationSinglePremium(agreementData) ||
    isFoundationFlexiblePremium(agreementData);

  const isFlexExtraContract = isFlexExtraAnnuityProduct(
    agreementData?.kindCode
  );

  const isRetireEaseContractResult = isRetireEaseContract(agreementData);
  const isFlexIVFund1OrFund4Contract = isFlexIVFund1FPOrFund4FP(
    agreementData?.kindCode
  );
  const isPanoramaSeriesContract =
    isPanoramaAnnuityProduct(kindCode) ||
    isPanoramaPassageAnnuityProduct(kindCode) ||
    isPanoramaPlusAnnuityProduct(kindCode) ||
    isPanoramaPremierAnnuityProduct(kindCode);

  const isTransitionsSelectContract = isTransitionsSelectAnnuityProduct(
    agreementData?.kindCode
  );

  const isTransitionsContract =
    isTransitionsAnnuityProduct(agreementData?.kindCode) &&
    !isTransitionsCustomPackageAnnuityProduct(agreementData?.kindCode);
  const isEquityEdgeAnnuityContract = isEquityEdgeAnnuityProduct(
    agreementData?.kindCode
  );
  const isLifeTrustAnnuityContract = isLifeTrustAnnuityProduct(
    agreementData?.kindCode
  );
  const isEvolutionContract = isEvolutionAnnuityProduct(
    agreementData?.kindCode
  );
  const isTransitionsCustomContract = isTransitionsCustomPackageAnnuityProduct(
    agreementData?.kindCode
  );

  const annuityProductSupportsKpis =
    isFixedVantagekcAnnuity ||
    isArtistryAnnuityContract ||
    isFlexExtraContract ||
    isFoundationPremiumContract ||
    isRetireEaseContractResult ||
    isFlexIVFund1OrFund4Contract ||
    isTransitionsSelectContract ||
    isEvolutionContract ||
    isPanoramaSeriesContract ||
    isTransitionsContract ||
    isEquityEdgeAnnuityContract ||
    isLifeTrustAnnuityContract ||
    isFlexIVFund1OrFund4Contract ||
    isTransitionsCustomContract;

  const displayKpi = isContractInForce && annuityProductSupportsKpis;

  /* Value Recalculation logic - Begins */

  const [asOfDate, setAsOfDate] = useState(annuityAsOfDate);
  const [showRecalcValues, setShowRecalcValues] = useState(false);
  const [showLoadingIcon, setShowLoadingIcon] = useState(false);
  const formattedSelectedDate = formatDate(asOfDate?.toString(), "YYYY-MM-DD");

  // Original request in case of Artistry Products, otherwise the only recalculation request
  const valueRecalcRequest = useMakeRequest({
    apiRequest: "fetchRecalculatedVNTGKCValues",
    apiParams: {
      agreementKey,
      carrierCode,
      formattedSelectedDate,
      transactionCode,
    },
    immediateRequest: false,
    initiateDevModeDispatch: false,
  });

  let suppAgreementKey = "";
  let originalRequestForEmployee = true;

  if (isArtistryAnnuityContract) {
    const [policyNumber, prefix, suffix, carrierAdminSystem] =
      agreementKey?.split("|") || [];

    if (compareStrings(suffix, "EE")) {
      suppAgreementKey = [policyNumber, prefix, "ER", carrierAdminSystem].join(
        "|"
      );
    } else {
      suppAgreementKey = [policyNumber, prefix, "EE", carrierAdminSystem].join(
        "|"
      );
      originalRequestForEmployee = false;
    }
  }

  // Subsequest request in case of Artistry Products (for employer, in case employee was already made & Vice-a-versa)
  const suppValueRecalcRequest = useMakeRequest({
    apiRequest: "fetchRecalculatedVNTGKCValues",
    apiParams: {
      agreementKey: suppAgreementKey,
      carrierCode,
      formattedSelectedDate,
      transactionCode,
    },
    immediateRequest: false,
    initiateDevModeDispatch: false,
  });

  const formattedAnnuityAsOfDate = formatDate(annuityAsOfDate, "YYYY-MM-DD");
  let recalculatedValues = {};
  useEffect(() => {
    if (
      isDefined(formattedSelectedDate) &&
      isDefined(formattedAnnuityAsOfDate) &&
      formattedAnnuityAsOfDate !== formattedSelectedDate
    ) {
      valueRecalcRequest.executeRequest();
      if (isArtistryAnnuityContract) suppValueRecalcRequest.executeRequest();
      setShowRecalcValues(false);
      setShowLoadingIcon(true);
      if (!valueRecalcRequest.isLoading && !suppValueRecalcRequest.isLoading) {
        setShowRecalcValues(true);
        setShowLoadingIcon(false);
      }
    } else {
      setShowRecalcValues(false);
    }
  }, [
    formattedSelectedDate,
    valueRecalcRequest.data,
    valueRecalcRequest.isLoading,
    suppValueRecalcRequest.isLoading,
    suppValueRecalcRequest.data,
  ]);

  recalculatedValues = valueRecalcRequest.data || {};

  // Consolidating the response in case of Artistry contracts with Employee Employer values
  if (isArtistryAnnuityContract) {
    recalculatedValues = getConsolidatedEEERresponse(
      valueRecalcRequest.data,
      suppValueRecalcRequest.data,
      originalRequestForEmployee
    );
  }

  /* Value Recalculation logic - Ends */

  return {
    policyId,
    agreementKey,
    annuityRequest,
    agreementRequest,
    serviceHistoryRequest,
    deathClaimEligibilityRequest,
    recalculatedValues,
    showRecalcValues,
    asOfDate,
    formattedAsOfDate,
    producersRequest,
    email,
    isDrawerOpened,
    openDrawer,
    closeDrawer,
    customer,
    printPDP,
    showErrorPage,
    hiddenScrollSpySections: getHiddenScrollSpySection({
      agreementRequest,
      annuityRequest,
    }),
    soaBillingRequest,
    cdaValuesRequest,
    billingRequest,
    setAsOfDate,
    showLoadingIcon,
    user,
    lineOfBusinessCode,
    displayKpi,
    statementPreferencesRequest,
    isDeliveryPreferenceEligibleAnnuity,
  };
};

export const getConsolidatedEEERresponse = (
  valueRecalcData,
  suppValueRecalcData,
  originalRequestForEmployee
) => {
  const valueRecalcContractValue =
    valueRecalcData?.recalculation?.contractValue;
  const valueRecalcContractValueFunds =
    valueRecalcContractValue?.funds?.fund.map((fund) => ({
      ...fund,
      segment: originalRequestForEmployee ? "EE" : "ER",
    })) || [];
  const suppValueRecalcContractValue =
    suppValueRecalcData?.recalculation?.contractValue;
  const suppValueRecalcContractValueFunds =
    suppValueRecalcContractValue?.funds?.fund.map((fund) => ({
      ...fund,
      segment: originalRequestForEmployee ? "ER" : "EE",
    })) || [];

  const policyValue =
    isANumber(valueRecalcContractValue?.policyValue) &&
    isANumber(suppValueRecalcContractValue?.policyValue)
      ? +valueRecalcContractValue.policyValue +
        +suppValueRecalcContractValue.policyValue
      : "-";

  const contractWithdrawalValue =
    isANumber(valueRecalcContractValue?.contractWithdrawalValue) &&
    isANumber(suppValueRecalcContractValue?.contractWithdrawalValue)
      ? +valueRecalcContractValue.contractWithdrawalValue +
        +suppValueRecalcContractValue.contractWithdrawalValue
      : "-";

  const freeWithdrawalAmt =
    isANumber(valueRecalcContractValue?.freeWithdrawalAmt) &&
    isANumber(suppValueRecalcContractValue?.freeWithdrawalAmt)
      ? +valueRecalcContractValue.freeWithdrawalAmt +
        +suppValueRecalcContractValue.freeWithdrawalAmt
      : "-";

  const currentDeathBenefit =
    isANumber(valueRecalcContractValue?.currentDeathBenefit) &&
    isANumber(suppValueRecalcContractValue?.currentDeathBenefit)
      ? +valueRecalcContractValue.currentDeathBenefit +
        +suppValueRecalcContractValue.currentDeathBenefit
      : "-";

  const consolidatedData = {
    recalculation: {
      asOfDate: valueRecalcData?.recalculation?.asOfDate,
      loans:
        valueRecalcData?.recalculation?.loans.concat(
          suppValueRecalcData?.recalculation?.loans
        ) || [],
      contractValue: {
        contractNbr: valueRecalcContractValue?.contractNbr,
        policyValue: policyValue.toString(),
        contractWithdrawalValue: contractWithdrawalValue.toString(),
        contingentDeferredSalesCharge:
          valueRecalcContractValue?.contingentDeferredSalesCharge,
        freeWithdrawalAmt: freeWithdrawalAmt.toString(),
        currentDeathBenefit: currentDeathBenefit.toString(),
        policyValueEE: originalRequestForEmployee
          ? valueRecalcContractValue?.policyValue
          : suppValueRecalcContractValue?.policyValue,
        policyValueER: originalRequestForEmployee
          ? suppValueRecalcContractValue?.policyValue
          : valueRecalcContractValue?.policyValue,
        funds: {
          fund:
            valueRecalcContractValueFunds.concat(
              suppValueRecalcContractValueFunds
            ) || [],
        },
      },
    },
  };

  return consolidatedData;
};

export const getHiddenScrollSpySection = (props) => {
  const { agreementRequest, annuityRequest } = props;
  const agreementData = agreementRequest.data || {};
  const annuityData = annuityRequest.data || {};

  const { agreementKeyPrefix, status, kindCode } = agreementData;
  const { issueState, marketType } = annuityData;

  const isArtistryAnnuityContract =
    isArtistryAnnuityProduct(agreementKeyPrefix);
  const isFlexExtraContract = isFlexExtraAnnuityProduct(kindCode);
  const isVariableAnnuityContract = isVariableAnnuity(agreementRequest);

  const hideInvestments = !isVariableAnnuityContract;
  const hideBillingPayment =
    !isVariableAnnuityContract && !isOdysseyAnnuityProduct(agreementKeyPrefix);

  const restrictedIssueSatesForLoans = ["VT"];
  const allowedMarketTypesForLoans = ["Tax Sheltered Annuity"];
  const isContractInForce = isPolicyInForce(status);

  const isRestrictedState = compareArrayOfStrings(
    restrictedIssueSatesForLoans,
    issueState
  );
  const isRestrictedMarketType = !containsArrayOfStrings(
    allowedMarketTypesForLoans,
    marketType
  );

  const hideLoan =
    (!isArtistryAnnuityContract && !isFlexExtraContract) ||
    !isContractInForce ||
    (isArtistryAnnuityContract &&
      (isRestrictedState || isRestrictedMarketType));

  const hideContractFeatures =
    isFoundationFlexiblePremium(agreementData) ||
    isFoundationSinglePremium(agreementData) ||
    isFlexIVFund1FPOrFund4FP(agreementData?.kindCode);

  return {
    hideLoan,
    hideInvestments,
    hideBillingPayment,
    hideContractFeatures,
  };
};

export const showAnnuityErrorPage = (props) => {
  const { agreementRequest, annuityRequest } = props;

  const {
    messages: { code, description },
  } = agreementRequest?.data || {};
  const {
    error: annuityError,
    data: { annuityErrorCode },
  } = annuityRequest;

  const errorStatus400FromAscApi = annuityError?.response?.status === 400;

  const agreementDoesNotExist =
    compareStrings(description, "Agreement not found") &&
    compareStrings(code, "1");
  const annuityError400 =
    errorStatus400FromAscApi && !compareStrings(annuityErrorCode, "ANN0007");
  const annuityError200 =
    !isDefined(annuityError) && isDefined(annuityErrorCode);

  if (agreementDoesNotExist || annuityError400 || annuityError200) return true;

  return false;
};

export const shouldAnnuityDisplayDeliveryPreference = (agreementData) => {
  const { lineOfBusinessCode, kindCode } = agreementData;

  const isAnnuityProduct = isAnnuityContract(lineOfBusinessCode);

  if (!isAnnuityProduct) return false;
  if (
    isFlexExtraAnnuityProduct(kindCode) ||
    isFlexIVContract(kindCode) ||
    isFlexPurchasePayIVorVProduct(kindCode) ||
    isFlexPurchaseVaFund1or4Product(kindCode) ||
    isFund1FlexiblePremiumContract(kindCode) ||
    isFund1SinglePremiumProduct(kindCode) ||
    isFund4FlexiblePremiumContract(kindCode) ||
    isFund4SinglePremiumProduct(kindCode) ||
    isLifeTrustAnnuityProduct(kindCode) ||
    isArtistryProduct(kindCode) ||
    isEquityEdgeAnnuityProduct(kindCode) ||
    isEvolutionAnnuityProduct(kindCode) ||
    isTransitionsAnnuityProduct(kindCode) ||
    isPanoramaAnnuityProduct(kindCode) ||
    isPanoramaPassageAnnuityProduct(kindCode) ||
    isPanoramaPlusAnnuityProduct(kindCode) ||
    isPanoramaPremierAnnuityProduct(kindCode)
  ) {
    return true;
  }
  return false;
};
