import React from "react";
import { formatAlirHapOrVntg1Data } from "./dividendHelper";
import { InformationPopover } from "../../../common/popovers/informationPopover";

const AlirHapOrVntg1Section = (props) => {
  const {
    alirCurrentDividendEarnedPremAmount,
    alirCurrentDividendEarnedDivdAmount,
    alirTotPaidUpAddsCashValAmount,
    dividendPaidUpAddsCashValAmount,
    alirDividendAvailableAmount,
    alirDivOptPaidUpAddsAmount,
    currentDividendTypeDescription,
  } = formatAlirHapOrVntg1Data(props);
  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <dl className="mm-key-value-pair">
          <dt id="alir_rider_name_lbl">Rider Name</dt>
          <dd id="alir_rider_name_value">ALIR</dd>
          <dt id="total_alir_curr_div_earned_label">
            Total ALIR Current Dividend Earned{" "}
            <InformationPopover
              popoverId="current_dividend_tooltip"
              text="The most recent dividend that was earned on the policy"
            />
          </dt>
          <dd id="total_alir_curr_div_earned_value">
            {alirDivOptPaidUpAddsAmount}
          </dd>
          <dt id="cash_value_alir_purchased_by_div_lbl">
            Cash Value of ALIR Purchased by Dividends
          </dt>
          <dd id="cash_value_alir_purchased_by_div_value">
            {dividendPaidUpAddsCashValAmount}
          </dd>
          <dt id="alir_current_div_earned_by_div_lbl">
            ALIR Current Dividend Earned by Dividends
          </dt>
          <dd id="alir_current_div_earned_by_div_value">
            {alirCurrentDividendEarnedDivdAmount}
          </dd>
        </dl>
      </div>
      <div className="col-12 col-md-6">
        <dl className="mm-key-value-pair">
          <dt id="dividend_option_lbl">Dividend Option</dt>
          <dd id="dividend_option_value">{currentDividendTypeDescription}</dd>
          <dt id="total_alir_dividend_cash_val_lbl">
            Total ALIR Dividend Cash Value
          </dt>
          <dd id="total_alir_dividend_cash_val_value">
            {alirDividendAvailableAmount}
          </dd>
          <dt id="cash_value_of_alir_purchased_by_prem_lbl">
            Cash Value of ALIR Purchased by Premium Payments
          </dt>
          <dd id="cash_value_of_alir_purchased_by_prem_value">
            {alirTotPaidUpAddsCashValAmount}
          </dd>
          <dt id="alir_current_div_earned_by_prem_payments_lbl">
            ALIR Current Dividend Earned by Premium Payments
          </dt>
          <dd id="alir_current_div_earned_by_prem_payments_value">
            {alirCurrentDividendEarnedPremAmount}
          </dd>
        </dl>
      </div>
    </div>
  );
};

export default React.memo(AlirHapOrVntg1Section);
