import React, { useEffect } from "react";
import { fireTealiumAndFollowLink } from "../../utils/tealium";
import IsTruthy from "../common/logic/isTruthy";
import IsFalsy from "../common/logic/isFalsy";
import { useVerifyCashValueQuoteLink } from "../policyDetails/pages/cashValues/restrictions";

const CashValueQuoteLink = (props) => {
  const {
    data,
    setShowCashValueQuoteLink = () => {},
    policyId,
    userEmail,
    tealiumCategory,
    target,
    isRenderAsListItem = false,
    cssClassName = "btn btn-inline",
  } = props;

  const { agreementKey } = data;

  const { isEligibleForCashValueQuoteLink } =
    useVerifyCashValueQuoteLink(props);

  useEffect(() => {
    setShowCashValueQuoteLink(isEligibleForCashValueQuoteLink);
  }, [isEligibleForCashValueQuoteLink]);

  return (
    <IsTruthy value={isEligibleForCashValueQuoteLink}>
      <IsTruthy value={isRenderAsListItem}>
        <li>
          <button
            type="button"
            id="cash-value-quote-link"
            className={cssClassName}
            onClick={() =>
              fireTealiumAndFollowLink({
                url: `/policyDetails/${encodeURIComponent(
                  agreementKey
                )}/cashValues`,
                action: "CashValueQuote Link clicked",
                category: tealiumCategory,
                value: policyId,
                custId: userEmail,
                target,
              })
            }
          >
            Get Cash Value Quote
          </button>
        </li>
      </IsTruthy>
      <IsFalsy value={isRenderAsListItem}>
        <button
          className={cssClassName}
          id="cash-value-quote-link"
          type="button"
          onClick={() =>
            fireTealiumAndFollowLink({
              url: `/policyDetails/${encodeURIComponent(
                agreementKey
              )}/cashValues`,
              action: "CashValueQuote Link clicked",
              category: tealiumCategory,
              value: policyId,
              custId: userEmail,
            })
          }
        >
          Get Cash Value Quote
        </button>
      </IsFalsy>
    </IsTruthy>
  );
};

export default React.memo(CashValueQuoteLink);
