/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import CommonForm from "./commonForm";
import { useDevModeProps } from "./hooks";
import IsTruthy from "../../common/logic/isTruthy";
import Form from "./form";
import { CollapseParent } from "../../common/molecules/collapse";

const DevMode = (props) => {
  const { shareData, pageApis, otherApis, isVisible } = useDevModeProps(props);

  return (
    <IsTruthy value={isVisible}>
      <div
        className="col-4"
        style={{
          position: "sticky",
          height: "100vh",
          top: "0",
          overflow: "auto",
        }}
      >
        <div style={{ margin: "8px" }}>
          <div
            style={{
              position: "sticky",
              top: "0",
              backgroundColor: "white",
              zIndex: 1,
            }}
          >
            <h2>Set Custom Api Response</h2>
            <CommonForm shareData={shareData} />
          </div>
          <div>
            <h4>Page Apis</h4>
            <CollapseParent>
              <Form formFields={pageApis} />
            </CollapseParent>
            <h4>Other Apis</h4>
            <CollapseParent>
              <Form formFields={otherApis} />
            </CollapseParent>
          </div>
        </div>
      </div>
    </IsTruthy>
  );
};

export default DevMode;
