import React from "react";
import {
  useConfirmationProps,
  handleConfirmationPrintButtonClick,
} from "./hooks";
import { setTitle } from "../../utils/dom";
import { fireTealiumAndFollowLink } from "../../utils/tealium";

const Confirmation = () => {
  setTitle("ServiceNet - Address Change Confirmation");
  const {
    selectedUsers,
    preferredMethodOfContact,
    requestorName,
    newAddress,
    requestDateTime,
    agreementKey,
    userEmail,
    trimmedPolicyId,
  } = useConfirmationProps();
  return (
    <div>
      <div className="row">
        <div className="col-md-9">
          <h1 className="h2">
            <picture>
              <span
                id="change_address_confirmation_success_image"
                role="img"
                className="illustration-confetti hide-print"
                title="Success Confetti"
              />
            </picture>
            <div id="change_address_confirmation_submitted_short_message">
              Thank you! Your Change Address Request has been submitted.
            </div>
          </h1>
        </div>
        <div className="col-3">
          <div id="printButton" className="justify-content-end">
            <button
              onClick={() =>
                handleConfirmationPrintButtonClick(trimmedPolicyId, userEmail)
              }
              data-bp-link="default"
              className="btn btn-inline hide-print"
              aria-label="Print"
              type="button"
            >
              <span
                id="change_address_confirmation_print_icon"
                className="icon-print"
              />
              <span>Print</span>
            </button>
          </div>
        </div>
      </div>
      <p
        className="mm-spacing--minor"
        id="change_address_confirmation_submitted_detailed_message"
      >
        The Change Address Request has been successfully submitted for
        processing. Please review Service History for processing time and
        updates. If you have any questions regarding this request, please call
        us at 1-800-272-2216 8:00 AM - 8:00 PM ET, Monday through Friday and
        reference the policy number.
      </p>
      <hr className="mm-spacing" />
      <div className="row mm-spacing--minor">
        <div className="col-md-9">
          <dl className="mm-key-value-pair">
            <dt id="confirmation_new_address_lbl">New Address</dt>
            <dd id="confirmation_new_address_value">
              <b>{newAddress}</b>
            </dd>
            <dt id="confirmation_apply_changes_to_lbl">Apply Change To</dt>
            <dd>
              {selectedUsers.map((user) => {
                const { customerName } = user.customer;
                const { id: customerIndex, policies } = user;
                return (
                  <React.Fragment key={customerName}>
                    <div id={`${customerIndex}_userName`}>
                      <b>{user.display}</b>
                    </div>
                    {policies.map((policy) => {
                      const {
                        productTypeName,
                        agreementKeyPrimaryDisplay,
                        roles,
                      } = policy;
                      const roleNames = roles
                        .map((role) => role.roleNames.join(", "))
                        .join(", ");
                      return (
                        <div
                          className="mm-spacing--minor"
                          id={`${customerIndex}_productrole_${agreementKeyPrimaryDisplay}`}
                        >
                          {`${productTypeName} #${agreementKeyPrimaryDisplay}, 
                        ${roleNames}`}
                        </div>
                      );
                    })}
                  </React.Fragment>
                );
              })}{" "}
            </dd>
            <dt id="confirmation_requestor_fullName_lbl">
              Financial Professional
            </dt>
            <dd id="confirmation_requestor_fullName_value">{requestorName}</dd>
            <dt id="confirmation_preferred_moc_lbl">
              Financial Professional Email{" "}
            </dt>
            <dd id="confirmation_preferred_moc_value">
              {preferredMethodOfContact}
            </dd>
            <dt id="confirmation_request_dateTime_lbl">Request Date/Time</dt>
            <dd id="confirmation_request_dateTime_value">{requestDateTime}</dd>
          </dl>
        </div>
      </div>

      <button
        type="button"
        id="confirmation_serviceHistory_link_btn"
        data-bp-link="default"
        onClick={() =>
          fireTealiumAndFollowLink({
            url: `/policyDetails/${encodeURIComponent(
              agreementKey
            )}/viewServiceHistory`,
            action: "View Service History link clicked",
            category: "Change Address",
            value: trimmedPolicyId,
            custId: userEmail,
          })
        }
        className="btn btn-primary hide-print"
      >
        {" "}
        VIEW SERVICE HISTORY
      </button>

      <a
        href={`/policyDetails/${encodeURIComponent(agreementKey)}`}
        className="btn btn-link hide-print"
        id="change_address_close_button"
      >
        Close
      </a>
    </div>
  );
};

export default Confirmation;
