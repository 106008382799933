import { checkIfTransactionIsAllowed } from "../../utils/policies";
import { compareStrings } from "../../utils/string";
import { supportedTransactions } from "../../utils/translate";

export const verifyInitiateDeathClaim = (props) => {
  const {
    agreementData,
    deathClaimEligibilityData,
    transactionSupportedPolicies,
    isCalledFrom,
  } = props;

  const { agreementKey } = agreementData;
  const { deathClaimEligibility } = deathClaimEligibilityData;

  const { isTransactionAllowed } = checkIfTransactionIsAllowed({
    transactionSupportedPolicies,
    transactionName: supportedTransactions.InitiateDeathClaim,
    agreementData,
  });

  const hasDeathClaimTransactionPending = compareStrings(
    deathClaimEligibility,
    "P"
  );

  const isEligible =
    compareStrings(deathClaimEligibility, "T") ||
    (hasDeathClaimTransactionPending && compareStrings(isCalledFrom, "PDP"));

  const initiateDeathClaimLink = `/policyDetails/${encodeURIComponent(
    agreementKey
  )}/initiateDeathClaim`;

  const returnObject = {
    isTransactionAllowed,
    initiateDeathClaimLink,
    isEligible,
  };

  return returnObject;
};
