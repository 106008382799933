import { useState } from "react";
import { isEnvisionPolicy } from "./policies";

export const useDisplayAtLeastOneTransactionLink = () => {
  const [showChangeAddressLink, setShowChangeAddressLink] = useState(true);
  const [showInitiateNewLoanLink, setShowInitiateNewLoanLink] = useState(false);
  const [showEditBillingFrequencyLink, setShowEditBillingFrequencyLink] =
    useState(false);
  const [showAnnuityFormsCenterLink, setShowAnnuityFormsCenterLink] =
    useState(false);
  const [showInForceIllustrationLink, setShowInForceIllustrationLink] =
    useState(false);
  const [showFundTransferLink, setShowFundTransferLink] = useState(false);
  const [showInitiateDeathClaimLink, setShowInitiateDeathClaimLink] =
    useState(false);
  const [showDirectLinksLink, setShowDirectLinksLink] = useState(false);
  const [showCashValueQuoteLink, setShowCashValueQuoteLink] = useState(false);
  const [showPolicyCoverageSummaryLink, setShowPolicyCoverageSummaryLink] =
    useState(false);
  const [showFillableFormLibraryLink, setShowFillableFormLibraryLink] =
    useState(false);

  const atLeastOneLinkShowing =
    showInitiateNewLoanLink ||
    showChangeAddressLink ||
    showEditBillingFrequencyLink ||
    showAnnuityFormsCenterLink ||
    showInForceIllustrationLink ||
    showFundTransferLink ||
    showInitiateDeathClaimLink ||
    showDirectLinksLink ||
    showCashValueQuoteLink ||
    showPolicyCoverageSummaryLink ||
    showFillableFormLibraryLink;

  const allLinkStatesSetToOff =
    !showInitiateNewLoanLink &&
    !showChangeAddressLink &&
    !showEditBillingFrequencyLink &&
    !showAnnuityFormsCenterLink &&
    !showInForceIllustrationLink &&
    !showFundTransferLink &&
    !showInitiateDeathClaimLink &&
    !showDirectLinksLink &&
    !showCashValueQuoteLink &&
    !showPolicyCoverageSummaryLink &&
    !showFillableFormLibraryLink;

  return {
    allLinkStatesSetToOff,
    showTransactionLinkBox: atLeastOneLinkShowing,
    transactionLinkSetFunctions: {
      setShowInitiateNewLoanLink,
      setShowChangeAddressLink,
      setShowEditBillingFrequencyLink,
      setShowAnnuityFormsCenterLink,
      setShowInForceIllustrationLink,
      setShowFundTransferLink,
      setShowInitiateDeathClaimLink,
      setShowDirectLinksLink,
      setShowCashValueQuoteLink,
      setShowPolicyCoverageSummaryLink,
      setShowFillableFormLibraryLink,
    },
  };
};

export const getFundTransferType = (agreementData) => {
  if (isEnvisionPolicy(agreementData)) return "ENVISION";
  return "";
};
