import React from "react";
import { useCheckboxProps } from "./hooks";

const Checkbox = (props) => {
  const {
    formField: { id, name, value, ariaLabel = "", autoComplete = "off" },
    useHook = useCheckboxProps,
    disabled,
  } = props;
  const { onChange, className } = useHook(props);

  return (
    <input
      id={`input-${id}`}
      name={name}
      className={className}
      type="checkbox"
      checked={value}
      autoComplete={autoComplete}
      aria-label={ariaLabel}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

export default Checkbox;
