import React from "react";

const DecedentPolicy = (props) => {
  const { productTypeName, agreementKeyPrimaryDisplay, roleTypeDisplay, id } =
    props;
  return (
    <div className="mm-spacing--minor">
      <div className="row">
        <div id={`decendent_policy_${id}`} className="col-8">
          {`${productTypeName} #${agreementKeyPrimaryDisplay}`}
        </div>
        <div id={`decedent_roles_${id}`} className="col-4">
          {roleTypeDisplay.join(", ")}
        </div>
      </div>
    </div>
  );
};
export default DecedentPolicy;
