import React from "react";
import FormScreenAddresses from "../../common/formScreenAddresses/index";

const CTAform = ({ lineOfBusinessCode }) => (
  <>
    <h2>Please sign and submit your request.</h2>
    <p className="mm-sub-copy">
      The request requires a form FR6445 signed by the policy owner. Please
      follow the steps below to submit the form.
    </p>
    <div className="mm-spacing">
      <a
        href="https://www.massmutual.com/efiles/life/pdfs/f6445.pdf"
        className="btn btn-primary"
        target="_blank"
        rel="noreferrer"
      >
        DOWNLOAD FORM
      </a>
    </div>
    <div className="mm-spacing">
      <hr />
    </div>
    <div className="row mm-spacing--minor">
      <ol>
        <li>
          Once the owner has completed the form, you can submit the form one of
          the three ways.
        </li>
      </ol>
    </div>
    <FormScreenAddresses lineOfBusinessCode={lineOfBusinessCode} />
  </>
);

export default CTAform;
