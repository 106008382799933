import React from "react";
import { range } from "../../../utils/array";

const Stepper = (props) => {
  const { totalSteps, currentStep } = props;
  const steps = range(1, totalSteps).map((stepNumber) => {
    const IS_ACTIVE_STEP = stepNumber === currentStep;
    if (IS_ACTIVE_STEP) {
      return (
        <li
          key={stepNumber}
          id={`stpper_listitem_${stepNumber}`}
          className="active"
          aria-current="step"
        >
          <span className="sr-only">Step {stepNumber}</span>
        </li>
      );
    }
    return (
      <li key={stepNumber} id={`stpper_listitem_${stepNumber}`}>
        <span className="sr-only">Step {stepNumber}</span>
      </li>
    );
  });
  return <ul className="mm-stepper">{steps}</ul>;
};

export default Stepper;
