import React from "react";
import { isDefined } from "../../utils/evaluate";
import IsTruthy from "../common/logic/isTruthy";
import IsFalsy from "../common/logic/isFalsy";
import { useUserAuthCheckProps } from "./hooks";

const UserAuthenticationCheck = (props) => {
  const { children } = props;
  const { user, className } = useUserAuthCheckProps(props);

  return (
    <div className={className}>
      <IsTruthy value={user.accessToken} validationFunction={isDefined}>
        {children}
      </IsTruthy>
      <IsFalsy value={user.accessToken} validationFunction={isDefined}>
        <div className="mm-text-center">
          <div className="mm-dots-loading-xl" />
        </div>
      </IsFalsy>
    </div>
  );
};

export default UserAuthenticationCheck;
