import React from "react";
import IsFalsy from "../../common/logic/isFalsy";
import IsTruthy from "../../common/logic/isTruthy";
import { useBillingFrequencyProps } from "../hooks";
import BillingFrequencyCard from "./billingFrequencyCard";

const BillingFrequency = (props) => {
  const { formData, billingModeData } = useBillingFrequencyProps(props);
  return (
    <div id="billingFrequencies" className="mm-spacing">
      <div className="row ">
        <div className="col-9">
          <p className="h3">Select Billing Frequency*</p>

          <p>
            Values displayed are based upon current premium amounts and do not
            include riders that are billed separately. Actual amounts may be
            different based on policy conditions at the time of the frequency
            change.
          </p>
          <IsTruthy
            value={billingModeData.monthlyBillingData.isCurrentBillingMode}
          >
            <p>
              <strong>
                Note: If the policy is removed from PAC, the transaction you are
                requesting may have an impact on commissions.
              </strong>
            </p>
          </IsTruthy>
          <IsFalsy
            value={billingModeData.monthlyBillingData.isCurrentBillingMode}
          >
            <p>
              <strong>
                Form F6445 must be submitted to put a policy on Pre-Authorized
                Checking.
              </strong>
            </p>
          </IsFalsy>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3 col-md-6">
          <BillingFrequencyCard
            formField={formData.monthlyBilling}
            data={billingModeData.monthlyBillingData}
          />
        </div>
        <div className="col-lg-3 col-md-6">
          <BillingFrequencyCard
            formField={formData.quarterlyBilling}
            data={billingModeData.quarterlyBillingData}
          />
        </div>
        <div className="col-lg-3 col-md-6">
          <BillingFrequencyCard
            formField={formData.semiAnnualBilling}
            data={billingModeData.semiAnnualBillingData}
          />
        </div>
        <div className="col-lg-3 col-md-6">
          <BillingFrequencyCard
            formField={formData.annualBilling}
            data={billingModeData.annualBillingData}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(BillingFrequency);
