import React from "react";

export const DaysThirtyTwoToSixtyTwoModal = () => (
  <div className="modal-body" id="days_32_to_62_modal">
    Payment will still be accepted by this date and the policy will be
    reinstated without additional requirements, but if there is no value then
    the policy will not provide coverage until receipt of the payment. If the
    payment is not received by this date and there is no value or Automatic Loan
    provision, then the policy will require regular reinstatement.
    <br />
    <br />
    Exceptions: This date does not apply if policy is on PAC, or Group Billing.
    <br />
    <br />
    For PAC or Group-Billed Traditional Life policies, the grace period is
    calculated based on the date the grace notice is sent, not the paid to date
    of the policy.
  </div>
);
