import React from "react";
import { InformationPopover } from "../../../common/popovers/informationPopover";

const KpiInfoPopover = (props) => {
  const { label, labelId, value, valueId, popoverId, text } = props;
  return (
    <>
      <dt id={labelId} className="mm-text-nowrap">
        {label}
        {` `}
        <InformationPopover popoverId={popoverId} text={text} />
      </dt>
      <dd id={valueId}>{value}</dd>
    </>
  );
};

export default KpiInfoPopover;
