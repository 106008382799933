import React, { useEffect, useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import { isDefined } from "../../utils/evaluate";
import IsTruthy from "../common/logic/isTruthy";
import IsFalsy from "../common/logic/isFalsy";
import { useCanContinue } from "./hooks";
import { useFormContext } from "../../contexts/form";
import { useCancelModal } from "../common/popup/cancel";
import { ADDRESS_CHANGE_MAX_RETRY_COUNT } from "../../constants/logic";

const ModalControl = (props) => {
  const {
    pageNumber,
    onSubmit,
    submissionRetry,
    shouldDisableSubmit,
    isSubmissionInProgress,
  } = props;
  const { onContinue, error } = useCanContinue(props);
  const { openCancelModal } = useCancelModal();
  const { formData } = useFormContext();

  const [submitButtonclassName, setSubmitButtonClassName] =
    useState("btn btn-primary");
  const [submitButtonText, setSubmitButtonText] = useState("Submit");
  const [submitButtonAlt, setSubmitButtonAlt] = useState("");
  const [submitButtonAriaLabel, setsubmitButtonAriaLabel] = useState("");
  useEffect(() => {
    if (isSubmissionInProgress) {
      setSubmitButtonClassName("btn btn-primary-loading");
      setSubmitButtonText("");
      setSubmitButtonAlt("loading..");
      setsubmitButtonAriaLabel("Loading");
    } else {
      setSubmitButtonClassName("btn btn-primary");
      setSubmitButtonText("Submit");
    }
  }, [isSubmissionInProgress]);

  useDeepCompareEffect(() => {
    const { retryAttemptCount, retryList } = submissionRetry;
    if (
      retryAttemptCount > 0 &&
      retryAttemptCount <= ADDRESS_CHANGE_MAX_RETRY_COUNT
    ) {
      onSubmit(formData, retryList);
    }
  }, [submissionRetry]);

  return (
    <div className="mm-spacing">
      <IsTruthy value={error} validationFunction={isDefined}>
        {" "}
        <div
          className="alert alert-danger mm-spacing--minor"
          role="alert"
          aria-label={error}
        >
          {error}
        </div>
      </IsTruthy>
      <div className="mm-spacing--minor">
        <IsTruthy value={pageNumber} validationFunction={(pn) => pn < 4}>
          <button
            id="continue_btn"
            onClick={onContinue}
            type="button"
            className="btn btn-primary"
          >
            continue
          </button>
        </IsTruthy>
        <IsTruthy value={pageNumber} validationFunction={(pn) => pn === 4}>
          <button
            id="submit_btn"
            type="submit"
            className={submitButtonclassName}
            disabled={shouldDisableSubmit || isSubmissionInProgress}
            onClick={(e) => {
              e.preventDefault();
              onSubmit(formData);
            }}
            alt={submitButtonAlt}
            aria-label={submitButtonAriaLabel}
          >
            {submitButtonText}
          </button>
        </IsTruthy>
        <IsFalsy value={pageNumber} validationFunction={(pn) => pn === 5}>
          <button
            id="cancel_btn"
            type="button"
            className="btn btn-link"
            onClick={openCancelModal}
          >
            Cancel
          </button>
        </IsFalsy>
      </div>
    </div>
  );
};

export default ModalControl;
