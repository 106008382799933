import React from "react";

const BlockLoading = (props) => {
  const { height } = props;
  return (
    <div className="mm-skeleton--full mm-spacing" style={{ minHeight: height }}>
      <span className="sr-only" aria-busy="true" aria-live="polite">
        Content is loading
      </span>
    </div>
  );
};

export default BlockLoading;
