import React from "react";
import CheckboxForm from "../../../common/forms/checkboxForm";
import { contactInformationUpdateAction } from "../../../../utils/translate";
import { isDefined } from "../../../../utils/evaluate";
import IsTruthy from "../../../common/logic/isTruthy";

const ContactInformationCard = (props) => {
  const {
    formField: contactDetailsFormField,
    checkboxFormField,
    useEditHook,
    cardType,
  } = props;

  const selectionFormField = checkboxFormField || contactDetailsFormField;
  const { id, contactDetails } = contactDetailsFormField;

  const { name, phone, addressLine1, addressLine2, city, zip, state, email } =
    contactDetails;

  const isStreetAddressDefined =
    isDefined(addressLine1) || isDefined(addressLine2);
  const isPhoneOrStreetAddressDefined =
    isDefined(phone) || isStreetAddressDefined;
  const areCityStateZipDefined =
    isDefined(city) || isDefined(zip) || isDefined(state);

  const { openContactInformationModal } = useEditHook({
    formField: contactDetailsFormField,
    checkboxFormField,
    action: contactInformationUpdateAction.EDIT,
    cardType,
  });

  return (
    <div className="mm-spacing--minor col-sm-12 col-md-6">
      <div className="card">
        <div className="card-body">
          <div className="mm-spacing--minor h3 card-title">
            <div className="row">
              <div className="col-9">
                <CheckboxForm
                  formField={{ ...selectionFormField, label: name }}
                />
              </div>
              <div className="col-3 justify-content-end">
                <span
                  id={`${selectionFormField.id}Icon`}
                  className="icon-user icon-lg"
                />
              </div>
            </div>
          </div>
          <div className="row card-text">
            <IsTruthy value={isPhoneOrStreetAddressDefined}>
              <div className="col-6">
                <IsTruthy value={isStreetAddressDefined}>
                  <p id={`${id}_addressLines`}>
                    {addressLine1}
                    {addressLine1 && addressLine2 && `, `}
                    {addressLine2}
                  </p>
                </IsTruthy>
                <p id={`${id}_phoneNumber`}>{phone}</p>
              </div>{" "}
            </IsTruthy>
            <div className="col-6">
              <IsTruthy value={areCityStateZipDefined}>
                <p id={`${id}_cityStateZip`}>
                  {city}
                  {city && state && `, `}
                  {state}
                  {(city || state) && zip && `, `}
                  {zip}
                </p>
              </IsTruthy>
              <p id={`${id}_email`}>{email}</p>
            </div>
          </div>
          <div className="card-footer">
            <button
              type="button"
              className="btn btn-secondary"
              id={`${id}_edit`}
              onClick={openContactInformationModal}
            >
              Edit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInformationCard;
