import React from "react";
import Column from "./column";

const Columns = (props) => {
  const { columns, sort, toggleSort, irregularColumns = [] } = props;
  let scope;
  if (irregularColumns.length > 0) {
    scope = "col";
  }
  const generatedColumns = columns.map((column) => (
    <Column
      key={`column-${column.accessor}-${column.label}`}
      column={column}
      sort={sort}
      toggleSort={toggleSort}
      scope={scope}
    />
  ));

  const generatedIrregularColumns = irregularColumns.map((irregularColumn) => {
    const { label, colSpan = 1, dataCell } = irregularColumn;
    if (dataCell) {
      return (
        <td colSpan={colSpan} key={`irregularColumn-${label}`}>
          {label}
        </td>
      );
    }
    return (
      <th colSpan={colSpan} scope="colgroup" key={`irregularColumn-${label}`}>
        {label}
      </th>
    );
  });

  const generatedColumnGroups = irregularColumns.map(
    (irregularColumn, index) => {
      const { colSpan = 1 } = irregularColumn;
      const formattedKey = `colgroup-${index}`;
      if (colSpan === 1) {
        return (
          <colgroup key={formattedKey}>
            <col />
          </colgroup>
        );
      }
      return <colgroup span={colSpan} key={formattedKey} />;
    }
  );

  if (irregularColumns.length > 0) {
    return (
      <>
        {generatedColumnGroups}
        <thead>
          <tr>{generatedIrregularColumns}</tr>
          <tr>{generatedColumns}</tr>
        </thead>
      </>
    );
  }

  return (
    <thead>
      <tr>{generatedColumns}</tr>
    </thead>
  );
};

export default React.memo(Columns);
