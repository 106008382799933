import React from "react";
import { stringIsEmptyOrNullish } from "../../utils/string";
import { useReviewProps } from "./hooks";
import IsFalsy from "../common/logic/isFalsy";

const Review = (props) => {
  const { setPageNumber } = props;
  const {
    selectedUsers,
    preferredMethodOfContact,
    requestorName,
    address1,
    address2,
    address3,
    city,
    state,
    zipCode,
    countryCode,
  } = useReviewProps();

  return (
    <div>
      <h1 className="mm-spacing" id="review_header_lbl">
        Review
      </h1>
      <p>
        This will apply the change of address to supported policies associated
        with the selected individual.
      </p>
      <br />
      <p className="h3" id="selected_customers_lbl">
        Selected Customer(s)
        <button
          type="button"
          className="btn btn-inline"
          onClick={() => setPageNumber(1)}
          aria-label="edit_customers_button"
          id="edit_selected_customers_btn"
        >
          Edit
        </button>
      </p>
      <div className="row mm-spacing--minor">
        <div className="col-md-7">
          {selectedUsers.map((user) => {
            const { customerName } = user.customer;

            const { id: customerIndex, policies } = user;

            return (
              <React.Fragment key={customerName}>
                <dl className="mm-key-value-pair">
                  <dt id={`review_name_${customerIndex}_lbl`}>
                    {customerName}
                  </dt>
                  <dd>
                    {policies.map((policy) => {
                      const {
                        productTypeName,
                        agreementKeyPrimaryDisplay,
                        roles,
                      } = policy;

                      const roleNames = roles
                        .map((role) => role.roleNames.join(", "))
                        .join(", ");

                      return (
                        <div
                          className="mm-spacing--minor"
                          id={`${customerIndex}_policy_${agreementKeyPrimaryDisplay}`}
                        >
                          {`${productTypeName} #${agreementKeyPrimaryDisplay}, 
                          ${roleNames}`}
                        </div>
                      );
                    })}
                  </dd>
                </dl>
              </React.Fragment>
            );
          })}
        </div>
      </div>
      <div className="row">
        <div className="col-md-7 mm-spacing--minor">
          <hr />
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <p className="h3" id="new_address_lbl">
            New Address
            <button
              type="button"
              className="btn btn-inline"
              onClick={() => setPageNumber(2)}
              aria-label="edit_address_button"
              id="edit_new_address_btn"
            >
              Edit
            </button>
          </p>
        </div>
      </div>

      <div className="row mm-spacing--minor">
        <div className="col-md-7">
          <dl className="mm-key-value-pair">
            <dt id="review_country_lbl">Country</dt>
            <dd id="review_country_value">{countryCode}</dd>
            <dt id="review_address1_lbl">Address 1</dt>
            <dd id="review_address1_value">{address1}</dd>
            <IsFalsy value={stringIsEmptyOrNullish(address2)}>
              <dt id="review_address2_lbl">Address 2</dt>
              <dd id="review_address2_value">{address2}</dd>{" "}
            </IsFalsy>
            <IsFalsy value={stringIsEmptyOrNullish(address3)}>
              <dt id="review_address3_lbl">Address 3</dt>
              <dd id="review_address3_value">{address3}</dd>{" "}
            </IsFalsy>
            <dt id="review_city_lbl">City</dt>
            <dd id="review_city_value">{city}</dd>
            <dt id="review_state_lbl">State</dt>
            <dd id="review_state_value">{state}</dd>
            <dt id="review_zip_code_lbl">ZIP Code</dt>
            <dd id="review_zip_code_value">{zipCode}</dd>{" "}
          </dl>{" "}
        </div>{" "}
      </div>
      <div className="row">
        <div className="col-md-7 mm-spacing--minor">
          <hr />
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <p className="h3" id="requestor_contact_lbl">
            Requestor Contact
            <button
              type="button"
              className="btn btn-inline"
              onClick={() => setPageNumber(3)}
              aria-label="edit_requestor_contact_button"
              id="edit_requestor_contact_btn"
            >
              Edit
            </button>
          </p>
        </div>
      </div>
      <div className="row mm-spacing">
        <div className="col-md-7">
          <dl className="mm-key-value-pair">
            <dt id="review_requestor_lbl">Financial Professional</dt>
            <dd id={`review_requestor_${requestorName}_value`}>
              {requestorName}
            </dd>
            <dt id="review_policy_and_role_lbl">
              Financial Professional Email
            </dt>
            <dd id={`review_requestor_email_${preferredMethodOfContact}_value`}>
              {preferredMethodOfContact}
            </dd>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default Review;
