import React from "react";
import JsonInput from "../../common/forms/jsonInput";
import Collapse from "../../common/molecules/collapse";

const JsonInputForm = (props) => {
  const { formField, useHook } = props;

  return (
    <div className="form-group">
      <Collapse id={formField.id} title={formField.label}>
        <JsonInput formField={formField} useHook={useHook} />
      </Collapse>
    </div>
  );
};

export default React.memo(JsonInputForm);
