import { useState } from "react";
import moment from "moment-timezone";
import { useUserState } from "../../contexts/user";
import { useMakeRequest } from "../../hooks/api";
import { isHomeOfficeUser } from "../../utils/findData";
import { useLDValue } from "../common/featureFlags/hooks";

export const useAgentEmailNotificationsProps = () => {
  const [currentDateTime] = useState(
    moment().tz("America/New_York").format("YYYY-MM-DD HH:mm:ss.SSS")
  );

  const numberOfDaysThreshold = useLDValue({
    flagName: ["maxDaysNewNotifications"],
  });

  const agentNotificationsRequest = useMakeRequest({
    apiRequest: "fetchAgentNotifications",
    apiParams: {
      daysThreshold: numberOfDaysThreshold,
      dateFilter: currentDateTime,
      extended: true,
    },
    immediateRequest: true,
    dataSelector: "processAgentNotificationsData",
    initiateDevModeDispatch: true,
  });

  const updateNotificationsLastViewedRequest = useMakeRequest({
    apiRequest: "updateNotificationsLastViewed",
    apiParams: {
      viewedAt: currentDateTime,
    },
    immediateRequest: false,
    initiateDevModeDispatch: false,
  });

  const { user } = useUserState();

  const isHomeOfficeUserResult = isHomeOfficeUser(user);

  return {
    agentNotificationsRequest,
    isHomeOfficeUserResult,
    updateNotificationsLastViewedRequest,
    numberOfDaysThreshold,
  };
};
