import React from "react";
import IsTruthy from "../../../../common/logic/isTruthy";
import IsFalsy from "../../../../common/logic/isFalsy";
import { DATA_UNAVAILABLE } from "../../../../../constants/ui";
import { LabelWithInformationPopover } from "../../../../common/popovers/informationPopover";
import LoanDetailsInfoTable from "./artistryTables/loanDetailsInfoTable";
import LoanPayoffInfoTable from "./artistryTables/loanPayoffInfoTable";
import LoanSummaryTable from "./artistryTables/loanSummaryTable";
import { getConfig, types } from "../../../../../config";
import SelectAsOfDate from "../../selectValueRecalculationAsOfDate";

const ArtistryLoanDisplay = (props) => {
  const {
    formattedMaxLoanAmount,
    outstandingLoanCount,
    formattedOutstandingLoanCount,
    maxLoanAsOfDate,
    maxLoanAmountPopover: {
      displaymaxLoanAmountPopover,
      maxLoanAmountPopoverTexts,
    },
    loanSectionInfoMessages,
    formattedLoanPayoffAsOfDate,
    groupedLoanList,
    annuityRequest,
    agreementRequest,
    hasActiveLoan,
    setAsOfDate,
    showLoanPayOffValues,
    showLoadingIcon,
    formattedContactUsNumber,
    formattedContactUsTime,
  } = props;

  const maxLoanAmountPopoverContent = maxLoanAmountPopoverTexts.map(
    (text, index) => {
      const popOverContentId = `popOverContentId_PDP_${index}`;
      return `<li key=${popOverContentId} id=${popOverContentId}>${text}</li>`;
    }
  );
  let maxLoanAmountTextList = "";
  maxLoanAmountPopoverContent.forEach((text) => {
    maxLoanAmountTextList += text;
  });

  const infoMessages = loanSectionInfoMessages.map(
    (loanSectionInfoMessage, index) => {
      const infoMessageId = `loanSectionInfoMessage-${index}`;
      return (
        <div
          className="alert alert-info"
          role="alert"
          key={loanSectionInfoMessage?.id}
          data-testid={infoMessageId}
          id={loanSectionInfoMessage?.id}
          dangerouslySetInnerHTML={{
            __html: loanSectionInfoMessage?.displayText,
          }}
        />
      );
    }
  );

  const fieldNetUrl = getConfig(types.fieldNetUrl);
  const annuityFormCenterRedirectUrl = `${fieldNetUrl}/riappexpress/raxfn?GUID=&LAD=A`;

  return (
    <div className="mm-spacing--minor">
      <h3 id="loan_availability_lbl">Loan Availability</h3>
      <hr />
      <div className="row mm-spacing--minor">
        <div className="col-12 col-md-6">
          <dl className="mm-key-value-pair">
            <dt id="loan_max_loan_lbl">Max Loan Amount</dt>
            <dd id="loan_max_loan_value" data-testid="loan_max_loan_value">
              <IsFalsy value={displaymaxLoanAmountPopover}>
                {formattedMaxLoanAmount}
              </IsFalsy>
              <IsTruthy value={displaymaxLoanAmountPopover}>
                <LabelWithInformationPopover
                  label={DATA_UNAVAILABLE}
                  popoverId="policy_max_loan_amount_popover"
                  text={`
                    <div class="row">
                      <div class="col-sm">
                        <ul class="list-unstyled">
                          ${maxLoanAmountTextList}
                        </ul>
                      </div>
                    </div>
                  `}
                />
              </IsTruthy>
            </dd>
            <dt id="loan_outstanding_loans_lbl">Number of Outstanding Loans</dt>
            <dd id="loan_outstanding_loans_value">
              {formattedOutstandingLoanCount}
            </dd>
          </dl>
        </div>
        <div className="col-12 col-md-6">
          <dl className="mm-key-value-pair">
            <dt id="loan_max_loan_quote_date_lbl">
              Max Loan Quote Effective Date
            </dt>
            <dd id="loan_max_loan_quote_date_value">{maxLoanAsOfDate}</dd>
          </dl>
        </div>
      </div>
      <hr />
      <h3 id="loan_summary_lbl">Loan Summary</h3>
      <IsTruthy value={outstandingLoanCount === 0}>
        <div
          className="alert alert-info"
          role="alert"
          data-testid="alert_loan_info_notavailable"
          id="no_loan_alert"
        >
          There are no loans on the contract. If you have any questions, contact
          our Service Center at{" "}
          <a href={`tel:+${formattedContactUsNumber}`}>
            {formattedContactUsNumber}
          </a>
          , {formattedContactUsTime}.
        </div>
      </IsTruthy>
      <IsFalsy value={outstandingLoanCount === 0}>
        <LoanSummaryTable
          outstandingLoanCount={outstandingLoanCount}
          groupedLoanList={groupedLoanList}
        />
        <h3 id="loan_detailed_information_lbl">Detailed Loan Information</h3>
        <LoanDetailsInfoTable
          outstandingLoanCount={outstandingLoanCount}
          groupedLoanList={groupedLoanList}
        />
        <h3 id="loan_payoff_information_lbl">Loan Payoff Information</h3>
        <SelectAsOfDate
          agreementRequest={agreementRequest}
          annuityRequest={annuityRequest}
          action="LOAN_PAYOFF"
          hasActiveLoan={hasActiveLoan}
          setAsOfDate={setAsOfDate}
          showLoanPayOffValues={showLoanPayOffValues}
          showLoadingIcon={showLoadingIcon}
        />
        <p id="as_of_date_value_loan_payoff" className="copy-lg">
          <IsTruthy value={showLoanPayOffValues}>
            <mark>As of {formattedLoanPayoffAsOfDate}</mark>
          </IsTruthy>
          <IsFalsy value={showLoanPayOffValues}>
            <span>As of {formattedLoanPayoffAsOfDate}</span>
          </IsFalsy>
        </p>
        <LoanPayoffInfoTable
          outstandingLoanCount={outstandingLoanCount}
          groupedLoanList={groupedLoanList}
        />
      </IsFalsy>
      <IsTruthy value={loanSectionInfoMessages.length > 0}>
        {infoMessages}
      </IsTruthy>
      <p>
        To initiate a loan for your client go to the{" "}
        <a id="link_annuity_loan_initiate" href={annuityFormCenterRedirectUrl}>
          Annuity Forms Center
        </a>
        .
      </p>
    </div>
  );
};

export default ArtistryLoanDisplay;
