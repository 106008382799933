import deburr from "lodash.deburr";
/* eslint-disable no-param-reassign */
export const length = (x) => x.length;
export const sum = (a, b) => a + b;
export const indexesOf = (substr) => ({
  in: (str) =>
    str
      .split(substr)
      .slice(0, -1)
      .map(length)
      .map((_, i, lengths) =>
        lengths.slice(0, i + 1).reduce(sum, i * substr.length)
      ),
});

export const insert = (str, index, value) =>
  str.substr(0, index) + value + str.substr(index);

/* eslint-disable import/prefer-default-export */
export const searchRows = ({
  searchString,
  initialRows = [],
  columns,
  searchColumns,
}) => {
  let rows = initialRows;
  const convertedSearchString = deburr(searchString);
  columns = columns.filter((column) => searchColumns.includes(column.accessor));
  if (convertedSearchString !== "") {
    // eslint-disable-next-line radix
    const upperCaseSearchString = convertedSearchString.toUpperCase();

    rows = rows.filter((row) => {
      const { flag, updatedRow } = searchRow({
        row,
        upperCaseSearchString,
        columns,
      });

      return flag ? updatedRow : false;
    });
  }

  return rows;
};

export const searchRow = ({ row, upperCaseSearchString, columns }) => {
  let flag = false;
  // eslint-disable-next-line array-callback-return
  columns.map(({ accessor, CustomCell }) => {
    if (CustomCell) {
      row[`org_${accessor}`] = row[accessor];
    }
    const { anyIndexes, newRowValue } = checkForSearchTerm({
      value: row[accessor],
      upperCaseSearchString,
    });
    if (anyIndexes) flag = true;
    // eslint-disable-next-line no-param-reassign
    row[accessor] = newRowValue;
  });
  return { updatedRow: row, flag };
};

export const checkForSearchTerm = ({ value, upperCaseSearchString }) => {
  try {
    if (value === undefined) return { anyIndexes: false, newRowValue: "" };
    // for handling owner name list in policy search
    if (Array.isArray(value)) {
      let containsIndexes = false;
      value.forEach((owner) => {
        const nameValue = owner.name.fullName.toUpperCase();
        const indexes = indexesOf(upperCaseSearchString).in(deburr(nameValue));
        const { anyIndexes, newRowValue: newNameValue } = tryToInsertSpan({
          indexes,
          rowValue: owner.name.fullName,
          searchString: upperCaseSearchString,
        });
        owner.name.fullName = newNameValue;
        if (anyIndexes) containsIndexes = true;
      });
      return { anyIndexes: containsIndexes, newRowValue: value };
    }
    const rowValue = `${value}`.toUpperCase();
    const indexes = indexesOf(upperCaseSearchString).in(deburr(rowValue));
    const { anyIndexes, newRowValue } = tryToInsertSpan({
      indexes,
      rowValue: `${value}`,
      searchString: upperCaseSearchString,
    });
    return { anyIndexes, newRowValue };
  } catch (error) {
    return { anyIndexes: false, newRowValue: "" };
  }
};

export const tryToInsertSpan = ({ indexes, rowValue, searchString }) => {
  if (indexes.length > 0) {
    // eslint-disable-next-line no-plusplus
    for (let i = indexes.length - 1; i >= 0; i--) {
      rowValue = insert(rowValue, indexes[i] + searchString.length, "</mark>");
      rowValue = insert(rowValue, indexes[i], "<mark>");
    }
    return { anyIndexes: true, newRowValue: rowValue };
  }
  return { anyIndexes: false, newRowValue: rowValue };
};
