import React, { useMemo } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useDropdown } from "./hooks";

const DropdownToggle = (props) => {
  const {
    formField: { id, display, options },
    useHook = useDropdown,
  } = props;
  const {
    functions: { selectedOption },
  } = useHook(props);

  const dropdownOptions = useMemo(
    () =>
      options.map((button) => (
        <Dropdown.Item
          value={button.value}
          id={button.id}
          key={button.id}
          eventKey={button.value}
          onClick={selectedOption}
        >
          {button.label}
        </Dropdown.Item>
      )),
    [options]
  );

  return (
    <div className="form-group">
      <DropdownButton
        id={`${id}-dropdownMenuButton`}
        title={display}
        variant="secondary"
      >
        {dropdownOptions}
      </DropdownButton>
    </div>
  );
};

export default React.memo(DropdownToggle);
