import {
  compareArrayOfStrings,
  compareStrings,
  startsWithStrings,
  stringIsEmptyOrNullish,
} from "./string";
import { isDefined, isNullOrUndefined } from "./evaluate";
// import { hasKey } from "./object";
import {
  ANNUITY_ARTISTRY_PREFIXES,
  ANNUITY_ODYSSEY_PREFIXES,
  ANNUITY_STABLE_VOYAGE_PREFIXES,
  ANNUITY_NON_GLWB_PRODUCT_TYPE_CODE,
  ANNUITY_NON_GLWB_TITLES,
  ANNUITY_EVOLUTION_PREFIXES,
  VARIABLE_ANNUITY_FLEX_EXTRA_KIND_CODES,
  SURVIVORSHIP_NON_TRAD_PE_PRODUCT_NAMES,
  SURVIVORSHIP_TRAD_MPR_PRODUCT_NAMES,
  SURVIVORSHIP_TRAD_OPM_PRODUCT_NAMES,
  SURVIVORSHIP_TRAD_VNTG1_PRODUCT_NAMES,
  ANNUITY_ODYSSEY_KIND_CODES,
  ANNUITY_ODYSSEY_PLUS_KIND_CODES,
  ANNUITY_ODYSSEY_SELECT_KIND_CODES,
  ANNUITY_STABLE_VOYAGE_KIND_CODES,
  VARIABLE_ANNUITY_ARTISTRY_KIND_CODES,
} from "../constants/logic";
import { typesOfAgents } from "../data/typesOfAgents";
import { supportedTransactions } from "./translate";
import { addDaystoDate, getCurrentDate, isBeforeDate } from "./dates";
import {
  DEFAULT_404_ERROR_MESSAGE,
  LOAN_RESTRICTED_SWL_POLICY_ERROR_MESSAGE,
} from "../constants/ui";

export const isTermPolicy = (props) => {
  const {
    agreementRequest: {
      data: { agreementKeyAdmin, majorProductCode },
    },
  } = props;
  const termAdminKeys = ["OPM", "MPR", "VNTG1", "HAP", "PALLM"];
  const IS_TERM_POLICY =
    compareArrayOfStrings(termAdminKeys, agreementKeyAdmin) &&
    compareStrings(majorProductCode, "TERM");

  return IS_TERM_POLICY;
};

export const isTermOrEdsDiPolicy = (props) => {
  const {
    agreementRequest: {
      data: { lineOfBusinessCode, agreementKeyAdmin },
    },
  } = props;
  const isEdsDiPolicy =
    isDisabilityPolicy({ lineOfBusinessCode }) &&
    compareStrings(agreementKeyAdmin, "EDS-DI");

  return isTermPolicy(props) || isEdsDiPolicy;
};

export const isULUVPolicy = (props) => {
  const {
    agreementRequest: {
      data: { agreementKeyAdmin, majorProductCode, lineOfBusinessCode },
    },
  } = props;
  return isULUVPolicyBusinessLogic(
    agreementKeyAdmin,
    majorProductCode,
    lineOfBusinessCode
  );
};
export const isFixedVantagekcAnnuityContract = (props) => {
  const { lineOfBusinessCode, kindCode } = props;
  return (
    compareStrings(lineOfBusinessCode, "ANN") &&
    (compareArrayOfStrings(ANNUITY_ODYSSEY_KIND_CODES, kindCode) ||
      compareArrayOfStrings(ANNUITY_ODYSSEY_PLUS_KIND_CODES, kindCode) ||
      compareArrayOfStrings(ANNUITY_ODYSSEY_SELECT_KIND_CODES, kindCode) ||
      isCMWindowsAnnuityProduct(kindCode) ||
      compareArrayOfStrings(ANNUITY_STABLE_VOYAGE_KIND_CODES, kindCode))
  );
};

export const isULUVPolicyBusinessLogic = (
  agreementKeyAdmin,
  majorProductCode,
  lineOfBusinessCode
) => {
  const termAdminKeys = ["PE1", "LVRGVL", "LIFCOM"];
  const IS_ULUV_POLICY =
    compareArrayOfStrings(termAdminKeys, agreementKeyAdmin) &&
    compareStrings(majorProductCode, "NTL") &&
    compareStrings(lineOfBusinessCode, "Life");

  return IS_ULUV_POLICY;
};

export const isPermPolicy = (props) => {
  const {
    agreementRequest: {
      data: { agreementKeyAdmin, majorProductCode },
    },
  } = props;

  const adminSystems = ["VNTG1", "OPM", "MPR"];
  const IS_PERM_POLICY =
    compareStrings(majorProductCode, "PERM") &&
    compareArrayOfStrings(adminSystems, agreementKeyAdmin);

  if (IS_PERM_POLICY) {
    return true;
  }
  return false;
};

export const isNTLPolicy = (props) => {
  const {
    agreementRequest: {
      data: { agreementKeyAdmin, majorProductCode },
    },
  } = props;

  const adminSystems = ["LIFCOM", "LVRGVL", "PE1", "PALLM"];
  const IS_NTL_POLICY =
    compareStrings(majorProductCode, "NTL") &&
    compareArrayOfStrings(adminSystems, agreementKeyAdmin);

  if (IS_NTL_POLICY) {
    return true;
  }
  return false;
};

export const isVariableLife = (props) => {
  const {
    agreementKeyAdmin,
    lineOfBusinessCode,
    majorProductCode,
    minorProductCode,
  } = props;

  const agreementKeyAdmins = ["PE1", "LVRGVL", "LIFCOM"];
  return (
    compareArrayOfStrings(agreementKeyAdmins, agreementKeyAdmin) &&
    compareStrings(lineOfBusinessCode, "LIFE") &&
    compareStrings(majorProductCode, "NTL") &&
    compareArrayOfStrings(["VL", "SVUL"], minorProductCode)
  );
};

export const isLVRGVLorLIFCOM = (props) => {
  const {
    agreementRequest: {
      data: {
        agreementKeyAdmin,
        majorProductCode,
        lineOfBusinessCode,
        minorProductCode,
      },
    },
  } = props;

  const agreementKeyAdmins = ["LVRGVL", "LIFCOM"];
  return (
    compareArrayOfStrings(agreementKeyAdmins, agreementKeyAdmin) &&
    compareStrings(lineOfBusinessCode, "LIFE") &&
    compareStrings(majorProductCode, "NTL") &&
    compareStrings(minorProductCode, "VL")
  );
};

export const isTermOrPermPolicy = (props) =>
  isPermPolicy(props) || isTermPolicy(props);

export const isLifeNonTerm = (props) => {
  const {
    data: { agreementKeyAdmin, majorProductCode },
  } = props;
  const possibleAgreementKeyAdmins = [
    "OPM",
    "MPR",
    "VNTG1",
    "PE1",
    "LIFCOM",
    "LVRGVL",
    "HAP",
  ];
  const possibleMajorProductCodes = ["PERM", "NTL"];
  return (
    compareArrayOfStrings(possibleAgreementKeyAdmins, agreementKeyAdmin) &&
    compareArrayOfStrings(possibleMajorProductCodes, majorProductCode)
  );
};

export const isTermPermOrNTLPolicy = (props) =>
  isPermPolicy(props) || isTermPolicy(props) || isNTLPolicy(props);

export const isCC1PolicyWithLTCIR = (props) => {
  const {
    agreementRequest: {
      data: { agreementKeyAdmin, majorProductCode, productTypeCode },
    },
  } = props;
  const productTypesCodes = ["CC1", "CC01", "CC12"];
  return (
    compareStrings(agreementKeyAdmin, "VNTG1") &&
    compareStrings(majorProductCode, "PERM") &&
    compareArrayOfStrings(productTypesCodes, productTypeCode)
  );
};

export const isVNTG1CC10112NBL = (props) => {
  const {
    agreementRequest: {
      data: { agreementKeyAdmin, productTypeCode },
    },
    billingRequest: {
      data: { billingMode },
    },
  } = props;
  const productTypesCodes = ["CC1", "CC01", "CC12"];
  if (
    compareStrings(agreementKeyAdmin, "VNTG1") &&
    compareStrings(billingMode, "NBL") &&
    compareArrayOfStrings(productTypesCodes, productTypeCode)
  ) {
    return true;
  }
  return false;
};

export const isPE1NTLVL = (props) => {
  const {
    agreementRequest: {
      data: { agreementKeyAdmin, majorProductCode, minorProductCode },
    },
  } = props;

  return (
    compareStrings(agreementKeyAdmin, "PE1") &&
    compareStrings(majorProductCode, "NTL") &&
    compareStrings(minorProductCode, "VL")
  );
};

export const verifyInitiateNewLoan = (props) => {
  const {
    loanData: { isEligible = "false" },
    agreementData: { agreementKey },
    transactionSupportedPolicies,
  } = props;

  const { isTransactionAllowed } = checkIfTransactionIsAllowed({
    transactionSupportedPolicies,
    transactionName: supportedTransactions.InitiateNewLoan,
    agreementData: props.agreementData,
  });

  const initiateNewLoanLink = `/policyDetails/${encodeURIComponent(
    agreementKey
  )}/initiatenewloan`;

  const loanRestrictedSwlPolicy = isLoanRestrictedSwlPolicy(
    props.agreementData
  );

  const returnObject = {
    redirectLink: initiateNewLoanLink,
    isEligible: compareStrings(isEligible, "true") && !loanRestrictedSwlPolicy,
    isTransactionAllowed,
    eligibilityError: loanRestrictedSwlPolicy
      ? LOAN_RESTRICTED_SWL_POLICY_ERROR_MESSAGE
      : DEFAULT_404_ERROR_MESSAGE,
  };

  return returnObject;
};

export const verifyRequestInformation = (props) => {
  const { agreementData, transactionSupportedPolicies } = props;

  const { agreementKey, status, statusReason } = agreementData;

  const ENVISION_POLICY = isEnvisionPolicy(agreementData);
  const IS_IN_FORCE =
    compareStrings(status, "IF") || compareStrings(statusReason, "DP");
  const isEligible = IS_IN_FORCE && !ENVISION_POLICY;

  const { isTransactionAllowed } = checkIfTransactionIsAllowed({
    transactionSupportedPolicies,
    transactionName: supportedTransactions.RequestInformation,
    agreementData,
  });

  const returnObject = {
    redirectLink: `/policyDetails/${encodeURIComponent(
      agreementKey
    )}/requestInformation`,
    isEligible,
    isTransactionAllowed,
  };

  return returnObject;
};

export const verifyDigitalRegistration = (props) => {
  const { agreementData, transactionSupportedPolicies, customer } = props;

  const { agreementKey, status } = agreementData;
  const { memberId } = customer;

  const isUserRegistered = isRegisteredOnMM(customer);

  const policyInForce = isPolicyInForce(status);

  const isEligible =
    policyInForce && !isUserRegistered && compareStrings(customer.type, "I");

  const { isTransactionAllowed } = checkIfTransactionIsAllowed({
    transactionSupportedPolicies,
    transactionName: supportedTransactions.DigitalRegistration,
    agreementData,
  });

  const returnObject = {
    redirectLink: `/policyDetails/${encodeURIComponent(
      agreementKey
    )}/${memberId}/digitalRegistration`,
    isEligible,
    isTransactionAllowed,
  };

  return returnObject;
};

export const checkIfTransactionIsAllowed = ({
  transactionSupportedPolicies,
  transactionName,
  agreementData = {},
}) => {
  const { lineOfBusinessCode, majorProductCode } = agreementData;

  const allowedPolicies =
    transactionSupportedPolicies?.[transactionName]?.[lineOfBusinessCode]?.[
      majorProductCode
    ] || {};

  let isTransactionAllowed = false;
  Object.entries(allowedPolicies).forEach((checkArray) => {
    const agreementPropertyName = checkArray[0];
    const agreementPropertyValues = checkArray[1];
    if (!isTransactionAllowed) {
      agreementPropertyValues.forEach(() => {
        isTransactionAllowed = allowedPolicies[agreementPropertyName]?.includes(
          agreementData[agreementPropertyName]
        );
      });
    }
  });

  return {
    isTransactionAllowed,
  };
};

export const removeOwnerAndInsuredDuplicates = (customers = []) =>
  customers.filter((customer, index, customerList) => {
    // if the govermentId is undefined or missing on customer object then treat as unique customer..
    if (!isDefined(customer.governmentId)) return true;

    // if cutomer's memberId is unique in the array then treat as unique customer..
    if (
      customerList.filter((y) => y.memberId === customer.memberId).length === 1
    )
      return true;

    // if more than one element in the array has same memberId We check the following
    // if the Names are equal on those elements then check govtId is equal on those elements
    // If All the above is true the element is dupe else unique..
    return (
      customerList.findIndex((t) => {
        const MEM_ID_MATCH = compareStrings(t.memberId, customer.memberId);
        const FULL_NAME_MATCH = compareStrings(t.fullName, customer.fullName);

        let NAME_MATCH;
        if (isDefined(t.middleName) || isDefined(customer.middleName)) {
          NAME_MATCH =
            compareStrings(t.firstName, customer.firstName) &&
            compareStrings(t.lastName, customer.lastName) &&
            compareStrings(t.middleName, customer.middleName);
        } else {
          NAME_MATCH =
            compareStrings(t.firstName, customer.firstName) &&
            compareStrings(t.lastName, customer.lastName);
        }

        const GOV_ID_MATCH = compareStrings(
          t.governmentId,
          customer.governmentId
        );

        if (compareStrings(customer.type, "I") || compareStrings(t.type, "I")) {
          if (NAME_MATCH && GOV_ID_MATCH && MEM_ID_MATCH) {
            return true;
          }
          return false;
        }
        if (compareStrings(customer.type, "N") || compareStrings(t.type, "N")) {
          if (FULL_NAME_MATCH && GOV_ID_MATCH && MEM_ID_MATCH) {
            return true;
          }
          return false;
        }
        return false;
      }) === index
    );
  });

export const findMatchingBeneficiaries = (currentItem, item) => {
  const MEM_ID_MATCH = compareStrings(currentItem.memberId, item.memberId);
  const FULL_NAME_MATCH = compareStrings(currentItem.fullName, item.fullName);

  const isMiddleNameMatch =
    compareStrings(currentItem.middleName, item.middleName) ||
    isNullOrUndefined(currentItem.middleName);

  const isFirstNameMatch =
    compareStrings(currentItem.firstName, item.firstName) ||
    isNullOrUndefined(currentItem.firstName);

  const isLastNameMatch =
    compareStrings(currentItem.lastName, item.lastName) ||
    isNullOrUndefined(currentItem.lastName);

  const NAME_MATCH = isFirstNameMatch && isMiddleNameMatch && isLastNameMatch;
  const GOV_ID_MATCH =
    compareStrings(currentItem.governmentId, item.governmentId) ||
    isNullOrUndefined(currentItem.governmentId);

  return {
    MEM_ID_MATCH,
    FULL_NAME_MATCH,
    NAME_MATCH,
    GOV_ID_MATCH,
  };
};

export const groupAndRemoveBeneficiaryDuplicates = (
  customeritem,
  peopleList = []
) =>
  peopleList.filter(() => {
    if (
      peopleList.filter((t) => {
        const { MEM_ID_MATCH, FULL_NAME_MATCH, NAME_MATCH, GOV_ID_MATCH } =
          findMatchingBeneficiaries(t, customeritem);
        if (compareStrings(t.type, "I")) {
          if (NAME_MATCH && GOV_ID_MATCH && MEM_ID_MATCH) {
            return true;
          }
        }
        if (compareStrings(t.type, "N")) {
          if (FULL_NAME_MATCH && GOV_ID_MATCH && MEM_ID_MATCH) {
            return true;
          }
        }
        return false;
      }).length === 1
    ) {
      return true;
    }
    return false;
  });

export const isPolicyInForce = (status) => compareStrings(status, "IF");

export const isEnvisionPolicy = (data) => {
  if (!data) return false;
  const { agreementKeyAdmin, lineOfBusinessCode, productTypeCode } = data;
  return (
    compareStrings(agreementKeyAdmin, "ANNWMA") &&
    compareStrings(lineOfBusinessCode, "ANN") &&
    (compareStrings(productTypeCode, "ENVISION") ||
      compareStrings(productTypeCode, "MASSMUTUAL ENVISION"))
  );
};

export const isDisabilityPolicy = (data) => {
  const { lineOfBusinessCode } = data;
  return compareStrings(lineOfBusinessCode, "DI");
};
export const isLifePolicy = (data) => {
  const { lineOfBusinessCode } = data;
  return compareStrings(lineOfBusinessCode, "LIFE");
};

export const isRadiusChoicePolicy = (data) => {
  if (!data?.baseCoverage) return false;
  const { baseCoverage } = data;
  return startsWithStrings(baseCoverage.marketingName, "Radius Choice");
};

export const isAnnuityContract = (lineOfBusinessCode) => {
  if (!lineOfBusinessCode) return false;
  return compareStrings(lineOfBusinessCode, "ANN");
};

export const isOdysseyAnnuityProduct = (productPrefix) => {
  if (!productPrefix) return false;
  return compareArrayOfStrings(ANNUITY_ODYSSEY_PREFIXES, productPrefix);
};

export const isStableVoyageAnnuityProduct = (productPrefix) => {
  if (!productPrefix) return false;
  return compareArrayOfStrings(ANNUITY_STABLE_VOYAGE_PREFIXES, productPrefix);
};

const annuityRiderPrefixes = [
  ...ANNUITY_STABLE_VOYAGE_PREFIXES,
  ...ANNUITY_ODYSSEY_PREFIXES,
  ...ANNUITY_NON_GLWB_PRODUCT_TYPE_CODE,
  ...ANNUITY_EVOLUTION_PREFIXES,
];

export const isNonGLWBRiders = (productPrefix) => {
  if (!productPrefix) return false;
  return compareArrayOfStrings(annuityRiderPrefixes, productPrefix);
};

export const isNonGLWBRidersByTitle = (title) => {
  if (!title) return false;
  return compareArrayOfStrings(ANNUITY_NON_GLWB_TITLES, title);
};

export const isArtistryAnnuityProduct = (productPrefix) => {
  if (!productPrefix) return false;
  return compareArrayOfStrings(ANNUITY_ARTISTRY_PREFIXES, productPrefix);
};

export const isFlexExtraAnnuityProduct = (kindCode) => {
  if (!kindCode) return false;
  return compareArrayOfStrings(
    VARIABLE_ANNUITY_FLEX_EXTRA_KIND_CODES,
    kindCode
  );
};

export const isNonEnvisionAnnuity = (agreementRequest) =>
  isAnnuityContract(agreementRequest?.data?.lineOfBusinessCode) &&
  !isEnvisionPolicy(agreementRequest?.data);
export const isPallmPolicy = (data) => {
  const { lineOfBusinessCode, agreementKeyAdmin } = data;
  return (
    compareStrings(lineOfBusinessCode, "LIFE") &&
    compareStrings(agreementKeyAdmin, "PALLM")
  );
};
export const isFixedAnnuity = (agreementRequest) =>
  compareStrings(agreementRequest?.data.lineOfBusinessCode, "ANN") &&
  compareStrings(agreementRequest?.data.majorProductCode, "FA");

export const isVariableAnnuity = (agreementRequest) =>
  compareStrings(agreementRequest?.data.lineOfBusinessCode, "ANN") &&
  compareStrings(agreementRequest?.data.majorProductCode, "VA");

export const getVerifyPolicyAgreementInfo = (props) => {
  const { agreementRequest, user } = props;
  const { data } = agreementRequest;

  return {
    isEnvisionPolicy: isEnvisionPolicy(data),
    hasHomeOfficeUserGroup: compareArrayOfStrings(
      user.groups,
      "fim-servicenet-homeoffice"
    ),
    isMmsdOnly: compareStrings(user.agentType, typesOfAgents.MMSD),
  };
};

export const isCoverpathPolicy = (agreementKeyAdmin) =>
  compareStrings(agreementKeyAdmin, "HAP");

export const isRetireEaseContract = (agreementData) => {
  const retireEaseKindCodeList = ["RCH012", "RET012"];
  const { kindCode, lineOfBusinessCode } = agreementData;

  return (
    compareStrings(lineOfBusinessCode, "ANN") &&
    compareArrayOfStrings(retireEaseKindCodeList, kindCode)
  );
};

export const isRetireEaseChoiceContract = (agreementData) => {
  const { kindCode, lineOfBusinessCode, productTypeCode } = agreementData;
  return (
    compareStrings(lineOfBusinessCode, "ANN") &&
    compareStrings(kindCode, "RCH012") &&
    compareStrings(productTypeCode, "RETEACH")
  );
};

export const hasVNTGKCAdmin = (agreementKeyAdmin) =>
  compareStrings(agreementKeyAdmin, "VNTGKC");

export const isSE2Policy = (data) => {
  const { agreementKeyAdmin } = data;
  return compareStrings(agreementKeyAdmin, "SE2");
};

export const isSignatureCareLtcPolicy = (data) => {
  const { lineOfBusinessCode, minorProductCode } = data;
  return (
    compareStrings(lineOfBusinessCode, "LTC") &&
    compareStrings(minorProductCode, "SGN CARE")
  );
};

export const isFlexIVContract = (kindCode) => {
  const flexIVKindCodes = ["FPPVA1", "FPPVA2"];
  return compareArrayOfStrings(flexIVKindCodes, kindCode);
};

export const isFund1FlexiblePremiumContract = (kindCode) => {
  const fund1FlexPremKindCodes = [
    "PVA160",
    "PVA165",
    "PVA168",
    "PVA170",
    "PVA175",
  ];
  return compareArrayOfStrings(fund1FlexPremKindCodes, kindCode);
};

export const isFund4FlexiblePremiumContract = (kindCode) => {
  const fund4FlexPremKindCodes = [
    "PVA460",
    "PV462",
    "PVA465",
    "PVA470",
    "PVA475",
  ];
  return compareArrayOfStrings(fund4FlexPremKindCodes, kindCode);
};

export const isFlexIVFund1FPOrFund4FP = (kindCode) =>
  isFlexIVContract(kindCode) ||
  isFund1FlexiblePremiumContract(kindCode) ||
  isFund4FlexiblePremiumContract(kindCode);
export const isFoundationFlexiblePremium = (agreementData) => {
  const foundationFlexibleKindCodeList = ["QFPFA", "82500", "82600", "NQFPFA"];
  const { kindCode, agreementKeyAdmin } = agreementData;

  return (
    compareStrings(agreementKeyAdmin, "LIFCOM") &&
    compareArrayOfStrings(foundationFlexibleKindCodeList, kindCode)
  );
};

export const isFoundationSinglePremium = (agreementData) => {
  const foundationSingleKindCodeList = [
    "QSPFA1",
    "83600",
    "NQSPFA5",
    "83500",
    "NQSPFA3",
    "QSPFA5",
    "QSPFA3",
    "NQSPFA1",
  ];
  const { kindCode, agreementKeyAdmin } = agreementData;

  return (
    compareStrings(agreementKeyAdmin, "LIFCOM") &&
    compareArrayOfStrings(foundationSingleKindCodeList, kindCode)
  );
};

export const isTermCoverPath = (agreementData) => {
  const { agreementKeyAdmin, majorProductCode } = agreementData;

  return (
    compareStrings(agreementKeyAdmin, "HAP") &&
    compareStrings(majorProductCode, "TERM")
  );
};

export const isPermCoverPath = (agreementData) => {
  const { agreementKeyAdmin, majorProductCode } = agreementData;

  return (
    compareStrings(agreementKeyAdmin, "HAP") &&
    compareStrings(majorProductCode, "PERM")
  );
};

export const isCMWindowsAnnuityProduct = (kindCode) => {
  const CMWindowsKindCodes = ["SPDA", "SPDA10", "SPDN", "SPDN10"];
  return compareArrayOfStrings(CMWindowsKindCodes, kindCode);
};

export const isEquityEdgeAnnuityProduct = (kindCode) => {
  const kindCodeList = ["EQEDGE", "EQMVA1", "EQUITY"];
  return compareArrayOfStrings(kindCodeList, kindCode);
};

export const isPanoramaAnnuityProduct = (kindCode) => {
  const kindCodeList = [
    "VASFPA",
    "VASFPB",
    "VASFPN",
    "VASOFA",
    "VASOFB",
    "VASOPE",
    "VASPDF",
    "VASSPE",
    "VASFPE",
  ];
  return compareArrayOfStrings(kindCodeList, kindCode);
};

export const isPanoramaPlusAnnuityProduct = (kindCode) => {
  const kindCodeList = ["FPVA", "FPVN", "PAPLAN", "PAPLNN"];
  return compareArrayOfStrings(kindCodeList, kindCode);
};

export const isEvolutionAnnuityProduct = (kindCode) =>
  compareStrings("EVOLUT", kindCode);

export const isPanoramaPassageAnnuityProduct = (kindCode) => {
  const kindCodeList = [
    "NCDFI0",
    "NCDFI1",
    "NCDFI2",
    "NCDFN0",
    "NCDFN1",
    "NCDFN2",
    "NCDSI0",
    "NCDSI1",
    "NCDSI2",
    "NCDSN0",
    "NCDSN2",
  ];
  return compareArrayOfStrings(kindCodeList, kindCode);
};

export const isPanoramaPremierAnnuityProduct = (kindCode) => {
  const kindCodeList = [
    "NYPIRA",
    "NYPPRE",
    "PANWAN",
    "PANWAI",
    "PANIRA",
    "PANPRE",
  ];
  return compareArrayOfStrings(kindCodeList, kindCode);
};

export const isTransitionsAnnuityProduct = (kindCode) => {
  const kindCodeList = ["TRAPKG", "TRAPKN", "TRCAFE", "TRCAFN"];
  return compareArrayOfStrings(kindCodeList, kindCode);
};

export const isTransitionsCustomPackageAnnuityProduct = (kindCode) => {
  const kindCodeList = ["TRCAFE", "TRCAFN"];
  return compareArrayOfStrings(kindCodeList, kindCode);
};

export const isTransitionsSelectAnnuityProduct = (kindCode) =>
  compareStrings("TRANSL", kindCode);

export const isLifeTrustAnnuityProduct = (kindCode) => {
  const kindCodeList = ["OMCMVA", "MVA001", "NONMVA", "OMCNON"];
  return compareArrayOfStrings(kindCodeList, kindCode);
};

export const isSwlSulSvlLifeProduct = (agreementData) => {
  const { productTypeName, majorProductName, agreementKeyAdmin } =
    agreementData;
  return (
    (compareArrayOfStrings(
      SURVIVORSHIP_NON_TRAD_PE_PRODUCT_NAMES,
      productTypeName
    ) &&
      compareStrings(majorProductName, "Non-Traditional Life") &&
      compareStrings(agreementKeyAdmin, "PE1")) ||
    (compareArrayOfStrings(
      SURVIVORSHIP_TRAD_MPR_PRODUCT_NAMES,
      productTypeName
    ) &&
      compareStrings(majorProductName, "Traditional Permanent") &&
      compareStrings(agreementKeyAdmin, "MPR")) ||
    (compareArrayOfStrings(
      SURVIVORSHIP_TRAD_OPM_PRODUCT_NAMES,
      productTypeName
    ) &&
      compareStrings(majorProductName, "Traditional Permanent") &&
      compareStrings(agreementKeyAdmin, "OPM")) ||
    (compareArrayOfStrings(
      SURVIVORSHIP_TRAD_VNTG1_PRODUCT_NAMES,
      productTypeName
    ) &&
      compareStrings(majorProductName, "Traditional Permanent") &&
      compareStrings(agreementKeyAdmin, "VNTG1"))
  );
};

export const isRegisteredOnMM = (props) => {
  const { amcID, amcidStatusCode } = props;
  return (
    compareStrings(amcidStatusCode, "HREG360") && !stringIsEmptyOrNullish(amcID)
  );
};

export const getCatsDivAndDeptCodeByLineOfBusinessCode = (
  lineOfBusinessCode
) => {
  let divCode = "";
  let deptCode = "";
  switch (lineOfBusinessCode.toLowerCase()) {
    case "life":
      divCode = "CS";
      deptCode = "DS";
      break;
    case "di":
      divCode = "DI";
      deptCode = "DI";
      break;
    case "ann":
      divCode = "AN";
      deptCode = "AC";
      break;
    default:
      break;
  }
  return { deptCode, divCode };
};

export const isLoanRestrictedSwlPolicy = (agreementData) => {
  const { kindCode, jurisdictionState, issueDate } = agreementData;

  const withinOneYearOfIssueDate = isBeforeDate(
    getCurrentDate(),
    addDaystoDate(issueDate, 366)
  );

  return (
    compareArrayOfStrings(
      ["W190S0", "W191S0", "W210S0", "W211S0", "3220S0", "3221S0"],
      kindCode
    ) &&
    withinOneYearOfIssueDate &&
    !compareStrings(jurisdictionState, "FL")
  );
};

export const isArtistryProduct = (kindCode) =>
  compareArrayOfStrings(VARIABLE_ANNUITY_ARTISTRY_KIND_CODES, kindCode);

export const isFlexPurchasePayIVorVProduct = (kindCode) => {
  const kindCodeList = ["80500", "80600"];

  return compareArrayOfStrings(kindCodeList, kindCode);
};

export const isFlexPurchaseVaFund1or4Product = (kindCode) =>
  compareStrings("80100", kindCode);

export const isFund1SinglePremiumProduct = (kindCode) =>
  compareStrings("SVA101", kindCode);

export const isFund4SinglePremiumProduct = (kindCode) =>
  compareStrings("SVA401", kindCode);
