import React from "react";
import { useConfirmationProps } from "../hooks";
import { fireTealiumAndFollowLink } from "../../../../utils/tealium";
import { isDefined } from "../../../../utils/evaluate";
import IsTruthy from "../../../common/logic/isTruthy";

const Confirmation = (props) => {
  const {
    owner,
    jointOwner,
    jointAnnuitant,
    marketingName,
    fundValueAmount,
    productTypeName,
    policyId,
    userEmail,
  } = props;

  const {
    currentDateTimeAndTimezone,
    financialProfessionalEmail,
    agreementKey,
    transferToFundDisplayName,
    financialProfessionalName,
  } = useConfirmationProps();

  return (
    <div>
      <div className="row">
        <div className="col-md-9">
          <h1 className="h2">
            <picture>
              <span
                role="img"
                className="illustration-confetti hide-print"
                title="Success Confetti"
              />
            </picture>
            Thank you! Your Fund Transfer Request has been submitted.
          </h1>
        </div>
        <div className="col-3">
          <div id="printButton" className="justify-content-end">
            <button
              onClick={() => window.print()}
              data-bp-link="default"
              className="btn btn-inline hide-print"
              aria-label="Print"
              type="button"
            >
              <span className="icon-print" />
              <span>Print</span>
            </button>
          </div>
        </div>
      </div>
      <p className="mm-spacing--minor">
        The MML Asset Allocation Fund Change was submitted on{" "}
        {currentDateTimeAndTimezone}. The change will be processed based on the
        close of the NYSE (generally 4:00 PM ET). All requests received after
        close of the New York Stock Exchange (generally 4:00 PM ET) will be
        processed on the following business day. Please review Service History
        for processing time and updates. If you have any questions regarding
        this request, please call us at 1-800-272-2216 8:00 AM - 8:00 PM ET,
        Monday through Friday and reference the contract number.
      </p>
      <hr className="mm-spacing" />
      <div className="row mm-spacing--minor">
        <div className="col-md-7">
          <dl className="mm-key-value-pair">
            <dt id="confirmation_total_estimated_value_lbl">
              Total Estimated Value
            </dt>
            <dd id="confirmation_total_estimated_value_value">
              <b>{`${fundValueAmount}`}</b>
            </dd>
            <dt id="confirmation_transfer_from_lbl">Transfer From</dt>
            <dd id="confirmation_transfer_from_value">
              <b>{marketingName}</b>
            </dd>
            <dt id="confirmation_transfer_to_lbl">Transfer To</dt>
            <dd id="confirmation_transfer_to_value">
              <b>{transferToFundDisplayName}</b>
            </dd>
            <dt id="confirmation_transfer_percent_lbl">Transfer Percent</dt>
            <dd id="confirmatio_transfer_percent_value">100%</dd>
            <dt id="confirmation_contract_number_lbl">Contract Number</dt>
            <dd id="confirmation_contract_number_value">{policyId}</dd>
            <dt id="confirmation_product_name_lbl">Product Name</dt>
            <dd id="confirmation_product_name_value">{productTypeName}</dd>
            <dt id="confirmation_owner_name_lbl">Owner Name</dt>
            <dd id="confirmation_owner_name_value">{owner}</dd>
            <IsTruthy value={isDefined(jointOwner)}>
              <dt id="confirmation_joint_owner_name_lbl">Joint Owner Name</dt>
              <dd id="confirmation_joint_owner_name_value">{jointOwner}</dd>
            </IsTruthy>
            <IsTruthy value={isDefined(jointAnnuitant)}>
              <dt id="confirmation_joint_annuitant_name_lbl">
                Joint Annuitant Name
              </dt>
              <dd id="confirmation_joint_annuitant_name_value">
                {jointAnnuitant}
              </dd>
            </IsTruthy>
            <dt id="confirmation_financial_professional_lbl">
              Financial Professional
            </dt>
            <dd id="confirmation_financial_professional_value">
              {financialProfessionalName}
            </dd>
            <dt id="confirmation_financial_professional_email_lbl">
              Financial Professional Email
            </dt>
            <dd id="confirmation_financial_professional_email_value">
              {financialProfessionalEmail}
            </dd>
            <dt id="confirmation_request_dateTime_lbl">Request Date/Time</dt>
            <dd id="confirmation_request_dateTime_value">
              {currentDateTimeAndTimezone}
            </dd>
            <dt id="confirmation_effective_date_lbl">Effective Date</dt>
            <dd id="confirmation_effective_date_value">
              {currentDateTimeAndTimezone}
            </dd>
          </dl>
        </div>
      </div>
      <button
        type="button"
        id="confirmation_serviceHistory_link_btn"
        data-bp-link="default"
        onClick={() =>
          fireTealiumAndFollowLink({
            url: `/policyDetails/${encodeURIComponent(
              agreementKey
            )}/viewServiceHistory`,
            action: "View Service History link clicked",
            category: "Fund Transfer",
            value: policyId,
            custId: userEmail,
          })
        }
        className="btn btn-primary hide-print"
      >
        VIEW SERVICE HISTORY
      </button>
      <a
        href={`/policyDetails/${encodeURIComponent(agreementKey)}`}
        className="btn btn-link hide-print"
      >
        Close
      </a>
    </div>
  );
};
export default Confirmation;
