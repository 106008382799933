import React from "react";
import Table from "../../../common/table";
import LoadingSection, {
  errorTypes,
  loadingTypes,
} from "../../../common/loading";
import { useServiceHistoryProps } from "./hooks";
import IsTruthy from "../../../common/logic/isTruthy";
import IsFalsy from "../../../common/logic/isFalsy";
import { NO_SERVICE_HISTORY_ALERT_TEXTS } from "../../../../constants/ui";

import { fireTealiumAndFollowLink } from "../../../../utils/tealium";

const ServiceHistory = (props) => {
  const { id, agreementKey, policyId, email, serviceRequest } = props;
  const {
    table: { columns, rows },
    showExtendedPageLink,
    isHap,
    isAnnuityProduct,
    contactUsTime,
    contactUsNumber,
  } = useServiceHistoryProps(props);
  return (
    <div id="service_history_section" className="mm-spacing">
      <h2 aria-label="Service History" id={id}>
        Service History
      </h2>
      <LoadingSection
        request={serviceRequest}
        loadingType={loadingTypes.PARAGRAPH}
        errorType={errorTypes.SECTION}
      >
        <div className="mm-spacing--minor">
          <IsTruthy value={rows.length < 1}>
            <IsTruthy value={isHap}>
              <div
                className="alert alert-info mm-spacing"
                role="alert"
                id="no_coverpath_service_history_available_alert"
              >
                There is no service history online for this policy. If you have
                any questions, contact our Coverpath Customer Service at{" "}
                <a href="tel:+1-866-957-5347">866-957-5347</a>, 8:00 AM - 6:00
                PM ET, Monday through Friday.
              </div>
            </IsTruthy>
            <IsFalsy value={isHap}>
              <IsTruthy value={isAnnuityProduct}>
                <div
                  className="alert alert-info mm-spacing"
                  role="alert"
                  id="no_annuity_service_history_available_alert"
                >
                  {NO_SERVICE_HISTORY_ALERT_TEXTS.contract}
                  <a href={`tel:+${contactUsNumber}`}>
                    {contactUsNumber}
                  </a>, {contactUsTime}
                </div>
              </IsTruthy>
              <IsFalsy value={isAnnuityProduct}>
                <div
                  className="alert alert-info mm-spacing"
                  role="alert"
                  id="no_non_annuity_service_history_available_alert"
                >
                  {NO_SERVICE_HISTORY_ALERT_TEXTS.policy}
                  <a href={`tel:+${contactUsNumber}`}>
                    {contactUsNumber}
                  </a>, {contactUsTime}
                </div>
              </IsFalsy>
            </IsFalsy>
          </IsTruthy>
          <IsTruthy value={rows.length > 0}>
            <Table
              id="service_history"
              columns={columns}
              rows={rows}
              showPagination={false}
            />
            <IsTruthy value={showExtendedPageLink}>
              <p id="view_all_service_history_lbl">
                For additional work events,{" "}
                <button
                  className="btn btn-inline"
                  id="view_all_service_history_link"
                  type="button"
                  onClick={() =>
                    fireTealiumAndFollowLink({
                      url: `/policyDetails/${encodeURIComponent(
                        agreementKey
                      )}/viewServiceHistory`,
                      action: "View Service history link clicked",
                      category: "PDP",
                      value: policyId,
                      custId: email,
                    })
                  }
                >
                  view Service History.
                </button>
              </p>
            </IsTruthy>
          </IsTruthy>
        </div>
      </LoadingSection>
    </div>
  );
};

export default React.memo(ServiceHistory);
