import React from "react";
import { contactUSDetails } from "../../data/contactUsDetails";

const ContactUs = () => (
  <div>
    <div className="mm-spacing">
      <h1>Call Centers</h1>
      <h2 className="h3">
        At this time, some lines of business will not appear in ServiceNet 2.0.
        <br />
        Get the service support you need by calling one of our Call Centers
      </h2>
    </div>

    <div className="row">
      <div className="col-md-8">
        <p>
          <span className="h4">Worksite</span>{" "}
          <span className="copy-sm">
            (Accident, Critical Illness and Life Insurance)
          </span>
        </p>

        <div className="row no-gutters">
          <div className="col-1">
            <span className="icon-call-black" />
          </div>

          <div className="col-11">
            <ul className="list-unstyled">
              <li>
                <a href={`tel:+${contactUSDetails.WORKSITE.number}`}>
                  {contactUSDetails.WORKSITE.number}
                </a>
              </li>
              <li className="copy-sm">{contactUSDetails.WORKSITE.time}</li>
            </ul>
          </div>
        </div>

        <hr />

        <p>
          <span className="h4">Coverpath</span>{" "}
          <span className="copy-sm">(Disability and Annuities)</span>
        </p>

        <div className="row no-gutters">
          <div className="col-1">
            <span className="icon-call-black" />
          </div>

          <div className="col-11">
            <ul className="list-unstyled">
              <li>
                <a href={`tel:+${contactUSDetails.COVERPATH.number}`}>
                  {contactUSDetails.COVERPATH.number}
                </a>
              </li>
              <li className="copy-sm">{contactUSDetails.COVERPATH.time}</li>
            </ul>
          </div>
        </div>

        <hr />

        <p className="h4">Corporate Owned and Bank Owned Life Insurance</p>

        <div className="row no-gutters">
          <div className="col-1">
            <span className="icon-call-black" />
          </div>

          <div className="col-11">
            <ul className="list-unstyled">
              <li>
                <a href={`tel:+${contactUSDetails.CORP.number}`}>
                  {contactUSDetails.CORP.number}
                </a>
              </li>
              <li className="copy-sm">{contactUSDetails.CORP.time}</li>
            </ul>
          </div>
        </div>

        <hr />

        <p className="h4">
          Individual Protection Products and Other Unsupported Products
        </p>

        <div className="row no-gutters">
          <div className="col-1">
            <span className="icon-call-black" />
          </div>

          <div className="col-11">
            <ul className="list-unstyled">
              <li>
                <a href={`tel:+${contactUSDetails.DEFAULT.number}`}>
                  {contactUSDetails.DEFAULT.number}
                </a>
              </li>
              <li className="copy-sm">{contactUSDetails.DEFAULT.time}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default React.memo(ContactUs);
