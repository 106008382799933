import React from "react";
import { PRE_AUTHORIZED_CHECK } from "../../../../constants/logic";
import IsTruthy from "../../../common/logic/isTruthy";
import { InformationPopover } from "../../../common/popovers/informationPopover";
import IsFalsy from "../../../common/logic/isFalsy";
import { PREAUTH_TOTAL_DRAFT_AMOUNT_TOOLTIP_TEXT } from "../../../../constants/ui";
import { compareStrings } from "../../../../utils/string";
import RecurringPayment from "./recurringPayment";

const AutopayInformation = (props) => {
  const { data, agreementKeyAdmin } = props;
  const {
    accountNumber,
    accountType,
    amount,
    bankAccountHolder,
    bankName,
    draftDay,
    paymentPreference,
    pacAccountNumber,
    routingNumber,
    totalAmount,
    displayAutopayInformation,
    isOnPremiumInterest,
    isOnAutoPayLoan,
    isOnAutoPayLoanInterest,
  } = data;

  return (
    <IsTruthy value={displayAutopayInformation}>
      <div className="mm-spacing--minor">
        <h3 id="autopay_information_lbl">Autopay Information</h3>
        <IsTruthy
          value={paymentPreference}
          validationFunction={(preference) =>
            compareStrings(preference, PRE_AUTHORIZED_CHECK)
          }
        >
          <hr />
          <div className="row">
            <div className="col-12 col-md-6">
              <dl className="mm-key-value-pair">
                <dt id="autopay_type_lbl">
                  Autopay Type <sup className="print-only">1</sup>
                  <InformationPopover
                    popoverId="autopay_type_tooltip"
                    text="<b>Pre-Authorized Check (PAC)</b>
                    <ul>
                    <li>Available for Monthly billing frequency and Quarterly for DI only</li>
                    <li>Draft dates chosen by depositor (1st-28th)</li> 
                    <li>Premium only. Interest billed separately</li>
                    </ul>
                    <b>Recurring Payments</b> 
                    <ul>
                    <li>Available for Quarterly, Semi-Annual & Annual billing frequencies</li>
                    <li>Always withdrawn on policy Paid To Date</li>
                    <li>Premium/Interest, Loan Interest only, Loan Principal only</li>
                    <li>Withdrawal amount is determined by the amount billed </li>
                    <li>Can only be set up/canceled via <a href=https://www.massmutual.com>MassMutual.com</a></li>
                    </ul>"
                  />
                </dt>
                <dd id="autopay_type_value">Pre-Authorized Check (PAC)</dd>
                <dt id="preauth_depositor_name_lbl">Depositor Name</dt>
                <dd
                  id="preauth_depositor_name_value"
                  aria-label={bankAccountHolder}
                >
                  {bankAccountHolder}
                </dd>
                <IsFalsy value={agreementKeyAdmin === "EDS-DI"}>
                  <dt id="policy_draft_amount_lbl">Policy Draft Amount</dt>
                  <dd id="policy_draft_amount_value" aria-label={amount}>
                    {amount}
                  </dd>{" "}
                  <dt id="preauth_bank_name_lbl">Bank Name</dt>
                  <dd id="preauth_bank_name_value" aria-label={bankName}>
                    {bankName}
                  </dd>
                  <dt id="preauth_bank_account_number_lbl">
                    Bank Account Number
                  </dt>
                  <dd
                    id="preauth_bank_account_number_value"
                    aria-label={accountNumber}
                  >
                    {accountNumber}
                  </dd>
                </IsFalsy>
              </dl>
            </div>
            <div className="col-12 col-md-6">
              <dl className="mm-key-value-pair">
                <dt id="pac_account_number_lbl">PAC Account Number</dt>
                <dd id="pac_account_number_value" aria-label={pacAccountNumber}>
                  {pacAccountNumber}
                </dd>
                <dt id="draft_day_lbl">Draft Day</dt>
                <dd id="draft_day_value" aria-label={draftDay}>
                  {draftDay}
                </dd>
                <dt id="preauth_total_draft_amount_lbl">
                  Total Draft Amount <sup className="print-only">2</sup>
                  <InformationPopover
                    popoverId="preauth_total_draft_amount"
                    text={PREAUTH_TOTAL_DRAFT_AMOUNT_TOOLTIP_TEXT}
                  />
                </dt>
                <dd
                  id="preauth_total_draft_amount_value"
                  aria-label={totalAmount}
                >
                  {totalAmount}{" "}
                </dd>
                <dt id="preauth_routing_number_lbl">Routing Number</dt>
                <dd
                  id="preauth_routing_number_value"
                  aria-label={routingNumber}
                >
                  {routingNumber}
                </dd>
                <dt id="preauth_account_type_lbl">Account Type</dt>
                <dd id="preauth_account_type_value" aria-label={accountType}>
                  {accountType}
                </dd>
              </dl>
            </div>
          </div>
        </IsTruthy>
        <IsTruthy
          value={paymentPreference}
          validationFunction={(preference) =>
            preference === PRE_AUTHORIZED_CHECK
          }
        >
          <ol className="print-only" id="print_preview_tooltips">
            <li id="print_preview_autopay_type_tooltip">
              <div>
                <b>Pre-Authorized Check (PAC)</b>: Available for Monthly billing
                frequency and Quarterly for DI only, Draft dates chosen by
                depositor (1st-28th), Premium only. Interest billed separately.{" "}
              </div>
              <div>
                <b>Recurring Payments</b>: Available for Quarterly, Semi-Annual
                & Annual billing frequencies, Always withdrawn on policy Paid To
                Date, Premium/Interest, Loan Interest only, Loan Principal only,
                Withdrawal amount is determined by the amount billed, Can only
                be set up/canceled via https://www.massmutual.com{" "}
              </div>
            </li>
            <li id="print_preview_totaldraftamount">
              {PREAUTH_TOTAL_DRAFT_AMOUNT_TOOLTIP_TEXT}
            </li>
          </ol>
        </IsTruthy>
        <IsTruthy value={isOnPremiumInterest}>
          <RecurringPayment data={data} paymentAppliedTo="Premium/Interest" />
        </IsTruthy>
        <IsTruthy value={isOnAutoPayLoan}>
          <RecurringPayment data={data} paymentAppliedTo="Loan Principal" />
        </IsTruthy>
        <IsTruthy value={isOnAutoPayLoanInterest}>
          <RecurringPayment data={data} paymentAppliedTo="Loan Interest" />
        </IsTruthy>
      </div>
      <hr />
    </IsTruthy>
  );
};

export default AutopayInformation;
