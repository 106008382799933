import React from "react";
import LabelWithHelpModal from "../../common/forms/labelWithHelpModal";

const FileDisabilityClaimModalBody = () => (
  <div className="modal-body">
    <hr />
    <h5 id="directLink_DisabilityClaim_ModalTitle">File a Disability Claim</h5>
    <p>
      The following can be done on the <strong>website</strong>:
    </p>
    <ul>
      <li>
        This Direct Link allows a customer to digitally submit their DI Claim,
        including E-signature
      </li>
      <li>
        Customer will be prompted to download prefilled attending physician’s
        statement
      </li>
    </ul>
  </div>
);

const FileDisabilityClaimModal = () => (
  <LabelWithHelpModal
    text="File a Disability Claim"
    modalTitle="File a Disability Claim"
    modalId="fileDisabilityClaim"
    modalBodyComponent={FileDisabilityClaimModalBody}
  />
);

export default FileDisabilityClaimModal;
