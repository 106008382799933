import React from "react";
import CreateNameList from "../../common/nameList/createNameList";

const PolicyInformation = (props) => {
  const { owners, insured, productTypeName, policyId } = props;
  return (
    <div className="row mm-spacing">
      <div className="col-md-6 col-sm-12">
        <dl className="mm-key-value-pair--summation">
          <dt id="insured_name_lbl">Insured Name</dt>
          <dd id="insured_name_value">
            <CreateNameList list={insured} />
          </dd>
          <dt id="owner_name_lbl">Owner Name</dt>
          <dd id="owner_name_value">
            <CreateNameList list={owners} />
          </dd>
          <dt id="policy_number_lbl">Policy Number</dt>
          <dd id="policy_number_value">{policyId || "-"}</dd>
          <dt id="product_name_lbl">Product Name</dt>
          <dd id="product_name_value">{productTypeName}</dd>
        </dl>
      </div>
    </div>
  );
};

export default PolicyInformation;
