// eslint-disable-next-line import/prefer-default-export
export const processProducerData = (props) => {
  const apiData = props?.response?.data;

  const producers = apiData?.producers || [{}, {}];
  const [agent, address] = producers;

  const formattedAddress = {
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
    ...address,
  };
  return {
    agent,
    address: formattedAddress,
  };
};
