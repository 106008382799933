import React from "react";
import { Dropdown } from "react-bootstrap";
import InputForm from "../forms/inputForm";
import {
  calculateGooglePlaceMatchedSubStrings,
  useAddressTextboxProps,
} from "./hooks";

const GooglePredictedAddressLine1 = (props) => {
  const {
    placePredictions,
    isPlacePredictionsLoading,
    handlePlaceSelectedButtonClick,
    displayPlacesPredictionCssClass,
    useHook,
  } = useAddressTextboxProps(props);

  const { formField } = props;

  return (
    <>
      <InputForm formField={formField} useHook={useHook} />
      <Dropdown
        className={`${displayPlacesPredictionCssClass ? "flush" : ""}`}
        show={displayPlacesPredictionCssClass}
      >
        {!isPlacePredictionsLoading &&
          placePredictions?.map((item, index) => (
            <Dropdown.Item
              key={item.description}
              type="button"
              id={`place_prediction_button${index}`}
              data-testid={`place_prediction_button${index}`}
              onClick={handlePlaceSelectedButtonClick(item)}
            >
              {
                calculateGooglePlaceMatchedSubStrings(item)?.addressParts
                  ?.beforeAddressPart
              }
              <mark>
                {
                  calculateGooglePlaceMatchedSubStrings(item)?.addressParts
                    ?.matchedAddressPart
                }
              </mark>
              {
                calculateGooglePlaceMatchedSubStrings(item)?.addressParts
                  ?.afterAddressPart
              }
            </Dropdown.Item>
          ))}
      </Dropdown>
    </>
  );
};

export default GooglePredictedAddressLine1;
