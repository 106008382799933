import React from "react";
import Table from "../../../common/table";
import { useInsuredInformationProps } from "./hooks";

const InsuredInformation = (props) => {
  const { id } = props;
  const {
    table: { insuredColumns, rows },
  } = useInsuredInformationProps(props);

  return (
    <div className="mm-spacing--minor">
      <h3 aria-label="insuredInformation_header" id={id}>
        Insured Information
      </h3>
      <Table
        id="insuredInformation"
        tableClass="mm-spacing--none"
        columns={insuredColumns}
        rows={rows}
        showPagination={false}
      />
    </div>
  );
};
export default React.memo(InsuredInformation);
