// eslint-disable-next-line import/prefer-default-export
export const processHomeOfficeAgreementRequest = (props) => {
  const apiData = props?.response?.data;
  const agreements = apiData?.agreements || [];

  const agreementsArray = agreements.map((agreement) => {
    const agreementCustomers = agreement?.agreementCustomers || [];
    const policyId = agreement?.agreementKeyPrimaryDisplay || "";

    const {
      lineOfBusinessCode = "-",
      productTypeCode = "",
      productTypeName = "-",
      status = "-",
      agreementKeyPrimaryDisplay = "-",
      agreementKey = "",
      agreementKeyAdmin = "",
      agreementKeyPrefix = "",
      agreementKeySuffix = "",
    } = agreement;

    return {
      agreementKey,
      agreementKeyAdmin,
      policyId,
      agreementCustomers,
      agreementKeyPrimaryDisplay,
      lineOfBusinessCode,
      productTypeName,
      productTypeCode,
      status,
      agreementKeyPrefix,
      agreementKeySuffix,
    };
  });

  return agreementsArray;
};
