import React from "react";
import LabelWithHelpModal from "../../common/forms/labelWithHelpModal";

const InitiateDisabilityClaimModalBody = () => (
  <div className="modal-body">
    <hr />
    <h5 id="directLink_InitiateDisabilityClaim_ModalTitle">
      Initiate Disability Claim
    </h5>
    <p>
      The following can be done on the <strong>website</strong>:
    </p>
    <ul>
      <li>Customer can print required forms based on condition</li>
    </ul>
  </div>
);

const InitiateDisabilityClaimModal = () => (
  <LabelWithHelpModal
    text="Initiate Disability Claim"
    modalTitle="Initiate Disability Claim"
    modalId="initiateDisabilityClaim"
    modalBodyComponent={InitiateDisabilityClaimModalBody}
  />
);

export default InitiateDisabilityClaimModal;
