import React from "react";
import { Row, Col } from "react-bootstrap";
import { useEditAddressProps, useCountryDropdownProps } from "./hooks";
import DropdownForm from "../forms/dropdownForm";
import InputForm from "../forms/inputForm";
import CheckboxForm from "../forms/checkboxForm";
import IsTruthy from "../logic/isTruthy";
import IsFalsy from "../logic/isFalsy";
import { compareStrings } from "../../../utils/string";
import GooglePredictedAddressLine1 from "../googlePredictedAddressLine1";

const EditAddress = (props) => {
  const {
    editAddressCountry,
    editAddressAddress1,
    editAddressAddress2,
    editAddressAddress3,
    editAddressIsMilitaryAddress,
    editAddressCity,
    editAddressState,
    editAddressMilitaryCity,
    editAddressMilitaryState,
    editAddressMilitaryZipCode,
    editAddressZipCode,
    showFreeformInputBox,
    showMilitaryAddressOption,
    isCalledFrom = "ChangeAddress",
    hideMandatoryFieldIndicator = false,
  } = useEditAddressProps(props);

  let fiveDigitZip;
  if (
    editAddressZipCode.value.length > 5 &&
    compareStrings(editAddressCountry.value, "USA")
  ) {
    fiveDigitZip = {
      ...editAddressZipCode,
      value: editAddressZipCode.value.substring(0, 5),
    };
  } else {
    fiveDigitZip = { ...editAddressZipCode };
  }

  const cityStateColSize = compareStrings(editAddressCountry.value, "USA")
    ? 5
    : 4;
  const zipcodeColSize = compareStrings(editAddressCountry.value, "USA")
    ? 2
    : 4;

  return (
    <div className="row">
      <div className="col-lg-9">
        <IsTruthy value={compareStrings("ChangeAddress", isCalledFrom)}>
          <h1 id="enter_address_header_lbl">Enter Address</h1>
        </IsTruthy>
        <IsTruthy value={compareStrings("InitiateNewLoan", isCalledFrom)}>
          <h2 id="enter_address_header_lbl">Enter Address</h2>
        </IsTruthy>
        <IsFalsy value={hideMandatoryFieldIndicator}>
          <p
            className="mm-sub-copy mm-spacing"
            id="enter_address_required_note_lbl"
          >
            * = Required Field
          </p>
        </IsFalsy>
        <DropdownForm
          formField={editAddressCountry}
          useHook={useCountryDropdownProps}
        />
        <GooglePredictedAddressLine1
          formField={editAddressAddress1}
          dependentAddressFieldIds={{
            addressLine1Field: editAddressAddress1.id,
            addressLine2Field: editAddressAddress2.id,
            addressCityField: editAddressCity.id,
            addressStateField: editAddressState.id,
            addressZip: editAddressZipCode.id,
            addressCountry: editAddressCountry.id,
          }}
        />
        <InputForm formField={editAddressAddress2} />
        <InputForm formField={editAddressAddress3} />
        <IsTruthy value={showMilitaryAddressOption}>
          <CheckboxForm formField={editAddressIsMilitaryAddress} />
        </IsTruthy>
        <Row className="mm-spacing">
          <IsTruthy value={editAddressIsMilitaryAddress.value}>
            <Col sm={12} md={5}>
              <DropdownForm formField={editAddressMilitaryCity} />
            </Col>
            <Col sm={12} md={5}>
              <DropdownForm formField={editAddressMilitaryState} />
            </Col>
            <Col sm={12} md={2}>
              <InputForm formField={editAddressMilitaryZipCode} />
            </Col>
          </IsTruthy>
          <IsFalsy value={editAddressIsMilitaryAddress.value}>
            <Col sm={12} md={cityStateColSize}>
              <InputForm formField={editAddressCity} />
            </Col>
            <Col sm={12} md={cityStateColSize}>
              <IsTruthy value={showFreeformInputBox}>
                <InputForm formField={editAddressState} />
              </IsTruthy>
              <IsFalsy value={showFreeformInputBox}>
                <DropdownForm formField={editAddressState} />
              </IsFalsy>
            </Col>
            <Col sm={12} md={zipcodeColSize}>
              <InputForm formField={fiveDigitZip} />
            </Col>
          </IsFalsy>
        </Row>
      </div>
    </div>
  );
};

export default EditAddress;
