import React from "react";
import EmailContact from "./emailContact";
import { useFormContext } from "../../../contexts/form";
import TextAreaForm from "../../common/forms/textareaForm";

const AddRecipient = () => {
  const { formData } = useFormContext();
  const { customEmailMessage } = formData;

  return (
    <>
      <div>
        <h3 id="add_recipient_heading">Add Recipient*</h3>
        <p id="add_recipient_text">
          Enter the recipient’s email below and add an optional custom message.
          Once complete, click continue to open the form and pre-fill as much or
          as little of the form as you like before sending to your client.
        </p>
      </div>
      <EmailContact />
      <div
        id="custom_email_message"
        className="row col-md-7 mm-spacing--minor form-group"
      >
        <TextAreaForm formField={customEmailMessage} />
      </div>
    </>
  );
};
export default React.memo(AddRecipient);
