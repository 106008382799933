import {
  getSessionStorageObject,
  redirectToUrl,
  setSessionStorageObject,
} from "./dom";
import { isDefined } from "./evaluate";
import { compareStrings } from "./string";

export const tealiumPageViewEvent = ({ page, category, custId }) => {
  if (window?.utag) {
    window.utag.view({
      tealium_event: `${page}_page_view`,
      content_type: `${page}_page`,
      page_name: page,
      page_category: category,
      site_id: "SVNT",
      _ccustid: custId,
    });
  }
};

export const fireTealiumEvent = ({
  label = "SVNT",
  action = "",
  category = "",
  property = "",
  value = "",
  custId = "",
}) => {
  if (window?.utag) {
    window.utag.link({
      event_label: label,
      event_action: action,
      event_category: category,
      event_property: property,
      event_value: value,
      _ccustid: custId,
    });
  }
};

export const fireTealiumAndFollowLink = (props) => {
  const { action, category, value, custId, url, target } = props;
  if (compareStrings(target, "_blank")) {
    fireTealiumEvent({
      action,
      category,
      value,
      custId,
    });
  } else {
    setSessionStorageObject({
      name: "UtagData",
      value: {
        event_label: "SVNT",
        event_action: action,
        event_category: category,
        event_value: value,
        _ccustid: custId,
      },
    });
  }
  redirectToUrl(url, target);
};

export const fireTealiumfromStoredObject = () => {
  const storedUtagData = getSessionStorageObject({ name: "UtagData" });
  if (isDefined(storedUtagData)) {
    if (window?.utag) {
      window.utag.link(storedUtagData);
      sessionStorage.removeItem("UtagData");
    }
  }
};
