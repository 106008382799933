import { useEffect, useState } from "react";
import { ANNUITY_LOAN_DEFAULT_DATE } from "../../../../../constants/logic";
import { DATA_UNAVAILABLE } from "../../../../../constants/ui";
import { contactUSDetails } from "../../../../../data/contactUsDetails";
import { groupBy } from "../../../../../utils/array";
import { useMakeRequest } from "../../../../../hooks/api";
import {
  createDateObject,
  formatDate,
  getFullAge,
} from "../../../../../utils/dates";
import {
  isArray,
  isDefined,
  isNullOrUndefined,
} from "../../../../../utils/evaluate";
import {
  formatMoney,
  formatPercentage,
  formatContactUsNumber,
  formatContactUsDateTime,
} from "../../../../../utils/format";
import {
  compareArrayOfStrings,
  compareStrings,
} from "../../../../../utils/string";
import {
  isArtistryAnnuityProduct,
  isFlexExtraAnnuityProduct,
} from "../../../../../utils/policies";

export const useAnnuityLoanProps = (props) => {
  const {
    annuityRequest,
    agreementRequest: {
      data: {
        agreementKey,
        agreementKeyPrefix,
        carrierCode,
        kindCode,
        contactUsNumber,
        contactUsTime,
      },
    },
  } = props;
  const isArtistryAnnuityContract =
    isArtistryAnnuityProduct(agreementKeyPrefix);
  const isFlexExtraContract = isFlexExtraAnnuityProduct(kindCode);
  const { artistryLoanData, flexExtraData } = useFormatLoanData({
    annuityData: annuityRequest.data,
    isFlexExtraContract,
    isArtistryAnnuityContract,
  });

  const { maxLoanAmount, outstandingLoanCount, loanList, annuitantAge } =
    artistryLoanData;

  const loanSectionInfoMessages = getLoanSectionInfoMessages({
    maxLoanAmount,
    outstandingLoanCount,
  });

  const maxLoanAmountPopover = getMaxLoanAmountPopover({
    maxLoanAmount,
    outstandingLoanCount,
    loanList,
    annuitantAge,
  });

  const formattedContactUsNumber = formatContactUsNumber(
    contactUsNumber,
    contactUSDetails.DEFAULT.number
  );
  const formattedContactUsTime = formatContactUsDateTime(
    contactUsTime,
    contactUSDetails.DEFAULT.time
  );

  /* Loan Pay off  logic - Begins */

  let agreementCarrierCode = "65935";

  switch (carrierCode) {
    case "100":
      agreementCarrierCode = "65935";
      break;
    case "200":
      agreementCarrierCode = "93432";
      break;
    case "208":
      agreementCarrierCode = "70416";
      break;
    default:
      break;
  }

  const transactionCode = "21203";

  const annuityAsOfDate = annuityRequest?.data?.asofDate;
  const [loanPayOffAsOfDate, setLoanPayOffAsOfDate] = useState(annuityAsOfDate);
  const [showLoanPayOffValues, setShowLoanPayOffValues] = useState(false);
  const [showLoadingIcon, setShowLoadingIcon] = useState(false);
  const formattedSelectedDate = formatDate(
    loanPayOffAsOfDate?.toString(),
    "YYYY-MM-DD"
  );

  const loanPayOffRequest = useMakeRequest({
    apiRequest: "fetchRecalculatedVNTGKCValues",
    apiParams: {
      agreementKey,
      formattedSelectedDate,
      carrierCode: agreementCarrierCode,
      transactionCode,
    },
    immediateRequest: false,
  });

  let subAgreementKey = "";
  if (isArtistryAnnuityContract) {
    const [policyNumber, prefix, suffix, carrierAdminSystem] =
      agreementKey?.split("|") || [];

    if (compareStrings(suffix, "EE")) {
      subAgreementKey = [policyNumber, prefix, "ER", carrierAdminSystem].join(
        "|"
      );
    } else {
      subAgreementKey = [policyNumber, prefix, "EE", carrierAdminSystem].join(
        "|"
      );
    }
  }

  // Subsequest request in case of Artistry Products (for employer, in case employee was already made & Vice-a-versa)
  const subLoanPayoffRequest = useMakeRequest({
    apiRequest: "fetchRecalculatedVNTGKCValues",
    apiParams: {
      agreementKey: subAgreementKey,
      formattedSelectedDate,
      carrierCode: agreementCarrierCode,
      transactionCode,
    },
    immediateRequest: false,
  });
  const formattedAnnuityAsOfDate = formatDate(annuityAsOfDate, "YYYY-MM-DD");

  let loanPayOffValues = {};
  useEffect(() => {
    if (
      isDefined(formattedSelectedDate) &&
      isDefined(formattedAnnuityAsOfDate) &&
      formattedAnnuityAsOfDate !== formattedSelectedDate
    ) {
      loanPayOffRequest.executeRequest();
      if (isArtistryAnnuityContract) subLoanPayoffRequest.executeRequest();
      setShowLoanPayOffValues(false);
      setShowLoadingIcon(true);
      if (!loanPayOffRequest.isLoading && !subLoanPayoffRequest.isLoading) {
        setShowLoanPayOffValues(true);
        setShowLoadingIcon(false);
      }
    } else {
      setShowLoanPayOffValues(false);
    }
  }, [
    formattedSelectedDate,
    loanPayOffRequest.data,
    loanPayOffRequest.isLoading,
    subLoanPayoffRequest.isLoading,
    subLoanPayoffRequest.data,
  ]);

  loanPayOffValues = getLoanPayOffValues(
    loanPayOffRequest.data,
    subLoanPayoffRequest.data
  );

  return {
    isArtistryAnnuityContract,
    isFlexExtraContract,
    artistryLoanData: {
      ...artistryLoanData,
      maxLoanAmountPopover,
      loanSectionInfoMessages,
      formattedContactUsNumber,
      formattedContactUsTime,
    },
    flexExtraData: {
      ...flexExtraData,
      formattedContactUsNumber,
      formattedContactUsTime,
    },
    setLoanPayOffAsOfDate,
    showLoanPayOffValues,
    showLoadingIcon,
    loanPayOffValues,
    selectedDate: formatDate(
      formattedSelectedDate,
      "monthCommaDayYear",
      "date is unavailable"
    ),
  };
};

export const getLoanPayOffValues = (loansData, subLoansData) => {
  const concat = (...arrays) => [].concat(...arrays.filter(Array.isArray));
  const loansArray =
    concat(loansData?.loansList, subLoansData?.loansList) || [];

  sortLoanListByStartDate(loansArray);
  const groupedLoanList = getGroupedLoanListByStartDate(loansArray);
  const groupedLoanListKeys = Object.keys(groupedLoanList);
  const outstandingLoanCount = groupedLoanListKeys.length;

  return { groupedLoanList, outstandingLoanCount };
};

const isLoanEligible = (loan) => {
  const loanDatesDefaultValue = createDateObject(ANNUITY_LOAN_DEFAULT_DATE);
  return (
    !compareStrings(loan?.status, loanStatues.PAID_UP) &&
    createDateObject(loan?.startDate) > loanDatesDefaultValue
  );
};

const filterLoanList = (loan, loansOutstanding) => {
  if (!isDefined(loan)) {
    return [];
  }
  // If ASC api returns one loan, it does not return as an array.
  // Rather it return an object.
  if (loansOutstanding === 1 && !isArray(loan) && isLoanEligible(loan)) {
    return [loan];
  }
  return isArray(loan) ? loan?.filter((item) => isLoanEligible(item)) : [];
};

export const sortLoanListByStartDate = (loanList) => {
  loanList?.sort((a, b) => {
    const dateA = createDateObject(a.startDate);
    const dateB = createDateObject(b.startDate);
    return dateA - dateB;
  });
};

export const getGroupedLoanListByStartDate = (loanList) => {
  const groupedLoanList = groupBy(loanList, "startDate");
  Object.keys(groupedLoanList).forEach((loanStartDate) => {
    groupedLoanList[loanStartDate] = groupBy(
      groupedLoanList[loanStartDate],
      "segment"
    );
  });
  return groupedLoanList;
};

export const getMaxLoanAmountPopover = (data) => {
  const {
    maxLoanAmount,
    outstandingLoanCount,
    loanList,
    annuitantAge,
    isFlexExtra,
    issueState,
  } = data;

  const hasAnyDeemedLoan = loanList?.some((item) =>
    compareStrings(item.status, loanStatues.DEEMED)
  );

  const isAgeMoreThanOrEqual70years6months =
    isDefined(annuitantAge) &&
    ((annuitantAge.years === 70 && annuitantAge.months >= 6) ||
      annuitantAge.years > 70);

  const displaymaxLoanAmountPopover =
    isNullOrUndefined(maxLoanAmount) ||
    outstandingLoanCount === 3 ||
    (!isFlexExtra && maxLoanAmount < 1000) ||
    (isFlexExtra && maxLoanAmount < 3000 && issueState !== "OR") ||
    (isFlexExtra && maxLoanAmount < 1000 && issueState === "OR") ||
    hasAnyDeemedLoan ||
    isAgeMoreThanOrEqual70years6months;

  const maxLoanAmountPopoverTexts = [];

  if (
    isNullOrUndefined(maxLoanAmount) ||
    (isFlexExtra && !isDefined(maxLoanAmount))
  ) {
    maxLoanAmountPopoverTexts.push(
      "The information is not available online for this contract."
    );
  }
  if (outstandingLoanCount === 3) {
    maxLoanAmountPopoverTexts.push(
      "There are currently three outstanding loans on this contract. No additional loans can be taken at this time."
    );
  }
  if (!isFlexExtra && maxLoanAmount < 1000) {
    maxLoanAmountPopoverTexts.push(
      "Max Loan Amount is less than the minimum loanable amount allowed ($1,000)."
    );
  }

  if (
    (isFlexExtra &&
      isDefined(maxLoanAmount) &&
      maxLoanAmount < 3000 &&
      issueState !== "OR") ||
    (isFlexExtra &&
      isDefined(maxLoanAmount) &&
      maxLoanAmount < 1000 &&
      issueState === "OR")
  ) {
    maxLoanAmountPopoverTexts.push(
      "Max Loan Amount is unavailable if it is less than $1,000 and the contract state is Oregon, or if it is less than $3,000 for all other contract states."
    );
  }

  if (hasAnyDeemedLoan) {
    maxLoanAmountPopoverTexts.push(
      "No further loans are available on this contract due to a deemed status on an existing loan."
    );
  }

  if (isAgeMoreThanOrEqual70years6months) {
    maxLoanAmountPopoverTexts.push(
      "The Annuitant age is greater than 70 ½; no loans can be taken."
    );
  }

  return {
    displaymaxLoanAmountPopover,
    maxLoanAmountPopoverTexts,
  };
};

export const getLoanSectionInfoMessages = (data) => {
  const loanSectionInfoMessages = [];
  const { maxLoanAmount, outstandingLoanCount } = data;
  const isAtLeastOneNonPaidUpLoan = outstandingLoanCount > 0;
  if (maxLoanAmount > 0) {
    loanSectionInfoMessages.push({
      id: "loanSectionRestrictedInfoMessages",
      displayText:
        "The ability to take a loan and the loan amount available may be restricted by the plan's provisions. Please consult your plan document or plan administrator for more information on the available amount.",
    });
  }
  if (isAtLeastOneNonPaidUpLoan) {
    loanSectionInfoMessages.push({
      id: "loanSectionStatusInfoMessages",
      displayText: `Loan status displayed in Detailed Loan Information is current and not historical. Please call the Service Center at <a href="tel:+${contactUSDetails.DEFAULT.number}">${contactUSDetails.DEFAULT.number}</a>, ${contactUSDetails.DEFAULT.time} to determine the eligibility for a loan.`,
    });
  }

  return loanSectionInfoMessages;
};

export const useFormatLoanData = (props) => {
  const {
    annuityData = {},
    isArtistryAnnuityContract,
    isFlexExtraContract,
  } = props;

  const {
    loanMaxAmt,
    loanMaxEffDate,
    loans,
    annuitantBirthDate,
    asofDate,
    loansOutstanding,
    issueState,
  } = annuityData;

  const { loan } = loans || {};

  const loanList = filterLoanList(loan, loansOutstanding);

  const formattedMaxLoanAsOfDate = formatDate(
    loanMaxEffDate,
    "default",
    DATA_UNAVAILABLE
  );

  return {
    artistryLoanData: isArtistryAnnuityContract
      ? processArtistryLoanData({
          formattedMaxLoanAsOfDate,
          annuitantBirthDate,
          asofDate,
          loan,
          loanList,
          loanMaxAmt,
        })
      : {},
    flexExtraData: isFlexExtraContract
      ? processFlexExtraLoanData({
          loanList,
          loanMaxAmt,
          issueState,
          formattedMaxLoanAsOfDate,
          annuitantBirthDate,
        })
      : {},
  };
};

const processArtistryLoanData = (props) => {
  const {
    loanList,
    loanMaxAmt,
    formattedMaxLoanAsOfDate,
    annuitantBirthDate,
    asofDate,
    loan,
  } = props;

  // sort does the array sorting in place and modify the original array.
  // So need to return any new loanlist array refference post sorting.
  sortLoanListByStartDate(loanList);
  const groupedLoanList = getGroupedLoanListByStartDate(loanList);
  const groupedLoanListKeys = Object.keys(groupedLoanList);
  const outstandingLoanCount = groupedLoanListKeys.length;

  const formattedMaxLoanAmount = formatMoney(loanMaxAmt);

  const hasActiveLoan = outstandingLoanCount > 0;
  const formattedOutstandingLoanCount = isDefined(loan)
    ? outstandingLoanCount
    : DATA_UNAVAILABLE;
  const annuitantAge = getFullAge(annuitantBirthDate);

  const formattedLoanPayoffAsOfDate = formatDate(
    asofDate,
    "default",
    "date is unavailable"
  );

  return {
    maxLoanAmount: loanMaxAmt,
    formattedMaxLoanAmount,
    outstandingLoanCount,
    formattedOutstandingLoanCount,
    maxLoanAsOfDate: formattedMaxLoanAsOfDate,
    loanList,
    groupedLoanList,
    annuitantAge,
    formattedLoanPayoffAsOfDate,
    hasActiveLoan,
  };
};

const processFlexExtraLoanData = (props) => {
  const {
    loanList,
    loanMaxAmt,
    issueState,
    formattedMaxLoanAsOfDate,
    annuitantBirthDate,
  } = props;

  const formattedMaxLoanAmount = processFlexExtraMaxLoanAmount(
    loanMaxAmt,
    issueState
  );

  const flexExtraActiveLoan =
    loanList?.find((entry) => entry.status === "Active") || {};

  const annuitantAge = getFullAge(annuitantBirthDate);

  const maxLoanAmountPopover = getMaxLoanAmountPopover({
    maxLoanAmount: loanMaxAmt,
    loanList,
    annuitantAge,
    isFlexExtra: true,
    issueState,
  });

  const formattedRemainingBalance = formatMoney(
    flexExtraActiveLoan?.remainingBalance,
    "-"
  );
  const formattedPayoffAmount = formatMoney(
    flexExtraActiveLoan?.payoffAmt,
    "-"
  );
  const formattedCurrentInterestRate = formatPercentage({
    rate: flexExtraActiveLoan?.currInterestRate,
  });
  const loanStatus = flexExtraActiveLoan?.status || "-";

  const displayNoActiveLoansAlert =
    isDefined(loanMaxAmt) && loanStatus !== "Active";

  const displayFlexExtraLoanSectionUnavailableAlert =
    compareArrayOfStrings(["NY", "AL"], issueState) ||
    (!isDefined(loanMaxAmt) && loanStatus !== "Active");

  const isActiveLoanOnContract =
    isDefined(loanMaxAmt) && loanStatus === "Active";

  return {
    formattedMaxLoanAmount,
    formattedRemainingBalance,
    formattedPayoffAmount,
    formattedCurrentInterestRate,
    loanStatus,
    formattedMaxLoanAsOfDate,
    displayNoActiveLoansAlert,
    displayFlexExtraLoanSectionUnavailableAlert,
    maxLoanAmountPopover,
    isActiveLoanOnContract,
  };
};

export const processFlexExtraMaxLoanAmount = (loanMaxAmt, issueState) => {
  const flexExtraMinMaxLoanAmt = compareStrings("OR", issueState) ? 1000 : 3000;
  return loanMaxAmt < flexExtraMinMaxLoanAmt
    ? "Unavailable"
    : formatMoney(loanMaxAmt);
};

export const loanStatues = {
  PAID_UP: "Paid Up",
  DEEMED: "Deemed",
  ACTIVE: "Active",
};
