import React from "react";
import IsTruthy from "../../../common/logic/isTruthy";
import { useFormContext } from "../../../../contexts/form";
import ClaimsPacketAndFormsAnswers from "./claimsPacketAndFormsAnswers";
import {
  calculateClaimsPacketAndFormsQuestionsDisplay,
  useClaimsPacketAndFormsQuestionAndAnswersProps,
} from "../../hooks";
import ClaimsPacketAndFormsQuestion from "./claimsPacketAndFormsQuestion";
import { INITIATE_DEATH_CLAIM_MAX_RECIPIENT_CONTACT_TEXT } from "../../../../constants/ui";

const ClaimsPacketAndFormsQuestionAndAnswers = (props) => {
  const {
    claimsPacketAndFormsQuestionsArray,
    producerRequest,
    claimRecipientsContactsCount,
    setClaimRecipientsContactsCount,
    disableAddContactButton,
    isDiOnlyClaim,
  } = props;
  const { formData } = useFormContext();

  const { displayClaimsPacketAndFormsInfoQuestion5 } =
    useClaimsPacketAndFormsQuestionAndAnswersProps();

  const claimsPacketAndFormsQuestionsAndAnswersMarkup =
    claimsPacketAndFormsQuestionsArray.map(
      (claimsPacketAndFormsQuestion, index) => {
        const questionNumber = index + 1;
        const { question } = claimsPacketAndFormsQuestion;
        const questionAndAnswerMarkUp = (
          <>
            <ClaimsPacketAndFormsQuestion
              question={question}
              questionNumber={questionNumber}
              displayClaimsPacketAndFormsInfoQuestion5={
                displayClaimsPacketAndFormsInfoQuestion5
              }
            />
            <IsTruthy value={questionNumber === 1}>
              <div className="row">
                <div className="col-12">
                  <ClaimsPacketAndFormsAnswers
                    formData={formData}
                    questionNumber={questionNumber}
                    claimRecipientsContactsCount={claimRecipientsContactsCount}
                  />
                </div>
              </div>
            </IsTruthy>
            <IsTruthy value={questionNumber === 2}>
              <div className="row">
                <ClaimsPacketAndFormsAnswers
                  formData={formData}
                  questionNumber={questionNumber}
                  producerRequest={producerRequest}
                  claimRecipientsContactsCount={claimRecipientsContactsCount}
                  setClaimRecipientsContactsCount={
                    setClaimRecipientsContactsCount
                  }
                  disableAddContactButton={disableAddContactButton}
                />
              </div>
              <IsTruthy value={disableAddContactButton}>
                <div
                  id="recipientMaxCountReachedAlert"
                  className="alert alert-info"
                  role="alert"
                >
                  {INITIATE_DEATH_CLAIM_MAX_RECIPIENT_CONTACT_TEXT}
                </div>
              </IsTruthy>
            </IsTruthy>
            <IsTruthy value={questionNumber === 3}>
              <div className="row">
                <div className="col-12">
                  <ClaimsPacketAndFormsAnswers
                    formData={formData}
                    questionNumber={questionNumber}
                    claimRecipientsContactsCount={claimRecipientsContactsCount}
                  />
                </div>
              </div>
            </IsTruthy>
            <IsTruthy value={questionNumber === 4}>
              <div className="row">
                <div className="col-12">
                  <ClaimsPacketAndFormsAnswers
                    formData={formData}
                    questionNumber={questionNumber}
                    isHorizontalAnswersDisplay
                    claimRecipientsContactsCount={claimRecipientsContactsCount}
                  />
                </div>
              </div>
            </IsTruthy>
            <IsTruthy
              value={
                questionNumber === 5 && displayClaimsPacketAndFormsInfoQuestion5
              }
            >
              <div className="row">
                <div className="col-12">
                  <ClaimsPacketAndFormsAnswers
                    formData={formData}
                    questionNumber={questionNumber}
                    isHorizontalAnswersDisplay
                    claimRecipientsContactsCount={claimRecipientsContactsCount}
                  />
                </div>
              </div>
            </IsTruthy>
            <IsTruthy value={questionNumber === 6}>
              <div className="row">
                <div className="col-12">
                  <ClaimsPacketAndFormsAnswers
                    formData={formData}
                    questionNumber={questionNumber}
                    isHorizontalAnswersDisplay
                    claimRecipientsContactsCount={claimRecipientsContactsCount}
                  />
                </div>
              </div>
            </IsTruthy>
          </>
        );
        return (
          <IsTruthy
            key={questionNumber}
            value={calculateClaimsPacketAndFormsQuestionsDisplay({
              questionNumber,
              formData,
              displayClaimsPacketAndFormsInfoQuestion5,
              isDiOnlyClaim,
            })}
          >
            {questionAndAnswerMarkUp}
          </IsTruthy>
        );
      }
    );

  return (
    <div
      className="mm-spacing--minor"
      id="claimsandForms_QuestionAnswer_Container"
    >
      {claimsPacketAndFormsQuestionsAndAnswersMarkup}
    </div>
  );
};

export default React.memo(ClaimsPacketAndFormsQuestionAndAnswers);
