import { useState, useEffect } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import compareDesc from "date-fns/compareDesc";
import cloneDeep from "lodash.clonedeep";
import { moveToNextPage, moveToPreviousPage, moveToPage } from "../utils/table";
import { isNaN } from "../utils/evaluate";
import { extractNumberFromString } from "../utils/format";
import { createDateObject } from "../utils/dates";
import { searchRows } from "../utils/search";
import { hasKey } from "../utils/object";
import { compareStrings } from "../utils/string";

const ENTER_WAS_PRESSED = 13;

const initialPaginationState = {
  rowSize: 10,
  currentPage: 1,
  inputtedPage: 1,
  totalPages: 0,
};

// eslint-disable-next-line import/prefer-default-export
export const usePagination = (props) => {
  const { rows } = props;
  const [pagination, setPagination] = useState(initialPaginationState);

  const nextPage = () => moveToNextPage({ pagination, setPagination });
  const previousPage = () => moveToPreviousPage({ pagination, setPagination });
  const setInputtedPage = (event) => {
    const {
      charCode,
      target: { value: newPage },
    } = event;
    const shouldChangePage = newPage !== "" || charCode === ENTER_WAS_PRESSED;
    moveToPage({ shouldChangePage, pagination, setPagination, newPage });
  };
  useEffect(() => {
    setPagination((state) => ({
      ...state,
      currentPage: 1,
      inputtedPage: 1,
      totalPages: Math.ceil(rows.length / state.rowSize),
    }));
  }, [pagination.rowSize, rows]);

  return {
    nextPage,
    previousPage,
    setInputtedPage,
    pagination,
  };
};

export const useSort = (props) => {
  const { columns, rows, defaultSortColumn, defaultSortDirection } = props;
  const [sort, setSort] = useState({
    columnName: defaultSortColumn,
    direction: defaultSortDirection,
  });
  const [sortedRows, setSortedRows] = useState(rows);

  useEffect(() => {
    if (!compareStrings(defaultSortColumn, "NO_DEFAULT_SORT")) {
      setSort({
        columnName: defaultSortColumn,
        direction: defaultSortDirection,
      });
    }
  }, [defaultSortColumn, defaultSortDirection]);

  // useDeepCompareEffect does not allow for html elements,
  // with some columns using html elements in the label of a column
  // we removed everything we did not need to prevent the app from crashing
  const minifiedColumns = columns.map((column) => {
    const { accessor, sortable } = column;
    return { accessor, sortable };
  });

  const toggleSort = (toggleSortProps) => {
    const newSortState = changeSortFieldAndDirection({
      columnToSortOn: toggleSortProps.columnName,
      sort,
    });
    setSort(newSortState);
  };

  useDeepCompareEffect(() => {
    setSortedRows(
      sortRows({
        columns: minifiedColumns,
        direction: sort.direction,
        columnName: sort.columnName,
        rows,
      })
    );
  }, [sort.direction, sort.columnName, rows, minifiedColumns]);

  return {
    sort,
    sortedRows,
    toggleSort,
  };
};

export const useSearchFilterRows = (props) => {
  const {
    searchStringDefault = "",
    showClearIconDefault = false,
    columns = [],
    rows,
  } = props;

  const filterSearchableColumns = columns.filter((column) =>
    hasKey({ obj: column, key: "sortable" })
  );
  const searchColumns = filterSearchableColumns.map(
    (column) => column?.accessor
  );

  const [searchString, setSearchString] = useState(searchStringDefault);
  const searchTable = ({ target: { value } }) => setSearchString(value);
  const clearSearch = () => setSearchString("");

  const [showClearIcon, setShowClearIcon] = useState(showClearIconDefault);

  const [displayedRows, setDisplayedRows] = useState(rows);

  useDeepCompareEffect(() => {
    const searchedRows = searchRows({
      searchString,
      initialRows: cloneDeep(rows),
      columns,
      searchColumns,
    });
    setDisplayedRows(searchedRows);
    setShowClearIcon(searchString.length > 0);
  }, [rows, searchString]);

  return {
    searchString,
    showClearIcon,
    searchTable,
    clearSearch,
    filteredRows: displayedRows,
  };
};

export const changeSortFieldAndDirection = (props) => {
  let newDirection;
  const {
    columnToSortOn,
    sort: { columnName, direction },
  } = props;

  const IS_SAME_COLUMN = columnName === columnToSortOn;

  if (IS_SAME_COLUMN) {
    switch (direction) {
      case "none":
        newDirection = "ascending";
        break;
      case "ascending":
        newDirection = "descending";
        break;
      case "descending":
        newDirection = "ascending";
        break;
      default:
        newDirection = "none";
        break;
    }
  } else {
    newDirection = "ascending";
  }

  return { columnName: columnToSortOn, direction: newDirection };
};

export const sortRows = (props) => {
  const { direction, columnName, rows, columns } = props;
  const [columnBeingSorted] = columns.filter((c) => c.accessor === columnName);
  const type = columnBeingSorted?.sortable;

  const NO_COLUMN_SELECTED = columnName === "";
  if (NO_COLUMN_SELECTED) return rows;

  switch (direction) {
    case "ascending":
      return compareRows({ columnName, type, rows });
    case "descending":
      return compareRows({ columnName, type, rows }).reverse();
    default:
      return compareRows({ columnName, type, rows });
  }
};

const compareRows = (props) => {
  const { columnName, type, rows } = props;
  // Currently supported types include String and Date
  if (type === "String") {
    return sortString({ rows, columnName });
  }
  if (type === "Date") {
    return sortDate({ rows, columnName });
  }
  if (type === "Cash") {
    return sortCash({ rows, columnName });
  }
  if (type === "Number") {
    return sortNumber({ rows, columnName });
  }
  return rows;
};

const sortCash = (props) => {
  const { columnName, rows } = props;
  const sortedRows = [...rows].sort((a, b) => {
    // Doing a string alphabetical compares
    const cashA = a[columnName]?.substring(1);
    const cashB = b[columnName]?.substring(1);

    return cashA - cashB > 0 ? cashA : cashB;
  });
  return sortedRows;
};

const sortString = (props) => {
  const { columnName, rows } = props;
  const sortMyRows = [...rows].sort((a, b) => {
    // Doing a string alphabetical compares
    const stringA = a[columnName]?.toLowerCase();
    const stringB = b[columnName]?.toLowerCase();

    return stringA?.localeCompare(stringB);
  });
  return sortMyRows;
};

const sortDate = (props) => {
  const { columnName, rows } = props;

  const sortMyRows = [...rows].sort((a, b) => {
    const dateFieldA = a[columnName];
    const dateFieldB = b[columnName];

    const dateA = createDateObject(dateFieldA);
    const dateB = createDateObject(dateFieldB);

    const compareResult = compareDesc(dateA, dateB);

    // if compareResult is NaN, it most likely means one of the input fields is undefined
    if (isNaN(compareResult)) {
      return handleDateUndefinedSorting(dateFieldA, dateFieldB);
    }

    return compareResult;
  });

  return sortMyRows;
};

export const sortNumber = (props) => {
  const { columnName, rows } = props;

  const sortedRows = [...rows].sort((a, b) => {
    const numberField1 = a[columnName];
    const numberField2 = b[columnName];

    const convertedToNumberField1 = extractNumberFromString(numberField1) || 0;
    const convertedToNumberField2 = extractNumberFromString(numberField2) || 0;

    return convertedToNumberField1 - convertedToNumberField2;
  });

  return sortedRows;
};

export const sortByProvidedOrder = (props) => {
  const { columnName, rows, order = [] } = props;
  if (!order) {
    return null;
  }

  const orderMap = {};
  // create a value to numerical value map so we can compare numbers
  // orderMap = {"first": 1, "second": 2}
  order.forEach((element, index) => {
    const lowerCase = element?.toLowerCase();
    orderMap[lowerCase] = index + 1;
  });

  const numKeys = Object.keys(orderMap)?.length;

  // If orderArray and numKeys's lengths do not match, it means orderArray is not valid because it had duplicates
  if (order.length !== numKeys) {
    return null;
  }

  const sortedRows = rows.sort((a, b) => {
    const firstValue = a[columnName];
    const secondValue = b[columnName];

    const mappedValue1 = orderMap[firstValue?.toLowerCase()] || numKeys + 1;
    const mappedValue2 = orderMap[secondValue?.toLowerCase()] || numKeys + 1;

    return mappedValue1 - mappedValue2;
  });

  return sortedRows;
};

// Handle sorting comparison if one or more date fields are undefined
export const handleDateUndefinedSorting = (dateFieldA, dateFieldB) => {
  if (!dateFieldA && !dateFieldB) {
    return 0;
  }
  if (!dateFieldA || dateFieldB === "-") {
    return -1;
  }
  return 1;
};

// TODO: probably want to standardize our month abbreviations someday
export const monthToInteger = (monthString) => {
  switch (monthString) {
    case "Jan":
    case "Jan.":
      return 1;
    case "Feb":
    case "Feb.":
      return 2;
    case "Mar":
    case "March":
      return 3;
    case "Apr":
    case "April":
      return 4;
    case "May":
      return 5;
    case "Jun":
    case "June":
      return 6;
    case "Jul":
    case "July":
      return 7;
    case "Aug":
    case "Aug.":
      return 8;
    case "Sep":
    case "Sept.":
      return 9;
    case "Oct":
    case "Oct.":
      return 10;
    case "Nov":
    case "Nov.":
      return 11;
    case "Dec":
    case "Dec.":
      return 12;
    default:
      return "";
  }
};
