import React from "react";
import { useParams } from "react-router-dom";
import { useModalState } from "../../../contexts/modal";
import ModalPopup from "./modalPopup";
import { fireTealiumEvent } from "../../../utils/tealium";
import { getTrimmedPolicyIdFromAgreement } from "../../../utils/findData";
import { useUserState } from "../../../contexts/user";
import { isDefined } from "../../../utils/evaluate";

export const useCancelModal = (props) => {
  const { lineOfBusinessCode, transactionName, pageNumber } = props || {};
  const { agreementKey } = useParams();
  const { openModal, closeModal } = useModalState();

  const cancelModalProps = {
    title: "Are you sure you want to cancel?",
    Component: Cancel,
    componentProps: {
      closeModal,
      agreementKey,
      transactionName,
      pageNumber,
      lineOfBusinessCode,
    },
  };

  const openCancelModal = () => {
    openModal(cancelModalProps);
  };

  return {
    openCancelModal,
  };
};

const Cancel = (props) => {
  const {
    closeModal,
    agreementKey,
    transactionName,
    pageNumber,
    lineOfBusinessCode,
  } = props;
  const { trimmedPolicyId } = getTrimmedPolicyIdFromAgreement(agreementKey);
  const {
    user: { email },
  } = useUserState();

  let actionVerbiage = `${transactionName} PageNumber ${pageNumber} Cancel button clicked`;
  if (isDefined(lineOfBusinessCode)) {
    actionVerbiage = `${lineOfBusinessCode}: ${actionVerbiage}`;
  }

  const cancelPendingTransaction = () => {
    if (isDefined(transactionName)) {
      fireTealiumEvent({
        label: "SVNT",
        action: actionVerbiage,
        category: `${transactionName}`,
        value: trimmedPolicyId,
        custId: email,
      });
    }
  };
  return (
    <ModalPopup
      closeModal={closeModal}
      agreementKey={agreementKey}
      modalBodyMessage="By canceling, you will lose any edits that you have made and you will not be able to undo this action."
      cancelButtonMessage="Yes, Cancel"
      continueButtonMessage="No, Stay"
      cancelPendingTransaction={cancelPendingTransaction}
    />
  );
};

export default Cancel;
