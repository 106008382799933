import React from "react";
import { useFormContext } from "../../contexts/form";
import CheckboxForm from "../common/forms/checkboxForm";
import InputForm from "../common/forms/inputForm";

const Recipient = (props) => {
  const { ownerField, ownerEmailFields } = props;
  return (
    <div className="row mm-spacing--minor align-items-center">
      <div className="col-md-4">
        <CheckboxForm
          formField={ownerField}
          className="form-group custom-control custom-checkbox mm-spacing--none"
        />
      </div>
      <div className="col-md-6">
        <InputForm formField={ownerEmailFields} />
      </div>
    </div>
  );
};

const Recipients = () => {
  const { formData } = useFormContext();
  const { eligibleOwnerCount } = formData;

  const ownerFields = [];
  const ownerEmailFields = [];
  for (let i = 0; i < eligibleOwnerCount; i += 1) {
    ownerFields.push(formData[`owner${i}`]);
    ownerEmailFields.push(formData[`ownerEmail${i}`]);
  }

  return ownerFields.map((ownerField, index) => (
    <Recipient
      key={ownerField.id}
      ownerField={ownerField}
      ownerEmailFields={ownerEmailFields[index]}
    />
  ));
};

const SelectRecipient = () => (
  <>
    <h1 id="header-recipent"> Direct Link Recipient </h1>
    <p className="mm-spacing" id="note-recipient">
      An email will be sent to the Owner’s email address listed below containing
      links to access their MassMutual.com account. Only Owners registered on
      MassMutual.com will display.
    </p>
    <div className="row mm-spacing--minor">
      <div className="col-md-4" id="header-name">
        <strong>Owner Full Name</strong>
      </div>
      <div className="col-md-6" id="header-email">
        <strong>Email Address</strong>
      </div>
    </div>
    <div className="mm-spacing">
      <Recipients />
    </div>
  </>
);

export default SelectRecipient;
