import React from "react";
import InputForm from "../../../../common/forms/inputForm";
import { useContactInformationModalProps, useCountryDropdown } from "./hooks";
import DropdownForm from "../../../../common/forms/dropdownForm";
import IsTruthy from "../../../../common/logic/isTruthy";
import IsFalsy from "../../../../common/logic/isFalsy";
import { isDefined } from "../../../../../utils/evaluate";
import GooglePredictedAddressLine1 from "../../../../common/googlePredictedAddressLine1";

const ContactInformationModal = (props) => {
  const { formFields, displayStateAsDropdown, saveContact, error } =
    useContactInformationModalProps(props);
  const { closeModal } = props;

  const {
    editName,
    editPhone,
    editExtension,
    editFax,
    editAddressCountry,
    editAddressAddress1,
    editAddressAddress2,
    editAddressAddress3,
    editAddressCity,
    editAddressZipCode,
    editAddressState,
    editEmail,
  } = formFields;

  return (
    <div>
      <div className="modal-body">
        <p className="mm-sub-copy" id="select_customers_required_note_lbl">
          * = Required Field
        </p>
        <div className="row">
          <div className="col-12">
            <InputForm formField={editName} />
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <InputForm formField={editPhone} />
          </div>
          <div className="col-4">
            <InputForm formField={editExtension} />
          </div>
          <div className="col-4">
            <InputForm formField={editFax} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <DropdownForm
              formField={editAddressCountry}
              useHook={useCountryDropdown}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <GooglePredictedAddressLine1
              formField={editAddressAddress1}
              dependentAddressFieldIds={{
                addressLine1Field: editAddressAddress1.id,
                addressLine2Field: editAddressAddress2.id,
                addressCityField: editAddressCity.id,
                addressStateField: editAddressState.id,
                addressZip: editAddressZipCode.id,
                addressCountry: editAddressCountry.id,
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <InputForm formField={editAddressAddress2} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <InputForm formField={editAddressAddress3} />
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <InputForm formField={editAddressCity} />
          </div>
          <div className="col-4">
            <IsTruthy value={displayStateAsDropdown}>
              <DropdownForm formField={editAddressState} />
            </IsTruthy>
            <IsFalsy value={displayStateAsDropdown}>
              <InputForm formField={editAddressState} />
            </IsFalsy>
          </div>
          <div className="col-4">
            <InputForm formField={editAddressZipCode} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <InputForm formField={editEmail} />
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <IsTruthy value={error} validationFunction={isDefined}>
          <div
            className="alert alert-danger mm-spacing--minor"
            role="alert"
            aria-label={error}
          >
            {error}
          </div>
        </IsTruthy>
        <button
          id="btnSave"
          onClick={saveContact}
          type="button"
          className="btn btn-primary"
        >
          <span className="icon-add" /> Save
        </button>
        <button
          id="btnCancel"
          onClick={closeModal}
          type="button"
          className="btn btn-secondary"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ContactInformationModal;
