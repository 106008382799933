import React from "react";
import { useConfirmationProps } from "./hooks";
import "../../../../Print.scss";

const ConfirmationPage = (props) => {
  const { agreementKey, policyId } = props;
  const {
    insured,
    selectedCheckboxes,
    requestorEmail,
    requestorName,
    receivedDate,
  } = useConfirmationProps(props);

  return (
    <div>
      <div className="row">
        <div className="col-md-9">
          <h1 className="h2">
            <picture>
              <span
                role="img"
                className="illustration-confetti hide-print"
                title="Success Confetti"
              />
            </picture>
            Thank you! Your request has been submitted.
          </h1>
        </div>
        <div className="col-3">
          <div id="printButton" className="justify-content-end">
            <button
              onClick={() => window.print()}
              data-bp-link="default"
              className="btn btn-inline hide-print"
              aria-label="Print"
              type="button"
            >
              <span className="icon-print" />
              <span>Print</span>
            </button>
          </div>
        </div>
      </div>
      <p className="mm-spacing--minor">
        Your request has been successfully submitted for processing. If there
        are multiple requests for information, they may be processed and sent
        individually. Please review Service History for processing time and
        updates. If you have any questions regarding this request, please call
        us at 1-800-272-2216 8:00 AM - 8:00 PM ET, Monday through Friday and
        reference the policy number.
      </p>
      <hr />
      <div className="row mm-spacing--minor">
        <div className="col-md-7">
          <dl className="mm-key-value-pair">
            <dt id="requested_information_lbl">Requested Information</dt>
            <dd id="requested_information_value">
              <b>{selectedCheckboxes}</b>
            </dd>
            <dt id="rqt_information_policy_id">Policy Number</dt>
            <dd id="rqt_information_policy_id_value">{policyId}</dd>
            <dt id="rqt_information_recipient_lbl">Financial Professional</dt>
            <dd id="rqt_information_recipient_value">{requestorName}</dd>
            <dt id="rqt_information_requestor_email_lbl">
              Financial Professional Email
            </dt>
            <dd id="rqt_information_requestor_email_value">{requestorEmail}</dd>
            <dt id="rqt_information_insured_lbl">Insured Name(s)</dt>
            <dd id="rqt_information_insured_value">{insured}</dd>
            <dt id="rqt_information_date_lbl">Request Date/Time</dt>
            <dd id="rqt_information_date_value">{receivedDate}</dd>
          </dl>
        </div>
      </div>

      <a
        id="confirmation_serviceHistory_link_lbl"
        className="btn btn-primary hide-print"
        href={`/policyDetails/${encodeURIComponent(
          agreementKey
        )}/viewServiceHistory`}
      >
        VIEW SERVICE HISTORY
      </a>
      <a
        href={`/policyDetails/${encodeURIComponent(agreementKey)}`}
        className="btn btn-link hide-print"
      >
        Close
      </a>
    </div>
  );
};

export default React.memo(ConfirmationPage);
