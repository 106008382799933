/* eslint-disable no-underscore-dangle */
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, useHistory } from "react-router-dom";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import App from "./App";
import { UserProvider } from "./contexts/user";
import { DevModeProvider } from "./contexts/devMode";
import * as serviceWorker from "./serviceWorker";
import ScrollToTop from "./utils/scrollToTop";
import { getConfig, types } from "./config";
import { AgentProfileProvider } from "./contexts/agentProfile";

const SecuredApp = () => {
  const OKTA_DOMAIN = getConfig(types.domain);
  const CLIENT_ID = getConfig(types.clientId);
  const CALLBACK_PATH = "/implicit/callback";

  const ISSUER = `https://${OKTA_DOMAIN}/`;
  const HOST = window.location.host;
  const REDIRECT_URI = `https://${HOST}${CALLBACK_PATH}`;
  const SCOPES = "openid profile email groups idnum";

  const config = {
    issuer: ISSUER,
    clientId: CLIENT_ID,
    redirectUri: REDIRECT_URI,
    scopes: SCOPES.split(/\s+/),
    pkce: true,
    audience: "api://servicenetapi",
  };

  const oktaAuth = new OktaAuth(config);

  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <DevModeProvider>
        <UserProvider>
          <AgentProfileProvider>
            <App />
          </AgentProfileProvider>
        </UserProvider>
      </DevModeProvider>
    </Security>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <SecuredApp />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
