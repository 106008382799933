import { getConfig, types as configTypes } from "../config";

export const contactUSDetails = {
  DEFAULT: { number: "1-800-272-2216", time: "Mon - Fri 8 a.m. - 8 p.m. ET" },
  COVERPATH: {
    number: "1-866-957-5347",
    time: "Mon - Fri 8 a.m. - 8 p.m. ET",
  },
  UNIV: {
    redirectLink: `${getConfig(
      configTypes.advisor360
    )}/a360-dashboard/Dashboard`,
  },
  CORP: {
    number: "1-800-665-2654",
    time: "Mon - Fri 8 a.m. - 5 p.m. ET",
  },
  WORKSITE: { number: "1-844-975-7522", time: "Mon - Fri 9 a.m. - 5 p.m. ET" },
  PAGE: { link: "/contact-us" },
};
export const contactUsDetailsUnSupportedLob = {
  ANN: {
    FA: {
      OPM: contactUSDetails.DEFAULT,
      MPR: contactUSDetails.DEFAULT,
      LIFCOM: contactUSDetails.DEFAULT,
    },
    VA: {
      UNIV: contactUSDetails.UNIV,
      LIFCOM: contactUSDetails.DEFAULT,
    },
  },
  LIFE: {
    PERM: {
      CM2000: contactUSDetails.DEFAULT,
      HAP: contactUSDetails.COVERPATH,
    },
    NTL: {
      VNTAGE: contactUSDetails.DEFAULT,
      UNIV: contactUSDetails.UNIV,
    },
  },
  LTC: {
    TRAD: {
      LFCARE: {
        number: "1-888-505-8952",
        time: "Mon - Fri 10 a.m. - 8 p.m. ET",
      },
    },
    NONTRAD: {
      LTC: {
        number: "1-800-568-9916",
        time: "Mon - Fri 8 a.m. - 6 p.m. ET",
      },
    },
    NTL: {
      UNIV: contactUSDetails.UNIV,
    },
  },
  LCM: {
    NTL: {
      UNIV: contactUSDetails.UNIV,
    },
  },
  INVS: {
    DPP: {
      UNIV: contactUSDetails.UNIV,
    },
    INVS: {
      UNIV: contactUSDetails.UNIV,
    },
    MUTLFUND: {
      UNIV: contactUSDetails.UNIV,
    },
    SECBRKG: {
      UNIV: contactUSDetails.UNIV,
    },
    UIT: {
      UNIV: contactUSDetails.UNIV,
    },
  },
};
