import React from "react";
import { formatLisrOrSiprOPMData } from "./dividendHelper";

const LisrOpmSection = (props) => {
  const {
    currentAmountOYT,
    currentCoverage,
    paidUpInsurancePortionOfSIA,
    totalAnnualPremium,
  } = formatLisrOrSiprOPMData(props);
  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <dl className="mm-key-value-pair">
          <dt id="lisr_rider_name_lbl">Rider Name</dt>
          <dd id="lisr_rider_name_value">LISR</dd>

          <dt id="lisr_term_portion_lbl">Term Portion of SIA</dt>
          <dd id="lisr_term_portion_value"> {currentAmountOYT}</dd>
          <dt id="lisr_sup_insurance_lbl">
            Supplemental Insurance Amount - Total
          </dt>
          <dd id="lisr_sup_insurance_value">{currentCoverage}</dd>
        </dl>
      </div>
      <div className="col-12 col-md-6">
        <dl className="mm-key-value-pair">
          <dt id="lisr_term_insurance_cost_lbl">Cost of Term Insurance</dt>
          <dd id="lisr_term_insurance_cost_value">{totalAnnualPremium}</dd>
          <dt id="lisr_paid_up_insurance_portion_lbl">
            Paid Up Insurance Portion of SIA
          </dt>
          <dd id="lisr_paid_up_insurance_portion_value">
            {paidUpInsurancePortionOfSIA}
          </dd>
        </dl>
      </div>
    </div>
  );
};

export default React.memo(LisrOpmSection);
