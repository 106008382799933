import React from "react";
import TextArea from "./textarea";
import Label from "./label";
import Errors from "./error";
import { hasError } from "../../../utils/validation";
import IsTruthy from "../logic/isTruthy";
import { isDefined } from "../../../utils/evaluate";
import { stringIsEmptyOrNullish } from "../../../utils/string";
import IsFalsy from "../logic/isFalsy";

const TextAreaForm = (props) => {
  const { formField, useHook } = props;

  const classString = hasError(formField)
    ? "textarea-group mm-spacing--minor is-invalid"
    : "textarea-group mm-spacing--none";

  return (
    <>
      <IsTruthy value={formField.hideLabel}>
        <IsTruthy value={isDefined(formField.prependText) && !formField.hidden}>
          <div className={classString}>
            <div className="textarea-group-prepend">
              <span
                id={`prependText_${formField.id}`}
                className="textarea-group-text"
              >
                {formField.prependText}
              </span>
            </div>
            <TextArea formField={formField} useHook={useHook} />
          </div>
          <Errors formField={formField} />
        </IsTruthy>
        <IsTruthy value={stringIsEmptyOrNullish(formField.prependText)}>
          <TextArea formField={formField} useHook={useHook} />
          <Errors formField={formField} />
        </IsTruthy>
      </IsTruthy>
      <IsFalsy value={formField.hideLabel}>
        <div className={classString}>
          <div className="form-group">
            <Label formField={formField} />
            <TextArea formField={formField} useHook={useHook} />
            <Errors formField={formField} />
          </div>
        </div>
      </IsFalsy>
    </>
  );
};

export default React.memo(TextAreaForm);
