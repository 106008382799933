import React, { useEffect, useState } from "react";
import { nameCase } from "@foundernest/namecase";
import { useLDValue } from "../common/featureFlags/hooks";
import IsFalsy from "../common/logic/isFalsy";
import IsTruthy from "../common/logic/isTruthy";
import { verifyPolicyCoverageSummaryLink } from "../policyCoverageSummary/restrictions";
import { fireTealiumEvent } from "../../utils/tealium";
import { fetchPdfFromContentApi } from "../../api";
import { useUserState } from "../../contexts/user";
import {
  convertBase64StringToByteArray,
  downloadPDF,
} from "../common/helpers/downloadHelper";
import {
  getFirstServicingAgent,
  getLoggedInUserInfo,
} from "../../utils/findData";
import { formatDate } from "../../utils/dates";
import {
  setUndefinedFieldsTo0,
  contentApiPdfRequestNumericalFields,
} from "../../utils/setFieldValues";
import { cutPrecedingZerosFromId, formatName } from "../../utils/format";

export const createRequestBody = (props) => {
  const { agreementData, producersData, user } = props;

  const servicingAgent = getFirstServicingAgent({
    agents: producersData?.producers,
  });

  const {
    firstName = "",
    lastName = "",
    fullName = "",
    businessPartnerId = "",
    homeAgencyId = "",
  } = servicingAgent;

  const { unprocessedAgreementData } = agreementData;

  const formattedFullName = formatName({
    nameObject: {
      fullName,
    },
    format: "firstNameDisplayedFirst",
  });

  const formattedRequestingAgency =
    cutPrecedingZerosFromId(homeAgencyId).toString();

  const producerInfo = [
    {
      firstName: nameCase(firstName),
      lastName: nameCase(lastName),
      businessPartnerId: businessPartnerId.toString(),
      requestorTypeCode: "A",
      fullName: formattedFullName,
      requestingAgency: formattedRequestingAgency,
    },
  ];

  const loggedInUserInfo = getLoggedInUserInfo(user);

  const today = new Date();
  const dateAsString = today.toString();
  const currentDate = formatDate(dateAsString, "YYYY-MM-DD", "");

  const processedAgreementData = setUndefinedFieldsTo0({
    fields: contentApiPdfRequestNumericalFields,
    data: unprocessedAgreementData.agreements[0],
  });

  const requestBody = {
    agreements: [
      {
        ...processedAgreementData,
        producers: producerInfo,
        loggedInUser: loggedInUserInfo,
        currentDate,
      },
    ],
    messages: unprocessedAgreementData?.messages,
    transType: unprocessedAgreementData?.transType,
    transGuid: unprocessedAgreementData?.transGuid,
    transExecDateTS: unprocessedAgreementData?.transExecDateTS,
  };

  return requestBody;
};

const handleClick = async (props) => {
  const {
    agreementData,
    user,
    policyId,
    userEmail,
    tealiumCategory,
    setIsLoading,
    producersData,
  } = props;

  setIsLoading(true);

  const headers = {
    Authorization: `Bearer ${user.accessToken}`,
  };
  const docType = "policyverification";
  const downloadFilename = "policy_coverage_summary.pdf";
  const reqBody = createRequestBody({ agreementData, producersData, user });
  fetchPdfFromContentApi({ headers, apiParams: { reqBody, docType } })
    .then((result) => {
      if (result?.data?.length && !result?.error) {
        fireTealiumEvent({
          label: "SVNT",
          action: "Policy Coverage Summary Download Link clicked",
          category: tealiumCategory,
          value: policyId,
          custId: userEmail,
        });
        downloadPDF(
          "",
          "",
          convertBase64StringToByteArray(result?.data),
          downloadFilename
        );
        setIsLoading(false);
      }
    })
    .catch(() => {
      setIsLoading(false);
      fireTealiumEvent({
        label: "SVNT",
        action: "Policy Coverage Summary Download failed",
        category: tealiumCategory,
        value: policyId,
        custId: userEmail,
      });
    });
};

const PolicyCoverageSummaryLink = (props) => {
  const {
    agreementData,
    producersData,
    cssClassName,
    policyId,
    userEmail,
    tealiumCategory,
    isRenderAsListItem = false,
    setShowPolicyCoverageSummaryLink = () => {},
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const iconClass = isLoading ? " loading" : " download";

  const transactionSupportedPolicies = useLDValue({
    flagName: ["transactionSupportedPolicies"],
  });

  const { user } = useUserState();

  const { isTransactionAllowed, isEligible } = verifyPolicyCoverageSummaryLink({
    agreementData,
    transactionSupportedPolicies,
  });

  const isEligibleForPolicyCoverageSummaryLink =
    isEligible && isTransactionAllowed;

  useEffect(() => {
    setShowPolicyCoverageSummaryLink(isEligibleForPolicyCoverageSummaryLink);
  }, [isEligibleForPolicyCoverageSummaryLink]);

  return (
    <IsTruthy value={isEligibleForPolicyCoverageSummaryLink}>
      <IsTruthy value={isRenderAsListItem}>
        <li>
          <button
            type="button"
            id="policyCoverageSummary-link"
            onClick={() =>
              handleClick({
                agreementData,
                user,
                policyId,
                userEmail,
                tealiumCategory,
                producersData,
                setIsLoading,
              })
            }
            className={`${cssClassName}${iconClass}`}
          >
            {" "}
            Policy Coverage Summary
          </button>
        </li>
      </IsTruthy>
      <IsFalsy value={isRenderAsListItem}>
        <button
          type="button"
          id="policyCoverageSummary-link"
          onClick={() =>
            handleClick({
              agreementData,
              user,
              policyId,
              userEmail,
              tealiumCategory,
              producersData,
              setIsLoading,
            })
          }
          className={cssClassName}
        >
          {" "}
          Policy Coverage Summary
        </button>
      </IsFalsy>
    </IsTruthy>
  );
};

export default React.memo(PolicyCoverageSummaryLink);
