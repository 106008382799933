import { AUTHORIZATION_CONFIRMATION_STATEMENT } from "../../../constants/ui";
import { useUserState } from "../../../contexts/user";
import { useRequestorContactEmail } from "../../../hooks/helper";
import { formattingTypes } from "../../../utils/formFormatting";
import {
  useConfirmEmailValidation,
  useEmailValidation,
  useGeneralValidation,
} from "../../../utils/validation";

export const useChangeBillingFrequencyFormFields = () => {
  const {
    user: { firstName, lastName },
  } = useUserState();
  const contactChoiceEmail = useRequestorContactEmail();

  return {
    monthlyBilling: {
      id: "monthlyBilling",
      label: "Monthly (PAC)",
      labelBold: true,
      name: "monthlyBilling",
      groupName: "billingFrequencyRadio",
      value: false,
      isPac: true,
      isCurrentBillingMode: false,
    },
    quarterlyBilling: {
      id: "quarterlyBilling",
      label: "Quarterly",
      labelBold: true,
      name: "quarterlyBilling",
      groupName: "billingFrequencyRadio",
      value: false,
      isPac: false,
      isCurrentBillingMode: false,
    },
    semiAnnualBilling: {
      id: "semiAnnualBilling",
      label: "Semi-Annual",
      labelBold: true,
      name: "semiAnnualBilling",
      groupName: "billingFrequencyRadio",
      value: false,
      isPac: false,
      isCurrentBillingMode: false,
    },
    annualBilling: {
      id: "annualBilling",
      label: "Annual",
      labelBold: true,
      name: "annualBilling",
      groupName: "billingFrequencyRadio",
      value: false,
      isPac: false,
      isCurrentBillingMode: false,
    },
    requestorNameInput: {
      id: "requestorName",
      name: "requestorName",
      value: `${firstName} ${lastName}`,
      formatting: formattingTypes.NAMES,
      useValidation: useGeneralValidation,
      hideLabel: true,
      errors: [],
      postpone: true,
      disabled: true,
    },
    contactChoiceEmailInput: {
      id: "contactChoiceEmailInput",
      hideLabel: true,
      ariaLabel: "Email input",
      placeholder: "Please type your email address",
      name: "contactChoiceEmailInput",
      value: contactChoiceEmail,
      uncheckedValue: "",
      errors: [],
      postpone: true,
      useValidation: useEmailValidation,
      disabled: false,
    },
    contactChoiceConfirmEmailInput: {
      id: "contactChoiceConfirmEmailInput",
      hideLabel: true,
      ariaLabel: "Confirm Email input",
      placeholder: "Please type your email address",
      name: "contactChoiceConfirmEmailInput",
      value: "",
      uncheckedValue: "",
      errors: [],
      postpone: true,
      emailFormFieldValue: contactChoiceEmail,
      useValidation: useConfirmEmailValidation,
      disabled: false,
      disablePaste: true,
    },
    changeBillingFrequencyStartDate: {
      id: "changeBillingFrequencyStartDate",
      label: "Start Date",
      labelBold: true,
      name: "changeBillingFrequencyStartDate",
      groupName: "changeBillingFrequencyStartDate",
      value: new Date(),
      minDate: "",
      maxDate: "",
    },
    authorizedToChangeBillingFrequency: {
      id: "authorizedToChangeBillingFrequency",
      label: AUTHORIZATION_CONFIRMATION_STATEMENT,
      name: "authorizedToChangeBillingFrequency",
      value: false,
      required: true,
    },
  };
};
