import React from "react";
import LabelWithHelpModal from "../../common/forms/labelWithHelpModal";

const PolicyCoverageSummaryModalBody = () => (
  <div className="modal-body">
    <hr />
    <h5 id="directLink_PolicyCoverageSummary_ModalTitle">
      Policy Coverage Summary
    </h5>
    <p>
      The following can be done on the <strong>website </strong>or{" "}
      <strong>mobile</strong> app:
    </p>
    <ul>
      <li>Customer can view/print Policy Coverage Summary</li>
    </ul>
    <div className="mm-spacing--minor" />
    <h5 id="directLink_PolicyCoverageSummary_ModalQuickTips">Quick Tips</h5>
    <ul>
      <li>Mirrors letter offered from Life Service Center</li>
      <li>
        Provides current values instead of waiting for an annual statement
      </li>
    </ul>
  </div>
);

const PolicyCoverageSummaryModal = () => (
  <LabelWithHelpModal
    text="Policy Coverage Summary"
    modalTitle="Policy Coverage Summary"
    modalId="policyCoverageSummary"
    modalBodyComponent={PolicyCoverageSummaryModalBody}
  />
);

export default PolicyCoverageSummaryModal;
