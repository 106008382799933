import { negateValueCssClass } from "../../../../../utils/css";
import { formatPercentage, formatMoney } from "../../../../../utils/format";
import {
  compareArrayOfStrings,
  compareStrings,
} from "../../../../../utils/string";

const zeroValueStrings = ["-", "0.00", "0"];

const checkValuationColumn = (value) => {
  const VALUATION_COLUMN_IS_ZERO = compareArrayOfStrings(
    zeroValueStrings,
    value
  );
  return VALUATION_COLUMN_IS_ZERO;
};

export const useCashValuesProps = (props) => {
  const {
    row,
    rowIndex,
    column: { accessor },
  } = props;

  const VALUATION_IS_ZERO = checkValuationColumn(row.valuation);
  const VALUE_IS_ZERO =
    compareArrayOfStrings(zeroValueStrings, row[accessor]) || VALUATION_IS_ZERO;
  const IS_VALUE_COLUMN = compareStrings(accessor, "valuation");

  const noChangeValue = IS_VALUE_COLUMN ? "Not Available" : "-";
  const cellValue = VALUE_IS_ZERO ? noChangeValue : formatMoney(row[accessor]);
  const className = VALUE_IS_ZERO ? "" : negateValueCssClass(row[accessor]);
  const cashValueType = row?.id === "0" ? "current" : "historical";

  return {
    cellValue,
    rowIndex,
    accessor,
    className,
    date: row.date,
    cashValueType,
  };
};
export const useDollarChangeProps = (props) => {
  const {
    row,
    rowIndex,
    column: { accessor },
  } = props;

  const VALUATION_COLUMN_IS_ZERO = checkValuationColumn(row.valuation);
  const VALUE_IS_ZERO =
    compareArrayOfStrings(zeroValueStrings, row[accessor]) ||
    VALUATION_COLUMN_IS_ZERO;

  const cellValue = VALUE_IS_ZERO ? "-" : formatMoney(row[accessor]);
  const className = VALUE_IS_ZERO ? "" : negateValueCssClass(row[accessor]);
  const cashValueType = row?.id === "0" ? "current" : "historical";

  return {
    cellValue,
    rowIndex,
    accessor,
    className,
    date: row.date,
    cashValueType,
  };
};

export const usePercentValuesProps = (props) => {
  const {
    row,
    rowIndex,
    column: { accessor },
  } = props;

  const VALUATION_COLUMN_IS_ZERO = checkValuationColumn(row.valuation);
  const VALUE_IS_ZERO =
    compareStrings(row[accessor], "-") || VALUATION_COLUMN_IS_ZERO;

  const cellValue = VALUE_IS_ZERO
    ? "-"
    : formatPercentage({ rate: row[accessor] });
  const className = VALUE_IS_ZERO ? "" : negateValueCssClass(row[accessor]);
  const cashValueType = row?.id === "0" ? "current" : "historical";

  return {
    cellValue,
    rowIndex,
    accessor,
    className,
    date: row.date,
    cashValueType,
  };
};

export const useDateValueProps = (props) => {
  const {
    row,
    rowIndex,
    column: { accessor },
  } = props;
  const cashValueType = row?.id === "0" ? "current" : "historical";

  return {
    cellValue: row[accessor],
    rowIndex,
    accessor,
    date: row.date,
    cashValueType,
  };
};
