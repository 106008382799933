import { useMemo } from "react";
import { formatDate, getDateDifference } from "../../../../utils/dates";
import {
  formatMoney,
  formatStatusCode,
  formatAnnuityProductName,
  formatStatusReasonCode,
  formatPercentage,
  convertStateAbbreviationToWord,
} from "../../../../utils/format";
import {
  isStableVoyageAnnuityProduct,
  isFixedAnnuity,
  isOdysseyAnnuityProduct,
  isVariableAnnuity,
  isNonEnvisionAnnuity,
  isArtistryAnnuityProduct,
  isRetireEaseContract,
  isFoundationFlexiblePremium,
  isFoundationSinglePremium,
  isRetireEaseChoiceContract,
  isFlexIVFund1FPOrFund4FP,
} from "../../../../utils/policies";
import DeferredSalesChargeSchedule from "./deferredSalesChargeSchedule/index";
import { useModalState } from "../../../../contexts/modal";
import { isDefined, isObject } from "../../../../utils/evaluate";
import {
  compareArrayOfStrings,
  compareStrings,
  containsArrayOfStrings,
  stringIsEmptyOrNullish,
} from "../../../../utils/string";
import { cdscSchedules } from "./deferredSalesChargeSchedule/hooks";
import { GMIB_PLUS_RIDER_NAMES } from "../../../../constants/logic";

export const useContractDetailsProps = (props) => {
  const {
    annuityRequest = {},
    agreementRequest = {},
    soaBillingRequest = {},
    cdaValuesRequest = {},
    recalculatedValues,
  } = props;
  const data = selectContractDetailData(props);
  const isStableVoyageProduct = isStableVoyageAnnuityProduct(
    data.agreementKeyPrefix
  );

  const { totalDeposit } = soaBillingRequest?.data || "";
  const {
    surrenderValue: cdaSurrValue,
    surrenderCharge: cdaSurrCharge,
    currentDeathBenefit: cdaDeathBenefit,
    surrenderFreeAmount: cdaFreeAmount,
  } = cdaValuesRequest?.data || "";

  const isOdysseyProduct = isOdysseyAnnuityProduct(data.agreementKeyPrefix);
  const isFixedAnnuityContract = isFixedAnnuity(props?.agreementRequest);
  const isVariableAnnuityContract = isVariableAnnuity(props?.agreementRequest);
  const isNonEnvisionAnnuityContract = isNonEnvisionAnnuity(
    props?.agreementRequest
  );
  const isArtistryAnnuityContract = isArtistryAnnuityProduct(
    data.agreementKeyPrefix
  );
  const isFoundationPremiumContract =
    isFoundationSinglePremium(props?.agreementRequest?.data) ||
    isFoundationFlexiblePremium(props?.agreementRequest?.data);

  const isFlexIVFund1FPOrFund4FPContract = isFlexIVFund1FPOrFund4FP(
    agreementRequest?.data?.kindCode
  );

  const { surrenderChargePeriod } = annuityRequest?.data
    ? annuityRequest.data
    : "";

  const { showModal } = useViewScheduleModal({
    isNonEnvisionAnnuityContract,
    agreementData: agreementRequest.data,
    annuityData: annuityRequest?.data,
  });

  let freeWithdrawalProvision;
  if (data.surrenderChargeAmount > 0) {
    freeWithdrawalProvision = formatMoney(data.freeWithdrawalProvision, "-");
  }
  if (data.surrenderChargeAmount === 0) {
    freeWithdrawalProvision = "Unavailable";
  }

  let displayToolTip = false;
  if (isFixedAnnuityContract) {
    displayToolTip = compareStrings(
      data.productTypeName.split(", ")[1],
      "1 Year Guarantee Period"
    );
  }

  if (isArtistryAnnuityContract) {
    displayToolTip = data.surrenderCharge === 0;
  }

  const displayGmibAvailWithdrawalAmount = isDefined(
    data.gmibAvailWithdrawalAmount
  );

  const displayGmwbAvailWithdrawalAmount = isObject(data.gmwbRider);
  const gmwbAvailWithdrawalAmount =
    data.gmwbRider?.riderAvailWithdrawAmt || "-";

  const assetAllocationModelKindCodes = [
    "TRANSL",
    "VASFPA",
    "VASFPB",
    "VASFPN",
    "VASOFA",
    "VASOFB",
    "VASOPE",
    "VASPDF",
    "VASSPE",
    "FPVA",
    "FPVN",
    "PAPLAN",
    "PAPLNN",
  ];
  const showAssetAllocationModelField = compareArrayOfStrings(
    assetAllocationModelKindCodes,
    data.kindCode
  );

  const surrenderChargeScheduleModalKindCodes = useMemo(
    () => createCDSCKindCodeList(cdscSchedules),
    []
  );

  const enableSurrChargeScheduleModal =
    compareArrayOfStrings(
      surrenderChargeScheduleModalKindCodes,
      data.kindCode
    ) && !data.isStableVoyage1Year;

  const isRetireEaseAnnuityProduct = isRetireEaseContract(data);

  const enableRetireEaseCdscModal =
    compareStrings("RET012", agreementRequest?.data?.kindCode) &&
    annuityRequest?.data?.annuityOption?.includes("Period Certain");

  const issueState =
    isRetireEaseAnnuityProduct ||
    isFoundationPremiumContract ||
    isFlexIVFund1FPOrFund4FPContract
      ? data.jurisdictionState
      : data.issueState;

  const vaFreeWithdrawal = isFlexIVFund1FPOrFund4FPContract
    ? cdaFreeAmount
    : data.freeAmount;

  const vaContingentDeferredSalesCharge = isFlexIVFund1FPOrFund4FPContract
    ? cdaSurrCharge
    : data.surrenderCharge;

  const displayVAFreeAmountToolTip =
    isVariableAnnuity(agreementRequest) &&
    !stringIsEmptyOrNullish(vaContingentDeferredSalesCharge) &&
    Number(vaContingentDeferredSalesCharge) === 0;

  const {
    isRetireEaseChoiceAndPeriodCertainOrRefundAnnuityOption,
    shouldDisplayTotalDistributionsForRetireEase,
    shouldDisplayTotalDistributionsForRetireEaseChoice,
  } = calculateFieldsDisplayCriteria(data);
  let vaFreeWithdrawalAmount;
  if (
    !stringIsEmptyOrNullish(vaContingentDeferredSalesCharge) &&
    Number(vaContingentDeferredSalesCharge) === 0
  ) {
    vaFreeWithdrawalAmount = "Not Applicable";
  } else if (isArtistryAnnuityContract) {
    vaFreeWithdrawalAmount = !displayToolTip
      ? formatMoney(data.freeAmount, "Unavailable")
      : "Unavailable";
  } else {
    vaFreeWithdrawalAmount = formatMoney(vaFreeWithdrawal, "-");
  }

  let issuingCompany;
  let totalPurchasePaymentAmount;
  let surrenderChargeSchedule;
  let ascSurrenderCharge;
  let freeAmount;
  let deathBenefitAmount;
  let netSurrenderAmount;
  if (isFoundationPremiumContract || isFlexIVFund1FPOrFund4FPContract) {
    issuingCompany = "Mass Mutual Life Ins Co";
    totalPurchasePaymentAmount = formatMoney(totalDeposit, "-");
    surrenderChargeSchedule = isDefined(surrenderChargePeriod)
      ? `${surrenderChargePeriod} Years`
      : "-";
    netSurrenderAmount = formatMoney(cdaSurrValue, "-");
    ascSurrenderCharge = formatMoney(cdaSurrCharge, "-");
    freeAmount = compareStrings(formatMoney(cdaSurrCharge), formatMoney("0.00"))
      ? "Not Applicable"
      : formatMoney(cdaFreeAmount, "-");
    deathBenefitAmount = formatMoney(cdaDeathBenefit, "-");
  } else {
    issuingCompany = isDefined(data.issuingCompany) ? data.issuingCompany : "-";
    totalPurchasePaymentAmount = formatMoney(data.netPremiumAmount, "-");
    surrenderChargeSchedule = isDefined(data.surrChargeSchedule)
      ? data.surrChargeSchedule
      : "-";
    netSurrenderAmount = formatMoney(data.netSurrenderAmount, "-");
    ascSurrenderCharge = formatMoney(data.ascSurrenderCharge, "-");
    freeAmount = compareStrings(
      formatMoney(data.ascSurrenderCharge),
      formatMoney("0.00")
    )
      ? "Not Applicable"
      : formatMoney(data.freeAmount, "-");
    deathBenefitAmount = formatMoney(data.deathBenefitAmount, "-");
  }

  const surrenderChargeScheduleValue =
    compareArrayOfStrings(
      [
        ...cdscSchedules.LIFETRUST.kindCodes,
        ...cdscSchedules.PANORAMA_PREMIER.kindCodes,
      ],
      data.kindCode
    ) && compareStrings(data.agreementKeyAdmin, "VNTGKC")
      ? "View Schedule"
      : surrenderChargeSchedule;

  return {
    showModal,
    policyId: isDefined(data.policyId) ? data.policyId : "-",
    lineOfBusinessName: isDefined(data.lineOfBusinessName)
      ? data.lineOfBusinessName
      : "-",
    productTypeName: isDefined(data.productTypeName)
      ? data.productTypeName
      : "-",
    marketingTypeDescription: isDefined(data.marketingTypeDescription)
      ? data.marketingTypeDescription
      : "-",
    fundBalanceAmount: formatMoney(data.fundBalanceAmount, "-"),
    issueDate: formatDate(data.issueDate, "", "-"),
    requestedMaturityDate: formatDate(data.requestedMaturityDate, "", "-"),
    status: `${formatStatusCode(data.status)}, ${formatStatusReasonCode(
      data.statusReason,
      data.lineOfBusinessCode
    )}`,
    netSurrenderAmount,
    surrenderChargeAmount: formatMoney(data.surrenderChargeAmount, "-"),
    ascSurrenderCharge,
    maxPartialSurrenderValue: formatMoney(data.maxPartialSurrenderValue, "-"),
    freeWithdrawalProvision,
    totalAvailableWithdrawalAmount: formatMoney(
      data.totalAvailableWithdrawalAmount,
      "-"
    ),
    deathBenefitAmount,
    guaranteedMinimumDeathBenefitAmount: formatMoney(
      data.guaranteedMinimumDeathBenefitAmount,
      "-"
    ),
    minimumGuaranteedInterestRate: isDefined(data.minimumGuaranteedInterestRate)
      ? formatPercentage({
          rate: data.minimumGuaranteedInterestRate,
          decimalPlaces: 1,
          maxDecimalPlaces: 1,
        })
      : "-",
    guarPeriodStartDate: formatDate(data.guarPeriodStartDate, "", "-"),
    guarPeriodEndDate: formatDate(data.guarPeriodEndDate, "", "-"),
    surrenderChargeSchedule,
    freeAmount,
    isStableVoyageProduct,
    isOdysseyProduct,
    issueState: isDefined(issueState)
      ? convertStateAbbreviationToWord(issueState)
      : "-",
    isFixedAnnuityContract,
    isVariableAnnuityContract,
    displayToolTip,
    displayVAFreeAmountToolTip,
    vaFreeWithdrawalAmount,
    gmibAvailWithdrawalAmount:
      formatMoney(data.gmibAvailWithdrawalAmount) || "",
    displayGmibAvailWithdrawalAmount,
    gmwbAvailWithdrawalAmount: formatMoney(gmwbAvailWithdrawalAmount) || "",
    displayGmwbAvailWithdrawalAmount,
    assetAllocModel: isDefined(data.assetAllocModel)
      ? data.assetAllocModel
      : "-",
    showAssetAllocationModelField,
    enableSurrChargeScheduleModal,
    isArtistryAnnuityContract,
    employeePayments: formatMoney(data.contributionsEE, ""),
    employeeContractValue: formatMoney(data.accountBalanceEE, ""),
    employerContractValue: formatMoney(data.accountBalanceER, ""),
    artistryFreeWithdrawalValue:
      data.surrenderCharge && !displayToolTip
        ? formatMoney(data.surrenderCharge, "")
        : "Unavailable",
    totalDistributions: formatMoney(data.totalDistributions, "-"),
    initialPurchase: formatMoney(data.initialPurchase, "-"),
    isRetireEaseContract: isRetireEaseAnnuityProduct,
    annuityOption: isDefined(data.annuityOption) ? data.annuityOption : "-",
    annuityOptionDescription: isDefined(data.annuityOptionDescription)
      ? data.annuityOptionDescription
      : "-",
    issuingCompany,
    residentState: isDefined(data.residentState)
      ? convertStateAbbreviationToWord(data.residentState)
      : "-",
    remainingInstallment: formatMoney(data.fundBalanceAmount, "-"),
    displayLengthOfPeriodCertain:
      isRetireEaseAnnuityProduct &&
      containsArrayOfStrings(["Period Certain"], data.annuityOption),
    lengthOfPeriodCertain:
      getDateDifference(data.issueDate, data.requestedMaturityDate)?.years ||
      "-",
    isFoundationPremiumContract,
    isFlexIVFund1FPOrFund4FPContract,
    totalPurchasePaymentAmount,
    showRecalcValues: data.showRecalcValues || false,
    recalcAsOfDate: formatDate(
      recalculatedValues?.recalculation?.asOfDate,
      "monthCommaDayYear",
      "Unavailable"
    ),
    isRetireEaseChoiceAndPeriodCertainOrRefundAnnuityOption,
    shouldDisplayTotalDistributionsForRetireEase,
    shouldDisplayTotalDistributionsForRetireEaseChoice,
    enableRetireEaseCdscModal,
    surrenderChargeScheduleValue,
  };
};

export const useViewScheduleModal = (props) => {
  const { openModal } = useModalState();

  const showModal = () => () => {
    const modalProps = {
      Component: DeferredSalesChargeSchedule,
      componentProps: {
        props,
      },
    };
    openModal(modalProps);
  };
  return { showModal };
};

export const selectContractDetailData = (props) => {
  const {
    agreementRequest,
    annuityRequest,
    recalculatedValues,
    showRecalcValues,
  } = props;

  const annuityData = annuityRequest?.data;
  let data = { ...agreementRequest.data };

  const GMIB_WITHDRAWAL_AMOUNT_DESCS = [
    "GMIB Plus 5%",
    "GMIB Plus 5% 2008",
    "GMIB Plus 6%",
    "GMIB Plus 6% 2008",
  ];

  if (isObject(annuityRequest)) {
    const productNameCombined = formatAnnuityProductName({
      agreementData: agreementRequest?.data,
      productName: annuityData.productName,
      funds: annuityData.funds,
      surrChargeSchedule: annuityData.surrChargeSchedule,
      productPrefix: annuityData.productPrefix,
    });

    const recalcContractValue =
      recalculatedValues?.recalculation?.contractValue;

    let gmibAvailWithdrawalAmount = annuityData.riderInfo.find((rider) =>
      compareArrayOfStrings(GMIB_PLUS_RIDER_NAMES, rider.riderName)
    )?.riderAvailWithdrawAmt;

    if (showRecalcValues) {
      const gmibRider = recalcContractValue?.riders?.find((rider) =>
        compareArrayOfStrings(GMIB_WITHDRAWAL_AMOUNT_DESCS, rider.Description)
      );

      gmibAvailWithdrawalAmount =
        gmibRider?.OLifEExtension?.MMExtension?.AvailWithdrawlAmt;
    }

    let gmwbRider = annuityData.riderInfo.find((rider) =>
      compareStrings("GMWB", rider.type)
    );

    if (showRecalcValues) {
      gmwbRider = recalcContractValue?.riders?.find((rider) =>
        compareStrings(["WBSGL", "WBJNT"], rider.RiderCode)
      );
    }

    const isStableVoyageProduct = isStableVoyageAnnuityProduct(
      annuityData.productPrefix
    );

    let isStableVoyage1Year = false;
    if (isStableVoyageProduct) {
      const foundFund = annuityData.funds?.fund?.find(
        (fund) => fund.balance > 0
      );
      const displayName = foundFund?.displayName || "";
      isStableVoyage1Year = compareStrings(
        displayName,
        "1 Year Guarantee Period"
      );
    }

    data = {
      ...data,
      policyId: annuityData.contractNbr,
      productTypeName: productNameCombined,
      marketingTypeDescription: annuityData.marketType,
      issueDate: annuityData.issueDate,
      requestedMaturityDate: annuityData.maturityDate,
      netPremiumAmount: annuityData.totalContributions,
      fundBalanceAmount: showRecalcValues
        ? recalcContractValue?.policyValue
        : annuityData.contractValue,
      guarPeriodStartDate: annuityData.guarPeriodStartDate,
      guarPeriodEndDate: annuityData.guarPeriodEndDate,
      deathBenefitAmount: showRecalcValues
        ? recalcContractValue?.currentDeathBenefit
        : annuityData.currentDeathBenefit,
      surrChargeSchedule: isStableVoyage1Year
        ? "No CDSC"
        : annuityData.surrChargeSchedule,
      ascSurrenderCharge: showRecalcValues
        ? recalcContractValue?.contingentDeferredSalesCharge
        : annuityData.surrenderCharge,
      freeAmount: showRecalcValues
        ? recalcContractValue?.freeWithdrawalAmt
        : annuityData.freeAmount,
      netSurrenderAmount: showRecalcValues
        ? recalcContractValue?.contractWithdrawalValue
        : annuityData.surrenderValue,
      issueState: annuityData.issueState,
      totalAvailableWithdrawalAmount: annuityData.totalWithdrawals,
      gmibAvailWithdrawalAmount,
      gmwbRider,
      assetAllocModel: annuityData.assetAllocationModel,
      totalDistributions: annuityData.totalDistributions,
      initialPurchase: annuityData.initialPurchase,
      annuityOption: annuityData.annuityOption,
      annuityOptionDescription: annuityData.annuityOptionDescription,
      issuingCompany: annuityData.issuingCompany,
      residentState: annuityData.residentState,
      showRecalcValues,
      accountBalanceEE: showRecalcValues
        ? recalcContractValue?.policyValueEE
        : annuityData.accountBalanceEE,
      accountBalanceER: showRecalcValues
        ? recalcContractValue?.policyValueER
        : annuityData.accountBalanceER,
      contributionsEE: annuityData.contributionsEE,
      surrenderCharge: annuityData.surrenderCharge,
      minimumGuaranteedInterestRate: showRecalcValues
        ? recalcContractValue?.funds?.fund?.find((fund) => fund.balance > 0)
            ?.CurrRate || "-"
        : data?.minimumGuaranteedInterestRate,
      isStableVoyage1Year,
    };
  }
  return data;
};

export const calculateFieldsDisplayCriteria = (data) => {
  const isPeriodCertainAnnuityOption = containsArrayOfStrings(
    ["Period Certain"],
    data.annuityOption
  );
  const isRefundAnnuityOption = containsArrayOfStrings(
    ["Cash Refund", "Installment Refund"],
    data.annuityOption
  );
  const isRetireEaseChoiceContractResult = isRetireEaseChoiceContract(data);
  const isRetireEaseContractResult = isRetireEaseContract(data);
  const shouldDisplayTotalDistributionsForRetireEase =
    isRetireEaseContractResult &&
    isPeriodCertainAnnuityOption &&
    !isRetireEaseChoiceContractResult;

  const shouldDisplayTotalDistributionsForRetireEaseChoice =
    isPeriodCertainAnnuityOption && isRetireEaseChoiceContractResult;

  const isRetireEaseChoiceAndPeriodCertainOrRefundAnnuityOption =
    isRetireEaseChoiceContractResult &&
    (isPeriodCertainAnnuityOption || isRefundAnnuityOption);

  return {
    isRetireEaseChoiceAndPeriodCertainOrRefundAnnuityOption,
    shouldDisplayTotalDistributionsForRetireEase,
    shouldDisplayTotalDistributionsForRetireEaseChoice,
  };
};

export const createCDSCKindCodeList = (schedulesObject) => {
  const cdscObjects = Object.values(schedulesObject);

  const kindCodes = [];
  cdscObjects.forEach((schedule) => kindCodes.push(...schedule.kindCodes));
  return kindCodes;
};
