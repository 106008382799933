import React from "react";
import { FooterLegal } from "react-blueprint-library";

const Footer = () => (
  <FooterLegal
    sitemap=""
    privacy_policy_url=""
    terms_of_use_url="https://fieldnet.massmutual.com/fldnet/fnmmfg/login/terms-of-use.html"
  />
);

export default React.memo(Footer);
