import React from "react";
import { isQuarterString } from "../../../../../utils/dates";
import { useDateValueProps } from "./hooks";
import IsTruthy from "../../../../common/logic/isTruthy";
import IsFalsy from "../../../../common/logic/isFalsy";

const DateCell = (props) => {
  const { cellValue, rowIndex, accessor, date, cashValueType } =
    useDateValueProps(props);

  return (
    <span id={`cell_${rowIndex}_${accessor}_${cashValueType}_value`}>
      <IsTruthy value={date} validationFunction={isQuarterString}>
        <strong>{cellValue}</strong>
        <br />
        <span className="badge badge-pill badge-secondary">Quarter-End</span>
      </IsTruthy>
      <IsFalsy value={date} validationFunction={isQuarterString}>
        {cellValue}
      </IsFalsy>
    </span>
  );
};

export default React.memo(DateCell);
