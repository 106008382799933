import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useFormContext } from "../../../contexts/form";
import { useUserState } from "../../../contexts/user";
import { useRequestorContactEmail } from "../../../hooks/helper";
import { isDefined } from "../../../utils/evaluate";
import {
  getBpIdFromUser,
  getFirstServicingAgent,
  getUserBySubType,
  getUsers,
  isHomeOfficeUser,
  userTypes,
} from "../../../utils/findData";
import { formatMoney, formatName, getLastXChars } from "../../../utils/format";
import { formattingTypes } from "../../../utils/formFormatting";
import { compareStrings } from "../../../utils/string";
import {
  useConfirmEmailValidation,
  useEmailValidation,
  useGeneralValidation,
} from "../../../utils/validation";
import { checkForRequestorContactError } from "../../common/requestorContact/hooks";
import {
  formatDate,
  getCurrentDate,
  getCurrentDateTimeWithTimezone,
} from "../../../utils/dates";
import { fireTealiumEvent } from "../../../utils/tealium";
import { createCatsEvents } from "../../../api";
import { useLDValue } from "../../common/featureFlags/hooks";
import { AUTHORIZATION_CONFIRMATION_STATEMENT } from "../../../constants/ui";

export const useEnvisionFundTransferProps = (props) => {
  const { agreementRequest, setPageNumber, producerRequest } = props;
  const [error, setError] = useState(null);
  const [showErrorPage, setShowErrorPage] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [showLoadingIcon, setShowLoadingIcon] = useState(false);

  const { user } = useUserState();
  const { firstName, lastName, email } = user;
  const contactChoiceEmail = useRequestorContactEmail();
  const forceCompleteEvent = useLDValue({
    flagName: ["testingEventForceCompletion"],
  });
  const shouldDisableSubmit = isHomeOfficeUser(user);
  const agreementData = agreementRequest?.data || {};
  const {
    agreementCustomers,
    agreementFunds,
    productTypeName,
    policyId,
    lineOfBusinessCode,
  } = agreementData;
  const { marketingName, fundValueAmount } = agreementFunds[0] || "";

  const owners = getUsers(agreementCustomers, "OWNR") || [];
  const owner = owners.find((customer) => {
    if (
      (compareStrings(customer.roleSubType, "LIST") &&
        compareStrings(customer.type, "I")) ||
      compareStrings(customer.type, "N")
    ) {
      return customer;
    }
    return null;
  });
  const jointOwner = owners.find((customer) => {
    if (
      compareStrings(customer.roleSubType, "JNT") &&
      compareStrings(customer.type, "I")
    ) {
      return customer;
    }
    return null;
  });
  const jointAnnuitant = getUserBySubType({
    type: "INSD",
    subType: "JNT",
    people: agreementCustomers,
  });

  const onSubmit = (formData) => {
    setShowLoadingIcon(true);
    const headers = {
      Authorization: `Bearer ${user.accessToken}`,
    };

    const requestBody = makeEnvisionFundTransferRequestBody({
      formData,
      agreementRequest,
      producerRequest,
      user,
      forceCompleteEvent,
    });

    createCatsEvents({ headers, apiParams: requestBody })
      .then((result) => {
        setTransactionId(result?.data?.transGUID);
        if (result?.status !== 200) {
          throw new Error("non 200 response");
        } else {
          fireTealiumEvent({
            label: "SVNT",
            action: `${lineOfBusinessCode}: Fund Transfer submitted`,
            category: "Fund Transfer",
            value: policyId,
            custId: user?.email,
          });
          setPageNumber(3);
        }
      })
      .catch((e) => {
        fireTealiumEvent({
          label: "SVNT",
          action: `${lineOfBusinessCode} Fund Transfer Failed`,
          category: "Fund Transfer",
          value: policyId,
          custId: user?.email,
        });
        setErrorText(
          compareStrings("Network Error", e.message)
            ? "The page you are looking for is temporarily unavailable."
            : "There was an error processing your request; please try again later."
        );
        setShowErrorPage(true);
      });
  };

  const fundTransferFormFields = {
    mmlConservativeAllocation: {
      id: "mmlConservativeAllocation",
      name: "mmlConservativeAllocation",
      label: "MML Conservative Allocation",
      groupName: "transerToRadioGroup",
      value: false,
      disabled: false,
      fundModelId: "CONSAA",
      fundNumber: 657,
    },
    mmlModerateAllocation: {
      id: "mmlModerateAllocation",
      name: "mmlModerateAllocation",
      label: "MML Moderate Allocation",
      groupName: "transerToRadioGroup",
      value: false,
      disabled: false,
      fundModelId: "MODRAA",
      fundNumber: 659,
    },
    mmlBalancedAllocation: {
      id: "mmlBalancedAllocation",
      name: "mmlBalancedAllocation",
      label: "MML Balanced Allocation",
      groupName: "transerToRadioGroup",
      value: false,
      disabled: false,
      fundModelId: "BALNAA",
      fundNumber: 658,
    },
    mmlAmericanFundsCoreAllocation: {
      id: "mmlAmericanFundsCoreAllocation",
      name: "mmlAmericanFundsCoreAllocation",
      label: "MML American Funds Core Allocation",
      groupName: "transerToRadioGroup",
      value: false,
      disabled: false,
      fundModelId: "COREAA",
      fundNumber: 663,
    },
    mmlGrowthAllocation: {
      id: "mmlGrowthAllocation",
      name: "mmlGrowthAllocation",
      label: "MML Growth Allocation",
      groupName: "transerToRadioGroup",
      value: false,
      disabled: false,
      fundModelId: "GROWAA",
      fundNumber: 660,
    },
    mmlBlend: {
      id: "mmlBlend",
      name: "mmlBlend",
      label: "MML Blend",
      groupName: "transerToRadioGroup",
      value: false,
      disabled: false,
      fundModelId: "BLNDAA",
      fundNumber: 627,
    },
    mmlIshares8020Allocation: {
      id: "mmlIshares8020Allocation",
      name: "mmlIshares8020Allocation",
      label: "MML iShares 80/20 Allocation",
      groupName: "transerToRadioGroup",
      value: false,
      disabled: false,
      fundModelId: "8020AA",
      fundNumber: 690,
    },
    mmlIshares6040Allocation: {
      id: "mmlIshares6040Allocation",
      name: "mmlIshares6040Allocation",
      label: "MML iShares 60/40 Allocation",
      groupName: "transerToRadioGroup",
      value: false,
      disabled: false,
      fundModelId: "6040AA",
      fundNumber: 689,
    },
    requestorNameInput: {
      id: "requestorName",
      name: "requestorName",
      value: `${firstName} ${lastName}`,
      formatting: formattingTypes.NAMES,
      useValidation: useGeneralValidation,
      hideLabel: true,
      errors: [],
      postpone: true,
      disabled: true,
    },
    contactChoiceEmailInput: {
      id: "contactChoiceEmailInput",
      hideLabel: true,
      ariaLabel: "Email input",
      placeholder: "Please type your email address",
      name: "contactChoiceEmailInput",
      value: contactChoiceEmail,
      uncheckedValue: "",
      errors: [],
      postpone: true,
      useValidation: useEmailValidation,
      disabled: false,
    },
    contactChoiceConfirmEmailInput: {
      id: "contactChoiceConfirmEmailInput",
      hideLabel: true,
      ariaLabel: "Confirm Email input",
      placeholder: "Please type your email address",
      name: "contactChoiceConfirmEmailInput",
      value: "",
      uncheckedValue: "",
      errors: [],
      postpone: true,
      emailFormFieldValue: contactChoiceEmail,
      useValidation: useConfirmEmailValidation,
      disabled: false,
      disablePaste: true,
    },
    authAcknowledgement: {
      id: "authAcknowledgement",
      name: "authAcknowledgement",
      label: AUTHORIZATION_CONFIRMATION_STATEMENT,
      value: false,
      required: true,
    },
    formAcknowledgement: {
      id: "formAcknowledgement",
      name: "formAcknowledgement",
      label: [
        "I am authorized to perform this fund transfer and have a completed ",
        <a
          id="form_disclosure_link"
          href="https://fieldnet.massmutual.com/public/ann/pdfs/fr2123.pdf"
          rel="noreferrer"
          target="_blank"
          key="form_disclosure_link"
        >
          Producer Authorization Form (FR2123)
        </a>,
        " on file.",
      ],
      value: false,
      required: true,
      userEmail: email,
    },
  };

  return {
    agreementRequest,
    error,
    setError,
    shouldDisableSubmit,
    owner: isDefined(owner)
      ? formatName({
          nameObject: owner,
          format: "firstNameDisplayedFirst",
        })
      : "-",
    jointOwner: formatName({
      nameObject: jointOwner,
      format: "firstNameDisplayedFirst",
    }),
    jointAnnuitant: formatName({
      nameObject: jointAnnuitant,
      format: "firstNameDisplayedFirst",
    }),
    productTypeName: isDefined(productTypeName) ? productTypeName : "-",
    policyId: isDefined(policyId) ? policyId : "-",
    marketingName,
    fundValueAmount: formatMoney(fundValueAmount),
    fundTransferFormFields,
    onSubmit,
    showErrorPage,
    transactionId,
    errorText,
    showLoadingIcon,
  };
};

export const useCanContinue = (props) => {
  const { formData } = useFormContext();
  const { nextPage, pageNumber, setError } = props;
  const requestorContactEmail = useRequestorContactEmail();
  const onContinue = () => {
    if (pageNumber === 1) {
      const { hasErrorForPage1 } = checkErrorForPage1({
        formData,
        setError,
        requestorContactEmail,
      });
      if (!hasErrorForPage1) {
        nextPage();
      }
    } else {
      nextPage();
    }
  };
  return { onContinue };
};

export const checkErrorForPage1 = (props) => {
  const { formData, setError, requestorContactEmail } = props;

  const { hasRadioFormError } = checkForRadioFormError({
    formData,
    setError,
  });

  const { hasRequestorContactError } =
    !hasRadioFormError &&
    checkForRequestorContactError({
      formData,
      setError,
      requestorContactEmail,
    });

  const { hasAcknowledgementsError } =
    !hasRadioFormError &&
    !hasRequestorContactError &&
    checkForAcknowledgementsErrors({
      formData,
      setError,
    });

  return {
    hasErrorForPage1:
      hasRadioFormError || hasRequestorContactError || hasAcknowledgementsError,
  };
};

export const checkForRadioFormError = (props) => {
  const { formData, setError } = props;

  const {
    mmlConservativeAllocation,
    mmlModerateAllocation,
    mmlBalancedAllocation,
    mmlAmericanFundsCoreAllocation,
    mmlGrowthAllocation,
    mmlBlend,
    mmlIshares8020Allocation,
    mmlIshares6040Allocation,
  } = formData;

  const isTransferToFundSelected =
    mmlConservativeAllocation.value ||
    mmlModerateAllocation.value ||
    mmlBalancedAllocation.value ||
    mmlAmericanFundsCoreAllocation.value ||
    mmlGrowthAllocation.value ||
    mmlBlend.value ||
    mmlIshares8020Allocation.value ||
    mmlIshares6040Allocation.value;

  let validationError;
  if (!isTransferToFundSelected) {
    validationError = "Please select an MML Asset Allocation Fund.";
  }
  setError(validationError);
  return { hasRadioFormError: isDefined(validationError) };
};

export const checkForAcknowledgementsErrors = (props) => {
  const { formData, setError } = props;
  const { authAcknowledgement, formAcknowledgement } = formData;

  const authAcknowledgementUnchecked = !authAcknowledgement.value;

  const formAcknowledgementUnchecked = !formAcknowledgement.value;

  let validationError;

  if (authAcknowledgementUnchecked) {
    validationError =
      "The owner must be authenticated either verbally or in person prior to submitting this request. Please contact your Agency Supervisory Officer (ASO) with any questions. If you can accept the first acknowledgement statement, select the check box and press continue to proceed.";
  } else if (formAcknowledgementUnchecked) {
    const formLink = (
      <a
        id="form_acknowledgement_link"
        href="https://fieldnet.massmutual.com/public/ann/pdfs/fr2123.pdf"
        rel="noreferrer"
        target="_blank"
        key="form_acknowledgment_link"
      >
        {" "}
        here
      </a>
    );
    validationError = (
      <>
        You must be authorized to perform this fund transfer and have completed
        a Producer Authorization Form (FR2123) on file. If the form has not been
        completed you can find it {formLink}.
      </>
    );
  }

  setError(validationError);
  return { hasAcknowledgementsError: isDefined(validationError) };
};

export const getSelectedTransferToFund = (formData) => {
  const {
    mmlConservativeAllocation,
    mmlModerateAllocation,
    mmlBalancedAllocation,
    mmlAmericanFundsCoreAllocation,
    mmlGrowthAllocation,
    mmlBlend,
    mmlIshares8020Allocation,
    mmlIshares6040Allocation,
  } = formData;
  if (mmlConservativeAllocation?.value) return mmlConservativeAllocation;
  if (mmlModerateAllocation?.value) return mmlModerateAllocation;
  if (mmlBalancedAllocation?.value) return mmlBalancedAllocation;
  if (mmlAmericanFundsCoreAllocation?.value)
    return mmlAmericanFundsCoreAllocation;
  if (mmlGrowthAllocation?.value) return mmlGrowthAllocation;
  if (mmlBlend?.value) return mmlBlend;
  if (mmlIshares8020Allocation?.value) return mmlIshares8020Allocation;
  if (mmlIshares6040Allocation?.value) return mmlIshares6040Allocation;

  return "";
};
export const useFundTransferProps = () => {
  const { formData } = useFormContext();

  return formData;
};
export const useReviewProps = () => {
  const { formData } = useFormContext();

  const financialProfessionalName = formData.requestorNameInput.value;
  const financialProfessionalEmail = formData.contactChoiceEmailInput.value;
  const selectedTransferToFund = getSelectedTransferToFund(formData);
  const transferToFundDisplayName = selectedTransferToFund.label;
  return {
    transferToFundDisplayName,
    financialProfessionalName,
    financialProfessionalEmail,
  };
};

export const useConfirmationProps = () => {
  const { formData } = useFormContext();
  const { agreementKey } = useParams();
  const financialProfessionalEmail = formData.contactChoiceEmailInput.value;
  const currentDateAndTime = formatDate(getCurrentDate(), "dateWithTime");
  const timezone = getCurrentDateTimeWithTimezone().split(" ")[3];
  const currentDateTimeAndTimezone = currentDateAndTime.concat(" ", timezone);
  const selectedTransferToFund = getSelectedTransferToFund(formData);
  const transferToFundDisplayName = selectedTransferToFund.label;
  const financialProfessionalName = formData.requestorNameInput.value;

  return {
    currentDateTimeAndTimezone,
    financialProfessionalEmail,
    agreementKey,
    transferToFundDisplayName,
    financialProfessionalName,
  };
};

export const makeEnvisionFundTransferRequestBody = (props) => {
  const {
    formData,
    user,
    agreementRequest,
    producerRequest,
    forceCompleteEvent,
  } = props;

  const {
    data: {
      agreementKeyAdmin,
      agreementFunds,
      agreementKeyPrefix,
      agreementKeyPrimary,
      agreementCustomers,
    },
  } = agreementRequest;

  const personInsured = getUserBySubType({
    type: userTypes.INSURED,
    subType: "PRMR",
    people: agreementCustomers,
  });

  const {
    firstName: insuredFirstName = "",
    lastName: insuredLastName = "",
    middleName: insuredMiddleName = "",
  } = personInsured || {};

  const { fundNumber: fromFundNumber, marketingName: fromFundName } =
    agreementFunds?.[0] || {};

  const selectedTransferToFund = getSelectedTransferToFund(formData);
  const { fundNumber: toFundNumber, label: toFundName } =
    selectedTransferToFund || {};

  const currentDateTimeTimezoneComponents =
    getCurrentDateTimeWithTimezone().split(" ");

  // Long comment string will be prepared at serverside as it require client ip
  const longComment = {
    submittedDateTime: {
      date: currentDateTimeTimezoneComponents[0],
      time: currentDateTimeTimezoneComponents[1],
      timeZone: currentDateTimeTimezoneComponents[3],
    },
    fromFundNumber,
    fromFundName,
    toFundNumber,
    toFundName,
  };

  const requestHelper = {};
  requestHelper.transactionName = "fundTransfer";

  const receivedDateTime = formatDate(getCurrentDate());

  const { producers } = producerRequest?.data || {};

  const servicingAgent = getFirstServicingAgent({
    agents: producers,
  });
  const { businessPartnerId, homeAgencyId } = servicingAgent;

  const { isAnAgent, idNumber } = getBpIdFromUser(user);
  const lengthOfAgencyId = 3;
  const orgUnit = isAnAgent
    ? getLastXChars(homeAgencyId, lengthOfAgencyId).padStart(
        lengthOfAgencyId,
        0
      )
    : "";

  return {
    divCode: "AN",
    deptCode: "PI",
    workEvent: "3082",
    agreementNumber: agreementKeyPrimary,
    carrierAdminSystem: agreementKeyAdmin,
    agreementNumberPrefix: agreementKeyPrefix,
    longComment,
    priorityCode: "10",
    requestorTypeCode: "A",
    receivedDate: receivedDateTime,
    agentId: `${businessPartnerId}`,
    serviceChannelSourceCode: "19",
    lastUpdatedBy: "SVCNET2",
    loggedBy: "SVCNET2",
    userId: "ANFFAHYD",
    completionInd: forceCompleteEvent ? "Y" : "N",
    insuredFirstName,
    insuredLastName,
    insuredMiddleName,
    requestorInfo: {
      name: `${user.firstName} ${user.lastName}`,
      email: formData.contactChoiceEmailInput.value,
      orgUnit,
      id: idNumber,
    },
    requestHelper,
  };
};
