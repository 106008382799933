import React, { useEffect } from "react";
import { getConfig, types } from "../../config";
import { useIsFixedAnnuityContract } from "../../hooks/helper";
import { redirectToUrl } from "../../utils/dom";
import { isDefined } from "../../utils/evaluate";
import { fireTealiumAndFollowLink } from "../../utils/tealium";
import IsFalsy from "../common/logic/isFalsy";
import IsTruthy from "../common/logic/isTruthy";

const AnnuityFormsCenterLink = (props) => {
  const {
    email,
    policyId,
    id,
    className,
    tealiumCategory,
    renderAsListItem,
    target,
    agreementData,
    setShowAnnuityFormsCenterLink = () => {},
  } = props;

  const isFixedAnnuity = useIsFixedAnnuityContract(agreementData);

  const fieldNetUrl = getConfig(types.fieldNetUrl);

  const redirectUrl = `${fieldNetUrl}/riappexpress/raxfn?GUID=&LAD=A`;

  const onClickHandler = () => {
    if (isDefined(tealiumCategory)) {
      fireTealiumAndFollowLink({
        url: redirectUrl,
        action: "Annuity Forms Center link clicked",
        category: tealiumCategory,
        value: policyId,
        custId: email,
        target,
      });
    } else {
      redirectToUrl(redirectUrl, target);
    }
  };
  useEffect(() => {
    setShowAnnuityFormsCenterLink(isFixedAnnuity);
  }, [isFixedAnnuity]);

  return (
    <IsTruthy value={isFixedAnnuity}>
      <IsTruthy value={renderAsListItem}>
        <li>
          <button
            type="button"
            id={id}
            onClick={onClickHandler}
            className={className}
          >
            Annuity Forms Center
          </button>
        </li>
      </IsTruthy>
      <IsFalsy value={renderAsListItem}>
        <button
          type="button"
          id={id}
          onClick={onClickHandler}
          className={className}
        >
          Annuity Forms Center
        </button>
      </IsFalsy>
    </IsTruthy>
  );
};

export default AnnuityFormsCenterLink;
