import React from "react";
import JsonInputForm from "./jsonInputForm";
import { useFormProps } from "./hooks";

const Form = (props) => {
  const { formFields, useCustomJsonInput } = useFormProps(props);

  return (
    <div className="row">
      {Object.keys(formFields).map((key) => (
        <div className="col-12">
          <JsonInputForm
            useHook={useCustomJsonInput}
            formField={formFields[key]}
          />
        </div>
      ))}
    </div>
  );
};

export default Form;
