import React from "react";
import Cell from "./cell";

const Row = (props) => {
  const { row, columns, customFunctions, rowIndex } = props;
  const generatedCells = columns.map((column, index) => (
    <Cell
      key={`cell-${column.accessor}`}
      row={row}
      rowIndex={rowIndex}
      column={column}
      cellIndex={index}
      customFunctions={customFunctions}
    />
  ));
  return <tr>{generatedCells}</tr>;
};

export default React.memo(Row);
