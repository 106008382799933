export const processCdaValues = (props) => {
  const apiData = props?.response?.data || {};
  const cdaValues = apiData?.cdaValues || {};

  const {
    surrenderValue = "",
    surrenderCharge = "",
    currentDeathBenefit = "",
    surrenderFreeAmount = "",
    funds = {},
  } = cdaValues;

  return {
    surrenderValue,
    surrenderCharge,
    currentDeathBenefit,
    surrenderFreeAmount,
    funds,
  };
};
