import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Download from "../../../common/table/customCells/download";
import { useMakeRequest, types } from "../../../../hooks/api";
import {
  formatDocumentData,
  formatPhoneNumberString,
} from "../../../../utils/format";
import {
  downloadPDF,
  convertBase64StringToByteArray,
} from "../../../common/helpers/downloadHelper";
import {
  isAnnuityContract,
  isCoverpathPolicy,
  isEnvisionPolicy,
} from "../../../../utils/policies";
import { isDefined } from "../../../../utils/evaluate";
import { fireTealiumEvent } from "../../../../utils/tealium";
import { useUserState } from "../../../../contexts/user";
import { compareStrings } from "../../../../utils/string";
import { DATA_UNAVAILABLE } from "../../../../constants/ui";
import { customerSelectedDeliveryPreferences } from "../../../../utils/translate";

// eslint-disable-next-line import/prefer-default-export
export const useDocumentsProps = (props) => {
  const {
    policyId,
    agreementRequest: {
      data: {
        contactUsTime,
        contactUsNumber,
        agreementKeyAdmin,
        lineOfBusinessCode,
      },
    },
    agreementRequest,
    statementPreferencesRequest,
    isDeliveryPreferenceEligibleAnnuity,
  } = props;

  const agreementData = agreementRequest.data;
  const { agreementKey } = useParams();

  const documentRequest = useMakeRequest({
    apiRequest: "fetchImagelistFromContent",
    apiParams: { agreementKey },
    dataSelector: "processContentData",
    immediateRequest: true,
    initiateDevModeDispatch: false,
  });
  let contactUsNo;
  let contactDateTime;
  const columns = [
    {
      accessor: "documentType",
      label: "Type",
      alignment: "left",
      CustomCell: Download,
      showIcon: true,
      isCalledFrom: "Policy Details",
    },
    {
      accessor: "formattedEffectiveDate",
      label: "Created Date",
      alignment: "left",
    },
  ];
  const rows = formatDocumentData(
    agreementData,
    documentRequest.data,
    lineOfBusinessCode
  ).slice(0, 3);

  const [docGuid, setGuid] = useState("");
  const [docObjStore, setObjectStore] = useState("");
  const [document, setDocument] = useState({});

  const { data, executeRequest, dispatch, error } = useMakeRequest({
    apiRequest: "fetchDocumentFromContent",
    apiParams: { agreementKey, docObjStore, docGuid },
    immediateRequest: false,
    initiateDevModeDispatch: false,
  });

  const { user } = useUserState();

  useEffect(() => {
    if (data?.length && !error) {
      downloadPDF(document, policyId, convertBase64StringToByteArray(data));
    }
  }, [document, policyId, data, dispatch, error]);

  const generateAndDownloadPDF = (row) => {
    dispatch({
      type: types.RESET,
      newState: {
        data: "",
        isLoading: false,
        error: null,
        shouldRequest: false,
        paramsForSelectors: {},
      },
    });
    const { documentType } = row;
    fireTealiumEvent({
      label: "SVNT",
      action: `${lineOfBusinessCode}: Document downloaded ${documentType}`,
      category: "Download Documents link clicked",
      value: policyId,
      custId: user?.email,
    });
    const { guid, objectStore } = row;
    setDocument(row);
    setGuid(guid);
    setObjectStore(objectStore);
    executeRequest();
  };

  const customFunctions = { generateAndDownloadPDF };

  const isHap = isCoverpathPolicy(agreementKeyAdmin);
  const isAnnuityProduct = isAnnuityContract(lineOfBusinessCode);
  const showExtendedPageLink = documentRequest.data.length > 3;

  const IS_ENVISION_POLICY = isEnvisionPolicy(agreementRequest.data);
  if (IS_ENVISION_POLICY) {
    contactUsNo = isDefined(contactUsNumber)
      ? contactUsNumber
      : "1-800-272-2216";
    contactDateTime = isDefined(contactUsTime)
      ? contactUsTime
      : "Mon - Fri 8 a.m. - 8 p.m. ET";
  } else {
    contactDateTime = contactUsTime;
    contactUsNo = contactUsNumber;
  }

  const statementPreferencesData = statementPreferencesRequest?.data;

  const { deliveryPreferencesUnsupported, deliveryPreferenceDisplay } =
    evaluateDeliveryPreferencesData(statementPreferencesData);

  const shouldDisplayDeliveryPreference =
    isDeliveryPreferenceEligibleAnnuity && !deliveryPreferencesUnsupported;

  return {
    requests: { documentRequest, statementPreferencesRequest },
    table: {
      rows,
      columns,
    },
    customFunctions,
    isHap,
    isAnnuityProduct,
    showExtendedPageLink,
    contactUsNumber: formatPhoneNumberString(contactUsNo),
    contactUsTime: contactDateTime,
    IS_ENVISION_POLICY,
    shouldDisplayDeliveryPreference,
    deliveryPreferenceDisplay,
  };
};

export const evaluateDeliveryPreferencesData = (statementPreferencesData) => {
  let deliveryPreferenceDisplay = DATA_UNAVAILABLE;
  if (
    !isDefined(statementPreferencesData) ||
    statementPreferencesData.customers?.length === 0
  ) {
    return {
      deliveryPreferencesUnsupported: false,
      deliveryPreferenceDisplay,
    };
  }

  const { customers } = statementPreferencesData || [];
  const { contracts } = customers[0] || [];
  const { preferences } = contracts[0] || [];

  const statementDeliveryPreference =
    preferences?.find(
      (preference) =>
        compareStrings(preference.typeCode, "DELIVERY") &&
        compareStrings(preference.subTypeCode, "STMNT")
    ) || {};

  const { valueCode, reasonCode } = statementDeliveryPreference;

  const deliveryPreferencesUnsupported = compareStrings(
    reasonCode?.value,
    "UNSUPPORTED"
  );

  if (isDefined(valueCode) && !deliveryPreferencesUnsupported) {
    deliveryPreferenceDisplay =
      customerSelectedDeliveryPreferences[valueCode.toUpperCase()];
  }

  return {
    deliveryPreferencesUnsupported,
    deliveryPreferenceDisplay: deliveryPreferenceDisplay || DATA_UNAVAILABLE,
  };
};
