import React from "react";
import Table from "../../../common/table";
import { usePeopleOnPolicyProps } from "./hooks";
import { BeneficiariesDisplay } from "./beneficiariesDisplay";
import IsTruthy from "../../../common/logic/isTruthy";
import IsFalsy from "../../../common/logic/isFalsy";
import LoadingSection, {
  errorTypes,
  loadingTypes,
} from "../../../common/loading";

const PeopleOnPolicy = (props) => {
  const { id, policyId, agreementRequest, producerData } = props;
  const {
    data: {
      peopleList,
      peopleOnPolicyColumns,
      agreementKey,
      columnsBeneficiary,
      beneficiariesSortByBeneficiaryClass,
      complexBeneficiaries,
      showFootnote,
      isPolicyHap,
      hapBeneficiariesFormatted,
      IS_DISABILITY_POLICY,
      isPallm,
      agentTable: { columns, rows },
    },
    functions: { showAgentModal, showCustomerModal, showBeneficiaryModal },
    requests: { beneficiaryRequest },
  } = usePeopleOnPolicyProps(props);
  const agreementData = agreementRequest.data;

  return (
    <div className="mm-spacing">
      <h2 aria-label="People on Policy" id={id}>
        People on Policy
      </h2>
      <div className="mm-spacing--minor">
        <Table
          id="people_on_policy"
          columns={peopleOnPolicyColumns}
          rows={peopleList}
          showPagination={false}
          customFunctions={{ showCustomerModal }}
        />
      </div>

      <div className="mm-spacing--minor">
        <IsTruthy value={isPolicyHap}>
          <LoadingSection
            request={agreementRequest}
            loadingType={loadingTypes.PARAGRAPH}
            errorType={errorTypes.SECTION}
          >
            <BeneficiariesDisplay
              showFootnote={showFootnote}
              agreementKey={agreementKey}
              agreementData={agreementData}
              policyId={policyId}
              columnsBeneficiary={columnsBeneficiary}
              beneficiariesSortByBeneficiaryClass={
                beneficiariesSortByBeneficiaryClass
              }
              complexBeneficiaries={complexBeneficiaries}
              showBeneficiaryModal={{ showBeneficiaryModal }}
              isConvertedHapPolicy={isPolicyHap}
              hapBeneficiary={hapBeneficiariesFormatted}
              displayNoBeneInfoAlert={false}
            />
          </LoadingSection>
        </IsTruthy>
        <IsFalsy value={isPolicyHap}>
          <IsFalsy value={IS_DISABILITY_POLICY}>
            <LoadingSection
              request={beneficiaryRequest}
              loadingType={loadingTypes.PARAGRAPH}
              errorType={errorTypes.SECTION}
            >
              <BeneficiariesDisplay
                showFootnote={showFootnote}
                agreementKey={agreementKey}
                agreementData={agreementData}
                policyId={policyId}
                columnsBeneficiary={columnsBeneficiary}
                beneficiariesSortByBeneficiaryClass={
                  beneficiariesSortByBeneficiaryClass
                }
                complexBeneficiaries={complexBeneficiaries}
                showBeneficiaryModal={{ showBeneficiaryModal }}
                isConvertedHapPolicy={isPolicyHap}
                hapBeneficiary={hapBeneficiariesFormatted}
                displayNoBeneInfoAlert={false}
                isPallm={isPallm}
              />
            </LoadingSection>
          </IsFalsy>
        </IsFalsy>
      </div>
      <div className="mm-spacing--minor">
        <h3 id="people_on_policy_agents_lbl">Financial Professionals/Agents</h3>
        <LoadingSection
          request={producerData}
          loadingType={loadingTypes.PARAGRAPH}
          errorType={errorTypes.SECTION}
        >
          <div className="row">
            <div className="col-sm">
              <Table
                id="agentsOnAgreement"
                columns={columns}
                rows={rows}
                customFunctions={{ showAgentModal }}
                showPagination={false}
                noResultsText="No information available."
              />
            </div>
          </div>
        </LoadingSection>
      </div>
    </div>
  );
};

export default React.memo(PeopleOnPolicy);
