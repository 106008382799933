export const isTruthy = (value) => {
  const truthyValues = [1, "1", "Y", true, "true"];
  return truthyValues.includes(value);
};

export const isFalsy = (value) => {
  const falseyValues = [0, "0", "N", false, "false"];
  return falseyValues.includes(value);
};

export const isDefined = (value) => {
  const IS_NULL = isNull(value);
  const IS_UNDEFINED = isUndefined(value);
  const IS_EMPTY_STRING = typeof value === "string" ? value === "" : false;
  const IS_DEFAULT_NUMBER = typeof value === "number" ? value === 0 : false;

  if (IS_NULL || IS_UNDEFINED || IS_EMPTY_STRING || IS_DEFAULT_NUMBER) {
    return false;
  }

  return true;
};

export const allAreDefined = (values) => {
  if (isNotArray(values)) return false;
  return values.reduce((isTrue, value) => {
    if (isFalsy(isTrue)) {
      return isTrue;
    }
    return isDefined(value);
  }, true);
};

export const allAreNotDefined = (values) => !allAreDefined(values);

export const isNested = (value) => isObject(value) || isArray(value);

export const isObject = (value) =>
  isDefined(value) && typeof value === "object";

export const isEmptyObject = (value) =>
  isObject(value) && Object.keys(value).length === 0;

export const isArray = (value) => Array.isArray(value);

export const isEmptyArray = (value) => value.length === 0;

export const isDate = (value) =>
  // If the date object is invalid it
  // will return 'NaN' on getTime()
  // and NaN is never equal to itself.
  // eslint-disable-next-line no-self-compare
  value.getTime() === value.getTime();

export const isNotArray = (value) => !isArray(value);

export const isUndefined = (value) => value === undefined;

export const isNull = (value) => value === null;

export const isNullOrUndefined = (value) => isNull(value) || isUndefined(value);

export const isNaN = (value) => Number.isNaN(Number(value));

export const isANumber = (value) => !isNaN(value);

export const isValidDPTxnId = (transactionId) =>
  isDefined(transactionId) && transactionId !== -1;
