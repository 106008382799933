import React from "react";
import { useBeneficiaryInformationProps } from "../hooks";

import { InitiateDeathClaimDetails } from "./intiateDeathClaimDetails";
import Table from "../../common/table";
import LoadingSection, { errorTypes, loadingTypes } from "../../common/loading";
import BeneficiaryInformationQuestionAnswers from "./beneficiaryInformation/beneficiaryInformationQuestionAnswers";

const BeneficiaryInformation = (props) => {
  const {
    beneficiariesByAgreementKeysRequest,
    beneficiaryInformationTableColumns,
    beneficiaryInformationTableRows,
  } = useBeneficiaryInformationProps(props);

  return (
    <div>
      <h1 className="h2" id="beneficiary_information_header">
        In this step, you will be asked to provide information about the
        beneficiary.{" "}
      </h1>
      <div>
        <div className="row">
          <InitiateDeathClaimDetails />
        </div>
      </div>
      <br />
      <LoadingSection
        request={beneficiariesByAgreementKeysRequest}
        loadingType={loadingTypes.PARAGRAPH}
        errorType={errorTypes.CHILDREN}
      >
        <div className="mm-spacing--minor">
          <h3 id="beneficiary_information_table_header">
            Beneficiary Information
          </h3>
          <Table
            id="beneficiary_information"
            columns={beneficiaryInformationTableColumns}
            rows={beneficiaryInformationTableRows}
            showPagination={false}
          />
        </div>
      </LoadingSection>
      <BeneficiaryInformationQuestionAnswers {...props} />
    </div>
  );
};

export default BeneficiaryInformation;
