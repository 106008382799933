import React from "react";
import CheckboxForm from "../common/forms/checkboxForm";
import { useCustomerCheckboxProps, useSelectUserProps } from "./hooks";
import SelectUsersCheckbox from "./selectUsersCheckbox";

const User = (props) => {
  const { user, agreementKeyAdmin } = props;

  return (
    <div>
      <SelectUsersCheckbox
        formField={user}
        agreementKeyAdmin={agreementKeyAdmin}
        useHook={useCustomerCheckboxProps}
      />
    </div>
  );
};
const SelectUsers = (props) => {
  const { users, authorizedToChange, notmyAddress, agreementKeyAdmin } =
    useSelectUserProps(props);
  return (
    <>
      <h1 id="select_customers_header_lbl">Select Customer(s)</h1>
      <p className="mm-sub-copy" id="select_customers_required_note_lbl">
        * = Required Field
      </p>
      <div>
        <p className="h3" id="select_customers_applicable_note_lbl">
          Change of address will be applied to all policies/contracts listed
          under the individual(s) selected below.*
        </p>
        <p className="mm-sub-copy" id="insured_eligible_note_lbl">
          Note: For any policies that are not shown below please contact the
          Service Center at 1-800-272-2216, 8:00 AM - 8:00 PM ET, Monday through
          Friday.
        </p>
      </div>
      <div className="mm-spacing">
        {users.map((user) => {
          if (user) {
            return (
              <User
                key={user.id}
                user={user}
                agreementKeyAdmin={agreementKeyAdmin}
              />
            );
          }
          return "";
        })}
      </div>
      <div className="mm-spacing">
        <p className="h3" id="acknowledgement_lbl">
          Acknowledgement
        </p>
        <CheckboxForm formField={authorizedToChange} />
        <CheckboxForm formField={notmyAddress} />
      </div>
    </>
  );
};

export default SelectUsers;
