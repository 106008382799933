import React from "react";
import IsTruthy from "../../../common/logic/isTruthy";

const RiderModal = (props) => {
  const {
    effectiveDate,
    termDate,
    eligibilityDate,
    isDisplayBenefitAmt,
    isDisplayRiderVersion,
    isDisplayEligibilityDateAndExpirationDate,
    benefitAmtLabel,
    benefitAmt,
    riderVersion,
  } = props;

  return (
    <div className="modal-body">
      <dl className="mm-key-value-pair">
        <dt id="rider_status_lbl">Status</dt>
        <dd id="rider_status_value">Active</dd>
        <dt id="rider_effectivedate_lbl">Effective Date</dt>
        <dd id="rider_effectivedate_value">{effectiveDate}</dd>
        <IsTruthy value={isDisplayRiderVersion}>
          <dt id="rider_Version_lbl">Rider Version </dt>
          <dd id="rider_Version_value">{riderVersion}</dd>
        </IsTruthy>
        <IsTruthy value={isDisplayEligibilityDateAndExpirationDate}>
          <dt id="rider_expirationdate_lbl">Expiration Date</dt>
          <dd id="rider_expirationdate_value">{termDate}</dd>
          <dt id="rider_eligibilitydate_lbl">Eligibility Date</dt>
          <dd id="rider_eligibilitydate_value">{eligibilityDate}</dd>
        </IsTruthy>
        <IsTruthy value={isDisplayBenefitAmt}>
          <dt id="rider_benefitamt_lbl">{benefitAmtLabel}</dt>
          <dd id="rider_benefitamt_value">{benefitAmt}</dd>
        </IsTruthy>
      </dl>
    </div>
  );
};

export default RiderModal;
