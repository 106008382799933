import React from "react";
import { useReviewProps } from "../hooks";
import IsTruthy from "../../common/logic/isTruthy";
import IsFalsy from "../../common/logic/isFalsy";

const Review = (props) => {
  const { setPageNumber } = props;
  const {
    formattedLoanAmount,
    formattedInterestRate,
    interestRateType,
    formattedOwnerName,
    formattedInsuredName,
    attnLine,
    addrFirstLine,
    addrSecondLine,
    addrThirdLine,
    addrFourthLine,
    mailingOption,
    upsSelected,
    upsAccountNumber,
    upsAssociatedZip,
    contactChoiceEmailInput,
    loggedInUser,
    policyId,
    productTypeName,
    enterPolicyNewAddress,
    individuallyOwnedText,
    achText,
    bankruptcyText,
    amountExceeds200K,
    divorceDecreeText,
    authorizedByOwnerText,
    hideMailingAdress,
  } = useReviewProps(props);

  return (
    <div>
      <h1 className="mm-spacing">Review</h1>
      <div className="row">
        <div className="col-md-8">
          <p className="h3">
            Questions & Disclosures
            <button
              type="button"
              className="btn btn-inline"
              onClick={() => setPageNumber(1)}
              aria-label="edit_questions_disclosures_button"
              id="initiatenewloan_review_edit_questions_disclosures_button"
            >
              Edit
            </button>
          </p>
          <div className="mm-spacing--minor">
            <dl className="mm-key-value-pair">
              <dt id="initiatenewloan_review_question_one_lbl">
                What&apos;s the ownership arrangement of this policy?
              </dt>
              <dd id="initiatenewloan_review_answer_one_value">
                {individuallyOwnedText}
              </dd>
              <dt id="initiatenewloan_review_question_two_lbl">
                Would the owner like to receive the loan disbursement through
                direct deposit?
              </dt>
              <dd id="initiatenewloan_review_answer_two_value">{achText}</dd>
              <dt id="initiatenewloan_review_question_three_lbl">
                Is this policy assigned, pledged or subject to any bankruptcy
                proceeding, attachment, lien or other claim?
              </dt>
              <dd id="initiatenewloan_review_answer_three_value">
                {bankruptcyText}
              </dd>
              <dt id="initiatenewloan_review_question_four_lbl">
                Is this policy subject to a divorce decree?
              </dt>
              <dd id="initiatenewloan_review_answer_four_value">
                {divorceDecreeText}
              </dd>
              <dt id="initiatenewloan_review_question_five_lbl">
                Is the loan amount requested equal to or greater than $200,000?
              </dt>
              <dd id="initiatenewloan_review_answer_five_value">
                {amountExceeds200K}
              </dd>
              <dt id="initiatenewloan_review_question_six_lbl">
                Has the policy owner authorized you to make this request?
              </dt>
              <dd id="initiatenewloan_review_answer_six_value">
                {authorizedByOwnerText}
              </dd>
            </dl>
          </div>

          <hr />

          <p className="h3">
            Loan Details
            <button
              type="button"
              className="btn btn-inline"
              onClick={() => setPageNumber(2)}
              aria-label="edit_loan_details_button"
              id="initiatenewloan_edit_loan_details_button"
            >
              Edit
            </button>
          </p>
          <div className=" mm-spacing--minor">
            <dl className="mm-key-value-pair">
              <dt id="initiatenewloan_loan_amount_requested_lbl">
                Loan Amount Requested
              </dt>
              <dd id="initiatenewloan_loan_amount_requested_value">
                {formattedLoanAmount}
              </dd>
              <dt id="initiatenewloan_current_interest_rate_lbl">
                Current Interest Rate
              </dt>
              <dd id="initiatenewloan_current_interest_rate_value">
                {formattedInterestRate}
              </dd>
              <dt id="initiatenewloan_interest_rate_type_lbl">
                Interest Rate Type
              </dt>
              <dd id="initiatenewloan_interest_rate_type_value">
                {interestRateType}
              </dd>
            </dl>
          </div>

          <hr />
          <IsFalsy value={hideMailingAdress}>
            <p className="h3">
              Mailing Address
              <button
                type="button"
                className="btn btn-inline"
                onClick={() => setPageNumber(3)}
                aria-label="edit_mailing_address_button"
                id="initiatenewloan_edit_mailing_address_button"
              >
                Edit
              </button>
            </p>
            <div className="mm-spacing--minor">
              <dl className="mm-key-value-pair">
                <IsFalsy value={enterPolicyNewAddress.value}>
                  <dt id="initiatenewloan_address_on_record_lbl">
                    Owner&apos;s Address on Record
                  </dt>
                  <dd id="initiatenewloan_address_on_record_value">
                    <IsTruthy value={attnLine !== ""}>
                      {attnLine}
                      <br />
                    </IsTruthy>
                    {addrFirstLine}
                    <IsTruthy value={addrSecondLine !== ""}>
                      <br />
                      {addrSecondLine}
                    </IsTruthy>
                    <IsTruthy value={addrThirdLine !== ""}>
                      <br />
                      {addrThirdLine}
                    </IsTruthy>
                    <IsTruthy value={addrFourthLine !== ""}>
                      <br />
                      {addrFourthLine}
                    </IsTruthy>
                  </dd>
                </IsFalsy>
                <IsTruthy value={enterPolicyNewAddress.value}>
                  <dt id="initiatenewloan_user_entered_address_lbl">
                    User Entered Address
                  </dt>
                  <dd id="initiatenewloan_user_entered_address_value">
                    {addrFirstLine}
                    <IsTruthy value={addrSecondLine !== ""}>
                      <br />
                      {addrSecondLine}
                    </IsTruthy>
                    <IsTruthy value={addrThirdLine !== ""}>
                      <br />
                      {addrThirdLine}
                    </IsTruthy>
                    <IsTruthy value={addrFourthLine !== ""}>
                      <br />
                      {addrFourthLine}
                    </IsTruthy>
                  </dd>
                </IsTruthy>
                <dt id="initiatenewloan_recipient_name_lbl">Recipient Name</dt>
                <dd id="initiatenewloan_recipient_name_value">
                  {formattedOwnerName}
                </dd>
                <dt id="initiatenewloan_mailing_option_lbl">Mailing Option</dt>
                <dd id="initiatenewloan_mailing_option_value">
                  {mailingOption}
                </dd>
                <IsTruthy value={upsSelected}>
                  <dt id="initiatenewloan_ups_account_number_lbl">
                    UPS Account Number
                  </dt>
                  <dd id="initiatenewloan_ups_account_number_value">
                    {upsAccountNumber}
                  </dd>
                  <dt id="initiatenewloan_associated_zip_postal_code_lbl">
                    Associated ZIP/Postal Code
                  </dt>
                  <dd id="initiatenewloan_associated_zip_postal_code_value">
                    {upsAssociatedZip}
                  </dd>
                </IsTruthy>
              </dl>
            </div>
            <hr />
          </IsFalsy>

          <p className="h3">Requestor Contact</p>
          <div className="mm-spacing--minor">
            <dl className="mm-key-value-pair">
              <dt id="initiatenewloan_financial_professional_lbl">
                Financial Professional
              </dt>
              <dd id="initiatenewloan_financial_professional_value">
                {loggedInUser}
              </dd>
              <dt id="initiatenewloan_financial_professional_email_lbl">
                Financial Professional Email
              </dt>
              <dd id="initiatenewloan_financial_professional_email_value">
                {contactChoiceEmailInput}
              </dd>
            </dl>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card--flat">
            <div className="card-body">
              <dl className="mm-key-value-pair">
                <dt id="initiatenewloan_review_owner_name_lbl">Owner Name</dt>
                <dd id="initiatenewloan_review_owner_name_value">
                  {formattedOwnerName}
                </dd>
                <dt id="initiatenewloan_insured_name_lbl">Insured Name</dt>
                <dd id="initiatenewloan_insured_name_value">
                  {formattedInsuredName}
                </dd>
                <dt id="initiatenewloan_policy_number_lbl">Policy Number</dt>
                <dd id="initiatenewloan_policy_number_value">{policyId}</dd>
                <dt id="initiatenewloan_product_name_lbl">Product Name</dt>
                <dd id="initiatenewloan_product_name_value">
                  {productTypeName}
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Review);
