import { extractNumberFromString, formatMoney } from "../../../../utils/format";
import {
  compareArrayOfStrings,
  compareStrings,
  stringIsEmptyOrNullish,
} from "../../../../utils/string";
import { DATA_UNAVAILABLE } from "../../../../constants/ui";
import {
  isCoverpathPolicy,
  isDisabilityPolicy,
  isNTLPolicy,
  isPallmPolicy,
} from "../../../../utils/policies";
import { isDefined } from "../../../../utils/evaluate";
import { checkStatusAndReason } from "../../types/life/hooks";

export const useDividendsProps = (props) => {
  const { id, agreementKey, agreementRequest, policyId, billingRequest } =
    props;
  const agreementData = agreementRequest.data;

  const extendedRiders = billingRequest?.data?.extendedRiders;

  const {
    currentDividendTypeDescription,
    currentDividendAmount,
    currentAdditionsPurchasedAmount,
    paidUpAddsAvailableAmount,
    paidUpAdditionsFaceAmount,
    dividendAccumAvailableAmount,
    lisrDividendCashValueAmount,
    lisrDivOptPaidUpAddsAmount,
    agreementCoverages,
    agreementKeyAdmin,
    lineOfBusinessCode,
    majorProductCode,
    currentDividendYear,
  } = agreementData;
  const showCurrentDividendYear =
    compareStrings(lineOfBusinessCode, "LIFE") &&
    compareStrings(majorProductCode, "PERM") &&
    compareArrayOfStrings(["OPM", "MPR", "VNTG1", "HAP"], agreementKeyAdmin);
  const dividends = [
    {
      paidUpAddsAvailableAmount: stringIsEmptyOrNullish(
        paidUpAddsAvailableAmount
      )
        ? DATA_UNAVAILABLE
        : formatMoney(paidUpAddsAvailableAmount),
      paidUpAdditionsFaceAmount: stringIsEmptyOrNullish(
        paidUpAdditionsFaceAmount
      )
        ? DATA_UNAVAILABLE
        : formatMoney(paidUpAdditionsFaceAmount),
      dividendAccumAvailableAmount: stringIsEmptyOrNullish(
        dividendAccumAvailableAmount
      )
        ? DATA_UNAVAILABLE
        : formatMoney(dividendAccumAvailableAmount),
    },
  ];
  const cashValuePaidUpAddsTotal = showRequestInformation({
    paidUpAddsAvailableAmount,
    isLoanPayoffCheckRequired: true,
    agreementData,
  })
    ? "Unavailable"
    : dividends[0]?.paidUpAddsAvailableAmount;
  const paidUpAddsFaceAmtTotal = showRequestInformation({
    paidUpAdditionsFaceAmount,
    isLoanPayoffCheckRequired: true,
    agreementData,
  })
    ? "Unavailable"
    : dividends[0]?.paidUpAdditionsFaceAmount;
  const dividendAccumAvailable = dividends[0].dividendAccumAvailableAmount;

  let displayDividentTotalBaseCoverage = true;
  if (
    isDisabilityPolicy(agreementData) &&
    !compareStrings("MPR", agreementData.agreementKeyAdmin)
  ) {
    displayDividentTotalBaseCoverage = false;
  }

  const findCoveragesObject = {
    agreementCoverages,
    agreementKeyAdmin,
    lineOfBusinessCode,
    majorProductCode,
    extendedRiders,
    lisrDividendCashValueAmount,
    lisrDivOptPaidUpAddsAmount,
  };

  const alirVntg1PolicyCoverages = findCoverages({
    coverageType: "alirVntg1",
    ...findCoveragesObject,
  });
  const siprOpmPolicyCoverages = findCoverages({
    coverageType: "siprOpm",
    ...findCoveragesObject,
  });
  const alirOpmPolicyCoverages = findCoverages({
    coverageType: "alirOpm",
    ...findCoveragesObject,
  });
  const lisrOpmPolicyCoverages = findCoverages({
    coverageType: "lisrOpm",
    ...findCoveragesObject,
  });
  const lisrVntg1PolicyCoverages = findCoverages({
    coverageType: "lisrVntg1",
    ...findCoveragesObject,
  });
  const alirHapPermPolicyCoverages = findCoverages({
    coverageType: "alirHapPerm",
    ...findCoveragesObject,
  });
  const lisrHapPolicyCoverages = findCoverages({
    coverageType: "lisrHap",
    ...findCoveragesObject,
  });

  const HAS_ADDITIONAL_RIDER_DIVIDENDS =
    alirVntg1PolicyCoverages.length > 0 ||
    siprOpmPolicyCoverages.length > 0 ||
    alirVntg1PolicyCoverages.length > 0 ||
    alirOpmPolicyCoverages.length > 0 ||
    lisrOpmPolicyCoverages.length > 0 ||
    alirHapPermPolicyCoverages.length > 0 ||
    lisrVntg1PolicyCoverages.showLisrVNTGSection ||
    lisrHapPolicyCoverages.length > 0;

  const isPallmNTLPolicy = isPallmPolicy(agreementData) && isNTLPolicy(props);

  let currentDividendDisplayName = "Cash Value of Paid Up Additions";
  let hidePaidUpAdditionsFaceAmount = false;
  if (
    compareStrings("Dividend Accumulations", currentDividendTypeDescription)
  ) {
    currentDividendDisplayName = "Annual Dividend";
    hidePaidUpAdditionsFaceAmount = true;
  }

  const isHAPPolicy = isCoverpathPolicy(agreementKeyAdmin);
  const isValidStatusReason = checkStatusAndReason(agreementData);

  return {
    agreementKey,
    id,
    currentDividendTypeDescription: stringIsEmptyOrNullish(
      currentDividendTypeDescription
    )
      ? DATA_UNAVAILABLE
      : currentDividendTypeDescription,
    currentDividendAmount: formatMoney(currentDividendAmount, DATA_UNAVAILABLE),
    currentAdditionsPurchasedAmount: formatMoney(
      currentAdditionsPurchasedAmount,
      DATA_UNAVAILABLE
    ),
    currentDividendDisplayName,
    hidePaidUpAdditionsFaceAmount,
    dividends,
    displayDividentTotalBaseCoverage,
    alirVntg1PolicyCoverages,
    siprOpmPolicyCoverages,
    alirOpmPolicyCoverages,
    lisrOpmPolicyCoverages,
    lisrVntg1PolicyCoverages,
    alirHapPermPolicyCoverages,
    lisrHapPolicyCoverages,
    agreementData,
    currentDividendYear: isDefined(currentDividendYear)
      ? currentDividendYear
      : "Unavailable",
    showCurrentDividendYear,
    showAdditionalRiderDividends: HAS_ADDITIONAL_RIDER_DIVIDENDS,
    isPallmNTLPolicy,
    policyId,
    cashValuePaidUpAddsTotal,
    paidUpAddsFaceAmtTotal,
    dividendAccumAvailable,
    isHAPPolicy,
    isValidStatusReason,
  };
};

export const findCoverages = (props) => {
  const {
    coverageType = "",
    agreementCoverages = [],
    agreementKeyAdmin,
    lineOfBusinessCode,
    majorProductCode,
    lisrDividendCashValueAmount,
    lisrDivOptPaidUpAddsAmount,
    extendedRiders = [],
  } = props;
  switch (coverageType) {
    case "alirVntg1": {
      const validAdditionalDividendsCriteria =
        compareStrings(agreementKeyAdmin, "VNTG1") &&
        compareStrings(lineOfBusinessCode, "LIFE") &&
        compareStrings(majorProductCode, "PERM");

      if (!validAdditionalDividendsCriteria) {
        return [];
      }

      return agreementCoverages.filter((coverage) => {
        const { type, category } = coverage;
        const types = ["ALIR", "ALIRS", "PALIR"];

        return (
          compareStrings(category, "R") && compareArrayOfStrings(types, type)
        );
      });
    }
    case "siprOpm": {
      const validAdditionalDividendsCriteria =
        compareStrings(agreementKeyAdmin, "OPM") &&
        compareStrings(lineOfBusinessCode, "LIFE") &&
        compareStrings(majorProductCode, "PERM");

      if (!validAdditionalDividendsCriteria) {
        return [];
      }

      const siprCoverages = agreementCoverages.filter((coverage) => {
        const { type, category } = coverage;
        const types = ["SIPR"];

        return (
          compareStrings(category, "R") && compareArrayOfStrings(types, type)
        );
      });

      if (siprCoverages.length > 0) {
        if (extendedRiders.length === 0) return [{}];
        return extendedRiders?.filter((rider) =>
          compareStrings(rider.name, "sipr")
        );
      }
      return [];
    }
    case "alirOpm": {
      const validAdditionalDividendsCriteria =
        compareStrings(agreementKeyAdmin, "OPM") &&
        compareStrings(lineOfBusinessCode, "LIFE") &&
        compareStrings(majorProductCode, "PERM");

      if (!validAdditionalDividendsCriteria) {
        return [];
      }

      const filterAlir = agreementCoverages.filter((coverage) => {
        const { type, category } = coverage;
        const desiredType = "ALIR";

        return (
          compareStrings(category, "R") && compareStrings(desiredType, type)
        );
      });

      const dividendTypeDescription = filterAlir?.[0]?.dividendTypeDescription;
      if (filterAlir.length > 0) {
        const filteredExtendedRiders = extendedRiders?.filter((rider) =>
          compareStrings("alir", rider.name)
        );
        if (filteredExtendedRiders.length > 0) {
          const combined = filteredExtendedRiders?.map((element) => ({
            ...element,
            dividendTypeDescription,
          }));

          return combined;
        }
        return [
          {
            dividendTypeDescription,
          },
        ];
      }
      return [];
    }
    case "lisrOpm": {
      const validAdditionalDividendsCriteria =
        compareStrings(agreementKeyAdmin, "OPM") &&
        compareStrings(lineOfBusinessCode, "LIFE") &&
        compareStrings(majorProductCode, "PERM");

      if (!validAdditionalDividendsCriteria) {
        return [];
      }

      const lisrCoverages = agreementCoverages.filter((coverage) => {
        const { type, category } = coverage;
        const types = ["LISR"];

        return (
          compareStrings(category, "R") && compareArrayOfStrings(types, type)
        );
      });

      if (lisrCoverages.length > 0) {
        if (extendedRiders.length === 0) return [{}];
        return extendedRiders?.filter((rider) =>
          compareStrings(rider.name, "lisr")
        );
      }

      return [];
    }
    case "lisrVntg1": {
      let showLisrVNTGSection = false;
      const validAdditionalDividendsCriteria =
        compareStrings(agreementKeyAdmin, "VNTG1") &&
        compareStrings(lineOfBusinessCode, "LIFE") &&
        compareStrings(majorProductCode, "PERM");

      const lisrCoverages = agreementCoverages.filter((coverage) => {
        const { type, category } = coverage;
        const types = ["LISR"];

        return (
          compareStrings(category, "R") && compareArrayOfStrings(types, type)
        );
      });

      if (validAdditionalDividendsCriteria && lisrCoverages.length > 0) {
        showLisrVNTGSection = true;
      }

      let riderSumInsured;
      const {
        faceAmount: riderSumInsuredAmount,
        type: lisrCoverageType,
        status: lisrStatus,
      } = lisrCoverages?.[0] || "";
      if (
        compareStrings(agreementKeyAdmin, "VNTG1") &&
        compareStrings(lisrCoverageType, "LISR") &&
        compareStrings(lisrStatus, "Term")
      ) {
        riderSumInsured = "Unavailable";
      } else {
        riderSumInsured = riderSumInsuredAmount;
      }

      const ytpCoverages = agreementCoverages.filter((coverage) => {
        const { shortName, status } = coverage;

        return (
          compareStrings(shortName, "YTP") && compareStrings(status, "ACT")
        );
      });

      const { faceAmount: amountOfTermPurchased } = ytpCoverages?.[0] || "";

      return {
        showLisrVNTGSection,
        riderSumInsured,
        amountOfTermPurchased,
        lisrDividendCashValueAmount,
        lisrDivOptPaidUpAddsAmount,
      };
    }
    case "alirHapPerm": {
      const validAdditionalDividendsCriteria =
        compareStrings(agreementKeyAdmin, "HAP") &&
        compareStrings(majorProductCode, "PERM");

      if (!validAdditionalDividendsCriteria) {
        return [];
      }

      return agreementCoverages.filter((coverage) => {
        const { type, category } = coverage;
        const desiredType = "ALIR";

        return (
          compareStrings(category, "R") && compareStrings(desiredType, type)
        );
      });
    }
    case "lisrHap": {
      const validAdditionalDividendsCriteria =
        compareStrings(agreementKeyAdmin, "HAP") &&
        compareStrings(lineOfBusinessCode, "LIFE") &&
        compareStrings(majorProductCode, "PERM");

      if (!validAdditionalDividendsCriteria) {
        return [];
      }

      const lisrCoverages = agreementCoverages.filter((coverage) => {
        const { type, category } = coverage;
        const desiredType = "LISR";

        return (
          compareStrings(category, "R") && compareStrings(desiredType, type)
        );
      });

      let extendedRider = {};

      if (lisrCoverages.length > 0) {
        if (extendedRiders.length > 0)
          extendedRider = extendedRiders?.filter((rider) =>
            compareStrings(rider.name, "lisr")
          )?.[0];
      } else {
        return [];
      }

      const { faceAmount: riderSumInsured } = lisrCoverages?.[0] || "";

      const ytpCoverages = agreementCoverages.filter((coverage) => {
        const { shortName, status } = coverage;

        return (
          compareStrings(shortName, "YTP") && compareStrings(status, "ACT")
        );
      });

      const { faceAmount: amountOfTermPurchased } = ytpCoverages?.[0] || {};

      const {
        currentAmountOYT,
        currentCoverage,
        paidUpInsurancePortionOfSIA,
        totalAnnualPremium,
      } = extendedRider || {};

      return [
        {
          currentAmountOYT,
          currentCoverage,
          paidUpInsurancePortionOfSIA,
          totalAnnualPremium,
          riderSumInsured,
          amountOfTermPurchased,
          lisrDividendCashValueAmount,
          lisrDivOptPaidUpAddsAmount,
        },
      ];
    }
    default:
      return [];
  }
};
export const showRequestInformation = (props) => {
  const {
    rowData,
    isLoanPayoffCheckRequired,
    agreementData: {
      lineOfBusinessCode,
      agreementKeyAdmin,
      paidUpAddsAvailableAmount,
      loanPayoffAmount,
    },
  } = props;

  return (
    rowData === DATA_UNAVAILABLE ||
    (isLoanPayoffCheckRequired &&
      compareStrings("LIFE", lineOfBusinessCode) &&
      compareArrayOfStrings(["OPM", "MPR", "VNTG1"], agreementKeyAdmin) &&
      extractNumberFromString(paidUpAddsAvailableAmount, 0) > 0 &&
      extractNumberFromString(loanPayoffAmount, 0) > 0)
  );
};
