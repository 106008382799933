import { useState } from "react";

export const useCollapseProps = (props) => {
  const { isOpenDefault = false, collapseHeaderClassString = "" } = props;
  const [isOpen, setIsOpen] = useState(isOpenDefault);
  return {
    onClick: () => setIsOpen((state) => !state),
    isOpen,
    css: {
      buttonCss: isOpen ? "btn-collapse" : "btn-collapse collapsed",
      collapseDivCSS: isOpen ? "collapse content show" : "collapse content",
    },
    collapseHeaderClassString,
  };
};
