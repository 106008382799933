import React from "react";
import CustomComponent from "../logic/customComponent";
import { useCollapseProps } from "./hooks";

const Collapse = (props) => {
  const { id, title, titleComponent, children } = props;
  const {
    onClick,
    isOpen,
    css: { buttonCss, collapseDivCSS },
    collapseHeaderClassString,
  } = useCollapseProps(props);
  return (
    <>
      <button
        className={buttonCss}
        type="button"
        data-toggle="collapse"
        data-target={`#collapse-${id}`}
        aria-expanded={isOpen}
        aria-controls={`#collapse-${id}`}
        onClick={onClick}
      >
        <CustomComponent {...props} component={titleComponent}>
          <span className={collapseHeaderClassString}>{title}</span>
        </CustomComponent>
        <span className="indicator" />
      </button>
      <div className={collapseDivCSS} id={`#collapse-${id}`}>
        {isOpen && children}
      </div>
    </>
  );
};

export const CollapseParent = ({ children }) => (
  <div className="mm-collapse">{children}</div>
);

export default Collapse;
