import React from "react";
import KpiBox from "./kpibox";
import { useKpiProps } from "./hooks";
import LoadingSection, {
  errorTypes,
  loadingTypes,
} from "../../../common/loading";
import IsTruthy from "../../../common/logic/isTruthy";
import { isPolicyInForce } from "../../../../utils/policies";

const KPI = (props) => {
  const { unsupportedPolicy } = props;
  const { kpiData, sizing, agreementRequest } = useKpiProps(props);
  const policyInForce = isPolicyInForce(agreementRequest.data.status);

  return (
    <IsTruthy value={policyInForce}>
      <LoadingSection
        request={agreementRequest}
        loadingType={loadingTypes.PARAGRAPH}
        errorType={errorTypes._500}
        unsupportedPolicy={unsupportedPolicy}
      >
        <div id="kpis" className="row">
          {kpiData.map((kpi) => (
            <KpiBox key={kpi.title.labelId} kpi={kpi} sizing={sizing} />
          ))}
        </div>
      </LoadingSection>
    </IsTruthy>
  );
};

export default React.memo(KPI);
