import React from "react";
import { useFormContext } from "../../../contexts/form";
import CheckboxForm from "../../common/forms/checkboxForm";
import sampleEmail from "../../../assets/images/digreg_sample_email.png";

const EligibleClient = () => {
  const { formData } = useFormContext();
  const { emailContactInput, authAcknowledgement } = formData;

  return (
    <>
      <div>
        <span id="is_eligible_icon" className="illustration-confetti" />{" "}
        <span id="is_eligible_text">
          Great! Your client is eligible to register under{" "}
          <strong>{emailContactInput.value}</strong>
        </span>
      </div>
      <div className="eligible-client-icon">
        <span id="unique_email_icon" className="icon-check-circle" />{" "}
        <span id="unique_email_text">Unique email, not already in use</span>
      </div>
      <div className="eligible-client-icon">
        <span id="ssn_on_file_icon" className="icon-check-circle" />{" "}
        <span id="ssn_on_file_text">
          Client’s SSN is on file with MassMutual
        </span>
      </div>
      <div className="mm-spacing eligible-client-icon">
        <span id="eligibile_products_icon" className="icon-check-circle" />{" "}
        <span id="eligibile_products_text">Client owns eligible products</span>
      </div>
      <div className="mm-spacing">
        <p id="pre_registration_text" className="mm-spacing--minor">
          Once you pre-register your client, they will receive the below email
          directly from your email address. The email will provide a link with
          instructions to create their MassMutual.com password. Once the
          password is created they will complete two-factor authentication by
          following the prompts on MassMutual.com. Upon completion, the client
          will be able to view their accounts on MassMutual.com.
        </p>
      </div>
      <div className="mm-spacing">
        <p className="h3" id="email_preview_lbl">
          Email Preview
        </p>
      </div>
      <div className="mm-spacing">
        <img
          id="review_email_sample_image"
          style={{
            maxWidth: "450px",
            padding: "5px",
            backgroundColor: "rgb(228, 231, 233)",
          }}
          alt="Sample Email"
          src={sampleEmail}
        />
      </div>
      <div className="mm-spacing">
        <p className="h3" id="acknowledgement_lbl">
          Acknowledgement*
        </p>
        <CheckboxForm formField={authAcknowledgement} />
      </div>
    </>
  );
};
export default React.memo(EligibleClient);
