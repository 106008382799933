export const processAll = (props = {}) => props?.response?.data || props;

export * from "./fetchAgreementData";
export * from "./fetchBillingInformation";
export * from "./fetchLoanData";
export * from "./fetchFinancialHistory";
export * from "./fetchProducersData";
export * from "./fetchProducerData";
export * from "./fetchCustomerData";
export * from "./fetchHomeOfficeAgreementData";
export * from "./fetchBeneficiaryData";
export * from "./fetchImagelistFromContent";
export * from "./fetchAgentSearchResults";
export * from "./fetchServiceHistoryData";
export * from "./fetchAnnuityData";
export * from "./fetchAgentProfileData";
export * from "./fetchAgreementCoreData";
export * from "./fetchCustomerEligibilityData";
export * from "./fetchEncryptedDataUsingCryptor";
export * from "./fetchSoaBillingArrangement";
export * from "./fetchCdaValues";
export * from "./fetchFundTransferEligibility";
export * from "./fetchAgreementDataByCustomerMemberGuid";
export * from "./fetchBeneficiariesByAgreementKeys";
export * from "./fetchAgentNotificationsData";
export * from "./fetchCatsByWorkEventId";
export * from "./fetchAgentUnseenNotificationsCountData";
export * from "./fetchDeathClaimEligibilityData";
export * from "./fetchUserDigitalRegistrationEligibilityData";
export * from "./fetchStatementPreferences";
