import produce from "immer";

const types = {
  LOG_IN_USER: "LOG_IN_USER",
  LOG_OUT_USER: "LOG_OUT_USER",
};

const initialUserState = {
  firstName: "",
  lastName: "",
  email: "",
  accessToken: "",
  idToken: "",
  groups: [],
  isAuthenticated: false,
  isAnAgent: false,
  agentType: "",
};

// eslint-disable-next-line default-param-last
const reducer = produce((state = initialUserState, action) => {
  const { type, ...props } = action;
  const groups = props?.userInfo?.groups || [];
  const lowerCaseGroups = groups.map((group) => group.toLowerCase());
  switch (type) {
    case types.LOG_IN_USER:
      return {
        ...state,
        accessToken: props.authState.accessToken.accessToken,
        idToken: props.authState.idToken,
        isAuthenticated: props.authState.isAuthenticated,
        firstName: props.userInfo.given_name,
        lastName: props.userInfo.family_name,
        email: props.userInfo.email,
        groups: lowerCaseGroups,
        isAnAgent: props.isAnAgent,
        agentType: props.agentType,
      };
    case types.LOG_OUT_USER:
      return initialUserState;
    default:
      return state;
  }
});

export { types, initialUserState, reducer };
