import React, { useEffect } from "react";
import { fireTealiumAndFollowLink } from "../../utils/tealium";
import { useLDValue } from "../common/featureFlags/hooks";
import IsTruthy from "../common/logic/isTruthy";
import { verifyInitiateNewLoan } from "../../utils/policies";

const InitiateLoanLink = (props) => {
  const {
    loanData,
    agreementRequest,
    policyId,
    email,
    isPDP,
    isMobileOrTablet = false,
    setShowInitiateNewLoanLink = () => {},
  } = props;
  const transactionSupportedPolicies = useLDValue({
    flagName: ["transactionSupportedPolicies"],
  });
  const agreementData = agreementRequest.data || {};

  const { redirectLink, isEligible, isTransactionAllowed } =
    verifyInitiateNewLoan({
      policyId,
      loanData,
      agreementData,
      transactionSupportedPolicies,
    });
  useEffect(() => {
    setShowInitiateNewLoanLink(isTransactionAllowed && isEligible);
  }, [isTransactionAllowed, isEligible]);

  const classForPDP = isMobileOrTablet
    ? "dropdown-item"
    : "btn btn-inline mm-spacing--none";
  const className = isPDP ? classForPDP : "btn btn-inline";
  const target = isPDP ? "_self" : "_blank";

  return (
    <IsTruthy value={isTransactionAllowed && isEligible}>
      <li>
        <button
          type="button"
          id="initiateALoan-link"
          onClick={() =>
            fireTealiumAndFollowLink({
              url: redirectLink,
              action: "Initiate a Loan link clicked",
              category: "QuickLink",
              value: policyId,
              custId: email,
              target,
            })
          }
          className={className}
        >
          Initiate a Loan
        </button>
      </li>
    </IsTruthy>
  );
};

export default React.memo(InitiateLoanLink);
