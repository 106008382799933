import React from "react";
import { isDefined } from "../../../../utils/evaluate";
import IsTruthy from "../../../common/logic/isTruthy";
import IsFalsy from "../../../common/logic/isFalsy";
import {
  InformationPopover,
  LabelWithInformationPopover,
  RequestInformationLink,
} from "../../../common/popovers/informationPopover";
import { checkStatusAndReason } from "../../types/life/hooks";
import {
  compareArrayOfStrings,
  compareStrings,
  stringIsEmptyOrNullish,
} from "../../../../utils/string";
import { isPE1NTLVL } from "../../../../utils/policies";
import { AS_OF_DATE_UNAVAILABLE } from "../../../../constants/ui";

const KpiTableModal = (props) => {
  const {
    idPrefix,
    data,
    displayData: { rows, footer, ltcirAdditionalData, policyIsVariableLife },
    asOfDate,
    sentence,
    isLTCIR,
    agreementData,
  } = props;

  const { agreementKey } = agreementData;

  const rowsToDisplay = rows.map((row) => {
    const { accessor, label, formatFunction } = row;

    const labelId = `${idPrefix}_${accessor}_lbl`;
    const valueId = `${idPrefix}_${accessor}_value`;
    const formattedValue = isDefined(formatFunction)
      ? formatFunction(data[accessor])
      : data[accessor];
    const { agreementKeyAdmin, netSurrenderAmount } = agreementData;

    const ineligbleAdmins = ["LVRGVL", "LIFCOM"];
    const DISPLAY_REQUEST_INFO =
      compareArrayOfStrings(ineligbleAdmins, agreementKeyAdmin) ||
      (compareStrings(agreementKeyAdmin, "PE1") &&
        (stringIsEmptyOrNullish(netSurrenderAmount) ||
          netSurrenderAmount === 0)) ||
      !isPE1NTLVL({ agreementRequest: { data: agreementData } });

    if (accessor === "partialSurrenderAmtDisplay" && DISPLAY_REQUEST_INFO) {
      return (
        <tr key={accessor}>
          <td id={labelId}>
            <LabelWithInformationPopover
              label="Partial Surrender Value"
              popoverId="partial_surrender_value"
              text="The information is not available online or via the call center. Click to submit a request for this information."
            />
          </td>
          <td id={valueId}>
            <IsTruthy
              value={agreementData}
              validationFunction={checkStatusAndReason}
            >
              <RequestInformationLink
                agreementData={agreementData}
                agreementKey={agreementKey}
                popoverId="partialSurrender"
              />
            </IsTruthy>
            <IsFalsy
              value={agreementData}
              validationFunction={checkStatusAndReason}
            >
              Unavailable
            </IsFalsy>
          </td>
        </tr>
      );
    }

    return (
      <React.Fragment key={accessor}>
        <IsTruthy
          value={policyIsVariableLife && accessor === "fundGPAInterestRateFmtd"}
        >
          <tr key={accessor}>
            <td id={labelId}>
              <p>
                <LabelWithInformationPopover
                  label={label}
                  popoverId="GPA_Interest_Tooltip"
                  text="The GPA interest rate is declared monthly and becomes effective on the first of each calendar month. This rate will never be less than the guaranteed rate as defined within your policy contract form. This rate is applied to the amount of fund value allocated to the GPA, in excess of policy debt. Amounts in the GPA equal to policy debt will be credited using a different rate as defined in your policy contract and/or prospectus"
                  placement="bottom"
                />
              </p>
            </td>
            <td id={valueId}>
              <p>{formattedValue}</p>
            </td>
          </tr>
          <tr />
        </IsTruthy>
        <IsFalsy value={accessor === "fundGPAInterestRateFmtd"}>
          <tr key={accessor}>
            <td id={labelId}>{label}</td>
            <td id={valueId}>{formattedValue}</td>
          </tr>
        </IsFalsy>
      </React.Fragment>
    );
  });

  const ltcirRows = isLTCIR
    ? ltcirAdditionalData.map((row) => {
        const { accessor, label, formatFunction, popoverMessage, popoverId } =
          row;

        const labelId = `${idPrefix}_${accessor}_lbl`;
        const valueId = `${idPrefix}_${accessor}_value`;
        const formattedValue = formatFunction(data[accessor]);

        return (
          <tr key={accessor}>
            <td id={labelId}>
              {label}{" "}
              <InformationPopover popoverId={popoverId} text={popoverMessage} />
            </td>
            <td id={valueId}>{formattedValue}</td>
          </tr>
        );
      })
    : [];

  const footerSection = footer.map((row) => {
    const {
      accessor,
      label,
      formatFunction,
      showPopover,
      popoverMessage,
      popoverId,
    } = row;

    const labelId = `${idPrefix}_${accessor}_lbl`;
    const valueId = `${idPrefix}_${accessor}_value`;
    const formattedValue = formatFunction(data[accessor]);

    return (
      <tr key={accessor}>
        <IsTruthy value={showPopover}>
          <td id={labelId}>
            {label}{" "}
            <InformationPopover popoverId={popoverId} text={popoverMessage} />
          </td>
        </IsTruthy>
        <IsFalsy value={showPopover}>
          <td id={labelId}>{label}</td>
        </IsFalsy>
        <td id={valueId}>{formattedValue}</td>
      </tr>
    );
  });

  return (
    <div className="modal-body">
      <IsTruthy value={asOfDate} validationFunction={isDefined}>
        <p className="mm-sub-copy">As of {asOfDate}</p>
      </IsTruthy>
      <IsFalsy value={asOfDate} validationFunction={isDefined}>
        <p className="mm-sub-copy"> {AS_OF_DATE_UNAVAILABLE}</p>
      </IsFalsy>
      <table className="mm-table-figures">
        <tbody>{rowsToDisplay}</tbody>
        <tfoot>{footerSection}</tfoot>
      </table>
      <IsTruthy value={isLTCIR}>
        <table className="mm-table-figures">
          <tbody>{ltcirRows}</tbody>
        </table>
      </IsTruthy>
      <IsTruthy value={sentence} validationFunction={isDefined}>
        <p>{sentence}</p>
      </IsTruthy>
    </div>
  );
};

export default KpiTableModal;
