import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import { useMakeRequest } from "../../hooks/api";
import {
  getBest,
  getBpIdFromUser,
  getFirstServicingAgent,
  getOwnerFromCustomers,
  getRequestorInfo,
  getUserBySubType,
  isHomeOfficeUser,
  userTypes,
} from "../../utils/findData";
import { useUserState } from "../../contexts/user";
import { isDefined } from "../../utils/evaluate";
import {
  compareStrings,
  isNonEmptyString,
  stringIsEmptyOrNullish,
} from "../../utils/string";
import { typesOfAgents } from "../../data/typesOfAgents";
import {
  useFillableFormList,
  verifyFillableFormLibraryLink,
} from "./restrictions";
import { useLDValue } from "../common/featureFlags/hooks";
import RadioButtonCell from "../common/table/customCells/radioButtonCell";
import {
  docusignSigningEvents,
  supportedTransactions,
} from "../../utils/translate";
import { isAnnuityContract } from "../../utils/policies";
import { types, useFormContext } from "../../contexts/form";
import {
  hasError,
  useConfirmEmailValidation,
  useEmailValidation,
  useGeneralValidation,
} from "../../utils/validation";
import { formatName, getLastXChars } from "../../utils/format";
import { useQuery, useRequestorContactEmail } from "../../hooks/helper";
import { formattingTypes } from "../../utils/formFormatting";
import {
  formatDate,
  getAge,
  getCurrentDate,
  getCurrentDateTimeWithTimezone,
} from "../../utils/dates";
import {
  createCatsEvents,
  createEsignatureRecipientView,
  sendEsignatureEnvelope,
} from "../../api";
import {
  fireTealiumAndFollowLink,
  fireTealiumEvent,
} from "../../utils/tealium";
import { useAddOrRemoveChatLauncher } from "../../utils/genesysChatClient";

export const useFillableFormsProps = () => {
  const { agreementKey } = useParams();
  const [pageNumber, setPageNumber] = useState(1);
  const [errorText, setErrorText] = useState("");
  const [showErrorPage, setShowErrorPage] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [showLoadingIcon, setShowLoadingIcon] = useState(false);
  const { user } = useUserState();
  const query = useQuery();

  const advisorActivityStatus = query.get("event");

  useEffect(() => {
    if (
      compareStrings(
        advisorActivityStatus,
        docusignSigningEvents.SIGNING_COMPLETE.code
      )
    ) {
      setPageNumber(2);
    } else if (isNonEmptyString(advisorActivityStatus)) {
      const isDocusignIncompleteEvent = Object.values(
        docusignSigningEvents
      ).some((event) => compareStrings(event.code, advisorActivityStatus));

      if (isDocusignIncompleteEvent) {
        setPageNumber(3);

        setErrorText("DocuSign form not sent. Please try again.");
        setShowErrorPage(true);
      }
    }
  }, [advisorActivityStatus]);

  const transactionSupportedPolicies = useLDValue({
    flagName: ["transactionSupportedPolicies"],
  });

  const agreementRequest = useMakeRequest({
    apiRequest: "fetchAgreementData",
    apiParams: { agreementKey },
    immediateRequest: true,
    dataSelector: "processAgreementRequest",
    dependentRequests: [],
  });
  const agreementData = agreementRequest?.data || {};

  const {
    lineOfBusinessCode,
    policyId,
    isPolicyNotFound,
    isUnauthorized,
    agreementCustomers,
  } = agreementData;

  const fillableFormList = useFillableFormList({ agreementData });

  const shouldDisableSubmit = isHomeOfficeUser(user);
  const { agentType } = getBpIdFromUser(user);
  const isMmsdOnlyUser = compareStrings(agentType, typesOfAgents.MMSD);

  const fillableFormSelectionFields = fillableFormList.reduce(
    (forms, form) => ({
      ...forms,
      [form.code]: {
        id: form.code,
        label: form.displayName,
        labelBold: true,
        name: form.code,
        groupName: "fillableFormRadio",
        value: false,
        formCode: form.code,
      },
    }),
    {}
  );

  const { owner } = getOwnerFromCustomers({ agreementCustomers });
  const ownerName = formatName({
    nameObject: owner,
    format: "firstNameDisplayedFirst",
  });
  const { memberGUID } = owner || {};

  const recipientFields = useAddRecipientFormFields({ ownerName });
  const fields = { ...fillableFormSelectionFields, ...recipientFields };

  const isPolicyLoaded = isDefined(policyId) && isDefined(lineOfBusinessCode);

  const { isEligible, isTransactionAllowed } = verifyFillableFormLibraryLink({
    agreementData,
    transactionSupportedPolicies,
    fillableFormList,
  });

  const customerRequestForOwner = useMakeRequest({
    apiRequest: "fetchCustomerData",
    apiParams: { memberGUID },
    immediateRequest: false,
    dataSelector: "processCustomersData",
  });
  const isCustomerApiCallRequired =
    isEligible && isPolicyLoaded && isTransactionAllowed && pageNumber === 1;

  useEffect(() => {
    if (isCustomerApiCallRequired) {
      customerRequestForOwner.executeRequest();
    }
  }, [isCustomerApiCallRequired]);

  const producersRequest = useMakeRequest({
    apiRequest: "fetchProducersData",
    apiParams: { agreementKey },
    immediateRequest: false,
    dataSelector: "processProducersData",
  });

  const isProducersApiCallRequired =
    isEligible && isPolicyLoaded && isTransactionAllowed;

  useEffect(() => {
    if (isProducersApiCallRequired) {
      producersRequest.executeRequest();
    }
  }, [isProducersApiCallRequired]);

  const onSubmit = async (formData) => {
    setShowLoadingIcon(true);
    const selectedFormConfig = fillableFormList.find(
      (fillableForm) => formData[fillableForm.code].value
    );
    const { displayName: selectedFormLabel } = selectedFormConfig;

    const headers = {
      Authorization: `Bearer ${user.accessToken}`,
    };

    try {
      const createEnvelopeRequestBody = makeCreateEnvelopeRequestBody({
        formData,
        agreementRequest,
        customerRequestForOwner,
        fillableFormList,
      });
      const { data: envelope } = await sendEsignatureEnvelope({
        headers,
        apiParams: createEnvelopeRequestBody,
        agreementKey,
      });
      const { envelopeId } = envelope;

      const recipientViewRequestBody = makeRecipientViewRequestBody({
        formData,
        fillableFormList,
        envelopeId,
      });

      const { data: recipientView } = await createEsignatureRecipientView({
        headers,
        apiParams: recipientViewRequestBody,
        agreementKey,
        envelopeId,
      });
      const { url } = recipientView;

      fireTealiumAndFollowLink({
        action: `${lineOfBusinessCode}: ${selectedFormLabel} selected and docusign Form generated for Advisor.`,
        category: `${supportedTransactions.FillableFormLinks}`,
        value: policyId,
        custId: user?.email,
        url,
      });
    } catch (e) {
      setTransactionId(e.response?.data?.transGUID);

      fireTealiumEvent({
        action: `${lineOfBusinessCode}: ${selectedFormLabel} selected and docusign Form generation Failed.`,
        category: `${supportedTransactions.FillableFormLinks}`,
        value: policyId,
        custId: user?.email,
      });
      setErrorText(
        compareStrings("Network Error", e.message)
          ? "The page you are looking for is temporarily unavailable."
          : "There was an error processing your request; please try again later."
      );
      setShowErrorPage(true);
      setShowLoadingIcon(false);
    }
  };

  useAddOrRemoveChatLauncher({
    agreementData,
    page: "Send Fillable Forms transaction",
  });

  return {
    fields,
    agreementRequest,
    agreementData,
    pageNumber,
    onSubmit,
    shouldDisableSubmit,
    lineOfBusinessCode,
    showErrorPage,
    errorText,
    showLoadingIcon,
    isUnauthorized,
    isMmsdOnlyUser,
    isPolicyNotFound,
    isPolicyLoaded,
    isTransactionAllowed,
    isEligible,
    transactionId,
    transactionName: supportedTransactions.FillableFormLinks,
    policyId,
    userEmail: user?.email,
    producersRequest,
    setPageNumber,
  };
};
export const useAddRecipientFormFields = (props) => {
  const { ownerName } = props;

  const {
    user: { firstName, lastName },
  } = useUserState();
  const contactChoiceEmail = useRequestorContactEmail();

  return {
    ownerNameInput: {
      id: "ownerName",
      name: "ownerName",
      value: ownerName,
      formatting: "NAMES",
      hideLabel: true,
      errors: [],
      postpone: true,
      useValidation: useGeneralValidation,
      disabled: true,
    },
    emailContactInput: {
      id: "emailContactInput",
      name: "emailContactInput",
      label: "Email Address",
      ariaLabel: "Email Address input",
      placeholder: "Please enter your client’s email address",
      labelBold: true,
      required: true,
      errors: [],
      postpone: false,
      useValidation: useEmailValidation,
      disabled: false,
      className: "form-control",
    },
    emailContactConfirmInput: {
      id: "emailContactConfirmInput",
      name: "emailContactConfirmInput",
      label: "Confirm Email Address",
      ariaLabel: "Confirm Email Address input",
      placeholder: "Please enter your client’s email address",
      labelBold: true,
      required: true,
      value: "",
      uncheckedValue: "",
      errors: [],
      postpone: true,
      useValidation: useConfirmEmailValidation,
      disabled: false,
      disablePaste: true,
      className: "form-control",
    },
    customEmailMessage: {
      id: "customEmailMessage",
      label: "Custom Email Message",
      ariaLabel: "Custom Email Message",
      hideLabel: false,
      labelBold: true,
      required: false,
      placeholder: "",
      name: "customEmailMessage",
      className: "md-textarea form-control",
      type: "text",
      rows: 5,
      cols: 300,
      useValidation: useGeneralValidation,
      min: 0,
      max: 1000,
      value: "",
      errors: [],
    },
    requestorNameInput: {
      id: "requestorName",
      name: "requestorName",
      value: `${firstName} ${lastName}`,
      formatting: formattingTypes.NAMES,
      useValidation: useGeneralValidation,
      hideLabel: true,
      errors: [],
      postpone: true,
      disabled: true,
    },
    contactChoiceEmailInput: {
      id: "contactChoiceEmailInput",
      hideLabel: true,
      ariaLabel: "Email input",
      placeholder: "Please type your email address",
      name: "contactChoiceEmailInput",
      value: contactChoiceEmail,
      uncheckedValue: "",
      errors: [],
      postpone: true,
      useValidation: useEmailValidation,
      disabled: false,
    },
    contactChoiceConfirmEmailInput: {
      id: "contactChoiceConfirmEmailInput",
      hideLabel: true,
      ariaLabel: "Confirm Email input",
      placeholder: "Please type your email address",
      name: "contactChoiceConfirmEmailInput",
      value: "",
      uncheckedValue: "",
      errors: [],
      postpone: true,
      emailFormFieldValue: contactChoiceEmail,
      useValidation: useConfirmEmailValidation,
      disabled: false,
      disablePaste: true,
    },
    authAcknowledgement: {
      id: "authAcknowledgement",
      name: "authAcknowledgement",
      label:
        "I confirm that I am aware of the electronic signature guidelines listed above.",
      value: false,
      required: false,
    },
  };
};
export const useEmailContactProps = () => {
  const {
    formData: { ownerNameInput, emailContactInput, emailContactConfirmInput },
    dispatchForm,
  } = useFormContext();
  const confirmEmailValidationErrors = useConfirmEmailValidation({
    formField: emailContactConfirmInput,
  });
  useEffect(() => {
    dispatchForm({
      type: types.REPLACE_FIELD,
      formField: {
        ...emailContactConfirmInput,
        emailFormFieldValue: emailContactInput.value,
        errors: confirmEmailValidationErrors,
      },
    });
  }, [emailContactInput]);

  return {
    ownerNameInput,
    emailContactInput,
    emailContactConfirmInput,
  };
};
export const useSelectForm = (props) => {
  const { agreementData } = props;
  const { formData } = useFormContext();
  const fillableFormList = useFillableFormList({
    agreementData,
  });

  const formsTableColumns = [
    {
      accessor: "displayName",
      label: "FORM",
      alignment: "left",
      CustomCell: RadioButtonCell,
    },
    {
      accessor: "description",
      label: "DESCRIPTION",
      alignment: "left",
    },
  ];
  const fillableFormRows = fillableFormList.map((form) => ({
    ...form,
    formField: formData[form.code],
  }));
  const formsTableRows = fillableFormRows;
  return { formsTableColumns, formsTableRows };
};
export const useCanContinue = () => {
  const { formData } = useFormContext();
  const { customEmailMessage } = formData;
  const hasSelectedForm = Object.values(formData).find(
    (f) => f.groupName === "fillableFormRadio" && f.value === true
  );
  const hasCustomEmailMessageError = hasError(customEmailMessage);
  const hasAcknowledgementError = useCheckForAcknowledgementErrors();
  const hasRecipientEmailInputError = useCheckForRecipientEmailInputErrors();
  const hasFinancialProfessionalEmailInputError =
    useCheckForFinancialProfessionalEmailInputErrors();
  const shouldEnableContinue =
    !hasAcknowledgementError &&
    !hasRecipientEmailInputError &&
    !hasCustomEmailMessageError &&
    hasSelectedForm &&
    !hasFinancialProfessionalEmailInputError;

  return { shouldEnableContinue };
};

export const useCheckForRecipientEmailInputErrors = () => {
  const { formData } = useFormContext();
  const { emailContactInput, emailContactConfirmInput } = formData;

  return (
    !isDefined(emailContactInput.value) ||
    !isDefined(emailContactConfirmInput.value) ||
    isDefined(emailContactInput.errors[0]?.hasError) ||
    isDefined(emailContactConfirmInput.errors[0]?.hasError)
  );
};

export const useCheckForFinancialProfessionalEmailInputErrors = () => {
  const { formData } = useFormContext();

  const { contactChoiceEmailInput, contactChoiceConfirmEmailInput } = formData;

  const contactChoiceEmail = useRequestorContactEmail();

  const hideChoiceConfirmEmailInput =
    !stringIsEmptyOrNullish(contactChoiceEmail) &&
    compareStrings(contactChoiceEmail, contactChoiceEmailInput.value);

  return (
    !isDefined(contactChoiceEmailInput.value) ||
    isDefined(contactChoiceEmailInput.errors[0]?.hasError) ||
    (!hideChoiceConfirmEmailInput &&
      isDefined(contactChoiceConfirmEmailInput.errors[0]?.hasError))
  );
};

export const useCheckForAcknowledgementErrors = () => {
  const { formData } = useFormContext();

  const { authAcknowledgement } = formData;

  return !authAcknowledgement.value;
};

export const useConfirmationProps = (props) => {
  const {
    policyId,
    lineOfBusinessCode,
    transactionName,
    producersRequest,
    agreementData,
    setPageNumber,
  } = props;

  const query = useQuery();
  const selectedFormCode = query.get("formCode");
  const envelopeId = query.get("envelopeId");
  const { user } = useUserState();
  const { agreementKey } = useParams();

  const fillableFormsConfig = useLDValue({
    flagName: ["fillableForms"],
  });
  const { forms: formList } = fillableFormsConfig;
  const selectedForm = formList.find((form) =>
    compareStrings(form.code, selectedFormCode)
  );
  const selectedFormName = selectedForm?.displayName;
  const isProducersDataLoaded = isDefined(
    producersRequest?.data?.producers[0]?.firstName
  );

  const [isCatsAPICalled, setIsCatsAPICalled] = useState(false);
  useEffect(() => {
    const fillableFormsCatsEventBody = makeFillableFormsCatsRequestBody({
      user,
      producersRequest,
      agreementData,
      envelopeId,
      selectedFormName,
    });

    const headers = {
      Authorization: `Bearer ${user.accessToken}`,
    };
    if (!isCatsAPICalled && isProducersDataLoaded)
      createCatsEvents({ headers, apiParams: fillableFormsCatsEventBody })
        .then(() => {
          fireTealiumEvent({
            label: "SVNT",
            action: `${lineOfBusinessCode}: Fillable Forms Cats Request Created`,
            category: `${supportedTransactions.FillableFormLinks}`,
            value: policyId,
            custId: user?.email,
          });
          setPageNumber(2);
        })
        .catch(() => {
          fireTealiumEvent({
            label: "SVNT",
            action: `${lineOfBusinessCode}: Fillable Forms Cats Request Failed`,
            category: `${supportedTransactions.FillableFormLinks}`,
            value: policyId,
            custId: user?.email,
          });
        });
    setIsCatsAPICalled(true);
  }, [isCatsAPICalled, isProducersDataLoaded]);

  const policyNumberLabelDisplayText = isAnnuityContract(lineOfBusinessCode)
    ? "Contract Number"
    : "Policy Number";

  return {
    policyId,
    selectedFormName,
    agreementKey,
    userEmail: user?.email,
    lineOfBusinessCode,
    transactionName,
    policyNumberLabelDisplayText,
    envelopeId,
  };
};

export const getSelectedFillableForm = (props) => {
  const { formData } = props;

  const selectedForm = Object.values(formData).find(
    (item) => item.groupName === "fillableFormRadio" && item.value === true
  );

  return selectedForm || {};
};

export const makeCreateEnvelopeRequestBody = (props) => {
  const {
    formData,
    agreementRequest,
    fillableFormList,
    customerRequestForOwner,
  } = props;

  const selectedFormConfig = fillableFormList.find(
    (fillableForm) => formData[fillableForm.code].value
  );

  const { emailContactInput, contactChoiceEmailInput, customEmailMessage } =
    formData;

  const agreementData = agreementRequest.data || {};
  const { agreementCustomers, jurisdictionState } = agreementData;

  const insured = getUserBySubType({
    people: agreementCustomers,
    type: "INSD",
    subType: "PRMR",
  });
  const { birthDate } = insured;
  const insuredAge = getAge(birthDate, 0);

  const { data: ownerData } = customerRequestForOwner;

  const { addresses } = ownerData || {};
  const ownerAddress = getBest(addresses) || {};

  const { state: ownerResidenceState } = ownerAddress;

  const { templates, docHubEmail } = selectedFormConfig;
  const filteredTemplates = templates.filter((template) => {
    const { conditional, code } = template;

    const isDisclouserRequiredForKY =
      // deepcode ignore IncompatibleTypesInComparison: <the getAge function have required number conversion logic>
      insuredAge >= 60 &&
      compareStrings(jurisdictionState, "KY") &&
      compareStrings(code, "KY_Life_Settlement_Brochure");

    const isDisclouserRequiredForOR =
      // deepcode ignore IncompatibleTypesInComparison: <the getAge function have required number conversion logic>
      insuredAge >= 60 &&
      compareStrings(ownerResidenceState, "OR") &&
      compareStrings(code, "CP1024OR_LifeSettlement-OR");

    const isDisclouserRequiredForME =
      // deepcode ignore IncompatibleTypesInComparison: <the getAge function have required number conversion logic>
      insuredAge >= 60 &&
      compareStrings(ownerResidenceState, "ME") &&
      compareStrings(code, "CP1024ME_LifeSettlement-ME");

    const isDisclouserRequiredForWA =
      // deepcode ignore IncompatibleTypesInComparison: <the getAge function have required number conversion logic>
      insuredAge >= 60 &&
      compareStrings(ownerResidenceState, "WA") &&
      compareStrings(code, "CP1024WA_LifeSettlement-WA");

    return (
      !conditional ||
      isDisclouserRequiredForKY ||
      isDisclouserRequiredForOR ||
      isDisclouserRequiredForME ||
      isDisclouserRequiredForWA
    );
  });

  const customEmailMessageContent = DOMPurify.sanitize(
    customEmailMessage.value
  );
  const requestBody = {
    form: {
      metaData: {
        templates: filteredTemplates,
      },
      recipients: {
        advisor: { email: contactChoiceEmailInput.value || "" },
        client: {
          email: emailContactInput.value || "",
          note: customEmailMessageContent,
        },
        docHub: { emailCode: docHubEmail },
      },
    },
    requestHelper: "fillableForms",
  };
  return requestBody;
};

export const makeRecipientViewRequestBody = (props) => {
  const { formData, fillableFormList, envelopeId } = props;

  const selectedFormConfig = fillableFormList.find(
    (fillableForm) => formData[fillableForm.code].value
  );
  const { code } = selectedFormConfig;

  const { contactChoiceEmailInput } = formData;

  return {
    email: contactChoiceEmailInput.value,
    returnUrl: `${window.location.href}?envelopeId=${encodeURIComponent(
      envelopeId
    )}&formCode=${encodeURIComponent(code)}`,
  };
};

export const makeFillableFormsCatsRequestBody = (props) => {
  const {
    user,
    agreementData,
    producersRequest,
    selectedFormName,
    envelopeId,
  } = props;

  const {
    agreementKey,
    agreementKeyAdmin,
    agreementKeyPrefix,
    agreementKeyPrimary,
    agreementCustomers,
  } = agreementData;

  const { producers } = producersRequest?.data || {};

  const personInsured = getUserBySubType({
    type: userTypes.INSURED,
    subType: "PRMR",
    people: agreementCustomers,
  });

  const {
    firstName: insuredFirstName = "",
    lastName: insuredLastName = "",
    middleName: insuredMiddleName = "",
  } = personInsured || {};
  const requestorName = `${user.firstName} ${user.lastName}`;
  const currentTimeLongCommentFormatted = getCurrentDateTimeWithTimezone();

  const requestHelper = {};
  requestHelper.transactionName = "fillableFormLinks";

  const receivedDateTime = formatDate(getCurrentDate());

  const servicingAgent = getFirstServicingAgent({
    agents: producers,
  });
  const { businessPartnerId, homeAgencyId } = servicingAgent;

  const { isAnAgent, idNumber } = getBpIdFromUser(user);
  const lengthOfAgencyId = 3;
  const orgUnit = isAnAgent
    ? getLastXChars(homeAgencyId, lengthOfAgencyId).padStart(
        lengthOfAgencyId,
        0
      )
    : "";

  const { requestorTypeCode } = getRequestorInfo({
    idNumber,
    producersData: producersRequest?.data,
    agreementKey,
  });

  const longComment = `Requestor Contact Info: \r\n  Name: ${requestorName}\r\n  ID: ${idNumber} Org Unit: ${orgUnit}\r\nSubmitted Date/Time: ${currentTimeLongCommentFormatted}
  \r\n\r\nForm Name: ${selectedFormName}  \r\n\r\nEnvelope ID: ${envelopeId} \r\n\r\n `;

  const fillableFormsCatsCATSData = {
    divCode: "CS",
    deptCode: "SE",
    workEvent: "12727",
    agreementNumber: agreementKeyPrimary,
    carrierAdminSystem: agreementKeyAdmin,
    agreementNumberPrefix: agreementKeyPrefix,
    priorityCode: "10",
    requestorTypeCode,
    receivedDate: receivedDateTime,
    agentId: `${businessPartnerId}`,
    serviceChannelSourceCode: "19",
    lastUpdatedBy: "SVCNET2",
    loggedByIdent: "SVCNET2",
    userId: "SERVNET",
    completionInd: "Y",
    insuredFirstName,
    insuredLastName,
    insuredMiddleName,
    longComment,
    requestHelper,
    requestorName,
  };

  return {
    eventData: fillableFormsCatsCATSData,
    requestHelper,
  };
};
