import React from "react";
import { isDefined } from "../../../../utils/evaluate";
import IsTruthy from "../../../common/logic/isTruthy";

const ContractInformation = (props) => {
  const { owner, jointOwner, jointAnnuitant, productTypeName, policyId } =
    props;

  return (
    <div className="row mm-spacing">
      <div className="col-md-6 col-sm-12">
        <dl className="mm-key-value-pair--summation">
          <dt id="owner_name_lbl">Owner Name</dt>
          <dd id="owner_name_value">{owner} </dd>
          <IsTruthy value={isDefined(jointOwner)}>
            <dt id="joint_owner_name_lbl">Joint Owner Name</dt>
            <dd id="joint_owner_name_value">{jointOwner}</dd>
          </IsTruthy>
          <IsTruthy value={isDefined(jointAnnuitant)}>
            <dt id="joint_annuitant_name_lbl">Joint Annuitant Name</dt>
            <dd id="joint_annuitant_name_value">{jointAnnuitant}</dd>
          </IsTruthy>
          <dt id="product_name_lbl">Product Name</dt>
          <dd id="product_name_value">{productTypeName}</dd>
          <dt id="contract_number_lbl">Contract Number</dt>
          <dd id="contract_number_value">{policyId}</dd>
        </dl>
      </div>
    </div>
  );
};

export default ContractInformation;
