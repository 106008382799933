import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { formatDate } from "../../../../utils/dates";
import { useUserState } from "../../../../contexts/user";
import { fireTealiumEvent } from "../../../../utils/tealium";
import { useMakeRequest } from "../../../../hooks/api";
import { getUsers } from "../../../../utils/findData";
import { userTypes } from "../../../../utils/translate";
import { getFundTransferType } from "../../../../utils/transactions";
import { isEnvisionPolicy } from "../../../../utils/policies";

export const useAnnuityContractDetailProps = (props) => {
  const { agreementKey } = useParams();
  const {
    agreementRequest,
    producersRequest,
    billingRequest,
    serviceHistoryRequest,
    deathClaimEligibilityRequest,
  } = props?.apiRequests || {};
  const agreementData = agreementRequest?.data || {};

  const { user } = useUserState();
  const { email } = user;
  const { agreementCustomers, lineOfBusinessCode } = agreementData;
  const owner = getUsers(agreementCustomers, userTypes.OWNER)[0] || {};
  const { memberGUID } = owner;

  const fundTransferEligibilityRequest = useMakeRequest({
    apiRequest: "fetchFundTransferEligibility",
    apiParams: {
      memberGUID,
      agreementKey,
      fundTransferType: getFundTransferType(agreementData),
    },
    immediateRequest: false,
    dataSelector: "processFundTransferEligibility",
    initiateDevModeDispatch: false,
  });

  useEffect(() => {
    if (isEnvisionPolicy(agreementData)) {
      fundTransferEligibilityRequest.executeRequest();
    }
  }, [agreementData]);

  const { policyId, asOfDate } = agreementRequest?.data || {};

  const formattedAsOfDate = formatDate(asOfDate, "monthCommaDayYear");

  const [isDrawerOpened, setDrawerOpened] = useState(false);
  const [customer, setCustomer] = useState();
  const openDrawer = (customerObject) => () => {
    setCustomer(customerObject);
    setDrawerOpened(true);
  };
  const closeDrawer = () => setDrawerOpened(false);

  const printPDP = () => {
    fireTealiumEvent({
      label: "SVNT",
      action: "Print",
      category: "Policy Details - Envision",
      custId: email,
    });
    window.print();
  };

  return {
    policyId,
    agreementKey,
    agreementRequest,
    billingRequest,
    fundTransferEligibilityRequest,
    serviceHistoryRequest,
    deathClaimEligibilityRequest,
    asOfDate: formattedAsOfDate,
    producersRequest,
    email,
    isDrawerOpened,
    openDrawer,
    closeDrawer,
    customer,
    printPDP,
    user,
    lineOfBusinessCode,
  };
};
