import React from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { setTitle } from "../../utils/dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
import FiveHundredOneError from "../common/errors/501";
import LdBannerContent from "../common/molecules/ldBannerContent";
import FourHundredFourError from "../common/errors/404";
import IsFalsy from "../common/logic/isFalsy";
import IsTruthy from "../common/logic/isTruthy";
import ErrorPage from "../common/errors/errorPage";
import LoadingSection, { errorTypes, loadingTypes } from "../common/loading";
import FiveHundredError from "../common/errors/500";
import Form from "../common/forms/form";
import { ModalProvider } from "../../contexts/modal";
import FillableFormsHeader from "./pages/fillableFormsHeader";
import SelectForm from "./pages/selectForm";
import { useFillableFormsProps } from "./hooks";
import ModalControl from "./modalControl";
import Confirmation from "./pages/confirmation";
import AddRecipient from "./pages/addRecipient";
import RequestorContact from "../common/requestorContact";
import { requestorContactLayout } from "../common/requestorContact/hooks";
import Acknowledgement from "./pages/acknowledgement";

const FillableForms = () => {
  setTitle("ServiceNet - Fillable Forms");

  const { agreementKey } = useParams();

  const {
    fields,
    agreementRequest,
    agreementData,
    pageNumber,
    onSubmit,
    shouldDisableSubmit,
    lineOfBusinessCode,
    showErrorPage,
    errorText,
    showLoadingIcon,
    isUnauthorized,
    isMmsdOnlyUser,
    isPolicyNotFound,
    isPolicyLoaded,
    isTransactionAllowed,
    isEligible,
    transactionId,
    transactionName,
    policyId,
    userEmail,
    producersRequest,
    setPageNumber,
  } = useFillableFormsProps();
  if (isUnauthorized) {
    return (
      <>
        <Header />
        <div className="container">
          <LdBannerContent />
          <FiveHundredOneError />
        </div>
        <Footer />
      </>
    );
  }

  if (isPolicyNotFound) {
    return (
      <>
        <Header />
        <div className="container">
          <LdBannerContent />
          <FourHundredFourError />
        </div>
        <Footer />
      </>
    );
  }

  return (
    <LoadingSection
      request={agreementRequest}
      loadingType={loadingTypes.SPINNER}
      errorType={errorTypes._500}
      addHeaderFooterToError
    >
      <LoadingSection
        request={producersRequest}
        loadingType={loadingTypes.SPINNER}
        errorType={errorTypes._500}
        addHeaderFooterToError
      >
        <IsTruthy value={isPolicyLoaded}>
          <IsFalsy value={isTransactionAllowed}>
            <IsTruthy value={isMmsdOnlyUser}>
              <Header />
              <div className="container">
                <LdBannerContent />
                <ErrorPage
                  errorTitle="Our records indicate you do not have access to this application."
                  agreementKey={agreementRequest?.agreementKey}
                  errorPageFor="mmsdOnly"
                  imageClass="illustration-error_500"
                />
              </div>
              <Footer />
            </IsTruthy>
            <IsFalsy value={isMmsdOnlyUser}>
              <Header />
              <div className="container">
                <LdBannerContent />
                <FourHundredFourError />
              </div>
              <Footer />
            </IsFalsy>
          </IsFalsy>
          <IsTruthy value={isTransactionAllowed}>
            <IsFalsy value={isEligible}>
              <Header />
              <div className="container">
                <LdBannerContent />
                <FourHundredFourError />
              </div>
              <Footer />
            </IsFalsy>
            <IsTruthy value={isTransactionAllowed && isEligible}>
              <IsTruthy value={showErrorPage}>
                <Header />
                <div className="container">
                  <LdBannerContent />
                  <FiveHundredError
                    turnOffReload
                    transactionId={transactionId}
                    title={errorText}
                    agreementKey={agreementKey}
                  />
                </div>
                <Footer />
              </IsTruthy>
              <IsFalsy value={showErrorPage}>
                <div className="container">
                  <Form fields={fields}>
                    <ModalProvider>
                      <FillableFormsHeader
                        shouldDisableSubmit={shouldDisableSubmit}
                      />
                      <IsTruthy value={pageNumber === 1}>
                        <SelectForm agreementData={agreementData} />
                        <div id="addRecipient" className="mm-spacing">
                          <AddRecipient />
                        </div>
                        <RequestorContact
                          layout={requestorContactLayout.SECTION}
                          sectionHeader="Financial Professional*"
                        />
                        <Acknowledgement />
                      </IsTruthy>
                      <IsTruthy value={pageNumber === 2}>
                        <Confirmation
                          transactionName={transactionName}
                          policyId={policyId}
                          lineOfBusinessCode={lineOfBusinessCode}
                          producersRequest={producersRequest}
                          agreementData={agreementData}
                          setPageNumber={setPageNumber}
                        />
                      </IsTruthy>
                      <ModalControl
                        pageNumber={pageNumber}
                        onSubmit={onSubmit}
                        shouldDisableSubmit={shouldDisableSubmit}
                        lineOfBusinessCode={lineOfBusinessCode}
                        showLoadingIcon={showLoadingIcon}
                        policyId={policyId}
                        userEmail={userEmail}
                      />
                    </ModalProvider>
                  </Form>
                </div>
              </IsFalsy>
            </IsTruthy>
          </IsTruthy>
        </IsTruthy>
      </LoadingSection>
    </LoadingSection>
  );
};

export default FillableForms;
