import React, { useRef } from "react";

const TableControl = (props) => {
  const {
    filterChanged,
    filterOn,
    searchString,
    searchTable,
    clearSearch,
    showClearIcon,
    showHomeToggle,
    placeHolderText,
  } = props;

  const searchTableInputRef = useRef(null);
  const homeToggle = showHomeToggle && (
    <div className="col-12 col-lg-6 mm-spacing-minor--stacked">
      <div className="custom-control custom-switch justify-content-end">
        <input
          id="search_toggle_input"
          checked={filterOn}
          onChange={filterChanged}
          type="checkbox"
          className="custom-control-input"
          aria-label="Show Policies Not Reported, Terminated and Unknown"
        />
        <label htmlFor="search_toggle_input" className="custom-control-label">
          Show Policies Not Reported, Terminated and Unknown
        </label>
      </div>
    </div>
  );

  const clearIcon = showClearIcon && (
    <button
      id="clear_icon_btn"
      className="btn btn-link"
      type="button"
      onClick={() => {
        clearSearch();
        searchTableInputRef.current.focus();
      }}
    >
      <span
        className="icon-answer-incorrect"
        aria-hidden="true"
        title="Clear Search"
      />
      <span className="sr-only">Clear Search</span>
    </button>
  );

  return (
    <div className="row mm-spacing">
      <div className="col-12 col-lg-6 mm-spacing-minor--stacked">
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            id="search_table_input"
            placeholder={placeHolderText}
            onChange={searchTable}
            value={searchString}
            aria-label={placeHolderText}
            ref={searchTableInputRef}
          />
          <div className="input-group-append">
            {clearIcon}
            <span className="input-group-text" id="search-dynamic">
              <span
                className="icon-search-boulder icon-sm"
                aria-hidden="true"
                title="Search"
              />
              <span className="sr-only">Search</span>
            </span>
          </div>
        </div>
      </div>
      {homeToggle}
    </div>
  );
};
export default React.memo(TableControl);
