import React from "react";
import IsFalsy from "../logic/isFalsy";
import IsTruthy from "../logic/isTruthy";
import CustomComponent from "../logic/customComponent";
import { usePickLoadingProps, errorTypes, loadingTypes } from "./hooks";
// eslint-disable-next-line import/no-cycle
import Header from "../../layout/header";
import Footer from "../../layout/footer";

const PickLoading = (props) => {
  const {
    children,
    unsupportedPolicy,
    request,
    customErrorMessage,
    removeReloadButton,
    addHeaderFooterToError,
    hideStandardErrorMessage,
    reloadAttempts,
    setReloadAttempts,
    disableReloadButton,
  } = props;

  const {
    LoadingComponent,
    ErrorComponent,
    isLoading,
    hasError,
    retryRequest,
    showBackToPolicyButton,
  } = usePickLoadingProps(props);
  return (
    <>
      <IsTruthy value={isLoading || unsupportedPolicy}>
        <CustomComponent
          component={LoadingComponent}
          showBackToPolicyButton={showBackToPolicyButton}
        />
      </IsTruthy>
      <IsTruthy value={hasError}>
        <IsTruthy value={addHeaderFooterToError}>
          <Header />
        </IsTruthy>
        <CustomComponent
          removeReloadButton={removeReloadButton}
          hideStandardErrorMessage={hideStandardErrorMessage}
          reloadAttempts={reloadAttempts}
          setReloadAttempts={setReloadAttempts}
          disableReloadButton={disableReloadButton}
          customErrorMessage={customErrorMessage}
          component={ErrorComponent}
          retryRequest={retryRequest}
          transactionId={request.transactionId}
          showBackToPolicyButton={showBackToPolicyButton}
        />
        <IsTruthy value={addHeaderFooterToError}>
          <Footer />
        </IsTruthy>
      </IsTruthy>
      <IsFalsy value={isLoading || hasError || unsupportedPolicy}>
        {children}
      </IsFalsy>
    </>
  );
};

export { errorTypes, loadingTypes };
export default PickLoading;
