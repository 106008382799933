import React, { useEffect, useState } from "react";
import { useFormContext } from "../../contexts/form";
import IsTruthy from "../common/logic/isTruthy";
import { useCancelModal } from "../common/popup/cancel";
import {
  displayContinueButton,
  getRecipientsContactDetails,
  useCanContinue,
  useFormRecipientsProps,
} from "./hooks";
import { isDefined } from "../../utils/evaluate";

const ModalControl = (props) => {
  const {
    pageNumber,
    onSubmit,
    showLoadingIcon,
    error,
    shouldDisableSubmit,
    shouldDisableContinue = false,
    lineOfBusinessCode,
    transactionName,
    email,
    claimRecipientsContactsCount,
    user,
    isDiOnlyClaim,
  } = props;
  const { onContinue } = useCanContinue(props);

  const { openCancelModal } = useCancelModal({
    lineOfBusinessCode,
    transactionName,
    pageNumber,
  });
  const [className, setClassName] = useState("btn btn-primary");
  const [buttonText, setButtonText] = useState("Submit");
  const { formData } = useFormContext();

  const { recipients } = useFormRecipientsProps({
    claimRecipientsContactsCount,
  });

  const submissionPageNumber = isDiOnlyClaim ? 4 : 5;

  useEffect(() => {
    if (showLoadingIcon) {
      setClassName("btn btn-primary-loading");
      setButtonText("");
    } else {
      setClassName("btn btn-primary");
      setButtonText("Submit");
    }
  }, [showLoadingIcon]);
  return (
    <div className="mm-spacing">
      <IsTruthy value={error} validationFunction={isDefined}>
        <div
          className="alert alert-danger mm-spacing--minor"
          role="alert"
          aria-label={error}
        >
          {error}
        </div>
      </IsTruthy>
      <IsTruthy
        value={{ pageNumber, formData, isDiOnlyClaim }}
        validationFunction={displayContinueButton}
      >
        <button
          id="continue_btn"
          onClick={onContinue}
          type="button"
          className="btn btn-primary"
          disabled={shouldDisableContinue}
        >
          continue
        </button>
      </IsTruthy>
      <IsTruthy
        value={pageNumber}
        validationFunction={(pn) => pn === submissionPageNumber}
      >
        <button
          id="submit_btn"
          type="submit"
          className={className}
          disabled={shouldDisableSubmit}
          onClick={(e) => {
            e.preventDefault();
            onSubmit({
              formDataForSubmission: formData,
              transactionName,
              email,
              recipients: getRecipientsContactDetails(recipients),
              user,
            });
          }}
        >
          {buttonText}
        </button>
      </IsTruthy>
      <IsTruthy value={pageNumber < 6}>
        <button
          id="cancel_btn"
          type="button"
          className="btn btn-secondary"
          onClick={openCancelModal}
        >
          Cancel
        </button>
      </IsTruthy>
    </div>
  );
};
export default ModalControl;
