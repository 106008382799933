export const formFormatting = (formField) => {
  const { value, formatting = "none" } = formField;
  switch (formatting) {
    case formattingTypes.ALPHANUMERIC:
      return value.replace(/[^a-zA-Z0-9]/g, "");
    case formattingTypes.LETTERS:
      return value.replace(/[^A-Za-z]/g, "");
    case formattingTypes.NAMES:
      return value.replace(/[^A-Za-z\-\s'.]/g, "");
    case formattingTypes.NUMBERS:
      return value.replace(/\D/g, "");
    case formattingTypes.ADDRESS:
      // numbers, uppercase letters, lowercase letters, range of special characters for accents, hyphen, apostrophe, period, space
      return value.replace(/[^0-9A-Za-zÀ-ÖØ-öø-ÿ\-'. /\\]/g, "");
    case formattingTypes.ZIPCODE:
      return value.replace(/[^0-9-]/g, "");
    case formattingTypes.PHONE_NUMBER:
      // eslint-disable-next-line no-case-declarations
      return formatToPhone(value);
    case formattingTypes.MONEY: {
      return formatMoney(value);
    }
    default:
      return value;
  }
};

const isModifierKey = (value) => {
  const key = value.keyCode;
  return (
    value.shiftKey === true ||
    key === 35 ||
    key === 36 || // Allow Shift, Home, End
    key === 8 ||
    key === 9 ||
    key === 13 ||
    key === 46 || // Allow Backspace, Tab, Enter, Delete
    (key > 36 && key < 41) || // Allow left, up, right, down
    // Allow Ctrl/Command + A,C,V,X,Z
    ((value.ctrlKey === true || value.metaKey === true) &&
      (key === 65 || key === 67 || key === 86 || key === 88 || key === 90))
  );
};
const formatToPhone = (value) => {
  if (isModifierKey(value)) {
    return " ";
  }

  // I am lazy and don't like to type things more than once
  const input = value.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
  const zip = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);

  if (input.length > 6) {
    return `${zip}-${middle}-${last}`;
  }
  if (input.length > 3) {
    return `${zip}-${middle}`;
  }
  if (input.length > 0) {
    return `${zip}`;
  }
  return input;
};

const formatMoney = (value) => {
  const sections = value.replace(/[^0-9.]/g, "").split(".");
  if (sections[0] !== "0" && sections[0] !== "00") {
    sections[0] = sections[0].replace(/[^0-9]/g, "");
  }

  const normalizedIntegers = Number(
    sections[0].replace(/[^0-9,]/g, "")
  ).toLocaleString("en-US");

  if (sections[1]) {
    return `${normalizedIntegers}.${sections[1]
      .replace(/[^0-9]/g, "")
      .slice(0, 2)}`;
  }
  if (value.indexOf(".") !== -1) {
    return `${normalizedIntegers}.`;
  }
  return normalizedIntegers;
};

export const formattingTypes = {
  ALPHANUMERIC: "ALPHANUMERIC",
  NUMBERS: "NUMBERS",
  ADDRESS: "ADDRESS",
  ZIPCODE: "ZIPCODE",
  PHONE_NUMBER: "PHONE_NUMBER",
  LETTERS: "LETTERS",
  NAMES: "NAMES",
  MONEY: "MONEY",
};
