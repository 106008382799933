import React from "react";
import CheckboxForm from "../../../common/forms/checkboxForm";
import RadioForm from "../../../common/forms/radioForm";
import { InformationPopover } from "../../../common/popovers/informationPopover";
import RequestorContact from "../../../common/requestorContact";
import { requestorContactLayout } from "../../../common/requestorContact/hooks";
import { useFundTransferProps } from "../hooks";

const FundTransfer = (props) => {
  const formData = useFundTransferProps();
  const { marketingName, fundValueAmount } = props;

  return (
    <div>
      <div id="transferFundsFrom" className="mm-spacing-minor">
        <div className="row ">
          <div className="col-9">
            <p className="h3">Transfer From</p>
            <p>
              You’re about to request a transfer of the contract value from the
              current MML Asset Allocation Fund into a new MML Asset Allocation
              Fund under this MassMutual Envision contract while RetirePay is in
              effect. Any Future purchase payments will be allocated to the MML
              Asset Allocation Fund chosen below.
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-10">
          <div className="card--flat">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-4">
                  <dl className="mm-key-value-pair--inverted">
                    <dt id="fund_name_lbl">Fund Name:</dt>
                    <dd id="fund_name_value">{marketingName}</dd>
                  </dl>
                </div>
                <div className="col-lg-4">
                  <dl className="mm-key-value-pair--inverted">
                    <dt id="total_estimated_value_lbl">
                      Total Estimated Value:
                    </dt>
                    <dd id="total_estimated_value_value">{fundValueAmount}</dd>
                  </dl>
                </div>
                <div className="col-lg-4">
                  <dl className="mm-key-value-pair--inverted">
                    <dt id="transfer_percent_lbl">Transfer Percent:</dt>
                    <dd id="transfer_percent_value">
                      100%{" "}
                      <InformationPopover
                        popoverId="transfer_percent_tooltip"
                        text="Currently we only support 100% Transfers."
                      />
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="transferFundsTo" className="mm-spacing">
        <div className="row">
          <div className="col-lg-10">
            <p className="h3">Transfer To*</p>
            <p>
              Only transfers into an MML Asset Allocation Fund are available
              online. If you would like to transfer contract value to funds
              available in the Custom Allocation Program, please complete form{" "}
              <a
                id="form_fr3004_link"
                href="https://fieldnet.massmutual.com/public/ann/pdfs/fr3004.pdf"
                target="_blank"
                rel="noreferrer"
              >
                FR3004 - MassMutual Envision with MassMutual RetirePay Fund
                Transfer / Asset Allocation Change
              </a>
              .
            </p>
            {Object.values(formData).map((fund) => {
              if (
                fund.label !== marketingName &&
                fund.groupName === "transerToRadioGroup"
              ) {
                return <RadioForm key={fund.id} formField={fund} />;
              }
              return null;
            })}
          </div>
        </div>
      </div>
      <div id="requestorContact" className="mm-spacing">
        <RequestorContact layout={requestorContactLayout.SECTION} />
      </div>
      <div id="acknowledgement" className="mm-spacing">
        <p className="h3" id="acknowledgement_lbl">
          Acknowledgement
        </p>
        <CheckboxForm formField={formData.authAcknowledgement} />
        <CheckboxForm formField={formData.formAcknowledgement} />
      </div>
    </div>
  );
};

export default FundTransfer;
