import { isString } from "./string";

const setTitle = (title) => {
  if (typeof title !== "string") {
    throw new Error("Title should be a string");
  }
  document.title = title;
};

const setLocalStorage = ({ name, value }) => {
  localStorage.setItem(name, value);
};

const getLocalStorage = ({ name }) => localStorage.getItem(name);
const getLocalStorageObject = ({ name }) =>
  JSON.parse(getLocalStorage({ name }));
const setSessionStorage = ({ name, value }) => {
  sessionStorage.setItem(name, value);
};

const getSessionStorage = ({ name }) => sessionStorage.getItem(name);

const getSessionStorageObject = ({ name }) =>
  JSON.parse(getSessionStorage({ name }));

const setSessionStorageObject = ({ name, value }) => {
  const stringifiedValue = isString(value) ? value : JSON.stringify(value);
  return setSessionStorage({ name, value: stringifiedValue });
};

const setLocalStorageObject = ({ name, value }) => {
  const stringifiedValue = isString(value) ? value : JSON.stringify(value);
  return setLocalStorage({ name, value: stringifiedValue });
};

const getUrlHash = () => {
  const urlHash = window?.location?.hash || "";
  return urlHash.length > 1 ? urlHash.substring(1) : "";
};

const getUrl = () => {
  const urlValue = window?.location?.href || "";
  return urlValue.length > 1 ? urlValue.substring(1) : "";
};

const scrollToElement = (elementId) => {
  const topOfElement = document.getElementById(elementId);
  topOfElement?.scrollIntoView();
};

const redirectToUrl = (urlValue, target = "_self") => {
  window.open(urlValue, target, "noreferrer");
};

export {
  setTitle,
  getUrlHash,
  getUrl,
  redirectToUrl,
  setLocalStorage,
  setLocalStorageObject,
  getLocalStorage,
  getLocalStorageObject,
  scrollToElement,
  setSessionStorage,
  getSessionStorage,
  getSessionStorageObject,
  setSessionStorageObject,
};
