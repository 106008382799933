import React from "react";
import { useInputFormProps } from "./hooks";

const Input = (props) => {
  const {
    formField: {
      id,
      name,
      value,
      placeholder = "",
      ariaLabel,
      autoComplete = "off",
      maxLength,
      disabled = false,
      hidden = false,
    },
    useHook = useInputFormProps,
  } = props;

  const { onChange, onPaste, className, onBlur } = useHook(props);

  return (
    <input
      id={`input-${id}`}
      name={name}
      className={className}
      type="text"
      autoComplete={autoComplete}
      placeholder={placeholder}
      aria-label={ariaLabel}
      value={value}
      onChange={onChange}
      maxLength={maxLength}
      disabled={disabled}
      hidden={hidden}
      onPaste={onPaste}
      onBlur={onBlur}
      data-testid={id}
    />
  );
};

export default React.memo(Input);
