// eslint-disable-next-line import/prefer-default-export
export const processContentData = (props) => {
  const apiData = props?.response?.data;
  const documents = apiData?.documentListResponse?.documents || [];
  const documentsArray = documents.map((doc) => {
    const {
      documentType = "",
      billType = "",
      effectiveDate = "",
      mimeType = "",
      objectStore = "",
      guid = "",
      displayName = "",
      scanDate = "",
    } = doc;

    return {
      documentType,
      billType,
      effectiveDate,
      mimeType,
      objectStore,
      guid,
      displayName,
      scanDate,
    };
  });

  return documentsArray;
};
