import produce from "immer";

const types = {
  STARTED_REQUEST: "STARTED_REQUEST",
  SUCCESSFUL_REQUEST: "SUCCESSFUL_REQUEST",
  FAILED_REQUEST: "FAILED_REQUEST",
  FINISHED_REQUEST: "FINISHED_REQUEST",
  START_REQUEST: "START_REQUEST",
  RESET: "RESET",
};

const initialApiState = {
  isLoading: false,
  data: "",
  transactionId: -1,
  error: null,
  shouldRequest: false,
  paramsForSelectors: {},
};

// eslint-disable-next-line default-param-last
const reducer = produce((state = initialApiState, action) => {
  const { type } = action;
  // add `, apiRequest, ...props` to descructured action object and uncomment the next line
  // console.log(`${apiRequest} type: `, type, props);
  switch (type) {
    case types.STARTED_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.FINISHED_REQUEST:
      return {
        ...state,
        isLoading: false,
        shouldRequest: false,
      };
    case types.SUCCESSFUL_REQUEST:
      return {
        ...state,
        data: action.data,
        transactionId: action.transactionId,
        error: null,
      };
    case types.FAILED_REQUEST:
      return {
        ...state,
        error: action.error,
        data: action.data,
        transactionId: action.transactionId,
      };
    case types.START_REQUEST:
      return {
        ...state,
        shouldRequest: true,
      };
    case types.RESET:
      return {
        ...action.newState,
      };
    default:
      return state;
  }
});

export { types, initialApiState, reducer };
