import {
  compareArrayOfStrings,
  compareStrings,
} from "../../../../../utils/string";

export const cdscSchedules = {
  LIFETRUST: {
    text: "Each Purchase Payment has its own schedule. Contingent Deferred Sales Charge (CDSC) is based on the number of years since each purchase payment was accepted.",
    kindCodes: ["OMCMVA", "MVA001", "NONMVA", "OMCNON"],
    schedule: ["7%", "6%", "5%", "4%", "3%", "2%", "1%"],
    startingYear: 1,
  },
  PANORAMA_PREMIER: {
    text: "Each Purchase Payment has its own schedule. Contingent Deferred Sales Charge (CDSC) is based on the number of years since each purchase payment was accepted.",
    kindCodes: ["NYPIRA", "NYPPRE", "PANWAN", "PANWAI", "PANIRA", "PANPRE"],
    schedule: ["7%", "6%", "5%", "4%", "3%", "2%", "1%"],
    startingYear: 1,
  },
  ODYSSEY_O5: {
    kindCodes: ["ODNY05", "ODYS05"],
    text: "",
    schedule: ["7%", "7%", "6%", "5%", "4%"],
    startingYear: 1,
  },
  ODYSSEY_07: {
    text: "",
    kindCodes: ["ODNY07", "ODYS07"],
    schedule: ["7%", "7%", "6%", "5.5%", "4.5%", "3.5%", "2%"],
    startingYear: 1,
  },
  ODYSSEY_PLUS_06: {
    text: "",
    kindCodes: ["ODPL06"],
    schedule: ["7%", "7%", "6%", "5%", "4%", "2%"],
    startingYear: 1,
  },
  ODYSSEY_PLUS_08: {
    text: "",
    kindCodes: ["ODPL08"],
    schedule: ["7%", "7%", "6%", "5.5%", "4.5%", "3.5%", "2%", "1%"],
    startingYear: 1,
  },
  ODYSSEY_SELECT_05: {
    text: "",
    kindCodes: ["ODSL05"],
    schedule: ["8%", "7%", "6%", "5%", "4%"],
    startingYear: 1,
  },
  ODYSSEY_SELECT_07: {
    text: "",
    kindCodes: ["ODSL07"],
    schedule: ["8%", "8%", "7%", "6%", "5%", "4%", "3%"],
    startingYear: 1,
  },
  ODYSSEY_SELECT_09: {
    text: "",
    kindCodes: ["ODSL09"],
    schedule: ["8%", "8%", "7%", "6%", "5%", "4%", "3%", "2%", "1%"],
    startingYear: 1,
  },
  PANORAMA: {
    text: "",
    kindCodes: [
      "VASFPA",
      "VASFPB",
      "VASFPN",
      "VASOFA",
      "VASOFB",
      "VASOPE",
      "VASPDF",
      "VASSPE",
      "VASFPE",
    ],
    schedule: ["5%", "5%", "5%", "5%", "5%", "4%", "4%", "4%", "4%", "4%"],
    startingYear: 1,
  },
  EVOLUTION: {
    text: "",
    kindCodes: ["EVOLUT"],
    schedules: {
      EVN_4: ["7%", "6%", "6%", "4%"],
      EVN_5: ["7%", "6%", "6%", "4%", "3%"],
    },
    startingYear: 1,
  },
  TRANSITIONS: {
    text: "",
    kindCodes: ["TRAPKG", "TRAPKN"],
    schedule: ["7%", "7%", "7%", "6%", "5%", "4%", "3%"],
    startingYear: 1,
  },
  TRANSITIONS_CUSTOM_AND_SELECT: {
    text: "",
    kindCodes: ["TRCAFE", "TRCAFN", "TRANSL"],
    schedules: {
      TC_5: ["7%", "7%", "7%", "6%", "5%"],
      TC_7: ["7%", "7%", "7%", "6%", "5%", "4%", "3%"],
      TC_9: ["7%", "7%", "7%", "6%", "5%", "4%", "3%", "2%", "1%"],
    },
    startingYear: 1,
  },
  ARTISTRY: {
    text: "",
    kindCodes: ["ARTIST", "NYARTI"],
    schedule: ["8%", "8%", "7%", "6%", "5%", "4%", "3%", "2%", "1%"],
    startingYear: 1,
  },
  FLEX_EXTRA_SINGLE_PREMIUM: {
    text: "",
    kindCodes: ["SPPVA4", "81600", "81500", "SPPVA3"],
    schedule: ["5%", "4%", "3%", "2%", "1%"],
    startingYear: 1,
  },
  FLEX_EXTRA_FLEXIBLE_PREMIUM: {
    text: "",
    kindCodes: ["FPPVA4", "FPPVA3"],
    schedule: ["8%", "8%", "7%", "6%", "5%", "4%", "3%", "2%", "1%"],
    startingYear: 1,
  },
  CM_WINDOWS: {
    text: "",
    kindCodes: ["SPDA", "SPDA10", "SPDN", "SPDN10"],
    schedule: ["8%", "7%", "6%", "5%", "4%", "3%", "2%", "1%"],
    startingYear: 1,
  },
  RETIRE_EASE: {
    text: "Withdrawals are not allowed in the first year and are only allowed if there is a Period Certain or Life with Period Certain payout option. Withdrawals are limited to one per contract year and will reduce future period certain payments.",
    kindCodes: ["RET012"],
    schedule: ["N/A", "8%", "7%", "6%", "5%", "4%", "3%", "2%", "1%"],
    startingYear: 1,
  },
  FOUNDATION_SINGLE: {
    text: "",
    kindCodes: [
      "QSPFA1",
      "83600",
      "NQSPFA5",
      "83500",
      "NQSPFA3",
      "QSPFA5",
      "QSPFA3",
      "NQSPFA1",
    ],
    schedule: ["8%", "7%", "6%", "5%", "4%", "3%", "2%", "1%"],
    startingYear: 1,
  },
  FOUNDATION_FLEXIBLE: {
    text: "",
    kindCodes: ["QFPFA", "82500", "82600", "NQFPFA"],
    schedule: ["8%", "8%", "7%", "6%", "5%", "4%", "3%", "2%", "1%"],
    startingYear: 1,
  },
  STABLE_VOYAGE: {
    text: "",
    kindCodes: ["STABLV"],
    schedules: {
      GUARANTEE_3: ["7%", "7%", "7%"],
      GUARANTEE_4: ["7%", "7%", "7%", "6%"],
      GUARANTEE_5: ["7%", "7%", "7%", "6%", "5%"],
      GUARANTEE_7: ["7%", "7%", "7%", "6%", "5%", "4%", "3%"],
      GUARANTEE_9: ["7%", "7%", "7%", "6%", "5%", "4%", "3%", "2%", "1%"],
      RENEWAL_3: ["6%", "6%", "6%"],
      RENEWAL_4: ["6%", "6%", "6%", "5%"],
      RENEWAL_5: ["6%", "6%", "6%", "5%", "4%"],
      RENEWAL_7: ["6%", "6%", "6%", "5%", "4%", "3%", "2%"],
      RENEWAL_9: ["6%", "6%", "6%", "5%", "4%", "3%", "2%", "1%", "1%"],
    },
    startingYear: 1,
  },
};

const transitionsCustomAndSelectKindCodes = ["TRCAFE", "TRCAFN", "TRANSL"];

export const useDeferredSalesChargeScheduleProps = (props) => {
  const { annuityData = {} } = props;
  const { kindCode } = props.agreementData;
  const { issueDate, guarPeriodStartDate } = annuityData;
  const { surrChargeSchedule } = annuityData;

  if (compareStrings(kindCode, "STABLV")) {
    const foundFund = annuityData.funds?.fund?.find((fund) => fund.balance > 0);
    const fundsDisplayName = foundFund?.displayName || "";

    const displayRenewalSchedule = issueDate !== guarPeriodStartDate;

    if (!displayRenewalSchedule) {
      switch (fundsDisplayName) {
        case "3 Year Guarantee Period":
          return {
            ...cdscSchedules.STABLE_VOYAGE,
            schedule: cdscSchedules.STABLE_VOYAGE.schedules.GUARANTEE_3,
          };
        case "4 Year Guarantee Period":
          return {
            ...cdscSchedules.STABLE_VOYAGE,
            schedule: cdscSchedules.STABLE_VOYAGE.schedules.GUARANTEE_4,
          };
        case "5 Year Guarantee Period":
          return {
            ...cdscSchedules.STABLE_VOYAGE,
            schedule: cdscSchedules.STABLE_VOYAGE.schedules.GUARANTEE_5,
          };
        case "7 Year Guarantee Period":
          return {
            ...cdscSchedules.STABLE_VOYAGE,
            schedule: cdscSchedules.STABLE_VOYAGE.schedules.GUARANTEE_7,
          };
        case "9 Year Guarantee Period":
          return {
            ...cdscSchedules.STABLE_VOYAGE,
            schedule: cdscSchedules.STABLE_VOYAGE.schedules.GUARANTEE_9,
          };
        default:
          return {};
      }
    }

    if (displayRenewalSchedule) {
      switch (fundsDisplayName) {
        case "3 Year Guarantee Period":
          return {
            ...cdscSchedules.STABLE_VOYAGE,
            schedule: cdscSchedules.STABLE_VOYAGE.schedules.RENEWAL_3,
          };
        case "4 Year Guarantee Period":
          return {
            ...cdscSchedules.STABLE_VOYAGE,
            schedule: cdscSchedules.STABLE_VOYAGE.schedules.RENEWAL_4,
          };
        case "5 Year Guarantee Period":
          return {
            ...cdscSchedules.STABLE_VOYAGE,
            schedule: cdscSchedules.STABLE_VOYAGE.schedules.RENEWAL_5,
          };
        case "7 Year Guarantee Period":
          return {
            ...cdscSchedules.STABLE_VOYAGE,
            schedule: cdscSchedules.STABLE_VOYAGE.schedules.RENEWAL_7,
          };
        case "9 Year Guarantee Period":
          return {
            ...cdscSchedules.STABLE_VOYAGE,
            schedule: cdscSchedules.STABLE_VOYAGE.schedules.RENEWAL_9,
          };
        default:
          return {};
      }
    }
  }

  if (compareStrings(kindCode, "EVOLUT")) {
    switch (surrChargeSchedule) {
      case "5 Years":
        return {
          ...cdscSchedules.EVOLUTION,
          schedule: cdscSchedules.EVOLUTION.schedules.EVN_5,
        };
      case "4 Years":
        return {
          ...cdscSchedules.EVOLUTION,
          schedule: cdscSchedules.EVOLUTION.schedules.EVN_4,
        };
      default:
        return {};
    }
  }

  if (compareArrayOfStrings(transitionsCustomAndSelectKindCodes, kindCode)) {
    switch (surrChargeSchedule) {
      case "5 Years":
        return {
          ...cdscSchedules.TRANSITIONS_CUSTOM_AND_SELECT,
          schedule: cdscSchedules.TRANSITIONS_CUSTOM_AND_SELECT.schedules.TC_5,
        };
      case "7 Years":
        return {
          ...cdscSchedules.TRANSITIONS_CUSTOM_AND_SELECT,
          schedule: cdscSchedules.TRANSITIONS_CUSTOM_AND_SELECT.schedules.TC_7,
        };
      case "9 Years":
        return {
          ...cdscSchedules.TRANSITIONS_CUSTOM_AND_SELECT,
          schedule: cdscSchedules.TRANSITIONS_CUSTOM_AND_SELECT.schedules.TC_9,
        };
      default:
        return {};
    }
  }

  return (
    Object.values(cdscSchedules).find((sched) =>
      compareArrayOfStrings(sched.kindCodes, kindCode)
    ) || {}
  );
};
