import React from "react";
import { Row, Col } from "react-bootstrap";
import CheckboxForm from "../../../common/forms/checkboxForm";

const Choices = (props) => {
  const { formData } = props;
  const checkBoxFields = [];
  Object.keys(formData).forEach((key) => {
    if (formData[key]?.type === "checkbox") {
      checkBoxFields.push(formData?.[key]);
    }
  });

  const showBoxes = checkBoxFields.filter((element) => element.show);

  const CheckBoxes = showBoxes.map((element) => (
    <Col md={6} key={element.id}>
      <CheckboxForm formField={element} />
    </Col>
  ));

  return (
    <Row>
      <Col md={8}>
        <Row>{CheckBoxes}</Row>
      </Col>
    </Row>
  );
};

export default Choices;
