import {
  annuityDisplayTitles,
  supportedAnnuityRiderNames,
  supportedAnnuityRiderTypes,
} from "../../../../utils/translate";
import {
  compareArrayOfStrings,
  compareStrings,
} from "../../../../utils/string";
import {
  MassMutualRetirePayDescription,
  MassMutualRetirePayPrintDescription,
  ReturnofPurchasePaymentDeathBenefitDescription,
  ReturnofPurchasePaymentDeathBenefitPrintDescription,
  NursingHomeandHospitalWithdrawalBenefitRiderDescription,
  NursingHomeandHospitalWithdrawalBenefitRiderPrintDescription,
  TerminalIllnessWithdrawalBenefitRiderDescription,
  TerminalIllnessWithdrawalBenefitRiderPrintDescription,
} from "../../../../constants/ui";
import {
  formatDate,
  isAfterDate,
  isBeforeDate,
  addYeartoDate,
  addDaystoDate,
  isleapYear,
} from "../../../../utils/dates";
import {
  formatPercentage,
  formatMoney,
  formatRiderStatus,
  formatContactUsNumber,
  formatContactUsDateTime,
  formatSupTagIfPresent,
  extractSupTagIfPresent,
} from "../../../../utils/format";
import {
  isDefined,
  isNullOrUndefined,
  isDate,
  isObject,
} from "../../../../utils/evaluate";
import { contactUSDetails } from "../../../../data/contactUsDetails";
import {
  isNonEnvisionAnnuity,
  isNonGLWBRiders,
  isNonGLWBRidersByTitle,
  isRetireEaseContract,
} from "../../../../utils/policies";
import RiderModal from "./riderModal";
import { useModalState } from "../../../../contexts/modal";
import RiderModalCell from "./riderModalCell";

export const useRidersProps = (props) => {
  const annuityRiders = getAnnuityRiders(props);
  const formattedContactUsNumber = formatContactUsNumber(
    props?.agreementRequest?.data?.contactUsNumber,
    contactUSDetails.DEFAULT.number
  );
  const formattedContactUsTime = formatContactUsDateTime(
    props?.agreementRequest?.data?.contactUsTime,
    contactUSDetails.DEFAULT.time
  );

  const isRetireEase = isRetireEaseContract(props?.agreementRequest?.data);
  const columns = getRiderColumns(isRetireEase);

  const rows = getRiderRows(annuityRiders, isRetireEase);

  const { showAnnuityModal } = useAnnuityRiderModal(props);
  return {
    data: {
      annuityRiders,
      formattedContactUsNumber,
      formattedContactUsTime,
      isRetireEase,
      table: { columns, rows },
      showAnnuityModal,
    },
  };
};
const GLWBRiderProductTypeCodes = ["GLSSTU", "GLSQLB", "GLJSTU", "GLJQLB"];
const AnnuityRiderProductTypeCodeArray = [
  ...GLWBRiderProductTypeCodes,
  "GMDBRP",
  "NHWRDR",
  "TIWRDR",
];

const checkSupportedRiderNames = (riderName, type) =>
  compareArrayOfStrings(Object.values(supportedAnnuityRiderNames), riderName) ||
  compareArrayOfStrings(Object.values(supportedAnnuityRiderTypes), type);

export const getAnnuityRiders = (props) => {
  const isNonEnvisionAnnuityContract = isNonEnvisionAnnuity(
    props?.agreementRequest
  );
  const isRetireEase = isRetireEaseContract(props?.agreementRequest?.data);

  if (isNonEnvisionAnnuityContract) {
    const {
      riderInfo,
      maturityDate,
      productPrefix,
      issueDate: contractIssueDate,
    } = props?.annuityRequest?.data || {};

    if (isRetireEase) {
      const riders = riderInfo.map((rider) => {
        const { riderName } = rider;

        return {
          riderName,
          issueDate: contractIssueDate,
        };
      });
      return riders;
    }

    const filteredAnnuityRiders = riderInfo.filter((rider) => {
      const { riderName = "", type = "" } = rider;
      return checkSupportedRiderNames(riderName, type);
    });
    const findAnnuityRiders = filteredAnnuityRiders.map((rider) => {
      const { riderIssueDate, riderName, type, benefitAmt } = rider;

      let effectiveDate = riderIssueDate;
      if (Date.parse(effectiveDate) < Date.parse(contractIssueDate)) {
        effectiveDate = contractIssueDate;
      }

      const displayNamesAssignedByType = {
        GMWB: "Guaranteed Minimum Withdrawal Benefit",
        LIP: "Lifetime Income Protector",
        GMIB: "Guaranteed Minimum Income Benefit",
        DB: rider?.riderName,
      };

      const displayNamesAssignedByName = [
        {
          names: [
            supportedAnnuityRiderNames.GMAB_10,
            supportedAnnuityRiderNames.GMAB_20,
          ],
          displayName: "Guaranteed Minimum Accumulation Benefit",
        },
      ];

      let displayName = "";
      if (
        compareArrayOfStrings(Object.keys(displayNamesAssignedByType), type)
      ) {
        displayName = displayNamesAssignedByType[type];
      }

      const displayNameObject = displayNamesAssignedByName.find((item) =>
        compareArrayOfStrings(item.names, riderName)
      );
      if (isObject(displayNameObject)) {
        displayName = displayNameObject.displayName;
      }

      const formattedBenefitAmt = formatMoney(benefitAmt, "-");

      return {
        status: "Active",
        effectiveDate: formatDate(effectiveDate, "", "-"),
        termDate: formatDate(maturityDate, "", "-"),
        eligibiltyDate: formatDate(effectiveDate, "", "-"),
        productTypeCode: type,
        productPrefix,
        title: riderName,
        benefitAmt: formattedBenefitAmt,
        displayName,
      };
    });

    const ridersSortedByEffectiveDate = findAnnuityRiders.sort(
      (a, b) => new Date(b.effectiveDate) - new Date(a.effectiveDate)
    );

    return ridersSortedByEffectiveDate;
  }

  const { agreementCoverages } = props?.agreementRequest?.data || {};
  const filteredCoverages = agreementCoverages.filter((coverage) => {
    const { productTypeCode = "" } = coverage;
    return compareArrayOfStrings(
      AnnuityRiderProductTypeCodeArray,
      productTypeCode
    );
  });
  const findRiders = filteredCoverages.map((coverage, index) => {
    const {
      productTypeCode,
      asOfDate,
      status,
      coverageWithdrawalEligiblityDate,
      coverageWithdrawalStartDate,
      effectiveDate,
      termDate,
      youngestCoveredPersonName,
      coverageNextAnnuityChargePercent,
      coverageBenefitAmount,
      productEnterpriseName,
      coverageContractWithdrawalRate,
      coverageAnnuityContractAmount,
      coverageRemainingAnnualLifetimeBenefitAmount,
      coverageNextStepUpDate,
    } = coverage;

    return {
      id: index,
      title: compareArrayOfStrings(GLWBRiderProductTypeCodes, productTypeCode)
        ? "MassMutual RetirePay℠"
        : coverage.productEnterpriseName,
      riderDescription:
        getRiderDescriptionandSortOrder(productTypeCode).riderDescription,
      riderPrintDescription:
        getRiderDescriptionandSortOrder(productTypeCode).riderPrintDescription,
      sortOrder: getRiderDescriptionandSortOrder(productTypeCode).sortOrder,
      productTypeCode: compareArrayOfStrings(
        GLWBRiderProductTypeCodes,
        productTypeCode
      )
        ? "GLWBRiders"
        : productTypeCode,
      coverageWithdrawalEligiblityDate: isDefined(
        coverageWithdrawalEligiblityDate
      )
        ? formatDate(coverageWithdrawalEligiblityDate)
        : "-",
      coverageWithdrawalStartDate,
      formattedcoverageWithdrawalStartDate: isDefined(
        coverageWithdrawalStartDate
      )
        ? formatDate(coverageWithdrawalStartDate)
        : "-",
      asOfDate: isDefined(asOfDate) ? formatDate(asOfDate) : "-",
      status: isDefined(status) ? formatRiderStatus(status) : "-",
      effectiveDate: isDefined(effectiveDate) ? formatDate(effectiveDate) : "-",
      termDate: isDefined(termDate) ? formatDate(termDate) : "-",
      youngestCoveredPersonName: isDefined(youngestCoveredPersonName)
        ? youngestCoveredPersonName
        : "-",
      coverageNextAnnuityChargePercent: isDefined(
        coverageNextAnnuityChargePercent
      )
        ? formatPercentage({
            rate: coverageNextAnnuityChargePercent,
            decimal: 2,
            maxDecimalPlaces: 2,
          })
        : "-",
      coverageBenefitAmount: isDefined(coverageBenefitAmount)
        ? formatMoney(coverageBenefitAmount)
        : "-",
      productEnterpriseName: isDefined(productEnterpriseName)
        ? productEnterpriseName
        : "-",
      coverageContractWithdrawalRate: isDefined(coverageContractWithdrawalRate)
        ? formatPercentage({
            rate: coverageContractWithdrawalRate,
            decimal: 2,
            maxDecimalPlaces: 2,
          })
        : "-",
      coverageRemainingAnnualLifetimeBenefitAmount: isDefined(
        coverageRemainingAnnualLifetimeBenefitAmount
      )
        ? coverageRemainingAnnualLifetimeBenefitAmount
        : "-",
      formattedcoverageRemainingAnnualLifetimeBenefitAmount: isDefined(
        coverageRemainingAnnualLifetimeBenefitAmount
      )
        ? formatMoney(coverageRemainingAnnualLifetimeBenefitAmount)
        : "-",
      coverageAnnuityContractAmount: isDefined(coverageAnnuityContractAmount)
        ? formatMoney(coverageAnnuityContractAmount)
        : "-",
      coverageNextStepUpDate: isDefined(coverageNextStepUpDate)
        ? formatDate(coverageNextStepUpDate)
        : "-",
    };
  });
  const sortedRiders = findRiders.sort((a, b) => a.sortOrder - b.sortOrder);
  return sortedRiders;
};

export const getRiderDescriptionandSortOrder = (productTypeCode) => {
  const riderData = {
    riderDescription: "",
    riderPrintDescription: "",
    sortOrder: "",
  };
  if (compareArrayOfStrings(GLWBRiderProductTypeCodes, productTypeCode)) {
    riderData.riderDescription = MassMutualRetirePayDescription;
    riderData.riderPrintDescription = MassMutualRetirePayPrintDescription;
    riderData.sortOrder = 1;
  } else if (compareStrings("GMDBRP", productTypeCode)) {
    riderData.riderDescription = ReturnofPurchasePaymentDeathBenefitDescription;
    riderData.riderPrintDescription =
      ReturnofPurchasePaymentDeathBenefitPrintDescription;
    riderData.sortOrder = 4;
  } else if (compareStrings("NHWRDR", productTypeCode)) {
    riderData.riderDescription =
      NursingHomeandHospitalWithdrawalBenefitRiderDescription;
    riderData.riderPrintDescription =
      NursingHomeandHospitalWithdrawalBenefitRiderPrintDescription;
    riderData.sortOrder = 2;
  } else if (compareStrings("TIWRDR", productTypeCode)) {
    riderData.riderDescription =
      TerminalIllnessWithdrawalBenefitRiderDescription;
    riderData.riderPrintDescription =
      TerminalIllnessWithdrawalBenefitRiderPrintDescription;
    riderData.sortOrder = 3;
  }
  return riderData;
};

const GLWBRiderPhases = {
  EligibleNotTaken: "ENT",
  NotEligible: "NE",
  EligibleTaken: "ET",
};
export const useGLWBRidersProps = (props) => {
  const {
    riderDescription,
    riderPrintDescription,
    coverageWithdrawalEligiblityDate,
    asOfDate,
    status,
    effectiveDate,
    termDate,
    youngestCoveredPersonName,
    coverageNextAnnuityChargePercent,
    coverageBenefitAmount,
    productEnterpriseName,
    coverageWithdrawalStartDate,
    formattedcoverageWithdrawalStartDate,
    coverageContractWithdrawalRate,
    coverageRemainingAnnualLifetimeBenefitAmount,
    formattedcoverageRemainingAnnualLifetimeBenefitAmount,
    coverageAnnuityContractAmount,
    coverageNextStepUpDate,
  } = props.riderValue;
  const isENTPhase = calculatePhaseforGLWBRiders(
    coverageWithdrawalEligiblityDate,
    coverageWithdrawalStartDate,
    coverageRemainingAnnualLifetimeBenefitAmount
  );
  return {
    data: {
      riderDescription,
      riderPrintDescription,
      asOfDate,
      status,
      effectiveDate,
      termDate,
      youngestCoveredPersonName,
      coverageNextAnnuityChargePercent,
      coverageBenefitAmount,
      coverageWithdrawalEligiblityDate,
      formattedcoverageWithdrawalStartDate,
      productEnterpriseName,
      isENTPhase,
      GLWBRiderPhases,
      coverageContractWithdrawalRate,
      formattedcoverageRemainingAnnualLifetimeBenefitAmount,
      coverageAnnuityContractAmount,
      coverageNextStepUpDate,
    },
  };
};
export const calculatePhaseforGLWBRiders = (
  coverageWithdrawalEligiblityDate,
  coverageWithdrawalStartDate,
  coverageRemainingAnnualLifetimeBenefitAmount
) => {
  let currentPhase = GLWBRiderPhases.NotEligible;

  const isFutureWithDrawalEligibityDate = isAfterDate(
    coverageWithdrawalEligiblityDate,
    new Date()
  );
  const isUndefinedorInfiniteWithdrawalEligibilityDate = !!(
    compareStrings(coverageWithdrawalEligiblityDate, "9999-12-31") ||
    isNullOrUndefined(coverageWithdrawalEligiblityDate)
  );

  const isPastWithDrawalEligibityDate = isBeforeDate(
    coverageWithdrawalEligiblityDate,
    new Date()
  );
  const isUndefinedorInfiniteWithdrawalStartDate = !!(
    compareStrings(coverageWithdrawalStartDate, "9999-12-31") ||
    isNullOrUndefined(coverageWithdrawalStartDate)
  );
  const isValidWithdrawalStartDate =
    isDate(new Date(coverageWithdrawalStartDate)) &&
    !compareStrings(coverageWithdrawalStartDate, "9999-12-31") &&
    coverageRemainingAnnualLifetimeBenefitAmount > 0;

  if (isValidWithdrawalStartDate) {
    currentPhase = GLWBRiderPhases.EligibleTaken;
  } else if (
    isPastWithDrawalEligibityDate &&
    isUndefinedorInfiniteWithdrawalStartDate
  ) {
    currentPhase = GLWBRiderPhases.EligibleNotTaken;
  } else if (
    isFutureWithDrawalEligibityDate &&
    isUndefinedorInfiniteWithdrawalEligibilityDate
  ) {
    currentPhase = GLWBRiderPhases.NotEligible;
  }
  return currentPhase;
};

export const useNonGLWBRidersProps = (props) => {
  const {
    riderDescription,
    riderPrintDescription,
    status,
    effectiveDate,
    termDate,
    productTypeCode,
    title,
    benefitAmt,
  } = props.riderValue;

  let eligibiltyDate =
    isDefined(effectiveDate) && !compareStrings(effectiveDate, "-")
      ? addYearstoEffectiveDate(effectiveDate)
      : "-";
  if (compareStrings("DB", productTypeCode)) {
    eligibiltyDate = effectiveDate;
  }

  const statusAndEffectiveDateSplitTitles = [
    supportedAnnuityRiderNames.EQUALIZER_BENEFIT,
    supportedAnnuityRiderNames.E_DOCUMENTS,
    supportedAnnuityRiderNames.GMAB_10,
    supportedAnnuityRiderNames.GMAB_20,
    supportedAnnuityRiderNames.BASIC_DEATH,
    supportedAnnuityRiderNames.BASIC_DEATH_COMBO,
    supportedAnnuityRiderNames.BASIC_DEATH_3YR_RESET,
    supportedAnnuityRiderNames.BASIC_DEATH_ROLLUP,
    supportedAnnuityRiderNames.BASIC_DEATH_ANNUAL_RATCHET,
    supportedAnnuityRiderNames.BASIC_DEATH_CONTRACT_VALUE,
    supportedAnnuityRiderNames.RATCHET_DB,
  ];
  const statusAndEffectiveDateSplitTypes = [
    "GMWB",
    "LIP",
    "GMIB",
    "PPB",
    "EEB",
  ];
  const typesDisplayingRiderVersion = ["GMWB", "LIP", "GMIB"];

  const namesDisplayingRiderVersion = [
    supportedAnnuityRiderNames.GMAB_10,
    supportedAnnuityRiderNames.GMAB_20,
    supportedAnnuityRiderNames.BASIC_DEATH,
    supportedAnnuityRiderNames.BASIC_DEATH_COMBO,
    supportedAnnuityRiderNames.BASIC_DEATH_3YR_RESET,
    supportedAnnuityRiderNames.BASIC_DEATH_ROLLUP,
    supportedAnnuityRiderNames.BASIC_DEATH_ANNUAL_RATCHET,
    supportedAnnuityRiderNames.BASIC_DEATH_CONTRACT_VALUE,
  ];

  const displayRiderVersion =
    compareArrayOfStrings(typesDisplayingRiderVersion, productTypeCode) ||
    compareArrayOfStrings(namesDisplayingRiderVersion, title);

  const typesDisplayingRiderValue = ["GMWB", "GMIB", "PPB"];
  const displayRiderValue = compareArrayOfStrings(
    typesDisplayingRiderValue,
    productTypeCode
  );

  const statusAndEffectiveDateSplit =
    compareArrayOfStrings(statusAndEffectiveDateSplitTitles, title) ||
    compareArrayOfStrings(statusAndEffectiveDateSplitTypes, productTypeCode);

  return {
    data: {
      riderDescription,
      riderPrintDescription,
      status,
      effectiveDate,
      termDate,
      eligibiltyDate,
      productTypeCode,
      benefitAmt,
      displayRiderVersion,
      displayRiderValue,
      statusAndEffectiveDateSplit,
      title,
    },
  };
};

export const addYearstoEffectiveDate = (effectiveDate) => {
  let result = new Date();
  const isFeb29OfLeapYear =
    new Date(effectiveDate).getMonth() === 1 &&
    new Date(effectiveDate).getDate() === 29 &&
    isleapYear(effectiveDate);

  if (isFeb29OfLeapYear) {
    result = addYeartoDate(effectiveDate, 1);
    result = addDaystoDate(result, 1);
  } else {
    result = addYeartoDate(effectiveDate, 1);
  }
  return formatDate(result.toString());
};

export const getRiderRows = (annuityRiders, isRetireEase) =>
  annuityRiders.map((rider, index) => {
    if (isRetireEase) {
      const { riderName, issueDate } = rider;
      return {
        id: index,
        riderName,
        issueDate: formatDate(issueDate) || "-",
        benefitValue: "Not Applicable",
      };
    }

    const { effectiveDate, title, displayName } = rider;
    const riderTitle = isDefined(displayName)
      ? extractSupTagIfPresent(displayName)
      : extractSupTagIfPresent(title);
    return {
      id: index,
      riderName: riderTitle,
      issueDate: formatDate(effectiveDate) || "-",
      ...rider,
      status: "Active",
      title,
      displayName,
    };
  });

export const getAnnuityRiderTitle = (riderValue) =>
  isDefined(riderValue.displayName)
    ? formatSupTagIfPresent(riderValue.displayName)
    : formatSupTagIfPresent(riderValue.title);

export const useAnnuityRiderModal = () => {
  const { openModal } = useModalState();

  const showAnnuityModal = (riderValue) => () => {
    const {
      effectiveDate,
      title,
      termDate,
      eligibiltyDate,
      benefitAmt,
      displayName,
    } = riderValue;

    const riderVersion = formatSupTagIfPresent(title);
    const riderTitle = isDefined(displayName)
      ? extractSupTagIfPresent(displayName)
      : extractSupTagIfPresent(title);

    let eligibilityDateValue = "";
    if (
      isNonGLWBRiders(riderValue.productTypeCode) ||
      isNonGLWBRiders(riderValue.productPrefix) ||
      isNonGLWBRidersByTitle(riderValue.title)
    ) {
      eligibilityDateValue =
        isDefined(effectiveDate) && !compareStrings(effectiveDate, "-")
          ? addYearstoEffectiveDate(effectiveDate)
          : "-";
      if (compareStrings("DB", riderValue.productTypeCode)) {
        eligibilityDateValue = effectiveDate;
      }
    } else {
      eligibilityDateValue = eligibiltyDate;
    }

    const annuityModalProps = {
      Component: RiderModal,
      componentProps: {
        effectiveDate,
        termDate,
        eligibilityDate: eligibilityDateValue,
        displayName,
        benefitAmt,
        riderVersion,
        title,
      },
      useHook: useRiderModalProps,
      title: riderTitle,
    };
    openModal(annuityModalProps);
  };
  return { showAnnuityModal };
};

export const useRiderModalProps = (props) => {
  const { displayName, title } = props;

  const titleValue = isDefined(displayName) ? displayName : title;

  const isDisplayBenefitAmt = compareArrayOfStrings(
    [
      annuityDisplayTitles.PRINCIPAL_PROTECTION_BENEFIT,
      annuityDisplayTitles.GMIB,
      annuityDisplayTitles.GMWB,
    ],
    titleValue
  );
  const benefitAmtLabel = compareArrayOfStrings(
    [annuityDisplayTitles.GMIB, annuityDisplayTitles.GMWB],
    titleValue
  )
    ? "Benefit Amount"
    : "Value";
  const isDisplayRiderVersion = compareArrayOfStrings(
    [
      annuityDisplayTitles.GMAB,
      annuityDisplayTitles.LIFETIME_INCOME_PROTECTOR,
      annuityDisplayTitles.BASIC_DEATH,
      annuityDisplayTitles.GMIB,
      annuityDisplayTitles.GMWB,
      annuityDisplayTitles.ANNUAL_RATCHET_DEATH_BENEFIT,
    ],
    titleValue
  );

  const isDisplayEligibilityDateAndExpirationDate = compareArrayOfStrings(
    [
      annuityDisplayTitles.TERMINAL_ILLNESS,
      annuityDisplayTitles.NURSING_HOSPITAL_WAIVER,
      annuityDisplayTitles.STANDARD_DEATH,
      annuityDisplayTitles.NURSING_HOME_WAIVER_AND_HOME_HEALTH_CARE_BENEFIT,
      annuityDisplayTitles.NURSING_HOME_HOSPITAL_WAIVER,
      annuityDisplayTitles.NURSING_HOME_BENEFIT_RIDER,
    ],
    titleValue
  );

  return {
    isDisplayBenefitAmt,
    isDisplayRiderVersion,
    isDisplayEligibilityDateAndExpirationDate,
    benefitAmtLabel,
  };
};

export const getRiderColumns = (isRetireEase) => {
  if (isRetireEase) {
    return [
      {
        accessor: "riderName",
        label: "Name",
        alignment: "left",
      },
      {
        accessor: "issueDate",
        label: "Effective Date",
        alignment: "left",
      },
      {
        accessor: "benefitValue",
        label: "Benefit Value",
        alignment: "left",
      },
    ];
  }

  return [
    {
      accessor: "riderName",
      label: "Name",
      alignment: "left",
      CustomCell: RiderModalCell,
    },
    {
      accessor: "status",
      label: "Status",
      alignment: "left",
    },
    {
      accessor: "issueDate",
      label: "Effective Date",
      alignment: "left",
    },
  ];
};
