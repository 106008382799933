import React from "react";
import LoadingSection, { errorTypes, loadingTypes } from "../../common/loading";
import { LOADING_ICON_CUSTOM_MESSAGE } from "../../../constants/ui";
import { encodeHtml } from "../../../utils/string";

const ServiceEventModal = (props) => {
  const {
    agreementNumber,
    formattedName,
    receivedDate,
    completionDate,
    catsByWorkEventIdRequest,
    status,
    requestorDesc,
    department,
    requestorName,
    extendedComment,
  } = props;

  return (
    <LoadingSection
      request={catsByWorkEventIdRequest}
      loadingType={loadingTypes.SPINNER}
      errorType={errorTypes.SECTION}
      customErrorMessage={LOADING_ICON_CUSTOM_MESSAGE}
    >
      <div className="modal-body">
        <dl className="mm-key-value-pair">
          <dt id="aen_policy_number_lbl">Policy Number</dt>
          <dd id="aen_policy_number_value">{agreementNumber}</dd>
          <dt id="aen_status_lbl">Status</dt>
          <dd id="aen_status_value">{status}</dd>
          <dt id="aen_insured_name_lbl">Insured Name</dt>
          <dd id="aen_insured_name_value">{formattedName}</dd>
          <dt id="aen_received_date_lbl">Received Date</dt>
          <dd id="aen_received_date_value">{receivedDate}</dd>
          <dt id="aen_completed_date_lbl">Completed Date</dt>
          <dd id="aen_completed_datee_value">{completionDate}</dd>
          <dt id="aen_department_lbl">Department</dt>
          <dd id="aen_department_value">{department}</dd>
          <dt id="aen_initiatedby_lbl">Initiated By</dt>
          <dd id="aen_initiatedby_value">{requestorDesc}</dd>
          <dt id="aen_requestor_name_lbl">Requestor Name</dt>
          <dd id="aen_requestor_name_value">{requestorName}</dd>
          <dt id="aen_comments_lbl">Comments</dt>
          <dd id="aen_comments_value" style={{ whiteSpace: "pre-line" }}>
            {encodeHtml(extendedComment)}
          </dd>
        </dl>
      </div>
    </LoadingSection>
  );
};
export default ServiceEventModal;
