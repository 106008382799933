import React from "react";
import IsTruthy from "../common/logic/isTruthy";
import { usePolicyRestrictionCheckProps } from "./hooks";
import Header from "./header";
import Footer from "./footer";
import FiveHundredOneError from "../common/errors/501";

const PolicyRestrictionCheck = (props) => {
  const { children } = props;
  const { showChildren, haveRestrictedPoliciesLoaded } =
    usePolicyRestrictionCheckProps(props);

  return (
    <>
      <IsTruthy value={showChildren && haveRestrictedPoliciesLoaded}>
        {children}
      </IsTruthy>
      <IsTruthy value={!showChildren && haveRestrictedPoliciesLoaded}>
        <Header />
        <FiveHundredOneError />
        <Footer />
      </IsTruthy>
    </>
  );
};

export default PolicyRestrictionCheck;
