import React from "react";
import Checkbox from "./checkbox";
import Label from "./label";
import { isDefined } from "../../../utils/evaluate";
import CustomComponent from "../logic/customComponent";

const SwitchForm = (props) => {
  const { formField, useHook, className = "" } = props;
  const classString = isDefined(className)
    ? className
    : "form-group custom-control custom-checkbox";

  return (
    <div className={classString}>
      <Checkbox formField={formField} useHook={useHook} />
      <Label className="custom-control-label" formField={formField}>
        <CustomComponent {...props} component={formField.labelComponent} />
      </Label>
    </div>
  );
};

export default React.memo(SwitchForm);
