import React from "react";
import Columns from "./columns";
import Rows from "./rows";
import Pagination from "./pagination";
import { calculateRows } from "../../../utils/table";
import {
  usePagination,
  useSort,
  useSearchFilterRows,
} from "../../../hooks/table";
import IsTruthy from "../logic/isTruthy";
import IsFalsy from "../logic/isFalsy";
import TableControl from "./tableControl";

const Table = (props) => {
  const {
    id,
    tableClass,
    tableWrapperClass,
    rows,
    columns,
    showPagination = true,
    customFunctions,
    defaultSortColumn = columns[0]?.accessor,
    defaultSortDirection = "ascending",
    showSearchBar = false,
    noResultsText = "There are no matching results.",
    irregularColumns,
  } = props;
  const {
    searchString,
    showClearIcon,
    searchTable,
    clearSearch,
    filteredRows,
  } = useSearchFilterRows({ rows, columns });

  const { nextPage, previousPage, setInputtedPage, pagination } = usePagination(
    { rows: filteredRows }
  );

  const { sort, sortedRows, toggleSort } = useSort({
    columns,
    rows: filteredRows,
    defaultSortColumn,
    defaultSortDirection,
  });

  const visibleRows = calculateRows({
    rows: sortedRows,
    pagination,
    showPagination,
  });
  const paginationComponent = showPagination && visibleRows.length > 0 && (
    <Pagination
      nextPage={nextPage}
      previousPage={previousPage}
      setInputtedPage={setInputtedPage}
      pagination={pagination}
      id={id}
    />
  );
  const tableClassAppend = tableClass ? ` ${tableClass}` : "";

  return (
    <>
      <IsTruthy value={showSearchBar}>
        <TableControl
          searchString={searchString}
          searchTable={searchTable}
          showClearIcon={showClearIcon}
          clearSearch={clearSearch}
          placeHolderText="Search"
        />
      </IsTruthy>

      <IsFalsy value={visibleRows.length === 0}>
        <div
          id={`table-${id}`}
          className={`table-responsive ${tableWrapperClass}`}
        >
          <table id={`${id}_tbl`} className={`mm-table${tableClassAppend}`}>
            <Columns
              columns={columns}
              sort={sort}
              toggleSort={toggleSort}
              irregularColumns={irregularColumns}
            />
            <Rows
              columns={columns}
              rows={visibleRows}
              customFunctions={customFunctions}
            />
          </table>
        </div>
      </IsFalsy>
      <IsTruthy value={visibleRows.length === 0}>
        <div className="h3">
          <span>{noResultsText}</span>
        </div>
      </IsTruthy>
      {paginationComponent}
    </>
  );
};

export default React.memo(Table);
