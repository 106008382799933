import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useHeaderNotificationDropdownProps } from "./hooks";
import IsTruthy from "../../../common/logic/isTruthy";
import IsFalsy from "../../../common/logic/isFalsy";
import NotificationsDropdownItem from "./notificationsDropDownItem";
// eslint-disable-next-line import/no-cycle
import LoadingSection, {
  errorTypes,
  loadingTypes,
} from "../../../common/loading";

const HeaderNotificationsDropdown = () => {
  const {
    agentNotificationsRequest,
    onNotificationsDropDownToggle,
    isEligible,
    hasNewNotifications,
    hasError,
    notifications,
    reloadAttempts,
    setReloadAttempts,
    maxReloadAttemptsReached,
    customErrorMessage,
    isUnseenCountDataLoaded,
  } = useHeaderNotificationDropdownProps();

  return (
    <IsTruthy value={isEligible && isUnseenCountDataLoaded}>
      <Dropdown className="nav-item" onToggle={onNotificationsDropDownToggle}>
        <Dropdown.Toggle
          variant="none"
          id="dropdown-basic"
          className="nav-link"
        >
          <span
            className="icon-notification-white icon-sm"
            data-testid="notification_icon"
          />
          <span className="sr-only">Notifications</span>
          <IsTruthy value={hasNewNotifications}>
            <span
              className="badge badge-indicator"
              data-testid="new_notification_indicator"
            />
          </IsTruthy>
        </Dropdown.Toggle>

        <Dropdown.Menu className="notifications">
          <div className="dropdown-text">
            <div className="row">
              <div className="col-6">
                <h3 id="notifications_dropdown_header">Notifications</h3>
              </div>
              <IsFalsy value={notifications.length === 0 || hasError}>
                <div className="col-6">
                  <div className="justify-content-end">
                    <a
                      href="/agentServiceTracking"
                      id="notifications_extended_page_link_top"
                      className="btn btn-inline"
                    >
                      <span>View All</span>
                      <span className="icon-arrow icon-sm" />
                    </a>
                  </div>
                </div>
              </IsFalsy>
            </div>
            <hr />
            <LoadingSection
              request={agentNotificationsRequest}
              loadingType={loadingTypes.SPINNER}
              errorType={errorTypes.DROPDOWN}
              reloadAttempts={reloadAttempts}
              setReloadAttempts={setReloadAttempts}
              customErrorMessage={customErrorMessage}
              removeReloadButton={maxReloadAttemptsReached}
              loadingSpinnerPosition="mm-content-object--centered"
            >
              <IsTruthy value={notifications.length > 0}>
                {notifications.map((notification, index) => (
                  <>
                    <NotificationsDropdownItem
                      notification={notification}
                      index={index}
                    />

                    <div className="dropdown-text">
                      <hr />
                    </div>
                  </>
                ))}
              </IsTruthy>
              <IsTruthy value={notifications.length === 0 && !hasError}>
                <Dropdown.Item>
                  <div className="SN-notification-wrapper">
                    <span
                      id="no_notifications_icon"
                      className="illustration-confetti"
                    />
                    <p className="h4">You’re all caught up!</p>
                    <p>No new notifications at this time.</p>
                  </div>
                </Dropdown.Item>
              </IsTruthy>
              <IsFalsy value={notifications.length === 0 || hasError}>
                <div className="dropdown-text">
                  <a
                    href="/agentServiceTracking"
                    id="notifications_extended_page_link_bottom"
                    className="btn btn-inline"
                  >
                    <span>View All Notifications</span>
                    <span className="icon-arrow icon-sm" />
                  </a>
                </div>
              </IsFalsy>
            </LoadingSection>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </IsTruthy>
  );
};

export default React.memo(HeaderNotificationsDropdown);
