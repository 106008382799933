import React from "react";
import RadioForm from "../../common/forms/radioForm";
import { useMailingAddressProps } from "../hooks";
import IsTruthy from "../../common/logic/isTruthy";
import InputForm from "../../common/forms/inputForm";
import EditAddress from "../../common/editAddress/editAddress";

const MailingAddress = (props) => {
  const {
    formData,
    formattedOwnerName,
    attnLine,
    addrFirstLine,
    addrSecondLine,
    addrThirdLine,
    addrFourthLine,
  } = useMailingAddressProps(props);
  return (
    <>
      <h1>Mailing Address</h1>
      <p className="mm-sub-copy">* = Required Field</p>
      <div className="mm-spacing--minor">
        <p className="h3">
          The loan check will be sent to the Policy Owner&apos;s address on
          record.
        </p>
        <p>
          Note: If you select other address, a signed loan form is required and
          will be available for download.
        </p>
        <RadioForm formField={formData.policyOwnerAddressonRecord} />
        <IsTruthy value={formData.policyOwnerAddressonRecord.value}>
          <div className="mm-control-indent">
            <p>
              {formattedOwnerName} <br />
              <IsTruthy value={attnLine !== ""}>
                {attnLine}
                <br />
              </IsTruthy>
              {addrFirstLine}
              <IsTruthy value={addrSecondLine !== ""}>
                <br />
                {addrSecondLine}
              </IsTruthy>
              <IsTruthy value={addrThirdLine !== ""}>
                <br />
                {addrThirdLine}
              </IsTruthy>
              <IsTruthy value={addrFourthLine !== ""}>
                <br />
                {addrFourthLine}
              </IsTruthy>
            </p>
          </div>
        </IsTruthy>
        <div className="row">
          <div className="col-12 col-md-5">
            <RadioForm formField={formData.enterPolicyNewAddress} />
          </div>
        </div>
        <IsTruthy value={formData.enterPolicyNewAddress.value}>
          <EditAddress
            hideMandatoryFieldIndicator
            isCalledFrom="InitiateNewLoan"
          />
        </IsTruthy>
      </div>
      <div className="mm-spacing--minor">
        <h4>Select the mailing option*</h4>
        <p>
          Note: You will need a UPS account to select UPS Priority. UPS is not
          overnight mail and will not deliver to P.O. boxes.
        </p>
        <div className="row">
          <div className="col-12 col-md-5">
            <RadioForm formField={formData.uspsGroundRadio} />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-5">
            <RadioForm formField={formData.upsPriorityRadio} />
            <div className="col-12 col-md-9">
              <IsTruthy value={formData.upsPriorityRadio.value}>
                <InputForm formField={formData.upsAccountNumber} />
                <InputForm formField={formData.zipCode} />
              </IsTruthy>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(MailingAddress);
