import React from "react";
import { useParams } from "react-router-dom";
import {
  contactUSDetails,
  contactUsDetailsUnSupportedLob,
} from "../../../data/contactUsDetails";
import { removeAllWhiteSpace } from "../../../utils/string";
import ErrorPage from "./errorPage";
import { getTrimmedPolicyIdFromAgreement } from "../../../utils/findData";
import { isCoverpathPolicy } from "../../../utils/policies";
import { useAddOrRemoveChatLauncher } from "../../../utils/genesysChatClient";

const FiveHundredThreeError = (props) => {
  const {
    agreementData: {
      majorProductCode,
      lineOfBusinessCode,
      agreementKeyAdmin,
      policyId,
    },
    transactionId,
    errorPageFor,
  } = props;

  const { agreementKey } = useParams();

  const { number, time, redirectLink } =
    contactUsDetailsUnSupportedLob?.[lineOfBusinessCode]?.[
      removeAllWhiteSpace(majorProductCode)
    ]?.[agreementKeyAdmin] || contactUSDetails.DEFAULT;

  const { trimmedPolicyId } = getTrimmedPolicyIdFromAgreement(agreementKey);

  const errorTitles = {
    advisor360: {
      title1: `Information is not available in ServiceNet for account ${policyId}.`,
      title2: `You can view account details by going to <a href="${redirectLink}">Advisor360°</a>.`,
      alt: `Information is not available in ServiceNet for account ${policyId}. You can view account details by going to ${redirectLink}.`,
      title2Size: "",
      title3Size: "",
    },
    phoneNrDisplay: {
      title1: `Information is not available online for Policy or Contract ${
        policyId || trimmedPolicyId
      }.`,
      title2: `Please contact our Service Center at ${number}, ${time}.`,
      title3: "",
      alt: `Information is not available online for Contract ${
        policyId || trimmedPolicyId
      }. Please contact our Service Center at ${number}, ${time}.`,
      title2Size: "p",
      title3Size: "",
    },
    coverPathDisplay: {
      title1: `This policy is part of the Coverpath Experience.`,
      title2: `This policy is serviced in Coverpath. Please advise the user to contact Coverpath Customer Service at ${number}, ${time}.`,
      title3: "",
      alt: `This policy is serviced in Coverpath. Please advise the user to contact Coverpath Customer Service at ${number}, ${time}.`,
      title2Size: "p",
      title3Size: "",
    },
  };

  let titleData = errorTitles.phoneNrDisplay;

  if (redirectLink) titleData = errorTitles.advisor360;

  if (isCoverpathPolicy(agreementKeyAdmin)) {
    titleData = errorTitles.coverPathDisplay;
  }

  useAddOrRemoveChatLauncher({ page: "errorPage" });

  return (
    <ErrorPage
      errorTitleSize="h2"
      errorTitleLine2Size={titleData.title2Size}
      errorTitleLine3Size={titleData.title3Size}
      errorTitle={titleData.title1}
      errorTitleLine2={titleData.title2}
      errorTitleLine3={titleData.title3}
      agreementKey={agreementKey}
      errorPageFor={errorPageFor}
      imageClass="illustration-sitting-at-desk-with-cat"
      transactionId={transactionId}
      displayTransactionId
    />
  );
};

export default FiveHundredThreeError;
