import React from "react";
import Stepper from "../../common/forms/stepper";
import IsTruthy from "../../common/logic/isTruthy";
import { TRANSACTIONS_DISABLED_MESSAGE } from "../../../constants/ui";

const InitiateDeathClaimHeader = (props) => {
  const { pageNumber, previousPage, shouldDisableSubmit, isDiOnlyClaim } =
    props;

  const totalPages = isDiOnlyClaim ? 4 : 5;

  return (
    <>
      <nav role="navigation" className="mm-navbar--transaction">
        <div className="mm-logo" id="servicenet_logo_img" />
        <IsTruthy value={pageNumber < 6}>
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <Stepper totalSteps={totalPages} currentStep={pageNumber} />
            </div>
          </div>
        </IsTruthy>
      </nav>
      <IsTruthy value={pageNumber > 1 && pageNumber < 6}>
        <button
          id="go_back_btn"
          type="button"
          className="btn btn-link mm-spacing--minor"
          onClick={previousPage}
        >
          <span className="icon-arrow-circle-spin-left" />
          <span>Previous Step</span>
        </button>
      </IsTruthy>
      <IsTruthy value={pageNumber < 6}>
        <p className="eyebrow" id="initiate_death_claim_step_lbl">
          INITIATE A DEATH CLAIM: STEP {pageNumber} of {totalPages}
        </p>
        <IsTruthy value={shouldDisableSubmit}>
          <div className="alert alert-danger" role="alert">
            {TRANSACTIONS_DISABLED_MESSAGE}
          </div>
        </IsTruthy>
      </IsTruthy>
    </>
  );
};
export default InitiateDeathClaimHeader;
