import React from "react";
import { setTitle } from "../../../utils/dom";
import { useConfirmationProps } from "../hooks";
import { fireTealiumAndFollowLink } from "../../../utils/tealium";

const Confirmation = (props) => {
  const { lineOfBusinessCode, policyId } = props;
  const { backToPolicyDisplayText, agreementKey, userEmail } =
    useConfirmationProps(props);
  setTitle("ServiceNet - Digital Registration Confirmation");
  return (
    <div>
      <div className="mm-spacing" />
      <div className="row">
        <div className="col-md-10">
          <h1 className="h2">
            <div id="digital_registration_confirmation_heading">
              Thank you! Your client has been pre-registered and an email has
              been sent to {userEmail}.
            </div>
          </h1>
        </div>
        <div className="col-2">
          <div id="printButton" className="justify-content-end">
            <button
              onClick={() => window.print()}
              data-bp-link="default"
              className="btn btn-inline hide-print"
              aria-label="Print"
              type="button"
            >
              <span
                id="digital_registration_print_icon"
                className="icon-print"
              />
              <span>Print</span>
            </button>
          </div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-md-12">
          <h4>
            <span id="digital_registration_confirmation_additional_message_header">
              To complete their registration, your client must:
            </span>
          </h4>
          <ul id="digital_registration_confirmation_additional_messages">
            <li>Click the Create Password link in the email</li>
            <li>
              Complete two-factor authentication by following the prompts on
              MassMutual.com
            </li>
            <li>Client has 72 hours to click the Create Password link</li>
          </ul>
        </div>
      </div>
      <div className="mm-spacing" />
      <div className="row">
        <div className="col-md-12">
          <div className="container">
            <button
              id="digital_registration_confirmation_back_to_policy_button"
              onClick={() =>
                fireTealiumAndFollowLink({
                  url: `/policyDetails/${encodeURIComponent(agreementKey)}`,
                  action: `${lineOfBusinessCode}: Digital Registration Back to Policy/Contract button clicked`,
                  category: "Digital Registration",
                  value: policyId,
                  custId: userEmail,
                })
              }
              type="button"
              className="btn btn-primary"
            >
              {backToPolicyDisplayText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
