import { useParams } from "react-router-dom";
import { useMakeRequest } from "../../../../hooks/api";
import {
  formatName,
  nameFormattingOptions,
  formatPercentage,
  formatMoney,
} from "../../../../utils/format";
import { getUserBySubType, userTypes } from "../../../../utils/findData";
import { formatDate } from "../../../../utils/dates";
import {
  compareArrayOfStrings,
  compareStrings,
} from "../../../../utils/string";
import { isDefined } from "../../../../utils/evaluate";
import { useUserState } from "../../../../contexts/user";
import { AGREEMENT_NOT_FOUND } from "../../../../constants/ui";
import {
  isAnnuityContract,
  isDisabilityPolicy,
} from "../../../../utils/policies";
import { useAddOrRemoveChatLauncher } from "../../../../utils/genesysChatClient";

export const useFundsProps = () => {
  const { agreementKey } = useParams();

  const agreementRequest = useMakeRequest({
    apiRequest: "fetchAgreementData",
    apiParams: { agreementKey },
    immediateRequest: true,
    dataSelector: "processAgreementRequest",
  });

  const agreementData = agreementRequest.data;

  const {
    policyId,
    agreementCustomers,
    agreementFunds,
    asOfDate,
    totalAccountValue,
    isUnauthorized,
    agreementKeyAdmin,
    minorProductCode,
    messages,
    lineOfBusinessCode,
  } = agreementData;
  const fundRows = mapAndFormatAgreementFunds(
    totalAccountValue,
    agreementKeyAdmin,
    minorProductCode,
    agreementFunds
  );

  const personInsured = getUserBySubType({
    type: userTypes.INSURED,
    subType: "PRMR",
    people: agreementCustomers,
  });
  const insuredNameFormatted = formatName({
    nameObject: personInsured,
    format: nameFormattingOptions.firstNameDisplayedFirst,
  });

  const {
    user: { email },
  } = useUserState();
  // const { type, description } = agreementRequest?.data?.messages;
  const validRequest =
    !isUnauthorized &&
    compareStrings(messages?.type, "SUCCESS") &&
    !compareStrings(messages?.description, AGREEMENT_NOT_FOUND);

  const isHapPolicy = compareStrings(agreementKeyAdmin, "HAP");

  const show404errorPageForAnnDiAndHap = !!(
    isAnnuityContract(lineOfBusinessCode) ||
    isHapPolicy ||
    isDisabilityPolicy(agreementRequest.data)
  );
  useAddOrRemoveChatLauncher({ agreementData, page: "Funds page" });

  return {
    requests: { agreementRequest },
    asOfDate: formatDate(asOfDate, "monthCommaDayYear"),
    insuredName: insuredNameFormatted,
    isUnauthorized,
    policyId,
    table: {
      columns,
      rows: fundRows,
    },
    email,
    validRequest,
    show404errorPageForAnnDiAndHap,
  };
};

const mapAndFormatAgreementFunds = (
  totalAccountValue,
  agreementKeyAdmin,
  minorProductCode,
  agreementFunds = []
) => {
  const isPE1VLPolicy =
    compareStrings(agreementKeyAdmin, "PE1") &&
    compareStrings(minorProductCode, "VL");
  let fundValueFormatted = null;
  let totalUnitsFormat = null;
  let unitValueFormatted = null;
  let FUND_VALUE_MISSING = null;
  const isFund199Available = agreementFunds.some((e) =>
    compareStrings(e.fundNumber, "199")
  );
  const remappedFunds = agreementFunds.map((fund) => {
    if (isPE1VLPolicy && isFund199Available) {
      totalUnitsFormat = !isDefined(fund?.fundTotalNumberUnits)
        ? "N/A"
        : fund?.fundTotalNumberUnits?.toFixed(4);
      unitValueFormatted = !isDefined(fund?.accumUnitAmount)
        ? "N/A"
        : fund?.accumUnitAmount?.toFixed(6);
      fundValueFormatted = !isDefined(fund?.fundValueAmount)
        ? "N/A"
        : formatMoney(fund?.fundValueAmount);
    } else {
      const ACCOUNT_VALUE_ZERO_OR_NEGATIVE = totalAccountValue <= 0;
      const IS_FIXED = compareStrings("FIX", fund?.investmentType);
      FUND_VALUE_MISSING =
        !isDefined(fund?.fundValueAmount) &&
        fund?.fundTransferAvailableAmount === 0;

      fundValueFormatted = ACCOUNT_VALUE_ZERO_OR_NEGATIVE
        ? "N/A"
        : formatMoney(fund?.fundValueAmount);
      totalUnitsFormat =
        ACCOUNT_VALUE_ZERO_OR_NEGATIVE ||
        IS_FIXED ||
        !isDefined(fund?.fundTotalNumberUnits) ||
        FUND_VALUE_MISSING
          ? "N/A"
          : fund?.fundTotalNumberUnits?.toFixed(4);
      unitValueFormatted =
        ACCOUNT_VALUE_ZERO_OR_NEGATIVE ||
        IS_FIXED ||
        !isDefined(fund?.accumUnitAmount) ||
        FUND_VALUE_MISSING
          ? "N/A"
          : fund?.accumUnitAmount?.toFixed(6);
    }
    // only show interest rate for specified fund numbers
    const SHOULD_SHOW_INTEREST_RATE = compareArrayOfStrings(
      ["001", "199", "200"],
      fund?.fundNumber
    );
    const interestRateFormatted = SHOULD_SHOW_INTEREST_RATE
      ? formatPercentage({
          rate: fund?.fundGPAInterestRateFmtd,
        })
      : "N/A";
    const allocationFormatted = formatPercentage({
      rate: fund?.fundPercentAmountFmtd,
    });
    const fundNameDisplay =
      (compareStrings(fund?.fundNumber, "199") && isDefined(fund?.fundName)) ||
      !isDefined(fund?.marketingName)
        ? fund?.fundName
        : fund?.marketingName;

    return {
      fundNumber: fund?.fundNumber,
      fundName: isDefined(fundNameDisplay) ? fundNameDisplay : "-",
      totalUnits: totalUnitsFormat,
      unitValue: unitValueFormatted,
      interestRate: interestRateFormatted,
      allocation: compareStrings(allocationFormatted, "-")
        ? "N/A"
        : allocationFormatted,
      fundValue: FUND_VALUE_MISSING ? "$0.00" : fundValueFormatted,
    };
  });
  return remappedFunds;
};

const columns = [
  {
    accessor: "fundNumber",
    label: "Fund No.",
    alignment: "left",
    dataCell: false,
    sortable: "Number",
  },
  {
    accessor: "fundName",
    label: "Fund Name",
    alignment: "left",
    sortable: "String",
  },
  {
    accessor: "totalUnits",
    label: "Total Units",
    alignment: "left",
    sortable: "Number",
  },
  {
    accessor: "unitValue",
    label: "Unit Value",
    alignment: "left",
    sortable: "Number",
  },
  {
    accessor: "interestRate",
    label: "Interest Rate",
    alignment: "left",
    sortable: "Number",
  },
  {
    accessor: "allocation",
    label: "Allocation",
    alignment: "left",
    sortable: "Number",
  },
  {
    accessor: "fundValue",
    label: "Fund Value",
    alignment: "left",
    sortable: "Number",
  },
];
