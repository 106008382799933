import React from "react";
import IsFalsy from "../logic/isFalsy";
import IsTruthy from "../logic/isTruthy";
import { isANumber, isDefined } from "../../../utils/evaluate";

const DropdownError = (props) => {
  const {
    retryRequest,
    customErrorMessage = "",
    removeReloadButton = false,
    disableReloadButton = false,
    setReloadAttempts,
    reloadAttempts,
  } = props;

  const handleClick = () => {
    if (
      isDefined(setReloadAttempts) &&
      isANumber(reloadAttempts) &&
      !disableReloadButton
    ) {
      setReloadAttempts(reloadAttempts + 1);
    }
    retryRequest();
  };

  return (
    <div className="SN-notification-wrapper">
      <IsFalsy value={removeReloadButton}>
        <p className="h4" id="dropdown_error_text">
          {customErrorMessage}
        </p>
        <p id="dropdown_try_again_text">Please try again.</p>
      </IsFalsy>
      <IsTruthy value={removeReloadButton}>
        <p className="h4" id="dropdown_error_text">
          {customErrorMessage}
        </p>
        <p id="dropdown_try_again_later_text">Please try again later.</p>
      </IsTruthy>
      <IsFalsy value={removeReloadButton}>
        <div>
          <button
            type="button"
            id="dropdown_reload_button"
            className="btn btn-inline"
            onClick={handleClick}
            disabled={disableReloadButton}
          >
            <span className="icon-reload" /> Reload
          </button>
        </div>
      </IsFalsy>
    </div>
  );
};

export default DropdownError;
