import React from "react";

const SelectFunds = () => (
  <>
    <h1>Select Funds</h1>
    <p className="mm-sub-copy">* = Required Field</p>
    <br />
  </>
);
export default SelectFunds;
