import { format } from "date-fns";
import { isDefined } from "./evaluate";
import { addTimeToDate, isAfterDate, isBeforeDate } from "./dates";
import { compareStrings } from "./string";

const hasField = (props) => {
  const { formField, property } = props;
  return Object.prototype.hasOwnProperty.call(formField, property);
};

const hasError = (props) => {
  if (!isDefined(props)) return false;
  const { errors = [], postpone = false } = props;
  const HAS_ERRORS = errors.length > 0;
  return HAS_ERRORS && !postpone;
};

const minValidation = (props) => {
  const {
    formField: { min, label, value },
  } = props;
  const TOO_SHORT = value.trim().length < min;
  if (TOO_SHORT) {
    return {
      hasError: true,
      message: `${label} must have at least ${min} characters.`,
    };
  }
  return { hasError: false, message: "" };
};

const minEqualValidation = (props) => {
  const {
    formField: { minEqual, label, value },
  } = props;
  const TOO_SHORT = value.trim().length <= minEqual;
  if (TOO_SHORT) {
    return {
      hasError: true,
      message: `${label} must have at least ${minEqual} characters.`,
    };
  }
  return { hasError: false, message: "" };
};

const maxValidation = (props) => {
  const {
    formField: { max, label, value },
  } = props;
  const TOO_LONG = value.length > max;
  if (TOO_LONG) {
    return {
      hasError: true,
      message: `${label} must be ${max} characters or less.`,
    };
  }
  return { hasError: false, message: "" };
};

const maxEqualValidation = (props) => {
  const {
    formField: { maxEqual, label, value },
  } = props;
  const TOO_LONG = value.length >= maxEqual;
  if (TOO_LONG) {
    return {
      hasError: true,
      message: `${label} must have less than ${maxEqual} characters.`,
    };
  }
  return { hasError: false, message: "" };
};

export const exactValidation = (props) => {
  const {
    formField: { exactLength, label, value },
  } = props;
  const NO_EXACT = value.length !== exactLength;
  if (NO_EXACT) {
    return {
      hasError: true,
      message: `${label} must have exactly ${exactLength} characters.`,
    };
  }
  return { hasError: false, message: "" };
};

export const emailValidation = (props) => {
  const {
    formField: { value },
  } = props;
  // eslint-disable-next-line no-useless-escape
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@“]+(\.[^<>()[\]\\.,;:\s@“]+)*)|(“.+“))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const VALID_EMAIL = emailRegex.test(value);
  if (!VALID_EMAIL) {
    return {
      hasError: true,
      message: "Please enter a valid email address",
    };
  }
  return { hasError: false, message: "" };
};

export const confirmEmailValidation = (props) => {
  const {
    formField: { value, emailFormFieldValue },
  } = props;
  // eslint-disable-next-line no-useless-escape
  const emailValidationError = emailValidation(props);
  if (emailValidationError.hasError) {
    return emailValidationError;
  }

  if (!compareStrings(value, emailFormFieldValue)) {
    return {
      hasError: true,
      message: "Emails do not match.",
    };
  }

  return { hasError: false, message: "" };
};

export const phoneValidation = (props) => {
  const {
    formField: { value },
  } = props;
  const IS_TEN_DIGITS = value.length - 2 === 10;
  if (!IS_TEN_DIGITS) {
    return {
      hasError: true,
      message: "Phone number should be 10 digits",
    };
  }
  return { hasError: false, message: "" };
};

export const faxValidation = (props) => {
  const {
    formField: { value },
  } = props;
  const IS_TEN_DIGITS = value.length - 2 === 10;
  if (!IS_TEN_DIGITS) {
    return {
      hasError: true,
      message: "Fax number should be 10 digits",
    };
  }
  return { hasError: false, message: "" };
};

export const phoneSearchValidation = (props) => {
  const {
    formField: { value },
  } = props;
  const IS_LESS_TEN_DIGITS = value.length < 10;
  const IS_GREATER_ELEVEN_DIGITS = value.length > 11;
  if (IS_LESS_TEN_DIGITS) {
    return {
      hasError: true,
      message: "Phone number should be at least 10 digits",
    };
  }
  if (IS_GREATER_ELEVEN_DIGITS) {
    return {
      hasError: true,
      message: "Phone number should be no more than 11 digits",
    };
  }
  const START_WITH_ONE = value.substring(0, 1) === "1";
  if (value.length === 11 && !START_WITH_ONE) {
    return {
      hasError: true,
      message:
        "At this time only domestic numbers are supported.  Please check the number and try again.",
    };
  }

  return { hasError: false, message: "" };
};

const upsAccountValidation = (props) => {
  const {
    formField: { label, value },
  } = props;
  const TOO_SHORT = value.length < 1;
  if (TOO_SHORT) {
    return {
      hasError: true,
      message: `Please provide ${label}.`,
    };
  }
  return { hasError: false, message: "" };
};

export const maxLoanValidation = (props) => {
  const {
    formField: { maxLoanAmount, value },
  } = props;
  const normalizedValue = value.replace(/,/g, "");
  const EXCEEDS_MAX_LOAN_AMOUNT =
    parseFloat(normalizedValue) > parseFloat(maxLoanAmount);
  const VALUE_LESS_THAN_1 = parseFloat(normalizedValue) <= 1;

  if (VALUE_LESS_THAN_1) {
    return {
      hasError: true,
      message: "Loan amount must be greater than $1.00",
    };
  }
  if (EXCEEDS_MAX_LOAN_AMOUNT) {
    return {
      hasError: true,
      message: "The loan amount can't exceed the Maximum Loan Amount available",
    };
  }
  return { hasError: false, message: "" };
};

const filterOutErrors = (potentialErrors) =>
  potentialErrors.filter((error) => {
    const DOES_HAVE_ERROR = error.hasError;
    return DOES_HAVE_ERROR;
  });

const validate = (props) => {
  const { formField } = props;

  const fieldsToCheck = [
    {
      propertyName: "min",
      functionName: minValidation,
    },
    {
      propertyName: "minEqual",
      functionName: minEqualValidation,
    },
    {
      propertyName: "max",
      functionName: maxValidation,
    },
    {
      propertyName: "maxEqual",
      functionName: maxEqualValidation,
    },
    {
      propertyName: "exactLength",
      functionName: exactValidation,
    },
  ];
  const errors = fieldsToCheck.map((fieldToCheck) => {
    const { propertyName, functionName } = fieldToCheck;
    if (hasField({ formField, property: propertyName })) {
      return functionName({ formField });
    }
    return {
      hasError: false,
      message: "",
    };
  });
  return filterOutErrors(errors);
};

const useGeneralValidation = (props) => {
  const { formField } = props;
  return validate({ formField });
};

export const useEmailValidation = (props) => {
  const { formField } = props;
  const allErrors = [emailValidation({ formField })];
  return filterOutErrors(allErrors);
};

export const useConfirmEmailValidation = (props) => {
  const { formField } = props;
  const allErrors = [confirmEmailValidation({ formField })];

  return filterOutErrors(allErrors);
};

export const usePhoneValidation = (props) => {
  const { formField } = props;
  const allErrors = [phoneValidation({ formField })];
  return filterOutErrors(allErrors);
};

export const useFaxValidation = (props) => {
  const { formField } = props;
  const allErrors = [faxValidation({ formField })];
  return filterOutErrors(allErrors);
};

export const usePhoneSearchValidation = (props) => {
  const { formField } = props;
  const allErrors = [phoneSearchValidation({ formField })];
  return filterOutErrors(allErrors);
};

export const useUpsAccountValidation = (props) => {
  const { formField } = props;
  const allErrors = [upsAccountValidation({ formField })];
  return filterOutErrors(allErrors);
};
export const useLoanValidation = (props) => {
  const { formField } = props;
  const allErrors = [maxLoanValidation({ formField })];
  return filterOutErrors(allErrors);
};

export const canBeProcessedByBot = (props) => {
  const {
    address,
    nonIndividualOwner = {},
    catsEvents = [],
    isMultipleOwners,
    isMultipleInsured,
    agreementKeyAdmin,
    flagValues,
  } = props;
  const { line1 = "", line2 = "", line3 = "" } = address;
  const { eventsArray } = catsEvents;
  const { changeAddressDuration } = flagValues;
  const isIndividuallyOwned = nonIndividualOwner.length === 0;

  const careOfTerms = ["c/o", "care of", "in care of"];
  const hasCareOf = careOfTerms.find(
    (term) =>
      line1.toLowerCase().includes(term) ||
      line2.toLowerCase().includes(term) ||
      line3.toLowerCase().includes(term)
  );
  const hasRecentEvent = eventsArray.find((catsEvent) => {
    if (
      catsEvent?.eventName === "Address Change Pending" &&
      isAfterDate(
        addTimeToDate({
          date: catsEvent?.eventDateTime,
          duration: changeAddressDuration,
        }),
        new Date()
      )
    ) {
      return true;
    }
    return false;
  });
  return (
    !isDefined(hasCareOf) &&
    isIndividuallyOwned &&
    !isDefined(hasRecentEvent) &&
    !isMultipleOwners &&
    !(isMultipleInsured && agreementKeyAdmin === "VNTG1")
  );
};

export const datePickerInputValidation = (props) => {
  const { selectedDate, datePickerMinDate, datePickerMaxDate } = props;

  let isBeforeMinDate;
  let isAfterMaxDate;

  if (selectedDate && datePickerMinDate) {
    isBeforeMinDate = isBeforeDate(
      format(selectedDate, "MM/dd/yyyy"),
      format(datePickerMinDate, "MM/dd/yyyy")
    );
  }

  if (selectedDate && datePickerMaxDate) {
    isAfterMaxDate = isAfterDate(
      format(selectedDate, "MM/dd/yyyy"),
      format(datePickerMaxDate, "MM/dd/yyyy")
    );
  }

  if (!selectedDate) return true;

  return !!(isBeforeMinDate || isAfterMaxDate);
};

export {
  hasField,
  hasError,
  validate,
  filterOutErrors,
  minValidation,
  minEqualValidation,
  maxValidation,
  maxEqualValidation,
  useGeneralValidation,
};
