import React from "react";
import LabelWithHelpModal from "../../common/forms/labelWithHelpModal";
import { isAnnuityContract } from "../../../utils/policies";

const getAddressChangeModalBodyHTML = (props) => {
  const policyOrContract = isAnnuityContract(props?.lineOfBusinessCode)
    ? "contracts "
    : "policies ";

  return (
    <div className="modal-body">
      <hr />
      <h5 id="directLink_AddressChange_ModalTitle">Address Change</h5>
      <p>
        The following can be done on the <strong>website </strong>or{" "}
        <strong>mobile</strong> app:
      </p>
      <ul>
        <li>Updates all of customer’s {policyOrContract} at one time</li>
      </ul>
      <ul>
        <li>Customers can check the status of their request</li>
      </ul>
      <div className="mm-spacing--minor" />
      <h5 id="directLink_AddressChange_ModalQuickTips">Quick Tips</h5>
      <ul>
        <li>
          Only available for individually owned {policyOrContract}. Use the
          Change Address on ServiceNet to submit non-individual address changes.
        </li>
      </ul>
    </div>
  );
};

const AddressChangeModal = (props) => {
  const AddressChangeModalBody = () => getAddressChangeModalBodyHTML(props);
  return (
    <LabelWithHelpModal
      text="Address Change"
      modalTitle="Address Change"
      modalId="addressChange"
      modalBodyComponent={AddressChangeModalBody}
      modalData={props}
    />
  );
};

export default AddressChangeModal;
