import React from "react";
import LabelWithHelpModal from "../../common/forms/labelWithHelpModal";

const PaperlessPreferenceModalBody = () => (
  <div className="modal-body">
    <hr />
    <h5 id="directLink_PaperlessPreferences_ModalTitle">
      Paperless Preferences
    </h5>
    <p>
      The following can be done on the <strong>website </strong>or{" "}
      <strong>mobile</strong> app:
    </p>
    <ul>
      <li>
        <strong>Paperless Billing</strong> (sometimes called “eBill” or
        “Paperless”)
        <ul>
          <li>
            By selecting paperless, the customer will no longer receive paper
            bills, instead they will receive an e-mail notification.
          </li>
          <li>
            Not available on PAC or DI policies that are part of a
            discount/group
          </li>
        </ul>
      </li>
      <li>
        <strong>Variable fund documents</strong> (sometimes called “eDelivery”)
        <ul>
          <li>
            Available for Variable Life and Variable Annuity products for the
            following documents:
            <ul>
              <li>
                Prospectuses, Prospectus Supplements, and Fund
                annual/semi-annual reports except SE2 and UNIV
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
);

const PaperlessPreferenceModal = () => (
  <LabelWithHelpModal
    text="Paperless Preferences"
    modalTitle="Paperless Preferences"
    modalId="paperlessPreferences"
    modalBodyComponent={PaperlessPreferenceModalBody}
  />
);

export default PaperlessPreferenceModal;
