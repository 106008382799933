import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { ScrollSpy } from "react-blueprint-library";
// eslint-disable-next-line import/no-extraneous-dependencies
import MMLogo from "blueprint-css/images/mm-logo/MM_FullMark_294.svg";
import AnnuityContractInfoHeader from "../../sections/basicPolicyInfoHeader/annuity/contractInfoHeader";
import PolicyAndContactInfoDrawer from "../../../common/drawers/policyAndContactInfoDrawer";
import { useAnnuityContractDetailProps } from "./hooks";
import { setTitle } from "../../../../utils/dom";
import LoadingSection, {
  errorTypes,
  loadingTypes,
} from "../../../common/loading";
import PeopleOnContract from "../../sections/peopleOnContract";
import Riders from "../../sections/riders";
import Investments from "../../sections/investments";
import ContractDetails from "../../sections/contractDetails";
import ServiceHistory from "../../sections/serviceHistory";
import Documents from "../../sections/documents";
import { tealiumPageViewEvent } from "../../../../utils/tealium";
import "../../../../Print.scss";
import IsTruthy from "../../../common/logic/isTruthy";
import IsFalsy from "../../../common/logic/isFalsy";
import FourHundredFourError from "../../../common/errors/404";
import AnnuityLoan from "../../sections/loan/annuities";
import Billing from "../../sections/billing";
import FinancialHistorySection from "../../sections/financialHistory";
import SelectAsOfDate from "../../sections/selectValueRecalculationAsOfDate";
import KPI from "../../sections/kpi";

const AnnuityDetails = (props) => {
  setTitle("ServiceNet - Contract Details");
  const { unsupportedPolicy } = props;
  const {
    policyId,
    agreementKey,
    agreementRequest,
    producersRequest,
    serviceHistoryRequest,
    deathClaimEligibilityRequest,
    email,
    isDrawerOpened,
    openDrawer,
    closeDrawer,
    customer,
    printPDP,
    annuityRequest,
    showErrorPage,
    hiddenScrollSpySections: {
      hideLoan,
      hideInvestments,
      hideBillingPayment,
      hideContractFeatures,
    },
    soaBillingRequest,
    cdaValuesRequest,
    billingRequest,
    setAsOfDate,
    formattedAsOfDate,
    recalculatedValues,
    showRecalcValues,
    showLoadingIcon,
    user,
    lineOfBusinessCode,
    displayKpi,
    statementPreferencesRequest,
    isDeliveryPreferenceEligibleAnnuity,
  } = useAnnuityContractDetailProps(props);

  useEffect(() => {
    tealiumPageViewEvent({
      page: "Policy Details",
      category: agreementKey,
      custId: email,
    });
  }, []);

  return (
    <LoadingSection
      request={agreementRequest}
      loadingType={loadingTypes.PARAGRAPH}
      errorType={errorTypes._404}
      unsupportedPolicy={unsupportedPolicy}
    >
      <LoadingSection
        request={annuityRequest}
        loadingType={loadingTypes.PARAGRAPH}
        errorType={errorTypes.CHILDREN}
        unsupportedPolicy={unsupportedPolicy}
      >
        <LoadingSection
          request={soaBillingRequest}
          loadingType={loadingTypes.PARAGRAPH}
          errorType={errorTypes.CHILDREN}
          unsupportedPolicy={unsupportedPolicy}
        >
          <LoadingSection
            request={cdaValuesRequest}
            loadingType={loadingTypes.PARAGRAPH}
            errorType={errorTypes.CHILDREN}
            unsupportedPolicy={unsupportedPolicy}
          >
            <IsTruthy value={showErrorPage}>
              <FourHundredFourError />
            </IsTruthy>
            <IsFalsy value={showErrorPage}>
              <div id="print_disclosure" className="print-only">
                <img
                  id="mm_footer_logo_img"
                  className="mm-logo"
                  alt="MassMutual Logo"
                  width="150"
                  src={MMLogo}
                />{" "}
                <p>
                  The information shown is as of {formattedAsOfDate} and could
                  differ based on factors such as the last premium/purchase
                  payment received, the policy/contract status, and any
                  policy/contract changes that may have been submitted but not
                  yet processed since that date. This report does not replace
                  the official statements that you receive about this
                  policy/contract. You should rely on your official statements
                  as the most accurate source of information.
                </p>
              </div>

              <AnnuityContractInfoHeader
                policyId={policyId}
                agreementRequest={agreementRequest}
                producersRequest={producersRequest}
                annuityRequest={annuityRequest}
                openDrawer={openDrawer}
                unsupportedPolicy={unsupportedPolicy}
                billingRequest={billingRequest}
                soaBillingRequest={soaBillingRequest}
                user={user}
                serviceHistoryRequest={serviceHistoryRequest}
                deathClaimEligibilityRequest={deathClaimEligibilityRequest}
                lineOfBusinessCode={lineOfBusinessCode}
              />
              <IsTruthy value={displayKpi}>
                <KPI
                  policyId={policyId}
                  agreementKey={agreementKey}
                  agreementRequest={agreementRequest}
                  unsupportedPolicy={unsupportedPolicy}
                  billingRequest={billingRequest}
                  annuityRequest={annuityRequest}
                  cdaValuesRequest={cdaValuesRequest}
                  email={email}
                />
              </IsTruthy>
              <p id="as_of_date_value" className="copy-md mm-spacing">
                As of {formattedAsOfDate}
              </p>
              <hr className="mm-spacing" />
              <div id="printButton" className="justify-content-end">
                <button
                  onClick={() => printPDP()}
                  data-bp-link="default"
                  className="btn btn-inline hide-print"
                  aria-label="Print"
                  type="button"
                >
                  <span className="icon-print" />
                  <span>Print</span>
                </button>
              </div>

              <ScrollSpy
                navClass="col-md-3 sn-scrollspy_navigation"
                contentClass="col-md-9 sn-scrollspy_content"
                behavior="smooth"
                placement="auth"
              >
                <div name="Contract Details" data-id="contractDetails">
                  <SelectAsOfDate
                    agreementRequest={agreementRequest}
                    annuityRequest={annuityRequest}
                    setAsOfDate={setAsOfDate}
                    showLoadingIcon={showLoadingIcon}
                    showRecalcValues={showRecalcValues}
                    action="VALUE_RECALC"
                  />
                  <ContractDetails
                    id="contractDetails"
                    agreementRequest={agreementRequest}
                    annuityRequest={annuityRequest}
                    unsupportedPolicy={unsupportedPolicy}
                    recalculatedValues={recalculatedValues}
                    showRecalcValues={showRecalcValues}
                    soaBillingRequest={soaBillingRequest}
                    cdaValuesRequest={cdaValuesRequest}
                  />
                </div>
                {!hideInvestments && (
                  <div name="Investments" data-id="investments">
                    <Investments
                      id="investments"
                      agreementKey={agreementKey}
                      annuityRequest={annuityRequest}
                      agreementRequest={agreementRequest}
                      recalculatedValues={recalculatedValues}
                      showRecalcValues={showRecalcValues}
                      cdaValuesRequest={cdaValuesRequest}
                    />
                  </div>
                )}
                {!hideContractFeatures && (
                  <div name="Contract Features" data-id="contractFeatures">
                    <Riders
                      id="contractFeatures"
                      agreementRequest={agreementRequest}
                      annuityRequest={annuityRequest}
                    />
                  </div>
                )}
                <div name="People On Contract" data-id="peopleOnContract">
                  <PeopleOnContract
                    id="peopleOnContract"
                    agreementKey={agreementKey}
                    agreementRequest={agreementRequest}
                    producerData={producersRequest?.data}
                    annuityRequest={annuityRequest}
                    policyId={policyId}
                  />
                </div>
                <div name="Financial History" data-id="financialHistory">
                  <FinancialHistorySection
                    id="financial-History"
                    agreementKey={agreementKey}
                    agreementRequest={agreementRequest}
                  />
                </div>
                {!hideBillingPayment && (
                  <div name="Billing/Payments" data-id="billing-Payment">
                    <Billing
                      id="billing-Payment"
                      agreementKey={agreementKey}
                      agreementRequest={agreementRequest}
                      billingRequest={billingRequest}
                      policyId={policyId}
                      email={email}
                      annuityRequest={annuityRequest}
                      soaBillingRequest={soaBillingRequest}
                    />
                  </div>
                )}
                {!hideLoan && (
                  <div name="Loans" data-id="loans">
                    <AnnuityLoan
                      id="loans"
                      annuityRequest={annuityRequest}
                      agreementRequest={agreementRequest}
                    />
                  </div>
                )}
                <div name="Service History" data-id="serviceHistory">
                  <ServiceHistory
                    id="serviceHistory"
                    agreementKey={agreementKey}
                    agreementRequest={agreementRequest}
                    policyId={policyId}
                    email={email}
                    serviceRequest={serviceHistoryRequest}
                  />
                </div>
                <div name="Documents" data-id="documents">
                  <Documents
                    id="documents"
                    agreementKey={agreementKey}
                    agreementRequest={agreementRequest}
                    policyId={policyId}
                    email={email}
                    statementPreferencesRequest={statementPreferencesRequest}
                    isDeliveryPreferenceEligibleAnnuity={
                      isDeliveryPreferenceEligibleAnnuity
                    }
                  />
                </div>
              </ScrollSpy>

              <Modal
                show={isDrawerOpened}
                onHide={closeDrawer}
                className="modal--drawer"
              >
                <Modal.Header>
                  <button
                    onClick={closeDrawer}
                    type="button"
                    className="close"
                    aria-label="Close"
                  />
                </Modal.Header>
                <Modal.Body id="sideDrawer">
                  <PolicyAndContactInfoDrawer
                    policyId={policyId}
                    agreementRequest={agreementRequest}
                    producerData={producersRequest?.data}
                    producerRequest={producersRequest}
                    customer={customer}
                    annuityRequest={annuityRequest}
                    billingRequest={billingRequest}
                    soaBillingRequest={soaBillingRequest}
                  />
                </Modal.Body>
              </Modal>
            </IsFalsy>
          </LoadingSection>
        </LoadingSection>
      </LoadingSection>
    </LoadingSection>
  );
};

export default React.memo(AnnuityDetails);
