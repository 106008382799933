import React from "react";
import Radio from "./radio";
import Label from "./label";
import IsTruthy from "../logic/isTruthy";
import { isDefined } from "../../../utils/evaluate";

const RadioForm = (props) => {
  const { formField, badgeText, useHook, customProps } = props;
  const displayBadge = isDefined(badgeText);
  return (
    <div className="custom-control custom-radio">
      <Radio
        formField={formField}
        useHook={useHook}
        customProps={customProps}
      />
      <Label formField={formField} className="custom-control-label" />
      <IsTruthy value={displayBadge}>
        {" "}
        <span className="badge badge-pill badge-secondary">{badgeText}</span>
      </IsTruthy>
    </div>
  );
};

export default RadioForm;
