import React from "react";
import Stepper from "../../common/forms/stepper";
import IsTruthy from "../../common/logic/isTruthy";
import LdBannerContent from "../../common/molecules/ldBannerContent";
import { TRANSACTIONS_DISABLED_MESSAGE } from "../../../constants/ui";

const DigitalRegistrationHeader = (props) => {
  const { pageNumber, shouldDisableSubmit } = props;

  return (
    <nav role="navigation" className="mm-navbar--transaction">
      <div className="mm-logo" />
      <IsTruthy value={pageNumber < 3}>
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <Stepper totalSteps={2} currentStep={pageNumber} />
          </div>
        </div>
        <div className="container mm-spacing">
          <LdBannerContent />
          <p />
        </div>
        <IsTruthy value={shouldDisableSubmit}>
          <div className="alert alert-danger" role="alert">
            {TRANSACTIONS_DISABLED_MESSAGE}
          </div>
        </IsTruthy>
        <h1 id="header-pre-register">Pre-Register Your Client</h1>
        <p id="header-required-field" className="mm-sub-copy">
          * = Required Field
        </p>
      </IsTruthy>
    </nav>
  );
};

export default DigitalRegistrationHeader;
