import React from "react";
import { formatPalirVntg1SectionData } from "./riderSectionHelper";

const PalirVntg1RiderSection = (props) => {
  const {
    riderType,
    maxAnnualPaymentAmount,
    premiumStartDate,
    premiumEndDate,
    yearToDatePremiumPaidAmount,
    annualPaymentCount,
    previousYearPaymentAmount,
    remainingPaymentAmount,
  } = formatPalirVntg1SectionData(props);

  return (
    <div id="palir_vntg1_rider">
      <div className="row">
        <div className="col-12 col-md-6">
          <dl className="mm-key-value-pair">
            <dt id="rider_name_palirVntg1_premium_lbl">Rider Name</dt>
            <dd id="rider_name_palirVntg1_premium_value">{riderType}</dd>
            <dt id="max_annual_payment_start_date_palirVntg1_lbl">
              Maximum Annual Payment Start Date
            </dt>
            <dd id="max_annual_payment_start_date_palirVntg1_value">
              {premiumStartDate}
            </dd>
            <dt id="ytd_payment_amount_lbl">YTD Payment Amount Total</dt>
            <dd id="ytd_payment_amount_value">{yearToDatePremiumPaidAmount}</dd>
            <dt id="remaining_payment_amount_lbl">
              Remaining Payment Amount For The Year
            </dt>
            <dd id="remaining_payment_amount_value">
              {remainingPaymentAmount}
            </dd>
          </dl>
        </div>
        <div className="col-12 col-md-6">
          <dl className="mm-key-value-pair">
            <dt id="max_annual_payment_amt_palirVntg1_lbl">
              Maximum Annual Payment Amount
            </dt>
            <dd id="max_annual_payment_amt_palirVntg1_value">
              {maxAnnualPaymentAmount}
            </dd>
            <dt id="max_annual_payment_end_date_palirVtng1_lbl">
              Maximum Annual Payment End Date
            </dt>
            <dd id="max_annual_payment_end_date_palirVtng1_value">
              {premiumEndDate}
            </dd>
            <dt id="ytd_number_of_payments_made_palirVtng1_lbl">
              YTD # of Payments Made For The Year
            </dt>
            <dd id="ytd_number_of_payments_made_palirVtng1_value">
              {annualPaymentCount}
            </dd>
            <dt id="total_payment_amt_last_year_palirVtng1_lbl">
              Total Payment Amount Last Year
            </dt>
            <dd id="total_payment_amt_last_year_palirVtng1_value">
              {previousYearPaymentAmount}
            </dd>
          </dl>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default React.memo(PalirVntg1RiderSection);
