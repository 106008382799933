import React from "react";
import { TRANSACTIONS_DISABLED_MESSAGE } from "../../../../constants/ui";
import Stepper from "../../../common/forms/stepper";
import IsTruthy from "../../../common/logic/isTruthy";

const FundTransferHeader = (props) => {
  const { pageNumber, previousPage, shouldDisableSubmit } = props;
  return (
    <>
      <nav role="navigation" className="mm-navbar--transaction">
        <div className="mm-logo" id="servicenet_logo_img" />
        <IsTruthy value={pageNumber < 3}>
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <Stepper totalSteps={2} currentStep={pageNumber} />
            </div>
          </div>
        </IsTruthy>
      </nav>
      <IsTruthy value={pageNumber === 2}>
        <button
          id="go_back_btn"
          type="button"
          className="btn btn-link mm-spacing--minor"
          onClick={previousPage}
        >
          <span className="icon-arrow-circle-spin-left" />
          <span>Previous Step</span>
        </button>
      </IsTruthy>
      <IsTruthy value={pageNumber < 3}>
        <p className="eyebrow" id="fund_transfer_step_lbl">
          TRANSFER FUNDS: STEP {pageNumber} of 2
        </p>
        <IsTruthy value={shouldDisableSubmit}>
          <div className="alert alert-danger" role="alert">
            {TRANSACTIONS_DISABLED_MESSAGE}
          </div>
        </IsTruthy>
      </IsTruthy>
    </>
  );
};
export default FundTransferHeader;
