import React from "react";
import { compareStrings, stringIsEmptyOrNullish } from "../../../utils/string";
import IsFalsy from "../logic/isFalsy";
import CustomerInfo from "./customerInfo";
import { useFormatBasicPolicyInfoProps } from "./hooks";
import IsTruthy from "../logic/isTruthy";
import LifePolicyInfo from "../../policyDetails/sections/basicPolicyInfoHeader/life/policyInfo";
import DisabilityPolicyInfo from "../../policyDetails/sections/basicPolicyInfoHeader/disability/policyInfo";
import AnnuityContractInfo from "../../policyDetails/sections/basicPolicyInfoHeader/annuity/contractInfo";
import LoadingSection, { errorTypes, loadingTypes } from "../loading";
import {
  LOADING_ICON_CUSTOM_MESSAGE,
  CONTRACT_NOT_IF_ALERT_TEXT,
} from "../../../constants/ui";
import { fireTealiumAndFollowLink } from "../../../utils/tealium";
import { useUserState } from "../../../contexts/user";

const BasicPolicyAndContactInfo = (props) => {
  const {
    policyId,
    customerData,
    producerData,
    agreementData,
    target,
    agreementRequest,
    customerRequest,
    producerRequest,
    annuityRequest,
    customerOwnerFullName,
    billingData,
    billingRequest,
    soaBillingRequest,
    memberId,
  } = props;

  const {
    lineOfBusinessCode,
    formattedLineOfBusinessCode,
    formattedStatusCode,
    badgeColor,
    viewPolicyDetailsLink,
    trimmedPolicyId,
    envisionPolicy,
    isFixedAnnuityContract,
    policyInForce,
    policyOrContract,
  } = useFormatBasicPolicyInfoProps(props);
  const {
    user: { email },
  } = useUserState();

  return (
    <div>
      <LoadingSection
        request={agreementRequest}
        loadingType={loadingTypes.SPINNER}
        errorType={errorTypes.SECTION}
        customErrorMessage={LOADING_ICON_CUSTOM_MESSAGE}
      >
        <LoadingSection
          request={customerRequest}
          loadingType={loadingTypes.SPINNER}
          errorType={errorTypes.SECTION}
          customErrorMessage={LOADING_ICON_CUSTOM_MESSAGE}
        >
          <LoadingSection
            request={producerRequest}
            loadingType={loadingTypes.SPINNER}
            errorType={errorTypes.SECTION}
            customErrorMessage={LOADING_ICON_CUSTOM_MESSAGE}
          >
            <LoadingSection
              request={billingRequest}
              loadingType={loadingTypes.SPINNER}
              errorType={errorTypes.SECTION}
              customErrorMessage={LOADING_ICON_CUSTOM_MESSAGE}
            >
              <div className="mm-spacing">
                <p className="eyebrow">
                  {policyOrContract}
                  Owner
                </p>
                <h2 id="customer_full_name_value">{customerOwnerFullName}</h2>

                <CustomerInfo
                  agreementData={agreementData}
                  customerData={customerData}
                  target={target}
                  policyId={trimmedPolicyId}
                  envisionPolicy={envisionPolicy}
                  billingData={billingData}
                  annuityData={annuityRequest?.data}
                  memberId={memberId}
                />
              </div>
              <div className="mm-spacing">
                <h2>
                  <span id="user_policy_title_value">
                    {policyId} ({formattedLineOfBusinessCode})
                  </span>
                  <span
                    id="policy_status_value"
                    className={`badge badge-pill ${badgeColor}`}
                  >
                    {formattedStatusCode}
                  </span>
                </h2>
                <div className="mm-sub-copy">
                  <IsFalsy
                    value={stringIsEmptyOrNullish(viewPolicyDetailsLink)}
                  >
                    <button
                      type="button"
                      id="view_policy_details_link"
                      data-bp-link="default"
                      onClick={() =>
                        fireTealiumAndFollowLink({
                          url: `${viewPolicyDetailsLink}`,
                          action: "View Details link clicked",
                          category: "drawer",
                          value: trimmedPolicyId,
                          custId: email,
                          target: "_blank",
                        })
                      }
                      className="btn btn-inline"
                    >
                      {" "}
                      View Details
                    </button>
                  </IsFalsy>
                </div>
                <IsTruthy value={compareStrings(lineOfBusinessCode, "ANN")}>
                  <IsTruthy value={isFixedAnnuityContract && !policyInForce}>
                    <div className="alert alert-info mm-spacing" role="alert">
                      {CONTRACT_NOT_IF_ALERT_TEXT}
                    </div>
                  </IsTruthy>
                  <AnnuityContractInfo
                    policyId={policyId}
                    agreementData={agreementData}
                    producerData={producerData}
                    annuityRequest={annuityRequest}
                    soaBillingRequest={soaBillingRequest}
                  />
                </IsTruthy>
                <IsTruthy value={compareStrings(lineOfBusinessCode, "LIFE")}>
                  <LifePolicyInfo
                    policyId={policyId}
                    agreementData={agreementData}
                    producerData={producerData}
                  />
                </IsTruthy>
                <IsTruthy value={compareStrings(lineOfBusinessCode, "DI")}>
                  <DisabilityPolicyInfo
                    policyId={policyId}
                    agreementData={agreementData}
                    producerData={producerData}
                  />
                </IsTruthy>
              </div>
            </LoadingSection>
          </LoadingSection>
        </LoadingSection>
      </LoadingSection>
    </div>
  );
};

export default React.memo(BasicPolicyAndContactInfo);
