/* eslint-disable jsx-a11y/label-has-for */
import React from "react";
import IsTruthy from "../logic/isTruthy";
import IsFalsy from "../logic/isFalsy";

const Label = (props) => {
  const {
    children,
    className,
    formField: { id, label, labelBold = false, required = false },
  } = props;

  return (
    <label
      id={`label-${id}`}
      style={{ overflowWrap: "anywhere" }}
      className={className}
      htmlFor={`input-${id}`}
    >
      <IsTruthy value={labelBold}>
        <strong>
          {label || children}
          <IsTruthy value={required}>*</IsTruthy>
        </strong>
      </IsTruthy>
      <IsFalsy value={labelBold}>
        {label || children}
        <IsTruthy value={required}>*</IsTruthy>
      </IsFalsy>
    </label>
  );
};

export default React.memo(Label);
