import React from "react";
import IsTruthy from "../logic/isTruthy";
import IsFalsy from "../logic/isFalsy";
import { useFeatureFlagProps } from "./hooks";

const FeatureFlag = (props) => {
  const { children } = props;
  const { isOn, message } = useFeatureFlagProps(props);

  return (
    <>
      <IsTruthy value={isOn}>{children}</IsTruthy>
      <IsFalsy value={isOn}>{message}</IsFalsy>
    </>
  );
};

export default FeatureFlag;
