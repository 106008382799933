import React from "react";
import { TRANSACTIONS_DISABLED_MESSAGE } from "../../constants/ui";
import IsTruthy from "../common/logic/isTruthy";
import Stepper from "../common/forms/stepper";
import LdBannerContent from "../common/molecules/ldBannerContent";

const DirectLinksHeader = (props) => {
  const { shouldDisableSubmit, pageNumber, previousPage } = props;

  return (
    <nav role="navigation" className="mm-navbar--transaction">
      <div className="mm-logo" />
      <IsTruthy value={pageNumber < 4}>
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <Stepper totalSteps={3} currentStep={pageNumber} />
          </div>
        </div>
      </IsTruthy>
      <IsTruthy value={pageNumber > 1 && pageNumber < 4}>
        <button
          id="go_back_btn"
          type="button"
          className="btn btn-link mm-spacing--minor"
          onClick={previousPage}
        >
          <span className="icon-arrow-circle-spin-left" />
          <span>Previous Step</span>
        </button>
      </IsTruthy>
      <IsTruthy value={pageNumber < 4}>
        <p className="eyebrow">SEND DIRECT LINK: STEP {pageNumber} of 3</p>
        <LdBannerContent />
        <IsTruthy value={shouldDisableSubmit}>
          <div
            className="alert alert-danger"
            role="alert"
            id="alert-transaction-disable"
          >
            {TRANSACTIONS_DISABLED_MESSAGE}
          </div>
        </IsTruthy>
      </IsTruthy>
    </nav>
  );
};

export default DirectLinksHeader;
