import React from "react";
import { compareStrings } from "../../../utils/string";
import IsTruthy from "../logic/isTruthy";
import IsFalsy from "../logic/isFalsy";
import { InformationPopover } from "../popovers/informationPopover";
import { useUserRegisteredSignifierProps } from "./hooks";
import { DigitalRegistrationLink } from "../../home/digitalRegistrationLink";

const UserRegisteredSignifier = (props) => {
  const {
    isCalledFrom,
    indexNumber,
    agreementData,
    customer,
    trimmedPolicyId,
    lineOfBusinessCode,
  } = props;
  const {
    userRegisteredMessageTooltipText,
    userRegisteredIcon,
    userRegisteredMessage,
  } = useUserRegisteredSignifierProps(props);
  return (
    <>
      <sup className="print-only">{indexNumber + 1}</sup>
      <span className="hide-print">
        <IsFalsy value={compareStrings(isCalledFrom, "drawer")}>
          <span className="mm-illustration-buffer">
            <InformationPopover
              triggerEvent={["hover", "focus"]}
              text={userRegisteredMessageTooltipText}
              iconName={userRegisteredIcon}
              popoverId="policy_detail_user_mmregistered_status"
              iconSize="md"
              placement="right"
            />
          </span>
        </IsFalsy>
        <IsTruthy value={compareStrings(isCalledFrom, "drawer")}>
          <span
            id="registered_status_icon"
            className={`icon-${userRegisteredIcon} icon-md mm-illustration-buffer`}
          />
          {userRegisteredMessage}
          <DigitalRegistrationLink
            agreementData={agreementData}
            customer={customer}
            policyId={trimmedPolicyId}
            isCalledFrom={isCalledFrom}
            lineOfBusinessCode={lineOfBusinessCode}
          />
        </IsTruthy>
      </span>
      <ol className="print-only">
        <li value={indexNumber + 1}>{userRegisteredMessageTooltipText}</li>
      </ol>
    </>
  );
};

export default React.memo(UserRegisteredSignifier);
