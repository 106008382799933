/* eslint-disable import/prefer-default-export */
import React from "react";
import Table from "../../../common/table";
import { RequestInformationLink } from "../../../common/popovers/informationPopover";
import IsTruthy from "../../../common/logic/isTruthy";
import IsFalsy from "../../../common/logic/isFalsy";
import { checkComplexBeneArrangement } from "./hooks";
import { isDefined } from "../../../../utils/evaluate";
import { checkStatusAndReason } from "../../types/life/hooks";
import {
  NO_BENEFICIARY_INFO_ALERT_TEXT,
  NO_DISTRIBUTION_DISPLAYED_MESSAGE,
} from "../../../../constants/ui";
import { contactUSDetails } from "../../../../data/contactUsDetails";

export const BeneficiariesDisplay = (props) => {
  const {
    agreementKey,
    agreementData,
    policyId,
    columnsBeneficiary,
    beneficiariesSortByBeneficiaryClass,
    complexBeneficiaries,
    showBeneficiaryModal,
    isConvertedHapPolicy,
    tableClass,
    showFootnote,
    isPallm,
    contactUsInfo,
    isNonEnvisionAnnuityContract,
    displayNoBeneInfoAlert,
  } = props;
  return (
    <>
      <IsTruthy value={displayNoBeneInfoAlert && isNonEnvisionAnnuityContract}>
        <h3 id="people_on_policy_hap_beneficiary_lbl">Beneficiary(s)</h3>
        <hr />
        <div
          className="alert alert-info mm-spacing"
          role="alert"
          id="no_beneficiary_info_available_alert"
        >
          {NO_BENEFICIARY_INFO_ALERT_TEXT}
          <a href={`tel:+${contactUsInfo?.formattedContactUsNumber}`}>
            {contactUsInfo?.formattedContactUsNumber}
          </a>
          , {contactUsInfo?.formattedContactUsTime}
        </div>
      </IsTruthy>
      <IsFalsy value={displayNoBeneInfoAlert && isNonEnvisionAnnuityContract}>
        <IsTruthy value={isConvertedHapPolicy}>
          <>
            <h3 id="people_on_policy_hap_beneficiary_lbl">Beneficiary(s)</h3>

            <IsTruthy value={beneficiariesSortByBeneficiaryClass.length > 0}>
              <Table
                id="people_on_policy_hap_beneficiary_table"
                tableClass={tableClass}
                columns={columnsBeneficiary}
                rows={beneficiariesSortByBeneficiaryClass}
                showPagination={false}
                customFunctions={showBeneficiaryModal}
              />
              <IsTruthy value={showFootnote}>
                <p
                  data-testid="hap_beneficiary_no_distribution_message"
                  className="copy-md"
                >
                  {NO_DISTRIBUTION_DISPLAYED_MESSAGE}
                </p>
              </IsTruthy>
            </IsTruthy>
            <IsFalsy value={beneficiariesSortByBeneficiaryClass.length > 0}>
              <hr />
              <div
                className="alert alert-info"
                role="alert"
                data-testid="beneficiary_information_unavailable_alert"
                id="beneficiary_information_unavailable_alert"
              >
                At this time, beneficiary information is not available for this
                policy. A Coverpath Customer Service representative can assist
                you with additional details including designation and
                percentages. Call{" "}
                <a href={`tel:+${contactUSDetails.COVERPATH.number}`}>
                  {contactUSDetails.COVERPATH.number}
                </a>
                , {contactUSDetails.COVERPATH.time}.
              </div>
              <hr />
            </IsFalsy>
          </>
        </IsTruthy>
        <IsFalsy value={isConvertedHapPolicy}>
          <IsTruthy
            value={beneficiariesSortByBeneficiaryClass}
            validationFunction={(benes) => benes.length > 0}
          >
            <IsFalsy
              value={complexBeneficiaries}
              validationFunction={checkComplexBeneArrangement}
            >
              <h3 id="people_on_policy_beneficiaries_lbl">Beneficiary(s)</h3>
              <Table
                id="beneficiaries"
                tableClass={tableClass}
                columns={columnsBeneficiary}
                rows={beneficiariesSortByBeneficiaryClass}
                showPagination={false}
                customFunctions={showBeneficiaryModal}
              />
              <IsTruthy value={showFootnote}>
                <p className="copy-md">{NO_DISTRIBUTION_DISPLAYED_MESSAGE}</p>
              </IsTruthy>
            </IsFalsy>
          </IsTruthy>
          <IsTruthy
            value={complexBeneficiaries[0]?.beneficiaryDesignationDescription}
            validationFunction={isDefined}
          >
            <>
              <h3 id="people_on_policy_beneficiary_arrangement_lbl">
                Beneficiary Arrangement
              </h3>
              <hr />
              <IsTruthy
                value={complexBeneficiaries}
                validationFunction={checkComplexBeneArrangement}
              >
                <IsTruthy value={isNonEnvisionAnnuityContract}>
                  <div
                    className="alert alert-info mm-spacing"
                    role="alert"
                    id="no_beneficiary_info_available_alert"
                  >
                    {NO_BENEFICIARY_INFO_ALERT_TEXT}
                    <a href={`tel:+${contactUsInfo?.formattedContactUsNumber}`}>
                      {contactUsInfo?.formattedContactUsNumber}
                    </a>
                    , {contactUsInfo?.formattedContactUsTime}
                  </div>
                </IsTruthy>
                <IsFalsy value={isNonEnvisionAnnuityContract}>
                  <IsTruthy
                    value={agreementData}
                    validationFunction={checkStatusAndReason}
                  >
                    <p>
                      The information is not available online or via the call
                      center. Click to submit a request for this information.
                    </p>
                    <RequestInformationLink
                      popoverId="beneficiaries"
                      agreementData={agreementData}
                      agreementKey={agreementKey}
                      policyId={policyId}
                    />
                  </IsTruthy>
                </IsFalsy>
                <IsFalsy
                  value={agreementData}
                  validationFunction={checkStatusAndReason}
                >
                  Unavailable
                </IsFalsy>
              </IsTruthy>
              <IsFalsy
                value={complexBeneficiaries}
                validationFunction={checkComplexBeneArrangement}
              >
                {complexBeneficiaries?.map((beneficiary, index) => (
                  <p
                    id={`people_on_policy_beneficiary_arrangement_${index}_value`}
                    key={beneficiary?.beneficiaryDesignationDescription}
                  >
                    {beneficiary?.beneficiaryDesignationDescription}
                  </p>
                ))}
              </IsFalsy>
              <hr />
            </>
          </IsTruthy>
        </IsFalsy>
      </IsFalsy>
      <IsTruthy value={isPallm}>
        <h3 id="people_on_policy_hap_beneficiary_lbl">Beneficiary(s)</h3>
        <hr />
        <div className="alert alert-info" role="alert">
          For servicing information please contact{" "}
          <a href="tel:+1-844-475-7016">1-844-475-7016</a>, 9:00 AM - 6:00 PM
          ET, Monday to Friday.
        </div>
      </IsTruthy>
    </>
  );
};
