import useGooglePlacesAutocompleteService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { getConfig, types as configTypes } from "../../../config";
import { types, useFormContext } from "../../../contexts/form";
import { isDefined, isEmptyObject } from "../../../utils/evaluate";
import { formFormatting } from "../../../utils/formFormatting";
import { capitalize } from "../../../utils/format";
import {
  compareArrayOfStrings,
  stringIsEmptyOrNullish,
} from "../../../utils/string";
import { hasError } from "../../../utils/validation";
import { useShouldRunValidation } from "../forms/hooks";
import { getAddressfromGoogleApiAddress } from "../../../utils/address";

export const calculateGooglePlaceMatchedSubStrings = (props) => {
  const { description, structured_formatting: structuredFormatting } = props;

  const matchedSubString =
    structuredFormatting &&
    structuredFormatting?.main_text_matched_substrings &&
    structuredFormatting?.main_text_matched_substrings.length > 0 &&
    structuredFormatting?.main_text_matched_substrings[0];

  let matchedSubStringLength = 0;
  if (structuredFormatting?.main_text_matched_substrings?.length > 1) {
    const totalLength = structuredFormatting?.main_text_matched_substrings
      .map((item) => item.length)
      .reduce((prev, next) => prev + next);
    matchedSubStringLength = totalLength + 1;
  } else {
    matchedSubStringLength = matchedSubString?.length;
  }
  let addressParts = {};

  if (matchedSubString) {
    addressParts = {
      beforeAddressPart: description.substr(0, matchedSubString.offset),
      matchedAddressPart: description.substr(
        matchedSubString.offset,
        matchedSubStringLength
      ),
      afterAddressPart: description.substr(
        matchedSubString.offset + matchedSubStringLength
      ),
    };
  }

  if (isEmptyObject(addressParts)) {
    addressParts.beforeAddressPart = description;
  }
  return { addressParts };
};

export const useAddressTextboxProps = (props) => {
  const { dispatchForm, formData } = useFormContext();
  const { dependentAddressFieldIds } = props;

  const editAddressAddress1 =
    formData[dependentAddressFieldIds.addressLine1Field];
  const editAddressAddress2 =
    formData[dependentAddressFieldIds.addressLine2Field];
  const editAddressCity = formData[dependentAddressFieldIds.addressCityField];
  const editAddressState = formData[dependentAddressFieldIds.addressStateField];
  const editAddressZipCode = formData[dependentAddressFieldIds.addressZip];
  const editAddressCountry = formData[dependentAddressFieldIds.addressCountry];

  const formField = editAddressAddress1;
  const GOOGLE_API_KEY = getConfig(configTypes.googleApiKey);
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = useGooglePlacesAutocompleteService({
    apiKey: GOOGLE_API_KEY,
    options: {
      componentRestrictions: { country: "us" },
      libraries: ["places"],
      types: ["address"],
    },
  });
  const displayPlacesPredictionCssClass = !!(
    !isPlacePredictionsLoading && placePredictions.length
  );
  const handlePlaceSelectedButtonClick = (selectedItem) => () => {
    if (placePredictions.length) {
      placesService?.getDetails(
        {
          placeId: selectedItem.place_id,
          fields: [
            "geometry",
            "address_components",
            "types",
            "formatted_address",
          ],
        },
        (placeDetails) => {
          const formattedAddress = getAddressfromGoogleApiAddress({
            geocoded: placeDetails.address_components,
            original: selectedItem,
          });
          if (isDefined(formattedAddress)) {
            dispatchForm({
              type: types.REPLACE_FIELD,
              formField: {
                ...editAddressAddress1,
                value: formattedAddress.street,
              },
            });
            dispatchForm({
              type: types.REPLACE_FIELD,
              formField: {
                ...editAddressAddress2,
                value: isDefined(formattedAddress.addLine2)
                  ? capitalize(formattedAddress.addLine2)
                  : "",
              },
            });
            dispatchForm({
              type: types.REPLACE_FIELD,
              formField: {
                ...editAddressCity,
                value: formattedAddress.city,
              },
            });
            dispatchForm({
              type: types.REPLACE_FIELD,
              formField: {
                ...editAddressState,
                value: formattedAddress.stateCode,
                display: formattedAddress.state,
              },
            });
            dispatchForm({
              type: types.REPLACE_FIELD,
              formField: {
                ...editAddressZipCode,
                value: formattedAddress.zip,
              },
            });
          }
        }
      );
    }
    placePredictions.length = 0;
  };
  const useHook = () => {
    const onChange = (event) => {
      if (
        compareArrayOfStrings(
          ["United States of America", "United States", "USA"],
          editAddressCountry.value
        )
      ) {
        getPlacePredictions({ input: event.target.value });
      }
      const newValue = formFormatting({
        value: event.target.value,
        formatting: formField.formatting,
      });
      const FIELD_IS_EMPTY = stringIsEmptyOrNullish(newValue);
      dispatchForm({
        type: types.UPDATE_FIELD_VALUE,
        formField,
        newValue,
        postpone: FIELD_IS_EMPTY,
      });
    };
    const onPaste = (event) => {
      if (formField.disablePaste) {
        event.preventDefault();
        return false;
      }
      return true;
    };
    const onBlur = (event) => {
      if (!event.relatedTarget?.id?.includes("place_prediction_button")) {
        placePredictions.length = 0;
        dispatchForm({
          type: types.REPLACE_FIELD,
          formField: {
            ...editAddressAddress1,
            value: formField.value,
          },
        });
      }
    };
    useShouldRunValidation({ dispatchForm, formData, formField });
    const className = hasError(formField)
      ? "form-control is-invalid"
      : "form-control";
    return {
      onChange,
      onPaste,
      onBlur,
      className,
    };
  };
  return {
    placePredictions,
    isPlacePredictionsLoading,
    handlePlaceSelectedButtonClick,
    displayPlacesPredictionCssClass,
    useHook,
  };
};
