import React from "react";
import InputForm from "../forms/inputForm";
import IsFalsy from "../logic/isFalsy";
import LoadingSection, { errorTypes, loadingTypes } from "../loading";

import { requestorContactLayout, useRequestorContactProps } from "./hooks";
import IsTruthy from "../logic/isTruthy";
import Label from "../forms/label";

const RequestorContact = (props) => {
  const {
    requestorNameInput,
    contactChoiceEmailInput,
    contactChoiceConfirmEmailInput,
    loggedInUser,
    hideChoiceConfirmEmailInput,
    agentProfileRequest,
  } = useRequestorContactProps(props);
  const {
    layout = requestorContactLayout.PAGE,
    sectionHeader = "Requestor Contact*",
  } = props;

  return (
    <div id="requestorContactInformation" className="mm-spacing">
      <LoadingSection
        request={agentProfileRequest}
        loadingType={loadingTypes.PARAGRAPH}
        errorType={errorTypes.CHILDREN}
      >
        <IsTruthy value={layout === requestorContactLayout.PAGE}>
          <h1 id="requestor_contact_header_lbl">Requestor Contact</h1>
          <p
            className="mm-sub-copy mm-spacing"
            id="requestor_contact_required_note_lbl"
          >
            * = Required Field
          </p>

          <p className="h3" id="label-requestorName">
            Financial Professional
          </p>
          <p className="mm-spacing" id="label-requestorName-value">
            {loggedInUser}
          </p>

          <div className="row">
            <div className="col-12 col-lg-6 col-md-6 mm-spacing-minor--stacked">
              <p className="h3" id="label-contactChoiceEmailInput">
                Financial Professional Email*
              </p>
              <InputForm formField={contactChoiceEmailInput} />
            </div>
            <IsFalsy value={hideChoiceConfirmEmailInput}>
              <div className="col-12 col-lg-6 col-md-6 mm-spacing-minor--stacked">
                <p className="h3" id="label-contactChoiceConfirmEmailInput">
                  Confirm Email*
                </p>
                <InputForm formField={contactChoiceConfirmEmailInput} />
              </div>
            </IsFalsy>
          </div>
        </IsTruthy>
        <IsTruthy value={layout === requestorContactLayout.SECTION}>
          <p className="h3">{sectionHeader}</p>
          <div className="row">
            <div className="col-12 col-lg-4 mm-spacing-minor--stacked">
              <Label formField={requestorNameInput}>
                Financial Professional
              </Label>
              <InputForm formField={requestorNameInput} />
            </div>
            <div className="col-12 col-lg-4 col-md-6 mm-spacing-minor--stacked">
              <Label formField={contactChoiceEmailInput}>
                Financial Professional Email*
              </Label>
              <InputForm formField={contactChoiceEmailInput} />
            </div>
            <IsFalsy value={hideChoiceConfirmEmailInput}>
              <div className="col-12 col-lg-4 col-md-6 mm-spacing-minor--stacked">
                <Label formField={contactChoiceConfirmEmailInput}>
                  Confirm Email*
                </Label>
                <InputForm formField={contactChoiceConfirmEmailInput} />
              </div>
            </IsFalsy>
          </div>
        </IsTruthy>
      </LoadingSection>
    </div>
  );
};

export default React.memo(RequestorContact);
