import { useLDValue } from "../components/common/featureFlags/hooks";

import { compareStrings } from "./string";

export const useHideEdsDiAsOfDate = (props) => {
  const { agreementKeyAdmin } = props;
  const hideEdsDiAsOfDate = useLDValue({
    flagName: ["hideEdsDiAsOfDate"],
  });

  return compareStrings(agreementKeyAdmin, "EDS-DI") && hideEdsDiAsOfDate;
};
