import React from "react";
import InputForm from "../../common/forms/inputForm";

import { useEmailContactProps } from "../hooks";

const EmailContact = (props) => {
  const { emailContactInput, emailContactConfirmInput } =
    useEmailContactProps(props);

  return (
    <>
      <div className="col-12 col-lg-4 col-md-4 mm-spacing-minor--stacked">
        <InputForm formField={emailContactInput} />
      </div>
      <div className="col-12 col-lg-4 col-md-4 mm-spacing-minor--stacked">
        <InputForm formField={emailContactConfirmInput} />
      </div>
    </>
  );
};

export default React.memo(EmailContact);
