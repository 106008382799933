/* eslint-disable no-underscore-dangle */
import Block from "./blockLoading";
import Line from "./lineLoading";
import Paragragh from "./paragraphLoading";
import Spinner from "./spinnerLoading";
import FourHundredThreeError from "../errors/403";
import FourHundredFourError from "../errors/404";
import FiveHundredError from "../errors/500";
import SiteDown from "../errors/siteDown";
import SectionError from "../errors/section";
import { compareStrings } from "../../../utils/string";

import { isDefined } from "../../../utils/evaluate";
import DropdownError from "../errors/dropdownError";

export const usePickLoadingProps = (props) => {
  const {
    loadingType,
    errorType,
    request: { isLoading, error, executeRequest },
  } = props;

  const errorComponentRequired = errorType !== errorTypes.CHILDREN;

  const is500ErrorInLoadingSection = compareStrings(errorType, "500");

  return {
    LoadingComponent: pickLoadingComponent(loadingType),
    ErrorComponent: pickErrorComponent(errorType),
    isLoading,
    hasError: errorComponentRequired && isDefined(error) && !isLoading,
    retryRequest: executeRequest,
    showBackToPolicyButton: !is500ErrorInLoadingSection,
  };
};

export const pickErrorComponent = (type) => {
  switch (type) {
    case errorTypes._403:
      return FourHundredThreeError;
    case errorTypes._404:
      return FourHundredFourError;
    case errorTypes._500:
      return FiveHundredError;
    case errorTypes.SITE:
      return SiteDown;
    case errorTypes.SECTION:
      return SectionError;
    case errorTypes.NONE:
      return null;
    case errorTypes.DROPDOWN:
      return DropdownError;
    default:
      return SiteDown;
  }
};

export const errorTypes = {
  _403: "403",
  _404: "404",
  _500: "500",
  SITE: "SITE",
  NONE: "NONE",
  SECTION: "SECTION",
  CHILDREN: "CHILDREN",
  DROPDOWN: "DROPDOWN",
};

export const pickLoadingComponent = (type) => {
  switch (type) {
    case loadingTypes.BLOCK:
      return Block;
    case loadingTypes.LINE:
      return Line;
    case loadingTypes.PARAGRAPH:
      return Paragragh;
    case loadingTypes.SPINNER:
      return Spinner;
    case loadingTypes.NONE:
      return null;
    default:
      return Paragragh;
  }
};

export const loadingTypes = {
  BLOCK: "BLOCK",
  LINE: "LINE",
  PARAGRAPH: "PARAGRAPH",
  SPINNER: "SPINNER",
  NONE: "NONE",
};
