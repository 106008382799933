import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useDeepCompareEffect from "use-deep-compare-effect";
import { types, useFormContext } from "../../contexts/form";
import { useMakeRequest } from "../../hooks/api";
import { formatDate, getCurrentDate } from "../../utils/dates";
import {
  formatMoney,
  formatPercentage,
  formatAddress,
  formatName,
  nameFormattingOptions,
  extractNumberFromString,
  getLastXChars,
} from "../../utils/format";
import { formattingTypes } from "../../utils/formFormatting";
import { compareStrings, removeCommas, isString } from "../../utils/string";
import {
  hasError,
  useLoanValidation,
  useEmailValidation,
  useGeneralValidation,
  useUpsAccountValidation,
  useConfirmEmailValidation,
} from "../../utils/validation";
import {
  getBpIdFromUser,
  getRequestorInfo,
  getUsers,
  isHomeOfficeUser,
  userTypes,
  getTrimmedPolicyIdFromAgreement,
  getOwnerFromCustomers,
} from "../../utils/findData";
import { useUserState } from "../../contexts/user";
import {
  isLoanRestrictedSwlPolicy,
  verifyInitiateNewLoan,
} from "../../utils/policies";
import { initiateLoan } from "../../api";
import { isDefined, isFalsy } from "../../utils/evaluate";
import { FORM_REQUIRED_RESPONSE } from "../../constants/logic";
import {
  AUTHORIZATION_CONFIRMATION_STATEMENT,
  PROCESSING_ERROR_MESSAGE,
} from "../../constants/ui";
import {
  checkForEditAddressErrors,
  useEditAddressFields,
  useAddressVerificationModal,
  useUpdateEditAddressVariables,
} from "../common/editAddress/hooks";
import {
  convertBase64StringToByteArray,
  downloadPDF,
} from "../common/helpers/downloadHelper";
import { fireTealiumEvent } from "../../utils/tealium";
import { useRequestorContactEmail } from "../../hooks/helper";
import { checkForRequestorContactError } from "../common/requestorContact/hooks";
import { useLDValue } from "../common/featureFlags/hooks";
import { typesOfAgents } from "../../data/typesOfAgents";
import { useAddOrRemoveChatLauncher } from "../../utils/genesysChatClient";

export const useInitiateLoanProps = () => {
  const { agreementKey } = useParams();
  const { user } = useUserState();
  const shouldDisableSubmit = isHomeOfficeUser(user);
  const { isAnAgent, agentType, idNumber, bpId } = getBpIdFromUser(user);
  const { email } = user;
  const { trimmedPolicyId } = getTrimmedPolicyIdFromAgreement(agreementKey);

  const loanRequest = useMakeRequest({
    apiRequest: "fetchLoanData",
    apiParams: { agreementKey },
    immediateRequest: false,
    dataSelector: "processLoanInformation",
  });

  const producersRequest = useMakeRequest({
    apiRequest: "fetchProducersData",
    apiParams: { agreementKey },
    immediateRequest: false,
    dataSelector: "processProducersData",
  });
  const producerRequest = useMakeRequest({
    apiRequest: "fetchProducerData",
    apiParams: { producerId: bpId },
    immediateRequest: false,
    dataSelector: "processProducerData",
  });

  const agreementRequest = useMakeRequest({
    apiRequest: "fetchAgreementData",
    apiParams: { agreementKey },
    immediateRequest: true,
    dataSelector: "processAgreementRequest",
    dependentRequests: [loanRequest, producersRequest],
  });

  const agreementData = agreementRequest.data;
  const {
    agreementCustomers,
    policyId,
    agreementKeyAdmin,
    lineOfBusinessCode,
    isPolicyNotFound,
  } = agreementData;

  const isAgreementLoaded =
    !agreementRequest.isLoading && isDefined(agreementRequest?.data?.policyId);

  useEffect(() => {
    if (isAgreementLoaded) {
      loanRequest.executeRequest();
      producersRequest.executeRequest();
    }
  }, [isAgreementLoaded]);

  const { data: producerData } = producersRequest;
  useEffect(() => {
    if (isAnAgent && isAgreementLoaded) {
      producerRequest.executeRequest();
    }
  }, [isAnAgent, isAgreementLoaded]);

  const transactionSupportedPolicies = useLDValue({
    flagName: ["transactionSupportedPolicies"],
  });

  const isMmsdOnlyUser = compareStrings(agentType, typesOfAgents.MMSD);

  const isAddressOnFile = checkIsAddressOnFile({
    loanRequest,
    agreementKeyAdmin,
  });

  const [transactionId, setTransactionId] = useState("");
  const [errorText, setErrorText] = useState("");
  const [pagesToSkip, setPagesToSkip] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [documentId, setDocumentId] = useState("");
  const [page1error, setPage1Error] = useState(false);
  const [page2error, setPage2Error] = useState(false);
  const [page3error, setPage3Error] = useState(false);

  const [loanSubmissionError, setLoanSubmissionError] = useState(false);
  const [error, setError] = useState(null);
  const [showLoadingIcon, setShowLoadingIcon] = useState(false);
  const skipPage = (pageNumberVerify) => {
    if (pagesToSkip.length <= 0) return false;
    return pagesToSkip.includes(pageNumberVerify);
  };
  const nextPage = () => {
    let nextPageNumber = pageNumber + 1;
    if (skipPage(nextPageNumber)) nextPageNumber += 1;
    setPageNumber(nextPageNumber);
  };
  const lastPage = () => {
    let previousPageNumber = pageNumber - 1;
    if (skipPage(previousPageNumber)) previousPageNumber -= 1;
    setPageNumber(previousPageNumber);
  };
  const previousPage = () => {
    lastPage();
    setError(null);
    setPage1Error(false);
    setPage2Error(false);
    setPage3Error(false);
  };

  const onSubmit = (formData) => {
    setShowLoadingIcon(true);
    const headers = {
      Authorization: `Bearer ${user.accessToken}`,
    };
    const body = makeRequestBody({
      agreementKey,
      user,
      idNumber,
      loanRequest,
      producerRequest,
      producerData,
      formData,
      agreementCustomers,
      agreementKeyAdmin,
    });
    initiateLoan({ headers, apiParams: body, agreementKey })
      .then((result) => {
        setLoanSubmissionError(false);
        setTransactionId(result?.data?.transGuid);
        setShowLoadingIcon(false);
        if (result?.status !== 200) {
          setErrorText(PROCESSING_ERROR_MESSAGE);
          setLoanSubmissionError(true);
          throw new Error("non 200 response");
        } else {
          if (result?.data?.Messages?.msgType !== "SUCCESS") {
            throw new Error("request failed");
          }
          if (
            compareStrings(result?.data?.Messages?.msgType, "SUCCESS") &&
            compareStrings(
              result?.data?.Messages?.msgDesc,
              FORM_REQUIRED_RESPONSE
            )
          ) {
            setDocumentId(result?.data?.documentId);
            setPageNumber(7);
          } else {
            fireTealiumEvent({
              label: "SVNT",
              action: `Initiate a Loan Submitted`,
              category: "Initiate a Loan",
              value: policyId,
              custId: user?.email,
            });
            setPageNumber(6);
          }
        }
      })
      .catch(() => {
        fireTealiumEvent({
          label: "SVNT",
          action: `${lineOfBusinessCode} Initiate New Loan Failed`,
          category: "Initiate New Loan",
          value: trimmedPolicyId,
          custId: email,
        });
        setErrorText(PROCESSING_ERROR_MESSAGE);
        setLoanSubmissionError(true);
      });
  };

  const fields = useInitiateLoanFields({ loanRequest });
  const IS_UNAUTHORIZED = agreementData.isUnauthorized;

  const { isEligible, isTransactionAllowed, eligibilityError } =
    verifyInitiateNewLoan({
      policyId,
      loanData: loanRequest.data || {},
      agreementData,
      transactionSupportedPolicies,
    });

  const loanRestrictedSwlPolicy = isLoanRestrictedSwlPolicy(agreementData);

  useAddOrRemoveChatLauncher({
    agreementData,
    page: "Initiate New Loan transaction",
  });

  return {
    policyId,
    agreementKey,
    fields,
    pageNumber,
    nextPage,
    previousPage,
    documentId,
    agreementData,
    agreementRequest,
    loanRequest,
    error,
    setError,
    setPageNumber,
    pagesToSkip,
    setPagesToSkip,
    onSubmit,
    page1error,
    setPage1Error,
    page2error,
    setPage2Error,
    page3error,
    setPage3Error,
    transactionId,
    errorText,
    shouldDisableSubmit,
    IS_UNAUTHORIZED,
    email: user?.email,
    loanSubmissionError,
    showLoadingIcon,
    isEligible,
    isTransactionAllowed,
    isPolicyLoaded: isDefined(policyId),
    isMmsdOnlyUser,
    isAddressOnFile,
    isPolicyNotFound,
    loanRestrictedSwlPolicy,
    eligibilityError,
  };
};

export const useQuestionAndDisclosureProps = (props) => {
  const { pagesToSkip, setPagesToSkip } = props;
  const { formData } = useFormContext();
  const questionsText = [
    {
      question: "Is this policy individually-owned?*",
      note: "Note: If policy is not individually-owned, a signed loan form is required and will be available for download.",
    },
    {
      question:
        "Would the owner like to receive the loan disbursement through direct deposit?*",
      note: "Note: If yes, direct client to MassMutual.com. Otherwise, a signed loan and ACH/EFT form is required and will be available for download.",
    },
    {
      question:
        "To your knowledge, is this policy assigned, pledged or subject to any bankruptcy proceeding, attachment, lien or other claim?*",
      note: "Note: If yes, a signed loan form is required and will be available for download.",
    },
    {
      question: "Is this policy subject to a divorce decree?*",
      note: "Note: If yes, a signature of former spouse is required and will be available for download.",
    },
    {
      question: "Is the loan amount requested $200,000.00 or more?*",
      note: "Note: If yes, a signed loan form is required and will be available for download.",
    },
  ];

  const question2YesValue = formData[`question${2}yes`].value;
  const question2NoValue = formData[`question${2}no`].value;
  // handel skipping addressdetails step based on question2 answer

  const mailAddressPageNumber = 3;
  const items = [...pagesToSkip];
  const indexOfItem = items.indexOf(mailAddressPageNumber);
  if (question2YesValue && indexOfItem < 0) {
    items.push(mailAddressPageNumber);
  }
  if (question2NoValue && indexOfItem >= 0) {
    items.splice(indexOfItem, 1);
  }
  useDeepCompareEffect(() => {
    setPagesToSkip(items);
  }, [items]);

  return {
    formData,
    questionsText,
  };
};

export const useLoanDetailProps = (props) => {
  const { loanRequest, setPage2Error } = props;
  const { formData, dispatchForm } = useFormContext();
  const { specificAmountRadio, specificAmountInput, maxLoanAmountRadio } =
    formData;

  const {
    maxLoanQuoteEffDate,
    currentInterestRate,
    interestRateType,
    maxLoanAmount,
  } = loanRequest.data;
  const maxLoanAmtExceeds200k = parseFloat(maxLoanAmount) > 200000;

  useEffect(() => {
    dispatchForm({
      type: types.REPLACE_FIELD,
      formField: {
        ...specificAmountInput,
        maxLoanAmount,
      },
    });
  }, [maxLoanAmount]);

  useDeepCompareEffect(() => {
    dispatchForm({
      type: types.REPLACE_FIELD,
      formField: {
        ...specificAmountInput,
        hidden: !specificAmountRadio.value,
      },
    });
  }, [specificAmountRadio]);

  const validateSpecifiedAmount =
    specificAmountRadio.value &&
    (hasError(specificAmountInput) || specificAmountInput.postpone);
  const validateMaxLoanAmtRadio = !maxLoanAmountRadio.value;

  const disableContinueButton =
    validateSpecifiedAmount ||
    (validateMaxLoanAmtRadio && maxLoanAmountRadio.value);

  setPage2Error(disableContinueButton);

  return {
    formData,
    interestRateType: compareStrings(interestRateType, "V")
      ? "Variable"
      : "Fixed",
    maxLoanAmount,
    maxLoanAmountFormatted: formatMoney(maxLoanAmount),
    currentInterestRate: formatPercentage({ rate: currentInterestRate }),
    maxLoanQuoteEffDate: formatDate(maxLoanQuoteEffDate, "monthCommaDayYear"),
    maxLoanAmtExceeds200k,
  };
};

export const useCanContinue = (props) => {
  const { formData } = useFormContext();
  const { enterPolicyNewAddress } = formData;
  const {
    nextPage,
    error,
    pageNumber,
    setError,
    agreementData,
    isAddressOnFile,
  } = props;
  const { openAddressVerification } = useAddressVerificationModal({
    nextPage,
    setError,
  });

  if (isString(error)) {
    setError({ id: error, text: error });
  }

  const requestorContactEmail = useRequestorContactEmail();

  const onContinue = () => {
    switch (pageNumber) {
      case 1:
        checkForErrorsPage1({ formData, nextPage, setError, agreementData });
        break;
      case 3:
        checkForErrorsPage3({
          formData,
          nextPage,
          setError,
          isNewAddress: enterPolicyNewAddress.value,
          isAddressOnFile,
          openAddressVerification,
        });
        break;
      case 4: {
        const { hasRequestorContactError } = checkForRequestorContactError({
          formData,
          setError,
          requestorContactEmail,
        });
        if (!hasRequestorContactError) {
          nextPage();
        }
        break;
      }
      default:
        nextPage();
    }
  };
  return { onContinue, error };
};

export const useInitiateLoanFields = (props) => {
  const { loanRequest } = props;
  const contactChoiceEmail = useRequestorContactEmail();
  const { maxLoanAmount } = loanRequest?.data || {};
  const editAddressfields = useEditAddressFields();
  return {
    maxLoanAmountFormField: {
      name: "maxLoanAmountFormField",
      value: 0,
    },
    question1yes: {
      id: "question1yes",
      label: "Yes",
      name: "question1yes",
      groupName: "question1Radio",
      value: false,
    },
    question1no: {
      id: "question1no",
      label: "No",
      name: "question1no",
      groupName: "question1Radio",
      value: false,
    },
    question2yes: {
      id: "question2yes",
      label: "Yes",
      name: "question2yes",
      groupName: "question2Radio",
      value: false,
    },
    question2no: {
      id: "question2no",
      label: "No",
      name: "question2no",
      groupName: "question2Radio",
      value: false,
    },
    question3yes: {
      id: "question3yes",
      label: "Yes",
      name: "question3yes",
      groupName: "question3Radio",
      value: false,
    },
    question3no: {
      id: "question3no",
      label: "No",
      name: "question3no",
      groupName: "question3Radio",
      value: false,
    },
    question4yes: {
      id: "question4yes",
      label: "Yes",
      name: "question4yes",
      groupName: "question4Radio",
      value: false,
    },
    question4no: {
      id: "question4no",
      label: "No",
      name: "question4no",
      groupName: "question4Radio",
      value: false,
    },
    question5yes: {
      id: "question5yes",
      label: "Yes",
      name: "question5yes",
      groupName: "question5Radio",
      value: false,
    },
    question5no: {
      id: "question5no",
      label: "No",
      name: "question5no",
      groupName: "question5Radio",
      value: false,
    },
    authAcknowledgement: {
      id: "authAcknowledgement",
      name: "authAcknowledgement",
      label: AUTHORIZATION_CONFIRMATION_STATEMENT,
      value: false,
      required: true,
    },
    disclosureAcknowledgement: {
      id: "disclosureAcknowledgement",
      name: "disclosureAcknowledgement",
      label: [
        "I acknowledge that the owner has read the ",
        <a
          id="loan_disclosure_link"
          href="https://www.massmutual.com/static/path/MMInsurance/Payment/PDFs/FR2066_OL.pdf"
          rel="noreferrer"
          target="_blank"
          key="loan_disclosure_link"
        >
          <u>loan disclosures</u>
        </a>,
        " and is aware that should the policy lapse or be surrendered while there is an outstanding loan, the possibility of a taxable gain exists. Any gain will be taxable as ordinary income and MassMutual will send a 1099 with the taxable information.",
      ],
      value: false,
      required: true,
    },
    uspsGroundRadio: {
      id: "uspsGroundRadio",
      label: "USPS Ground (Up to 10 business days)",
      name: "uspsGroundRadio",
      groupName: "newLoanMailingRadio",
      value: true,
    },
    upsPriorityRadio: {
      id: "upsPriorityRadio",
      label: "UPS Priority (Up to 2 business days)",
      name: "upsPriorityRadio",
      groupName: "newLoanMailingRadio",
      value: false,
    },
    upsAccountNumber: {
      id: "upsAccountNumber",
      label: "UPS Account Number",
      required: true,
      labelBold: true,
      placeholder: "",
      name: "upsAccountNumber",
      value: "",
      uncheckedValue: "",
      errors: [],
      postpone: true,
      formatting: formattingTypes.ALPHANUMERIC,
      useValidation: useUpsAccountValidation,
    },
    zipCode: {
      id: "zipCode",
      label: "Associated Zip/Postal Code",
      required: true,
      labelBold: true,
      placeholder: "",
      name: "zipCode",
      value: "",
      uncheckedValue: "",
      errors: [],
      postpone: true,
      formatting: formattingTypes.ZIPCODE,
      useValidation: useGeneralValidation,
      exactLength: 5,
      maxLength: 5,
    },
    contactChoiceEmailInput: {
      id: "contactChoiceEmailInput",
      hideLabel: true,
      ariaLabel: "Email input",
      placeholder: "Please type your email address",
      name: "contactChoiceEmailInput",
      value: contactChoiceEmail,
      uncheckedValue: "",
      errors: [],
      postpone: true,
      useValidation: useEmailValidation,
      disabled: false,
    },
    contactChoiceConfirmEmailInput: {
      id: "contactChoiceConfirmEmailInput",
      hideLabel: true,
      ariaLabel: "Confirm Email input",
      placeholder: "Please type your email address",
      name: "contactChoiceConfirmEmailInput",
      value: "",
      uncheckedValue: "",
      errors: [],
      postpone: true,
      emailFormFieldValue: contactChoiceEmail,
      useValidation: useConfirmEmailValidation,
      disabled: false,
      disablePaste: true,
    },
    specificAmountRadio: {
      id: "specificAmountRadio",
      label: "Specific Amount",
      name: "specificAmountRadio",
      groupName: "loanDetails",
      value: true,
    },
    specificAmountInput: {
      id: "specificAmountInput",
      name: "specificAmountInput",
      value: "",
      errors: [],
      postpone: true,
      formatting: formattingTypes.MONEY,
      useValidation: useLoanValidation,
      hideLabel: true,
      maxLoanAmount,
      prependText: "$",
      hidden: false,
      maxLength: 10,
    },
    maxLoanAmountRadio: {
      id: "maxLoanAmountRadio",
      label: "Maximum Loan Amount",
      name: "maxLoanAmountRadio",
      groupName: "loanDetails",
      value: false,
      disabled: false,
    },
    policyOwnerAddressonRecord: {
      id: "policyOwnerAddressonRecord",
      label: "Policy Owner's Address on Record",
      name: "policyOwnerAddressonRecord",
      groupName: "newLoanAddressRadio",
      value: true,
    },
    enterPolicyNewAddress: {
      id: "enterPolicyNewAddress",
      label: "Enter a New Address",
      name: "enterPolicyNewAddress",
      groupName: "newLoanAddressRadio",
      value: false,
    },
    ...editAddressfields,
  };
};

export const happyPathValuesSelected = (props) => {
  const { formData } = props;
  let HAPPY_PATH_VALUES_SELECTED = true;
  for (let i = 0; i < 5; i += 1) {
    if (
      (formData[`question${i + 1}no`].value === true &&
        formData[`question${i + 1}yes`].value === false) ||
      (formData[`question${i + 1}no`].value === false &&
        formData[`question${i + 1}yes`].value === true)
    ) {
      HAPPY_PATH_VALUES_SELECTED = true;
    } else if (
      formData[`question${i + 1}no`].value === false &&
      formData[`question${i + 1}yes`].value === false
    ) {
      HAPPY_PATH_VALUES_SELECTED = false;
    }
  }
  return HAPPY_PATH_VALUES_SELECTED;
};

export const checkForErrorsPage1 = (props) => {
  const { formData, nextPage, setError, agreementData } = props;
  const { disclosureAcknowledgement, authAcknowledgement } = formData;
  const BOTH_CHECKED =
    disclosureAcknowledgement.value && authAcknowledgement.value;
  const BOTH_UNCHECKED =
    !disclosureAcknowledgement.value && !authAcknowledgement.value;
  const DISCLOSURE_UNCHECKED =
    !disclosureAcknowledgement.value && authAcknowledgement.value;
  const AUTHORIZATION_UNCHECKED =
    !authAcknowledgement.value && disclosureAcknowledgement.value;
  if (happyPathValuesSelected({ formData }) && BOTH_CHECKED) {
    nextPage();
    setError(null);
  } else if (BOTH_UNCHECKED) {
    setError({
      text: `If you cannot acknowledge these statements, you may contact our service center for more information at ${agreementData.contactUsNumber} ${agreementData.contactUsTime}. If you can acknowledge these statements, select the checkboxes and press continue to proceed.`,
      id: "loan-init-both-acknowledgements-unchecked",
    });
  } else if (DISCLOSURE_UNCHECKED) {
    setError({
      text: `You cannot proceed with the loan request unless the owner has read the loan disclosures and has been made aware of tax implications. If you cannot acknowledge the second statement, you may contact our service center for more information at ${agreementData.contactUsNumber} ${agreementData.contactUsTime}. If you can accept the second acknowledgment statement, select the checkbox and press continue to proceed.`,
      id: "loan-init-disclosure-acknowledgement-unchecked",
    });
  } else if (AUTHORIZATION_UNCHECKED) {
    setError({
      text: "The owner must be authenticated either verbally or in person prior to submitting this request. Please contact your Agency Supervisory Officer (ASO) with any questions. If you can accept the first acknowledgement statement, select the checkbox and press continue to proceed.",
      id: "loan-init-authorization-acknowledgement-unchecked",
    });
  }
};

export const checkForErrorsPage3 = (props) => {
  const {
    formData,
    nextPage,
    setError,
    isNewAddress,
    openAddressVerification,
    isAddressOnFile,
  } = props;
  const { uspsGroundRadio, upsPriorityRadio, upsAccountNumber, zipCode } =
    formData;
  let validationResult = "";

  const USPS_SELECTED = uspsGroundRadio.value === true;
  const UPS_SELECTED = upsPriorityRadio.value === true;
  const HAS_UPS_ACCOUNT_VALUE = upsAccountNumber.value.length > 1;
  const HAS_ZIP_CODE_VALUE = zipCode.value.length > 0;
  const HAS_FIVE_DIGITS = zipCode.value.length === 5;

  if (isNewAddress) {
    validationResult = checkForEditAddressErrors({
      formData,
      nextPage,
      setError,
      updateStateVariables: true,
    });
  }

  const VALID_UPS_ACCOUNT =
    UPS_SELECTED && HAS_UPS_ACCOUNT_VALUE && HAS_ZIP_CODE_VALUE;

  const NO_ADDRESS_ON_FILE = !isAddressOnFile && !isNewAddress;

  if (
    !compareStrings(validationResult, "") &&
    !compareStrings(validationResult, "ValidationCompleted")
  ) {
    setError(validationResult);
  } else if (compareStrings(validationResult, "ValidationCompleted")) {
    setError(null);
    openAddressVerification();
  } else if (NO_ADDRESS_ON_FILE) {
    setError("No address on file.");
  } else if (VALID_UPS_ACCOUNT || USPS_SELECTED) {
    setError(null);
    nextPage();
  } else if (UPS_SELECTED && !HAS_UPS_ACCOUNT_VALUE) {
    setError("Please provide UPS Account Number.");
  } else if (UPS_SELECTED && !HAS_ZIP_CODE_VALUE) {
    setError("Please provide ZIP Code.");
  } else if (UPS_SELECTED && !HAS_FIVE_DIGITS) {
    setError("ZIP Code must have exactly 5 characters.");
  } else if (!VALID_UPS_ACCOUNT && !USPS_SELECTED) {
    setError("Please choose a mailing option.");
  }
};

export const useMailingAddressProps = (props) => {
  const { formData } = useFormContext();
  const { upsPriorityRadio, upsAccountNumber, zipCode } = formData;
  const { loanRequest, setPage3Error } = props;
  const { agreementCustomers } = loanRequest?.data || {};

  const { agreementKeyAdmin } = props.agreementData;

  const { owner, address } = getOwnerandAddressFromCustomers({
    agreementCustomers,
    agreementKeyAdmin,
  });
  const {
    attnLine,
    addrFirstLine,
    addrSecondLine,
    addrThirdLine,
    addrFourthLine,
  } = address;

  const formattedOwnerName = formatName({
    nameObject: owner,
    format: nameFormattingOptions.fullNameThenFirstName,
  });

  const UPS_SELECTED = upsPriorityRadio.value === true;
  const HAS_UPS_ACCOUNT_VALUE = upsAccountNumber.value.length > 1;
  const HAS_FIVE_DIGITS = zipCode.value.length === 5;

  const VALID_UPS_ACCOUNT =
    !UPS_SELECTED || (UPS_SELECTED && HAS_UPS_ACCOUNT_VALUE && HAS_FIVE_DIGITS);

  setPage3Error(!VALID_UPS_ACCOUNT);

  return {
    formData,
    formattedOwnerName,
    attnLine,
    addrFirstLine,
    addrSecondLine,
    addrThirdLine,
    addrFourthLine,
  };
};

export const useRequestorContactProps = () => {
  useUpdateEditAddressVariables();
};

export const useReviewProps = (props) => {
  const { formData } = useFormContext();
  const { specificAmountInput, specificAmountRadio, enterPolicyNewAddress } =
    formData;
  const { agreementData, loanRequest } = props;
  const { policyId, productTypeName, agreementKeyAdmin } = agreementData;

  // Loan Details
  const loanAmountRequested = specificAmountRadio.value
    ? specificAmountInput.value
    : specificAmountInput.maxLoanAmount;
  const loanAmountNoComma = removeCommas(loanAmountRequested);
  const formattedLoanAmount = formatMoney(loanAmountNoComma);
  const { agreementCustomers, currentInterestRate, interestRateType } =
    loanRequest.data;
  const formattedInterestRate = formatPercentage({ rate: currentInterestRate });
  // Mailing Address

  const { owner, address } = getOwnerandAddressFromCustomers({
    agreementCustomers,
    agreementKeyAdmin,
  });
  let attnLine;
  let addrFirstLine;
  let addrSecondLine;
  let addrThirdLine;
  let addrFourthLine;

  const formattedOwnerName = owner?.fullName;
  const upsSelected = formData.upsPriorityRadio.value;
  const mailingOption = upsSelected ? "UPS Priority" : "USPS Ground";
  const upsAccountNumber = formData.upsAccountNumber.value;
  const upsAssociatedZip = formData.zipCode.value;

  const {
    addressLine1,
    addressLine2,
    addressLine3,
    addressCity,
    addressPostalCode,
    addressCountryName,
    addressStateName,
  } = formatAddressFields(formData, address);

  if (enterPolicyNewAddress.value === true) {
    const userEnteredAddress = {
      addressLine1,
      addressLine2,
      addressLine3,
      city: addressCity,
      postalCode: addressPostalCode,
      countryName: addressCountryName,
      state: addressStateName,
    };
    ({
      attnLine,
      addrFirstLine,
      addrSecondLine,
      addrThirdLine,
      addrFourthLine,
    } = formatAddress(userEnteredAddress));
  } else {
    ({
      attnLine,
      addrFirstLine,
      addrSecondLine,
      addrThirdLine,
      addrFourthLine,
    } = address);
  }

  // Requestor Contact
  const {
    user: { firstName, lastName },
  } = useUserState();
  const loggedInUser = `${firstName} ${lastName}`;
  const contactChoiceEmailInput = formData.contactChoiceEmailInput.value;

  const insureds =
    getUsers(
      agreementCustomers,
      "INSD",
      nameFormattingOptions.fullNameThenFirstName
    ) || [];
  const insured = insureds?.[0];
  const formattedInsuredName = insured?.fullName;

  const individuallyOwnedText = formData.question1yes.value
    ? "Individually-owned"
    : "Non-Individually-owned";

  const achText = formData.question2yes.value ? "Yes" : "No";
  const bankruptcyText = formData.question3yes.value ? "Yes" : "No";
  const divorceDecreeText = formData.question4yes.value ? "Yes" : "No";
  const amountExceeds200K =
    parseFloat(loanAmountNoComma) >= 200000 ? "Yes" : "No";
  const authorizedByOwnerText = formData.authAcknowledgement.value
    ? "Yes"
    : "No";

  const hideMailingAdress = formData.question2yes.value;

  return {
    formattedLoanAmount,
    formattedInterestRate,
    interestRateType: compareStrings(interestRateType, "V")
      ? "Variable"
      : "Fixed",
    formattedOwnerName,
    formattedInsuredName,
    attnLine,
    addrFirstLine,
    addrSecondLine,
    addrThirdLine,
    addrFourthLine,
    mailingOption,
    upsSelected,
    upsAccountNumber,
    upsAssociatedZip,
    contactChoiceEmailInput,
    loggedInUser,
    policyId,
    productTypeName,
    enterPolicyNewAddress,
    individuallyOwnedText,
    achText,
    bankruptcyText,
    amountExceeds200K,
    divorceDecreeText,
    authorizedByOwnerText,
    hideMailingAdress,
  };
};

export const useConfirmationProps = (props) => {
  const { formData } = useFormContext();
  const { agreementKey } = useParams();
  const { agreementData, loanRequest } = props;
  const { policyId, productTypeName, agreementKeyAdmin } = agreementData;
  const { agreementCustomers } = loanRequest?.data || {};
  const {
    user: { firstName, lastName },
  } = useUserState();

  const loggedInUser = `${firstName} ${lastName}`;
  const { owner, address } = getOwnerandAddressFromCustomers({
    agreementCustomers,
    agreementKeyAdmin,
  });
  const {
    attnLine,
    addrFirstLine,
    addrSecondLine,
    addrThirdLine,
    addrFourthLine,
  } = address;
  const formattedOwnerName = owner?.fullName;

  const insureds =
    getUsers(
      agreementCustomers,
      "INSD",
      nameFormattingOptions.fullNameThenFirstName
    ) || [];
  const insured = insureds?.[0];
  const formattedInsuredName = insured?.fullName;

  const loanAmountRequested = formData.specificAmountRadio.value
    ? formData.specificAmountInput.value
    : formData.specificAmountInput.maxLoanAmount;
  const formattedLoanAmount = formatMoney(loanAmountRequested);

  const upsSelected = formData.upsPriorityRadio.value;
  const mailingOption = upsSelected ? "UPS Priority" : "USPS Ground";
  const upsAccountNumber = formData.upsAccountNumber.value;
  const upsAssociatedZip = formData.zipCode.value;

  const preferredMethodOfContact = formData.contactChoiceEmailInput.value || "";

  const requestDateTime = formatDate(getCurrentDate(), "dateWithTime");

  return {
    formattedLoanAmount,
    formattedOwnerName,
    formattedInsuredName,
    attnLine,
    addrFirstLine,
    addrSecondLine,
    addrThirdLine,
    addrFourthLine,
    mailingOption,
    upsSelected,
    upsAccountNumber,
    upsAssociatedZip,
    preferredMethodOfContact,
    policyId,
    loggedInUser,
    productTypeName,
    requestDateTime,
    agreementKey,
  };
};

export const useCTAFormProps = (documentId, policyId) => {
  const [disableViewFormButton, setDisableViewFormButton] = useState(false);
  const { user } = useUserState();
  const pdfRequest = useMakeRequest({
    apiRequest: "fetchPdfFromDocumentId",
    apiParams: { documentId },
    immediateRequest: false,
  });
  useEffect(() => {
    if (pdfRequest?.data?.length && !pdfRequest?.error) {
      setDisableViewFormButton(true);
      fireTealiumEvent({
        label: "SVNT",
        action: `Initiate a Loan Form Downloaded`,
        category: "Initiate a Loan",
        value: policyId,
        custId: user?.email,
      });
      downloadPDF(
        "",
        policyId,
        convertBase64StringToByteArray(pdfRequest.data),
        "Loan Application.pdf"
      );
    }
  }, [pdfRequest?.data]);

  const generateAndDownloadPDF = () => {
    pdfRequest.executeRequest();
  };

  return {
    generateAndDownloadPDF,
    disableViewFormButton,
  };
};

export const getPremiumPayorFromCustomers = (props) => {
  const { agreementCustomers } = props;

  const premiumPayors =
    getUsers(
      agreementCustomers,
      userTypes.PREMIUM_PAYER,
      nameFormattingOptions.fullNameThenFirstName
    ) || [];
  const premiumPayor = premiumPayors?.[0];
  const {
    attnLine,
    addrFirstLine,
    addrSecondLine,
    addrThirdLine,
    addrFourthLine,
  } = formatAddress(premiumPayor?.address, false);

  return {
    premiumPayor,
    address: {
      attnLine,
      addrFirstLine,
      addrSecondLine,
      addrThirdLine,
      addrFourthLine,
    },
  };
};

export const getOwnerandAddressFromCustomers = (props) => {
  const { agreementCustomers, agreementKeyAdmin } = props;
  const { owner, address: ownerAddress } = getOwnerFromCustomers({
    agreementCustomers,
  });
  const { address: premiumPayorAddress } = getPremiumPayorFromCustomers({
    agreementCustomers,
  });
  const address =
    agreementKeyAdmin === "MPR" ? premiumPayorAddress : ownerAddress;
  return { owner, address };
};

export const makeRequestBody = (props) => {
  const {
    agreementKey,
    user,
    formData,
    idNumber,
    loanRequest,
    producerRequest,
    producerData,
    agreementCustomers,
    agreementKeyAdmin,
  } = props;
  const {
    question1yes,
    question2no,
    question3no,
    question4no,
    specificAmountRadio,
    specificAmountInput,
    uspsGroundRadio,
    upsAccountNumber,
    zipCode,
    enterPolicyNewAddress,
  } = formData;
  const {
    interestRateType,
    currentInterestRate,
    qualifiedIndicator,
    taxWithholdingIndicator,
    variableLifeIndicator,
    maxLoanAmount,
  } = loanRequest?.data || {};
  const { agent } = producerRequest?.data || {};
  const { homeAgencyId } = agent;
  const orgUnit = getLastXChars(homeAgencyId, 3).padStart(3, 0) || "";
  const owners =
    getUsers(
      agreementCustomers,
      "OWNR",
      nameFormattingOptions.fullNameThenFirstName
    ) || [];
  const owner = owners?.[0];
  const { premiumPayor } = getPremiumPayorFromCustomers({ agreementCustomers });
  const { address } = agreementKeyAdmin === "MPR" ? premiumPayor : owner;
  const { firstName, lastName } = user;

  const loanOption = specificAmountRadio.value ? "Specified" : "MaxLoan";
  const loanAmount = compareStrings(loanOption, "Specified")
    ? specificAmountInput.value
    : maxLoanAmount;
  const formattedLoanAmount = extractNumberFromString(loanAmount)?.toFixed(2);
  const customerType = question1yes.value ? "I" : "N";
  const variableLifeInd = !isFalsy(variableLifeIndicator);
  const qualifiedInd = !isFalsy(qualifiedIndicator);
  const taxWitholdingInd = !isFalsy(taxWithholdingIndicator);
  const formattedOwnerName = formatName({
    nameObject: owner,
    format: nameFormattingOptions.fullNameThenFirstName,
  });

  const newAddressInd = enterPolicyNewAddress.value;

  const {
    addressLine1,
    addressLine2,
    addressLine3,
    addressCity,
    addressPostalCode,
    addressCountryName,
    addressStateName,
  } = question2no.value ? formatAddressFields(formData, address) : {};

  const addressAttentionLine = question2no.value
    ? address?.attentionLine || ""
    : "";

  const customerId = owner.memberGUID;
  const divorceDecreeInd = !question4no.value;
  const { roleType } = owner;
  const requestAch = !question2no.value;
  const lienBankruptcyDivAssign = !question3no.value;
  const multipleOwnerInd = question1yes.value ? "individual" : "";

  const shippingOption = uspsGroundRadio.value ? "USPS" : "UPS";
  const shippingAcctName = "";
  const shippingAcctNumber = question2no.value
    ? upsAccountNumber.value || ""
    : "";
  const shippingAcctZip = question2no.value ? zipCode.value || "" : "";

  const responseMethod = "E-mail";
  const requestorName = `${firstName} ${lastName}`;
  const requestorEmail = formData.contactChoiceEmailInput.value;
  const { requestorAgentId, requestorTypeCode } = getRequestorInfo({
    idNumber,
    producerData,
    agreementKey,
  });
  const formattedRequestorId = requestorAgentId.toUpperCase() || "";

  return {
    agreement: {
      loanRequest: {
        loanAmount: formattedLoanAmount,
        loanOption,
        shippingOption,
        shippingAcctName,
        shippingAcctNumber,
        shippingAcctZip,
        customerType,
        variableLifeIndicator: variableLifeInd,
        qualifiedIndicator: qualifiedInd,
        payeeName: formattedOwnerName,
        attentionLine: addressAttentionLine,
        line1: addressLine1,
        line2: addressLine2,
        line3: addressLine3,
        city: addressCity,
        state: addressStateName,
        countryName: addressCountryName,
        postalCode: addressPostalCode,
        newAddressIndicator: newAddressInd,
        customerId,
        interestRateType,
        currentInterestRate,
        multipleOwnerIndicator: multipleOwnerInd,
        divorceDecreeIndicator: divorceDecreeInd,
        roleType,
        taxWitholdingIndicator: taxWitholdingInd,
        requestAch,
        lienBankruptcyDivAssign,
      },
      requestorInfo: {
        requestorAgentId: formattedRequestorId,
        requestorOrgId: orgUnit,
        requestorTypeCode,
        responseMethod,
        requestorName,
        requestorEmail,
        requestorContactMethod: responseMethod,
        responseTo: requestorName,
      },
    },
  };
};

export const formatAddressFields = (formData, address) => {
  const {
    editAddressCountry,
    editAddressAddress1,
    editAddressAddress2,
    editAddressAddress3,
    editAddressIsMilitaryAddress,
    editAddressCity,
    editAddressMilitaryCity,
    editAddressState,
    editAddressMilitaryState,
    editAddressZipCode,
    editAddressMilitaryZipCode,
    enterPolicyNewAddress,
  } = formData;

  let addressLine1;
  let addressLine2;
  let addressLine3;
  let addressCity;
  let addressPostalCode;
  let addressCountryName;
  let addressStateName;
  if (enterPolicyNewAddress.value === true) {
    addressLine1 = editAddressAddress1.value || "";
    addressLine2 = editAddressAddress2.value || "";
    addressLine3 = editAddressAddress3.value || "";
    addressCountryName = editAddressCountry.value || "";
    addressCity =
      editAddressIsMilitaryAddress.value === true
        ? editAddressMilitaryCity.value || ""
        : editAddressCity.value || "";
    addressPostalCode =
      editAddressIsMilitaryAddress.value === true
        ? editAddressMilitaryZipCode.value || ""
        : editAddressZipCode.value || "";
    addressStateName =
      editAddressIsMilitaryAddress.value === true
        ? editAddressMilitaryState.value || ""
        : editAddressState.value || "";
  } else {
    addressLine1 = address.line1 || "";
    addressLine2 = address.line2 || "";
    addressLine3 = address.line3 || "";
    addressCity = address.city || "";
    addressPostalCode = address.postalCode || "";
    addressCountryName = address.countryName || "";
    addressStateName = address.state || "";
  }

  return {
    addressLine1,
    addressLine2,
    addressLine3,
    addressCity,
    addressPostalCode,
    addressCountryName,
    addressStateName,
  };
};

const checkIsAddressOnFile = ({ loanRequest, agreementKeyAdmin }) => {
  const { address } = getOwnerandAddressFromCustomers({
    agreementCustomers: loanRequest.data.agreementCustomers,
    agreementKeyAdmin,
  });
  const { addrFirstLine, addrSecondLine, addrThirdLine, addrFourthLine } =
    address;

  const isAddressOnFile =
    isDefined(address) &&
    ((isDefined(addrFirstLine) && !compareStrings(addrFirstLine, "-")) ||
      isDefined(addrSecondLine) ||
      isDefined(addrThirdLine) ||
      isDefined(addrFourthLine));

  return isAddressOnFile;
};
