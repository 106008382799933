import React from "react";
import RadioForm from "../../common/forms/radioForm";

const Question = (props) => {
  const { question, note, yesField, noField } = props;

  return (
    <div>
      <p className="h4">{question}</p>
      <p>{note}</p>
      <div className="row">
        <div className="col-12 col-md-5">
          <div className="row">
            <div className="col-12 col-md-5">
              <RadioForm formField={yesField} />
            </div>
            <div className="col-12 col-md-5">
              <RadioForm formField={noField} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Question);
