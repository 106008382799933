import React from "react";
import CreateNameList from "../../common/nameList/createNameList";
import { useReviewProps } from "../hooks";

const Review = (props) => {
  const {
    currentBillFrequency,
    newBillFrequency,
    premium,
    annualPremium,
    modalChargeAmount,
    aprPercentage,
    selectedDate,
    financialProfessionalName,
    financialProfessionalEmail,
    insured,
    owners,
    policyId,
    productTypeName,
  } = useReviewProps(props);

  return (
    <div>
      <h1 className="mm-spacing">Review</h1>
      <div className="row">
        <div className="col-md-8">
          <p className="h3">Billing Frequency</p>
          <div className="mm-spacing--minor">
            <dl className="mm-key-value-pair">
              <dt id="review_current_frequency_lbl">Current Frequency</dt>
              <dd id="review_current_frequency_value">
                {currentBillFrequency}
              </dd>
              <dt id="review_new_frequency_lbl">New Frequency</dt>
              <dd id="review_new_frequency_value">{newBillFrequency}</dd>
              <dt id="review_premium_lbl">Premium</dt>
              <dd id="review_premium_value">{premium}</dd>
              <dt id="review_annualized_amount_lbl">Annualized Amount</dt>
              <dd id="review_annualized_amount_value">{annualPremium}</dd>
              <dt id="review_modal_charge_lbl">Modal Charge</dt>
              <dd id="review_modal_charge_value">{modalChargeAmount}</dd>
              <dt id="review_apr_lbl">APR</dt>
              <dd id="review_apr_value">{aprPercentage}</dd>
            </dl>
          </div>

          <hr />

          <p className="h3">Start Date</p>
          <div className=" mm-spacing--minor">
            <dl className="mm-key-value-pair">
              <dt id="review_selected_date_lbl">Selected Start Date</dt>
              <dd id="review_selected_date_value">{selectedDate}</dd>
            </dl>
          </div>

          <hr />

          <p className="h3">Recipient</p>
          <div className="mm-spacing--minor">
            <dl className="mm-key-value-pair">
              <dt id="review_financial_professional_lbl">
                Financial Professional
              </dt>
              <dd id="review_financial_professional_value">
                {financialProfessionalName}
              </dd>
              <dt id="review_financial_professional_email_lbl">
                Financial Professional Email
              </dt>
              <dd id="review_financial_professional_email_value">
                {financialProfessionalEmail}
              </dd>
            </dl>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card--flat">
            <div className="card-body">
              <dl className="mm-key-value-pair">
                <dt id="review_owner_name_lbl">Owner Name</dt>
                <dd id="review_owner_name_value">
                  <CreateNameList list={owners} />
                </dd>
                <dt id="review_insured_name_lbl">Insured Name</dt>
                <dd id="review_insured_name_value">
                  <CreateNameList list={insured} />
                </dd>
                <dt id="review_policy_number_lbl">Policy Number</dt>
                <dd id="review_policy_number_value">{policyId}</dd>
                <dt id="review_product_name_lbl">Product Name</dt>
                <dd id="review_product_name_value">{productTypeName}</dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Review);
