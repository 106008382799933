import React from "react";
import Label from "./label";
import DropDown from "./dropdown";

const DropdownForm = (props) => {
  const { formField, useHook } = props;
  return (
    <div className="form-group">
      <Label formField={formField} />
      <DropDown formField={formField} useHook={useHook} />
    </div>
  );
};

export default React.memo(DropdownForm);
