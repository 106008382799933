import isEqual from "lodash.isequal";
import { useFlags } from "launchdarkly-react-client-sdk";
import { isDefined } from "../../../utils/evaluate";
import { compareArrayOfStrings } from "../../../utils/string";

export const useFeatureFlagProps = (props) => {
  const { isOn, message } = useCheckForValue(props);

  return { isOn, message };
};

export const useIsFeatureOn = (flagName) => {
  const flags = useFlags();
  const defaultReturn = false;

  if (flagName?.length !== 0 && isDefined(flags[flagName])) {
    return flags[flagName];
  }
  return defaultReturn;
};

export const useHidePageFromUser = (props) => {
  const {
    pageName,
    agreementKeyAdmin,
    productTypeCode,
    majorProductCode,
    minorProductCode,
  } = props;
  const conditions = useIsFeatureOn(pageName);
  let defaultReturn = false;

  if (conditions && conditions.length > 0) {
    const conditionMatchingAdmins = conditions.filter(
      (condition) => condition.agreementKeyAdmin === agreementKeyAdmin
    );

    if (!conditionMatchingAdmins || conditionMatchingAdmins.length === 0) {
      return false;
    }

    const subConditions = [];
    conditionMatchingAdmins.map((condition) => {
      if (condition.subConditions) subConditions.push(condition.subConditions);
      return condition;
    });
    if (!subConditions || subConditions.length === 0) {
      return true;
    }

    subConditions.forEach((condition) => {
      if (!defaultReturn) {
        if (condition.productTypeCode)
          defaultReturn = compareArrayOfStrings(
            condition.productTypeCode,
            productTypeCode
          );
        if (condition.majorProductCode)
          defaultReturn = compareArrayOfStrings(
            condition.majorProductCode,
            majorProductCode
          );
        if (condition.minorProductCode)
          defaultReturn = compareArrayOfStrings(
            condition.minorProductCode,
            minorProductCode
          );
      }
      return condition;
    });
  }
  return defaultReturn;
};

export const useGetFlagsValues = (flagNames) => {
  const flags = useFlags();

  const flagValues = [];
  let areAllFlagsOff = true;

  flagNames.map((flagName) => {
    let flagValue = false;
    if (flagName?.length !== 0 && isDefined(flags[flagName])) {
      flagValue = flags[flagName];
    }
    flagValues[flagName] = flagValue;
    if (flagValue) areAllFlagsOff = false;
    return flagValue;
  });

  return { flagValues, areAllFlagsOff };
};

export const useCheckForValue = (props) => {
  const { flagName = [] } = props;
  const flags = useFlags();
  const defaultReturn = {
    message: "Flag not found.",
    isOn: false,
  };

  const isEmpty = flagName.length === 0;
  if (isEmpty) {
    return defaultReturn;
  }

  return flagName.reduce((latestDepth, nextStep) => {
    const isNotDefined = !isDefined(latestDepth);
    const isDefaultReturn = isEqual(defaultReturn, latestDepth);
    const hasFlag = compareArrayOfStrings(Object.keys(latestDepth), nextStep);

    if (isNotDefined || isDefaultReturn) {
      return defaultReturn;
    }
    if (hasFlag) {
      return latestDepth[nextStep];
    }
    return defaultReturn;
  }, flags);
};

export const useLDValue = (props) => {
  const { flagName = [] } = props;
  const flags = useFlags();
  const defaultReturn = {};

  const isEmpty = flagName.length === 0;
  if (isEmpty) {
    return defaultReturn;
  }

  return flagName.reduce((latestDepth, nextStep) => {
    const isNotDefined = !isDefined(latestDepth);
    const isDefaultReturn = isEqual(defaultReturn, latestDepth);
    const hasFlag = compareArrayOfStrings(Object.keys(latestDepth), nextStep);

    if (isNotDefined || isDefaultReturn) {
      return defaultReturn;
    }
    if (hasFlag) {
      return latestDepth[nextStep];
    }
    return defaultReturn;
  }, flags);
};
