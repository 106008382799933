import React from "react";
import { useCashValuesProps } from "./hooks";
import { isQuarterString } from "../../../../../utils/dates";
import IsTruthy from "../../../../common/logic/isTruthy";
import IsFalsy from "../../../../common/logic/isFalsy";

const CashValueCell = (props) => {
  const { cellValue, rowIndex, accessor, className, date, cashValueType } =
    useCashValuesProps(props);

  return (
    <span
      id={`cell_${rowIndex}_${accessor}_${cashValueType}_value`}
      className={className}
    >
      <IsTruthy value={date} validationFunction={isQuarterString}>
        <strong>{cellValue}</strong>
      </IsTruthy>
      <IsFalsy value={date} validationFunction={isQuarterString}>
        {cellValue}
      </IsFalsy>
    </span>
  );
};

export default React.memo(CashValueCell);
