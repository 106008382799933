import React from "react";
import { ListBasic } from "react-blueprint-library";
import { useUserState } from "../../contexts/user";
import { useLDValue } from "../common/featureFlags/hooks";
import { fireTealiumAndFollowLink } from "../../utils/tealium";
import { verifyDigitalRegistration } from "../../utils/policies";
import IsTruthy from "../common/logic/isTruthy";
import { compareStrings } from "../../utils/string";

export const DigitalRegistrationLink = ({
  isCalledFrom,
  policyId,
  agreementData,
  customer,
  lineOfBusinessCode,
}) => {
  const { user } = useUserState();

  const { email } = user;

  const transactionSupportedPolicies = useLDValue({
    flagName: ["transactionSupportedPolicies"],
  });

  const { redirectLink, isEligible, isTransactionAllowed } =
    verifyDigitalRegistration({
      policyId,
      agreementData,
      transactionSupportedPolicies,
      customer,
    });

  const target = compareStrings(isCalledFrom, "drawer") ? "_blank" : "_self";

  const linkButton = (
    <button
      className="btn btn-inline"
      id={`${isCalledFrom}_digital_registration_link`}
      type="button"
      onClick={() =>
        fireTealiumAndFollowLink({
          url: redirectLink,
          action: `${lineOfBusinessCode}: Digital Registration link clicked from ${isCalledFrom}`,
          category: `${isCalledFrom}`,
          value: policyId,
          custId: email,
          target,
        })
      }
    >
      Pre-Register your client
    </button>
  );

  if (compareStrings(isCalledFrom, "PDP")) {
    return (
      <IsTruthy value={isEligible && isTransactionAllowed}>
        <ListBasic.ListBasicItem>{linkButton}</ListBasic.ListBasicItem>
      </IsTruthy>
    );
  }
  return (
    <IsTruthy value={isEligible && isTransactionAllowed}>
      <div>{linkButton}</div>
    </IsTruthy>
  );
};
