/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import IsTruthy from "../common/logic/isTruthy";
import { useChangeAddressProps, useRequestorContactProps } from "./hooks";
import EditAddress from "../common/editAddress/editAddress";
import SelectUsers from "./selectUsers";
import Review from "./review";
import ModalControl from "./modalControl";
import Form from "../common/forms/form";
import AddressFlowHeader from "./header";
import Header from "../layout/header";
import Confirmation from "./confirmation";
import { ModalProvider } from "../../contexts/modal";
import FiveHundredError from "../common/errors/500";
import Footer from "../layout/footer";
import IsFalsy from "../common/logic/isFalsy";
import FourHundredFourError from "../common/errors/404";
import FiveHundredOneError from "../common/errors/501";
import { tealiumPageViewEvent } from "../../utils/tealium";
import LdBannerContent from "../common/molecules/ldBannerContent";
import RequestorContact from "../common/requestorContact";
import LoadingSection, { errorTypes, loadingTypes } from "../common/loading";
import ErrorPage from "../common/errors/errorPage";

const ChangeAddress = (props) => {
  const { agreementKey } = useParams();
  const {
    pageNumber,
    nextPage,
    previousPage,
    fields,
    agreementRequest,
    producersRequest,
    agreementData,
    setError,
    error,
    setPageNumber,
    onSubmit,
    submissionRetry,
    showErrorPage,
    transactionId,
    errorText,
    shouldDisableSubmit,
    email,
    changeAddressIsUnavailable,
    isMmsdOnly,
    changeAddressTransactionAllowed,
    isPolicyLoaded,
    isSubmissionInProgress,
    annuityRequest,
    isPolicyNotFound,
  } = useChangeAddressProps(props);

  useEffect(() => {
    tealiumPageViewEvent({
      page: "Change Address",
      category: agreementKey,
      custId: email,
    });
  }, []);

  if (isPolicyNotFound) {
    return (
      <>
        <Header />
        <div className="container">
          <LdBannerContent />
          <FourHundredFourError />
        </div>
        <Footer />
      </>
    );
  }

  return (
    <LoadingSection
      request={agreementRequest}
      loadingType={loadingTypes.SPINNER}
      errorType={errorTypes._500}
      addHeaderFooterToError
    >
      <LoadingSection
        request={producersRequest}
        loadingType={loadingTypes.SPINNER}
        errorType={errorTypes._500}
        addHeaderFooterToError
      >
        <LoadingSection
          request={annuityRequest}
          loadingType={loadingTypes.SPINNER}
          errorType={errorTypes._500}
          addHeaderFooterToError
        >
          <IsTruthy value={isPolicyLoaded}>
            <IsFalsy value={changeAddressTransactionAllowed}>
              <IsTruthy value={isMmsdOnly}>
                <Header />
                <div className="container">
                  <LdBannerContent />
                  <ErrorPage
                    errorTitle="Our records indicate you do not have access to this application."
                    agreementKey={agreementRequest?.agreementKey}
                    errorPageFor="mmsdOnly"
                    imageClass="illustration-error_500"
                  />
                </div>
                <Footer />
              </IsTruthy>
              <IsFalsy value={isMmsdOnly}>
                <Header />
                <div className="container">
                  <LdBannerContent />
                  <FourHundredFourError />
                </div>
                <Footer />
              </IsFalsy>
            </IsFalsy>
            <IsTruthy value={changeAddressTransactionAllowed}>
              <IsTruthy value={agreementData?.isUnauthorized}>
                <Header />
                <div className="container">
                  <LdBannerContent />
                  <FiveHundredOneError />
                </div>
                <Footer />
              </IsTruthy>
              <IsFalsy value={agreementData?.isUnauthorized}>
                <IsTruthy value={showErrorPage}>
                  <Header />
                  <div className="container">
                    <LdBannerContent />
                    <FiveHundredError
                      turnOffReload
                      transactionId={transactionId}
                      title={errorText}
                      agreementKey={agreementKey}
                    />
                  </div>
                  <Footer />
                </IsTruthy>

                <IsTruthy value={changeAddressIsUnavailable}>
                  <Header />
                  <div className="container">
                    <LdBannerContent />
                    <FourHundredFourError />
                  </div>
                  <Footer />
                </IsTruthy>

                <IsFalsy value={changeAddressIsUnavailable}>
                  <IsFalsy value={showErrorPage}>
                    <div className="container">
                      <Form fields={fields}>
                        <ModalProvider>
                          <AddressFlowHeader
                            pageNumber={pageNumber}
                            previousPage={previousPage}
                            shouldDisableSubmit={shouldDisableSubmit}
                          />
                          <IsTruthy value={pageNumber === 1}>
                            <SelectUsers agreementData={agreementData} />
                          </IsTruthy>
                          <IsTruthy value={pageNumber === 2}>
                            <EditAddress
                              setError={setError}
                              isCalledFrom="ChangeAddress"
                            />
                          </IsTruthy>
                          <IsTruthy value={pageNumber === 3}>
                            <RequestorContact
                              useContainerHook={useRequestorContactProps}
                            />
                          </IsTruthy>
                          <IsTruthy value={pageNumber === 4}>
                            <Review setPageNumber={setPageNumber} />
                          </IsTruthy>
                          <IsTruthy value={pageNumber === 5}>
                            <Confirmation />
                          </IsTruthy>
                          <ModalControl
                            pageNumber={pageNumber}
                            nextPage={nextPage}
                            agreementKey={agreementData.agreementKey}
                            setError={setError}
                            error={error}
                            onSubmit={onSubmit}
                            submissionRetry={submissionRetry}
                            setPageNumber={setPageNumber}
                            shouldDisableSubmit={shouldDisableSubmit}
                            isSubmissionInProgress={isSubmissionInProgress}
                          />
                        </ModalProvider>
                      </Form>
                    </div>
                  </IsFalsy>
                </IsFalsy>
              </IsFalsy>
            </IsTruthy>
          </IsTruthy>
        </LoadingSection>
      </LoadingSection>
    </LoadingSection>
  );
};

export default ChangeAddress;
