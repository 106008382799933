import React from "react";

const RiderModalCell = (props) => {
  const {
    row,
    rowIndex,
    column: { accessor },
    customFunctions,
  } = props;

  return (
    <button
      id={`${accessor}_${rowIndex}_btn`}
      onClick={customFunctions.showRiderModal(row)}
      className="btn btn-inline"
      type="button"
    >
      <span
        id={`${accessor}_${rowIndex}_value`}
        dangerouslySetInnerHTML={{ __html: row[accessor] }}
      />
    </button>
  );
};

export default RiderModalCell;
