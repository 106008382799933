import React from "react";
import { setTitle } from "../../../utils/dom";
import { useConfirmationProps } from "../hooks";
import IsTruthy from "../../common/logic/isTruthy";
import { InformationPopover } from "../../common/popovers/informationPopover";

const Confirmation = (props) => {
  setTitle("ServiceNet - Loan Request Confirmation");
  const {
    formattedLoanAmount,
    formattedOwnerName,
    formattedInsuredName,
    attnLine,
    addrFirstLine,
    addrSecondLine,
    addrThirdLine,
    addrFourthLine,
    mailingOption,
    upsSelected,
    upsAccountNumber,
    upsAssociatedZip,
    preferredMethodOfContact,
    policyId,
    loggedInUser,
    productTypeName,
    requestDateTime,
    agreementKey,
  } = useConfirmationProps(props);
  return (
    <div>
      <div className="row">
        <div className="col-md-9">
          <h1 className="h2">
            <picture>
              <span
                role="img"
                className="illustration-confetti hide-print"
                title="Success Confetti"
              />
            </picture>
            Thank you! Your Loan Request has been submitted.
          </h1>
        </div>
        <div className="col-3">
          <div id="printButton" className="justify-content-end">
            <button
              onClick={() => window.print()}
              data-bp-link="default"
              className="btn btn-inline hide-print"
              aria-label="Print"
              type="button"
              id="initiate_loan_confirmation_button_print"
            >
              <span className="icon-print" />
              <span>Print</span>
            </button>
          </div>
        </div>
      </div>
      <p className="mm-spacing--minor">
        The Loan Request has been successfully submitted. Please allow two
        business days{" "}
        <InformationPopover
          popoverId="massmutual_business_days_tooltip"
          text="Note: MassMutual business days align with the NYSE."
        />{" "}
        for processing. Please review Service History for processing time and
        updates. If you have any questions regarding this request, please call
        1-800-272-2216 8:00 AM - 8:00 PM ET, Monday through Friday and reference
        the policy number.
      </p>
      <hr className="mm-spacing" />
      <div className="row mm-spacing--minor">
        <div className="col-md-7">
          <dl className="mm-key-value-pair">
            <dt id="confirmation_new_address_lbl">Loan Amount Requested</dt>
            <dd id="initiatenewloan_confirmation_new_address_value">
              <b>{formattedLoanAmount}</b>
            </dd>
            <dt id="confirmation_mailing_address_lbl">Mailing Address</dt>
            <dd id="initiatenewloan_confirmation_mailing_address_value">
              <IsTruthy value={attnLine !== ""}>
                <span id="confirmation_attention_line_address_value">
                  <b>{attnLine}</b>
                </span>
                <br />
              </IsTruthy>
              <span id="confirmation_first_line_address_value">
                <b>{addrFirstLine}</b>
              </span>
              <IsTruthy value={addrSecondLine !== ""}>
                <br />
                <span id="confirmation_second_line_address_value">
                  <b>{addrSecondLine}</b>
                </span>
              </IsTruthy>
              <IsTruthy value={addrThirdLine !== ""}>
                <br />
                <span id="confirmation_third_line_address_value">
                  <b>{addrThirdLine}</b>
                </span>
              </IsTruthy>
              <IsTruthy value={addrFourthLine !== ""}>
                <br />
                <span id="confirmation_fourth_line_address_value">
                  <b>{addrFourthLine}</b>
                </span>
              </IsTruthy>
            </dd>
            <dt id="confirmation_owner_fullName_lbl">Owner Name</dt>
            <dd id="initiatenewloan_confirmation_owner_fullName_value">
              {formattedOwnerName}
            </dd>
            <dt id="confirmation_insured_fullName_lbl">Insured Name</dt>
            <dd id="initiatenewloan_confirmation_insured_fullName_value">
              {formattedInsuredName}
            </dd>
            <dt id="confirmation_policy_number_lbl">Policy Number</dt>
            <dd id="initiatenewloan_confirmation_policy_number_value">
              {policyId}
            </dd>
            <dt id="confirmation_product_name_lbl">Product Name</dt>
            <dd id="initiatenewloan_confirmation_product_name_value">
              {productTypeName}
            </dd>
            <dt id="confirmation_mailing_option_lbl">Mailing Option</dt>
            <dd id="initiatenewloan_confirmation_mailing_option_value">
              {mailingOption}
            </dd>
            <IsTruthy value={upsSelected}>
              <dt id="initiatenewloan_confirmation_ups_account_number_lbl">
                UPS Account Number
              </dt>
              <dd id="initiatenewloan_confirmation_ups_account_number_value">
                {upsAccountNumber}
              </dd>
              <dt id="initiatenewloan_confirmation_associated_zip_postal_code_lbl">
                Associated ZIP/Postal Code
              </dt>
              <dd id="initiatenewloan_confirmation_associated_zip_postal_code_value">
                {upsAssociatedZip}
              </dd>
            </IsTruthy>
            <dt id="confirmation_requestor_fullName_lbl">
              Financial Professional
            </dt>
            <dd id="initiatenewloan_confirmation_requestor_fullName_value">
              {loggedInUser}
            </dd>
            <dt id="confirmation_preferred_moc_lbl">
              Financial Professional Email{" "}
            </dt>
            <dd id="initiatenewloan_confirmation_preferred_moc_value">
              {preferredMethodOfContact}
            </dd>
            <dt id="confirmation_request_dateTime_lbl">Request Date/Time</dt>
            <dd id="initiatenewloan_confirmation_request_dateTime_value">
              {requestDateTime}
            </dd>
          </dl>
        </div>
      </div>

      <a
        id="confirmation_serviceHistory_link_lbl"
        className="btn btn-primary hide-print"
        href={`/policyDetails/${encodeURIComponent(
          agreementKey
        )}/viewServiceHistory`}
      >
        VIEW SERVICE HISTORY
      </a>

      <a
        href={`/policyDetails/${encodeURIComponent(agreementKey)}`}
        className="btn btn-link hide-print"
      >
        Close
      </a>
    </div>
  );
};

export default Confirmation;
