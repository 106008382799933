import React from "react";
import DecedentCard from "./decedentCard";
import { useFormContext } from "../../../../contexts/form";
import { useSelectDecedentHook } from "../../hooks";
import { contactUSDetails } from "../../../../data/contactUsDetails";

const SelectDecedent = (props) => {
  const { formData } = useFormContext();
  const { totalCustomers } = formData;
  const { setShouldDisableContinue, setIsDiOnlyClaim, eligibleCustomers } =
    props;

  const userFields = [];
  for (let i = 0; i < totalCustomers; i += 1) {
    userFields.push(formData[`customer${i}`]);
  }

  return (
    <>
      <h1 className="h2" id="select_decedent_header">
        Please select the person whose death is being reported
      </h1>
      <div className="mm-spacing">
        <div className="mm-sub-copy">
          {" "}
          <p id="select_decedent_body_text">
            It&apos;s a difficult time for someone who loses a loved one and
            we&apos;re here to help. We&apos;ll take you through the process
            step by step to gather the information we don&apos;t already have to
            file a death claim on your client&apos;s behalf. If you have any
            questions, please contact the Service Center at{" "}
            {contactUSDetails.DEFAULT.number}, Monday through Friday, 8:00 AM -
            8:00 PM ET.
          </p>
        </div>
      </div>
      <br />
      <div id="selectDecedent" className="mm-spacing">
        <div className="row">
          <div className="col-12">
            <p className="h3 mm-spacing--none " id="select_decedent_header_lbl">
              Select Decedent
            </p>
            <div className="row">
              {userFields.map((userField) => (
                <DecedentCard
                  key={userField.id}
                  userField={userField}
                  useHook={useSelectDecedentHook}
                  setShouldDisableContinue={setShouldDisableContinue}
                  setIsDiOnlyClaim={setIsDiOnlyClaim}
                  eligibleCustomers={eligibleCustomers}
                />
              ))}
            </div>

            <p id="select_decedent_coverpath_note_text">
              Note: Not all Coverpath policies are currently supported in
              ServiceNet. Contact Coverpath Customer Service at 866-957-5347,
              Mon - Fri 8 a.m. - 6 p.m. ET to initiate a claim if applicable
              policies are not listed above.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectDecedent;
