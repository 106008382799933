import React from "react";
import { isDefined } from "../../../utils/evaluate";
import IsTruthy from "../../common/logic/isTruthy";
import { useChangeBillingFrequencyStartDateProps } from "../hooks";
import InputDatePicker from "../../common/datepicker/inputDatePicker";

const SelectFrequencyChangeStartDate = (props) => {
  const {
    datePickerMinDate,
    datePickerMaxDate,
    pacDraftMessage,
    dateHandler,
    invalidDate,
    selectedDate,
  } = useChangeBillingFrequencyStartDateProps(props);

  return (
    <div className="row mm-spacing">
      <div className="col-9">
        <p className="h3">Select Start Date*</p>
        <IsTruthy value={pacDraftMessage} validationFunction={isDefined}>
          <div
            id="pac_start_date_info_message"
            className="alert alert-info"
            role="alert"
            aria-label={pacDraftMessage}
          >
            {pacDraftMessage}
          </div>
        </IsTruthy>
        <p>
          Fractional premium(s) may be due as a result of a change in the
          payment frequency. If so, the fractional premium(s) must be paid
          before the new frequency selected becomes effective.
        </p>
        <p className="h3">Start Date*</p>
        <div className="row">
          <div className="col-md-7">
            <InputDatePicker
              id="freq_change_date_picker"
              datePickerMinDate={datePickerMinDate}
              datePickerMaxDate={datePickerMaxDate}
              selectedDate={selectedDate}
              dateHandler={dateHandler}
              invalidDate={invalidDate}
              errorId="changeBillingFrequencyStartDate"
              errorMessage="Please select today's date or one within the next 30 days."
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default SelectFrequencyChangeStartDate;
