import React from "react";
import InputForm from "../common/forms/inputForm";
import DropdownForm from "../common/forms/dropdownForm";
import SearchButton from "../common/forms/inputAddons/searchButton";
import { useSearchFormProps } from "./hooks";
import IsTruthy from "../common/logic/isTruthy";
import { compareStrings } from "../../utils/string";
import IsFalsy from "../common/logic/isFalsy";
import { fireTealiumEvent } from "../../utils/tealium";
import RadioForm from "../common/forms/radioForm";
import { useUserState } from "../../contexts/user";

const SearchForm = (props) => {
  const {
    resetForm,
    searchByType,
    formField,
    fetchPolicy,
    disableSearch,
    lastNameField,
    firstNameField,
    agentId,
    agencyNumber,
    searchByAgencyNumber,
    searchByAgentId,
    showAgentAgencyForm,
  } = useSearchFormProps(props);

  const {
    user: { email },
  } = useUserState();

  const handleSearch = (e) => {
    fetchPolicy(e);
    let property = "";
    if (searchByType?.display !== "Policy Number") {
      property = searchByAgentId?.value ? agentId?.value : agencyNumber?.value;
    }
    const value =
      searchByType?.display !== "SSN / TIN" ? formField?.value : "HIDDEN";

    fireTealiumEvent({
      label: "SVNT",
      action: "Search",
      category: searchByType?.display,
      property,
      value,
      custId: email,
    });
    resetForm();
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-3">
          <DropdownForm formField={searchByType} />
        </div>

        <IsFalsy
          value={searchByType.value}
          validationFunction={(val) => compareStrings(val, "fullName")}
        >
          <div className="col-12 col-md-6">
            <InputForm formField={formField} />
          </div>
        </IsFalsy>

        <IsTruthy
          value={searchByType.value}
          validationFunction={(val) => compareStrings(val, "fullName")}
        >
          <div className="col-12 col-md-3">
            <InputForm formField={firstNameField} />
          </div>
          <div className="col-12 col-md-3">
            <InputForm formField={lastNameField} />
          </div>
        </IsTruthy>
        <IsFalsy value={showAgentAgencyForm}>
          <div className="col-12 col-md-3">
            <div className="form-group button-label-offset mm-spacing">
              <SearchButton
                disabled={disableSearch}
                onClick={handleSearch}
                formField={formField}
              />
            </div>
          </div>
        </IsFalsy>
      </div>

      <IsTruthy value={showAgentAgencyForm}>
        <div className="mm-spacing--minor">
          <p className="h5" id="searchByAgentOrAgency">
            <strong>Enter Agent/Agency</strong>
          </p>
          <div className="row">
            <div className="col-12 col-md-5">
              <div
                className="row"
                aria-required="true"
                aria-labelledby="searchByAgentOrAgency"
                role="radiogroup"
              >
                <div className="col-12 col-md-5">
                  <RadioForm formField={searchByAgentId} />
                </div>
                <div className="col-12 col-md-7">
                  <RadioForm formField={searchByAgencyNumber} />
                </div>
              </div>
            </div>
            <IsTruthy value={searchByAgentId.value}>
              <div className="col-12 col-md-4">
                <InputForm formField={agentId} />
              </div>
            </IsTruthy>
            <IsTruthy value={searchByAgencyNumber.value}>
              <div className="col-12 col-md-4">
                <InputForm formField={agencyNumber} />
              </div>
            </IsTruthy>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-3 mm-spacing">
            <SearchButton
              disabled={disableSearch}
              onClick={handleSearch}
              formField={formField}
            />
          </div>
        </div>
      </IsTruthy>
    </>
  );
};

export default SearchForm;
