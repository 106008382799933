import React from "react";
import { compareStrings } from "../../../utils/string";
import IsTruthy from "../../common/logic/isTruthy";

export const SelectedDirectLinksList = (props) => {
  const { selectedDirectLinks, isCalledFrom } = props;
  const ulCssClassName = compareStrings(isCalledFrom, "confirmation")
    ? "list-unstyled"
    : "";
  return (
    <div>
      <ul id="directlink_selected_list" className={ulCssClassName}>
        {selectedDirectLinks.map((linkName) => {
          const snakeCaseLinkName = linkName?.displayName
            .replaceAll(" ", "_")
            .toLowerCase();
          return (
            <li
              key={`${snakeCaseLinkName}_key`}
              id={`${snakeCaseLinkName}_list_entry`}
            >
              <IsTruthy
                value={isCalledFrom}
                validationFunction={(val) =>
                  compareStrings(val, "confirmation")
                }
              >
                <strong> {linkName?.displayName}</strong>
              </IsTruthy>
              <IsTruthy
                value={isCalledFrom}
                validationFunction={(val) => compareStrings(val, "review")}
              >
                {linkName?.displayName}
              </IsTruthy>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default React.memo(SelectedDirectLinksList);
