import { isDefined } from "./evaluate";

export const contentApiPdfRequestNumericalFields = [
  "netSurrenderAmount",
  "dividendAvailableAmount",
  "totalCashValueAmount",
  "settlementDividendAmount",
  "paymentAmount",
  "cashSurrenderAmount",
  "loanInterestRate",
  "loanInterestAmount",
  "loanPrincipal",
  "faceAmount",
  "cashValuePaidUpDividendAmount",
  "netDeathBenefitAmount",
];

export const setUndefinedFieldsTo0 = ({ data, fields }) => {
  const requestBody = { ...data };

  fields.forEach((field) => {
    if (!isDefined(requestBody[field])) {
      requestBody[field] = 0;
    }
  });
  return requestBody;
};
