import React from "react";
import LabelWithHelpModal from "../../common/forms/labelWithHelpModal";

const DocumentHubModalBody = () => (
  <div className="modal-body">
    <hr />
    <h5 id="directLink_DocumentHub_ModalTitle">Document Hub</h5>
    <p>
      The following documents (PDF) are available on the{" "}
      <strong>website </strong>or <strong>mobile</strong> app:
    </p>
    <ul>
      <li>Bill Statements (Bills)</li>
      <li>Tax Documents</li>
      <li>Statements (Annual & Annuity)</li>
      <li>Confirmations</li>
      <li>Loan Statement</li>
      <li>Record of Payment</li>
      <li>Policy Coverage Summary / Policy Verification Letter</li>
      <li>Cash Value Quote (Current & Historical)</li>
    </ul>
    <div className="mm-spacing--minor" />
    <h5 id="directLink_DocumentHub_ModalQuickTips">Quick Tips</h5>
    <ul>
      <li>
        If a customer has previously generated a Cash Value Letter or Policy
        Verification Letter online those will also be available
      </li>
    </ul>
  </div>
);

const DocumentHubModal = () => (
  <LabelWithHelpModal
    text="Document Hub"
    modalTitle="Document Hub"
    modalId="documentHub"
    modalBodyComponent={DocumentHubModalBody}
  />
);

export default DocumentHubModal;
