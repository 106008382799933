import { formatDate } from "../../../utils/dates";
import { compareStrings } from "../../../utils/string";

export const getFileName = (row, policyId) => {
  const formattedDate = formatDate(row.effectiveDate, "YYYYMMDD");
  const fileExtension = row.mimeType ? row.mimeType.split("/")[1] : "pdf";
  return `${`${formattedDate}-${policyId}-${row.documentType}`}.${fileExtension}`;
};

export const convertBase64StringToByteArray = (str) => {
  const byteCharacters = atob(str);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i += 1) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return byteArray;
};

export const downloadPDF = (doc, policyId, pdfData, fileName = "") => {
  const pdfFile = new Blob([pdfData], { type: doc.mimeType });
  const pdfFileUrl = URL.createObjectURL(pdfFile);
  const formattedFileName = compareStrings(fileName, "")
    ? getFileName(doc, policyId)
    : fileName;

  if (window.navigator.msSaveOrOpenBlob) {
    // for IE, due to createObjectURL not supported by IE
    window.navigator.msSaveOrOpenBlob(pdfFile, fileName);
  } else {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = pdfFileUrl;
    a.download = formattedFileName;
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(pdfFileUrl);
    }, 100);
  }
};
