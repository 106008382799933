import { formatDate } from "../../utils/dates";
import { formatPhoneNumberString } from "../../utils/format";

export const processServiceHistoryData = (props) => {
  const serviceHistoryEvents = props?.response?.data?.events || [];

  const eventsArray =
    serviceHistoryEvents.map((event) => {
      const {
        eventName = "-",
        serviceChannel = "-",
        requestorDesc = "-",
        requestorName = "-",
        extendedComment = "",
        requestorPhoneNr,
        workEvent = "",
      } = event;

      const eventDate = formatDate(event?.receivedDate) || "-";
      const eventDateTime = event?.receivedDate || "";
      let completionDate = formatDate(event?.completionDate) || "-";
      let status = "-";

      if (event?.completionInd === "N") {
        status = "Pending";
        completionDate = formatDate(event?.expectedCompletionDate) || "-";
      } else if (event?.completionInd === "Y") {
        status = "Resolved-Completed";
      }
      const requestorPhoneNumber =
        formatPhoneNumberString(requestorPhoneNr) || "-";

      return {
        eventName,
        eventDate,
        eventDateTime,
        status,
        serviceChannel,
        completionDate,
        requestorDesc,
        requestorName,
        requestorPhoneNumber,
        extendedComment,
        workEvent,
      };
    }) || [];

  return { eventsArray };
};
