import { useEffect } from "react";
import { useAgentProfileState } from "../../../contexts/agentProfile";
import { types, useFormContext } from "../../../contexts/form";
import { useUserState } from "../../../contexts/user";
import { useRequestorContactEmail } from "../../../hooks/helper";
import { isDefined } from "../../../utils/evaluate";
import { compareStrings, stringIsEmptyOrNullish } from "../../../utils/string";
import {
  confirmEmailValidation,
  emailValidation,
  useConfirmEmailValidation,
} from "../../../utils/validation";

export const useRequestorContactProps = (props) => {
  const { useContainerHook = () => {} } = props;
  const {
    user: { firstName, lastName },
  } = useUserState();
  const loggedInUser = `${firstName} ${lastName}`;
  const {
    formData: {
      requestorNameInput,
      contactChoiceEmailInput,
      contactChoiceConfirmEmailInput,
    },
    dispatchForm,
  } = useFormContext();

  const {
    agentProfile: { agentProfileRequest },
  } = useAgentProfileState();

  const contactChoiceEmail = useRequestorContactEmail();

  useEffect(() => {
    if (!contactChoiceEmailInput.value) {
      dispatchForm({
        type: types.REPLACE_FIELD,
        formField: {
          ...contactChoiceEmailInput,
          value: contactChoiceEmail,
        },
      });
    }
  }, [contactChoiceEmail]);

  const confirmEmailValidationErrors = useConfirmEmailValidation({
    formField: contactChoiceConfirmEmailInput,
  });

  useEffect(() => {
    dispatchForm({
      type: types.REPLACE_FIELD,
      formField: {
        ...contactChoiceConfirmEmailInput,
        emailFormFieldValue: contactChoiceEmailInput.value,
        errors: confirmEmailValidationErrors,
      },
    });
  }, [contactChoiceEmailInput]);

  const hideChoiceConfirmEmailInput =
    !stringIsEmptyOrNullish(contactChoiceEmail) &&
    compareStrings(contactChoiceEmail, contactChoiceEmailInput.value);

  useContainerHook();

  return {
    requestorNameInput,
    contactChoiceEmailInput,
    contactChoiceConfirmEmailInput,
    loggedInUser,
    hideChoiceConfirmEmailInput,
    agentProfileRequest,
  };
};

export const checkForRequestorContactError = (props) => {
  const { formData, setError, requestorContactEmail } = props;
  const { contactChoiceEmailInput, contactChoiceConfirmEmailInput } = formData;

  const areRequiredFieldsFilled =
    isDefined(contactChoiceEmailInput.value) &&
    (compareStrings(requestorContactEmail, contactChoiceEmailInput.value) ||
      isDefined(contactChoiceConfirmEmailInput.value));

  const emailValidationError = emailValidation({
    formField: contactChoiceEmailInput,
  });
  const confirmEmailValidationError = confirmEmailValidation({
    formField: contactChoiceConfirmEmailInput,
  });

  let validationError = null;

  if (!areRequiredFieldsFilled) {
    validationError = "Please fill out all required fields.";
  } else if (
    emailValidationError.hasError ||
    (!compareStrings(requestorContactEmail, contactChoiceEmailInput.value) &&
      confirmEmailValidationError.hasError)
  ) {
    validationError = "Please fix input errors.";
  }

  setError(validationError);
  return { hasRequestorContactError: isDefined(validationError) };
};

export const requestorContactLayout = {
  PAGE: "PAGE",
  SECTION: "Section",
};
