import React from "react";
import CheckboxForm from "../common/forms/checkboxForm";
import { isDefined } from "../../utils/evaluate";

const Choices = (props) => {
  const { formData, lineOfBusinessCode } = props;
  const checkBoxFields = [];
  Object.keys(formData).forEach((key) => {
    const { type, directLink } = formData[key] || {};
    if (type === "linkCheckbox" && isDefined(directLink)) {
      checkBoxFields.push(formData?.[key]);
    }
  });

  const CheckBoxes = checkBoxFields.map((element) => (
    <div className="col-md-12" key={element.id}>
      <CheckboxForm
        formField={element}
        lineOfBusinessCode={lineOfBusinessCode}
      />
    </div>
  ));

  return (
    <div id="directlink_options_list" className="row">
      {CheckBoxes}
    </div>
  );
};

export default Choices;
