import React from "react";
import RadioForm from "../../forms/radioForm";

const RadioButtonCell = (props) => {
  const { row } = props;
  const { formField } = row;
  return <RadioForm formField={formField} />;
};

export default React.memo(RadioButtonCell);
