import { convertToTitleCase } from "../../utils/format";

export const processBeneficiaryRequest = (props) => {
  const beneficiaryData = props?.response?.data;
  const beneficiaries =
    beneficiaryData?.beneficiaryDesignation?.beneficiaries || [];

  const source = beneficiaryData?.beneficiaryDesignation?.source || "";

  const beneficiaryArray =
    beneficiaries.map((beneficiary) => {
      const beneficiaryClass = beneficiary.beneficiaryClass
        ? convertToTitleCase(beneficiary.beneficiaryClass)
        : "-";
      const relationship = beneficiary.relationship
        ? convertToTitleCase(beneficiary.relationship)
        : "-";

      const beneficiaryTypeIsNumber =
        typeof beneficiary.beneficiaryType === "number";
      let beneficiaryType;
      if (beneficiaryTypeIsNumber) {
        beneficiaryType = beneficiary.beneficiaryType.toString();
      } else {
        beneficiaryType = beneficiary.beneficiaryType
          ? convertToTitleCase(beneficiary.beneficiaryType)
          : "-";
      }

      const {
        nameObject = {
          fullName: beneficiary.fullName || "",
          firstName: beneficiary.firstName || "",
          lastName: beneficiary.lastName || "",
          middleName: beneficiary.middleName || "",
          suffix: beneficiary.suffix || "",
        },
        amount = beneficiary.distribution?.amount,
        percent = beneficiary.distribution?.percent,
        beneficiaryDesignationDescription = "",
        email = "-",
        phone = {
          areaCode: "",
          number: "",
        },
        address = {
          line1: "",
          city: "",
          state: "",
          postalCode: "",
          countryCode: "",
        },
        beneficiaryId = "",
        trustType = "",
      } = beneficiary;

      return {
        beneficiaryClass,
        nameObject,
        relationship,
        beneficiaryType,
        amount,
        percent,
        email,
        phone,
        address,
        beneficiaryId,
        beneficiaryDesignationDescription,
        trustType,
      };
    }) || [];

  return { beneficiaryArray, source };
};
