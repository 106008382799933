import React from "react";
import { formatAlirVntg1SectionData } from "./riderSectionHelper";
import { InformationPopover } from "../../../../common/popovers/informationPopover";
import {
  LISR_UNAVAILABLE_VALUE_TOOLTIP_TEXT,
  LISR_UNAVAILABLE_VALUE_PRINT_TEXT,
} from "../../../../../constants/ui";
import IsTruthy from "../../../../common/logic/isTruthy";

const AlirVntg1RiderSection = (props) => {
  const { hideLisrAlirBilledPremiumValues } = props;
  const {
    riderCoverages,
    riderName,
    scheduledModalPaymentVal,
    catchUpAmountAvailableVal,
    catchUpAmountAppliedVal,
    modalNonUWIncrAmtAvailableVal,
  } = formatAlirVntg1SectionData(props);

  return riderCoverages.map((rider, index) => {
    const { billedPremium, billingMode } = rider;

    return (
      // eslint-disable-next-line react/no-array-index-key
      <div id={`rider${index}`} key={`rider_${index}`}>
        <div className="row">
          <div className="col-12 col-md-6">
            <dl className="mm-key-value-pair">
              <dt id={`rider${index}_name_alirVntg1_premium_lbl`}>
                Rider Name
              </dt>
              <dd id={`rider${index}_name_alirVntg1_premium_value`}>
                {riderName}
              </dd>
              <dt id={`billed${index}_frequency_alirVntg1_lbl`}>
                Billed Frequency
              </dt>
              <dd id={`billed${index}_frequency_alirVntg1_value`}>
                {billingMode}
              </dd>
              <dt id={`catch_up_amount_available${index}_alirVntg1_lbl`}>
                Catch Up Amount Available
              </dt>
              <dd id={`catch_up_amount_available${index}_alirVntg1_value`}>
                {catchUpAmountAvailableVal}
              </dd>
              <dt id={`modal_nonwritten_increase_amount${index}_lbl`}>
                Modal Non-Underwritten Increase Amount Available
              </dt>
              <dd id={`modal_nonwritten_increase_amount${index}_value`}>
                {modalNonUWIncrAmtAvailableVal}
              </dd>
            </dl>
          </div>
          <div className="col-12 col-md-6">
            <dl className="mm-key-value-pair">
              <dt id={`billed_premium${index}_alirVntg1_lbl`}>
                Billed Premium
              </dt>
              <dd id={`billed_premium${index}_alirVntg1_value`}>
                {billedPremium}
                <IsTruthy value={hideLisrAlirBilledPremiumValues}>
                  <sup className="print-only">1</sup>{" "}
                  <InformationPopover
                    popoverId="billed_premium_unavailable_tooltip"
                    text={LISR_UNAVAILABLE_VALUE_TOOLTIP_TEXT}
                  />
                </IsTruthy>
              </dd>
              <dt id={`scheduled_modal${index}_payment_alirVtng1_lbl`}>
                Scheduled Modal Payment
              </dt>
              <dd id={`scheduled_modal${index}_payment_alirVtng1_value`}>
                {scheduledModalPaymentVal}
                <IsTruthy value={hideLisrAlirBilledPremiumValues}>
                  <sup className="print-only">1</sup>{" "}
                  <InformationPopover
                    popoverId="scheduled_modal_unavailable_tooltip"
                    text={LISR_UNAVAILABLE_VALUE_TOOLTIP_TEXT}
                  />
                </IsTruthy>
              </dd>
              <dt id={`catch_up_amount${index}_alirVtng1_lbl`}>
                Catch Up Amount Applied
              </dt>
              <dd id={`catch_up_amount${index}_alirVtng1_value`}>
                {catchUpAmountAppliedVal}
              </dd>
            </dl>
          </div>
        </div>
        <ol id="alir_rider_section_print_footnotes" className="print-only">
          <IsTruthy value={hideLisrAlirBilledPremiumValues}>
            <li>{LISR_UNAVAILABLE_VALUE_PRINT_TEXT}</li>
          </IsTruthy>
        </ol>
        <hr />
      </div>
    );
  });
};

export default React.memo(AlirVntg1RiderSection);
