import React, { useEffect } from "react";
import { useLDValue } from "../common/featureFlags/hooks";
import IsFalsy from "../common/logic/isFalsy";
import IsTruthy from "../common/logic/isTruthy";
import { verifyInitiateDeathClaim } from "../initiateDeathClaim/restrictions";
import { useInitiateDeathClaimLinkProps } from "../initiateDeathClaim/hooks";

const InitiateDeathClaimLink = (props) => {
  const {
    agreementData,
    deathClaimEligibilityData,
    cssClassName,
    isRenderAsListItem,
    setShowInitiateDeathClaimLink = () => {},
    isCalledFrom,
  } = props;

  const transactionSupportedPolicies = useLDValue({
    flagName: ["transactionSupportedPolicies"],
  });

  const { handleInitiateDeathClaimLinkClick } =
    useInitiateDeathClaimLinkProps(props);

  const { isTransactionAllowed, isEligible } = verifyInitiateDeathClaim({
    agreementData,
    deathClaimEligibilityData,
    transactionSupportedPolicies,
    isCalledFrom,
  });

  useEffect(() => {
    setShowInitiateDeathClaimLink(isTransactionAllowed && isEligible);
  }, [isTransactionAllowed, isEligible]);

  return (
    <IsTruthy value={isTransactionAllowed}>
      <IsTruthy value={isEligible}>
        <IsTruthy value={isRenderAsListItem}>
          <li>
            <button
              type="button"
              id="initiateDeathClaim-link"
              onClick={handleInitiateDeathClaimLinkClick}
              className={cssClassName}
            >
              {" "}
              Initiate a Death Claim
            </button>
          </li>
        </IsTruthy>
        <IsFalsy value={isRenderAsListItem}>
          <button
            type="button"
            id="initiateDeathClaim-link"
            onClick={handleInitiateDeathClaimLinkClick}
            className={cssClassName}
          >
            {" "}
            Initiate a Death Claim
          </button>
        </IsFalsy>
      </IsTruthy>
    </IsTruthy>
  );
};

export default React.memo(InitiateDeathClaimLink);
