import React from "react";
import { useLoanProps } from "./hooks";
import {
  RequestInformationLink,
  LabelWithInformationPopover,
} from "../../../common/popovers/informationPopover";
import {
  DATA_UNAVAILABLE,
  REQUEST_INFORMATION_TOOLTIP_TEXT,
} from "../../../../constants/ui";
import IsTruthy from "../../../common/logic/isTruthy";
import IsFalsy from "../../../common/logic/isFalsy";
import { isTermPolicy } from "../../../../utils/policies";
import LoadingSection, {
  errorTypes,
  loadingTypes,
} from "../../../common/loading";
import { compareStrings } from "../../../../utils/string";
import { fireTealiumAndFollowLink } from "../../../../utils/tealium";

const Loan = (props) => {
  const { id, agreementKey, policyId, agreementRequest, email } = props;
  const {
    requests: { billingRequest },
    data: {
      maxLoanAmount,
      interestRate,
      interestRateType,
      loanInterestAmount,
      loanPrincipal,
      maxLoanAsOfDate,
      loanPayoffAsOfDate,
      payoffBalance,
      futureLoanPayoffDate,
      futureLoanPayoffAmount,
      showLoanPayoffSection,
      forceShowUnavailable,
      isNTLProduct,
    },
    loanIsEligible,
    redirectLink,
    isTransactionAllowed,
    isHapPolicy,
    isValidStatusAndReason,
  } = useLoanProps(props);

  const dataUnavailable = (value) => value === DATA_UNAVAILABLE;
  const agreementData = agreementRequest.data;
  const showRequestInfo = (value, popoverId, labelId, label, valueId) => (
    <>
      <IsTruthy value={value} validationFunction={dataUnavailable}>
        <IsTruthy value={!isHapPolicy && isValidStatusAndReason}>
          <dt id={labelId}>
            <LabelWithInformationPopover
              label={label}
              popoverId={popoverId}
              text={REQUEST_INFORMATION_TOOLTIP_TEXT}
            />
          </dt>
          <dd id={valueId}>
            <RequestInformationLink
              popoverId={popoverId}
              agreementData={agreementData}
              agreementKey={agreementKey}
              policyId={policyId}
            />
          </dd>
        </IsTruthy>
        <IsFalsy value={!isHapPolicy && isValidStatusAndReason}>
          <dt id={labelId}>{label}</dt>
          <dd id={valueId}>{DATA_UNAVAILABLE}</dd>
        </IsFalsy>
      </IsTruthy>
      <IsFalsy value={value} validationFunction={dataUnavailable}>
        <dt id={labelId}>{label}</dt>
        <dd id={valueId}>{value}</dd>
      </IsFalsy>
    </>
  );

  const maxLoanAmountDisplay = showRequestInfo(
    maxLoanAmount,
    "max_loan",
    "loan_max_loan_lbl",
    "Max Loan Amount",
    "loan_max_loan_value"
  );

  const payoffBalanceDisplay =
    isNTLProduct && !compareStrings(payoffBalance, "Unavailable") ? (
      <>
        <dt id="loan_payoff_amount_lbl">Loan Payoff Amount</dt>
        <dd id="loan_payoff_amount_value">{payoffBalance}</dd>
      </>
    ) : (
      showRequestInfo(
        payoffBalance,
        "loan_payoff_amount",
        "loan_payoff_amount_lbl",
        "Loan Payoff Amount",
        "loan_payoff_amount_value"
      )
    );

  const futureLoanPayoffAmountDisplay = showRequestInfo(
    futureLoanPayoffAmount,
    "future_loan_payoff_amount",
    "future_loan_payoff_amount_lbl",
    "Future Loan Payoff Amount",
    "future_loan_payoff_amount_value"
  );

  return (
    <IsFalsy value={props} validationFunction={isTermPolicy}>
      <div className="mm-spacing">
        <h2 aria-label="Loans" id={id}>
          Loans
        </h2>

        <LoadingSection
          request={billingRequest}
          loadingType={loadingTypes.PARAGRAPH}
          errorType={errorTypes.SECTION}
        >
          <div className="mm-spacing--minor">
            <h3 id="loan_availability_lbl">Loan Availability</h3>
            <hr />
            <div className="row mm-spacing--minor">
              <div className="col-12 col-md-6">
                <dl className="mm-key-value-pair">
                  <IsTruthy value={dataUnavailable(maxLoanAsOfDate)}>
                    <dt id="loan_max_loan_lbl">Max Loan Amount</dt>
                    <dd id="loan_max_loan_value">{DATA_UNAVAILABLE}</dd>
                  </IsTruthy>
                  <IsFalsy value={dataUnavailable(maxLoanAsOfDate)}>
                    {maxLoanAmountDisplay}
                  </IsFalsy>

                  <dt id="loan_current_interest_rate_lbl">
                    Current Interest Rate
                  </dt>
                  <dd id="loan_current_interest_rate_value">{interestRate}</dd>
                </dl>
              </div>
              <div className="col-12 col-md-6">
                <dl className="mm-key-value-pair">
                  <dt id="loan_max_loan_quote_date_lbl">Max Loan as of Date</dt>
                  <dd id="loan_max_loan_quote_date_value">{maxLoanAsOfDate}</dd>
                  <dt id="loan_interest_rate_type_lbl">Interest Rate Type</dt>
                  <dd id="loan_interest_rate_type_value">{interestRateType}</dd>
                </dl>
              </div>
            </div>
            <IsTruthy value={isTransactionAllowed && loanIsEligible}>
              <p className="hide-print" id="initiate_a_new_loan_lbl">
                <button
                  className="btn btn-inline"
                  id="initiate_new_loan_link"
                  type="button"
                  onClick={() =>
                    fireTealiumAndFollowLink({
                      url: redirectLink,
                      action: "Initiate a Loan link clicked from loan section",
                      category: "PDP",
                      value: policyId,
                      custId: email,
                    })
                  }
                >
                  Initiate a New Loan
                </button>{" "}
                for your client.
              </p>
            </IsTruthy>
            <hr />
          </div>
          <IsTruthy value={showLoanPayoffSection}>
            <div className="mm-spacing--minor">
              <h3 id="loan_payoff_info_lbl">Loan Payoff Information</h3>
              <hr />
              <div className="row">
                <div className="col-12 col-md-6">
                  <dl className="mm-key-value-pair">
                    <dt id="loan_payoff_date_lbl">Loan Payoff as of Date</dt>
                    <dd id="loan_payoff_date_value">
                      {" "}
                      <IsFalsy value={dataUnavailable(loanPayoffAsOfDate)}>
                        {loanPayoffAsOfDate}
                      </IsFalsy>
                      <IsTruthy value={dataUnavailable(loanPayoffAsOfDate)}>
                        {DATA_UNAVAILABLE}
                      </IsTruthy>
                    </dd>

                    <IsTruthy value={forceShowUnavailable}>
                      <dt id="loan_payoff_amount_lbl">Loan Payoff Amount</dt>
                      <dd id="loan_payoff_amount_value">{DATA_UNAVAILABLE}</dd>
                    </IsTruthy>
                    <IsFalsy value={forceShowUnavailable}>
                      {payoffBalanceDisplay}
                    </IsFalsy>

                    <dt id="future_loan_payoff_date_lbl">
                      Future Loan Payoff Date
                    </dt>
                    <dd id="future_loan_payoff_date_value">
                      <IsFalsy value={dataUnavailable(futureLoanPayoffDate)}>
                        {futureLoanPayoffDate}
                      </IsFalsy>
                      <IsTruthy value={dataUnavailable(futureLoanPayoffDate)}>
                        {DATA_UNAVAILABLE}
                      </IsTruthy>
                    </dd>
                  </dl>
                </div>
                <div className="col-12 col-md-6">
                  <dl className="mm-key-value-pair">
                    <dt id="loan_principal_lbl">Loan Principal</dt>
                    <dd id="loan_principal_value">
                      <IsFalsy value={dataUnavailable(loanPrincipal)}>
                        {loanPrincipal}
                      </IsFalsy>
                      <IsTruthy value={dataUnavailable(loanPrincipal)}>
                        {DATA_UNAVAILABLE}
                      </IsTruthy>
                    </dd>
                    <IsFalsy value={isNTLProduct}>
                      <dt id="loan_accrued_loan_interest_lbl">
                        Accrued Loan Interest
                      </dt>
                      <dd id="loan_accrued_loan_interest_value">
                        {loanInterestAmount}
                      </dd>
                    </IsFalsy>
                    <IsTruthy value={forceShowUnavailable}>
                      <dt id="future_loan_payoff_amount_lbl">
                        Future Loan Payoff Amount
                      </dt>
                      <dd id="future_loan_payoff_amount_value">
                        {DATA_UNAVAILABLE}
                      </dd>
                    </IsTruthy>
                    <IsFalsy value={forceShowUnavailable}>
                      {futureLoanPayoffAmountDisplay}
                    </IsFalsy>
                  </dl>
                </div>
              </div>
              <hr />
            </div>
          </IsTruthy>
        </LoadingSection>
      </div>
    </IsFalsy>
  );
};

export default React.memo(Loan);
