import {
  checkIfTransactionIsAllowed,
  isPolicyInForce,
} from "../../../../utils/policies";
import { supportedTransactions } from "../../../../utils/translate";
import { compareStrings } from "../../../../utils/string";
import { useLDValue } from "../../../common/featureFlags/hooks";
import { getFirstServicingAgent } from "../../../../utils/findData";
import { isDefined } from "../../../../utils/evaluate";

export const verifyCashValueQuoteLink = (props) => {
  const { agreementData, transactionSupportedPolicies } = props;

  const { isTransactionAllowed } = checkIfTransactionIsAllowed({
    transactionSupportedPolicies,
    transactionName: supportedTransactions.CashValueQuote,
    agreementData,
  });

  const returnObject = {
    isEligible: isAgreementDataEligibleForCashValueQuoteLink(agreementData),
    isTransactionAllowed,
  };

  return returnObject;
};

export const isAgreementDataEligibleForCashValueQuoteLink = (agreementData) => {
  const { status, requestCashValueQuoteIndicator } = agreementData;

  const isValidPolicyStatus = isPolicyInForce(status);
  const isTrueIndicatorForCashValueQuote = compareStrings(
    "Yes",
    requestCashValueQuoteIndicator
  );

  return isValidPolicyStatus && isTrueIndicatorForCashValueQuote;
};

export const useVerifyCashValueQuoteLink = (props) => {
  const { data } = props;
  const transactionSupportedPolicies = useLDValue({
    flagName: ["transactionSupportedPolicies"],
  });

  const { isEligible, isTransactionAllowed } = verifyCashValueQuoteLink({
    agreementData: data,
    transactionSupportedPolicies,
  });

  const isEligibleForCashValueQuoteLink = isEligible && isTransactionAllowed;

  return { isEligibleForCashValueQuoteLink };
};

export const isProducersDataEligibleForCashValueDownload = (producersData) => {
  const servicingAgent = getFirstServicingAgent({
    agents: producersData?.producers,
  });

  return {
    isProducerEligibleForCashValueDownload: isDefined(servicingAgent.fullName),
  };
};
