import React from "react";
import IsFalsy from "../../../common/logic/isFalsy";
import IsTruthy from "../../../common/logic/isTruthy";

const ClaimsPacketAndFormsQuestion = (props) => {
  const { question, questionNumber, displayClaimsPacketAndFormsInfoQuestion5 } =
    props;
  return (
    <>
      <IsTruthy
        value={questionNumber === 5 && displayClaimsPacketAndFormsInfoQuestion5}
      >
        <p id={`claimsPacketAndForms_Question${questionNumber}`} className="h4">
          {question}
        </p>
      </IsTruthy>
      <IsFalsy value={questionNumber === 5}>
        <p id={`claimsPacketAndForms_Question${questionNumber}`} className="h4">
          {question}
        </p>
      </IsFalsy>
    </>
  );
};
export default React.memo(ClaimsPacketAndFormsQuestion);
