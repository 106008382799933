import React from "react";
import { useParams } from "react-router-dom";
import { setTitle } from "../../utils/dom";
import { useChangeBillingFrequencyProps } from "./hooks";
import LoadingSection, { errorTypes, loadingTypes } from "../common/loading";
import PolicyInformation from "./pages/policyInformation";
import Header from "../layout/header";
import LdBannerContent from "../common/molecules/ldBannerContent";
import FiveHundredOneError from "../common/errors/501";
import Footer from "../layout/footer";
import IsTruthy from "../common/logic/isTruthy";
import IsFalsy from "../common/logic/isFalsy";
import { ModalProvider } from "../../contexts/modal";
import Form from "../common/forms/form";
import ChangeBillingFrequencyHeader from "./pages/changeBillingFrequencyHeader";
import FourHundredFourError from "../common/errors/404";
import BillingFrequency from "./pages/billingFrequency";
import ModalControl from "./modalControl";
import Confirmation from "./pages/confirmation";
import CTAform from "./pages/CTAform";
import Review from "./pages/review";
import RequestorContact from "../common/requestorContact";
import { requestorContactLayout } from "../common/requestorContact/hooks";
import SelectFrequencyChangeStartDate from "./pages/selectFrequencyChangeStartDate";
import FiveHundredError from "../common/errors/500";
import ChangeBillingFrequencyAcknowledgement from "./pages/changeBillingFrequencyAcknowledgement";
import ErrorPage from "../common/errors/errorPage";

const ChangeBillingFrequency = () => {
  setTitle("ServiceNet - Change Billing Frequency");
  const { agreementKey } = useParams();
  const {
    owners,
    insured,
    productTypeName,
    policyId,
    agreementRequest,
    billingRequest,
    producerRequest,
    serviceHistoryRequest,
    agreementData,
    billingData,
    billingModeData,
    isMmsdOnlyUser,
    isPolicyLoaded,
    isEligible,
    isTransactionAllowed,
    isUnauthorized,
    fields,
    pageNumber,
    lineOfBusinessCode,
    setPageNumber,
    previousPage,
    nextPage,
    setError,
    error,
    onSubmit,
    shouldDisableSubmit,
    hasBillingFrequencyChangeTransactionPending,
    showErrorPage,
    transactionId,
    errorText,
    isPolicyNotFound,
  } = useChangeBillingFrequencyProps(agreementKey);

  if (isUnauthorized) {
    return (
      <>
        <Header />
        <div className="container">
          <LdBannerContent />
          <FiveHundredOneError />
        </div>
        <Footer />
      </>
    );
  }

  if (isPolicyNotFound) {
    return (
      <>
        <Header />
        <div className="container">
          <LdBannerContent />
          <FourHundredFourError />
        </div>
        <Footer />
      </>
    );
  }

  return (
    <LoadingSection
      request={agreementRequest}
      loadingType={loadingTypes.SPINNER}
      errorType={errorTypes._500}
      addHeaderFooterToError
    >
      <LoadingSection
        request={billingRequest}
        loadingType={loadingTypes.SPINNER}
        errorType={errorTypes._500}
        addHeaderFooterToError
      >
        <LoadingSection
          request={serviceHistoryRequest}
          loadingType={loadingTypes.SPINNER}
          errorType={errorTypes._500}
          addHeaderFooterToError
        >
          <LoadingSection
            request={producerRequest}
            loadingType={loadingTypes.SPINNER}
            errorType={errorTypes._500}
            addHeaderFooterToError
          >
            <IsTruthy value={isPolicyLoaded}>
              <IsFalsy value={isTransactionAllowed}>
                <IsTruthy value={isMmsdOnlyUser}>
                  <Header />
                  <div className="container">
                    <LdBannerContent />
                    <ErrorPage
                      errorTitle="Our records indicate you do not have access to this application."
                      agreementKey={agreementRequest?.agreementKey}
                      errorPageFor="mmsdOnly"
                      imageClass="illustration-error_500"
                    />
                  </div>
                  <Footer />
                </IsTruthy>
                <IsFalsy value={isMmsdOnlyUser}>
                  <Header />
                  <div className="container">
                    <LdBannerContent />
                    <FourHundredFourError />
                  </div>
                  <Footer />
                </IsFalsy>
              </IsFalsy>
            </IsTruthy>
            <IsTruthy value={isTransactionAllowed}>
              <IsFalsy value={isEligible}>
                <Header />
                <div className="container">
                  <LdBannerContent />
                  <FourHundredFourError />
                </div>
                <Footer />
              </IsFalsy>
            </IsTruthy>
            <IsTruthy value={isTransactionAllowed && isEligible}>
              <IsTruthy value={showErrorPage}>
                <Header />
                <div className="container">
                  <LdBannerContent />
                  <FiveHundredError
                    turnOffReload
                    transactionId={transactionId}
                    title={errorText}
                    agreementKey={agreementKey}
                  />
                </div>
                <Footer />
              </IsTruthy>
              <IsFalsy value={showErrorPage}>
                <div className="container">
                  <Form fields={fields}>
                    <ModalProvider>
                      <ChangeBillingFrequencyHeader
                        pageNumber={pageNumber}
                        previousPage={previousPage}
                        shouldDisableSubmit={shouldDisableSubmit}
                        hasBillingFrequencyChangeTransactionPending={
                          hasBillingFrequencyChangeTransactionPending
                        }
                        lineOfBusinessCode={lineOfBusinessCode}
                      />
                      <IsTruthy value={pageNumber === 1}>
                        <h1>Frequency Change/PAC Removal</h1>
                        <p className="mm-sub-copy">* = Required Field</p>
                        <br />
                        <PolicyInformation
                          owners={owners}
                          insured={insured}
                          productTypeName={productTypeName}
                          policyId={policyId}
                        />
                        <BillingFrequency
                          billingModeData={billingModeData}
                          setPageNumber={setPageNumber}
                          setError={setError}
                        />
                        <SelectFrequencyChangeStartDate
                          billingData={billingData}
                        />
                        <RequestorContact
                          layout={requestorContactLayout.SECTION}
                        />
                        <ChangeBillingFrequencyAcknowledgement />
                      </IsTruthy>
                      <IsTruthy value={pageNumber === 2}>
                        <Review
                          policyId={policyId}
                          productTypeName={productTypeName}
                          owners={owners}
                          insured={insured}
                          billingData={billingData}
                          agreementData={agreementData}
                        />
                      </IsTruthy>
                      <IsTruthy value={pageNumber === 4}>
                        <CTAform lineOfBusinessCode={lineOfBusinessCode} />
                      </IsTruthy>
                      <IsTruthy value={pageNumber === 3}>
                        <Confirmation
                          policyId={policyId}
                          productTypeName={productTypeName}
                          owners={owners}
                          insured={insured}
                          billingData={billingData}
                        />
                      </IsTruthy>
                      <ModalControl
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                        nextPage={nextPage}
                        onSubmit={onSubmit}
                        setError={setError}
                        error={error}
                        shouldDisableSubmit={shouldDisableSubmit}
                        lineOfBusinessCode={lineOfBusinessCode}
                        policyId={policyId}
                        billingData={billingData}
                      />
                    </ModalProvider>
                  </Form>
                </div>
              </IsFalsy>
            </IsTruthy>
          </LoadingSection>
        </LoadingSection>
      </LoadingSection>
    </LoadingSection>
  );
};

export default React.memo(ChangeBillingFrequency);
