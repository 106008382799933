import React from "react";
import { useDecedentInformationProps } from "../hooks";
import DecedentInfoQuestionsAndAnswers from "./decedentInformation/decedentInfoQuestionAndAnswers";
import { InitiateDeathClaimDetails } from "./intiateDeathClaimDetails";

const DecedentInformation = (props) => {
  const { decedentInfoQuestionsText } = useDecedentInformationProps(props);

  return (
    <div>
      <h2>
        <div id="decedent_Information_Header">
          In this step, you will be asked to provide information about the
          decedent.
        </div>
      </h2>
      <div>
        <div className="row">
          <InitiateDeathClaimDetails />
        </div>
      </div>
      <DecedentInfoQuestionsAndAnswers
        decedentInfoQuestionsText={decedentInfoQuestionsText}
      />
    </div>
  );
};

export default DecedentInformation;
