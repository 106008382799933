import React, { useEffect } from "react";
import { useUserState } from "../../contexts/user";
import { isMmsdOnlyAgent } from "../../utils/findData";
import { compareStrings } from "../../utils/string";
import { fireTealiumAndFollowLink } from "../../utils/tealium";
import { verifyChangeAddress } from "../changeAddress/hooks";
import { useLDValue } from "../common/featureFlags/hooks";
import IsFalsy from "../common/logic/isFalsy";
import IsTruthy from "../common/logic/isTruthy";

const ChangeAddressLink = (props) => {
  const {
    data,
    policyId,
    userEmail,
    cssClassName = "btn btn-inline",
    isCalledFrom,
    target = "_self",
    billingData,
    setShowChangeAddressLink = () => {},
    annuityData = {},
    isRenderAsListItem = false,
  } = props;

  const transactionSupportedPolicies = useLDValue({
    flagName: ["transactionSupportedPolicies"],
  });

  const { user } = useUserState();
  const isMmsdOnlyUser = isMmsdOnlyAgent(user);

  const changeAddressProps = verifyChangeAddress({
    data,
    policyId,
    prependPolicyDetails: true,
    transactionSupportedPolicies,
    billingData,
    isMmsdOnlyUser,
    annuityData,
  });

  useEffect(() => {
    setShowChangeAddressLink(
      !changeAddressProps.unavailable && !changeAddressProps.showAlert
    );
  }, [changeAddressProps.unavailable, changeAddressProps.showAlert]);

  return (
    <>
      <IsTruthy value={changeAddressProps.unavailable}>
        <IsTruthy
          value={
            changeAddressProps.showAlert && compareStrings(isCalledFrom, "PDP")
          }
        >
          <li>
            <div className="alert alert-info" role="alert">
              Address Change is not available.
            </div>
          </li>
        </IsTruthy>
      </IsTruthy>

      <IsFalsy value={changeAddressProps.unavailable}>
        <IsFalsy value={changeAddressProps.showAlert}>
          <IsTruthy value={isRenderAsListItem}>
            <li>
              <button
                type="button"
                id="changeaddress-link"
                onClick={() =>
                  fireTealiumAndFollowLink({
                    url: changeAddressProps.redirectLink,
                    action: "Change Address link clicked",
                    category: isCalledFrom,
                    value: policyId,
                    custId: userEmail,
                    target,
                  })
                }
                className={cssClassName}
              >
                {" "}
                Change Address
              </button>
            </li>
          </IsTruthy>
          <IsFalsy value={isRenderAsListItem}>
            <button
              type="button"
              id="changeaddress-link"
              onClick={() =>
                fireTealiumAndFollowLink({
                  url: changeAddressProps.redirectLink,
                  action: "Change Address link clicked",
                  category: isCalledFrom,
                  value: policyId,
                  custId: userEmail,
                  target,
                })
              }
              className={cssClassName}
            >
              {" "}
              Change Address
            </button>
          </IsFalsy>
        </IsFalsy>
      </IsFalsy>
    </>
  );
};

export default React.memo(ChangeAddressLink);
