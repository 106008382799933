import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useUserState } from "../../contexts/user";
import { useMakeRequest } from "../../hooks/api";
import {
  isHomeOfficeUser,
  getBpIdFromUser,
  getTrimmedPolicyIdFromAgreement,
  generateAgreementKeyfromAgreementData,
  getPolicyIdFromAgreementKey,
} from "../../utils/findData";
import {
  getVerifyPolicyAgreementInfo,
  isDisabilityPolicy,
} from "../../utils/policies";
import { useLDValue } from "../common/featureFlags/hooks";
import { verifyInitiateDeathClaim } from "./restrictions";
import { isDefined, isEmptyArray, isEmptyObject } from "../../utils/evaluate";
import { compareStrings, compareArrayOfStrings } from "../../utils/string";
import { types, useFormContext } from "../../contexts/form";
import ModalPopup from "../common/popup/modalPopup";
import { useModalState } from "../../contexts/modal";
import {
  fireTealiumAndFollowLink,
  fireTealiumEvent,
} from "../../utils/tealium";
import {
  INITIATE_DEATH_CLAIM_MAX_BENE_CONTACT_COUNT,
  INITIATE_DEATH_CLAIM_MAX_RECIPIENT_CONTACT_COUNT,
} from "../../constants/logic";

import { hasError, useGeneralValidation } from "../../utils/validation";
import countries from "../../data/countries.json";
import stateAndTerritories from "../../data/statesAndTerritories.json";
import { scrollToElement } from "../../utils/dom";
import ContactInformationModal from "./pages/common/contactInformationModal";
import {
  resetContactFormFields,
  useContactInformationFields,
} from "./pages/common/contactInformationModal/hooks";
import {
  contactInformationUpdateAction,
  supportedTransactions,
} from "../../utils/translate";
import { formatDate, getCurrentDate } from "../../utils/dates";
import { createDeathClaimNotification, fetchFillablePdfForm } from "../../api";
import {
  convertBase64StringToByteArray,
  downloadPDF,
} from "../common/helpers/downloadHelper";
import {
  convertToTitleCase,
  formatLineOfBusiness,
  formatName,
  nameFormattingOptions,
} from "../../utils/format";
import { checkComplexBeneArrangement } from "../policyDetails/sections/peopleOnPolicy/hooks";
import { sanitizeData } from "../../utils/sanitizeData";
import { useAddOrRemoveChatLauncher } from "../../utils/genesysChatClient";

export const useInitiateDeathClaimProps = () => {
  const [shouldDisableContinue, setShouldDisableContinue] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [beneContactsCount, setBeneContactsCount] = useState(0);
  const [claimRecipientsContactsCount, setClaimRecipientsContactsCount] =
    useState(0);
  const [isDiOnlyClaim, setIsDiOnlyClaim] = useState(false);
  const { agreementKey } = useParams();
  const nextPage = () => setPageNumber(pageNumber + 1);
  const lastPage = () => setPageNumber(pageNumber - 1);
  const [showLoadingIcon, setShowLoadingIcon] = useState(false);
  const [error, setError] = useState(null);
  const [showErrorPage, setShowErrorPage] = useState(false);
  const [errorText, setErrorText] = useState("");

  const previousPage = () => {
    lastPage();
  };
  const onSubmit = (props) => {
    const { formDataForSubmission, transactionName, email, recipients, user } =
      props;
    const { additionalInfo } = formDataForSubmission;
    const HAS_ERRORS = hasError(additionalInfo);

    if (!HAS_ERRORS) {
      const requestBody = makeSubmissionRequestBody({
        formDataForSubmission,
        recipients,
        user,
        isDiOnlyClaim,
      });

      const headers = {
        Authorization: `Bearer ${user.accessToken}`,
      };

      setShowLoadingIcon(true);
      createDeathClaimNotification({
        headers,
        apiParams: { ...requestBody },
        agreementKey,
      })
        .then((result) => {
          if (result?.status === 200) {
            setShowLoadingIcon(false);
            fireTealiumEvent({
              label: "SVNT",
              action: `${lineOfBusinessCode}: ${transactionName} submitted`,
              category: `${transactionName}`,
              value: policyId,
              custId: email,
            });
            setPageNumber(6);
          }
        })
        .catch((e) => {
          fireTealiumEvent({
            label: "SVNT",
            action: `${lineOfBusinessCode}: ${transactionName} failed`,
            category: `${transactionName}`,
            value: policyId,
            custId: email,
          });

          setErrorText(
            compareStrings("Network Error", e.message)
              ? "The page you are looking for is temporarily unavailable."
              : "There was an error processing your request; please try again later."
          );

          setShowErrorPage(true);
        });
    }
  };

  const { user } = useUserState();
  const shouldDisableSubmit = isHomeOfficeUser(user);
  const { isAnAgent, bpId } = getBpIdFromUser(user);

  const agreementRequest = useMakeRequest({
    apiRequest: "fetchAgreementData",
    apiParams: { agreementKey },
    immediateRequest: true,
    dataSelector: "processAgreementRequest",
  });

  const producerRequest = useMakeRequest({
    apiRequest: "fetchProducerData",
    apiParams: { producerId: bpId },
    immediateRequest: false,
    dataSelector: "processProducerData",
  });

  const agreementData = agreementRequest?.data || {};
  const {
    policyId,
    lineOfBusinessCode,
    isPolicyNotFound,
    agreementKeyPrefix,
    agreementKeySuffix,
    agreementKeyAdmin,
  } = agreementData;

  const isPolicyDataLoaded =
    isDefined(policyId) && isDefined(lineOfBusinessCode);

  const deathClaimEligibilityDataRequest = useMakeRequest({
    apiRequest: "fetchDeathClaimEligibilityData",
    apiParams: {
      primaryId: getPolicyIdFromAgreementKey(agreementKey),
      prefix: agreementKeyPrefix,
      suffix: agreementKeySuffix,
      adminSystemCode: agreementKeyAdmin,
    },
    immediateRequest: false,
    dataSelector: "processDeathClaimEligibilityData",
    defaultReturn: [],
  });

  useEffect(() => {
    if (isAnAgent) {
      producerRequest.executeRequest();
    }
  }, [isAnAgent]);

  useEffect(() => {
    if (isPolicyDataLoaded) {
      deathClaimEligibilityDataRequest.executeRequest();
    }
  }, [isPolicyDataLoaded]);

  const deathClaimEligibilityData =
    deathClaimEligibilityDataRequest?.data || {};

  const eligibleCustomers =
    deathClaimEligibilityDataRequest?.data?.eligibleCustomers || [];

  const transactionSupportedPolicies = useLDValue({
    flagName: ["transactionSupportedPolicies"],
  });

  const { isTransactionAllowed, isEligible } = verifyInitiateDeathClaim({
    agreementData,
    deathClaimEligibilityData,
    transactionSupportedPolicies,
  });

  const { isMmsdOnly } = getVerifyPolicyAgreementInfo({
    agreementRequest,
    user,
  });

  const deathClaimFormFields = useInitiateDeathClaimFields({
    eligibleCustomers,
    producerRequest,
    user,
  });

  useAddOrRemoveChatLauncher({
    agreementData,
    page: "Initiate Death Claim transaction",
  });

  return {
    deathClaimFormFields,
    pageNumber,
    setPageNumber,
    nextPage,
    previousPage,
    onSubmit,
    shouldDisableSubmit,
    policyId,
    isTransactionAllowed,
    isMmsdOnly,
    agreementRequest,
    producerRequest,
    isPolicyDataLoaded,
    isEligible,
    showLoadingIcon,
    setBeneContactsCount,
    beneContactsCount,
    error,
    setError,
    shouldDisableContinue,
    setShouldDisableContinue,
    claimRecipientsContactsCount,
    setClaimRecipientsContactsCount,
    lineOfBusinessCode,
    email: user.email,
    transactionName: supportedTransactions.InitiateDeathClaim,
    showErrorPage,
    user,
    errorText,
    agreementKey,
    isPolicyNotFound,
    isDiOnlyClaim,
    setIsDiOnlyClaim,
    eligibleCustomers,
    deathClaimEligibilityDataRequest,
  };
};

export const useCanContinue = (props) => {
  const {
    nextPage,
    pageNumber,
    setError,
    lineOfBusinessCode,
    policyId,
    email,
    transactionName,
  } = props;
  const { formData } = useFormContext();
  const [isIntialPage1Render, setIsIntialPage1Render] = useState(true);
  const { openVerifyRecipientModal } = useVerifyFormsRecipientProps(props);

  if (pageNumber === 1) {
    checkErrorForPage1({
      formData,
      setError,
      isIntialPage1Render,
    });
  }
  const onContinue = () => {
    fireTealiumEvent({
      label: "SVNT",
      action: `${lineOfBusinessCode}: ${transactionName} PageNumber ${pageNumber} Continue button clicked`,
      category: `${transactionName}`,
      value: policyId,
      custId: email,
    });
    if (pageNumber === 1) {
      setIsIntialPage1Render(false);
      const { hasErrorForPage1 } = checkErrorForPage1({
        formData,
        setError,
        isIntialPage1Render,
      });
      if (!hasErrorForPage1) {
        nextPage();
      }
    } else if (pageNumber === 4) {
      const noRecipientSelected = checkErrorForPage4(formData);
      if (noRecipientSelected === false) {
        nextPage();
      } else {
        openVerifyRecipientModal(nextPage);
      }
    } else if (pageNumber < 6) {
      nextPage();
    }
  };
  return { onContinue };
};
export const checkErrorForPage1 = (props) => {
  const { formData, setError, isIntialPage1Render } = props;

  let hasSelectedDecedent = false;
  for (let i = 0; i < formData.totalCustomers; i += 1) {
    hasSelectedDecedent = formData[`customer${i}`].value;
    if (hasSelectedDecedent) break;
  }

  let validationError;
  if (!hasSelectedDecedent && !isIntialPage1Render) {
    validationError = "To continue, a decedent must be selected.";
  }

  setError(validationError);

  return {
    hasErrorForPage1: !hasSelectedDecedent,
  };
};

export const checkErrorForPage4 = (formData) => {
  const recipientSelectedCount = getClaimPacketRecipientCount(formData);

  return recipientSelectedCount === 0;
};
export const getClaimPacketRecipientCount = (formData) => {
  const { agentRecipient } = formData;

  let recipientSelectedCount = 0;
  if (agentRecipient.value) {
    recipientSelectedCount += 1;
  }
  for (
    let index = 0;
    index < INITIATE_DEATH_CLAIM_MAX_RECIPIENT_CONTACT_COUNT;
    index += 1
  ) {
    const claimRecipientFormField = {
      ...formData[`claimRecipientContact${index}`],
    };
    if (claimRecipientFormField.value) {
      recipientSelectedCount += 1;
    }
  }
  for (
    let index = 0;
    index < INITIATE_DEATH_CLAIM_MAX_BENE_CONTACT_COUNT;
    index += 1
  ) {
    const beneRecipientFormField = {
      ...formData[`beneRecipient${index}`],
    };
    if (beneRecipientFormField.value) {
      recipientSelectedCount += 1;
    }
  }
  return recipientSelectedCount;
};

export const useBeneficiaryInformationProps = () => {
  const { formData } = useFormContext();

  const selectedDecedent = getSelectedDecedentObjectfromFormData(formData);
  const agreementKeys = selectedDecedent.policies.map(
    (policy) => policy.agreementKey
  );

  const beneficiaryInformationTableColumns = [
    {
      accessor: "policyContractNumbers",
      label: "Policy/Contract Number(s)",
      alignment: "left",
    },
    {
      accessor: "lineOfBusiness",
      label: "Line of Business",
      alignment: "left",
    },
    {
      accessor: "beneficiaryArrangement",
      label: "Beneficiary Arrangement",
      alignment: "left",
    },
  ];

  const beneficiariesByAgreementKeysRequest = useMakeRequest({
    apiRequest: "fetchBeneficiariesByAgreementKeys",
    apiParams: {
      agreementKeys,
    },
    immediateRequest: true,
    dataSelector: "processBeneficiariesByAgreementKeys",
  });

  const { beneficiaryData } = beneficiariesByAgreementKeysRequest.data || {};

  const beneficiaryInformationTableRows = getBeneficiaryInformationTableRows(
    beneficiaryData,
    selectedDecedent.policies
  );

  return {
    beneficiariesByAgreementKeysRequest,
    beneficiaryInformationTableColumns,
    beneficiaryInformationTableRows,
  };
};

export const useBeneficiaryInformationQuestionAnswers = (props) => {
  const { formData } = useFormContext();
  const {
    beneContactQuestionYes,
    beneContactQuestionNo,
    beneTypeQuestionIndividual,
    beneTypeQuestionMultipleIndividuals,
    beneTypeQuestionTrust,
    beneTypeQuestionEstate,
    beneTypeQuestionCorporation,
    beneTypeQuestionMultipleArrangements,
    beneTypeQuestionNoBeneficiary,
    beneTypeQuestionUnknown,
  } = formData;

  const {
    setBeneContactsCount,
    beneContactsCount,
    setShouldDisableContinue,
    claimRecipientsContactsCount,
  } = props;

  const displayAddBeneContactFields = beneContactQuestionYes.value;
  const displaybeneContactQuestion = Object.values(formData).find(
    (f) => f.groupName === "beneTypeQuestion" && f.value === true
  );

  const beneContacts = [];
  for (let index = 0; index < beneContactsCount; index += 1) {
    const fieldname = `beneContact${index}`;
    beneContacts.push({
      ...formData[fieldname],
    });
  }

  const { openContactInformationModal } =
    useBeneClaimRecipientContactModalProps({
      formField: formData[`beneContact${beneContactsCount}`],
      setBeneContactsCount,
      beneContactsCount,
      action: contactInformationUpdateAction.ADD,
      cardType: "beneCard",
    });
  const selectedBeneContact = beneContacts.filter(
    (contact) =>
      !isEmptyObject(contact.contactDetails) && contact.value === true
  );

  const { recipients } = useFormRecipientsProps({
    formData,
    claimRecipientsContactsCount,
  });

  const beneficiaryMaxCountReached =
    beneContactsCount >= INITIATE_DEATH_CLAIM_MAX_BENE_CONTACT_COUNT ||
    recipients.length >= INITIATE_DEATH_CLAIM_MAX_RECIPIENT_CONTACT_COUNT;

  if (
    (displayAddBeneContactFields === true && selectedBeneContact.length > 0) ||
    beneContactQuestionNo.value === true
  ) {
    setShouldDisableContinue(false);
  } else {
    setShouldDisableContinue(true);
  }

  return {
    beneContactQuestionYes,
    beneContactQuestionNo,
    beneTypeQuestionIndividual,
    beneTypeQuestionMultipleIndividuals,
    beneTypeQuestionTrust,
    beneTypeQuestionEstate,
    beneTypeQuestionCorporation,
    beneTypeQuestionMultipleArrangements,
    beneTypeQuestionNoBeneficiary,
    beneTypeQuestionUnknown,
    beneContacts,
    displayAddBeneContactFields,
    displaybeneContactQuestion,
    beneficiaryMaxCountReached,
    addBeneContact: () => {
      if (beneContactsCount < INITIATE_DEATH_CLAIM_MAX_BENE_CONTACT_COUNT) {
        openContactInformationModal();
      }
    },
  };
};

export const useFormRecipientsProps = (props) => {
  const { claimRecipientsContactsCount } = props;
  const { formData } = useFormContext();
  const {
    beneContact0,
    beneContact1,
    beneContact2,
    beneContact3,
    beneContact4,
    beneRecipient0,
    beneRecipient1,
    beneRecipient2,
    beneRecipient3,
    beneRecipient4,
    agentContact,
    agentRecipient,
    claimPacketAndFormsAnswer1No,
  } = formData;

  const beneAddresses = [
    beneContact0,
    beneContact1,
    beneContact2,
    beneContact3,
    beneContact4,
  ];

  const beneRecipientSelections = [
    beneRecipient0,
    beneRecipient1,
    beneRecipient2,
    beneRecipient3,
    beneRecipient4,
  ];

  const claimRecipientContacts = [];
  for (let index = 0; index < claimRecipientsContactsCount; index += 1) {
    const claimRecipientFormField = {
      ...formData[`claimRecipientContact${index}`],
    };
    claimRecipientContacts.push({
      claimRecipientFormField,
    });
  }

  const combinedRecipientAndAddress = [];
  beneAddresses.forEach((beneFormField, index) => {
    const pairedFields = {
      beneFormField,
      recipientFormField: beneRecipientSelections[index],
    };
    combinedRecipientAndAddress.push(pairedFields);
  });

  const recipientContactsChecked = combinedRecipientAndAddress
    .filter((contact) => contact.beneFormField.value)
    .filter((contact) => !isEmptyObject(contact.beneFormField.contactDetails));

  const agentEntry = {
    recipientFormField: agentRecipient,
    beneFormField: agentContact,
  };

  const recipients = claimPacketAndFormsAnswer1No.value
    ? [...recipientContactsChecked, ...claimRecipientContacts]
    : [agentEntry, ...recipientContactsChecked, ...claimRecipientContacts];
  return { recipients };
};

export const useBeneClaimRecipientContactModalProps = (props) => {
  const {
    formField,
    setBeneContactsCount,
    beneContactsCount,
    action,
    cardType,
    setClaimRecipientsContactsCount,
    claimRecipientsContactsCount,
    checkboxFormField,
  } = props;
  const { contactDetails } = formField || {};

  const { openModal, closeModal } = useModalState();
  const { formData, dispatchForm } = useFormContext();

  const closeBeneContactModal = () => {
    resetContactFormFields({ formData, dispatchForm });
    closeModal();
  };

  const saveContactDetails = (updatedContactDetails) => {
    dispatchForm({
      type: types.REPLACE_FIELD,
      formField: {
        ...formField,
        contactDetails: updatedContactDetails,
        value: true,
      },
    });
    if (action === contactInformationUpdateAction.ADD) {
      if (compareStrings(cardType, "claimRecipientCard")) {
        setClaimRecipientsContactsCount(claimRecipientsContactsCount + 1);
      } else {
        setBeneContactsCount(beneContactsCount + 1);
      }
    }
    if (action === contactInformationUpdateAction.EDIT) {
      if (compareStrings(cardType, "beneCard")) {
        dispatchForm({
          type: types.REPLACE_FIELD,
          formField: {
            ...checkboxFormField,
            value: true,
          },
        });
      }
    }
  };

  const modalTitle =
    compareStrings(cardType, "claimRecipientCard") ||
    compareStrings(formField?.id, "agentContact")
      ? "Recipient Information"
      : "Beneficiary Contact Information";

  const contactInformationModalProps = {
    title: modalTitle,
    Component: ContactInformationModal,
    componentProps: {
      closeModal: closeBeneContactModal,
      contactDetails,
      saveContactDetails,
    },
    modalSize: "lg",
    closeModal: closeBeneContactModal,
  };

  const openContactInformationModal = () =>
    openModal(contactInformationModalProps);

  return {
    openContactInformationModal,
  };
};

export const getPeopleReceivingForms = (recipients) => {
  const receivingFormsNamesBeneList = recipients
    .filter(
      (r) =>
        r?.beneFormField?.contactDetails?.name &&
        r?.recipientFormField?.value === true
    )
    .map((r) => r?.beneFormField?.contactDetails?.name);

  const claimsRecipientNamesList = recipients
    .filter(
      (r) =>
        r?.claimRecipientFormField?.contactDetails?.name &&
        r?.claimRecipientFormField?.value === true
    )
    .map((r) => r?.claimRecipientFormField?.contactDetails?.name);

  return [...receivingFormsNamesBeneList, ...claimsRecipientNamesList];
};

export const getSelectedAnswer = (formData, questionName) => {
  const selectedAnswer = Object.values(formData).find(
    (decedentInfo) =>
      decedentInfo.groupName === questionName && decedentInfo.value === true
  );
  if (selectedAnswer) {
    return selectedAnswer.label;
  }
  return null;
};
/** ************* Question Numbers: *********************************
 1. Did the decedent's death occur in the U.S.?
 2. Is their exact date of death known?
 3. What was their cause of death?
 4. What was the decedent’s marital status at the time of passing?
 5. Was the decedent ever divorced?
 6. Was the policy on an active Disability claim?
 ****************************************************************** */
export const useReviewDecedentInfoProps = () => {
  const { formData } = useFormContext();

  const selectedDecedent = getSelectedDecedentObjectfromFormData(formData);
  const {
    decedentInfoQuestion1No,
    decedentInfoQuestion1Country,
    decedentInfoQuestion2Yes,
    decedentInfoQuestion2Date,
    decedentInfoQuestion4Married,
    decedentInfoQuestion4WidowOrWidower,
    decedentInfoQuestion4SpouseFirstName,
    decedentInfoQuestion4SpouseLastName,
    decedentInfoQuestion4FormerSpouseFirstName,
    decedentInfoQuestion4FormerSpouseLastName,
    decedentInfoQuestion4FormerSpouseDateOfDivorce,
    decedentInfoQuestion4DivorceOccuredState,
    decedentInfoQuestion5Yes,
    decedentInfoQuestion5FormerSpouseFirstName,
    decedentInfoQuestion5FormerSpouseLastName,
    decedentInfoQuestion5FormerSpouseDateOfDivorce,
    decedentInfoQuestion5DivorceOccuredState,
    decedentInfoQuestion6DisabilityClaimNumberYes,
    decedentInfoQuestion6DisabilityClaimNumber,
  } = formData;
  let didDeathOccurInUS = getSelectedAnswer(
    formData,
    "decedentInfoQuestion1Radio"
  );
  if (decedentInfoQuestion1No.value) {
    const countryOfDeath =
      decedentInfoQuestion1Country.value !== ""
        ? decedentInfoQuestion1Country.display
        : "Country Unknown";
    didDeathOccurInUS = `No, ${countryOfDeath}`;
  }
  let dateOfDeath = "Unknown";
  if (
    decedentInfoQuestion2Yes.value &&
    decedentInfoQuestion2Date.value !== ""
  ) {
    dateOfDeath = formatDate(decedentInfoQuestion2Date.value.toString());
  }
  const causeOfDeath = getSelectedAnswer(
    formData,
    "decedentInfoQuestion3Radio"
  );
  let maritalStatus = getSelectedAnswer(formData, "decedentInfoQuestion4Radio");
  if (compareArrayOfStrings(["Married", "Widow/Widower"], maritalStatus)) {
    if (
      decedentInfoQuestion4SpouseFirstName.value ||
      decedentInfoQuestion4SpouseLastName.value
    ) {
      maritalStatus +=
        `, ${decedentInfoQuestion4SpouseFirstName.value} ${decedentInfoQuestion4SpouseLastName.value}`.trim();
    }
  } else if (compareStrings(maritalStatus, "Divorced")) {
    if (
      decedentInfoQuestion4FormerSpouseFirstName.value ||
      decedentInfoQuestion4FormerSpouseLastName.value
    ) {
      maritalStatus +=
        `, ${decedentInfoQuestion4FormerSpouseFirstName.value} ${decedentInfoQuestion4FormerSpouseLastName.value}`.trim();
    }
    if (decedentInfoQuestion4FormerSpouseDateOfDivorce.value) {
      maritalStatus += `, ${formatDate(
        decedentInfoQuestion4FormerSpouseDateOfDivorce.value.toString()
      )}`;
    }
    if (decedentInfoQuestion4DivorceOccuredState.value) {
      maritalStatus += `, ${decedentInfoQuestion4DivorceOccuredState.display}`;
    }
  }
  let previousDivorceStatus = getSelectedAnswer(
    formData,
    "decedentInfoQuestion5Radio"
  );
  if (decedentInfoQuestion5Yes.value) {
    if (
      decedentInfoQuestion5FormerSpouseFirstName.value ||
      decedentInfoQuestion5FormerSpouseLastName.value
    ) {
      previousDivorceStatus +=
        `, ${decedentInfoQuestion5FormerSpouseFirstName.value} ${decedentInfoQuestion5FormerSpouseLastName.value}`.trim();
    }
    if (decedentInfoQuestion5FormerSpouseDateOfDivorce.value) {
      previousDivorceStatus += `, ${formatDate(
        decedentInfoQuestion5FormerSpouseDateOfDivorce.value.toString()
      )}`;
    }
    if (decedentInfoQuestion5DivorceOccuredState.value) {
      previousDivorceStatus += `, ${decedentInfoQuestion5DivorceOccuredState.display}`;
    }
  }
  const policiesLobArray = [
    ...new Set(
      selectedDecedent.policies.map((policy) => policy.lineOfBusinessCode)
    ),
  ];
  const displayCauseOfDeathOnReview = compareArrayOfStrings(
    policiesLobArray,
    "LIFE"
  );
  const displayPreviousDivorceQuestionOnReview =
    decedentInfoQuestion4Married.value ||
    decedentInfoQuestion4WidowOrWidower.value;
  const displayDisabilityQuestionOnReview = selectedDecedent.policies.some(
    (policy) =>
      compareStrings(policy.lineOfBusinessCode, "DI") &&
      compareArrayOfStrings(policy.arrayRoleType, "INSD")
  );
  const activeDisabilityClaim = getSelectedAnswer(
    formData,
    "decedentInfoQuestion6Radio"
  );
  let disabilityClaimNumber = "";
  if (
    decedentInfoQuestion6DisabilityClaimNumberYes.value &&
    decedentInfoQuestion6DisabilityClaimNumber.value
  ) {
    disabilityClaimNumber = decedentInfoQuestion6DisabilityClaimNumber.value;
  }
  return {
    decedentName: selectedDecedent.label,
    didDeathOccurInUS,
    dateOfDeath,
    displayCauseOfDeathOnReview,
    causeOfDeath,
    maritalStatus,
    previousDivorceStatus,
    displayPreviousDivorceQuestionOnReview,
    displayDisabilityQuestionOnReview,
    activeDisabilityClaim,
    disabilityClaimNumber,
  };
};
export const useReviewFinancialProfessionalProps = () => {
  const { formData } = useFormContext();
  const { agentContact, additionalInfo } = formData;
  const financialProfessionalName = agentContact?.contactDetails?.name || "";
  const financialProfessionalEmail = agentContact?.contactDetails?.email || "";

  return {
    financialProfessionalName,
    financialProfessionalEmail,
    additionalInfo,
  };
};
export const useReviewBeneficiaryInfoProps = () => {
  const { formData } = useFormContext();
  const {
    beneContactQuestionNo,
    beneContact0,
    beneContact1,
    beneContact2,
    beneContact3,
    beneContact4,
  } = formData;

  const beneTypeSelectedAnswer = getSelectedAnswer(
    formData,
    "beneTypeQuestion"
  );

  const beneContactArray = [
    beneContact0,
    beneContact1,
    beneContact2,
    beneContact3,
    beneContact4,
  ];

  const beneContactNamePhoneAndEmail = [];
  let beneContactSelectedAnswer;
  if (beneContactQuestionNo.value) {
    beneContactSelectedAnswer = beneContactQuestionNo.label;
  } else {
    beneContactArray.map((contact) =>
      !isEmptyObject(contact.contactDetails) && contact.value === true
        ? beneContactNamePhoneAndEmail.push([
            contact.contactDetails.name,
            contact.contactDetails.phone || "",
            contact.contactDetails.email || "",
          ])
        : null
    );
    beneContactSelectedAnswer = beneContactNamePhoneAndEmail;
  }

  return {
    beneTypeSelectedAnswer,
    beneContactSelectedAnswer,
  };
};

export const useReviewRecipientOfFormInfoProps = (props) => {
  const { claimRecipientsContactsCount } = props;
  const { formData } = useFormContext();
  const { recipients } = useFormRecipientsProps({
    formData,
    claimRecipientsContactsCount,
  });

  const policiesLobArray = getSelectedDecedentPoliciesLobs({ formData });

  const isNotAnnuityOnlyContract = policiesLobArray.some(
    (policyItem) => !compareStrings(policyItem, "ANN")
  );

  const claimPacketQuestion1SelectedAnswer = getSelectedAnswer(
    formData,
    "claimPacketAndFormsAnswer1Radio"
  );

  const claimPacketQuestion3SelectedAnswer = getSelectedAnswer(
    formData,
    "claimPacketAndFormsAnswer3Radio"
  );
  const displayClaimPacketQuestion4 =
    compareStrings(
      claimPacketQuestion3SelectedAnswer,
      "No, I would like the Claim Examiner to send the forms to the beneficiary"
    ) || claimPacketQuestion1SelectedAnswer === "No";

  const claimPacketQuestion4SelectedAnswer = getSelectedAnswer(
    formData,
    "claimPacketAndFormsAnswer4Radio"
  );
  const displayClaimPacketQuestion5 =
    compareStrings(claimPacketQuestion4SelectedAnswer, "Email") &&
    isNotAnnuityOnlyContract;

  const claimPacketQuestion5SelectedAnswer = getSelectedAnswer(
    formData,
    "claimPacketAndFormsAnswer5Radio"
  );

  const claimPacketQuestion6SelectedAnswer = getSelectedAnswer(
    formData,
    "claimPacketAndFormsAnswer6Radio"
  );
  const receivingFormsNamesList = getPeopleReceivingForms(recipients);

  return {
    claimPacketQuestion1SelectedAnswer,
    claimPacketQuestion3SelectedAnswer,
    displayClaimPacketQuestion4,
    claimPacketQuestion4SelectedAnswer,
    displayClaimPacketQuestion5,
    claimPacketQuestion5SelectedAnswer,
    claimPacketQuestion6SelectedAnswer,
    receivingFormsNamesList,
  };
};

export const useReviewProps = (props) => {
  const {
    decedentName,
    didDeathOccurInUS,
    dateOfDeath,
    displayCauseOfDeathOnReview,
    causeOfDeath,
    maritalStatus,
    previousDivorceStatus,
    displayPreviousDivorceQuestionOnReview,
    displayDisabilityQuestionOnReview,
    activeDisabilityClaim,
    disabilityClaimNumber,
  } = useReviewDecedentInfoProps();
  const {
    financialProfessionalName,
    financialProfessionalEmail,
    additionalInfo,
  } = useReviewFinancialProfessionalProps();
  const { beneTypeSelectedAnswer, beneContactSelectedAnswer } =
    useReviewBeneficiaryInfoProps();
  const {
    claimPacketQuestion1SelectedAnswer,
    claimPacketQuestion3SelectedAnswer,
    displayClaimPacketQuestion4,
    claimPacketQuestion4SelectedAnswer,
    displayClaimPacketQuestion5,
    claimPacketQuestion5SelectedAnswer,
    claimPacketQuestion6SelectedAnswer,
    receivingFormsNamesList,
  } = useReviewRecipientOfFormInfoProps(props);
  return {
    decedentName,
    didDeathOccurInUS,
    dateOfDeath,
    displayCauseOfDeathOnReview,
    causeOfDeath,
    maritalStatus,
    previousDivorceStatus,
    displayPreviousDivorceQuestionOnReview,
    displayDisabilityQuestionOnReview,
    activeDisabilityClaim,
    disabilityClaimNumber,
    beneTypeSelectedAnswer,
    beneContactSelectedAnswer,
    claimPacketQuestion1SelectedAnswer,
    claimPacketQuestion3SelectedAnswer,
    displayClaimPacketQuestion4,
    claimPacketQuestion4SelectedAnswer,
    displayClaimPacketQuestion5,
    claimPacketQuestion5SelectedAnswer,
    claimPacketQuestion6SelectedAnswer,
    receivingFormsNamesList,
    financialProfessionalName,
    financialProfessionalEmail,
    additionalInfo,
  };
};

export const useConfirmationProps = (props) => {
  const { formData } = useFormContext();
  const {
    claimRecipientsContactsCount,
    lineOfBusinessCode,
    transactionName,
    policyId,
    userEmail,
  } = props;
  const { recipients } = useFormRecipientsProps({
    formData,
    claimRecipientsContactsCount,
  });
  const { agreementKey } = useParams();
  const selectedDecedent = getSelectedDecedentObjectfromFormData(formData);

  const { agentContact, additionalInfo } = formData;
  const policyNumbers = selectedDecedent.policies.map(
    (policy) => policy.agreementKeyPrimaryDisplay
  );
  const policyNumbersList = policyNumbers ? policyNumbers.join(", ") : "";
  const financialProfessionalEmail = agentContact?.contactDetails?.email || "";
  const sanitizedAdditionalComments = sanitizeData(additionalInfo?.value);
  const requestDateTime = formatDate(getCurrentDate(), "dateWithTime");

  const receivingFormsNamesList = getPeopleReceivingForms(recipients);
  const policiesLobArray = [
    ...new Set(
      selectedDecedent.policies.map((policy) => policy.lineOfBusinessCode)
    ),
  ];
  let hasEligibleLifePolicy = false;
  const indexOfLifeNotPallm = selectedDecedent.policies.findIndex(
    (policy) =>
      compareStrings(policy.lineOfBusinessCode, "LIFE") &&
      !compareStrings(policy.agreementKeyAdmin, "PALLM")
  );
  if (indexOfLifeNotPallm > -1) {
    hasEligibleLifePolicy = true;
  }
  const hasAnnuityPolicy = compareArrayOfStrings(policiesLobArray, "ANN");
  const { user } = useUserState();
  const onPreFillFormButtonClick = (lifeOrAnnuity) => {
    const reqBody = makePrefilledFormRequestBody({
      formData,
      selectedDecedent,
      lifeOrAnnuity,
    });

    const headers = {
      Authorization: `Bearer ${user.accessToken}`,
    };
    const downloadFilename = compareStrings(lifeOrAnnuity, "Life")
      ? "Life Insurance Claim Form - FR1018G.pdf"
      : "Annuity Claim Packet.pdf";
    fetchFillablePdfForm({ headers, apiParams: reqBody }).then((result) => {
      if (result?.data?.length && !result?.error) {
        fireTealiumEvent({
          label: "SVNT",
          action: `${lineOfBusinessCode}: ${transactionName} ${lifeOrAnnuity} Fillable Form Downloaded`,
          category: `${transactionName}`,
          value: policyNumbersList,
          custId: user?.email,
        });
        downloadPDF(
          "",
          "",
          convertBase64StringToByteArray(result?.data),
          downloadFilename
        );
      }
    });
  };
  return {
    decedentName: selectedDecedent.label,
    policyNumbersList,
    financialProfessionalEmail,
    receivingFormsNamesList,
    requestDateTime,
    agreementKey,
    additionalComments: sanitizedAdditionalComments,
    hasEligibleLifePolicy,
    hasAnnuityPolicy,
    onPreFillFormButtonClick,
    lineOfBusinessCode,
    transactionName,
    userEmail,
    policyId,
  };
};

export const makePrefilledFormRequestBody = (props) => {
  const { formData, selectedDecedent, lifeOrAnnuity } = props;
  const isLifePolicy = compareStrings(lifeOrAnnuity, "Life");
  const fillablePdfFormName = isLifePolicy ? "fr1018g.pdf" : "fr1024.pdf";

  const causeOfDeath = getSelectedAnswer(
    formData,
    "decedentInfoQuestion3Radio"
  );

  const mannerOfDeath = compareStrings(causeOfDeath, "Unknown")
    ? ""
    : causeOfDeath;
  const pdfFillableFormDataArray = [
    {
      field: isLifePolicy ? "220" : "FR1024_Deceased_fullLegalName",
      value: selectedDecedent?.label,
    },
    {
      field: isLifePolicy
        ? "FR1018G_Deceased_MaritalStatus"
        : "FR1024_MaritalStatus_atdeath",
      value: getDecedentMaritalDetails(formData)?.maritalStatus,
    },
    {
      field: isLifePolicy
        ? "FR1018G_Deceased_SpouseName"
        : "FR1024_Deceased_SpouseName",
      value: getDecedentMaritalDetails(formData)?.spouseName,
    },
    {
      field: isLifePolicy
        ? "FR1018G_BeneficiaryType"
        : "FR1024_BeneficiaryType",
      value: getBeneficiaryDetails(formData)?.beneficiaryType,
    },
    // To do Update this when address story is implemented
    // {
    //   field: "FR1018G_Deceased_ResidentialAddress1",
    //   value: "1295 State Street",
    // },
    // {
    //   field: "FR1018G_Deceased_ResidentialCity",
    //   value: "Springfield",
    // },
    // {
    //   field: "FR1018G_Deceased_ResidentialZip",
    //   value: "01111",
    // },
    // {
    //   field: "FR1018G_Deceased_State",
    //   value: "Massachusetts",
    // },
  ];
  if (isLifePolicy) {
    pdfFillableFormDataArray.push({
      field: "FR1018G_MOD",
      value: mannerOfDeath,
    });
    pdfFillableFormDataArray.push({
      field: "FR1018G_Deceased_DivorceDate",
      value: getDecedentMaritalDetails(formData)?.divorceDate,
    });
    pdfFillableFormDataArray.push({
      field: "FR1018G_Deceased_DivorceState",
      value: getDecedentMaritalDetails(formData)?.divorceOccuredState,
    });
    pdfFillableFormDataArray.push({
      field: "FR1018G_Deceased_DivorceState",
      value: getDecedentMaritalDetails(formData)?.divorceOccuredState,
    });
  }
  return {
    formsource: {
      parameters: [
        {
          field: "flatten",
          value: "true",
        },
      ],
      type: "S3",
    },
    forms: [fillablePdfFormName],
    data: pdfFillableFormDataArray,
  };
};

export const getDecedentMaritalDetails = (formData) => {
  const deceasedMaritalStatus = getSelectedAnswer(
    formData,
    "decedentInfoQuestion4Radio"
  );
  const {
    decedentInfoQuestion4SpouseFirstName,
    decedentInfoQuestion4SpouseLastName,
    decedentInfoQuestion4FormerSpouseFirstName,
    decedentInfoQuestion4FormerSpouseLastName,
    decedentInfoQuestion5FormerSpouseDateOfDivorce,
    decedentInfoQuestion5DivorceOccuredState,
  } = formData;
  let maritalStatus = "";
  let spouseName = "";
  let divorceDate = "";
  let divorceOccuredState = "";
  let wasDecedentDivorced = "";
  switch (deceasedMaritalStatus) {
    case "Never Married":
      maritalStatus = "Single";
      break;
    case "Widow/Widower":
      maritalStatus = "Widowed";
      break;
    case "Unknown":
      maritalStatus = "";
      break;
    default:
      maritalStatus = deceasedMaritalStatus;
      break;
  }

  if (
    compareArrayOfStrings(["Married", "Widow/Widower"], deceasedMaritalStatus)
  ) {
    if (
      decedentInfoQuestion4SpouseFirstName.value ||
      decedentInfoQuestion4SpouseLastName.value
    ) {
      spouseName =
        `${decedentInfoQuestion4SpouseFirstName.value} ${decedentInfoQuestion4SpouseLastName.value}`.trim();
    }
    wasDecedentDivorced = compareStrings(
      getSelectedAnswer(formData, "decedentInfoQuestion5Radio"),
      "Unknown"
    )
      ? ""
      : getSelectedAnswer(formData, "decedentInfoQuestion5Radio");

    if (compareStrings(wasDecedentDivorced, "Yes")) {
      if (decedentInfoQuestion5FormerSpouseDateOfDivorce.value) {
        divorceDate = formatDate(
          decedentInfoQuestion5FormerSpouseDateOfDivorce.value.toString()
        );
      }
      if (decedentInfoQuestion5DivorceOccuredState.value) {
        divorceOccuredState = decedentInfoQuestion5DivorceOccuredState.display;
      }
    } else {
      divorceDate = "";
      divorceOccuredState = "";
    }
  } else if (compareStrings(maritalStatus, "Divorced")) {
    if (
      decedentInfoQuestion4FormerSpouseFirstName.value ||
      decedentInfoQuestion4FormerSpouseLastName.value
    ) {
      spouseName +=
        `${decedentInfoQuestion4FormerSpouseFirstName.value} ${decedentInfoQuestion4FormerSpouseLastName.value}`.trim();
    }
  }

  return {
    maritalStatus,
    spouseName,
    divorceDate,
    divorceOccuredState,
    wasDecedentDivorced,
  };
};

export const getBeneficiaryDetails = (formData) => {
  const beneTypeSelectedAnswer = getSelectedAnswer(
    formData,
    "beneTypeQuestion"
  );
  let beneficiaryType = "";
  switch (beneTypeSelectedAnswer) {
    case "Individual beneficiary (a person)":
    case "Multiple individual beneficiaries (people/lawful children designations)":
      beneficiaryType = "Individual";
      break;
    case "Corporation/Partnership":
      beneficiaryType = "Corporation";
      break;
    case "Trust":
    case "Estate":
      beneficiaryType = beneTypeSelectedAnswer;
      break;
    default:
      beneficiaryType = "";
      break;
  }
  return { beneficiaryType };
};

export const useSelectDecedentHook = (props) => {
  const { formData } = useFormContext();
  const { formField, customProps } = props;
  const { setShouldDisableContinue, setIsDiOnlyClaim, eligibleCustomers } =
    customProps;
  const { dispatchForm } = useFormContext();
  const { agreementKey } = useParams();

  useEffect(() => {
    if (formField.value === true) {
      const { policyList } = getPoliciesandRolesforCustomerMemberGuid({
        eligibleCustomers,
        formField,
        agreementKey,
      });
      dispatchForm({
        type: types.REPLACE_FIELD,
        formField: {
          ...formField,
          policies: policyList,
        },
      });
    }
  }, [eligibleCustomers, formField.value]);

  const selectedDecedent = getSelectedDecedentObjectfromFormData(formData);
  const policies = selectedDecedent?.policies;

  const hasDiPoliciesOnly =
    isDefined(policies) &&
    policies.length > 0 &&
    !policies?.some((policy) =>
      compareArrayOfStrings(["LIFE", "ANN"], policy.lineOfBusinessCode)
    );

  if (hasDiPoliciesOnly) {
    setIsDiOnlyClaim(true);
  }

  useEffect(() => {
    setShouldDisableContinue(true);
    if (isDefined(policies) && !isEmptyArray(policies)) {
      setShouldDisableContinue(false);
    }
  }, [selectedDecedent]);

  const onChange = () => {
    dispatchForm({
      type: types.RADIO_SELECTED,
      formField,
    });
  };
  return {
    onChange,
  };
};

export const getPoliciesandRolesforCustomerMemberGuid = (props) => {
  const { eligibleCustomers, formField } = props;

  const customer =
    eligibleCustomers?.find((eligibleCustomer) =>
      compareStrings(eligibleCustomer.memberGUID, formField.memberGUID)
    ) || {};

  const policyList =
    customer?.policies?.length > 0
      ? customer?.policies?.map((customerDataItem) => {
          const { arrayRoleTypeDisplay } =
            getOWNRAndINSDforCustomerData(customerDataItem);
          const { primaryId, prefix, suffix, adminSystemCode } =
            customerDataItem;
          const agreementKey = generateAgreementKeyfromAgreementData({
            primaryId,
            prefix,
            suffix,
            adminSystemCode,
          });

          return {
            arrayRoleType: customerDataItem.roleTypes,
            roleTypeDisplay: arrayRoleTypeDisplay,
            agreementKeyPrefix: customerDataItem.prefix,
            agreementKeySuffix: customerDataItem.suffix,
            agreementKey,
            agreementKeyPrimary: customerDataItem.primaryId,
            agreementKeyAdmin: customerDataItem.adminSystemCode,
            agreementKeyPrimaryDisplay:
              customerDataItem.agreementKeyPrimaryDisplay,
            memberGUID: customer.memberGUID,
            decedentFirstName: customer.firstName,
            decedentLastName: customer.lastName,
            decedentMiddleName: customer.middleName,
            ...customerDataItem,
          };
        })
      : [];

  const filteredAgreementByCustomerDataPolicyList =
    policyList.length > 0
      ? policyList?.filter(
          (agreementCustomerDataItem) =>
            !compareStrings(agreementCustomerDataItem.agreementKeyAdmin, "UNIV")
        )
      : [];

  return { policyList: filteredAgreementByCustomerDataPolicyList };
};

export const useInitiateDeathClaimFields = (props) => {
  const { eligibleCustomers, producerRequest, user } = props;

  const customersToDisplay = eligibleCustomers.reduce(
    (users, customer, index) => {
      const fieldName = `customer${index}`;
      const formattedName = formatName({
        nameObject: customer,
        format: nameFormattingOptions.firstNameDisplayedFirst,
      });
      return {
        ...users,
        [fieldName]: {
          id: fieldName,
          label: formattedName,
          name: fieldName,
          groupName: "decedent",
          value: false,
          memberGUID: customer.memberGUID,
          agreementsRequest: {},
          policies: [],
          hasExecutedAtLeastOnce: false,
        },
      };
    },
    {}
  );

  const agentContactDetails = getAgentContactDetails({
    producerData: producerRequest.data,
    user,
  });

  const beneContacts = {};
  const beneRecipients = {};
  const claimRecipientContacts = {};

  for (
    let index = 0;
    index < INITIATE_DEATH_CLAIM_MAX_BENE_CONTACT_COUNT;
    index += 1
  ) {
    const fieldName = `beneContact${index}`;
    beneContacts[fieldName] = {
      id: fieldName,
      name: fieldName,
      labelBold: true,
      label: "",
      value: true,
      required: false,
      contactDetails: {},
    };

    const recipientField = `beneRecipient${index}`;
    beneRecipients[recipientField] = {
      id: recipientField,
      name: recipientField,
      labelBold: true,
      label: "",
      value: false,
      required: false,
      stepName: "claimsPacketAndForms",
      stepQuestionNumber: 2,
    };
  }

  // To Do : INITIATE_DEATH_CLAIM_MAX_RECIPIENT_CONTACT_COUNT value has to be dynamically calculated. Details are mentioned in SVNT-5563
  for (
    let index = 0;
    index < INITIATE_DEATH_CLAIM_MAX_RECIPIENT_CONTACT_COUNT;
    index += 1
  ) {
    const recipientField = `claimRecipientContact${index}`;
    claimRecipientContacts[recipientField] = {
      id: recipientField,
      name: recipientField,
      labelBold: true,
      label: "",
      value: false,
      required: false,
      contactDetails: {},
      stepName: "claimsPacketAndForms",
      stepQuestionNumber: 2,
    };
  }

  const useContactInformationModalFields = useContactInformationFields();
  const decedentInformationFields = useDecedentInformationFields();
  const claimPacketRecipientFields = useClaimPacketAndFormsFields();
  const additionalInfoField = useAdditionalInfoField();
  return {
    ...customersToDisplay,
    totalCustomers: eligibleCustomers.length,
    beneContactQuestionYes: {
      id: "beneContactQuestionYes",
      label: "Yes",
      name: "beneContactQuestionYes",
      groupName: "beneContactQuestion",
      value: false,
    },
    beneContactQuestionNo: {
      id: "beneContactQuestionNo",
      label: "No, please use the information on file.",
      name: "beneContactQuestionNo",
      groupName: "beneContactQuestion",
      value: false,
    },
    beneTypeQuestionIndividual: {
      id: "beneTypeQuestionIndividual",
      label: "Individual beneficiary (a person)",
      name: "beneTypeQuestionIndividual",
      groupName: "beneTypeQuestion",
      value: false,
    },
    beneTypeQuestionMultipleIndividuals: {
      id: "beneTypeQuestionMultipleIndividuals",
      label:
        "Multiple individual beneficiaries (people/lawful children designations)",
      name: "beneTypeQuestionMultipleIndividuals",
      groupName: "beneTypeQuestion",
      value: false,
    },
    beneTypeQuestionTrust: {
      id: "beneTypeQuestionTrust",
      label: "Trust",
      name: "beneTypeQuestionTrust",
      groupName: "beneTypeQuestion",
      value: false,
    },
    beneTypeQuestionEstate: {
      id: "beneTypeQuestionEstate",
      label: "Estate",
      name: "beneTypeQuestionEstate",
      groupName: "beneTypeQuestion",
      value: false,
    },
    beneTypeQuestionCorporation: {
      id: "beneTypeQuestionCorporation",
      label: "Corporation/Partnership",
      name: "beneTypeQuestionCorporation",
      groupName: "beneTypeQuestion",
      value: false,
    },
    beneTypeQuestionMultipleArrangements: {
      id: "beneTypeQuestionMultipleArrangements",
      label: "These policies have multiple beneficiary arrangements",
      name: "beneTypeQuestionMultipleArrangements",
      groupName: "beneTypeQuestion",
      value: false,
    },
    beneTypeQuestionNoBeneficiary: {
      id: "beneTypeQuestionNoBeneficiary",
      label: "No beneficiary arrangement listed",
      name: "beneTypeQuestionNoBeneficiary",
      groupName: "beneTypeQuestion",
      value: false,
    },
    beneTypeQuestionUnknown: {
      id: "beneTypeQuestionUnknown",
      label: "Unknown",
      name: "beneTypeQuestionUnknown",
      groupName: "beneTypeQuestion",
      value: false,
    },
    ...beneContacts,
    ...beneRecipients,
    ...claimRecipientContacts,
    ...useContactInformationModalFields,
    ...decedentInformationFields,
    ...claimPacketRecipientFields,
    ...additionalInfoField,
    agentContact: {
      id: "agentContact",
      name: "agentContact",
      labelBold: true,
      label: "",
      value: true,
      required: false,
      contactDetails: agentContactDetails,
    },
    agentRecipient: {
      id: "agentRecipient",
      name: "agentRecipient",
      labelBold: true,
      label: "",
      value: false,
      required: false,
      stepName: "claimsPacketAndForms",
      stepQuestionNumber: 2,
    },
  };
};
export const useAdditionalInfoField = () => ({
  additionalInfo: {
    id: "additionalInfo",
    label: "Additional Information",
    ariaLabel: "Additional Information",
    hideLabel: true,
    labelBold: false,
    required: false,
    placeholder: "",
    name: "additionalInfo",
    className: "md-textarea form-control",
    type: "text",
    rows: 5,
    cols: 300,
    useValidation: useGeneralValidation,
    min: 0,
    max: 1000,
    value: "",
    errors: [],
  },
});
export const useDecedentInformationProps = (props) => {
  const { setShouldDisableContinue } = props;
  setShouldDisableContinue(false);
  const decedentInfoQuestionsText = [
    {
      question: "Did the decedent's death occur in the U.S.?",
      subQuestion: "In what country did their death occur?",
    },
    {
      question: "Is their exact date of death known?",
    },
    {
      question: "What was their cause of death?",
    },
    {
      question:
        "What was the decedent’s marital status at the time of passing?",
    },
    {
      question: "To your knowledge, was the decedent ever divorced?",
    },
    {
      question:
        "Our records indicate the decedent is the insured on a Disability Income policy. To your knowledge, was the policy on an active Disability claim?",
      subQuestion: "Do you know the Disability Claim number?",
    },
  ];

  return {
    decedentInfoQuestionsText,
  };
};

export const useDecedentInformationFields = () => ({
  decedentInfoQuestion1Yes: {
    id: "decedentInfoQuestion1Yes",
    label: "Yes",
    name: "decedentInfoQuestion1Yes",
    groupName: "decedentInfoQuestion1Radio",
    value: false,
    stepName: "decedentInfo",
    stepQuestionNumber: 1,
  },
  decedentInfoQuestion1No: {
    id: "decedentInfoQuestion1No",
    label: "No",
    name: "decedentInfoQuestion1No",
    groupName: "decedentInfoQuestion1Radio",
    value: false,
    stepName: "decedentInfo",
    stepQuestionNumber: 1,
  },
  decedentInfoQuestion1Country: {
    id: "decedentInfoQuestion1Country",
    name: "decedentInfoQuestion1Country",
    display: "Select",
    defaultDisplay: "Select",
    labelbold: true,
    required: false,
    value: "",
    errors: [],
    options: countries,
    stepName: "decedentInfo",
    stepQuestionNumber: 1.1,
    type: "dropdown",
  },

  decedentInfoQuestion1Unknown: {
    id: "decedentInfoQuestion1Unknown",
    label: "Unknown",
    name: "decedentInfoQuestion1Unknown",
    groupName: "decedentInfoQuestion1Radio",
    value: false,
    stepName: "decedentInfo",
    stepQuestionNumber: 1,
  },
  decedentInfoQuestion2Yes: {
    id: "decedentInfoQuestion2Yes",
    label: "Yes",
    name: "decedentInfoQuestion2Yes",
    groupName: "decedentInfoQuestion2Radio",
    value: false,
    stepName: "decedentInfo",
    stepQuestionNumber: 2,
  },
  decedentInfoQuestion2Date: {
    id: "decedentInfoQuestion2Date",
    name: "decedentInfoQuestion2Date",
    groupName: "decedentInfoQuestion2Date",
    label: "Date of Death",
    labelBold: true,
    value: "",
    minDate: "",
    maxDate: new Date(),
    stepName: "decedentInfo",
    stepQuestionNumber: 2.1,
  },
  decedentInfoQuestion2No: {
    id: "decedentInfoQuestion2No",
    label: "No",
    name: "decedentInfoQuestion2No",
    groupName: "decedentInfoQuestion2Radio",
    value: false,
    stepName: "decedentInfo",
    stepQuestionNumber: 2,
  },

  decedentInfoQuestion3Natural: {
    id: "decedentInfoQuestion3Natural",
    label: "Natural",
    name: "decedentInfoQuestion3Natural",
    groupName: "decedentInfoQuestion3Radio",
    value: false,
    stepName: "decedentInfo",
    stepQuestionNumber: 3,
  },
  decedentInfoQuestion3Accidental: {
    id: "decedentInfoQuestion3Accidental",
    label: "Accidental",
    name: "decedentInfoQuestion3Accidental",
    groupName: "decedentInfoQuestion3Radio",
    value: false,
    stepName: "decedentInfo",
    stepQuestionNumber: 3,
  },
  decedentInfoQuestion3Homicide: {
    id: "decedentInfoQuestion3Homicide",
    label: "Homicide",
    name: "decedentInfoQuestion3Homicide",
    groupName: "decedentInfoQuestion3Radio",
    value: false,
    stepName: "decedentInfo",
    stepQuestionNumber: 3,
  },
  decedentInfoQuestion3Suicide: {
    id: "decedentInfoQuestion3Suicide",
    label: "Suicide",
    name: "decedentInfoQuestion3Suicide",
    groupName: "decedentInfoQuestion3Radio",
    value: false,
    stepName: "decedentInfo",
    stepQuestionNumber: 3,
  },
  decedentInfoQuestion3Unknown: {
    id: "decedentInfoQuestion3Unknown",
    label: "Unknown",
    name: "decedentInfoQuestion3Unknown",
    groupName: "decedentInfoQuestion3Radio",
    value: false,
    stepName: "decedentInfo",
    stepQuestionNumber: 3,
  },

  decedentInfoQuestion4NeverMarried: {
    id: "decedentInfoQuestion4NeverMarried",
    label: "Never Married",
    name: "decedentInfoQuestion4NeverMarried",
    groupName: "decedentInfoQuestion4Radio",
    value: false,
    stepName: "decedentInfo",
    stepQuestionNumber: 4,
  },
  decedentInfoQuestion4Married: {
    id: "decedentInfoQuestion4Married",
    label: "Married",
    name: "decedentInfoQuestion4Married",
    groupName: "decedentInfoQuestion4Radio",
    value: false,
    stepName: "decedentInfo",
    stepQuestionNumber: 4,
  },
  decedentInfoQuestion4Divorced: {
    id: "decedentInfoQuestion4Divorced",
    label: "Divorced",
    name: "decedentInfoQuestion4Divorced",
    groupName: "decedentInfoQuestion4Radio",
    value: false,
    stepName: "decedentInfo",
    stepQuestionNumber: 4,
  },
  decedentInfoQuestion4WidowOrWidower: {
    id: "decedentInfoQuestion4WidowOrWidower",
    label: "Widow/Widower",
    name: "decedentInfoQuestion4WidowOrWidower",
    groupName: "decedentInfoQuestion4Radio",
    value: false,
    stepName: "decedentInfo",
    stepQuestionNumber: 4,
  },
  decedentInfoQuestion4Unknown: {
    id: "decedentInfoQuestion4Unknown",
    label: "Unknown",
    name: "decedentInfoQuestion4Unknown",
    groupName: "decedentInfoQuestion4Radio",
    value: false,
    stepName: "decedentInfo",
    stepQuestionNumber: 4,
  },
  decedentInfoQuestion4SpouseFirstName: {
    id: "decedentInfoQuestion4SpouseFirstName",
    label: "Spouse First Name",
    labelBold: false,
    required: false,
    placeholder: "",
    name: "decedentInfoQuestion4SpouseFirstName",
    useValidation: useGeneralValidation,
    value: "",
    errors: [],
    stepName: "decedentInfo",
    stepQuestionNumber: 4.1,
  },
  decedentInfoQuestion4SpouseLastName: {
    id: "decedentInfoQuestion4SpouseLastName",
    label: "Spouse Last Name",
    labelBold: false,
    required: false,
    placeholder: "",
    name: "decedentInfoQuestion4SpouseLastName",
    useValidation: useGeneralValidation,
    value: "",
    errors: [],
    stepName: "decedentInfo",
    stepQuestionNumber: 4.1,
  },
  decedentInfoQuestion4FormerSpouseLastName: {
    id: "decedentInfoQuestion4FormerSpouseLastName",
    label: "Former Spouse Last Name",
    labelBold: false,
    required: false,
    placeholder: "",
    name: "decedentInfoQuestion4FormerSpouseLastName",
    useValidation: useGeneralValidation,
    value: "",
    errors: [],
    stepName: "decedentInfo",
    stepQuestionNumber: 4.1,
  },
  decedentInfoQuestion4FormerSpouseFirstName: {
    id: "decedentInfoQuestion4FormerSpouseFirstName",
    label: "Former Spouse First Name",
    labelBold: false,
    required: false,
    placeholder: "",
    name: "decedentInfoQuestion4FormerSpouseFirstName",
    useValidation: useGeneralValidation,
    value: "",
    errors: [],
    stepName: "decedentInfo",
    stepQuestionNumber: 4.1,
  },
  decedentInfoQuestion4FormerSpouseDateOfDivorce: {
    id: "decedentInfoQuestion4FormerSpouseDateOfDivorce",
    label: "Date of Divorce",
    labelBold: true,
    name: "decedentInfoQuestion4FormerSpouseDateOfDivorce",
    groupName: "decedentInfoQuestion4FormerSpouseDateOfDivorce",
    value: "",
    minDate: "",
    maxDate: new Date(),
    stepName: "decedentInfo",
    stepQuestionNumber: 4.1,
  },
  decedentInfoQuestion4DivorceOccuredState: {
    id: "decedentInfoQuestion4DivorceOccuredState",
    name: "decedentInfoQuestion4DivorceOccuredState",
    display: "Select",
    defaultDisplay: "Select",
    labelbold: true,
    required: false,
    label: "State in which the divorce occurred",
    value: "",
    errors: [],
    options: stateAndTerritories,
    stepName: "decedentInfo",
    stepQuestionNumber: 4.1,
    type: "dropdown",
  },
  decedentInfoQuestion5Yes: {
    id: "decedentInfoQuestion5Yes",
    label: "Yes",
    name: "decedentInfoQuestion5Yes",
    groupName: "decedentInfoQuestion5Radio",
    value: false,
    stepName: "decedentInfo",
    stepQuestionNumber: 5,
  },
  decedentInfoQuestion5No: {
    id: "decedentInfoQuestion5No",
    label: "No",
    name: "decedentInfoQuestion5No",
    groupName: "decedentInfoQuestion5Radio",
    value: false,
    stepName: "decedentInfo",
    stepQuestionNumber: 5,
  },
  decedentInfoQuestion5Unknown: {
    id: "decedentInfoQuestion5Unknown",
    label: "Unknown",
    name: "decedentInfoQuestion5Unknown",
    groupName: "decedentInfoQuestion5Radio",
    value: false,
    stepName: "decedentInfo",
    stepQuestionNumber: 5,
  },
  decedentInfoQuestion5FormerSpouseLastName: {
    id: "decedentInfoQuestion5FormerSpouseLastName",
    label: "Former Spouse Last Name",
    labelBold: false,
    required: false,
    placeholder: "",
    name: "decedentInfoQuestion5FormerSpouseLastName",
    useValidation: useGeneralValidation,
    value: "",
    errors: [],
    stepName: "decedentInfo",
    stepQuestionNumber: 5.1,
  },
  decedentInfoQuestion5FormerSpouseFirstName: {
    id: "decedentInfoQuestion5FormerSpouseFirstName",
    label: "Former Spouse First Name",
    labelBold: false,
    required: false,
    placeholder: "",
    name: "decedentInfoQuestion5FormerSpouseFirstName",
    useValidation: useGeneralValidation,
    value: "",
    errors: [],
    stepName: "decedentInfo",
    stepQuestionNumber: 5.1,
  },
  decedentInfoQuestion5FormerSpouseDateOfDivorce: {
    id: "decedentInfoQuestion5FormerSpouseDateOfDivorce",
    label: "Date of Divorce",
    labelBold: true,
    name: "decedentInfoQuestion5FormerSpouseDateOfDivorce",
    groupName: "decedentInfoQuestion5FormerSpouseDateOfDivorce",
    value: "",
    minDate: "",
    maxDate: new Date(),
    stepName: "decedentInfo",
    stepQuestionNumber: 5.1,
  },
  decedentInfoQuestion5DivorceOccuredState: {
    id: "decedentInfoQuestion5DivorceOccuredState",
    name: "decedentInfoQuestion5DivorceOccuredState",
    display: "Select",
    defaultDisplay: "Select",
    labelbold: true,
    required: false,
    label: "State in which the divorce occurred",
    value: "",
    errors: [],
    options: stateAndTerritories,
    stepName: "decedentInfo",
    stepQuestionNumber: 5.1,
    type: "dropdown",
  },
  decedentInfoQuestion6Yes: {
    id: "decedentInfoQuestion6Yes",
    label: "Yes",
    name: "decedentInfoQuestion6Yes",
    groupName: "decedentInfoQuestion6Radio",
    value: false,
    stepName: "decedentInfo",
    stepQuestionNumber: 6,
  },
  decedentInfoQuestion6No: {
    id: "decedentInfoQuestion6No",
    label: "No",
    name: "decedentInfoQuestion6No",
    groupName: "decedentInfoQuestion6Radio",
    value: false,
    stepName: "decedentInfo",
    stepQuestionNumber: 6,
  },
  decedentInfoQuestion6DisabilityClaimNumber: {
    id: "decedentInfoQuestion6DisabilityClaimNumber",
    label: "Claim Number",
    labelBold: false,
    required: false,
    placeholder: "",
    name: "decedentInfoQuestion6DisabilityClaimNumber",
    useValidation: useGeneralValidation,
    value: "",
    errors: [],
    stepName: "decedentInfo",
    stepQuestionNumber: 6.2,
  },
  decedentInfoQuestion6DisabilityClaimNumberYes: {
    id: "decedentInfoQuestion6DisabilityClaimNumberYes",
    label: "Yes",
    name: "decedentInfoQuestion6DisabilityClaimNumberYes",
    groupName: "decedentInfoQuestion6DisabilityClaimNumberRadio",
    value: false,
    stepName: "decedentInfo",
    stepQuestionNumber: 6.1,
  },
  decedentInfoQuestion6DisabilityClaimNumberNo: {
    id: "decedentInfoQuestion6DisabilityClaimNumberNo",
    label: "No",
    name: "decedentInfoQuestion6DisabilityClaimNumberNo",
    groupName: "decedentInfoQuestion6DisabilityClaimNumberRadio",
    value: false,
    stepName: "decedentInfo",
    stepQuestionNumber: 6.1,
  },
});

export const calculateDecedentInformationQuestionDisplay = (props) => {
  const { questionNumber, formData, shouldDisplayDecedentInfoQuestion3 } =
    props;
  let displayQuestion = false;
  if (shouldDisplayDecedentInfoQuestion3 === true) {
    if (questionNumber === 1) {
      displayQuestion = true;
    } else if (questionNumber === 2) {
      displayQuestion =
        formData.decedentInfoQuestion1Yes.value ||
        formData.decedentInfoQuestion1No.value ||
        formData.decedentInfoQuestion1Unknown.value;
    } else if (questionNumber === 3) {
      displayQuestion =
        formData.decedentInfoQuestion2Yes.value ||
        formData.decedentInfoQuestion2No.value;
    } else if (questionNumber === 4) {
      displayQuestion =
        formData.decedentInfoQuestion3Accidental.value ||
        formData.decedentInfoQuestion3Homicide.value ||
        formData.decedentInfoQuestion3Natural.value ||
        formData.decedentInfoQuestion3Suicide.value ||
        formData.decedentInfoQuestion3Unknown.value;
    } else if (questionNumber === 5) {
      displayQuestion =
        formData.decedentInfoQuestion4Married.value ||
        formData.decedentInfoQuestion4WidowOrWidower.value;
    } else if (questionNumber === 6) {
      if (
        formData.decedentInfoQuestion4NeverMarried.value ||
        formData.decedentInfoQuestion4Divorced.value ||
        formData.decedentInfoQuestion4Unknown.value
      ) {
        displayQuestion = true;
      } else {
        displayQuestion =
          formData.decedentInfoQuestion5Yes.value ||
          formData.decedentInfoQuestion5No.value ||
          formData.decedentInfoQuestion5Unknown.value;
      }
    }
  } else if (shouldDisplayDecedentInfoQuestion3 === false) {
    if (questionNumber === 1) {
      displayQuestion = true;
    } else if (questionNumber === 2) {
      displayQuestion =
        formData.decedentInfoQuestion1Yes.value ||
        formData.decedentInfoQuestion1No.value ||
        formData.decedentInfoQuestion1Unknown.value;
    } else if (questionNumber === 4) {
      displayQuestion =
        formData.decedentInfoQuestion2Yes.value ||
        formData.decedentInfoQuestion2No.value;
    } else if (questionNumber === 5) {
      displayQuestion =
        formData.decedentInfoQuestion4Married.value ||
        formData.decedentInfoQuestion4WidowOrWidower.value;
    } else if (questionNumber === 6) {
      if (
        formData.decedentInfoQuestion4NeverMarried.value ||
        formData.decedentInfoQuestion4Divorced.value ||
        formData.decedentInfoQuestion4Unknown.value
      ) {
        displayQuestion = true;
      } else {
        displayQuestion =
          formData.decedentInfoQuestion5Yes.value ||
          formData.decedentInfoQuestion5No.value ||
          formData.decedentInfoQuestion5Unknown.value;
      }
    }
  }

  return displayQuestion;
};

export const useInitiateDeathClaimDetails = () => {
  const { formData } = useFormContext();
  const selectedDecedent = getSelectedDecedentObjectfromFormData(formData);
  const policyNumbers = selectedDecedent?.policies.map(
    (policy) => policy.agreementKeyPrimaryDisplay
  );
  return {
    decedentName: selectedDecedent?.label,
    policyNumbers,
    selectedDecedent,
  };
};

export const getSelectedDecedentObjectfromFormData = (formData) => {
  const selectedDecedent = Object.values(formData).find(
    (decedentInfo) =>
      decedentInfo.groupName === "decedent" && decedentInfo.value === true
  );
  return selectedDecedent || {};
};

export const useDecedentInfoQuestionsAndAnswersProps = () => {
  const { formData, dispatchForm } = useFormContext();
  const {
    decedentInfoQuestion2Date,
    decedentInfoQuestion4FormerSpouseDateOfDivorce,
    decedentInfoQuestion5FormerSpouseDateOfDivorce,
    decedentInfoQuestion4Married,
    decedentInfoQuestion4WidowOrWidower,
  } = formData;

  const decedentInfoQuestion2DateHandler = (date) => {
    if (isDefined(decedentInfoQuestion2Date) && isDefined(dispatchForm)) {
      dispatchForm({
        type: types.REPLACE_FIELD,
        formField: {
          ...decedentInfoQuestion2Date,
          value: date,
          postpone: false,
        },
      });
    }
  };

  const decedentInfoQuestion4FormerSpouseDateOfDivorceHandler = (date) => {
    if (
      isDefined(decedentInfoQuestion4FormerSpouseDateOfDivorce) &&
      isDefined(dispatchForm)
    ) {
      dispatchForm({
        type: types.REPLACE_FIELD,
        formField: {
          ...decedentInfoQuestion4FormerSpouseDateOfDivorce,
          value: date,
          postpone: false,
        },
      });
    }
  };
  const decedentInfoQuestion5FormerSpouseDateOfDivorceHandler = (date) => {
    if (
      isDefined(decedentInfoQuestion5FormerSpouseDateOfDivorce) &&
      isDefined(dispatchForm)
    ) {
      dispatchForm({
        type: types.REPLACE_FIELD,
        formField: {
          ...decedentInfoQuestion5FormerSpouseDateOfDivorce,
          value: date,
          postpone: false,
        },
      });
    }
  };

  const selectedDecedent = getSelectedDecedentObjectfromFormData(formData);

  const policiesLobArray = [
    ...new Set(
      selectedDecedent.policies.map((policy) => policy.lineOfBusinessCode)
    ),
  ];
  const shouldDisplayDecedentInfoQuestion3 = compareArrayOfStrings(
    policiesLobArray,
    "LIFE"
  );

  const shouldDisplayDecedentInfoQuestion5 =
    decedentInfoQuestion4Married.value === true ||
    decedentInfoQuestion4WidowOrWidower.value === true;

  const isDecedentInsuredOnDisabilityPolicy = selectedDecedent.policies.some(
    (policy) =>
      compareStrings(policy.lineOfBusinessCode, "DI") &&
      compareArrayOfStrings(policy.arrayRoleType, "INSD")
  );
  const questionKeys = [
    "decedentInfoQuestion1",
    "decedentInfoQuestion2",
    "decedentInfoQuestion3",
    "decedentInfoQuestion4",
    "decedentInfoQuestion5",
    "decedentInfoQuestion6",
  ];

  return {
    decedentInfoQuestion2DateHandler,
    decedentInfoQuestion4FormerSpouseDateOfDivorceHandler,
    decedentInfoQuestion5FormerSpouseDateOfDivorceHandler,
    questionKeys,
    shouldDisplayDecedentInfoQuestion3,
    isDecedentInsuredOnDisabilityPolicy,
    shouldDisplayDecedentInfoQuestion5,
  };
};

export const displayContinueButton = (props) => {
  const { formData, pageNumber, isDiOnlyClaim } = props;
  const {
    decedentInfoQuestion6Yes,
    decedentInfoQuestion6No,
    decedentInfoQuestion4Married,
    decedentInfoQuestion4WidowOrWidower,
    decedentInfoQuestion4Divorced,
    decedentInfoQuestion4Unknown,
    decedentInfoQuestion4NeverMarried,
    decedentInfoQuestion5Yes,
    decedentInfoQuestion5No,
    decedentInfoQuestion5Unknown,
    decedentInfoQuestion6DisabilityClaimNumberYes,
    decedentInfoQuestion6DisabilityClaimNumberNo,
    beneContactQuestionYes,
    beneContactQuestionNo,
    claimPacketAndFormsAnswer6Email,
    claimPacketAndFormsAnswer6Phone,
    claimPacketAndFormsAnswer1AssistWithForms,
    claimPacketAndFormsAnswer1AssistWithDeliveryOfProceeds,
    claimPacketAndFormsAnswer1AssistWithBothFormsAndDelivery,
    claimPacketAndFormsAnswer1No,
    claimPacketAndFormsAnswer3YesDownloadPreFilledForm,
    claimPacketAndFormsAnswer3NoClaimExaminerSendForms,
    claimPacketAndFormsAnswer4Email,
    claimPacketAndFormsAnswer4Fax,
    claimPacketAndFormsAnswer4Mail,
    claimPacketAndFormsAnswer5PDF,
    claimPacketAndFormsAnswer5SmartForm,
  } = formData;

  let shouldDisplayContinueButton = false;

  if (pageNumber === 1) {
    shouldDisplayContinueButton = true;
  }
  if (pageNumber === 2) {
    const selectedDecedent = getSelectedDecedentObjectfromFormData(formData);
    const isDecedentInsuredOnDisabilityPolicy = selectedDecedent.policies.some(
      (policy) =>
        compareStrings(policy.lineOfBusinessCode, "DI") &&
        compareArrayOfStrings(policy.arrayRoleType, "INSD")
    );

    const isAnswersWithOutSubQuestionSelectedForQuestion4 =
      decedentInfoQuestion4NeverMarried.value ||
      decedentInfoQuestion4Divorced.value ||
      decedentInfoQuestion4Unknown.value;

    const isAnswersWithSubQuestionSelectedForQuestion4 =
      decedentInfoQuestion4Married.value ||
      decedentInfoQuestion4WidowOrWidower.value;

    const isQuestion4SubQuestionsAnswered =
      isAnswersWithSubQuestionSelectedForQuestion4 &&
      (decedentInfoQuestion5Yes.value ||
        decedentInfoQuestion5No.value ||
        decedentInfoQuestion5Unknown.value);

    const isQuestion6SubQuestionAnswered = decedentInfoQuestion6Yes.value
      ? decedentInfoQuestion6DisabilityClaimNumberYes.value ||
        decedentInfoQuestion6DisabilityClaimNumberNo.value
      : decedentInfoQuestion6Yes.value;

    shouldDisplayContinueButton = isDecedentInsuredOnDisabilityPolicy
      ? isQuestion6SubQuestionAnswered || decedentInfoQuestion6No.value
      : isAnswersWithOutSubQuestionSelectedForQuestion4 ||
        isQuestion4SubQuestionsAnswered;
  }

  if (!isDiOnlyClaim) {
    if (pageNumber === 3) {
      shouldDisplayContinueButton =
        beneContactQuestionYes.value || beneContactQuestionNo.value;
    }
    if (pageNumber === 4) {
      const policiesLobArray = getSelectedDecedentPoliciesLobs({ formData });
      const isNotAnnuityOnlyContract = policiesLobArray.some(
        (policyItem) => !compareStrings(policyItem, "ANN")
      );

      const isQuestion1YesAnswerSelected =
        claimPacketAndFormsAnswer1AssistWithForms.value ||
        claimPacketAndFormsAnswer1AssistWithDeliveryOfProceeds.value ||
        claimPacketAndFormsAnswer1AssistWithBothFormsAndDelivery.value;
      const isQuestion4Answered =
        claimPacketAndFormsAnswer4Email.value ||
        claimPacketAndFormsAnswer4Fax.value ||
        claimPacketAndFormsAnswer4Mail.value;
      const isQuestion3Answered =
        claimPacketAndFormsAnswer3YesDownloadPreFilledForm.value ||
        claimPacketAndFormsAnswer3NoClaimExaminerSendForms.value;
      const isQuestion5Answered =
        claimPacketAndFormsAnswer5PDF.value ||
        claimPacketAndFormsAnswer5SmartForm.value;
      const isQuestion6Answered =
        claimPacketAndFormsAnswer6Email.value ||
        claimPacketAndFormsAnswer6Phone.value;
      if (isQuestion1YesAnswerSelected) {
        shouldDisplayContinueButton =
          isQuestion3Answered && isQuestion6Answered;
      }
      if (
        claimPacketAndFormsAnswer1No.value ||
        claimPacketAndFormsAnswer3NoClaimExaminerSendForms.value
      ) {
        shouldDisplayContinueButton =
          isNotAnnuityOnlyContract && claimPacketAndFormsAnswer4Email.value
            ? isQuestion5Answered && isQuestion6Answered
            : isQuestion4Answered && isQuestion6Answered;
      }
    }
  } else if (isDiOnlyClaim) {
    if (pageNumber === 3) {
      shouldDisplayContinueButton =
        claimPacketAndFormsAnswer6Email.value ||
        claimPacketAndFormsAnswer6Phone.value;
    }
  }

  return shouldDisplayContinueButton;
};

export const useDecedentQuestionRadioButtonProps = (props) => {
  const { formField } = props;
  const { dispatchForm } = useFormContext();
  const onChange = (event) => {
    dispatchForm({
      type: types.RADIO_SELECTED,
      formField,
    });

    dispatchForm({
      type: types.CLEAR_FOLLOWING_VALUE_FIELDS,
      formField,
    });

    setTimeout(() => {
      const currentQuestionNumberIndex = event.target.id.replace(/[^0-9]/g, "");
      scrollToElement(`decedentInfo_Question${currentQuestionNumberIndex}`);
    }, 0);
  };
  return {
    onChange,
  };
};
export const getOWNRAndINSDforCustomerData = (customerDataItem) => {
  const { lineOfBusinessCode, roleTypes } = customerDataItem;
  if (!isDefined(lineOfBusinessCode) || isEmptyArray(roleTypes)) {
    return [];
  }

  const arrayRoleTypeDisplay = [];

  if (
    compareStrings(lineOfBusinessCode, "LIFE") ||
    compareStrings(lineOfBusinessCode, "DI")
  ) {
    if (compareArrayOfStrings(roleTypes, "OWNR")) {
      arrayRoleTypeDisplay.push("Owner");
    }
    if (compareArrayOfStrings(roleTypes, "INSD")) {
      arrayRoleTypeDisplay.push("Insured");
    }
  }
  if (compareStrings(lineOfBusinessCode, "ANN")) {
    if (compareArrayOfStrings(roleTypes, "OWNR")) {
      arrayRoleTypeDisplay.push("Owner");
    }
    if (compareArrayOfStrings(roleTypes, "INSD")) {
      arrayRoleTypeDisplay.push("Annuitant");
    }
  }
  return { arrayRoleTypeDisplay };
};

export const useInitiateDeathClaimLinkProps = (props) => {
  const {
    agreementData,
    deathClaimEligibilityData,
    isCalledFrom,
    userEmail,
    policyId,
    target,
  } = props;

  const { agreementKey, lineOfBusinessCode } = agreementData;
  const { deathClaimEligibility } = deathClaimEligibilityData;

  const transactionSupportedPolicies = useLDValue({
    flagName: ["transactionSupportedPolicies"],
  });

  const { initiateDeathClaimLink } = verifyInitiateDeathClaim({
    agreementData,
    deathClaimEligibilityData,
    transactionSupportedPolicies,
  });

  const hasDeathClaimInitiationTransactionPending = compareStrings(
    deathClaimEligibility,
    "P"
  );

  const { openPendingTransactionModal } = usePendingTransactionModalProps({
    agreementKey,
    policyId,
    userEmail,
  });

  const handleInitiateDeathClaimLinkClick = () => {
    if (hasDeathClaimInitiationTransactionPending) {
      openPendingTransactionModal();
    } else {
      fireTealiumAndFollowLink({
        url: initiateDeathClaimLink,
        action: `${lineOfBusinessCode}: ${supportedTransactions.InitiateDeathClaim} clicked`,
        category: isCalledFrom,
        value: policyId,
        custId: userEmail,
        target,
      });
    }
  };
  return {
    handleInitiateDeathClaimLinkClick,
    hasDeathClaimInitiationTransactionPending,
  };
};

export const usePendingTransactionModalProps = (props) => {
  const { agreementKey, policyId, userEmail } = props;

  const { openModal, closeModal } = useModalState();

  const handleServiceHistoryButtonClick = () => {
    fireTealiumAndFollowLink({
      url: `/policyDetails/${encodeURIComponent(
        agreementKey
      )}/viewServiceHistory`,
      action: `Service History link clicked from Claim Pending modal popup`,
      category: "PDP",
      value: policyId,
      custId: userEmail,
    });
  };

  const pendingTransacationModalProps = {
    title: "Claim Pending",
    Component: ModalPopup,
    componentProps: {
      closeModal: handleServiceHistoryButtonClick,
      agreementKey,
      modalBodyMessage:
        "A Claim is currently pending on this policy/contract.  Please view the Service History for more information or contact the Home Office at 1-(800)-272-2216, Monday - Friday, 8am-8pm ET.",
      hasUrlInMessage: false,
      continueButtonMessage: "Service History",
      cancelPendingTransaction: closeModal,
    },
  };

  const openPendingTransactionModal = () =>
    openModal(pendingTransacationModalProps);

  return {
    openPendingTransactionModal,
  };
};

export const useVerifyFormsRecipientProps = (props) => {
  const { nextPage } = props;
  const { openModal, closeModal } = useModalState();

  const handleCloseModal = () => {
    closeModal();
  };
  const handleContinueClick = () => {
    closeModal();
    nextPage();
  };

  const verifyFormsRecipientProps = {
    title: "Are you sure you want to continue?",
    Component: ModalPopup,
    componentProps: {
      closeModal: handleCloseModal,
      modalBodyMessage:
        "You have not selected a recipient for the claims packet.",
      hasUrlInMessage: false,
      cancelButtonMessage: "Continue",
      continueButtonMessage: "Cancel",
      customCancelButton: true,
      cancelButtonFunction: handleContinueClick,
    },
  };

  const openVerifyRecipientModal = () => openModal(verifyFormsRecipientProps);

  return {
    openVerifyRecipientModal,
  };
};

export const useClaimPacketAndFormsFields = () => ({
  claimPacketAndFormsAnswer1AssistWithForms: {
    id: "claimPacketAndFormsAnswer1AssistWithForms",
    label: "Yes, I'll assist with forms/requirements",
    name: "claimPacketAndFormsAnswer1AssistWithForms",
    groupName: "claimPacketAndFormsAnswer1Radio",
    stepName: "claimsPacketAndForms",
    stepQuestionNumber: 1,
    value: false,
  },
  claimPacketAndFormsAnswer1AssistWithDeliveryOfProceeds: {
    id: "claimPacketAndFormsAnswer1AssistWithDeliveryOfProceeds",
    label: "Yes, I'll assist with delivery of proceeds",
    name: "claimPacketAndFormsAnswer1AssistWithDeliveryOfProceeds",
    groupName: "claimPacketAndFormsAnswer1Radio",
    stepName: "claimsPacketAndForms",
    stepQuestionNumber: 1,
    value: false,
  },
  claimPacketAndFormsAnswer1AssistWithBothFormsAndDelivery: {
    id: "claimPacketAndFormsAnswer1AssistWithBothFormsAndDelivery",
    label:
      "Yes, I'll assist with both forms/requirements and delivery of proceeds",
    name: "claimPacketAndFormsAnswer1AssistWithBothFormsAndDelivery",
    groupName: "claimPacketAndFormsAnswer1Radio",
    stepName: "claimsPacketAndForms",
    stepQuestionNumber: 1,
    value: false,
  },
  claimPacketAndFormsAnswer1No: {
    id: "claimPacketAndFormsAnswer1No",
    label: "No",
    name: "claimPacketAndFormsAnswer1No",
    groupName: "claimPacketAndFormsAnswer1Radio",
    stepName: "claimsPacketAndForms",
    stepQuestionNumber: 1,
    value: false,
  },
  claimPacketAndFormsAnswer3YesDownloadPreFilledForm: {
    id: "claimPacketAndFormsAnswer3YesDownloadPreFilledForm",
    label:
      "Yes, I would like to download a pre-filled form, and send to the beneficiary myself",
    name: "claimPacketAndFormsAnswer3YesDownloadPreFilledForm",
    groupName: "claimPacketAndFormsAnswer3Radio",
    stepName: "claimsPacketAndForms",
    stepQuestionNumber: 3,
    value: false,
  },
  claimPacketAndFormsAnswer3NoClaimExaminerSendForms: {
    id: "claimPacketAndFormsAnswer3NoClaimExaminerSendForms",
    label:
      "No, I would like the Claim Examiner to send the forms to the beneficiary",
    name: "claimPacketAndFormsAnswer3NoClaimExaminerSendForms",
    groupName: "claimPacketAndFormsAnswer3Radio",
    stepName: "claimsPacketAndForms",
    stepQuestionNumber: 3,
    value: false,
  },
  claimPacketAndFormsAnswer4Email: {
    id: "claimPacketAndFormsAnswer4Email",
    label: "Email",
    name: "claimPacketAndFormsAnswer4Email",
    groupName: "claimPacketAndFormsAnswer4Radio",
    stepName: "claimsPacketAndForms",
    stepQuestionNumber: 4,
    value: false,
  },
  claimPacketAndFormsAnswer4Fax: {
    id: "claimPacketAndFormsAnswer4Fax",
    label: "Fax",
    name: "claimPacketAndFormsAnswer4Fax",
    groupName: "claimPacketAndFormsAnswer4Radio",
    stepName: "claimsPacketAndForms",
    stepQuestionNumber: 4,
    value: false,
  },
  claimPacketAndFormsAnswer4Mail: {
    id: "claimPacketAndFormsAnswer4Mail",
    label: "Mail",
    name: "claimPacketAndFormsAnswer4Mail",
    groupName: "claimPacketAndFormsAnswer4Radio",
    stepName: "claimsPacketAndForms",
    stepQuestionNumber: 4,
    value: false,
  },
  claimPacketAndFormsAnswer5PDF: {
    id: "claimPacketAndFormsAnswer5PDF",
    label: "PDF",
    name: "claimPacketAndFormsAnswer5PDF",
    groupName: "claimPacketAndFormsAnswer5Radio",
    stepName: "claimsPacketAndForms",
    stepQuestionNumber: 5,
    value: false,
  },
  claimPacketAndFormsAnswer5SmartForm: {
    id: "claimPacketAndFormsAnswer5SmartForm",
    label: "SmartForm",
    name: "claimPacketAndFormsAnswer5SmartForm",
    groupName: "claimPacketAndFormsAnswer5Radio",
    stepName: "claimsPacketAndForms",
    stepQuestionNumber: 5,
    value: false,
  },
  claimPacketAndFormsAnswer6Phone: {
    id: "claimPacketAndFormsAnswer6Phone",
    label: "Phone",
    name: "claimPacketAndFormsAnswer6Phone",
    groupName: "claimPacketAndFormsAnswer6Radio",
    stepName: "claimsPacketAndForms",
    stepQuestionNumber: 6,
    value: false,
  },
  claimPacketAndFormsAnswer6Email: {
    id: "claimPacketAndFormsAnswer6Email",
    label: "Email",
    name: "claimPacketAndFormsAnswer6Email",
    groupName: "claimPacketAndFormsAnswer6Radio",
    stepName: "claimsPacketAndForms",
    stepQuestionNumber: 6,
    value: false,
  },
});

export const useClaimsPacketAndFormsProps = (props) => {
  const {
    producerRequest,
    claimRecipientsContactsCount,
    setClaimRecipientsContactsCount,
    isDiOnlyClaim,
  } = props;
  const { formData } = useFormContext();
  const { recipients } = useFormRecipientsProps({
    formData,
    claimRecipientsContactsCount,
  });

  const disableAddContactButton =
    recipients.length >= INITIATE_DEATH_CLAIM_MAX_RECIPIENT_CONTACT_COUNT;

  const claimsPacketAndFormsQuestionsArray = [
    {
      question: "Would you like to assist with the claim process?",
    },
    {
      question: "Who will be receiving the forms?",
    },
    {
      question:
        "Would you like to download a pre-filled form and send it to the beneficiary?",
    },
    {
      question:
        "A Claim Examiner will send the forms. How would you like the claim forms to be sent?",
    },
    {
      question: "What type of form would you like to be sent?",
    },
    {
      question: isDiOnlyClaim
        ? "This claim is associated with a Disability policy only and therefore does not require a form. As the Advisor, what is your preferred method of contact in case we need to reach you?"
        : "As the Financial Professional, what is your preferred method of contact in case we need to reach you?",
    },
  ];

  return {
    claimsPacketAndFormsQuestionsArray,
    producerRequest,
    claimRecipientsContactsCount,
    setClaimRecipientsContactsCount,
    disableAddContactButton,
  };
};

export const calculateClaimsPacketAndFormsQuestionsDisplay = (props) => {
  const {
    questionNumber,
    formData,
    displayClaimsPacketAndFormsInfoQuestion5,
    isDiOnlyClaim,
  } = props;
  const {
    claimPacketAndFormsAnswer1AssistWithForms,
    claimPacketAndFormsAnswer1AssistWithDeliveryOfProceeds,
    claimPacketAndFormsAnswer1AssistWithBothFormsAndDelivery,
    claimPacketAndFormsAnswer1No,
    claimPacketAndFormsAnswer3YesDownloadPreFilledForm,
    claimPacketAndFormsAnswer3NoClaimExaminerSendForms,
    claimPacketAndFormsAnswer4Email,
    claimPacketAndFormsAnswer4Fax,
    claimPacketAndFormsAnswer4Mail,
    claimPacketAndFormsAnswer5PDF,
    claimPacketAndFormsAnswer5SmartForm,
  } = formData;
  let displayQuestion = false;

  if (!isDiOnlyClaim) {
    if (questionNumber === 1) {
      displayQuestion = true;
    } else if (questionNumber === 2) {
      displayQuestion =
        claimPacketAndFormsAnswer1AssistWithForms.value ||
        claimPacketAndFormsAnswer1AssistWithDeliveryOfProceeds.value ||
        claimPacketAndFormsAnswer1AssistWithBothFormsAndDelivery.value ||
        claimPacketAndFormsAnswer1No.value;
    } else if (questionNumber === 3) {
      displayQuestion =
        claimPacketAndFormsAnswer1AssistWithForms.value ||
        claimPacketAndFormsAnswer1AssistWithDeliveryOfProceeds.value ||
        claimPacketAndFormsAnswer1AssistWithBothFormsAndDelivery.value;
    } else if (questionNumber === 4) {
      displayQuestion =
        claimPacketAndFormsAnswer1No.value ||
        claimPacketAndFormsAnswer3NoClaimExaminerSendForms.value;
    } else if (questionNumber === 5) {
      displayQuestion = claimPacketAndFormsAnswer4Email.value;
    } else if (questionNumber === 6) {
      if (claimPacketAndFormsAnswer3YesDownloadPreFilledForm.value) {
        displayQuestion =
          claimPacketAndFormsAnswer3YesDownloadPreFilledForm.value;
      } else {
        const isQuestion5Answered =
          claimPacketAndFormsAnswer5PDF.value ||
          claimPacketAndFormsAnswer5SmartForm.value;
        const isQuestion4Answered =
          claimPacketAndFormsAnswer4Email.value ||
          claimPacketAndFormsAnswer4Fax.value ||
          claimPacketAndFormsAnswer4Mail.value;
        displayQuestion = displayClaimsPacketAndFormsInfoQuestion5
          ? isQuestion5Answered
          : isQuestion4Answered;
      }
    }
  } else if (isDiOnlyClaim && questionNumber === 6) {
    displayQuestion = true;
  }
  return displayQuestion;
};

export const useClaimsPacketAndFormsRadioButtonProps = (props) => {
  const { formField } = props;
  const { dispatchForm } = useFormContext();
  const onChange = (event) => {
    dispatchForm({
      type: types.RADIO_SELECTED,
      formField,
    });

    dispatchForm({
      type: types.CLEAR_FOLLOWING_VALUE_FIELDS,
      formField,
    });

    setTimeout(() => {
      const currentQuestionNumberIndex = event.target.id.replace(/[^0-9]/g, "");
      scrollToElement(
        `claimsPacketAndForms_Question${currentQuestionNumberIndex}`
      );
    }, 0);
  };
  return {
    onChange,
  };
};

export const useClaimsPacketAndFormsQuestionAndAnswersProps = () => {
  const { formData } = useFormContext();
  const { claimPacketAndFormsAnswer4Email } = formData;
  const policiesLobArray = getSelectedDecedentPoliciesLobs({ formData });
  const isNotAnnuityOnlyContract = policiesLobArray.some(
    (policyItem) => !compareStrings(policyItem, "ANN")
  );

  const displayClaimsPacketAndFormsInfoQuestion5 = isNotAnnuityOnlyContract
    ? claimPacketAndFormsAnswer4Email.value
    : false;

  return { displayClaimsPacketAndFormsInfoQuestion5 };
};
export const getSelectedDecedentPoliciesLobs = (props) => {
  const { formData } = props;

  const selectedDecedent = getSelectedDecedentObjectfromFormData(formData);
  const policiesLobArray = [
    ...new Set(
      selectedDecedent.policies.map((policy) => policy.lineOfBusinessCode)
    ),
  ];
  return policiesLobArray;
};
export const getAgentContactDetails = (props) => {
  const { producerData, user } = props;

  const oktaUserName = formatName({
    nameObject: {
      firstName: user.firstName,
      lastName: user.lastName,
      fullName: user.fullName,
    },
    format: "firstNameDisplayedFirst",
    defaultReturn: "-",
  });

  if (!isDefined(producerData) || isEmptyObject(producerData?.agent)) {
    return {
      email: user.email,
      name: `${oktaUserName} (Financial Professional)`,
    };
  }

  const { agent, address } = producerData;
  const { firstName, fullName, lastName, phoneNumber, emailAddress } = agent;
  const {
    addressLine1,
    addressLine2,
    addressLine3,
    city,
    state,
    country,
    zip,
  } = address;

  const agentUserName = formatName({
    nameObject: { firstName, lastName, fullName },
    format: "firstNameDisplayedFirst",
    defaultReturn: "-",
  });

  return {
    addressLine1,
    addressLine2,
    addressLine3,
    city,
    country,
    email: emailAddress || user.email,
    name: `${agentUserName || oktaUserName} (Financial Professional)`,
    phone: phoneNumber,
    state,
    zip,
  };
};

export const getBeneficiaryInformationTableRows = (
  beneficiaryData,
  selectedDecedentPolicies
) => {
  const rows = [];
  if (isDefined(beneficiaryData?.data)) {
    const beneficiaryDataRows = [];
    beneficiaryData.data.map((policy) => {
      const { trimmedPolicyId } = getTrimmedPolicyIdFromAgreement(
        policy.agreementKey
      );

      const { lineOfBusinessCode, lineOfBusiness } =
        getLineOfBusinessOfBeneficiaryPolicy(selectedDecedentPolicies, policy);

      if (isEmptyObject(policy.beneficiaryDesignation)) {
        beneficiaryDataRows.push({
          id: `${trimmedPolicyId}-beneficiary-information-table-row`,
          policyContractNumbers: trimmedPolicyId,
          beneficiaryArrangement: isDisabilityPolicy({ lineOfBusinessCode })
            ? "Not Applicable"
            : "Unavailable",
          lineOfBusiness,
        });
      } else {
        const { beneficiaries } = policy.beneficiaryDesignation;
        const sortedBeneficiaries = sortBeneficiariesByClass(beneficiaries);

        let beneficiaryClass;
        let beneficiaryName;
        let beneficiaryRelationship;
        let beneficiaryDesignationDescription;
        let beneficiaryArrangement;
        const beneficiaryArrangementList = [];

        for (let i = 0; i < sortedBeneficiaries.length; i += 1) {
          const beneficiary = sortedBeneficiaries[i];
          beneficiaryClass = convertToTitleCase(beneficiary?.beneficiaryClass);
          beneficiaryName = formatName({
            nameObject: beneficiary,
            format: "firstNameDisplayedFirst",
          });
          beneficiaryRelationship = convertToTitleCase(
            beneficiary?.relationship
          );
          beneficiaryDesignationDescription =
            checkComplexBeneArrangement(sortedBeneficiaries) ||
            !isDefined(beneficiary.beneficiaryDesignationDescription)
              ? "Unavailable"
              : beneficiary.beneficiaryDesignationDescription;

          if (
            isDefined(beneficiaryClass) &&
            isDefined(beneficiaryName) &&
            isDefined(beneficiaryRelationship)
          ) {
            beneficiaryArrangement = `${beneficiaryClass}: ${beneficiaryName}, ${beneficiaryRelationship}`;
          } else if (
            isDefined(beneficiaryClass) &&
            isDefined(beneficiaryName) &&
            !isDefined(beneficiaryRelationship)
          ) {
            beneficiaryArrangement = `${beneficiaryClass}: ${beneficiaryName}`;
          } else if (!isDefined(beneficiaryName)) {
            beneficiaryArrangement = beneficiaryDesignationDescription;
          }

          if (
            compareStrings(beneficiaryArrangement, "Unavailable") &&
            i !== 0
          ) {
            break;
          } else {
            beneficiaryArrangementList.push(
              `<li>${beneficiaryArrangement}</li>`
            );
          }
        }

        beneficiaryDataRows.push({
          id: `${trimmedPolicyId}-beneficiary-information-table-row`,
          policyContractNumbers: trimmedPolicyId,
          beneficiaryArrangement: `<ul class="list-unstyled">${beneficiaryArrangementList.join(
            ""
          )}</ul>`,
          lineOfBusiness,
        });
      }
      return beneficiaryDataRows;
    });
    rows.push(...beneficiaryDataRows);
  }

  if (isDefined(beneficiaryData?.errors)) {
    const errorRows = beneficiaryData.errors.map((policy) => {
      const { trimmedPolicyId } = getTrimmedPolicyIdFromAgreement(
        policy.agreementKey
      );
      const { lineOfBusiness } = getLineOfBusinessOfBeneficiaryPolicy(
        selectedDecedentPolicies,
        policy
      );
      return {
        id: `${trimmedPolicyId}-beneficiary-information-table-row`,
        policyContractNumbers: trimmedPolicyId,
        beneficiaryArrangement: "Unavailable",
        lineOfBusiness,
      };
    });

    rows.push(...errorRows);
  }
  return rows;
};

export const sortBeneficiariesByClass = (beneficiaries) => {
  const primaryBenes = [];
  const secondaryBenes = [];
  const tertiaryAndOtherBenes = [];
  beneficiaries.forEach((bene) => {
    if (compareStrings(bene?.beneficiaryClass, "primary")) {
      primaryBenes.push({
        ...bene,
      });
    } else if (compareStrings(bene?.beneficiaryClass, "secondary")) {
      secondaryBenes.push({
        ...bene,
      });
    } else {
      tertiaryAndOtherBenes.push(bene);
    }
  });

  return [...primaryBenes, ...secondaryBenes, ...tertiaryAndOtherBenes];
};

export const useClaimsPacketAndFormsAnswersProps = (props) => {
  const {
    formData,
    claimRecipientsContactsCount,
    setClaimRecipientsContactsCount,
  } = props;

  const { openContactInformationModal } =
    useBeneClaimRecipientContactModalProps({
      formField:
        formData[`claimRecipientContact${claimRecipientsContactsCount}`],
      setClaimRecipientsContactsCount,
      claimRecipientsContactsCount,
      action: contactInformationUpdateAction.ADD,
      cardType: "claimRecipientCard",
    });

  const addClaimsRecipientContact = () => {
    openContactInformationModal();
  };

  return { addClaimsRecipientContact };
};

export const makeSubmissionRequestBody = (props) => {
  const { formDataForSubmission, recipients, user, isDiOnlyClaim } = props;

  const {
    decedentInfoQuestion2Date,
    decedentInfoQuestion4SpouseFirstName,
    decedentInfoQuestion4SpouseLastName,
    decedentInfoQuestion4FormerSpouseFirstName,
    decedentInfoQuestion4FormerSpouseLastName,
    decedentInfoQuestion5FormerSpouseFirstName,
    decedentInfoQuestion5FormerSpouseLastName,
    decedentInfoQuestion1Country,
    decedentInfoQuestion6Yes,
    decedentInfoQuestion6DisabilityClaimNumber,
    decedentInfoQuestion6DisabilityClaimNumberYes,
    decedentInfoQuestion4FormerSpouseDateOfDivorce,
    decedentInfoQuestion5FormerSpouseDateOfDivorce,
    decedentInfoQuestion4DivorceOccuredState,
    decedentInfoQuestion5DivorceOccuredState,
    agentContact,
    additionalInfo,
  } = formDataForSubmission;

  const selectedDecedent = getSelectedDecedentObjectfromFormData(
    formDataForSubmission
  );

  const beneficiaryInformation = [];
  for (
    let index = 0;
    index < INITIATE_DEATH_CLAIM_MAX_BENE_CONTACT_COUNT;
    index += 1
  ) {
    const beneficiary = formDataForSubmission[`beneContact${index}`];
    if (!isEmptyObject(beneficiary.contactDetails) && beneficiary.value) {
      beneficiaryInformation.push({
        fullName: beneficiary.contactDetails.name,
        communication: {
          emailAddress: beneficiary.contactDetails.email,
          phone: [
            {
              phoneNumber: beneficiary.contactDetails.phone,
              extension: beneficiary.contactDetails.extension,
              phoneTypeCode: "Phone",
            },
          ],
        },
      });
    }
  }

  const maritalStatus = getSelectedAnswer(
    formDataForSubmission,
    "decedentInfoQuestion4Radio"
  );

  const decedentWasDivored =
    compareStrings(
      getSelectedAnswer(formDataForSubmission, "decedentInfoQuestion4Radio"),
      "Divorced"
    ) ||
    compareStrings(
      getSelectedAnswer(formDataForSubmission, "decedentInfoQuestion5Radio"),
      "Yes"
    );

  const dateOfDivorce = compareStrings(
    getSelectedAnswer(formDataForSubmission, "decedentInfoQuestion4Radio"),
    "Divorced"
  )
    ? formatDate(
        decedentInfoQuestion4FormerSpouseDateOfDivorce.value.toString()
      )
    : formatDate(
        decedentInfoQuestion5FormerSpouseDateOfDivorce.value.toString()
      );

  const divorceState = compareStrings(
    getSelectedAnswer(formDataForSubmission, "decedentInfoQuestion4Radio"),
    "Divorced"
  )
    ? decedentInfoQuestion4DivorceOccuredState.value
    : decedentInfoQuestion5DivorceOccuredState.value;

  const spouseInformation = [];
  if (compareArrayOfStrings(["Married", "Widow/Widower"], maritalStatus)) {
    spouseInformation.push({
      party: {
        givenName: decedentInfoQuestion4SpouseFirstName.value,

        surname: decedentInfoQuestion4SpouseLastName.value,
        relationship: {
          typeCode: "SPOUSE",
        },
      },
    });
  }
  if (decedentWasDivored) {
    if (
      compareStrings(
        getSelectedAnswer(formDataForSubmission, "decedentInfoQuestion4Radio"),
        "Divorced"
      )
    ) {
      spouseInformation.push({
        party: {
          givenName: decedentInfoQuestion4FormerSpouseFirstName.value,
          surname: decedentInfoQuestion4FormerSpouseLastName.value,
          relationship: {
            typeCode: "XSPOUSE",
          },
        },
      });
    } else {
      spouseInformation.push({
        party: {
          givenName: decedentInfoQuestion5FormerSpouseFirstName.value,
          surname: decedentInfoQuestion5FormerSpouseLastName.value,
          relationship: {
            typeCode: "XSPOUSE",
          },
        },
      });
    }
  }
  const isDecedentInsuredOnDisabilityPolicy = selectedDecedent.policies.some(
    (policy) =>
      compareStrings(policy.lineOfBusinessCode, "DI") &&
      compareArrayOfStrings(policy.arrayRoleType, "INSD")
  );

  const selectedDecedentPolicies = selectedDecedent.policies.map((policy) => ({
    primaryId: policy.agreementKeyPrimary,
    prefix: policy.agreementKeyPrefix,
    suffix: policy.agreementKeySuffix,
    adminSystemCode: policy.agreementKeyAdmin,
    insured: {
      partyIdentity: {
        typeCode: "MBRGUID",
        typeId: policy.memberGUID,
      },
      person: {
        deathReason: isDefined(
          getSelectedAnswer(formDataForSubmission, "decedentInfoQuestion3Radio")
        )
          ? getSelectedAnswer(
              formDataForSubmission,
              "decedentInfoQuestion3Radio"
            )
          : "",
      },
      fullName: selectedDecedent?.label || "",
      givenName: policy?.decedentFirstName,
      otherGivenName: policy?.decedentMiddleName || "",
      deathDate: isDefined(decedentInfoQuestion2Date.value)
        ? formatDate(decedentInfoQuestion2Date.value.toString(), "YYYY-MM-DD")
        : "",
      surname: policy?.decedentLastName,
      maritalStatusCode: maritalStatus,
      spouse: spouseInformation,
    },
    beneficiaries: beneficiaryInformation,
  }));

  const recipientInformation = [];
  let communicationPreferenceCode = "";
  const claimsPacketAndFormsQuestion1Answer = getSelectedAnswer(
    formDataForSubmission,
    "claimPacketAndFormsAnswer1Radio"
  );
  const claimPacketAndFormsQuestion3Answer = getSelectedAnswer(
    formDataForSubmission,
    "claimPacketAndFormsAnswer3Radio"
  );
  const claimsPacketAndFormsQuestion4Answer = getSelectedAnswer(
    formDataForSubmission,
    "claimPacketAndFormsAnswer4Radio"
  );
  const claimsPacketAndFormsQuestion5Answer = getSelectedAnswer(
    formDataForSubmission,
    "claimPacketAndFormsAnswer5Radio"
  );

  const policiesLobArray = getSelectedDecedentPoliciesLobs({
    formData: formDataForSubmission,
  });
  const isNotAnnuityOnlyContract = policiesLobArray.some(
    (policyItem) => !compareStrings(policyItem, "ANN")
  );

  let agentWasSelected = false;
  for (let index = 0; index < recipients.length; index += 1) {
    if (compareStrings(recipients[index].id, "agentContact")) {
      agentWasSelected = true;
    }
    if (
      !compareStrings(claimsPacketAndFormsQuestion1Answer, "No") &&
      compareStrings(
        claimPacketAndFormsQuestion3Answer,
        "Yes, I would like to download a pre-filled form, and send to the beneficiary myself"
      )
    ) {
      communicationPreferenceCode =
        "Agent will download and send pre-filled form";
    }
    if (compareStrings(claimsPacketAndFormsQuestion4Answer, "Email")) {
      if (isNotAnnuityOnlyContract) {
        communicationPreferenceCode = claimsPacketAndFormsQuestion5Answer;
      } else {
        communicationPreferenceCode = "Email";
      }
    } else if (
      compareArrayOfStrings(
        ["Fax", "Mail"],
        claimsPacketAndFormsQuestion4Answer
      )
    ) {
      communicationPreferenceCode = claimsPacketAndFormsQuestion4Answer;
    }

    let addressLine2;
    if (
      isDefined(recipients[index].contactDetails.addressLine2) &&
      isDefined(recipients[index].contactDetails.addressLine3)
    ) {
      addressLine2 = `${recipients[index].contactDetails.addressLine2}, ${recipients[index].contactDetails.addressLine3}`;
    } else if (
      isDefined(recipients[index].contactDetails.addressLine2) &&
      !isDefined(recipients[index].contactDetails.addressLine3)
    ) {
      addressLine2 = recipients[index].contactDetails.addressLine2;
    }

    recipientInformation.push({
      address: {
        city: recipients[index].contactDetails.city,

        postalCode: recipients[index].contactDetails.zip,
        stateOrProvinceCode: recipients[index].contactDetails.state,
        line2: addressLine2,
        line1: recipients[index].contactDetails.addressLine1,
        countryCode: recipients[index].contactDetails.country,
      },
      fullName: recipients[index].contactDetails.name.replace(
        / \(Financial Professional\)/g,
        ""
      ),
      communicationPreferenceCode,
      communication: {
        phone: [
          {
            phoneNumber: recipients[index].contactDetails.phone,
            extension: recipients[index].contactDetails.extension,
            phoneTypeCode: "Phone",
          },
          {
            phoneNumber: recipients[index].contactDetails.fax,
            phoneTypeCode: "Fax",
          },
        ],
        emailAddress: recipients[index].contactDetails.email,
      },
    });
  }

  let recipientOfFormsAnswerText;
  if (recipients.length > 1) {
    recipientOfFormsAnswerText = "Multiple";
  } else if (recipients.length === 1 && agentWasSelected) {
    recipientOfFormsAnswerText = "Agent";
  } else {
    recipientOfFormsAnswerText = "Other";
  }

  const questionsAndAnswers = [
    {
      questionCode: "DEATH_CLAIM_INIT_DEATH_IN_US",
      questionText: "Did the death occur in the U.S.?",
      answerText: getSelectedAnswer(
        formDataForSubmission,
        "decedentInfoQuestion1Radio"
      ),
    },
    {
      questionCode: "DEATH_CLAIM_INIT_PRIMARY_BENE_ARRANGEMENT",
      questionText: "What is the Primary Beneficiary arrangement?",
      answerText: isDiOnlyClaim
        ? "Unknown"
        : getSelectedAnswer(formDataForSubmission, "beneTypeQuestion"),
    },
    {
      questionCode: "DEATH_CLAIM_INIT_IS_AGENT_OPTIN",
      questionText: "Does agent want to opt-in?",
      answerText: isDiOnlyClaim
        ? "Not Applicable"
        : getSelectedAnswer(
            formDataForSubmission,
            "claimPacketAndFormsAnswer1Radio"
          ),
    },
    {
      questionCode: "DEATH_CLAIM_INIT_FORM_RECIPIENTS",
      questionText: "Recipient of forms",
      answerText: recipientOfFormsAnswerText,
    },
  ];

  if (
    compareStrings(
      getSelectedAnswer(formDataForSubmission, "decedentInfoQuestion1Radio"),
      "No"
    )
  ) {
    const countryInfo = countries.find((country) =>
      compareStrings(country.id, decedentInfoQuestion1Country.value)
    );
    questionsAndAnswers.push({
      questionCode: "DEATH_CLAIM_INIT_DEATH_IN_COUNTRY",
      questionText: "In what country did the death occur?",
      answerText: isDefined(decedentInfoQuestion1Country.value)
        ? countryInfo.label
        : "",
    });
  }
  if (isDecedentInsuredOnDisabilityPolicy) {
    questionsAndAnswers.push({
      questionCode: "DEATH_CLAIM_INIT_PENDING_DI_CLAIM_EXISTS",
      questionText: "Is the insured on a current or pending disability claim?",
      answerText: decedentInfoQuestion6Yes.value ? "Yes" : "No",
    });
    if (decedentInfoQuestion6DisabilityClaimNumberYes.value) {
      questionsAndAnswers.push({
        questionCode: "DEATH_CLAIM_INIT_PENDING_DI_CLAIM_NUMBER",
        questionText: "Disability Claim Number if known",
        answerText: decedentInfoQuestion6DisabilityClaimNumber.value,
      });
    }
  }

  if (compareArrayOfStrings(["Married", "Widow/Widower"], maritalStatus)) {
    questionsAndAnswers.push({
      questionCode: "DEATH_CLAIM_INIT_INSURED_EVER_DIVORCED",
      questionText: "Was the deceased ever divorced?",
      answerText: decedentWasDivored ? "Yes" : "No",
    });
  }

  if (decedentWasDivored) {
    questionsAndAnswers.push(
      {
        questionCode: "DEATH_CLAIM_INIT_INSURED_DIVORCE_DATE",
        questionText: "Date of the divorce",
        answerText: dateOfDivorce,
      },
      {
        questionCode: "DEATH_CLAIM_INIT_INSURED_DIVORCE_STATE",
        questionText: "State in which the divorce occurred",
        answerText: divorceState,
      }
    );
  }
  const sanitizedAdditionalComments = sanitizeData(additionalInfo?.value);

  return {
    claim: {
      transaction: {
        submission: {
          policy: selectedDecedentPolicies,
          submitter: {
            partyRole: {
              roleCode: "AGNT",
            },
            communicationPreferenceCode: getSelectedAnswer(
              formDataForSubmission,
              "claimPacketAndFormsAnswer6Radio"
            ),
            communication: {
              emailAddress: isDefined(agentContact.contactDetails.email)
                ? agentContact.contactDetails.email
                : user.email,
            },
          },
          questionAndAnswers: questionsAndAnswers,
        },
      },
      notes: [
        {
          description: sanitizedAdditionalComments,
        },
      ],
    },
    correspondence: {
      recipients: recipientInformation,
    },
  };
};

export const getRecipientsContactDetails = (recipients) => {
  const receivingFormsNamesBeneList = recipients
    .filter(
      (recipient) =>
        recipient?.beneFormField?.contactDetails?.name &&
        recipient?.recipientFormField?.value === true
    )
    .map((recipient) => recipient?.beneFormField);

  const claimsRecipientNamesList = recipients
    .filter(
      (recipient) =>
        recipient?.claimRecipientFormField?.contactDetails?.name &&
        recipient?.claimRecipientFormField?.value === true
    )
    .map((recipient) => recipient?.claimRecipientFormField);

  return [...receivingFormsNamesBeneList, ...claimsRecipientNamesList];
};

export const getLineOfBusinessOfBeneficiaryPolicy = (
  selectedDecedentPolicies,
  policy
) => {
  const selectedPolicy =
    selectedDecedentPolicies?.find((decedentPolicy) =>
      compareStrings(decedentPolicy.agreementKey, policy.agreementKey)
    ) || {};
  const { lineOfBusinessCode } = selectedPolicy;
  return {
    lineOfBusinessCode,
    lineOfBusiness: formatLineOfBusiness(selectedPolicy),
  };
};
