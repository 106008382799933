import React from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { useUserState } from "../../../contexts/user";
import { typesOfAgents } from "../../../data/typesOfAgents";
import { getBpIdFromUser } from "../../../utils/findData";
import { verifyRequestInformation } from "../../../utils/policies";
import { compareStrings } from "../../../utils/string";
import { fireTealiumAndFollowLink } from "../../../utils/tealium";
import { useLDValue } from "../featureFlags/hooks";

export const InformationPopover = ({
  popoverId,
  text,
  placement = "top",
  iconName = "info",
  iconSize = "sm",
  triggerEvent = "click",
}) => (
  <OverlayTrigger
    trigger={triggerEvent}
    rootClose
    placement={placement}
    overlay={
      <Popover id={`popover-${popoverId}`} style={{ zIndex: 100002 }}>
        <Popover.Body
          id={`popover-body-${popoverId}`}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </Popover>
    }
  >
    <span
      className={`icon-${iconName} icon-${iconSize}`}
      id={`icon-${popoverId}`}
      role="button"
    >
      <span className="sr-only">Info Popover</span>
    </span>
  </OverlayTrigger>
);

export const LabelWithInformationPopover = ({
  label,
  popoverId,
  text,
  placement,
}) => (
  <>
    {label}{" "}
    <InformationPopover
      popoverId={popoverId}
      text={text}
      placement={placement}
    />
  </>
);

export const RequestInformationLink = ({
  popoverId,
  policyId,
  agreementData,
}) => {
  const { user } = useUserState();

  const { email } = user;

  const transactionSupportedPolicies = useLDValue({
    flagName: ["transactionSupportedPolicies"],
  });

  const { agentType } = getBpIdFromUser(user);
  const isMmsdOnlyUser = compareStrings(agentType, typesOfAgents.MMSD);

  const { redirectLink } = verifyRequestInformation({
    isMmsdOnlyUser,
    policyId,
    agreementData,
    transactionSupportedPolicies,
  });

  return (
    <div>
      <button
        className="btn btn-inline"
        id={`${popoverId}_request_info_link`}
        type="button"
        onClick={() =>
          fireTealiumAndFollowLink({
            url: redirectLink,
            action: `Request information link clicked from ${popoverId}`,
            category: "PDP",
            value: policyId,
            custId: email,
          })
        }
      >
        Request Information
      </button>
    </div>
  );
};
