export const processAgreementDataByCustomerMemberGuid = (props) => {
  const apiResponse = props?.response;
  const policyInfo = apiResponse?.data || {};
  const messages = apiResponse?.messages || {};

  return {
    policyInfo,
    messages,
  };
};
