import React from "react";
import { formatLisrVNTGData } from "./dividendHelper";

const LisrVntg1Section = (props) => {
  const { coverages } = props;
  const {
    riderSumInsured,
    amountOfTermPurchased,
    lisrDividendCashValueAmount,
    lisrDivOptPaidUpAddsAmount,
  } = formatLisrVNTGData(coverages);
  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <dl className="mm-key-value-pair">
          <dt id="lisr_rider_name_lbl">Rider Name</dt>
          <dd id="lisr_rider_name_value">LISR</dd>

          <dt id="lisr_div_cash_value_amount_lbl">
            Total LISR Dividend Cash Values
          </dt>
          <dd id="lisr_div_cash_value_amount_value">
            {lisrDividendCashValueAmount}
          </dd>
          <dt id="lisr_amount_term_purchased_lbl">Amount of Term Purchased</dt>
          <dd id="lisr_amount_term_purchased_value">{amountOfTermPurchased}</dd>
        </dl>
      </div>
      <div className="col-12 col-md-6">
        <dl className="mm-key-value-pair">
          <dt id="lisr_rider_sum_insured_lbl">Rider Sum Insured</dt>
          <dd id="lisr_rider_sum_insured_value">{riderSumInsured}</dd>
          <dt id="lisr_total_curr_div_earned_lbl">
            Total LISR Current Dividend Earned
          </dt>
          <dd id="lisr_total_curr_div_earned_value">
            {lisrDivOptPaidUpAddsAmount}
          </dd>
        </dl>
      </div>
    </div>
  );
};

export default React.memo(LisrVntg1Section);
