import React from "react";
import { useOktaAuth } from "@okta/okta-react";
import LoginError from "../loginError";
import Header from "../layout/header";
import Footer from "../layout/footer";

const LoginCallback = () => {
  const { oktaAuth } = useOktaAuth();
  const [displayError, setDisplayError] = React.useState(false);
  React.useEffect(() => {
    oktaAuth.handleLoginRedirect().catch((err) => {
      console.error(`loginCallbackError: ${err}`);
      setDisplayError(true);
    });
  }, [oktaAuth]);
  if (displayError) {
    return (
      <>
        <Header />
        <LoginError />
        <Footer />
      </>
    );
  }
  return null;
};
export default React.memo(LoginCallback);
