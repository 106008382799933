import React from "react";

const Checkmark = (props) => {
  const {
    row,
    rowIndex,
    column: { accessor, iconName, iconColor },
  } = props;

  const icon = row[accessor] && (
    <span
      id={`${accessor}_${rowIndex}_value`}
      className={`icon-${iconName}-${iconColor}`}
    />
  );
  return icon;
};

export default React.memo(Checkmark);
