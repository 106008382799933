import produce from "immer";

const types = {
  NEW_SAVE: "NEW_SAVE",
  TURN_ON: "TURN_ON",
  TURN_OFF: "TURN_OFF",
};

const initialDevModeState = {
  api: {
    agreement: {
      id: "agreement",
      label: "Agreement Api",
      apiRequestName: "fetchAgreementData",
      name: "agreement",
      value: "{}",
    },
    agreementCoreByCustId: {
      id: "agreementCoreByCustId",
      label: "Agreements Cores by custId",
      apiRequestName: "fetchAgreementCoreDataByCustId",
      name: "agreementCoreByCustId",
      value: "{}",
    },
    contract: {
      id: "contract",
      label: "Legacy Annuity Api",
      apiRequestName: "fetchAnnuityByContractNbr",
      name: "contract",
      value: "{}",
    },
    contractSoa: {
      id: "contractSoa",
      label: "SOA Annuity Api",
      apiRequestName: "fetchAnnuityByAgreementKey",
      name: "contractSoa",
      value: "{}",
    },
    billing: {
      id: "billing",
      label: "Billing Api",
      apiRequestName: "fetchBillingInformation",
      name: "billing",
      value: "{}",
    },
    beneficiaries: {
      id: "beneficiaries",
      label: "Benefeciary Api",
      apiRequestName: "fetchBeneficiaries",
      name: "beneficiaries",
      value: "{}",
    },
    content: {
      id: "content",
      label: "Content Api",
      apiRequestName: "fetchImagelistFromContent",
      name: "content",
      value: "{}",
    },
    customer: {
      id: "customer",
      label: "Customer Api",
      apiRequestName: "fetchCustomerData",
      name: "customer",
      value: "{}",
    },
    financialHistory: {
      id: "financialHistory",
      label: "Financial History Api",
      apiRequestName: "fetchFinancialHistory",
      name: "financialHistory",
      value: "{}",
    },
    loan: {
      id: "loan",
      label: "Loan Api",
      apiRequestName: "fetchLoanData",
      name: "loan",
      value: "{}",
    },
    producer: {
      id: "producer",
      label: "Producer Api",
      apiRequestName: "fetchProducerData",
      name: "producer",
      value: "{}",
    },
    producers: {
      id: "producers",
      label: "Producers Api",
      apiRequestName: "fetchProducersData",
      name: "producers",
      value: "{}",
    },
    serviceHistory: {
      id: "serviceHistory",
      label: "Service History(CATS) Api",
      apiRequestName: "fetchCatsEvents",
      name: "serviceHistory",
      value: "{}",
    },
    policyNumberSearch: {
      id: "policyNumberSearch",
      label: "Search By Policy Number Api",
      apiRequestName: "fetchDataByPolicyNumber",
      name: "policyNumberSearch",
      value: "{}",
    },
    agentSearch: {
      id: "agentSearch",
      label: "Search by Agent Api",
      apiRequestName: "fetchAgentSearchResults",
      name: "agentSearch",
      value: "{}",
    },
    agentProfile: {
      id: "agentProfile",
      label: "Search Agent profile",
      apiRequestName: "fetchAgentProfile",
      name: "agentProfile",
      value: "{}",
    },
    cryptorEncrypt: {
      id: "cryptorEncrypt",
      label: "Encrypt with cryptor",
      apiRequestName: "fetchEncryptedDataUsingCryptor",
      name: "cryptorEncrypt",
      value: "{}",
    },
    addressEligibility: {
      id: "addressEligibility",
      label: "Get Address Eligibility by Cust Id",
      apiRequestName: "fetchCustomerEligibilityData",
      name: "addressEligibility",
      value: "{}",
    },
    valueRecalcRequest: {
      id: "valueRecalcRequest",
      label: "SOA Recalculated VNTGKC Values",
      apiRequestName: "fetchRecalculatedVNTGKCValues",
      name: "valueRecalcRequest",
      value: "{}",
    },
    soaBillingArrangement: {
      id: "soaBillingArrangement",
      label: "SOA Billing Arrangement",
      apiRequestName: "fetchSoaBillingArrangement",
      name: "soaBillingArrangement",
      value: "{}",
    },
    cdaValues: {
      id: "cdaValues",
      label: "SOA CDA Values",
      apiRequestName: "fetchCdaValues",
      name: "cdaValues",
      value: "{}",
    },
    fundTransferEligibility: {
      id: "fundTransferEligibility",
      label: "Fund Transfer Eligibility",
      apiRequestName: "fetchFundTransferEligibility",
      name: "fundTransferEligibility",
      value: "{}",
    },
    deathClaimEligibility: {
      id: "deathClaimEligibility",
      label: "Death Claim Eligibility",
      apiRequestName: "fetchDeathClaimEligibilityData",
      name: "deathClaimEligibility",
      value: "{}",
    },
    agentUnseenNotifications: {
      id: "agentUnseenNotifications",
      label: "Agent Unseen Notifications",
      apiRequestName: "fetchAgentUnseenNotificationsCount",
      name: "agentUnseenNotifications",
      value: "{}",
    },
    agentNotifications: {
      id: "agentNotifications",
      label: "Agent Notifications",
      apiRequestName: "fetchAgentNotifications",
      name: "agentNotifications",
      value: "{}",
    },
    catsByWorkEventId: {
      id: "catsByWorkEventId",
      label: "Cats by Work Event Id",
      apiRequestName: "fetchCatsByWorkEventId",
      name: "catsByWorkEventId",
      value: "{}",
    },
    digitalRegistrationUserEligibility: {
      id: "digitalRegistrationUserEligibility",
      label: "User eligibility check api for digital registration",
      apiRequestName: "fetchUserDigitalRegistrationEligibility",
      name: "digitalRegistrationEligibility",
      value: "{}",
    },
    statementPreferences: {
      id: "statementPreferences",
      label: "Agreement Statement Preferences",
      apiRequestName: "fetchStatementPreferences",
      name: "statementPreferences",
      value: "{}",
    },
  },
  isOn: true,
  isVisible: true,
};

// eslint-disable-next-line default-param-last
const reducer = produce((state = JSON.parse(initialDevModeState), action) => {
  const { type } = action;
  // add `, ...props` to descructured action object and uncomment the next line
  // console.log(`type: `, type, props);
  switch (type) {
    case types.NEW_SAVE:
      return {
        ...state,
        api: {
          ...state.api,
          [action.formField.name]: {
            ...state.api[action.formField.name],
            value: action.newValue,
          },
        },
      };
    case types.TURN_ON:
      return {
        ...state,
        isOn: true,
      };
    case types.TURN_OFF:
      return {
        ...state,
        isOn: false,
      };
    default:
      return state;
  }
});

export { types, initialDevModeState, reducer };
