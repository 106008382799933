import React from "react";
import CheckboxForm from "../../common/forms/checkboxForm";
import IsTruthy from "../../common/logic/isTruthy";
import { useQuestionAndDisclosureProps } from "../hooks";
import Question from "./question";

const QuestionsAndDisclosures = (props) => {
  const { setPage1Error } = props;
  const { formData, questionsText } = useQuestionAndDisclosureProps(props);
  const questionKeys = [
    "question1",
    "question2",
    "question3",
    "question4",
    "question5",
  ];

  const displayAcknowledgement = (questionProps) => {
    const { data, isAcknowledge = false } = questionProps;
    let DISPLAY_ACKNOWLEDGEMENT = true;
    if (
      (data[`question${5}no`].value === true &&
        data[`question${5}yes`].value === false) ||
      (data[`question${5}no`].value === false &&
        data[`question${5}yes`].value === true &&
        isAcknowledge)
    ) {
      DISPLAY_ACKNOWLEDGEMENT = true;
    } else if (
      data[`question${5}no`].value === false &&
      data[`question${5}yes`].value === false &&
      isAcknowledge
    ) {
      DISPLAY_ACKNOWLEDGEMENT = false;
    }
    return DISPLAY_ACKNOWLEDGEMENT;
  };

  const questions = questionKeys.map((key, i) => {
    const { question, note } = questionsText[i];
    const Markup = (
      <Question
        question={question}
        note={note}
        yesField={formData[`${key}yes`]}
        noField={formData[`${key}no`]}
        key={key}
        questionNumber={i + 1}
        setPage1Error={setPage1Error}
      />
    );

    if (i === 0) {
      return Markup;
    }
    if (i === 1) {
      return (
        <IsTruthy
          key={key}
          value={
            formData[`question${i}yes`].value ||
            formData[`question${i}no`].value
          }
        >
          {Markup}
        </IsTruthy>
      );
    }
    return (
      <IsTruthy
        key={key}
        value={
          formData[`question${i}yes`].value || formData[`question${i}no`].value
        }
      >
        {Markup}
      </IsTruthy>
    );
  });

  return (
    <>
      <h1>Questions & Disclosures</h1>
      <p className="mm-sub-copy">* = Required Field</p>
      <div className="mm-spacing">
        <p className="mm-sub-copy">
          Based on your response and input throughout this form, you may be
          required to sign a loan form.
        </p>
      </div>
      <div className="mm-spacing--minor">
        <p className="h3">Ownership & Disbursement Questions</p>
        {questions}
      </div>
      <IsTruthy
        value={{ data: formData, isAcknowledge: true }}
        validationFunction={displayAcknowledgement}
      >
        <div className="mm-spacing">
          <p className="h3">Acknowledgement</p>
          <CheckboxForm formField={formData.authAcknowledgement} />
          <CheckboxForm formField={formData.disclosureAcknowledgement} />
        </div>
      </IsTruthy>
    </>
  );
};

export default React.memo(QuestionsAndDisclosures);
