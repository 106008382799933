import React, { useReducer, useContext, createContext, useMemo } from "react";
import { reducer, types, initialUserState } from "../reducers/userReducer";

const UserContext = createContext(null);

// TODO: add more documentation
const UserProvider = (props) => {
  const { children, initialState = initialUserState } = props;

  const [state, dispatch] = useReducer(reducer, initialState);

  const values = useMemo(
    () => ({
      user: state,
      userDispatch: dispatch,
    }),
    [state, dispatch]
  );

  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

const useUserState = () => useContext(UserContext);

export { UserProvider, useUserState, types };
