import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { setTitle } from "../../../../utils/dom";
import { useFundsProps } from "./hooks";
import Table from "../../../common/table";
import LoadingSection, {
  errorTypes,
  loadingTypes,
} from "../../../common/loading";
import { mecIndicatorText } from "../../types/life/hooks";
import IsTruthy from "../../../common/logic/isTruthy";
import IsFalsy from "../../../common/logic/isFalsy";
import { compareStrings } from "../../../../utils/string";
import FourHundredFourError from "../../../common/errors/404";
import { useHidePageFromUser } from "../../../common/featureFlags/hooks";
import FiveHundredOneError from "../../../common/errors/501";
import { tealiumPageViewEvent } from "../../../../utils/tealium";
import BackToPolicy from "../../../common/backToPolicy";

const Funds = () => {
  setTitle("ServiceNet - Funds");
  const { agreementKey } = useParams();
  const {
    requests: { agreementRequest },
    policyId,
    asOfDate,
    insuredName,
    isUnauthorized,
    table: { columns, rows },
    email,
    validRequest,
    show404errorPageForAnnDiAndHap,
  } = useFundsProps();
  const {
    mecIndicator,
    agreementKeyAdmin,
    productTypeCode,
    majorProductCode,
    minorProductCode,
  } = agreementRequest.data;
  const hideFundsPage = useHidePageFromUser({
    pageName: "fundPage",
    agreementKeyAdmin,
    productTypeCode,
    majorProductCode,
    minorProductCode,
  });
  useEffect(() => {
    tealiumPageViewEvent({
      page: "Funds",
      category: agreementKey,
      custId: email,
    });
  }, []);
  if (isUnauthorized) {
    return <FiveHundredOneError />;
  }

  return (
    <LoadingSection
      request={agreementRequest}
      loadingType={loadingTypes.PARAGRAPH}
      errorType={errorTypes._500}
    >
      <IsFalsy value={!hideFundsPage && validRequest}>
        <FourHundredFourError />
      </IsFalsy>
      <IsTruthy value={show404errorPageForAnnDiAndHap}>
        <FourHundredFourError />
      </IsTruthy>
      <IsTruthy
        value={
          !hideFundsPage && validRequest && !show404errorPageForAnnDiAndHap
        }
      >
        <BackToPolicy
          agreementData={agreementRequest.data}
          jumpLink="policyDetails"
        />
        <h1>Funds</h1>

        <p className="mm-sub-copy">As of {asOfDate}</p>
        <div className="row mm-spacing">
          <div className="col-md-6 col-sm-12">
            <dl className="mm-key-value-pair--summation">
              <dt id="insured_name_lbl">Insured Name</dt>
              <dd id="insured_name_value">{insuredName}</dd>
              <dt id="policy_id_lbl">Policy Number</dt>
              <dd id="policy_id_value">{policyId}</dd>
            </dl>
          </div>
        </div>
        <IsTruthy
          value={mecIndicator}
          validationFunction={(val) => compareStrings(val, "Yes")}
        >
          <div
            id="funds_mec_indicator_text"
            className="alert alert-info mm-spacing"
            role="alert"
          >
            {mecIndicatorText}
          </div>
        </IsTruthy>
        <div className="row">
          <div className="col-sm">
            <Table
              columns={columns}
              rows={rows}
              defaultSortColumn="allocation"
              defaultSortDirection="descending"
              showPagination
              showSearchBar
            />
          </div>
        </div>
      </IsTruthy>
    </LoadingSection>
  );
};

export default React.memo(Funds);
