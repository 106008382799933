import React from "react";
import { fireTealiumAndFollowLink } from "../../../../utils/tealium";
import LoadingSection, {
  errorTypes,
  loadingTypes,
} from "../../../common/loading";
import IsFalsy from "../../../common/logic/isFalsy";
import IsTruthy from "../../../common/logic/isTruthy";
import Table from "../../../common/table";
import { useFinancialHistorySectionProps } from "./hooks";
import { NO_FINANCIAL_HISTORY_ALERT_TEXTS } from "../../../../constants/ui";

const FinancialHistorySection = (props) => {
  const {
    table: { rows, columns },
    financialHistoryRequest,
    shouldShowFinancialHistoryExtendedPageLink,
    agreementKey,
    email,
    policyId,
    defaultSortField,
    contactUsNumber,
    contactUsTime,
    isHAPPolicy,
    isAnnuityProduct,
  } = useFinancialHistorySectionProps(props);
  return (
    <div id="financial_History_Section">
      <h2 aria-label="Financial History">Financial History</h2>

      <LoadingSection
        request={financialHistoryRequest}
        loadingType={loadingTypes.PARAGRAPH}
        errorType={errorTypes.CHILDREN}
      >
        <div className="mm-spacing--minor">
          <IsTruthy value={rows.length > 0}>
            <div className="row">
              <div className="col-sm">
                <Table
                  columns={columns}
                  rows={rows}
                  id="financial_history"
                  showPagination={false}
                  defaultSortColumn={defaultSortField}
                />
                <IsTruthy value={shouldShowFinancialHistoryExtendedPageLink}>
                  <p id="see_financial_History_lbl">
                    For additional financial information,{" "}
                    <button
                      className="btn btn-inline"
                      id="see_financial_History_link"
                      type="button"
                      onClick={() =>
                        fireTealiumAndFollowLink({
                          url: `/policyDetails/${encodeURIComponent(
                            agreementKey
                          )}/financialHistory`,
                          action: "See Financial History link clicked",
                          category: "PDP",
                          value: policyId,
                          custId: email,
                        })
                      }
                    >
                      see Financial History.
                    </button>
                  </p>
                </IsTruthy>
              </div>
            </div>
          </IsTruthy>
          <IsFalsy value={rows.length > 0}>
            <IsTruthy value={isHAPPolicy}>
              <div
                className="alert alert-info"
                role="alert"
                id="no_coverpath_financial_history_available_alert"
              >
                Financial History is not supported for this product. A Coverpath
                customer service representative can assist you with any
                questions. Call <a href="tel:+1-866-957-5347"> 866-957-5347</a>,
                Mon - Fri 8 a.m. - 6 p.m. ET.
              </div>
            </IsTruthy>
            <IsFalsy value={isHAPPolicy}>
              <IsTruthy value={isAnnuityProduct}>
                <div
                  className="alert alert-info"
                  role="alert"
                  id="no_annuity_financial_history_available_alert"
                >
                  {NO_FINANCIAL_HISTORY_ALERT_TEXTS.contract}
                  <a href={`tel:+${contactUsNumber}`}>
                    {contactUsNumber}
                  </a>, {contactUsTime}.
                </div>
              </IsTruthy>
              <IsFalsy value={isAnnuityProduct}>
                <div
                  className="alert alert-info"
                  role="alert"
                  id="no_non_annuity_financial_history_available_alert"
                >
                  {NO_FINANCIAL_HISTORY_ALERT_TEXTS.policy}
                  <a href={`tel:+${contactUsNumber}`}>
                    {contactUsNumber}
                  </a>, {contactUsTime}.
                </div>
              </IsFalsy>
            </IsFalsy>
          </IsFalsy>
        </div>
      </LoadingSection>
    </div>
  );
};

export default React.memo(FinancialHistorySection);
