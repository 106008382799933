// eslint-disable-next-line import/prefer-default-export
export const processLoanInformation = (props) => {
  const apiData = props?.response?.data;

  const agreement = apiData?.agreement?.[0] || {
    loan: [{}],
    loanEligibility: [{}],
  };
  const agreementCustomers =
    apiData?.agreementRelationships?.[0].agreementCustomers || [];
  const { qualifiedIndicator, taxWithholdingIndicator, variableLifeIndicator } =
    agreement;
  const loan = agreement?.loan?.[0];
  const loanEligibility = apiData?.loanEligibility?.[0] || {};

  const {
    currentInterestRate = "",
    interestRateType = "",
    maxLoanAmount = "",
    maxLoanQuoteEffDate = "",
    loanPayoffAmount = false,
  } = loan;
  const { isEligible = "", formRequired = "" } = loanEligibility;

  return {
    qualifiedIndicator,
    taxWithholdingIndicator,
    variableLifeIndicator,
    currentInterestRate,
    interestRateType,
    maxLoanAmount,
    maxLoanQuoteEffDate,
    loanPayoffAmount,
    isEligible,
    formRequired,
    agreementCustomers,
  };
};
