import React from "react";
import { DatePicker } from "react-blueprint-library";
import { useValueRecalculationAsOfDate } from "./hooks";
import IsTruthy from "../../../common/logic/isTruthy";

const SelectAsOfDate = (props) => {
  const { showRecalcValues, showLoanPayOffValues } = props;
  const {
    datePickerMaxDate,
    datePickerMinDate,
    selectedDate,
    displayDatePicker,
    dateHandler,
    datePickerLabel,
    setAsOfDate,
    className,
    buttonText,
    fireVNTGKCRecalcTealium,
  } = useValueRecalculationAsOfDate(props);

  return (
    <IsTruthy value={displayDatePicker}>
      <div className="row">
        <div className="col-md-4 col-lg-5 hide-print">
          <DatePicker
            id="value-recalc-date-picker"
            label={datePickerLabel}
            selected={selectedDate}
            onChange={dateHandler}
            minDate={datePickerMinDate}
            maxDate={datePickerMaxDate}
          />
        </div>
        <div className="col-md-3">
          <div className="form-group button-label-offset">
            <button
              type="button"
              className={className}
              onClick={() => {
                setAsOfDate(selectedDate);
                fireVNTGKCRecalcTealium();
              }}
            >
              {buttonText}
            </button>
          </div>
        </div>
        <IsTruthy value={showRecalcValues}>
          <div className="col-md-4">
            <div className="form-group button-label-offset">
              <button
                type="button"
                className="btn btn-primary hide-print"
                onClick={() => {
                  dateHandler(datePickerMaxDate);
                  setAsOfDate(datePickerMaxDate);
                }}
              >
                RESET TO DEFAULT DATE
              </button>
            </div>
          </div>
        </IsTruthy>
        <IsTruthy value={showLoanPayOffValues}>
          <div className="col-md-4">
            <div className="form-group button-label-offset">
              <button
                type="button"
                className="btn btn-primary hide-print"
                onClick={() => {
                  dateHandler(datePickerMinDate);
                  setAsOfDate(datePickerMinDate);
                }}
              >
                RESET TO DEFAULT DATE
              </button>
            </div>
          </div>
        </IsTruthy>
      </div>
    </IsTruthy>
  );
};

export default SelectAsOfDate;
