import React from "react";
import LabelWithHelpModal from "../../common/forms/labelWithHelpModal";

const OwnerChangeModalBody = () => (
  <div className="modal-body">
    <hr />
    <h5 id="directLink_OwnerChange_ModalTitle">Owner Change</h5>
    <p>
      The following can be done on the <strong>website</strong>:
    </p>
    <ul>
      <li>
        Customer can provide information so an Owner Change form can be
        partially prefilled to print/sign (PDF)
      </li>
    </ul>
    <div className="mm-spacing--minor" />
    <h5 id="directLink_OwnerChange_ModalQuickTips">Quick Tips</h5>
    <ul>
      <li>
        Current and new owner signatures required, E-signature is not available.
      </li>
      <li>Available for individual to individual ownership changes only</li>
    </ul>
  </div>
);

const OwnerChangeModal = () => (
  <LabelWithHelpModal
    text="Owner Change"
    modalTitle="Owner Change"
    modalId="ownerChange"
    modalBodyComponent={OwnerChangeModalBody}
  />
);

export default OwnerChangeModal;
