import React, { useEffect, useState } from "react";
import { isDefined } from "../../utils/evaluate";
import IsTruthy from "../common/logic/isTruthy";
import IsFalsy from "../common/logic/isFalsy";
import { useCanContinue, happyPathValuesSelected } from "./hooks";
import { useFormContext } from "../../contexts/form";
import { useCancelModal } from "../common/popup/cancel";

const ModalControl = (props) => {
  const {
    pageNumber,
    onSubmit,
    page2error,
    page3error,
    shouldDisableSubmit,
    showLoadingIcon,
  } = props;
  const { onContinue, error } = useCanContinue(props);
  const { openCancelModal } = useCancelModal();
  const { formData } = useFormContext();

  const showPage1ContinueButton = happyPathValuesSelected({ formData });
  const showPage1CancelButton = showPage1ContinueButton;
  const [className, setClassName] = useState("btn btn-primary");
  const [buttonText, setButtonText] = useState("Submit");
  useEffect(() => {
    if (showLoadingIcon) {
      setClassName("btn btn-primary-loading");
      setButtonText("");
    } else {
      setClassName("btn btn-primary");
      setButtonText("Submit");
    }
  }, [showLoadingIcon]);

  const Page1buttons = (
    <IsTruthy value={pageNumber === 1}>
      <IsTruthy value={showPage1ContinueButton}>
        <button
          id="initiatenewloan_continue_button"
          onClick={onContinue}
          type="button"
          className="btn btn-primary"
        >
          continue
        </button>
      </IsTruthy>
      <IsTruthy value={showPage1CancelButton}>
        <button
          id="initiatenewloan_cancel_button"
          type="button"
          className="btn btn-link"
          onClick={openCancelModal}
        >
          Cancel
        </button>
      </IsTruthy>
      <IsFalsy value={showPage1CancelButton}>
        <button
          id="initiatenewloan_cancel_button"
          type="button"
          className="btn btn-secondary"
          onClick={openCancelModal}
        >
          Cancel
        </button>
      </IsFalsy>
    </IsTruthy>
  );

  return (
    <div className="mm-spacing">
      <IsTruthy value={error} validationFunction={isDefined}>
        <div
          className="alert alert-danger mm-spacing--minor"
          role="alert"
          aria-label={error}
          id={error?.id}
        >
          {error?.text}
        </div>
      </IsTruthy>
      <div className="mm-spacing--minor">
        {Page1buttons}
        <IsTruthy
          value={pageNumber}
          validationFunction={(pn) => pn < 5 && pn > 1}
        >
          <button
            id="continueButton"
            onClick={onContinue}
            type="button"
            className="btn btn-primary"
            disabled={page2error || page3error}
          >
            continue
          </button>
        </IsTruthy>
        <IsTruthy value={pageNumber} validationFunction={(pn) => pn === 5}>
          <button
            id="initiatenewloan_submit_button"
            type="submit"
            className={className}
            disabled={shouldDisableSubmit || showLoadingIcon}
            onClick={(e) => {
              e.preventDefault();
              onSubmit(formData);
            }}
          >
            {buttonText}
          </button>
          <button
            id="initiatenewloan_cancel_button"
            type="button"
            className="btn btn-link"
            onClick={openCancelModal}
          >
            Cancel
          </button>
        </IsTruthy>
        <IsFalsy
          value={pageNumber}
          validationFunction={(pn) => pn >= 5 || pn <= 1}
        >
          <button
            id="initiatenewloan_cancel_button"
            type="button"
            className="btn btn-link"
            onClick={openCancelModal}
          >
            Cancel
          </button>
        </IsFalsy>
      </div>
    </div>
  );
};

export default ModalControl;
