import React from "react";
import { DatePicker } from "react-blueprint-library";
import IsTruthy from "../logic/isTruthy";
import Errors from "../forms/error";

const InputDatePicker = (props) => {
  const {
    id,
    datePickerMinDate,
    datePickerMaxDate,
    selectedDate,
    dateHandler,
    invalidDate,
    errorId,
    errorMessage,
    label,
  } = props;

  return (
    <>
      <DatePicker
        id={id}
        monthsShown="2"
        selected={selectedDate}
        onChange={dateHandler}
        minDate={datePickerMinDate}
        maxDate={datePickerMaxDate}
        label={label}
      />
      <IsTruthy value={invalidDate}>
        <Errors
          formField={{
            id: errorId,
            errors: [
              {
                message: errorMessage,
              },
            ],
            postpone: false,
            cssClass: "input-group-append invalid-feedback",
          }}
        />
      </IsTruthy>
    </>
  );
};
export default InputDatePicker;
