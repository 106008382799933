import React from "react";
import { FormProvider } from "../../../contexts/form";

const Form = (props) => {
  const { fields, children, onSubmit } = props;
  return (
    <FormProvider fields={fields}>
      <form onSubmit={onSubmit}>{children}</form>
    </FormProvider>
  );
};

export default Form;
