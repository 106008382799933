import React from "react";
import { InformationPopover } from "../../../common/popovers/informationPopover";
import { TOTAL_WITHDRAWAL_AMOUNT_TOOLTIP_TEXT } from "../../../../constants/ui";
import { useGetRecurringPaymentsData } from "./hooks";
import IsFalsy from "../../../common/logic/isFalsy";
import IsTruthy from "../../../common/logic/isTruthy";
import { compareStrings } from "../../../../utils/string";

const RecurringPayment = (props) => {
  const { paymentAppliedTo } = props;

  const {
    accountNumber,
    accountType,
    bankAccountHolder,
    bankName,
    routingNumber,
    totalWithdrawalAmount,
    dateOfWithdrawal,
    billedPremium,
    billingModeDesc,
  } = useGetRecurringPaymentsData(props);

  return (
    <>
      <hr />
      <div className="row">
        <div className="col-12 col-md-6">
          <dl className="mm-key-value-pair">
            <dt id="autopay_type_lbl">
              Autopay Type <sup className="print-only">1</sup>
              <InformationPopover
                popoverId="autopay_type_tooltip"
                text="<b>Pre-Authorized Check (PAC)</b>
                    <ul>
                    <li>Available for Monthly billing frequency and Quarterly for DI only</li>
                    <li>Draft dates chosen by depositor (1st-28th)</li> 
                    <li>Premium only. Interest billed separately</li>
                    </ul>
                    <b>Recurring Payments</b> 
                    <ul>
                    <li>Available for Quarterly, Semi-Annual & Annual billing frequencies</li>
                    <li>Always withdrawn on policy Paid To Date</li>
                    <li>Premium/Interest, Loan Interest only, Loan Principal only</li>
                    <li>Withdrawal amount is determined by the amount billed </li>
                    <li>Can only be set up/canceled via <a href=https://www.massmutual.com>MassMutual.com</a></li>
                    </ul>"
              />
            </dt>
            <dd id="autopay_type_value">Recurring Payments</dd>
            <dt id="depositor_name_lbl">Depositor Name</dt>
            <dd id="depositor_name_value" aria-label={bankAccountHolder}>
              {bankAccountHolder}
            </dd>
            <dt id="total_withdrawal_amount_lbl">
              Total Withdrawal Amount <sup className="print-only">2</sup>
              <InformationPopover
                popoverId="total_withdrawal_amount"
                text={TOTAL_WITHDRAWAL_AMOUNT_TOOLTIP_TEXT}
              />
            </dt>
            <dd id="total_withdrawal_amount_value">{totalWithdrawalAmount}</dd>
            <dt id="bank_name_lbl" aria-label={bankName}>
              Bank Name
            </dt>
            <dd id="bank_name_value">{bankName}</dd>
            <dt id="bank_account_number_lbl" aria-label={accountNumber}>
              Bank Account Number
            </dt>
            <dd id="bank_name_value">{accountNumber}</dd>
          </dl>
        </div>
        <div className="col-12 col-md-6">
          <dl className="mm-key-value-pair">
            <dt id="payment_applied_to_lbl" aria-label={paymentAppliedTo}>
              Payment Applied To
            </dt>
            <dd id="payment_applied_to_value">{paymentAppliedTo}</dd>
            <IsTruthy
              value={compareStrings(paymentAppliedTo, "Loan Principal")}
            >
              <dt id="frequency_lbl" aria-label={billingModeDesc}>
                Frequency
              </dt>
              <dd id="frequency_value">{billingModeDesc}</dd>
            </IsTruthy>
            <dt id="date_of_withdrawal_lbl" aria-label={dateOfWithdrawal}>
              Date of Withdrawal
            </dt>
            <dd id="date_of_withdrawal_value">{dateOfWithdrawal}</dd>
            <IsFalsy value={compareStrings(paymentAppliedTo, "Loan Principal")}>
              <dt id="billed_amount_lbl" aria-label={billedPremium}>
                Billed Amount
              </dt>
              <dd id="billed_amount_value">{billedPremium}</dd>
            </IsFalsy>

            <dt id="routing_number_lbl" aria-label={routingNumber}>
              Routing Number
            </dt>
            <dd id="routing_number_value">{routingNumber}</dd>
            <dt id="account_type_lbl" aria-label={accountType}>
              Account Type
            </dt>
            <dd id="account_type_value">{accountType}</dd>
          </dl>
        </div>
      </div>
      <ol className="print-only" id="print_preview_tooltips">
        <li id="print_preview_autopay_type_tooltip">
          <div>
            <b>Pre-Authorized Check (PAC)</b>: Available for Monthly billing
            frequency and Quarterly for DI only, Draft dates chosen by depositor
            (1st-28th), Premium only. Interest billed separately.{" "}
          </div>
          <div>
            <b>Recurring Payments</b>: Available for Quarterly, Semi-Annual &
            Annual billing frequencies, Always withdrawn on policy Paid To Date,
            Premium/Interest, Loan Interest only, Loan Principal only,
            Withdrawal amount is determined by the amount billed, Can only be
            set up/canceled via https://www.massmutual.com{" "}
          </div>
        </li>
        <li id="print_preview_totaldraftamount">
          {TOTAL_WITHDRAWAL_AMOUNT_TOOLTIP_TEXT}
        </li>
      </ol>
    </>
  );
};

export default RecurringPayment;
