import React from "react";
import IsTruthy from "../../../common/logic/isTruthy";
import IsFalsy from "../../../common/logic/isFalsy";
import Table from "../../../common/table";
import { useDocumentsProps } from "./hooks";
import LoadingSection, {
  errorTypes,
  loadingTypes,
} from "../../../common/loading";
import { fireTealiumAndFollowLink } from "../../../../utils/tealium";
import { NO_DOCUMENTS_ALERT_TEXTS } from "../../../../constants/ui";

const Documents = (props) => {
  const { id, agreementKey, policyId, email, statementPreferencesRequest } =
    props;

  const {
    requests: { documentRequest },
    table: { rows, columns },
    customFunctions,
    isHap,
    isAnnuityProduct,
    showExtendedPageLink,
    contactUsNumber,
    contactUsTime,
    shouldDisplayDeliveryPreference,
    deliveryPreferenceDisplay,
  } = useDocumentsProps(props);

  return (
    <div id="documents_section" className="mm-spacing">
      <h2 aria-label="Documents" id={id}>
        Documents
      </h2>
      <IsTruthy value={shouldDisplayDeliveryPreference}>
        <div className="mm-spacing--minor">
          <h3 aria-label="delivery_preference" id="delivery_preference_label">
            Delivery Preference
          </h3>
          <LoadingSection
            request={statementPreferencesRequest}
            loadingType={loadingTypes.PARAGRAPH}
            errorType={errorTypes.SECTION}
          >
            <hr />
            <div className="col-12 col-md-6">
              <dl className="mm-key-value-pair">
                <dt id="statements_lbl">Statements</dt>
                <dd id="statements_value">{deliveryPreferenceDisplay}</dd>
              </dl>
            </div>
            <hr />
          </LoadingSection>
        </div>
      </IsTruthy>
      <div className="mm-spacing--minor">
        <IsTruthy value={shouldDisplayDeliveryPreference}>
          <h3 aria-label="document_history" id="document_history">
            Document History
          </h3>{" "}
        </IsTruthy>
        <LoadingSection
          request={documentRequest}
          loadingType={loadingTypes.PARAGRAPH}
          errorType={errorTypes.SECTION}
        >
          <IsTruthy value={rows.length > 0}>
            <Table
              id="documents"
              columns={columns}
              rows={rows}
              showPagination={false}
              customFunctions={customFunctions}
            />
            <IsTruthy value={showExtendedPageLink}>
              <p>
                For all documents,{" "}
                <button
                  className="btn btn-inline"
                  id="view_all_documents_link"
                  type="button"
                  onClick={() =>
                    fireTealiumAndFollowLink({
                      url: `/policyDetails/${encodeURIComponent(
                        agreementKey
                      )}/documents`,
                      action: "View Documents link clicked",
                      category: "PDP",
                      value: policyId,
                      custId: email,
                    })
                  }
                >
                  view Documents.
                </button>
              </p>
            </IsTruthy>
          </IsTruthy>
          <IsFalsy value={rows.length > 0}>
            <IsTruthy value={isHap}>
              <div
                className="alert alert-info"
                role="alert"
                id="no_coverpath_documents_available_alert"
              >
                There are no documents available online for this policy. If you
                have any questions, contact our Coverpath Customer Service at{" "}
                <a href="tel:+1-866-957-5347">866-957-5347</a>, 8:00 AM - 6:00
                PM ET, Monday through Friday.
              </div>
            </IsTruthy>
            <IsFalsy value={isHap}>
              <IsTruthy value={isAnnuityProduct}>
                <div
                  className="alert alert-info"
                  role="alert"
                  id="no_annuity_documents_available_alert"
                >
                  {NO_DOCUMENTS_ALERT_TEXTS.contract}
                  <a href={`tel:+${contactUsNumber}`}>
                    {contactUsNumber}
                  </a>, {contactUsTime}
                </div>
              </IsTruthy>
              <IsFalsy value={isAnnuityProduct}>
                <div
                  className="alert alert-info"
                  role="alert"
                  id="no_non_annuity_documents_available_alert"
                >
                  {NO_DOCUMENTS_ALERT_TEXTS.policy}
                  <a href={`tel:+${contactUsNumber}`}>
                    {contactUsNumber}
                  </a>, {contactUsTime}
                </div>
              </IsFalsy>
            </IsFalsy>
          </IsFalsy>
        </LoadingSection>
      </div>
    </div>
  );
};

export default React.memo(Documents);
