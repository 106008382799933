import React from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { isAnnuityContract } from "../../../utils/policies";
import { isDefined } from "../../../utils/evaluate";

const BackToPolicy = (props) => {
  const { agreementData, jumpLink } = props;
  const { agreementKey, lineOfBusinessCode } = agreementData || {};
  const { agreementKey: agreementKeyFromUrl } = useParams();
  const backToPolicyDisplayText = isAnnuityContract(lineOfBusinessCode)
    ? "Back to Contract"
    : "Back to Policy";
  const jumpLinkHash = isDefined(jumpLink) ? `#${jumpLink}` : "";
  return (
    <a
      id="go_back_link"
      className="btn btn-link"
      href={`/policyDetails/${encodeURIComponent(
        agreementKey || agreementKeyFromUrl
      )}${jumpLinkHash}`}
    >
      <span className="icon-arrow-circle-spin-left" /> {backToPolicyDisplayText}
    </a>
  );
};
export default BackToPolicy;
