import { stringIsEmptyOrNullish } from "./string";

const countryCodeMap = [
  { Code: "AF", Name: "Afghanistan", ThreeLetterCode: "AFG" },
  { Code: "AX", Name: "\u00c5land Islands", ThreeLetterCode: "" },
  { Code: "AL", Name: "Albania", ThreeLetterCode: "ALB" },
  { Code: "DZ", Name: "Algeria", ThreeLetterCode: "DZA" },
  { Code: "AS", Name: "American Samoa", ThreeLetterCode: "" },
  { Code: "AD", Name: "Andorra", ThreeLetterCode: "AND" },
  { Code: "AO", Name: "Angola", ThreeLetterCode: "AGO" },
  { Code: "AI", Name: "Anguilla", ThreeLetterCode: "AIA" },
  { Code: "AQ", Name: "Antarctica", ThreeLetterCode: "ATR" },
  { Code: "AG", Name: "Antigua and Barbuda", ThreeLetterCode: "ATG" },
  { Code: "AR", Name: "Argentina", ThreeLetterCode: "ARG" },
  { Code: "AM", Name: "Armenia", ThreeLetterCode: "ARM" },
  { Code: "AW", Name: "Aruba", ThreeLetterCode: "ABW" },
  { Code: "AU", Name: "Australia", ThreeLetterCode: "AUS" },
  { Code: "AT", Name: "Austria", ThreeLetterCode: "AUT" },
  { Code: "AZ", Name: "Azerbaijan", ThreeLetterCode: "AZE" },
  { Code: "BS", Name: "Bahamas", ThreeLetterCode: "BHS" },
  { Code: "BH", Name: "Bahrain", ThreeLetterCode: "BHR" },
  { Code: "BD", Name: "Bangladesh", ThreeLetterCode: "BGD" },
  { Code: "BB", Name: "Barbados", ThreeLetterCode: "BRB" },
  { Code: "BY", Name: "Belarus", ThreeLetterCode: "BLR" },
  { Code: "BE", Name: "Belgium", ThreeLetterCode: "BEL" },
  { Code: "BZ", Name: "Belize", ThreeLetterCode: "BLZ" },
  { Code: "BJ", Name: "Benin", ThreeLetterCode: "BEN" },
  { Code: "BM", Name: "Bermuda", ThreeLetterCode: "BMU" },
  { Code: "BT", Name: "Bhutan", ThreeLetterCode: "BTN" },
  {
    Code: "BO",
    Name: "Bolivia, Plurinational State of",
    ThreeLetterCode: "BOL",
  },
  {
    Code: "BQ",
    Name: "Bonaire, Sint Eustatius and Saba",
    ThreeLetterCode: "BON",
  },
  { Code: "BA", Name: "Bosnia and Herzegovina", ThreeLetterCode: "BIH" },
  { Code: "BW", Name: "Botswana", ThreeLetterCode: "BWA" },
  { Code: "BV", Name: "Bouvet Island", ThreeLetterCode: "BOI" },
  { Code: "BR", Name: "Brazil", ThreeLetterCode: "BRA" },
  {
    Code: "IO",
    Name: "British Indian Ocean Territory",
    ThreeLetterCode: "BIT",
  },
  { Code: "BN", Name: "Brunei Darussalam", ThreeLetterCode: "BRN" },
  { Code: "BG", Name: "Bulgaria", ThreeLetterCode: "BGR" },
  { Code: "BF", Name: "Burkina Faso", ThreeLetterCode: "BFA" },
  { Code: "BI", Name: "Burundi", ThreeLetterCode: "BDI" },
  { Code: "KH", Name: "Cambodia", ThreeLetterCode: "KHM" },
  { Code: "CM", Name: "Cameroon", ThreeLetterCode: "CMR" },
  { Code: "CA", Name: "Canada", ThreeLetterCode: "CAN" },
  { Code: "CV", Name: "Cape Verde", ThreeLetterCode: "CPV" },
  { Code: "KY", Name: "Cayman Islands", ThreeLetterCode: "CYM" },
  { Code: "CF", Name: "Central African Republic", ThreeLetterCode: "CAF" },
  { Code: "TD", Name: "Chad", ThreeLetterCode: "TCD" },
  { Code: "CL", Name: "Chile", ThreeLetterCode: "CHL" },
  { Code: "CN", Name: "China", ThreeLetterCode: "CHN" },
  { Code: "CX", Name: "Christmas Island", ThreeLetterCode: "CHM" },
  { Code: "CC", Name: "Cocos (Keeling) Islands", ThreeLetterCode: "COC" },
  { Code: "CO", Name: "Colombia", ThreeLetterCode: "COL" },
  { Code: "KM", Name: "Comoros", ThreeLetterCode: "COM" },
  { Code: "CG", Name: "Congo", ThreeLetterCode: "COG" },
  {
    Code: "CD",
    Name: "Congo, the Democratic Republic of the",
    ThreeLetterCode: "ZAR",
  },
  { Code: "CK", Name: "Cook Islands", ThreeLetterCode: "COK" },
  { Code: "CR", Name: "Costa Rica", ThreeLetterCode: "CRI" },
  { Code: "CI", Name: "C\u00f4te d'Ivoire", ThreeLetterCode: "CIV" },
  { Code: "HR", Name: "Croatia", ThreeLetterCode: "HRV" },
  { Code: "CU", Name: "Cuba", ThreeLetterCode: "CUB" },
  { Code: "CW", Name: "Cura\u00e7ao", ThreeLetterCode: "CUR" },
  { Code: "CY", Name: "Cyprus", ThreeLetterCode: "CYP" },
  { Code: "CZ", Name: "Czech Republic", ThreeLetterCode: "CZE" },
  { Code: "DK", Name: "Denmark", ThreeLetterCode: "DNK" },
  { Code: "DJ", Name: "Djibouti", ThreeLetterCode: "DJI" },
  { Code: "DM", Name: "Dominica", ThreeLetterCode: "DMA" },
  { Code: "DO", Name: "Dominican Republic", ThreeLetterCode: "DOM" },
  { Code: "EC", Name: "Ecuador", ThreeLetterCode: "ECU" },
  { Code: "EG", Name: "Egypt", ThreeLetterCode: "EGY" },
  { Code: "SV", Name: "El Salvador", ThreeLetterCode: "SLV" },
  { Code: "GQ", Name: "Equatorial Guinea", ThreeLetterCode: "GNQ" },
  { Code: "ER", Name: "Eritrea", ThreeLetterCode: "ERI" },
  { Code: "EE", Name: "Estonia", ThreeLetterCode: "EST" },
  { Code: "ET", Name: "Ethiopia", ThreeLetterCode: "ETH" },
  { Code: "FK", Name: "Falkland Islands (Malvinas)", ThreeLetterCode: "FLK" },
  { Code: "FO", Name: "Faroe Islands", ThreeLetterCode: "FRO" },
  { Code: "FJ", Name: "Fiji", ThreeLetterCode: "FJI" },
  { Code: "FI", Name: "Finland", ThreeLetterCode: "FIN" },
  { Code: "FR", Name: "France", ThreeLetterCode: "FRA" },
  { Code: "GF", Name: "French Guiana", ThreeLetterCode: "GUF" },
  { Code: "PF", Name: "French Polynesia", ThreeLetterCode: "PYF" },
  { Code: "TF", Name: "French Southern Territories", ThreeLetterCode: "FSA" },
  { Code: "GA", Name: "Gabon", ThreeLetterCode: "GAB" },
  { Code: "GM", Name: "Gambia", ThreeLetterCode: "GMB" },
  { Code: "GE", Name: "Georgia", ThreeLetterCode: "GEO" },
  { Code: "DE", Name: "Germany", ThreeLetterCode: "DEU" },
  { Code: "GH", Name: "Ghana", ThreeLetterCode: "GHA" },
  { Code: "GI", Name: "Gibraltar", ThreeLetterCode: "GIB" },
  { Code: "GR", Name: "Greece", ThreeLetterCode: "GRC" },
  { Code: "GL", Name: "Greenland", ThreeLetterCode: "GRL" },
  { Code: "GD", Name: "Grenada", ThreeLetterCode: "GRD" },
  { Code: "GP", Name: "Guadeloupe", ThreeLetterCode: "GLP" },
  { Code: "GU", Name: "Guam", ThreeLetterCode: "" },
  { Code: "GT", Name: "Guatemala", ThreeLetterCode: "GTM" },
  { Code: "GG", Name: "Guernsey", ThreeLetterCode: "GSY" },
  { Code: "GN", Name: "Guinea", ThreeLetterCode: "GIN" },
  { Code: "GW", Name: "Guinea-Bissau", ThreeLetterCode: "GNB" },
  { Code: "GY", Name: "Guyana", ThreeLetterCode: "GUY" },
  { Code: "HT", Name: "Haiti", ThreeLetterCode: "HTI" },
  {
    Code: "HM",
    Name: "Heard Island and McDonald Islands",
    ThreeLetterCode: "HMI",
  },
  { Code: "VA", Name: "Holy See (Vatican City State)", ThreeLetterCode: "VAT" },
  { Code: "HN", Name: "Honduras", ThreeLetterCode: "HND" },
  { Code: "HK", Name: "Hong Kong", ThreeLetterCode: "HKG" },
  { Code: "HU", Name: "Hungary", ThreeLetterCode: "HUN" },
  { Code: "IS", Name: "Iceland", ThreeLetterCode: "ISL" },
  { Code: "IN", Name: "India", ThreeLetterCode: "IND" },
  { Code: "ID", Name: "Indonesia", ThreeLetterCode: "IDN" },
  { Code: "IR", Name: "Iran, Islamic Republic of", ThreeLetterCode: "IRN" },
  { Code: "IQ", Name: "Iraq", ThreeLetterCode: "IRQ" },
  { Code: "IE", Name: "Ireland", ThreeLetterCode: "IRL" },
  { Code: "IM", Name: "Isle of Man", ThreeLetterCode: "IOM" },
  { Code: "IL", Name: "Israel", ThreeLetterCode: "ISR" },
  { Code: "IT", Name: "Italy", ThreeLetterCode: "ITA" },
  { Code: "JM", Name: "Jamaica", ThreeLetterCode: "JAM" },
  { Code: "JP", Name: "Japan", ThreeLetterCode: "JPN" },
  { Code: "JE", Name: "Jersey", ThreeLetterCode: "JSY" },
  { Code: "JO", Name: "Jordan", ThreeLetterCode: "JOR" },
  { Code: "KZ", Name: "Kazakhstan", ThreeLetterCode: "KAZ" },
  { Code: "KE", Name: "Kenya", ThreeLetterCode: "KEN" },
  { Code: "KI", Name: "Kiribati", ThreeLetterCode: "" },
  {
    Code: "KP",
    Name: "Korea, Democratic People's Republic of",
    ThreeLetterCode: "PRK",
  },
  { Code: "KR", Name: "Korea, Republic of", ThreeLetterCode: "KOR" },
  { Code: "KW", Name: "Kuwait", ThreeLetterCode: "KWT" },
  { Code: "KG", Name: "Kyrgyzstan", ThreeLetterCode: "KGZ" },
  {
    Code: "LA",
    Name: "Lao People's Democratic Republic",
    ThreeLetterCode: "LAO",
  },
  { Code: "LV", Name: "Latvia", ThreeLetterCode: "LVA" },
  { Code: "LB", Name: "Lebanon", ThreeLetterCode: "LBN" },
  { Code: "LS", Name: "Lesotho", ThreeLetterCode: "LSO" },
  { Code: "LR", Name: "Liberia", ThreeLetterCode: "LBR" },
  { Code: "LY", Name: "Libya", ThreeLetterCode: "LBY" },
  { Code: "LI", Name: "Liechtenstein", ThreeLetterCode: "LIE" },
  { Code: "LT", Name: "Lithuania", ThreeLetterCode: "LTU" },
  { Code: "LU", Name: "Luxembourg", ThreeLetterCode: "LUX" },
  { Code: "MO", Name: "Macao", ThreeLetterCode: "MAX" },
  {
    Code: "MK",
    Name: "Macedonia, the Former Yugoslav Republic of",
    ThreeLetterCode: "MKD",
  },
  { Code: "MG", Name: "Madagascar", ThreeLetterCode: "MDG" },
  { Code: "MW", Name: "Malawi", ThreeLetterCode: "MWI" },
  { Code: "MY", Name: "Malaysia", ThreeLetterCode: "MYS" },
  { Code: "MV", Name: "Maldives", ThreeLetterCode: "MDV" },
  { Code: "ML", Name: "Mali", ThreeLetterCode: "MLI" },
  { Code: "MT", Name: "Malta", ThreeLetterCode: "MLT" },
  { Code: "MH", Name: "Marshall Islands", ThreeLetterCode: "" },
  { Code: "MQ", Name: "Martinique", ThreeLetterCode: "MTQ" },
  { Code: "MR", Name: "Mauritania", ThreeLetterCode: "MRT" },
  { Code: "MU", Name: "Mauritius", ThreeLetterCode: "MUS" },
  { Code: "YT", Name: "Mayotte", ThreeLetterCode: "MAY" },
  { Code: "MX", Name: "Mexico", ThreeLetterCode: "MX" },
  { Code: "FM", Name: "Micronesia, Federated States of", ThreeLetterCode: "" },
  { Code: "MD", Name: "Moldova, Republic of", ThreeLetterCode: "MDA" },
  { Code: "MC", Name: "Monaco", ThreeLetterCode: "MCO" },
  { Code: "MN", Name: "Mongolia", ThreeLetterCode: "MNG" },
  { Code: "ME", Name: "Montenegro", ThreeLetterCode: "MNT" },
  { Code: "MS", Name: "Montserrat", ThreeLetterCode: "MSR" },
  { Code: "MA", Name: "Morocco", ThreeLetterCode: "MAR" },
  { Code: "MZ", Name: "Mozambique", ThreeLetterCode: "MOZ" },
  { Code: "MM", Name: "Myanmar", ThreeLetterCode: "" },
  { Code: "NA", Name: "Namibia", ThreeLetterCode: "NAM" },
  { Code: "NR", Name: "Nauru", ThreeLetterCode: "NRU" },
  { Code: "NP", Name: "Nepal", ThreeLetterCode: "NPL" },
  { Code: "NL", Name: "Netherlands", ThreeLetterCode: "NLD" },
  { Code: "NC", Name: "New Caledonia", ThreeLetterCode: "NCL" },
  { Code: "NZ", Name: "New Zealand", ThreeLetterCode: "NZL" },
  { Code: "NI", Name: "Nicaragua", ThreeLetterCode: "NIC" },
  { Code: "NE", Name: "Niger", ThreeLetterCode: "NER" },
  { Code: "NG", Name: "Nigeria", ThreeLetterCode: "NGA" },
  { Code: "NU", Name: "Niue", ThreeLetterCode: "NIU" },
  { Code: "NF", Name: "Norfolk Island", ThreeLetterCode: "NFK" },
  { Code: "MP", Name: "Northern Mariana Islands", ThreeLetterCode: "" },
  { Code: "NO", Name: "Norway", ThreeLetterCode: "NOR" },
  { Code: "OM", Name: "Oman", ThreeLetterCode: "OMN" },
  { Code: "PK", Name: "Pakistan", ThreeLetterCode: "PAK" },
  { Code: "PW", Name: "Palau", ThreeLetterCode: "" },
  { Code: "PS", Name: "Palestine, State of", ThreeLetterCode: "PLS" },
  { Code: "PA", Name: "Panama", ThreeLetterCode: "PAN" },
  { Code: "PG", Name: "Papua New Guinea", ThreeLetterCode: "PNG" },
  { Code: "PY", Name: "Paraguay", ThreeLetterCode: "PRY" },
  { Code: "PE", Name: "Peru", ThreeLetterCode: "PER" },
  { Code: "PH", Name: "Philippines", ThreeLetterCode: "PHL" },
  { Code: "PN", Name: "Pitcairn", ThreeLetterCode: "PCN" },
  { Code: "PL", Name: "Poland", ThreeLetterCode: "POL" },
  { Code: "PT", Name: "Portugal", ThreeLetterCode: "PRT" },
  { Code: "PR", Name: "Puerto Rico", ThreeLetterCode: "" },
  { Code: "QA", Name: "Qatar", ThreeLetterCode: "QAT" },
  { Code: "RE", Name: "R\u00e9union", ThreeLetterCode: "REU" },
  { Code: "RO", Name: "Romania", ThreeLetterCode: "ROM" },
  { Code: "RU", Name: "Russian Federation", ThreeLetterCode: "RUS" },
  { Code: "RW", Name: "Rwanda", ThreeLetterCode: "RWA" },
  { Code: "BL", Name: "Saint Barth\u00e9lemy", ThreeLetterCode: "" },
  {
    Code: "SH",
    Name: "Saint Helena, Ascension and Tristan da Cunha",
    ThreeLetterCode: "SHN",
  },
  { Code: "KN", Name: "Saint Kitts and Nevis", ThreeLetterCode: "KIT" },
  { Code: "LC", Name: "Saint Lucia", ThreeLetterCode: "LCA" },
  { Code: "MF", Name: "Saint Martin (French part)", ThreeLetterCode: "MAA" },
  { Code: "PM", Name: "Saint Pierre and Miquelon", ThreeLetterCode: "SPM" },
  {
    Code: "VC",
    Name: "Saint Vincent and the Grenadines",
    ThreeLetterCode: "VCT",
  },
  { Code: "WS", Name: "Samoa", ThreeLetterCode: "ESM" },
  { Code: "SM", Name: "San Marino", ThreeLetterCode: "SMR" },
  { Code: "ST", Name: "Sao Tome and Principe", ThreeLetterCode: "STP" },
  { Code: "SA", Name: "Saudi Arabia", ThreeLetterCode: "SAU" },
  { Code: "SN", Name: "Senegal", ThreeLetterCode: "SEN" },
  { Code: "RS", Name: "Serbia", ThreeLetterCode: "SER" },
  { Code: "SC", Name: "Seychelles", ThreeLetterCode: "SYC" },
  { Code: "SL", Name: "Sierra Leone", ThreeLetterCode: "SLE" },
  { Code: "SG", Name: "Singapore", ThreeLetterCode: "SGP" },
  { Code: "SX", Name: "Sint Maarten (Dutch part)", ThreeLetterCode: "" },
  { Code: "SK", Name: "Slovakia", ThreeLetterCode: "" },
  { Code: "SI", Name: "Slovenia", ThreeLetterCode: "SVN" },
  { Code: "SB", Name: "Solomon Islands", ThreeLetterCode: "SLB" },
  { Code: "SO", Name: "Somalia", ThreeLetterCode: "SOM" },
  { Code: "ZA", Name: "South Africa", ThreeLetterCode: "ZAF" },
  {
    Code: "GS",
    Name: "South Georgia and the South Sandwich Islands",
    ThreeLetterCode: "SGS",
  },
  { Code: "SS", Name: "South Sudan", ThreeLetterCode: "SDN" },
  { Code: "ES", Name: "Spain", ThreeLetterCode: "ESP" },
  { Code: "LK", Name: "Sri Lanka", ThreeLetterCode: "LKA" },
  { Code: "SD", Name: "Sudan", ThreeLetterCode: "SDN" },
  { Code: "SR", Name: "Suriname", ThreeLetterCode: "SUR" },
  { Code: "SJ", Name: "Svalbard and Jan Mayen", ThreeLetterCode: "SJM" },
  { Code: "SZ", Name: "Swaziland", ThreeLetterCode: "SWZ" },
  { Code: "SE", Name: "Sweden", ThreeLetterCode: "SWE" },
  { Code: "CH", Name: "Switzerland", ThreeLetterCode: "CHE" },
  { Code: "SY", Name: "Syrian Arab Republic", ThreeLetterCode: "SYR" },
  { Code: "TW", Name: "Taiwan, Province of China", ThreeLetterCode: "TWN" },
  { Code: "TJ", Name: "Tajikistan", ThreeLetterCode: "TJK" },
  { Code: "TZ", Name: "Tanzania, United Republic of", ThreeLetterCode: "TZA" },
  { Code: "TH", Name: "Thailand", ThreeLetterCode: "THA" },
  { Code: "TL", Name: "Timor-Leste", ThreeLetterCode: "TMP" },
  { Code: "TG", Name: "Togo", ThreeLetterCode: "TGO" },
  { Code: "TK", Name: "Tokelau", ThreeLetterCode: "TKL" },
  { Code: "TO", Name: "Tonga", ThreeLetterCode: "TON" },
  { Code: "TT", Name: "Trinidad and Tobago", ThreeLetterCode: "TTO" },
  { Code: "TN", Name: "Tunisia", ThreeLetterCode: "TUN" },
  { Code: "TR", Name: "Turkey", ThreeLetterCode: "TUR" },
  { Code: "TM", Name: "Turkmenistan", ThreeLetterCode: "TKM" },
  { Code: "TC", Name: "Turks and Caicos Islands", ThreeLetterCode: "TCA" },
  { Code: "TV", Name: "Tuvalu", ThreeLetterCode: "TUV" },
  { Code: "UG", Name: "Uganda", ThreeLetterCode: "UGA" },
  { Code: "UA", Name: "Ukraine", ThreeLetterCode: "UKR" },
  { Code: "AE", Name: "United Arab Emirates", ThreeLetterCode: "ARE" },
  { Code: "GB", Name: "United Kingdom", ThreeLetterCode: "UK" },
  { Code: "U.S.", Name: "United States", ThreeLetterCode: "USA" },
  {
    Code: "UM",
    Name: "United States Minor Outlying Islands",
    ThreeLetterCode: "",
  },
  { Code: "UY", Name: "Uruguay", ThreeLetterCode: "URY" },
  { Code: "UZ", Name: "Uzbekistan", ThreeLetterCode: "UZB" },
  { Code: "VU", Name: "Vanuatu", ThreeLetterCode: "VUT" },
  {
    Code: "VE",
    Name: "Venezuela, Bolivarian Republic of",
    ThreeLetterCode: "VEN",
  },
  { Code: "VN", Name: "Viet Nam", ThreeLetterCode: "VNM" },
  { Code: "VG", Name: "Virgin Islands, British", ThreeLetterCode: "BVI" },
  { Code: "VI", Name: "Virgin Islands, U.S.", ThreeLetterCode: "" },
  { Code: "WF", Name: "Wallis and Futuna", ThreeLetterCode: "WFF" },
  { Code: "EH", Name: "Western Sahara", ThreeLetterCode: "WSH" },
  { Code: "YE", Name: "Yemen", ThreeLetterCode: "YEM" },
  { Code: "ZM", Name: "Zambia", ThreeLetterCode: "ZMB" },
  { Code: "ZW", Name: "Zimbabwe", ThreeLetterCode: "ZWE" },
];

const getCountryCode = (countryName, threeLetterCountryCode) => {
  for (let i = 0; i < countryCodeMap.length; i += 1) {
    const { Name, Code, ThreeLetterCode } = countryCodeMap[i];
    if (
      !stringIsEmptyOrNullish(countryName) &&
      Name.toLocaleLowerCase() === countryName.toLocaleLowerCase()
    ) {
      return Code;
    }
    if (
      !stringIsEmptyOrNullish(threeLetterCountryCode) &&
      ThreeLetterCode.toLocaleLowerCase() ===
        threeLetterCountryCode.toLocaleLowerCase()
    ) {
      return Code;
    }
  }
  return countryName;
};

export default getCountryCode;
