import React from "react";
import IsFalsy from "../../../common/logic/isFalsy";
import IsTruthy from "../../../common/logic/isTruthy";

const DecedentInformationQuestions = (props) => {
  const {
    shouldDisplayDecedentInfoQuestion3,
    questionNumber,
    shouldDisplayDecedentInfoQuestion5,
    isDecedentInsuredOnDisabilityPolicy,
    question,
    isSubQuestion = false,
  } = props;

  const questionId = isSubQuestion
    ? `decedentInfo_Question${questionNumber}_SubQuestion`
    : `decedentInfo_Question${questionNumber}`;

  return (
    <>
      <IsTruthy
        value={
          questionNumber === 3 && shouldDisplayDecedentInfoQuestion3 === true
        }
      >
        <p id={questionId} className="h4">
          {question}
        </p>
      </IsTruthy>
      <IsTruthy
        value={questionNumber === 5 && shouldDisplayDecedentInfoQuestion5}
      >
        <p id={questionId} className="h4">
          {question}
        </p>
      </IsTruthy>
      <IsTruthy
        value={questionNumber === 6 && isDecedentInsuredOnDisabilityPolicy}
      >
        <p id={questionId} className="h4">
          {question}
        </p>
      </IsTruthy>
      <IsFalsy
        value={
          questionNumber === 5 || questionNumber === 3 || questionNumber === 6
        }
      >
        <p id={questionId} className="h4">
          {question}
        </p>
      </IsFalsy>
    </>
  );
};

export default React.memo(DecedentInformationQuestions);
