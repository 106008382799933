export const findOPMDesc = (shortName) => {
  switch (shortName) {
    case "ABR":
      return "Available at no additional cost, allows the policy owner to receive an advance of policy death benefit when MassMutual receives satisfactory proof that that insured has a terminal illness, expected to result in death within 12 months.";
    case "ADB":
      return "A death benefit equal to the elected amount is paid when the death occurs as the result of an accident as defined under the terms of the rider.";
    case "ALIR":
      return "This rider, known as ALIR, provides for the purchase of additional, participating paid-up life insurance that will be added to the death benefit of a policy. In tradVantage, ALIR is referred to as the Paid-Up Additions Rider (PUAR).";
    case "CIA":
      return "Provides term insurance coverage on children of insured. Coverage is provided until child's age 25 or parent's age 70, whichever is earlier.";
    case "CTR":
      return "Provides term insurance coverage on children of insured. Coverage is provided until child's age 25 or parent's age 70, whichever is earlier. Coverage may be converted to a life policy at age 25 (child's age) for an amount up to 5 times the amount of the rider.";
    case "IPA":
      return "For Life Insurance: This benefit guarantees the owner the right to purchase additional policies without evidence of insurability on election option dates. For Disability Insurance: This benefit allows the insured, at specific intervals, to increase coverage without medical evidence of insurability (only financial evidence is required).";
    case "IPR":
      return "Guarantees insured the option to purchase specified amounts of additional insurance on his life at standard rates and without evidence of insurability.";
    case "LISR":
      return "When this rider is attached to a Whole Life (WL), Modified Premium Whole Life (Mod WL), or High Early Cash Value Whole Life (HECV) policy, it provides flexibility in funding and protection levels. LISR provides a non-guaranteed level death benefit comprised of one-year term insurance and paid-up additions. LISR is known as the Flexible Rider (FX or FLEX) on tradVantage.";
    case "SIPR":
      return "This rider provides supplemental coverage consisting of one-year term insurance and paid-up additions purchased with dividends. May be attached at-issue only to Survivorship Whole Life (SWL) and Survivorship Whole Life Protector (SWLP) policies. Not available in Arkansas.";
    case "WP2":
      return "The Waiver of Premium Rider (WP) provides that premiums for the base policy and the rider will be waived if the insured becomes totally disabled as described by the rider.";
    case "WP":
      return "The Waiver of Premium Rider (WP) provides that premiums for the base policy and the rider will be waived if the insured becomes totally disabled as described by the rider.";
    case "1RTR1":
      return "When the Renewable Term Rider (RTR) is attached, a level amount of term insurance is provided for one year. The term coverage is renewed automatically each year until the policy anniversary nearest the insured's 70th birthday. Premiums increase on renewal and are payable until age 70 or to the end of the premium payment period, if earlier.";
    default:
      return "";
  }
};

export const findMPRDesc = (shortName) => {
  switch (shortName) {
    case "ABR PAID UP":
      return "Rider provides right to increase death benefit and cash value of base policy. Rider available only on Graded Premium Life 20 and Econolife policies issued after 9/22/1986.";
    case "ABR UNSCHEDULED":
      return "Rider provides right to increase death benefit and cash value of base policy. Rider available only on Graded Premium Life 20 and Econolife policies issued after 9/22/1986.";
    case "ADDL INDEMNITY":
      return "Pays a specified additional amount if the insured dies by accidental means.";
    case "CHILDRENS INS":
      return "Provides term insurance coverage on children of insured. Coverage is provided until child's age 25 or parent's age 70, whichever is earlier.";
    case "DIS W":
      return "This rider will waive the premiums on the base policy, as well as on this rider, during total and permanent disability which occurs between the period of time after attained age 5 and before attained age 65.";
    case "INSURABILITY":
      return "Guarantees insured the option to purchase specified amounts of additional insurance on his life at standard rates and without evidence of insurability.";
    case "10 YR TERM":
      return "Provides term insurance protection for 10-year periods (but no later than age 65).";
    case "LBR":
      return "Allows the policy owner to receive an advance of a portion of the policy death benefit when the company receives satisfactory proof the insured has a terminal illness, expected to result in death within 12 months.";
    case "SIA":
      return "Provides term insurance coverage on a spouse's life for a period of 20 years.";
    default:
      return "";
  }
};

export const findLVRGVLDesc = (shortName) => {
  switch (shortName) {
    case "ADB":
      return "A death benefit equal to the elected amount is paid when the death occurs as the result of an accident as defined under the terms of the rider.";
    case "DBR":
      return "The Disability Benefit Rider (DBR) will, during the total and permanent disability of the insured as defined in the rider, waive all monthly deductions from the Account Value. In addition, each month a specified benefit amount will be credited to the Account Value (this amount may be increased if evidence of insurability is provided).";
    case "IPR":
      return "Guarantees insured the option to purchase specified amounts of additional insurance on his life at standard rates and without evidence of insurability.";
    default:
      return "";
  }
};

export const findPE1Desc = (shortName) => {
  switch (shortName) {
    case "ABR":
      return "Available at no additional cost, allows the policy owner to receive an advance of policy death benefits when MassMutual receives satisfactory proof that the insured has a terminal illness, expected to result in death within 12 months.";
    case "DIS BENEFIT RDR":
      return "The Disability Benefit Rider (DBR) will, during the total and permanent disability of the insured as defined in the rider, waive all monthly deductions from the Account Value. In addition, each month a specified benefit amount will be credited to the Account Value (this amount may be increased if evidence of insurability is provided).";
    case "ESTATE PROTECTION RD":
      return "The Estate Protection Rider (EPR) provides a level death benefit if both insured's die during the first four policy years. This death benefit is equal to 125% of the initial death benefit of the base policy. Premiums are payable for four years on the same frequency as the base policy.";
    case "GUARANTEED INS RDR":
      return "Provides the right to increase the face amount of the policy or purchase a new policy on the insured without evidence of insurability on certain option dates. There is an additional charge for this rider.";
    case "LBR":
      return "Allows the policy owner to receive an advance of a portion of the policy death benefit when the company receives satisfactory proof the insured has a terminal illness, expected to result in death within 12 months.";
    case "OI RIDER - BASE":
      return "The Other Insured Rider provides convertible, yearly renewable level-term insurance coverage for one additional insured.";
    case "SPLIT OPTION":
      return "PSO allows the owner of a policy to exchange it, without evidence of insurability, for two single life policies, available a the time the split is exercised, one on the life of each insured, in the event of divorce or an estate tax law change as described in the rider. In the event of divorce, the right to exchange will be available for the six-month period beginning on the date six months after the effective date of the final decree of divorce.";
    case "SURVIVORSHIP TERM RI":
      return "This rider, if elected, provides an additional amount of coverage to the base policy, for the term (period of time) associated with the rider. Rider availability, details and terms may vary by product and/or state.";
    case "WAIVER MC RIDER":
      return "A policy provision that under certain conditions an insurance policy will be kept in full force by the company without further payment of premiums. It is used in the event of total and permanent disability.";
    case "WAIVER MD RIDER":
      return "A policy provision that under certain conditions an insurance policy will be kept in full force by the company without further payment of premiums. It is used in the event of total and permanent disability.";
    case "WAIVER SP RIDER":
      return "When the Waiver of Specified Premium Rider (WSP) is attached, during the total disability of the insured the Account Value will be credited the greater of the monthly charges or a monthly specified amount designated by the policy owner at-issue.";
    case "ADB":
      return "A death benefit equal to the elected amount is paid when the death occurs as the result of an accident as defined under the terms of the rider.";
    case "DBR":
      return "The Disability Benefit Rider (DBR) will, during the total and permanent disability of the insured as defined in the rider, waive all monthly deductions from the Account Value. In addition, each month a specified benefit amount will be credited to the Account Value (this amount may be increased if evidence of insurability is provided).";
    case "IPR":
      return "Guarantees insured the option to purchase specified amounts of additional insurance on his life at standard rates and without evidence of insurability.";
    default:
      return "";
  }
};

export const findVNTG1Desc = (shortName) => {
  switch (shortName) {
    case "ACBRS":
      return "The Accelerated Death Benefit Rider allows a policy owner to receive an advance of policy death benefits when the surviving insured has a terminal illness, expected to result in death within 12 months. The funds may be used for any purpose. This rider terminates upon acceleration. There is no cost for the addition of this rider however there is a fee if the rider is exercised.";
    case "ACBR":
      return "Available at no additional cost, allows the policy owner to receive an advance of policy death benefits when MassMutual receives satisfactory proof that the insured has a terminal illness, expected to result in death within 12 months.";
    case "ALIR":
      return "This rider, known as ALIR, provides for the purchase of additional, participating paid-up life insurance that will be added to the death benefit of a policy. In tradVantage, ALIR is referred to as the Paid-Up Additions Rider.";
    case "ALIRS":
      return "This rider, known as ALIR, provides for the purchase of additional, participating paid-up life insurance that will be added to the death benefit of a policy. In tradVantage, ALIR is referred to as the Paid-Up Additions Rider.";
    case "PALIR":
      return "The Planned Additional Life Insurance Rider (PALIR) allows the policy owner to purchase additional participating paid up insurance by making up to three payments each policy year that do not need to be made using the same premium frequency as the base policy. The rider increases the policy's death benefit and accelerates cash value growth.";
    case "EPR":
      return "The Estate Protection Rider (EPR) provides a level death benefit if both insured's die during the first four policy years. This death benefit is equal to 125% of the initial death benefit of the base policy. Premiums are payable for four years on the same frequency as the base policy.  \nIn New York, the Estate Protection Rider is also known as the Four Year Level Survivorship Term Insurance Rider.";
    case "GIO":
      return "Guarantees insured the option to purchase specified amounts of additional insurance on his life at standard rates and without evidence of insurability.";
    case "LISR":
      return "When this rider is attached to a Whole Life (WL), Modified Premium Whole Life (Mod WL), or High Early Cash Value Whole Life (HECV) policy, it provides flexibility in funding and protection levels. LISR provides a non-guaranteed level death benefit comprised of one-year term insurance and paid-up additions. LISR is known as the Flexible Rider on tradVantage.";
    case "LISRS":
      return "When this rider is attached to a Whole Life (WL), Modified Premium Whole Life (Mod WL), or High Early Cash Value Whole Life (HECV) policy, it provides flexibility in funding and protection levels. LISR provides a non-guaranteed level death benefit comprised of one-year term insurance and paid-up additions. LISR is known as the Flexible Rider on tradVantage.";
    case "LTCIR":
      return "This is a single premium LTC benefit rider that is mandatorily attached to a Single Premium Whole Life base plan at issue. The LTCIR provides acceleration of the life insurance as well as pure LTC coverage.";
    case "LTCR":
      return "Long Term Care Rider - Allows accelerated payment of death benefit for reimbursement of qualified long term care (LTC) expenses.";
    case "PSO":
      return "The Policy Split Option allows for the right to exchange of the policy without evidence of insurability, for two single life insurance policies, available at the time the split is exercised. One on the life of each insured for half of the pre-split Death Benefit. The rider can be exercised when certain events occur, such as divorce, dissolution of a corporation or partnership or changes in the federal estate tax laws, as more fully described in the rider. There is no cost for the addition of this rider however there is a fee if the rider is exercised.";
    case "TIR":
      return "Under the Transfer or Insured Rider (TIR), the owner is allowed to exchange the original policy for a new policy on the life of another person, provided an insurable interest exists between the owner and the substitute insured, the new insured is not older than age 75, and evidence of insurability is provided.";
    case "WPDS":
      return "This rider waives the policy's premiums if an insured covered under the rider dies within the first 20 years of the policy. After the death, we will waive any premium due (except ALIR and LISR dump in premium) through policy year 20. This rider is also available at issue. Premiums for this rider are payable for the first 15 policy years.";
    case "WP":
      return "The Waiver of Premium Rider (WP) provides that premiums for the base policy and the rider will be waived if the insured becomes totally disabled as described by the rider.";
    case "RTR1":
      return "When the Renewable Term Rider (RTR) is attached, a level amount of term insurance is provided for one year. The term coverage is renewed automatically each year until the policy anniversary nearest the insured's 70th birthday. Premiums increase on renewal and are payable until age 70 or to the end of the premium payment period, if earlier.";
    case "YTP":
      return "When the LISR rider is attached to provide a non-guaranteed level death benefit. This death benefit is comprised of paid up additions and one year term insurance. As the paid up additions increase, the one year term will decrease until the LISR crosses over and is comprised solely of paid up additions.";
    default:
      return "";
  }
};

export const findRiderDescription = (shortName, agreementKeyAdmin) => {
  switch (agreementKeyAdmin) {
    case "OPM":
      return findOPMDesc(shortName);
    case "MPR":
      return findMPRDesc(shortName);
    case "LVRGVL":
      return findLVRGVLDesc(shortName);
    case "PE1":
      return findPE1Desc(shortName);
    case "VNTG1":
      return findVNTG1Desc(shortName);
    case "HAP":
      return findHAPDesc(shortName);
    default:
      return "";
  }
};

export const findDIOPMDesc = (shortName) => {
  switch (shortName) {
    case "AMIR":
      return "Provides for monthly income in addition to base policy. Premiums for this rider are level. Multiple AMIR riders may be attached to the base policy, each having its own Waiting Period and Benefit Period.";
    case "AIR":
      return "The Annual Increase Rider provides a 3%-5% compounded annual increase in the aggregate monthly benefit provided by the base policy and any Annually Renewable Disability Income, Additional Monthly Income and/or Contingent Monthly Rider attached to that policy. In order to continue increases, this rider must be renewed e every fives years. The cost for this additional coverage causes the premium to increase each year. (for 9500 series: This rider can be fully converted to level premium coverage through age 60 provided the insured is not disabled).";
    case "CIR":
      return "Provides additional monthly income that does not duplicate social benefits. Primary (PB) does not duplicate benefits paid on the insured. Supplemental (SB) does not duplicate benefits paid to the family of the insured. Not available on 9500 series.";
    case "CMR":
      return "Provides for monthly income in addition to base policy. Rider does not duplicate Social Benefits received from another source. If insured receives no Social Benefits, full benefit would apply. If insured receives one Social Benefit (not including Social Security Retirement), 40% of rider amount would be paid. If insured received two Social Benefits or received Social Retirement, no benefit would apply. The exception to this is California, where a dollar-for-dollar trade off does not apply. Note: Social Benefits include Social Security Retirement, Social Security Disability, and Social Security Disability for Families. Worker's Compensation and State Cash Sickness.";
    case "CDP":
      return "Provides additional monthly income that does not duplicate social benefits. Primary (PB) does not duplicate benefits paid on the insured. Supplemental (SB) does not duplicate benefits paid to family of insured. Not available on 9500 series.";
    case "DIA":
      return "Enables the insured to integrate coverage under this policy with other short benefit period coverage which insured may have. Provides that after a period of disability has continued for a Deferral Period of 6 months, 1 year or 2 years, Additional Monthly Income (total or partial as for the basic policy) as applied for will be paid as long as income is payable under the basic policy.";
    case "DIR":
      return "Provides additional monthly benefit with longer Waiting Period than basic policy. Usually purchased to work around other coverage (Short-Term programs) insured may have. Not available on 9500 series.";
    case "DIPR":
      return "Available on Business Overhead Expense policies. Rider functions the same as Disability Income Protection Rider (DIPR) on regular Disability policies. Allows additional coverage to be purchased without evidence of medical insurability, only financial insurability. Option may be exercised in two-year option periods beginning at issue. Additional coverage may be a new policy or a rider attached to the existing policy. This rider remains on the policy to age 51 or for a minimum of 3 option periods, whichever is greater. Not available on 9500 series BOE policies.";
    case "EBS":
      return "Provides for extension of Benefit Period to life. This rider falls off the policy on the anniversary following the insured's 60th birthday. Lifetime benefit not available after age 60. The amount of coverage beyond 65 that the insured is eligible to receive is dependent upon when the insured became disable. If the insured is Age 44 or below at time of disability, they will receive 100% of their benefit for life. The benefit will decrease by 5% each year after age 44 (e.g.: If insured becomes disabled at 45, they will get 95% of their benefit for life after age 65, etc.) The extended benefit applies to the basic policy only.";
    case "EBIS":
      return "Provides for extension of Benefit Period to life. This rider falls off the policy on the anniversary following the insured's 60th birthday. Lifetime benefit not available after age 60. The amount of coverage beyond 65 that the insured is eligible to receive is dependent upon when the insured became disable. If the insured is Age 44 or below at time of disability, they will receive 100% of their benefit for life. The benefit will decrease by 5% each year after age 44 (e.g.: If insured becomes disabled at 45, they will get 95% of their benefit for life after age 65, etc.) The extended benefit applies to the basic policy only.";
    case "EIR":
      return "Provides for extension of Benefit Period to life. This rider falls off the policy on the anniversary following the insured's 60th birthday. Lifetime benefit not available after age 60. The amount of coverage beyond 65 that the insured is eligible to receive is dependent upon when the insured became disabled. If the insured is Age 44 or below at time of disability, they will receive 100% of their benefit for life. The benefit will decrease by 5% each year after age 44 (e.g.: If insured becomes disabled at 45, they will get 95% of their benefit for life after age 65, etc.) The extended benefit applies to the basic policy only.";
    case "EMR":
      return "Provides for extension of Benefit Period to life. This rider falls off the policy on the anniversary following the insured's 60th birthday. Lifetime benefit not available after age 60. The amount of coverage beyond 65 that the insured is eligible to receive is dependent upon when the insured became disabled. If the insured is Age 44 or below at time of disability, they will receive 100% of their benefit for life. The benefit will decrease by 5% each year after age 44 (e.g.: If insured becomes disabled at 45, they will get 95% of their benefit for life after age 65, etc.) The extended benefit applies to the basic policy only.";
    case "EIA":
      return "Provides in a single policy some of the coverage on a short benefit period basis and the remained on a longer benefit period basis. Provides Extra Monthly Income (total or partial, as for the basic policy) for a Maximum Benefit Period of 3,4,5,9,10 or 11 months or 2 or 5 years as elected.";
    case "IAR":
      return "Coverage that helps buffer the impact of inflation. Provides for increased income, beginning at second year of disability, based on changes in the Consumer Price Index (CPI). Percentage of increase is based on policy series.";
    case "IMBA":
      return "Provides monthly income in addition to the basic policy. Available on Business Overhead Expense policies. Not available on 9500 series policies.";
    case "IMBR":
      return "Provides monthly income in additional to the basic policy. Available on Business Overhead Expense policies. Not available on 9500 series policies.";
    case "ROR":
      return "Provides for full benefit to be paid (assumes 100% loss of income) even if insured is able to work at another occupation. Rider is only available at a professionals level and insured must qualify by class and income level. Designed to provide extra protection for professionals.";
    case "SIR":
      return "Provides additional monthly benefit for short period of time. Usually used to work around other coverage insured may have.";
    default:
      return "";
  }
};

export const findDIMPRDesc = (shortName) => {
  switch (shortName) {
    case "AS":
      return "AB/NC83 Series: provides a monthly benefit beyond the Anniversary on or after the insured's 65th birthday if they are totally disable before the Anniversary on or after their 45th birthday. The maximum benefit is reduced by 10% for every year age 45 that they became totally disabled, with benefits payable for the entire length of total disability.";
    case "OWNOCC":
      return "If you cannot work in your own occupation, this rider allows you to earn an income from a new occupation without reducing your total disability benefit. You will also receive full benefits if you are disabled from loss of sight, use of two limbs, or loss of speech or hearing.";
    case "PR":
      return "50% of benefit when you return to work in the 1st twelve months, after 12 months it is based on residual.";
    case "SO":
      return "This optional rider pays a monthly benefit when Social Security does not. As long as you are not receiving Social Security benefits and satisfy the eligibility requirements, the rider benefit amount is added to your basic policy to determine your total, partial and residual benefits.";
    default:
      return "";
  }
};

export const findDIEDSDesc = (shortName) => {
  switch (shortName) {
    case "ABI":
      return "Automatic Increase Benefit Rider is a supplemental benefit rider that allows the automatic annual purchase of benefits without evidence of medical or financial insurability (provided the insured is not disabled).";
    case "BIR":
      return "The Benefit Increase Rider, which must be renewed every 3 years, provides the opportunity to obtain additional benefits to the policy without Proof of Good Health other than proof that the insured is Actively At Work and is not Disabled. We will require proof of the nature of the Insured's Occupation.";
    case "AMBR":
      return "Additional Monthly Benefit Rider provides an additional benefit with a waiting period identical to the base but with a different benefit period. The rider can be used to cover special expenses with a shorter duration than other covered expenses insured under the base policy.";
    case "FIO":
      return "Future Insurability Option Rider allows (before disability) as policyowner to increase the amount of monthly disability income coverage as his/her income increases, with only financial underwriting required.";
    case "IPR":
      return "Income Protection Rider allows the Insured, at specific intervals, to increase coverage without medical evidence of insurability (only financial evidence is required). The rider provides income protection for the business owner in the event of total disability.";
    case "PDB":
      return "This rider will pay monthly disability benefits when the insured is determined to be partially disabled as defined by the rider.";
    case "CAT":
      return "Catastrophic Disability Benefit Rider provides benefits that cover up to 100% of pre-disability earned income when combined with a base policy. It is a total disability benefit that terminated when the insured reaches age 65.";
    case "LIFE":
      return "Lifetime Benefits Rider provides monthly benefits for the insured's lifetime, if eligibility requirements are satisfied.";
    case "COLA":
      return "Cost of Living Adjustment Rider is to help benefits keep pace with inflation in the event of disability lasting longer than 12 months. Increases start with the second year of disability.";
    case "GRCOLA":
      return "Cost of Living Adjustment Rider is to help benefits keep pace with inflation in the event of disability lasting longer than 12 months. Increases start with the second year of disability.";
    case "GRFIO":
      return "Future Insurability Option Rider allows (before disability) a policyowner to increase the amount of monthly disability income coverage as his/her income increases, with only financial underwriting required.";
    case "GRSUP":
      return "Group Supplemental Rider provides additional return-to-work benefits which complements (enhances) the benefits an insured receives from his/her group LTD plan during a partial disability.";
    case "LTC":
      return "LTC Endorsement: While this policy is in-force, 2% of total (cumulative) premium paid on a MassMutual disability income policy is used as a credit towards first-year MassMutual LTC premium. This endorsement is not available in all states.";
    case "MAX":
      return "The Mental Disorder Limitation can be waived under the Maximum Benefit Period Endorsement. This Endorsement is only available on employer-pay cases of 20 + lives.";
    case "OWNOCC":
      return "Own Occupation Rider provides a monthly benefit when the insured is totally disabled and is working in another occupation.";
    case "HIV":
      return "HIV Rider provides monthly benefits should the Insured test positive for HIV. The rider is designed to provide a transitional benefit while a health care worker makes future career decisions. Benefits will be paid whether the Insured is working or not, whether he/she is symptomatic or not, and regardless of how the virus was contracted.";
    case "RGR":
      return "Retireguard Rider will provide benefits to help cover pension contributions that would have been made to eligible qualified defined contribution plans had that insured remained healthy.";
    case "STR":
      return "Short Term Disability Benefits Rider pays a monthly benefit for total disability after a brief waiting period for a short period of time (typically up to three, six, nine or 12 months).";
    case "SIR":
      return "Social Insurance Rider provides monthly income benefits during disability without duplication benefits provided by Social Security, State Cash Sickness or Workers' Compensation.";
    case "SLR":
      return "The Student Loan Rider will reimburse the Owner for certain student loan payments incurred by the Insured up to the maximum monthly benefit for the rider. Documentation will be required at time of claim to verify the benefit amount we will pay under the Rider.";
    case "PECL":
      return "A disability or loss caused by a pre-existing condition will not be covered if: the pre-existing condition was not fully and accurately described in the application; or the pre-existing condition has specifically been excluded by name or specific description. However, a disability or loss caused by a pre-existing condition will be covered if it starts two years after the policy has been issued and it has not been excluded from coverage.";
    default:
      return "";
  }
};

export const findDIRiderDescription = (shortName, agreementKeyAdmin) => {
  switch (agreementKeyAdmin) {
    case "OPM":
      return findDIOPMDesc(shortName);
    case "MPR":
      return findDIMPRDesc(shortName);
    case "EDS":
      return findDIEDSDesc(shortName);
    case "EDS-DI":
      return findDIEDSDesc(shortName);
    default:
      return "";
  }
};

export const findHAPDesc = (shortName) => {
  switch (shortName) {
    case "ALIR":
      return "This rider, known as ALIR, provides for the purchase of additional, participating paid-up life insurance that will be added to the death benefit of a policy. In tradVantage, ALIR is referred to as the Paid-Up Additions Rider.";
    case "TIR":
      return "Under the Transfer or Insured Rider (TIR), the owner is allowed to exchange the original policy for a new policy on the life of another person, provided an insurable interest exists between the owner and the substitute insured, the new insured is not older than age 75, and evidence of insurability is provided.";
    case "LISR":
      return "When this rider is attached to a Whole Life (WL), Modified Premium Whole Life (Mod WL), or High Early Cash Value Whole Life (HECV) policy, it provides flexibility in funding and protection levels. LISR provides a non-guaranteed level death benefit comprised of one-year term insurance and paid-up additions. LISR is known as the Flexible Rider on tradVantage.";
    default:
      return "";
  }
};
