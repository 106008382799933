import React from "react";
import { NOT_APPLICABLE } from "../../../../../../constants/ui";
import { isEmptyObject, isNaN } from "../../../../../../utils/evaluate";
import { formatMoney } from "../../../../../../utils/format";
import Table from "../../../../../common/table";

const getTotal = (value1, value2) => {
  if (isNaN(value1) && isNaN(value2)) {
    return NOT_APPLICABLE;
  }
  if (isNaN(value1)) {
    return value2;
  }
  if (isNaN(value2)) {
    return value1;
  }
  return Number(value1) + Number(value2);
};

const getLoanPrincipal = (groupedLoanListValue) => ({
  employee:
    formatMoney(groupedLoanListValue?.EE?.[0]?.remainingBalance) ||
    NOT_APPLICABLE,
  employer:
    formatMoney(groupedLoanListValue?.ER?.[0]?.remainingBalance) ||
    NOT_APPLICABLE,
  total: formatMoney(
    getTotal(
      groupedLoanListValue?.EE?.[0]?.remainingBalance,
      groupedLoanListValue?.ER?.[0]?.remainingBalance
    )
  ),
});

const getAccruedLoanInterest = (groupedLoanListValue) => ({
  employee:
    formatMoney(groupedLoanListValue?.EE?.[0]?.accruedLoanInterest) ||
    NOT_APPLICABLE,
  employer:
    formatMoney(groupedLoanListValue?.ER?.[0]?.accruedLoanInterest) ||
    NOT_APPLICABLE,
  total: formatMoney(
    getTotal(
      groupedLoanListValue?.EE?.[0]?.accruedLoanInterest,
      groupedLoanListValue?.ER?.[0]?.accruedLoanInterest
    )
  ),
});

const getLoanPayoffAmount = (groupedLoanListValue) => ({
  employee:
    formatMoney(groupedLoanListValue?.EE?.[0]?.payoffAmt) || NOT_APPLICABLE,
  employer:
    formatMoney(groupedLoanListValue?.ER?.[0]?.payoffAmt) || NOT_APPLICABLE,
  total: formatMoney(
    getTotal(
      groupedLoanListValue?.EE?.[0]?.payoffAmt,
      groupedLoanListValue?.ER?.[0]?.payoffAmt
    )
  ),
});

const getLoanPayoffInformation = (groupedLoanList) => {
  const groupedLoanListValues = Object.values(groupedLoanList);

  const payOffResult = groupedLoanListValues.reduce(
    (loansPayoffDetail, groupedLoanListValue, currentIndex) => {
      const formattedLoanPrincipal = getLoanPrincipal(groupedLoanListValue);
      const formattedAccruedLoanInterest =
        getAccruedLoanInterest(groupedLoanListValue);
      const formattedLoanPayoffAmount =
        getLoanPayoffAmount(groupedLoanListValue);

      if (isEmptyObject(loansPayoffDetail)) {
        return {
          loanPrincipal: {
            header: "Loan Principal",
            loan1Employee: formattedLoanPrincipal.employee,
            loan1Employer: formattedLoanPrincipal.employer,
            loan1Total: formattedLoanPrincipal.total,
          },
          accruedLoanInterest: {
            header: "Accrued Loan Interest",
            loan1Employee: formattedAccruedLoanInterest.employee,
            loan1Employer: formattedAccruedLoanInterest.employer,
            loan1Total: formattedAccruedLoanInterest.total,
          },
          loanPayoffAmount: {
            header: "Loan Payoff Amount",
            loan1Employee: formattedLoanPayoffAmount.employee,
            loan1Employer: formattedLoanPayoffAmount.employer,
            loan1Total: formattedLoanPayoffAmount.total,
          },
        };
      }
      return {
        loanPrincipal: {
          ...loansPayoffDetail.loanPrincipal,
          [`loan${currentIndex + 1}Employee`]: formattedLoanPrincipal.employee,
          [`loan${currentIndex + 1}Employer`]: formattedLoanPrincipal.employer,
          [`loan${currentIndex + 1}Total`]: formattedLoanPrincipal.total,
        },
        accruedLoanInterest: {
          ...loansPayoffDetail.accruedLoanInterest,
          [`loan${currentIndex + 1}Employee`]:
            formattedAccruedLoanInterest.employee,
          [`loan${currentIndex + 1}Employer`]:
            formattedAccruedLoanInterest.employer,
          [`loan${currentIndex + 1}Total`]: formattedAccruedLoanInterest.total,
        },
        loanPayoffAmount: {
          ...loansPayoffDetail.loanPayoffAmount,
          [`loan${currentIndex + 1}Employee`]:
            formattedLoanPayoffAmount.employee,
          [`loan${currentIndex + 1}Employer`]:
            formattedLoanPayoffAmount.employer,
          [`loan${currentIndex + 1}Total`]: formattedLoanPayoffAmount.total,
        },
      };
    },
    {}
  );

  return [
    payOffResult.loanPrincipal,
    payOffResult.accruedLoanInterest,
    payOffResult.loanPayoffAmount,
  ];
};

const getPayoffInfoColumns = (outstandingLoanCount) => {
  const irregularColumns = [
    { label: "", dataCell: true },
    { label: "Loan 1", colSpan: "3" },
  ];

  const loanPayoffColumns = [
    {
      accessor: "header",
      label: "",
      alignment: "left",
    },
    {
      accessor: "loan1Employee",
      label: "Employee",
      alignment: "left",
    },
    {
      accessor: "loan1Employer",
      label: "Employer",
      alignment: "left",
    },
    {
      accessor: "loan1Total",
      label: "Total",
      alignment: "left",
    },
  ];
  if (outstandingLoanCount > 1) {
    loanPayoffColumns.push(
      {
        accessor: "loan2Employee",
        label: "Employee",
        alignment: "left",
      },
      {
        accessor: "loan2Employer",
        label: "Employer",
        alignment: "left",
      },
      {
        accessor: "loan2Total",
        label: "Total",
        alignment: "left",
      }
    );
    irregularColumns.push({ label: "Loan 2", colSpan: "3" });
  }
  if (outstandingLoanCount > 2) {
    loanPayoffColumns.push(
      {
        accessor: "loan3Employee",
        label: "Employee",
        alignment: "left",
      },
      {
        accessor: "loan3Employer",
        label: "Employer",
        alignment: "left",
      },
      {
        accessor: "loan3Total",
        label: "Total",
        alignment: "left",
      }
    );
    irregularColumns.push({ label: "Loan 3", colSpan: "3" });
  }

  return { irregularColumns, loanPayoffColumns };
};

const LoanPayoffInfoTable = (props) => {
  const { outstandingLoanCount, groupedLoanList } = props;

  const { irregularColumns, loanPayoffColumns } =
    getPayoffInfoColumns(outstandingLoanCount);
  const loanPayoffRows = getLoanPayoffInformation(groupedLoanList);

  return (
    <Table
      id="loan_payoff"
      columns={loanPayoffColumns}
      rows={loanPayoffRows}
      showPagination={false}
      irregularColumns={irregularColumns}
    />
  );
};
export default LoanPayoffInfoTable;
