import React from "react";
import IsTruthy from "../../../common/logic/isTruthy";
import IsFalsy from "../../../common/logic/isFalsy";
import { compareStrings } from "../../../../utils/string";

const PersonModalCell = (props) => {
  const {
    row,
    rowIndex,
    column: { accessor },
    customFunctions,
  } = props;

  let onClick = null;

  if (compareStrings(accessor, "customerName"))
    onClick = customFunctions.showCustomerModal(row);
  else if (compareStrings(accessor, "agentName"))
    onClick = customFunctions.showAgentModal(row);
  else onClick = customFunctions.showBeneficiaryModal(row);

  return (
    <>
      <IsTruthy value={row.disableBeneModal}>
        <span
          id={`${accessor}_${rowIndex}_value`}
          dangerouslySetInnerHTML={{ __html: row[accessor] }}
        />
      </IsTruthy>
      <IsFalsy value={row.disableBeneModal}>
        <button
          id={`${accessor}_${rowIndex}_btn`}
          onClick={onClick}
          className="btn btn-inline"
          type="button"
        >
          <span
            id={`${accessor}_${rowIndex}_value`}
            dangerouslySetInnerHTML={{ __html: row[accessor] }}
          />
        </button>
      </IsFalsy>
    </>
  );
};

export default PersonModalCell;
