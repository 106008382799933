import React, { useEffect, useState } from "react";
import { isDefined } from "../../../utils/evaluate";
import IsTruthy from "../../common/logic/isTruthy";
import { useCancelModal } from "../../common/popup/cancel";
import { useCanContinue } from "./hooks";
import { useFormContext } from "../../../contexts/form";

const ModalControl = (props) => {
  const { pageNumber, onSubmit, shouldDisableSubmit, error, showLoadingIcon } =
    props;
  const { onContinue } = useCanContinue(props);
  const { openCancelModal } = useCancelModal();
  const { formData } = useFormContext();

  const [className, setClassName] = useState("btn btn-primary");
  const [buttonText, setButtonText] = useState("Submit");
  useEffect(() => {
    if (showLoadingIcon) {
      setClassName("btn btn-primary-loading");
      setButtonText("");
    } else {
      setClassName("btn btn-primary");
      setButtonText("Submit");
    }
  }, [showLoadingIcon]);

  return (
    <div className="mm-spacing">
      <IsTruthy value={error} validationFunction={isDefined}>
        <div
          className="alert alert-danger mm-spacing--minor"
          role="alert"
          aria-label={error}
        >
          {error}
        </div>
      </IsTruthy>
      <IsTruthy value={pageNumber} validationFunction={(pn) => pn === 1}>
        <button
          id="continue_btn"
          onClick={onContinue}
          type="button"
          className="btn btn-primary"
        >
          continue
        </button>
      </IsTruthy>
      <IsTruthy value={pageNumber} validationFunction={(pn) => pn === 2}>
        <button
          id="submit_btn"
          type="submit"
          className={className}
          disabled={shouldDisableSubmit || showLoadingIcon}
          onClick={(e) => {
            e.preventDefault();
            onSubmit(formData);
          }}
        >
          {buttonText}
        </button>
      </IsTruthy>
      <IsTruthy value={pageNumber < 3}>
        <button
          id="cancel_btn"
          type="button"
          className="btn btn-link"
          onClick={openCancelModal}
        >
          Cancel
        </button>
      </IsTruthy>
    </div>
  );
};
export default ModalControl;
