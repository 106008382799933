export const processCustomersData = (props) => {
  const apiData = props?.response?.data;

  const customers = apiData?.customers || [{}];
  const [customer] = customers;

  const {
    addresses = [],
    birthDate = "",
    customerAgreements = [],
    emails = [],
    firstName = "",
    fullName = "",
    genderCode = "",
    governmentIdLast4 = "",
    ids = "",
    lastName = "",
    memberGUID = "",
    memberId = "",
    middleName = "",
    phones = [],
    type = "",
  } = customer;
  return {
    addresses,
    birthDate,
    customerAgreements,
    emails,
    firstName,
    fullName,
    genderCode,
    governmentIdLast4,
    ids,
    lastName,
    memberGUID,
    memberId,
    middleName,
    phones,
    type,
  };
};
