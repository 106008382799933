import React from "react";
import { isDefined } from "../../../../../utils/evaluate";
import IsTruthy from "../../../../common/logic/isTruthy";
import IsFalsy from "../../../../common/logic/isFalsy";
import { LabelWithInformationPopover } from "../../../../common/popovers/informationPopover";
import { compareArrayOfStrings } from "../../../../../utils/string";

const ContractValuesKpiModal = (props) => {
  const {
    idPrefix,
    data,
    displayData: { rows },
    popoverId,
    popoverText,
    displayFundsLink = false,
    handleSeeFundsButtonClick,
    notApplicableFreeAmountOrWithdrawal,
  } = props;

  const rowsToDisplay = rows.map((row) => {
    const { accessor, label, formatFunction } = row;

    const labelId = `${idPrefix}_${accessor}_lbl`;
    const valueId = `${idPrefix}_${accessor}_value`;
    const formattedValue = isDefined(formatFunction)
      ? formatFunction(data[accessor])
      : data[accessor];

    const isNotApplicableFreeAmountOrWithdrawal =
      compareArrayOfStrings(["freeAmount", "freeWithdrawal"], accessor) &&
      notApplicableFreeAmountOrWithdrawal;

    return (
      <React.Fragment key={accessor}>
        <IsFalsy value={isNotApplicableFreeAmountOrWithdrawal}>
          <tr key={accessor}>
            <td id={labelId}>{label}</td>
            <td id={valueId}>{formattedValue}</td>
          </tr>
        </IsFalsy>
        <IsTruthy value={isNotApplicableFreeAmountOrWithdrawal}>
          <tr key={accessor}>
            <td id={labelId}>{label}</td>
            <td id={valueId}>
              {formattedValue}
              <LabelWithInformationPopover
                popoverId={popoverId}
                text={popoverText}
                placement="bottom"
              />
            </td>
          </tr>
        </IsTruthy>
      </React.Fragment>
    );
  });

  return (
    <div className="modal-body">
      <table className="mm-table-figures">
        <tbody>{rowsToDisplay}</tbody>
        <IsTruthy value={displayFundsLink}>
          <br />
          <p className="hide-print">
            For additional fund information, click{" "}
            <button
              className="btn btn-inline"
              id="see_funds_link"
              type="button"
              onClick={handleSeeFundsButtonClick}
            >
              here
            </button>
          </p>
        </IsTruthy>
      </table>
    </div>
  );
};

export default ContractValuesKpiModal;
