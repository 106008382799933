import produce from "immer";

const types = {
  LOG_IN_AGENT: "LOG_IN_AGENT",
  LOG_OUT_AGENT: "LOG_OUT_AGENT",
};

const initialAgentProfileState = {
  agentProfileRequest: {},
  agentProfileData: {
    communications: [],
  },
};

// eslint-disable-next-line default-param-last
const reducer = produce((state = initialAgentProfileState, action) => {
  const { type, ...props } = action;
  const { agentProfileRequest } = props;
  const agentProfileData = agentProfileRequest?.data || {};
  switch (type) {
    case types.LOG_IN_AGENT: {
      return {
        ...state,
        agentProfileData,
        agentProfileRequest,
      };
    }
    case types.LOG_OUT_AGENT:
      return initialAgentProfileState;
    default:
      return state;
  }
});

export { types, initialAgentProfileState, reducer };
