import { useEffect } from "react";
import { AGREEMENT_NOT_FOUND } from "../../../../constants/ui";
import { useUserState } from "../../../../contexts/user";
import { useMakeRequest } from "../../../../hooks/api";
import { formatDate } from "../../../../utils/dates";
import { isDefined } from "../../../../utils/evaluate";
import { getTrimmedPolicyIdFromAgreement } from "../../../../utils/findData";
import {
  formatContactUsDateTime,
  formatContactUsNumber,
  formatMoney,
  formatTransactionDescription,
} from "../../../../utils/format";
import { compareStrings } from "../../../../utils/string";
import { contactUSDetails } from "../../../../data/contactUsDetails";
import {
  isAnnuityContract,
  isCoverpathPolicy,
} from "../../../../utils/policies";

export const useFinancialHistorySectionProps = (props) => {
  let financialHistorySectionTableRows = {};
  let shouldShowFinancialHistoryExtendedPageLink = false;
  let topThreeFinancialHistorySectionTableRows = {};
  let financialHistoryColumns = {};
  const { agreementKey, agreementRequest } = props;

  const {
    contactUsNumber,
    contactUsTime,
    agreementKeyAdmin,
    lineOfBusinessCode,
  } = agreementRequest.data;

  const financialHistoryRequest = useMakeRequest({
    apiRequest: "fetchFinancialHistory",
    apiParams: { agreementKey },
    dataSelector: "processFinancialHistoryRequest",
    immediateRequest: false,
    defaultReturn: [],
  });

  const { isValidAgreementRequest, isUnauthorized, type } =
    getAgreementRequestData({
      dependentAgreementData: agreementRequest?.data,
    });
  useEffect(() => {
    if (isValidAgreementRequest) {
      financialHistoryRequest.executeRequest();
    }
  }, [isUnauthorized, type]);

  const {
    data: { financialHistory, financialHistoryAvailable },
  } = financialHistoryRequest;

  const isFinancialHistoryDataLoaded = isDefined(financialHistory);
  if (isFinancialHistoryDataLoaded) {
    financialHistorySectionTableRows = getTableRows({
      financialHistory,
      financialHistoryAvailable,
      agreementKeyAdmin,
    });
    shouldShowFinancialHistoryExtendedPageLink =
      financialHistorySectionTableRows.length > 3;

    topThreeFinancialHistorySectionTableRows =
      financialHistorySectionTableRows?.slice(0, 3) || [];
    financialHistoryColumns = [...financialHistorySectionTableColumns];
    if (
      topThreeFinancialHistorySectionTableRows.some(
        (k) => k.contributionTaxYear !== "-"
      )
    ) {
      financialHistoryColumns.splice(
        2,
        0,
        makeColumn("contributionTaxYear", "Contribution Year", "fh")
      );
    }
  }

  const {
    user: { email },
  } = useUserState();
  const { trimmedPolicyId } = getTrimmedPolicyIdFromAgreement(agreementKey);
  const defaultSortField = "submissionDate";
  const isHAPPolicy = isCoverpathPolicy(agreementKeyAdmin);
  const isAnnuityProduct = isAnnuityContract(lineOfBusinessCode);
  return {
    table: {
      rows: topThreeFinancialHistorySectionTableRows,
      columns: financialHistoryColumns,
    },
    financialHistoryRequest,
    shouldShowFinancialHistoryExtendedPageLink,
    agreementKey,
    email,
    policyId: trimmedPolicyId,
    defaultSortField,
    contactUsNumber: formatContactUsNumber(
      contactUsNumber,
      contactUSDetails.DEFAULT.number
    ),
    contactUsTime: formatContactUsDateTime(
      contactUsTime,
      contactUSDetails.DEFAULT.time
    ),
    isHAPPolicy,
    isAnnuityProduct,
  };
};

const financialHistorySectionTableColumns = [
  {
    accessor: "submissionDate",
    label: "Process Date",
    alignment: "left",
    sectionAbbreviation: "fh",
  },
  {
    accessor: "effectiveDate",
    label: "Effective Date",
    alignment: "left",
    sectionAbbreviation: "fh",
  },
  {
    accessor: "transactionDesc",
    label: "Transaction",
    alignment: "left",
    sectionAbbreviation: "fh",
  },
  {
    accessor: "totalPayment",
    label: "Amount",
    alignment: "left",
    sectionAbbreviation: "fh",
  },
];

const makeColumn = (accessor, label, sectionAbbreviation) => ({
  accessor,
  label,
  alignment: "left",
  sectionAbbreviation,
});

export const getAgreementRequestData = ({ dependentAgreementData }) => {
  const { isUnauthorized } = dependentAgreementData;
  const { type, description } = dependentAgreementData?.messages || {};
  const isValidAgreementRequest =
    !isUnauthorized &&
    compareStrings(type, "SUCCESS") &&
    !compareStrings(description, AGREEMENT_NOT_FOUND);
  return { isValidAgreementRequest, isUnauthorized, type };
};

const getPE1Transaction = (transactionProps) => {
  const { transactionCode } = transactionProps;
  return formatTransactionDescription(transactionCode);
};
export const getTableRows = ({
  financialHistory,
  financialHistoryAvailable,
  agreementKeyAdmin,
}) => {
  const getRetireEaseAnnuityRows = (item, index) => {
    const {
      submissionDate,
      effectiveDate,
      transactionCode,
      transactionDesc,
      totalPayment,
      contributionTaxYear,
    } = item;

    const isPE1Policy = compareStrings(agreementKeyAdmin, "PE1");
    let transactionDescription = "-";
    if (isPE1Policy) {
      transactionDescription = isDefined(transactionCode)
        ? getPE1Transaction({ transactionCode })
        : "";
    } else {
      transactionDescription = isDefined(transactionDesc)
        ? getFinancialHistoryTransactionDescription(transactionDesc)
        : "-";
    }
    return {
      id: index,
      submissionDate: formatDate(submissionDate, "monthCommaDayYear", "-"),
      effectiveDate: formatDate(effectiveDate, "monthCommaDayYear", "-"),
      transactionDesc: transactionDescription,
      totalPayment: formatMoney(totalPayment, "-"),
      contributionTaxYear:
        compareStrings(contributionTaxYear, "9999") ||
        !isDefined(contributionTaxYear)
          ? "-"
          : contributionTaxYear,
    };
  };

  if (financialHistoryAvailable) {
    return financialHistory.map(getRetireEaseAnnuityRows);
  }
  return [];
};

export const getFinancialHistoryTransactionDescription = (transactionDesc) => {
  let transactionDescription = "";

  switch (transactionDesc.toLowerCase().trim()) {
    case "annuity payment":
      transactionDescription = "Periodic Payment";
      break;
    case "premium payment":
      transactionDescription = "Contributions";
      break;
    default:
      transactionDescription = transactionDesc;
  }

  return transactionDescription;
};
