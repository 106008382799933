import React from "react";
import { fireTealiumEvent } from "../../../../utils/tealium";
import { useUserState } from "../../../../contexts/user";
import IsTruthy from "../../logic/isTruthy";
import IsFalsy from "../../logic/isFalsy";
import SpinnerLoading from "../../loading/spinnerLoading";

const DownloadCell = (props) => {
  const {
    row,
    rowIndex,
    column: { accessor, showIcon, showLoadingIcon, isCalledFrom },
    customFunctions,
  } = props;
  const {
    user: { email },
  } = useUserState();
  const cashValueType = row?.id === "0" ? "current" : "historical";

  const downloadDocument = () => {
    fireTealiumEvent({
      label: "SVNT",
      action: `${isCalledFrom} Download Button click`,
      category: `${isCalledFrom} Page`,
      custId: email,
      value: row[accessor],
    });
    customFunctions.generateAndDownloadPDF(row);
  };

  return (
    <button
      id={`${accessor}_${rowIndex}_link`}
      onClick={downloadDocument}
      className="btn btn-inline"
      type="button"
    >
      <IsTruthy value={showLoadingIcon}>
        <SpinnerLoading loadingIconSize="sm" />
      </IsTruthy>
      <IsFalsy value={showLoadingIcon}>
        {showIcon ? <span className="icon-sm icon-download" /> : ""}{" "}
        <span
          id={`${accessor}_${rowIndex}_${cashValueType}_value`}
          dangerouslySetInnerHTML={{ __html: row[accessor] }}
        />
      </IsFalsy>
    </button>
  );
};

export default React.memo(DownloadCell);
