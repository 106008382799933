import React from "react";
import RadioForm from "../../../common/forms/radioForm";
import IsTruthy from "../../../common/logic/isTruthy";
import { isDefined } from "../../../../utils/evaluate";
import { INITIATE_DEATH_CLAIM_BENE_MAX_COUNT_REACHED_MESSAGE } from "../../../../constants/ui";
import ContactInformationCard from "../common/contactInformationCard";
import {
  useBeneClaimRecipientContactModalProps,
  useBeneficiaryInformationQuestionAnswers,
} from "../../hooks";

export const BeneContactCards = (props) => {
  const { beneContacts } = props;

  return beneContacts.map((beneContactFields, index) => {
    const keyName = `beneContact${index}`;
    return (
      <ContactInformationCard
        key={keyName}
        formField={beneContactFields}
        cardType="beneCard"
        useEditHook={useBeneClaimRecipientContactModalProps}
      />
    );
  });
};

const BeneficiaryInformationQuestionAnswers = (props) => {
  const {
    beneContactQuestionYes,
    beneContactQuestionNo,
    beneTypeQuestionIndividual,
    beneTypeQuestionMultipleIndividuals,
    beneTypeQuestionTrust,
    beneTypeQuestionEstate,
    beneTypeQuestionCorporation,
    beneTypeQuestionMultipleArrangements,
    beneTypeQuestionNoBeneficiary,
    beneTypeQuestionUnknown,
    beneContacts,
    displayAddBeneContactFields,
    beneficiaryMaxCountReached,
    displaybeneContactQuestion,
    addBeneContact,
  } = useBeneficiaryInformationQuestionAnswers(props);

  return (
    <>
      <p className="h4" id="beneTypeQuestion">
        Which beneficiary type best describes the beneficiary arrangement(s)
        listed above?
      </p>
      <p className="sub-copy" id="bene_type_question_note">
        Note: This information will help us expedite assigning the claim.
      </p>
      <div className="row mm-spacing--minor">
        <div className="col-12">
          <RadioForm formField={beneTypeQuestionIndividual} />
        </div>
        <div className="col-12">
          <RadioForm formField={beneTypeQuestionMultipleIndividuals} />
        </div>
        <div className="col-12">
          <RadioForm formField={beneTypeQuestionTrust} />
        </div>
        <div className="col-12">
          <RadioForm formField={beneTypeQuestionEstate} />
        </div>
        <div className="col-12">
          <RadioForm formField={beneTypeQuestionCorporation} />
        </div>
        <div className="col-12">
          <RadioForm formField={beneTypeQuestionMultipleArrangements} />
        </div>
        <div className="col-12">
          <RadioForm formField={beneTypeQuestionNoBeneficiary} />
        </div>
        <div className="col-12">
          <RadioForm formField={beneTypeQuestionUnknown} />
        </div>
      </div>
      <IsTruthy value={isDefined(displaybeneContactQuestion)}>
        <p className="h4" id="beneContactQuestion">
          Do you have the beneficiary(ies) contact information to provide to the
          Claim Examiner?
        </p>
        <p className="sub-copy" id="beneContactQuestionNote">
          Note: The Claim Examiner will <u>only</u> use this information to
          service the claim. This information does not impact the current
          beneficiary arrangement.
        </p>
        <div className="row mm-spacing--minor">
          <div className="col-12">
            <RadioForm formField={beneContactQuestionYes} />
          </div>
          <div className="col-12">
            <RadioForm formField={beneContactQuestionNo} />
          </div>
        </div>
        <IsTruthy value={displayAddBeneContactFields}>
          <div className="row">
            <BeneContactCards beneContacts={beneContacts} />
          </div>
          <div className="mm-spacing">
            <IsTruthy value={beneficiaryMaxCountReached}>
              <div
                id="beneficiaryMaxCountReachedAlert"
                className="alert alert-info"
                role="alert"
              >
                {INITIATE_DEATH_CLAIM_BENE_MAX_COUNT_REACHED_MESSAGE}
              </div>
            </IsTruthy>
            <button
              id="btnAddBene"
              type="button"
              disabled={beneficiaryMaxCountReached}
              className="btn btn-secondary"
              onClick={addBeneContact}
            >
              <span className="icon-add" /> Add Contact
            </button>
          </div>
        </IsTruthy>
      </IsTruthy>
    </>
  );
};

export default React.memo(BeneficiaryInformationQuestionAnswers);
