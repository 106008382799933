import React from "react";
import { fireTealiumAndFollowLink } from "../../../../utils/tealium";
import { useUserState } from "../../../../contexts/user";
import { compareStrings } from "../../../../utils/string";
import IsTruthy from "../../logic/isTruthy";
import { sanitizeData } from "../../../../utils/sanitizeData";

export const getLink = (props) => {
  const { row, accessor } = props;

  switch (accessor) {
    case "policyId":
    case "agreementKeyPrimaryDisplay":
    case "notificationPolicyNumber":
      return `/policyDetails/${encodeURIComponent(row.agreementKey)}`;
    default:
      return "/invalidLink";
  }
};

const Links = (props) => {
  const {
    row,
    rowIndex,
    column: { accessor, linkTargetValue = "false" },
  } = props;

  const urlLink = getLink({ accessor, row });
  const {
    user: { email },
  } = useUserState();

  const buttonClass = row.newNotification
    ? "btn btn-inline"
    : "btn btn-inline no-indicator";

  if (compareStrings(accessor, "notificationPolicyNumber")) {
    return (
      <>
        <IsTruthy value={row.newNotification}>
          <span
            className="badge badge-indicator--flat"
            data-testid="new-notification-indicator"
            id={`new-notification-indicator_${rowIndex}`}
          />{" "}
        </IsTruthy>
        <button
          type="button"
          id={`${accessor}_${rowIndex}_link`}
          target={linkTargetValue}
          onClick={() =>
            fireTealiumAndFollowLink({
              url: urlLink,
              action:
                "Notifications Table Policy Number clicked and redirect to Policy Details page",
              category: "Search Result",
              value: row[accessor],
              custId: email,
              target: { linkTargetValue },
            })
          }
          className={buttonClass}
        >
          <span
            id={`${accessor}_${rowIndex}_value`}
            dangerouslySetInnerHTML={{ __html: sanitizeData(row[accessor]) }}
          />
        </button>
      </>
    );
  }

  return (
    <span name="link">
      <button
        type="button"
        id={`${accessor}_${rowIndex}_link`}
        target={linkTargetValue}
        onClick={() =>
          fireTealiumAndFollowLink({
            url: urlLink,
            action:
              "Search Result Table PolicyID clicked and redirect to PolicyDetails page",
            category: "Search Result",
            value: row[accessor],
            custId: email,
            target: { linkTargetValue },
          })
        }
        className="btn btn-inline"
      >
        <span
          id={`${accessor}_${rowIndex}_value`}
          dangerouslySetInnerHTML={{ __html: row[accessor] }}
        />
      </button>
    </span>
  );
};

export default React.memo(Links);
