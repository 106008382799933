import React from "react";
import { INITIATE_DEATH_CLAIM_DECEDENT_DISABILITY_POLICY_INSURED_MESSAGE } from "../../../../constants/ui";
import { useFormContext } from "../../../../contexts/form";
import InputDatePicker from "../../../common/datepicker/inputDatePicker";
import DropdownForm from "../../../common/forms/dropdownForm";
import InputForm from "../../../common/forms/inputForm";
import IsTruthy from "../../../common/logic/isTruthy";
import {
  calculateDecedentInformationQuestionDisplay,
  useDecedentInfoQuestionsAndAnswersProps,
} from "../../hooks";
import DecedentInformationAnswers from "./decedentInformationAnswers";
import DecedentInformationQuestions from "./decedentInformationQuestions";

const DecedentInfoQuestionsAndAnswers = (props) => {
  const { decedentInfoQuestionsText } = props;
  const { formData } = useFormContext();

  const {
    decedentInfoQuestion2DateHandler,
    decedentInfoQuestion4FormerSpouseDateOfDivorceHandler,
    decedentInfoQuestion5FormerSpouseDateOfDivorceHandler,
    questionKeys,
    shouldDisplayDecedentInfoQuestion3,
    isDecedentInsuredOnDisabilityPolicy,
    shouldDisplayDecedentInfoQuestion5,
  } = useDecedentInfoQuestionsAndAnswersProps();

  const {
    decedentInfoQuestion1No,
    decedentInfoQuestion2Yes,
    decedentInfoQuestion1Country,
    decedentInfoQuestion2Date,
    decedentInfoQuestion2No,
    decedentInfoQuestion4Married,
    decedentInfoQuestion4WidowOrWidower,
    decedentInfoQuestion4SpouseFirstName,
    decedentInfoQuestion4SpouseLastName,
    decedentInfoQuestion4Divorced,
    decedentInfoQuestion4DivorceOccuredState,
    decedentInfoQuestion4FormerSpouseDateOfDivorce,
    decedentInfoQuestion4FormerSpouseLastName,
    decedentInfoQuestion4FormerSpouseFirstName,
    decedentInfoQuestion6Yes,
    decedentInfoQuestion6DisabilityClaimNumber,
    decedentInfoQuestion6DisabilityClaimNumberYes,
    decedentInfoQuestion6DisabilityClaimNumberNo,
    decedentInfoQuestion6No,
    decedentInfoQuestion5Yes,
    decedentInfoQuestion5DivorceOccuredState,
    decedentInfoQuestion5FormerSpouseDateOfDivorce,
    decedentInfoQuestion5FormerSpouseLastName,
    decedentInfoQuestion5FormerSpouseFirstName,
  } = formData;

  const questionsAndAnswerMarkup = questionKeys.map((key, i) => {
    const { question, subQuestion } = decedentInfoQuestionsText[i];

    const questionNumber = i + 1;
    const Markup = (
      <>
        <DecedentInformationQuestions
          shouldDisplayDecedentInfoQuestion3={
            shouldDisplayDecedentInfoQuestion3
          }
          questionNumber={questionNumber}
          shouldDisplayDecedentInfoQuestion5={
            shouldDisplayDecedentInfoQuestion5
          }
          isDecedentInsuredOnDisabilityPolicy={
            isDecedentInsuredOnDisabilityPolicy
          }
          question={question}
        />
        <div className="row">
          <div className="col-12">
            <IsTruthy value={questionNumber === 1}>
              <DecedentInformationAnswers
                formData={formData}
                questionNumber={questionNumber}
              />
              <IsTruthy value={decedentInfoQuestion1No.value}>
                <div className="row">
                  <div className="col col-md-6">
                    <DecedentInformationQuestions
                      questionNumber={questionNumber}
                      question={subQuestion}
                      isSubQuestion
                    />
                    <DropdownForm formField={decedentInfoQuestion1Country} />
                  </div>
                </div>
              </IsTruthy>
            </IsTruthy>

            <IsTruthy value={questionNumber === 2}>
              <DecedentInformationAnswers
                formData={formData}
                questionNumber={questionNumber}
                yesFormField={decedentInfoQuestion2Yes}
                noFormField={decedentInfoQuestion2No}
                isYesorNoAnswers
              />

              <IsTruthy value={decedentInfoQuestion2Yes.value}>
                <div className="row">
                  <div className="col col-md-12">
                    <InputDatePicker
                      id={decedentInfoQuestion2Date.id}
                      datePickerMinDate={decedentInfoQuestion2Date.minDate}
                      datePickerMaxDate={decedentInfoQuestion2Date.maxDate}
                      selectedDate={decedentInfoQuestion2Date.value}
                      dateHandler={decedentInfoQuestion2DateHandler}
                      errorId={decedentInfoQuestion2Date.id}
                      label={decedentInfoQuestion2Date.label}
                    />
                  </div>
                </div>
              </IsTruthy>
            </IsTruthy>
            <IsTruthy
              value={
                questionNumber === 3 &&
                shouldDisplayDecedentInfoQuestion3 === true
              }
            >
              <DecedentInformationAnswers
                formData={formData}
                questionNumber={questionNumber}
              />
            </IsTruthy>
            <IsTruthy value={questionNumber === 4}>
              <DecedentInformationAnswers
                formData={formData}
                questionNumber={questionNumber}
              />
              <IsTruthy
                value={
                  decedentInfoQuestion4Married.value ||
                  decedentInfoQuestion4WidowOrWidower.value
                }
              >
                <div className="row">
                  <div className="col col-md-6">
                    <InputForm
                      formField={decedentInfoQuestion4SpouseFirstName}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col col-md-6">
                    <InputForm
                      formField={decedentInfoQuestion4SpouseLastName}
                    />
                  </div>
                </div>
              </IsTruthy>
              <IsTruthy value={decedentInfoQuestion4Divorced.value}>
                <div className="row">
                  <div className="col col-md-6">
                    <InputForm
                      formField={decedentInfoQuestion4FormerSpouseFirstName}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col col-md-6">
                    <InputForm
                      formField={decedentInfoQuestion4FormerSpouseLastName}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col col-md-6">
                    <InputDatePicker
                      id={decedentInfoQuestion4FormerSpouseDateOfDivorce.id}
                      datePickerMinDate={
                        decedentInfoQuestion4FormerSpouseDateOfDivorce.minDate
                      }
                      datePickerMaxDate={
                        decedentInfoQuestion4FormerSpouseDateOfDivorce.maxDate
                      }
                      selectedDate={
                        decedentInfoQuestion4FormerSpouseDateOfDivorce.value
                      }
                      dateHandler={
                        decedentInfoQuestion4FormerSpouseDateOfDivorceHandler
                      }
                      errorId={
                        decedentInfoQuestion4FormerSpouseDateOfDivorce.id
                      }
                      label={
                        decedentInfoQuestion4FormerSpouseDateOfDivorce.label
                      }
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col col-md-6">
                    <DropdownForm
                      formField={decedentInfoQuestion4DivorceOccuredState}
                    />
                  </div>
                </div>
              </IsTruthy>
            </IsTruthy>
            <IsTruthy
              value={
                questionNumber === 5 &&
                (decedentInfoQuestion4Married.value ||
                  decedentInfoQuestion4WidowOrWidower.value)
              }
            >
              <DecedentInformationAnswers
                formData={formData}
                questionNumber={questionNumber}
              />
              <IsTruthy value={decedentInfoQuestion5Yes.value}>
                <div className="row">
                  <div className="col col-md-6">
                    <InputForm
                      formField={decedentInfoQuestion5FormerSpouseFirstName}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col col-md-6">
                    <InputForm
                      formField={decedentInfoQuestion5FormerSpouseLastName}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col col-md-6">
                    <InputDatePicker
                      id={decedentInfoQuestion5FormerSpouseDateOfDivorce.id}
                      datePickerMinDate={
                        decedentInfoQuestion5FormerSpouseDateOfDivorce.minDate
                      }
                      datePickerMaxDate={
                        decedentInfoQuestion5FormerSpouseDateOfDivorce.maxDate
                      }
                      selectedDate={
                        decedentInfoQuestion5FormerSpouseDateOfDivorce.value
                      }
                      dateHandler={
                        decedentInfoQuestion5FormerSpouseDateOfDivorceHandler
                      }
                      errorId={
                        decedentInfoQuestion5FormerSpouseDateOfDivorce.id
                      }
                      label={
                        decedentInfoQuestion5FormerSpouseDateOfDivorce.label
                      }
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col col-md-6">
                    <DropdownForm
                      formField={decedentInfoQuestion5DivorceOccuredState}
                    />
                  </div>
                </div>
              </IsTruthy>
            </IsTruthy>
            <IsTruthy
              value={
                questionNumber === 6 && isDecedentInsuredOnDisabilityPolicy
              }
            >
              <DecedentInformationAnswers
                formData={formData}
                questionNumber={questionNumber}
                yesFormField={decedentInfoQuestion6Yes}
                noFormField={decedentInfoQuestion6No}
                isYesorNoAnswers
              />
              <IsTruthy value={decedentInfoQuestion6Yes.value}>
                <DecedentInformationQuestions
                  questionNumber={questionNumber}
                  question={subQuestion}
                  isDecedentInsuredOnDisabilityPolicy={
                    isDecedentInsuredOnDisabilityPolicy
                  }
                  isSubQuestion
                />
                <DecedentInformationAnswers
                  formData={formData}
                  questionNumber={questionNumber}
                  yesFormField={decedentInfoQuestion6DisabilityClaimNumberYes}
                  noFormField={decedentInfoQuestion6DisabilityClaimNumberNo}
                  isYesorNoAnswers
                />
                <IsTruthy
                  value={decedentInfoQuestion6DisabilityClaimNumberYes.value}
                >
                  <div className="row">
                    <div className="col col-md-6">
                      <InputForm
                        formField={decedentInfoQuestion6DisabilityClaimNumber}
                      />
                    </div>
                  </div>
                </IsTruthy>
              </IsTruthy>
              <IsTruthy value={decedentInfoQuestion6No.value}>
                <div
                  id="decedentInfo_Disability_Policy_Message"
                  className="alert alert-info"
                  role="alert"
                >
                  {
                    INITIATE_DEATH_CLAIM_DECEDENT_DISABILITY_POLICY_INSURED_MESSAGE
                  }
                </div>
              </IsTruthy>
            </IsTruthy>
          </div>
        </div>
      </>
    );
    return (
      <IsTruthy
        key={key}
        value={calculateDecedentInformationQuestionDisplay({
          questionNumber: i + 1,
          formData,
          shouldDisplayDecedentInfoQuestion3,
        })}
      >
        {Markup}
      </IsTruthy>
    );
  });

  return (
    <div
      className="mm-spacing--minor"
      id="decedentInfo_QuestionAnswer_Container"
    >
      {questionsAndAnswerMarkup}
    </div>
  );
};

export default React.memo(DecedentInfoQuestionsAndAnswers);
