import React from "react";
import InputForm from "../../common/forms/inputForm";

import { useEmailContactProps } from "../hooks";
import Label from "../../common/forms/label";

const EmailContact = (props) => {
  const { ownerNameInput, emailContactInput, emailContactConfirmInput } =
    useEmailContactProps(props);

  return (
    <div className="row mm-spacing">
      <div className="col-12 col-lg-4 mm-spacing-minor--stacked">
        <Label formField={ownerNameInput}>Owner Name</Label>
        <InputForm formField={ownerNameInput} />
      </div>
      <div className="col-6 col-lg-4 col-md-4 mm-spacing-minor--stacked">
        <InputForm formField={emailContactInput} />
      </div>
      <div className="col-6 col-lg-4 col-md-4 mm-spacing-minor--stacked">
        <InputForm formField={emailContactConfirmInput} />
      </div>
    </div>
  );
};

export default React.memo(EmailContact);
