import React from "react";
import { useInputFormProps } from "./hooks";

const TextArea = (props) => {
  const {
    formField: {
      id,
      name,
      value,
      placeholder = "",
      ariaLabel,
      autoComplete = "off",
      maxLength,
      rows = "4",
      cols = "250",
      hidden = false,
    },
    useHook = useInputFormProps,
  } = props;
  const { onChange, className } = useHook(props);
  return (
    <textarea
      id={`textarea-${id}`}
      name={name}
      className={className}
      type="text"
      autoComplete={autoComplete}
      placeholder={placeholder}
      aria-label={ariaLabel}
      value={value}
      hidden={hidden}
      maxLength={maxLength}
      onChange={onChange}
      rows={rows}
      cols={cols}
    />
  );
};

export default TextArea;
