import cloneDeep from "lodash.clonedeep";
import {
  getAllServicingAgent,
  getAllWritingAgent,
  userTypes,
} from "../../../../utils/findData";
import {
  checkForComplexBene,
  removeDuplicates,
  sortBeneficiariesByAllocationThenClass,
  useAgentModal,
  useBeneficiaryModal,
  useCustomerModal,
  formatAgentTable,
} from "../peopleOnPolicy/hooks";
import {
  formatBeneficiaryType,
  formatBeneName,
  formatMoney,
  formatName,
  formatPercentage,
  nameFormattingOptions,
  formatContactUsNumber,
  formatContactUsDateTime,
} from "../../../../utils/format";
import {
  groupAndRemoveBeneficiaryDuplicates,
  findMatchingBeneficiaries,
  isDisabilityPolicy,
  isNonEnvisionAnnuity,
  isRetireEaseContract,
  isFoundationSinglePremium,
  isFoundationFlexiblePremium,
  isEnvisionPolicy,
  isCoverpathPolicy,
} from "../../../../utils/policies";
import { compareStrings } from "../../../../utils/string";
import PersonModalCell from "../peopleOnPolicy/personModalCell";
import Icon from "../../../common/table/customCells/checkmark";
import { isArray, isDefined } from "../../../../utils/evaluate";
import { getRoleName, roleNames } from "../../../../utils/translate";
import { useMakeRequest } from "../../../../hooks/api";
import { contactUSDetails } from "../../../../data/contactUsDetails";

export const usePeopleOnContractProps = (props) => {
  const { agreementRequest, producerData, annuityRequest = {} } = props;
  const agreementData = agreementRequest.data;
  const {
    agreementKey,
    agreementCustomers,
    asOfDate,
    policyId,
    lineOfBusinessCode,
    agreementKeyAdmin,
  } = agreementData;

  const beneficiaryRequest = useMakeRequest({
    apiRequest: "fetchBeneficiaries",
    apiParams: { agreementKey },
    immediateRequest: true,
    dataSelector: "processBeneficiaryRequest",
  });

  const notBeneficiaries = agreementCustomers.filter(
    (customer) => !compareStrings(customer.roleType, userTypes.BENEFICARY)
  );

  const IS_DISABILITY_POLICY = isDisabilityPolicy({ lineOfBusinessCode });

  const isNonEnvisionAnnuityContract = isNonEnvisionAnnuity(agreementRequest);

  const isHapPolicy = isCoverpathPolicy(agreementKeyAdmin);

  const peopleListUnsorted = getPeople(notBeneficiaries, true);
  const peopleList = sortPeopleByRoles(peopleListUnsorted);
  const writingAgents = removeDuplicates(
    getAllWritingAgent({
      agents: producerData.producers,
    })
  );
  const servicingAgents = removeDuplicates(
    getAllServicingAgent({
      agents: producerData.producers,
    })
  );

  const isRetireEase = isRetireEaseContract(agreementData);
  const disableBeneModal =
    isNonEnvisionAnnuityContract &&
    beneficiaryRequest?.data?.source === "legacy" &&
    !isRetireEase;
  let complexBeneficiaries = [];
  let displayNoBeneInfoAlert = true;
  let showFootnote = false;
  const beneficiariesFormatted = beneficiaryRequest.data.beneficiaryArray
    ?.filter((beneficiary) => {
      if (
        isDefined(beneficiary.nameObject.firstName) ||
        isDefined(beneficiary.nameObject.lastName) ||
        isDefined(beneficiary.nameObject.fullName) ||
        isDefined(beneficiary.beneficiaryDesignationDescription) ||
        (isDefined(beneficiary.beneficiaryType) &&
          !compareStrings(beneficiary.beneficiaryType, "-"))
      ) {
        displayNoBeneInfoAlert = false;
      }
      const isComplexBeneficiary = checkForComplexBene(beneficiary);

      if (isComplexBeneficiary) {
        if (isNonEnvisionAnnuityContract) {
          complexBeneficiaries.push(beneficiary);
        } else {
          complexBeneficiaries = [beneficiary];
        }
      }

      return !isComplexBeneficiary;
    })
    .map((beneficiary) => {
      const { amount, percent } = beneficiary;
      let allocation = `-`;

      if (isDefined(amount)) {
        allocation = formatMoney(amount, "-");
      } else if (isDefined(percent)) {
        allocation = formatPercentage({
          rate: percent,
          divide: true,
          maxDecimalPlaces: 2,
        });
      }

      if (!isDefined(amount) && !isDefined(percent)) {
        showFootnote = true;
      }

      const formattedBeneName = formatBeneName({
        beneType: beneficiary.beneficiaryType,
        nameObject: beneficiary.nameObject,
        isHapPolicy,
      });

      const formattedBeneficiaryClass =
        compareStrings(lineOfBusinessCode, "ANN") &&
        compareStrings(beneficiary.beneficiaryClass, "Secondary")
          ? "Contingent"
          : beneficiary.beneficiaryClass;

      return {
        ...beneficiary,
        beneficiaryName: formattedBeneName,
        beneficiaryType: formatBeneficiaryType(beneficiary.beneficiaryType),
        beneficiaryClass: formattedBeneficiaryClass,
        allocation,
        asOfDate,
        showFootnoteModal: !isDefined(amount) && !isDefined(percent),
        disableBeneModal,
        isRetireEase,
      };
    });

  const beneficiariesSortByBeneficiaryClass =
    sortBeneficiariesByAllocationThenClass(beneficiariesFormatted);

  const { showModal: showCustomerModal } = useCustomerModal({
    IS_DISABILITY_POLICY,
    agreementKey,
    agreementData,
    annuityRequest,
  });
  const { showModal: showAgentModal } = useAgentModal(agreementData);
  const { showModal: showBeneficiaryModal } = useBeneficiaryModal();

  const peopleOnContractDefaultColumns =
    getDefaultPeopleOnContractColumns(agreementData);
  const peopleOnContractColumns = addOptionalPeopleOnPolicyColumns(
    peopleOnContractDefaultColumns,
    peopleList,
    agreementData
  );

  const formattedContactUsNumber = formatContactUsNumber(
    props?.agreementRequest?.data?.contactUsNumber,
    contactUSDetails.DEFAULT.number
  );
  const formattedContactUsTime = formatContactUsDateTime(
    props?.agreementRequest?.data?.contactUsTime,
    contactUSDetails.DEFAULT.time
  );

  const contactUsInfo = {
    formattedContactUsNumber,
    formattedContactUsTime,
  };

  const sortedComplexBenes = isNonEnvisionAnnuityContract
    ? formatAndSortComplexBenes(complexBeneficiaries)
    : complexBeneficiaries;

  return {
    requests: {
      beneficiaryRequest,
      producerData,
    },
    data: {
      agreementData,
      peopleOnContractColumns,
      peopleList,
      agreementKey,
      columnsBeneficiary: getBeneficiaryColumnByPolicyType(agreementData),
      beneficiariesSortByBeneficiaryClass,
      complexBeneficiaries: sortedComplexBenes,
      displayNoBeneInfoAlert,
      showFootnote,
      policyId,
      contactUsInfo,
      isNonEnvisionAnnuityContract,
      agentTable: formatAgentTable({ servicingAgents, writingAgents }),
    },
    functions: {
      showCustomerModal,
      showAgentModal,
      showBeneficiaryModal,
    },
  };
};

const defaultPeopleOnContractColumns = [
  {
    accessor: "customerName",
    label: "Name",
    alignment: "left",
    CustomCell: PersonModalCell,
  },
  {
    accessor: "owner",
    label: "Owner",
    alignment: "center",
    CustomCell: Icon,
    iconName: "check",
    iconColor: "green",
  },
  {
    accessor: "annuitant",
    label: "Annuitant",
    alignment: "center",
    CustomCell: Icon,
    iconName: "check",
    iconColor: "green",
  },
  {
    accessor: "premiumPayor",
    label: "Payor",
    alignment: "center",
    CustomCell: Icon,
    iconName: "check",
    iconColor: "green",
  },
];

const columnsBeneficiary = [
  {
    accessor: "beneficiaryName",
    label: "Name",
    alignment: "left",
    CustomCell: PersonModalCell,
  },
  {
    accessor: "beneficiaryClass",
    label: "Class",
    alignment: "left",
  },
  {
    accessor: "allocation",
    label: "Distribution",
    alignment: "left",
  },
  {
    accessor: "relationship",
    label: "Relationship to Owner",
    alignment: "left",
  },
];

export const getPeople = (
  notBeneficiaries,
  isCalledFromPeopleOnPolicyOrContract = false
) => {
  if (!isArray(notBeneficiaries)) {
    return [];
  }
  const condensedNonBenes = notBeneficiaries.reduce((people, customer) => {
    const { roleType, roleSubType } = customer;
    const roleName = getRoleName(roleType, roleSubType);
    let matchingPeople;
    if (isCalledFromPeopleOnPolicyOrContract) {
      matchingPeople = groupAndRemoveBeneficiaryDuplicates(customer, people);
    } else {
      matchingPeople = people.filter((person) =>
        compareStrings(customer.memberGUID, person.memberGUID)
      );
    }
    const PERSON_ALREADY_EXISTS = matchingPeople.length > 0;
    if (PERSON_ALREADY_EXISTS) {
      return people.map((person) => {
        let CORRECT_PERSON;
        if (isCalledFromPeopleOnPolicyOrContract) {
          const { MEM_ID_MATCH, FULL_NAME_MATCH, NAME_MATCH, GOV_ID_MATCH } =
            findMatchingBeneficiaries(person, customer);

          if (compareStrings(person.type, "I")) {
            CORRECT_PERSON = MEM_ID_MATCH && NAME_MATCH && GOV_ID_MATCH;
          } else if (compareStrings(person.type, "N")) {
            CORRECT_PERSON = MEM_ID_MATCH && FULL_NAME_MATCH && GOV_ID_MATCH;
          }
        } else {
          CORRECT_PERSON = compareStrings(
            customer.memberGUID,
            person.memberGUID
          );
        }
        if (CORRECT_PERSON) {
          return {
            ...person,
            owner: person.owner
              ? person.owner
              : compareStrings(userTypes.OWNER, roleType) &&
                compareStrings(roleSubType, "LIST"),
            jointOwner: person.jointOwner
              ? person.jointOwner
              : compareStrings(userTypes.OWNER, roleType) &&
                compareStrings(roleSubType, "JNT"),
            annuitant: person.annuitant
              ? person.annuitant
              : compareStrings(userTypes.INSURED, roleType) &&
                compareStrings(roleSubType, "PRMR"),
            jointAnnuitant: person.jointAnnuitant
              ? person.jointAnnuitant
              : compareStrings(userTypes.INSURED, roleType) &&
                compareStrings(roleSubType, "JNT"),
            premiumPayor: person.premiumPayor
              ? person.premiumPayor
              : compareStrings(roleType, userTypes.PREMIUM_PAYER),
            assignee: person.assignee
              ? person.assignee
              : compareStrings(roleType, userTypes.ASSIGNEE),
            powerOfAttorney: person.powerOfAttorney
              ? person.powerOfAttorney
              : compareStrings(roleType, userTypes.POWER_OF_ATTORNEY),
            alternateAddressee: person.alternateAddressee
              ? person.alternateAddressee
              : compareStrings(roleType, userTypes.ALTERNATE_ADDRESSEE) &&
                compareStrings(roleSubType, "SECA"),
            payee: person.payee
              ? person.payee
              : compareStrings(roleType, userTypes.ALTERNATE_ADDRESSEE) &&
                compareStrings(roleSubType, "PAY"),
            roles: person.roles ? [...person.roles, roleName] : [roleName],
            arrayRoleType: person.arrayRoleType.concat(customer.roleType),
            arrayRoleSubType: person.arrayRoleSubType.concat(
              customer.roleSubType
            ),
          };
        }
        return person;
      });
    }
    const newCustomer = {
      ...customer,
      customerName: formatName({
        nameObject: customer,
        format: nameFormattingOptions.firstNameDisplayedFirst,
      }),
      owner:
        compareStrings(userTypes.OWNER, roleType) &&
        compareStrings(roleSubType, "LIST"),
      jointOwner:
        compareStrings(userTypes.OWNER, roleType) &&
        compareStrings(roleSubType, "JNT"),
      annuitant:
        compareStrings(userTypes.INSURED, roleType) &&
        compareStrings(roleSubType, "PRMR"),
      jointAnnuitant:
        compareStrings(userTypes.INSURED, roleType) &&
        compareStrings(roleSubType, "JNT"),
      premiumPayor: compareStrings(roleType, userTypes.PREMIUM_PAYER),
      assignee: compareStrings(roleType, userTypes.ASSIGNEE),
      powerOfAttorney: compareStrings(roleType, userTypes.POWER_OF_ATTORNEY),
      alternateAddressee:
        compareStrings(roleType, userTypes.ALTERNATE_ADDRESSEE) &&
        compareStrings(roleSubType, "SECA"),
      payee:
        compareStrings(roleType, userTypes.ALTERNATE_ADDRESSEE) &&
        compareStrings(roleSubType, "PAY"),
      roles: roleName ? [roleName] : null,
      arrayRoleType: [customer.roleType],
      arrayRoleSubType: [customer.roleSubType],
    };
    return people.concat(newCustomer);
  }, []);

  const condensedNonBeneRolesSorted = condensedNonBenes.map((nonBene) => {
    const sortedRoles = nonBene.roles
      ? sortRoles(nonBene.roles)
          .map((role) => {
            if (compareStrings(role, "Owner") && nonBene.jointOwner)
              return "Joint Owner";
            if (compareStrings(role, "Insured")) {
              if (nonBene.jointAnnuitant) return "Joint Annuitant";
              return "Annuitant";
            }
            if (compareStrings(role, "Payee")) {
              return "Payee";
            }
            if (compareStrings(role, "Premium Payor")) {
              return "Payor";
            }
            return role;
          })
          .join(", ")
      : "-";

    return {
      ...nonBene,
      roles: sortedRoles,
    };
  });
  return condensedNonBeneRolesSorted;
};

export const sortRoles = (roles) => {
  const sortedRoles = [];
  if (roles.includes(roleNames.OWNR)) {
    sortedRoles.push(roleNames.OWNR);
  }
  if (roles.includes(roleNames.INSD)) {
    sortedRoles.push(roleNames.INSD);
  }
  if (roles.includes(roleNames.PAY)) {
    sortedRoles.push(roleNames.PAY);
  }
  if (roles.includes(roleNames.PPAY)) {
    sortedRoles.push(roleNames.PPAY);
  }
  return sortedRoles;
};

export const sortPeopleByRoles = (peopleList) => {
  const sortOrder = [
    "owner",
    "jointOwner",
    "annuitant",
    "jointAnnuitant",
    "premiumPayor",
    "assignee",
    "powerOfAttorney",
    "alternateAddressee",
    "payee",
  ];
  const copyOfPeopleList = cloneDeep(peopleList);
  const sortedPeopleList = [];

  sortOrder.forEach((role) => {
    let i = 0;
    while (i < copyOfPeopleList.length) {
      const person = copyOfPeopleList[i];
      if (person[role]) {
        sortedPeopleList.push(person);
        copyOfPeopleList.splice(i, 1);
      } else {
        i += 1;
      }
    }
  });

  return sortedPeopleList;
};

const makeColumn = (accessor, label) => ({
  accessor,
  label,
  alignment: "center",
  CustomCell: Icon,
  iconName: "check",
  iconColor: "green",
});
const makeBeneficiaryColumn = (accessor, label) => ({
  accessor,
  label,
  alignment: "left",
});

export const getBeneficiaryColumnByPolicyType = (agreementData) => {
  const isRetireEaseContractResult = isRetireEaseContract(agreementData);
  const isFoundationPremiumContract =
    isFoundationSinglePremium(agreementData) ||
    isFoundationFlexiblePremium(agreementData);
  const beneficiaryColumns = [...columnsBeneficiary];
  if (!isRetireEaseContractResult && !isFoundationPremiumContract) {
    beneficiaryColumns.splice(
      1,
      0,
      makeBeneficiaryColumn("beneficiaryType", "Type")
    );
  }
  return beneficiaryColumns;
};

export const getDefaultPeopleOnContractColumns = (agreementData) => {
  const isRetireEaseContractResult = isRetireEaseContract(agreementData);
  const peopleOnContractDefaultColumns = [...defaultPeopleOnContractColumns];
  let filteredPeopleOnContractDefaultColumns = peopleOnContractDefaultColumns;
  if (isRetireEaseContractResult) {
    filteredPeopleOnContractDefaultColumns =
      peopleOnContractDefaultColumns.filter(
        (person) => person.accessor !== "premiumPayor"
      );
  }
  return filteredPeopleOnContractDefaultColumns;
};

export const addOptionalPeopleOnPolicyColumns = (
  defaultColumns,
  peopleList,
  agreementData
) => {
  const isRetireEaseContractResult = isRetireEaseContract(agreementData);
  const isEnvisionPolicyResult = isEnvisionPolicy(agreementData);
  const peopleOnPolicyWithOptionalColumns = [...defaultColumns];

  const columnTracker = {
    isAssignee: false,
    isPowerOfAttorney: false,
    isAlternateAddressee: false,
    isJointAnnuitant: false,
    isJointOwner: false,
    isPayee: false,
  };

  peopleList.forEach((person) => {
    if (person.assignee) {
      columnTracker.isAssignee = true;
    }
    if (person.powerOfAttorney) {
      columnTracker.isPowerOfAttorney = true;
    }
    if (person.alternateAddressee) {
      columnTracker.isAlternateAddressee = true;
    }
    if (person.jointOwner) {
      columnTracker.isJointOwner = true;
    }
    if (person.jointAnnuitant) {
      columnTracker.isJointAnnuitant = true;
    }
    if (person.payee) {
      columnTracker.isPayee = true;
    }
  });
  if (columnTracker.isAssignee) {
    peopleOnPolicyWithOptionalColumns.push(makeColumn("assignee", "Assignee"));
  }
  if (columnTracker.isPowerOfAttorney) {
    peopleOnPolicyWithOptionalColumns.push(
      makeColumn("powerOfAttorney", "Power of Attorney")
    );
  }
  if (columnTracker.isAlternateAddressee) {
    peopleOnPolicyWithOptionalColumns.push(
      makeColumn("alternateAddressee", "Alternate Addressee")
    );
  }
  if (columnTracker.isJointOwner) {
    peopleOnPolicyWithOptionalColumns.push(
      makeColumn("jointOwner", "Joint Owner")
    );
  }
  if (columnTracker.isJointAnnuitant) {
    peopleOnPolicyWithOptionalColumns.push(
      makeColumn("jointAnnuitant", "Joint Annuitant")
    );
  }
  if (columnTracker.isPayee) {
    if (isRetireEaseContractResult || isEnvisionPolicyResult) {
      peopleOnPolicyWithOptionalColumns.push(makeColumn("payee", "Payee"));
    }
  }
  return peopleOnPolicyWithOptionalColumns;
};

export const formatAndSortComplexBenes = (complexBeneficiaries) => {
  const primaryBenes = [];
  const contingentBenes = [];
  const benesWithoutClass = [];
  complexBeneficiaries.forEach((bene) => {
    if (bene?.beneficiaryClass?.toLowerCase() === "primary") {
      primaryBenes.push({
        ...bene,
        beneficiaryDesignationDescription: `PRIMARY: ${bene.beneficiaryDesignationDescription}`,
      });
    } else if (bene?.beneficiaryClass?.toLowerCase() === "secondary") {
      contingentBenes.push({
        ...bene,
        beneficiaryDesignationDescription: `CONTINGENT: ${bene.beneficiaryDesignationDescription}`,
      });
    } else {
      benesWithoutClass.push(bene);
    }
  });

  return primaryBenes.length > 0
    ? [...primaryBenes, ...contingentBenes, ...benesWithoutClass]
    : [...benesWithoutClass, ...contingentBenes];
};
