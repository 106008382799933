import { isNaN } from "./evaluate";

export const filterRows = (props) => {
  const { rows, filterOn } = props;

  if (!filterOn) {
    return rows;
  }
  const statuses = ["Not Reported", "Terminated", "Unknown"];
  return rows.filter((row) => !statuses.includes(row.status));
};

export const calculateRows = (props) => {
  const {
    rows,
    pagination: { currentPage, rowSize },
    showPagination,
  } = props;
  let selectedRows = [];
  // pagination
  const SHOULD_FILTER_ROWS = rows.length > 0 && showPagination;
  if (SHOULD_FILTER_ROWS) {
    const startingPoint = (currentPage - 1) * rowSize;
    const endingPoint = startingPoint + rowSize;
    selectedRows = rows.slice(startingPoint, endingPoint);
    return selectedRows;
  }

  return rows;
};

export const moveToNextPage = (props) => {
  const {
    pagination,
    pagination: { currentPage, totalPages },
    setPagination,
  } = props;
  const validatedCurrentPage = checkPageState({
    newPage: parseInt(currentPage, 10) + 1,
    totalPages,
    currentPage,
  });

  setPagination({
    ...pagination,
    inputtedPage: validatedCurrentPage,
    currentPage: validatedCurrentPage,
  });
};

export const moveToPreviousPage = (props) => {
  const {
    pagination,
    pagination: { currentPage, totalPages },
    setPagination,
  } = props;
  const validatedCurrentPage = checkPageState({
    newPage: currentPage - 1,
    totalPages,
    currentPage,
  });

  setPagination({
    ...pagination,
    inputtedPage: validatedCurrentPage,
    currentPage: validatedCurrentPage,
  });
};

export const moveToPage = (props) =>
  props.shouldChangePage ? changePage(props) : userEnteredNewPageNumber(props);

export const changePage = (props) => {
  const {
    pagination: { totalPages, currentPage },
    pagination,
    newPage,
    setPagination,
    shouldChangePage,
  } = props;
  const validatedCurrentPage = checkPageState({
    newPage,
    totalPages,
    currentPage,
    shouldChangePage,
  });

  setPagination({
    ...pagination,
    currentPage: validatedCurrentPage,
    inputtedPage: validatedCurrentPage,
  });
};

export const userEnteredNewPageNumber = (props) => {
  const {
    pagination: { totalPages, currentPage },
    pagination,
    newPage,
    setPagination,
    shouldChangePage,
  } = props;
  const validatedCurrentPage = checkPageState({
    newPage,
    totalPages,
    currentPage,
    shouldChangePage,
  });

  setPagination({
    ...pagination,
    inputtedPage: validatedCurrentPage,
  });
};

export const checkPageState = (props) => {
  const { newPage, currentPage, totalPages, shouldChangePage } = props;
  const isBelowZero = newPage < 0;
  const isZero = newPage === "0";
  const isAboveTotalPages = newPage > totalPages;
  const isNotANumber = isNaN(newPage);
  const isEmpty = newPage.length === 0;

  if (isNotANumber) {
    return currentPage;
  }
  if (isZero && shouldChangePage) {
    return currentPage;
  }
  if (isBelowZero) {
    return 1;
  }
  if (isAboveTotalPages) {
    return totalPages;
  }
  if (isEmpty && shouldChangePage) {
    return currentPage;
  }
  return newPage;
};

export const positionCell = (props) => {
  const { alignment } = props;
  switch (alignment) {
    case "center":
      return "col--center";
    case "right":
      return "col--right";
    default:
      return "";
  }
};
