import React from "react";
import { isDefined, isEmptyArray, isNaN } from "../../../../utils/evaluate";
import {
  formatPercentage,
  formatMoney,
  formatContactUsDateTime,
  formatContactUsNumber,
} from "../../../../utils/format";
import { formatDate } from "../../../../utils/dates";
import {
  isArtistryAnnuityProduct,
  isNonEnvisionAnnuity,
  isFlexIVFund1FPOrFund4FP,
  checkIfTransactionIsAllowed,
} from "../../../../utils/policies";
import { contactUSDetails } from "../../../../data/contactUsDetails";
import { supportedTransactions } from "../../../../utils/translate";
import { useLDValue } from "../../../common/featureFlags/hooks";
import { useUserState } from "../../../../contexts/user";
import { getTrimmedPolicyIdFromAgreement } from "../../../../utils/findData";

export const useInvestmentsProps = (props) => {
  const {
    agreementRequest,
    annuityRequest,
    recalculatedValues,
    showRecalcValues,
    cdaValuesRequest,
  } = props;
  const transactionSupportedPolicies = useLDValue({
    flagName: ["transactionSupportedPolicies"],
  });
  const isNonEnvisionAnnuityContract = isNonEnvisionAnnuity(agreementRequest);
  const isArtistryContract = isArtistryAnnuityProduct(
    agreementRequest.data.agreementKeyPrefix
  );
  const isFlexIVFund1FPOrFund4FPContract = isFlexIVFund1FPOrFund4FP(
    agreementRequest?.data?.kindCode
  );

  const {
    user: { email },
  } = useUserState();

  const agreementData = agreementRequest.data;

  const { agreementKey, agreementFunds } = agreementRequest.data;
  let annuityFunds = annuityRequest?.data?.funds?.fund || [];
  if (showRecalcValues)
    annuityFunds =
      recalculatedValues?.recalculation?.contractValue?.funds?.fund || [];
  if (isFlexIVFund1FPOrFund4FPContract)
    annuityFunds = cdaValuesRequest?.data?.funds?.fund || [];

  const { trimmedPolicyId } = getTrimmedPolicyIdFromAgreement(agreementKey);

  const { isTransactionAllowed: showFundTransferButton } =
    checkIfTransactionIsAllowed({
      transactionSupportedPolicies,
      transactionName: supportedTransactions.FundTransfer,
      agreementData,
    });

  const fundRows = isNonEnvisionAnnuityContract
    ? formatInvestmentDataArray(mapAnnuityInvestmentData(annuityFunds))
    : formatInvestmentDataArray(agreementFunds);

  const employeeFundRows = formatArtistryEmployeeTableRows(annuityFunds);
  const employerFundRows = formatArtistryEmployerTableRows(annuityFunds);

  const fundColumns = isNonEnvisionAnnuityContract
    ? columns.filter((item) => item.label !== "Asset Class")
    : columns;

  const formattedContactUsNumber = formatContactUsNumber(
    props?.agreementRequest?.data?.contactUsNumber,
    contactUSDetails.DEFAULT.number
  );
  const formattedContactUsTime = formatContactUsDateTime(
    props?.agreementRequest?.data?.contactUsTime,
    contactUSDetails.DEFAULT.time
  );

  const contactUsInfo = {
    formattedContactUsNumber,
    formattedContactUsTime,
  };

  return {
    requests: { agreementRequest },
    table: { columns: fundColumns, rows: fundRows },
    contactUsInfo,
    isNonEnvisionAnnuityContract,
    isArtistryContract,
    showFundTransferButton,
    agreementKey,
    trimmedPolicyId,
    email,
    employeeFundRows: !isEmptyArray(employeeFundRows) ? employeeFundRows : "",
    employerFundRows: !isEmptyArray(employerFundRows) ? employerFundRows : "",
    recalcAsOfDate: formatDate(
      recalculatedValues?.recalculation?.asOfDate,
      "monthCommaDayYear",
      "Unavailable"
    ),
  };
};

const columns = [
  {
    accessor: "fundNumber",
    label: ["Investment", <br />, "Number"],
    alignment: "left",
    sortable: "Number",
  },
  {
    accessor: "fundName",
    label: "Investments",
    alignment: "left",
    sortable: "String",
  },
  {
    accessor: "marketingCategory",
    label: "Asset Class",
    alignment: "left",
    sortable: "String",
  },
  {
    accessor: "accumUnitAmount",
    label: "Unit Value",
    alignment: "left",
    sortable: "Number",
  },
  {
    accessor: "fundTotalNumberUnits",
    label: ["Accumulation", <br />, "Units"],
    alignment: "left",
    sortable: "Number",
  },
  {
    accessor: "fundPercentAmount",
    label: ["% of", <br />, "Contract", <br />, "Value"],
    alignment: "left",
    sortable: "Number",
  },
  {
    accessor: "fundFuturePaymentAllocationPercent",
    label: ["Future", <br />, "Payment", <br />, "Allocation"],
    alignment: "left",
    sortable: "Number",
  },
  {
    accessor: "fundValueAmount",
    label: ["Dollar", <br />, "Amount"],
    alignment: "left",
    sortable: "Number",
  },
];

const formatInvestmentDataArray = (agreementFundsData = []) => {
  const remappedAgreementFunds = agreementFundsData.map((investmentData) => {
    const investmentNo = !isDefined(investmentData.fundNumber)
      ? "-"
      : investmentData.fundNumber;
    const investFundName = !isDefined(investmentData.fundName)
      ? "-"
      : investmentData.fundName;
    const assetClass = !isDefined(investmentData.marketingCategory)
      ? "-"
      : investmentData.marketingCategory;
    const unitValue =
      !isDefined(investmentData.accumUnitAmount) ||
      isNaN(investmentData.accumUnitAmount)
        ? "-"
        : investmentData.accumUnitAmount?.toFixed(6);
    const totalUnits =
      (!isDefined(investmentData.fundTotalNumberUnits) &&
        investmentData.fundTotalNumberUnits !== 0) ||
      isNaN(investmentData.fundTotalNumberUnits)
        ? "-"
        : Number(investmentData.fundTotalNumberUnits)?.toFixed(4);

    const futurePaymentAllocation = !isDefined(
      investmentData.fundFuturePaymentAllocationPercent
    )
      ? "0%"
      : formatPercentage({
          rate: investmentData.fundFuturePaymentAllocationPercent,
          maxDecimalPlaces: 2,
        });
    const isDollarValueAvailable = !isDefined(investmentData.fundValueAmount);

    const dollarAmount = isDollarValueAvailable
      ? "-"
      : formatMoney(investmentData?.fundValueAmount);

    const percentageContractValue =
      !isDefined(investmentData.fundPercentAmount) || isDollarValueAvailable
        ? "-"
        : formatPercentage({
            rate: investmentData?.fundPercentAmount,
            decimalPlaces: 2,
            maxDecimalPlaces: 2,
          });
    return {
      fundNumber: investmentNo,
      fundName: investFundName,
      accumUnitAmount: unitValue,
      marketingCategory: assetClass,
      fundTotalNumberUnits: totalUnits,
      fundPercentAmount: percentageContractValue,
      fundFuturePaymentAllocationPercent: futurePaymentAllocation,
      fundValueAmount: dollarAmount,
    };
  });
  return remappedAgreementFunds;
};

const mapAnnuityInvestmentData = (annuityFunds) =>
  annuityFunds.map((fund) => ({
    ...fund,
    fundPercentAmount: fund.accountPct,
    fundFuturePaymentAllocationPercent: fund.allocPct,
    fundValueAmount: fund.balance,
    fundName: fund.displayName,
    fundNumber: fund.number,
    accumUnitAmount: Number(fund.unitValue),
    fundTotalNumberUnits: fund.units,
    marketingCategory: "",
  }));

const formatArtistryEmployeeTableRows = (annuityFunds) => {
  const employeeFunds = annuityFunds.filter((fund) => {
    if (fund.segment === "EE") return true;
    return false;
  });
  return employeeFunds.map((fund) => {
    const fundNumber = isDefined(fund.number) ? fund.number : "-";
    const fundName = isDefined(fund.displayName) ? fund.displayName : "-";
    const fundTotalNumberUnits =
      (!isDefined(fund.units) && fund.units !== 0) || isNaN(fund.units)
        ? "-"
        : Number(fund.units)?.toFixed(4);
    const accumUnitAmount = isDefined(fund.unitValue)
      ? Number(fund.unitValue).toFixed(4)
      : "-";
    const fundPercentAmount = isDefined(fund.accountPct)
      ? formatPercentage({
          rate: fund.accountPct,
          decimalPlaces: 2,
          maxDecimalPlaces: 2,
        })
      : "-";
    const fundFuturePaymentAllocationPercent = isDefined(fund.allocPct)
      ? formatPercentage({
          rate: fund.allocPct,
          decimalPlaces: 2,
          maxDecimalPlaces: 2,
        })
      : "-";
    const fundValueAmount = isDefined(fund.balance)
      ? formatMoney(fund.balance)
      : "-";

    return {
      fundNumber,
      fundName,
      fundTotalNumberUnits,
      accumUnitAmount,
      fundPercentAmount,
      fundFuturePaymentAllocationPercent,
      fundValueAmount,
    };
  });
};

const formatArtistryEmployerTableRows = (annuityFunds) => {
  const employerFunds = annuityFunds.filter((fund) => {
    if (fund.segment === "ER") return true;
    return false;
  });
  return employerFunds.map((fund) => {
    const fundNumber = isDefined(fund.number) ? fund.number : "-";
    const fundName = isDefined(fund.displayName) ? fund.displayName : "-";
    const fundTotalNumberUnits =
      (!isDefined(fund.units) && fund.units !== 0) || isNaN(fund.units)
        ? "-"
        : Number(fund.units)?.toFixed(4);
    const accumUnitAmount = isDefined(fund.unitValue)
      ? Number(fund.unitValue).toFixed(4)
      : "-";
    const fundPercentAmount = isDefined(fund.accountPct)
      ? formatPercentage({
          rate: fund.accountPct,
          decimalPlaces: 2,
          maxDecimalPlaces: 2,
        })
      : "-";
    const fundFuturePaymentAllocationPercent = isDefined(fund.allocPct)
      ? formatPercentage({
          rate: fund.allocPct,
          decimalPlaces: 2,
          maxDecimalPlaces: 2,
        })
      : "-";
    const fundValueAmount = isDefined(fund.balance)
      ? formatMoney(fund.balance)
      : "-";

    return {
      fundNumber,
      fundName,
      fundTotalNumberUnits,
      accumUnitAmount,
      fundPercentAmount,
      fundFuturePaymentAllocationPercent,
      fundValueAmount,
    };
  });
};
