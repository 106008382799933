import React from "react";
import { useConfirmationProps } from "./hooks";
import { SelectedDirectLinksList } from "./common/selectedDirectLinksList";
import { setTitle } from "../../utils/dom";

const Confirmation = (props) => {
  setTitle("ServiceNet - Direct Links Confirmation");
  const {
    selectedOwnerFields,
    selectedOwnerEmailFields,
    selectedDirectLinks,
    redirectBacktoPolicyWithTealiumEvent,
    backButtonText,
  } = useConfirmationProps(props);

  return (
    <>
      <div className="row">
        <div className="col-9">
          <h1 className="h2">
            <picture>
              <span
                id="directlink_confirmation_submitted_header_image"
                role="img"
                className="illustration-confetti hide-print"
                title="Success Confetti"
              />
            </picture>
            <span id="directlink_confirmation_submitted_header_message">
              Thank you! The Direct Link email is on the way.
            </span>
          </h1>
        </div>
        <div className="col-3">
          <div id="printButton" className="justify-content-end">
            <button
              data-bp-link="default"
              className="btn btn-inline hide-print"
              aria-label="Print"
              type="button"
              id="directlink_confirmation_submitted_print_button"
              onClick={() => {
                window.print();
              }}
            >
              <span
                id="directlink_confirmation_submitted_print_icon"
                className="icon-print"
              />
              <span>Print</span>
            </button>
          </div>
        </div>
        <div className="col-12">
          <p id="directlink_confirmation_message" className="mm-spacing--minor">
            The Direct Links you requested to MassMutual.com have been
            successfully sent to your client. Please review Service History for
            processing time and updates. If you have any questions regarding
            this request, please call us at 1-800-272-2216 and reference the
            policy number.
          </p>
        </div>
      </div>
      <hr className="mm-spacing" />
      <div className="row mm-spacing--minor">
        <div className="col-md-7">
          <dl className="mm-key-value-pair">
            <dt id="direct_link_confirmation_link_sent_lbl">
              Direct Links Sent
            </dt>
            <dd id="direct_link_confirmation_link_sent_wrapper">
              <SelectedDirectLinksList
                selectedDirectLinks={selectedDirectLinks}
                isCalledFrom="confirmation"
              />
            </dd>
            <dt id="direct_link_confirmation_policy_holders_lbl">
              Policy Holder(s) Information
            </dt>
            <dd id="direct_link_confirmation_policy_holders_details_wrapper">
              {selectedOwnerFields.map((owner, index) => {
                const ownerName = owner?.label;
                const ownerEmail = selectedOwnerEmailFields[index].value;
                return (
                  <>
                    {" "}
                    <div
                      className="mm-spacing--minor"
                      id={`directlink_confirmation_owner${index}_name_value}`}
                    >
                      <strong>{ownerName}</strong>
                    </div>
                    <div
                      id={`directlink_confirmation_owner${index}_email_value}`}
                    >
                      {ownerEmail}
                    </div>
                    <div className="mm-spacing--minor" />
                  </>
                );
              })}
            </dd>
          </dl>
        </div>
      </div>{" "}
      <div className="row mm-spacing" />
      <div className="row">
        <div className="col-12">
          <div className="container">
            <button
              id="direct_link_confirmation_go_back_btn"
              type="button"
              className="btn btn-primary hide-print"
              onClick={redirectBacktoPolicyWithTealiumEvent}
            >
              <span className="icon-arrow-circle-spin-left" />
              {"  "} {backButtonText}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default Confirmation;
