/* eslint-disable no-underscore-dangle */

const getConfig = (key, attempts = 0) => {
  const HAS_ENV = !!window && !!window._env;
  const MAX_ATTEMPTS = 20; // 5 seconds of checking given that the timeout ms is 250
  const NOT_MAXED_OUT_ATTEMPTS = attempts < MAX_ATTEMPTS;
  const TIME_OUT_MS = 50000;

  if (HAS_ENV) {
    return window._env[key] || "Item not found";
  }

  if (NOT_MAXED_OUT_ATTEMPTS) {
    window.setTimeout(() => getConfig(key, attempts + 1), TIME_OUT_MS);
  }

  return "Window or Window Env not found";
};

const types = {
  apiUrl: "REACT_APP_API_URL",
  domain: "REACT_APP_DOMAIN",
  clientId: "REACT_APP_CLIENT_ID",
  env: "REACT_APP_ENV",
  fieldNetUrl: "REACT_APP_FIELD_NET_URL",
  launchDarkly: "REACT_APP_LAUNCH_DARKLY_ID",
  tealiumEnviroment: "REACT_APP_TEALIUM_ENVIROMENT",
  coverpathUrl: "REACT_APP_COVERPATH_URL",
  baltoAppUrl: "REACT_APP_BALTO_APP_URI",
  se2Url: "REACT_APP_SE2_URL",
  sigCareLtcUrl: "REACT_APP_SIG_CARE_LTC_URL",
  advisor360: "REACT_APP_ADVISOR360_URL",
  googleApiKey: "REACT_APP_GOOGLE_API_KEY",
  genesysEnv: "REACT_APP_GENESYS_ENV",
};

export { getConfig, types };
