import React from "react";
import IsTruthy from "../common/logic/isTruthy";
import Stepper from "../common/forms/stepper";
import { TRANSACTIONS_DISABLED_MESSAGE } from "../../constants/ui";
import LdBannerContent from "../common/molecules/ldBannerContent";

const AddressFlowHeader = (props) => {
  const { pageNumber, previousPage, shouldDisableSubmit } = props;
  return (
    <>
      <nav role="navigation" className="mm-navbar--transaction">
        <div className="mm-logo" id="servicenet_logo_img" />
        <IsTruthy value={pageNumber < 5}>
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <Stepper totalSteps={4} currentStep={pageNumber} />
            </div>
          </div>
        </IsTruthy>
      </nav>
      <IsTruthy value={pageNumber > 1 && pageNumber < 5}>
        <button
          id="go_back_btn"
          type="button"
          className="btn btn-link mm-spacing--minor"
          onClick={previousPage}
        >
          <span className="icon-arrow-circle-spin-left" />
          <span>Previous Step</span>
        </button>
      </IsTruthy>
      <IsTruthy value={pageNumber < 5}>
        <p className="eyebrow" id="change_address_step_lbl">
          CHANGE ADDRESS: STEP {pageNumber} of 4
        </p>
        <LdBannerContent />
        <IsTruthy value={shouldDisableSubmit}>
          <div className="alert alert-danger" role="alert">
            {TRANSACTIONS_DISABLED_MESSAGE}
          </div>
        </IsTruthy>
      </IsTruthy>
    </>
  );
};

export default AddressFlowHeader;
