import React from "react";

const FourHundredThreeError = () => (
  <picture>
    <span
      role="img"
      className="illustration-error_403"
      title="Error Site Down"
    />
  </picture>
);

export default FourHundredThreeError;
