import React from "react";
import { InitiateDeathClaimDetails } from "./intiateDeathClaimDetails";
import { useReviewProps } from "../hooks";
import IsTruthy from "../../common/logic/isTruthy";
import { isArray } from "../../../utils/evaluate";
import { stringIsEmptyOrNullish } from "../../../utils/string";
import TextAreaForm from "../../common/forms/textareaForm";
import IsFalsy from "../../common/logic/isFalsy";

const Review = (props) => {
  const { setPageNumber, isDiOnlyClaim } = props;

  const {
    decedentName,
    didDeathOccurInUS,
    dateOfDeath,
    displayCauseOfDeathOnReview,
    causeOfDeath,
    maritalStatus,
    previousDivorceStatus,
    displayPreviousDivorceQuestionOnReview,
    displayDisabilityQuestionOnReview,
    activeDisabilityClaim,
    disabilityClaimNumber,
    financialProfessionalName,
    financialProfessionalEmail,
    additionalInfo,
    beneTypeSelectedAnswer,
    beneContactSelectedAnswer,
    claimPacketQuestion1SelectedAnswer,
    claimPacketQuestion3SelectedAnswer,
    displayClaimPacketQuestion4,
    claimPacketQuestion4SelectedAnswer,
    displayClaimPacketQuestion5,
    claimPacketQuestion5SelectedAnswer,
    claimPacketQuestion6SelectedAnswer,
    receivingFormsNamesList,
  } = useReviewProps(props);

  const pageNumber = isDiOnlyClaim ? 3 : 4;

  const beneContactList = isArray(beneContactSelectedAnswer)
    ? beneContactSelectedAnswer.map((contactInfo, i) => {
        if (
          stringIsEmptyOrNullish(contactInfo[1]) &&
          stringIsEmptyOrNullish(contactInfo[2])
        ) {
          return (
            <p
              id={`review_beneficiary_contact_info_value_beneContact${i + 1}`}
              key={`beneContact${i + 1}`}
            >
              {contactInfo[0]}
            </p>
          );
        }
        if (stringIsEmptyOrNullish(contactInfo[1])) {
          return (
            <p
              id={`review_beneficiary_contact_info_value_beneContact${i + 1}`}
              key={`beneContact${i + 1}`}
            >
              {contactInfo[0]}, {contactInfo[2]}
            </p>
          );
        }
        if (stringIsEmptyOrNullish(contactInfo[2])) {
          return (
            <p
              id={`review_beneficiary_contact_info_value_beneContact${i + 1}`}
              key={`beneContact${i + 1}`}
            >
              {contactInfo[0]}, {contactInfo[1]}
            </p>
          );
        }
        return (
          <p
            id={`review_beneficiary_contact_info_value_beneContact${i + 1}`}
            key={`beneContact${i + 1}`}
          >
            {contactInfo[0]}, {contactInfo[1]}, {contactInfo[2]}
          </p>
        );
      })
    : beneContactSelectedAnswer;
  const receivingFormsNames =
    isArray(receivingFormsNamesList) && receivingFormsNamesList.length > 0 ? (
      receivingFormsNamesList.map((name, i) => (
        <p
          id={`review_receiving_forms_contact_info_value_receivingForms${
            i + 1
          }`}
          key={`receivingForms${i + 1}`}
        >
          {name}
        </p>
      ))
    ) : (
      <dd id="review_receiving_forms_contact_info_value">None Selected </dd>
    );
  return (
    <div>
      <div className="row">
        <div className="col-md-7">
          <h1 id="review_header_lbl">Review</h1>
          <br />
          <p className="h3" id="review_decedent_information_lbl">
            Decedent Information
            <button
              type="button"
              className="btn btn-inline"
              onClick={() => setPageNumber(2)}
              aria-label="Edit"
              id="edit_selected_decedent_btn"
            >
              Edit
            </button>
          </p>
          <div className="mm-spacing">
            <dl className="mm-key-value-pair">
              <dt id="review_decedent_name_lbl">Decedent&apos;s Name</dt>
              <dd id="review_decedent_name_value">{decedentName}</dd>
              <dt id="review_death_in_us_lbl">
                Did their death occur in the U.S.?
              </dt>
              <dd id="review_death_in_us_value">{didDeathOccurInUS}</dd>
              <dt id="review_date_of_death_lbl">
                What was their exact date of death?
              </dt>
              <dd id="review_date_of_death_value">{dateOfDeath}</dd>
              <IsTruthy value={displayCauseOfDeathOnReview}>
                <dt id="review_cause_of_death_lbl">Cause of Death</dt>
                <dd id="review_cause_of_death_value">{causeOfDeath}</dd>
              </IsTruthy>
              <dt id="review_decedent_marital_status_lbl">
                What was the decedent’s marital status at the time of passing?
              </dt>
              <dd id="review_decedent_marital_status_value">{maritalStatus}</dd>
              <IsTruthy value={displayPreviousDivorceQuestionOnReview}>
                <dt id="review_decedent_divorced_status_lbl">
                  To your knowledge, was the decedent ever divorced?
                </dt>
                <dd id="review_decedent_divorced_status_value">
                  {previousDivorceStatus}
                </dd>
              </IsTruthy>
              <IsTruthy value={displayDisabilityQuestionOnReview}>
                <dt id="review_disability_active_claim_lbl">
                  Is the Disability policy on an active claim?
                </dt>
                <dd id="review_disability_active_claim_value">
                  {activeDisabilityClaim}
                </dd>
                <IsTruthy value={activeDisabilityClaim === "Yes"}>
                  <IsTruthy value={disabilityClaimNumber !== ""}>
                    <dt id="review_disability_claim_number_lbl">
                      Claim number
                    </dt>
                    <dd id="review_disability_claim_number_value">
                      {disabilityClaimNumber}
                    </dd>
                  </IsTruthy>
                </IsTruthy>
              </IsTruthy>
            </dl>
          </div>
          <div className="mm-spacing">
            <hr />
          </div>
          <IsFalsy value={isDiOnlyClaim}>
            <div className="mm-spacing">
              <p className="h3" id="review_beneficiary_information_header_lbl">
                Beneficiary Information
                <button
                  type="button"
                  className="btn btn-inline"
                  onClick={() => setPageNumber(3)}
                  aria-label="Edit"
                  id="edit_beneficiary_btn"
                >
                  Edit
                </button>
              </p>
              <dl className="mm-key-value-pair">
                <dt id="review_beneficiary_type_lbl">Beneficiary Type</dt>
                <dd id="review_beneficiary_type_value">
                  {beneTypeSelectedAnswer}
                </dd>
                <dt id="review_beneficiary_contact_info_lbl">
                  Beneficiary Contact Information
                </dt>
                <dd>{beneContactList}</dd>
              </dl>
            </div>
            <div className="mm-spacing">
              <hr />
            </div>
          </IsFalsy>
          <div className="mm-spacing">
            <p className="h3" id="review_forms_recipient_header_lbl">
              Recipient of Claims Packet
              <button
                type="button"
                className="btn btn-inline"
                onClick={() => setPageNumber(pageNumber)}
                aria-label="Edit"
                id="edit_forms_recipient_btn"
              >
                Edit
              </button>
            </p>
            <IsTruthy value={isDiOnlyClaim}>
              <div>
                <p id="review_forms_not_applicable_for_di_lbl">
                  <strong>
                    <em>Forms not applicable for DI only Death Claim</em>
                  </strong>
                </p>
              </div>
            </IsTruthy>
            <dl className="mm-key-value-pair">
              <IsFalsy value={isDiOnlyClaim}>
                <dt id="review_assist_with_process_lbl">
                  Would you like to assist with the claim process?
                </dt>
                <dd id="review_assist_with_process_value">
                  {claimPacketQuestion1SelectedAnswer}
                </dd>
                <dt id="review_forms_recipient_lbl">
                  Who will be receiving the forms?
                </dt>
                <dd>{receivingFormsNames}</dd>
                <IsTruthy value={claimPacketQuestion1SelectedAnswer !== "No"}>
                  <dt id="review_forms_download_prefilled_lbl">
                    Would you like to download a pre-filled form and send it to
                    the beneficiary?
                  </dt>
                  <dd id="review_forms_download_prefilled_value">
                    {claimPacketQuestion3SelectedAnswer}
                  </dd>
                </IsTruthy>
                <IsTruthy value={displayClaimPacketQuestion4}>
                  <dt id="review_how_forms_sent_lbl">
                    A Claim Examiner will send the forms. How would you like the
                    forms to be sent?
                  </dt>
                  <dd id="review_how_forms_sent_value">
                    {claimPacketQuestion4SelectedAnswer}
                  </dd>
                </IsTruthy>
                <IsTruthy value={displayClaimPacketQuestion5}>
                  <dt id="review_form_type_lbl">
                    What type of form would you like to be sent?
                  </dt>
                  <dd id="review_form_type_value">
                    {claimPacketQuestion5SelectedAnswer}
                  </dd>
                </IsTruthy>
              </IsFalsy>
              <dt id="review_method_of_contact_lbl">
                As the Financial Professional, what is your preferred method of
                contact in case we need to reach you?
              </dt>
              <dd id="review_method_of_contact_value">
                {claimPacketQuestion6SelectedAnswer}
              </dd>
            </dl>
          </div>
          <div className="mm-spacing">
            <hr />
          </div>
          <div className="mm-spacing">
            <p className="h3" id="review_financial_professional_header_lbl">
              Financial Professional Information
              <IsFalsy value={isDiOnlyClaim}>
                <button
                  type="button"
                  className="btn btn-inline"
                  onClick={() => setPageNumber(4)}
                  aria-label="Edit"
                  id="edit_financial_professional_btn"
                >
                  Edit
                </button>
              </IsFalsy>
            </p>
            <dl className="mm-key-value-pair">
              <dt id="review_financial_professional_lbl">Name</dt>
              <dd id="review_financial_professional_value">
                {financialProfessionalName}
              </dd>
              <dt id="review_financial_professional_email_lbl">Email</dt>
              <dd id="review_financial_professional_email_value">
                {financialProfessionalEmail}
              </dd>
            </dl>
            <hr />
            <p className="h5" id="review_additional_comments_lbl">
              Do you have any additional information you would like to provide
              the Claim Examiner? Please be advised that our Claims team does a
              thorough review to capture all accounts associated with the
              decedent. Enter additional comments below (up to 1000 characters).
            </p>
            <div
              id="review_additional_comments_value"
              className="mm-spacing--minor form-group"
            >
              <TextAreaForm formField={additionalInfo} />
            </div>
          </div>
        </div>
        {/* floating gray box will stack at bottom for mobile phone */}
        <InitiateDeathClaimDetails />
      </div>
    </div>
  );
};
export default Review;
