import React from "react";
import { formatLisrHAPData } from "./dividendHelper";

const LisrHapSection = (props) => {
  const { coverages } = props;
  const {
    currentAmountOYT,
    currentCoverage,
    paidUpInsurancePortionOfSIA,
    totalAnnualPremium,
    riderSumInsured,
    amountOfTermPurchased,
    lisrDividendCashValueAmount,
    lisrDivOptPaidUpAddsAmount,
  } = formatLisrHAPData(coverages);
  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <dl className="mm-key-value-pair">
          <dt id="lisr_rider_name_lbl">Rider Name</dt>
          <dd id="lisr_rider_name_value">LISR</dd>

          <dt id="lisr_div_cash_value_amount_lbl">
            Total LISR Dividend Cash Values
          </dt>
          <dd id="lisr_div_cash_value_amount_value">
            {lisrDividendCashValueAmount}
          </dd>
          <dt id="lisr_amount_term_purchased_lbl">Amount of Term Purchased</dt>
          <dd id="lisr_amount_term_purchased_value">{amountOfTermPurchased}</dd>
          <dt id="lisr_term_portion_lbl">Term Portion of SIA</dt>
          <dd id="lisr_term_portion_value"> {currentAmountOYT}</dd>
          <dt id="lisr_sup_insurance_lbl">
            Supplemental Insurance Amount - Total
          </dt>
          <dd id="lisr_sup_insurance_value">{currentCoverage}</dd>
        </dl>
      </div>
      <div className="col-12 col-md-6">
        <dl className="mm-key-value-pair">
          <dt id="lisr_rider_sum_insured_lbl">Rider Sum Insured</dt>
          <dd id="lisr_rider_sum_insured_value">{riderSumInsured}</dd>
          <dt id="lisr_total_curr_div_earned_lbl">
            Total LISR Current Dividend Earned
          </dt>
          <dd id="lisr_total_curr_div_earned_value">
            {lisrDivOptPaidUpAddsAmount}
          </dd>
          <dt id="lisr_term_insurance_cost_lbl">Cost of Term Insurance</dt>
          <dd id="lisr_term_insurance_cost_value">{totalAnnualPremium}</dd>
          <dt id="lisr_paid_up_insurance_portion_lbl">
            Paid Up Insurance Portion of SIA
          </dt>
          <dd id="lisr_paid_up_insurance_portion_value">
            {paidUpInsurancePortionOfSIA}
          </dd>
        </dl>
      </div>
    </div>
  );
};

export default React.memo(LisrHapSection);
