import React from "react";

import LoadingSection, {
  errorTypes,
  loadingTypes,
} from "../../../../common/loading";
import IsTruthy from "../../../../common/logic/isTruthy";

import { useAnnuityLoanProps } from "./hooks";

import FlexExtraLoanDisplay from "./flexExtraLoanDisplay";
import ArtistryLoanDisplay from "./artistryLoanDisplay";

const AnnuityLoan = (props) => {
  const { id, annuityRequest, agreementRequest } = props;
  const {
    isArtistryAnnuityContract,
    isFlexExtraContract,
    artistryLoanData,
    flexExtraData,
    setLoanPayOffAsOfDate,
    showLoanPayOffValues,
    showLoadingIcon,
    loanPayOffValues,
    selectedDate,
  } = useAnnuityLoanProps(props);
  return (
    <div className="mm-spacing">
      <h2 aria-label="Loans" id={id}>
        Loans
      </h2>
      <hr />
      <LoadingSection
        request={agreementRequest}
        loadingType={loadingTypes.PARAGRAPH}
        errorType={errorTypes.SECTION}
      >
        <LoadingSection
          request={annuityRequest}
          loadingType={loadingTypes.PARAGRAPH}
          errorType={errorTypes.SECTION}
        >
          <IsTruthy value={isFlexExtraContract}>
            <FlexExtraLoanDisplay flexExtraData={flexExtraData} />
          </IsTruthy>
          <IsTruthy value={isArtistryAnnuityContract}>
            <ArtistryLoanDisplay
              formattedMaxLoanAmount={artistryLoanData.formattedMaxLoanAmount}
              outstandingLoanCount={
                showLoanPayOffValues
                  ? loanPayOffValues.outstandingLoanCount
                  : artistryLoanData.outstandingLoanCount
              }
              formattedOutstandingLoanCount={
                artistryLoanData.formattedOutstandingLoanCount
              }
              maxLoanAsOfDate={artistryLoanData.maxLoanAsOfDate}
              maxLoanAmountPopover={artistryLoanData.maxLoanAmountPopover}
              loanSectionInfoMessages={artistryLoanData.loanSectionInfoMessages}
              formattedLoanPayoffAsOfDate={
                showLoanPayOffValues
                  ? selectedDate
                  : artistryLoanData.formattedLoanPayoffAsOfDate
              }
              formattedContactUsNumber={
                artistryLoanData.formattedContactUsNumber
              }
              formattedContactUsTime={artistryLoanData.formattedContactUsTime}
              groupedLoanList={
                showLoanPayOffValues
                  ? loanPayOffValues.groupedLoanList
                  : artistryLoanData.groupedLoanList
              }
              hasActiveLoan={artistryLoanData.hasActiveLoan}
              annuityRequest={annuityRequest}
              agreementRequest={agreementRequest}
              setAsOfDate={setLoanPayOffAsOfDate}
              showLoanPayOffValues={showLoanPayOffValues}
              showLoadingIcon={showLoadingIcon}
            />
          </IsTruthy>
        </LoadingSection>
      </LoadingSection>
    </div>
  );
};

export default React.memo(AnnuityLoan);
