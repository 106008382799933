import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { useAgentProfileState } from "../contexts/agentProfile";
import { useUserState } from "../contexts/user";
import {
  communicationTypes,
  getAgentCommunicationDetailsByType,
} from "../utils/findData";
import {
  useGetFlagsValues,
  useLDValue,
} from "../components/common/featureFlags/hooks";
import { hasKey } from "../utils/object";
import { isEmptyObject } from "../utils/evaluate";

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useBannerMessageProps = () => {
  const { flagValues, areAllFlagsOff } = useGetFlagsValues([
    "showBannerMessage",
  ]);
  const bannerMessage = flagValues?.showBannerMessage || "";
  return {
    showBannerMessage: !areAllFlagsOff,
    bannerMessageToDisplay: bannerMessage,
  };
};

export const useSiteDownProps = () => {
  const { flagValues } = useGetFlagsValues(["siteDownWithMessage"]);
  const siteDownWithMessage = flagValues?.siteDownWithMessage || "";
  return {
    siteDown: siteDownWithMessage.siteDown,
    messageToDisplay: siteDownWithMessage.message,
  };
};

export const useRequestorContactEmail = () => {
  const {
    user: { email, isAnAgent },
  } = useUserState();

  const {
    agentProfile: {
      agentProfileData: { communications = [] },
    },
  } = useAgentProfileState();

  let contactChoiceEmail;
  if (isAnAgent) {
    const agentEmail = getAgentCommunicationDetailsByType(
      communications,
      communicationTypes.EMAIL
    );
    contactChoiceEmail = agentEmail ? agentEmail.toString() : "";
  } else {
    contactChoiceEmail = email ? email.toString() : "";
  }
  return contactChoiceEmail;
};

export const useIsFixedAnnuityContract = (data) => {
  const supportedLegacyContracts = useLDValue({
    flagName: ["supportedContracts"],
  });

  if (!data) return false;
  const { agreementKeyAdmin, lineOfBusinessCode, kindCode } = data;

  const haveSupportedContractsLoaded = !isEmptyObject(supportedLegacyContracts);

  const SUPPORTED_LEGACY_CONTRACT =
    hasKey({ obj: supportedLegacyContracts, key: lineOfBusinessCode }) &&
    hasKey({
      obj: supportedLegacyContracts[lineOfBusinessCode],
      key: agreementKeyAdmin,
    }) &&
    supportedLegacyContracts[lineOfBusinessCode][agreementKeyAdmin].includes(
      kindCode
    );

  return haveSupportedContractsLoaded && SUPPORTED_LEGACY_CONTRACT;
};

export const useQuery = () => new URLSearchParams(useLocation().search);
