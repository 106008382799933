import React from "react";
import SwitchForm from "../../common/forms/switchForm";
import { useCommonFormProps } from "./hooks";

const CommonForm = (props) => {
  const { isOn, useCustomSwitch } = useCommonFormProps(props);
  return (
    <div className="row">
      <div className="col-12">
        <SwitchForm useHook={useCustomSwitch} formField={isOn} />
      </div>
    </div>
  );
};

export default CommonForm;
