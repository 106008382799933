import React from "react";
import { useModalState } from "../../../contexts/modal";
import CustomComponent from "../logic/customComponent";

const HelpModalBody = (props) => {
  const { modalBodyComponent } = props;
  return <CustomComponent component={modalBodyComponent} />;
};

const LabelWithHelpModal = (props) => {
  const { text, modalId, modalBodyComponent, modalTitle } = props;
  const { openModal } = useModalState();

  const helpModalProps = {
    title: modalTitle,
    Component: HelpModalBody,
    componentProps: { modalBodyComponent, ...props },
  };

  const showHelpModal = () => openModal(helpModalProps);

  return (
    <>
      {text}{" "}
      <button
        id={`btn-help-${modalId}`}
        data-testid={`btn-help-${modalId}`}
        type="button"
        className="btn btn-inline"
        onClick={showHelpModal}
      >
        <span
          className="icon-help icon-sm"
          id={`icon-help-${modalId}`}
          role="button"
        >
          <span className="sr-only">{`${modalTitle} modal`}</span>
        </span>
      </button>
    </>
  );
};

export default LabelWithHelpModal;
