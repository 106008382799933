import React from "react";
import RadioForm from "../../../common/forms/radioForm";
import {
  useBeneClaimRecipientContactModalProps,
  useClaimsPacketAndFormsAnswersProps,
  useClaimsPacketAndFormsRadioButtonProps,
  useFormRecipientsProps,
} from "../../hooks";
import ContactInformationCard from "../common/contactInformationCard";
import IsTruthy from "../../../common/logic/isTruthy";
import IsFalsy from "../../../common/logic/isFalsy";
import LoadingSection, {
  errorTypes,
  loadingTypes,
} from "../../../common/loading";
import { isDefined } from "../../../../utils/evaluate";

export const RecipientContactCards = (props) => {
  const { recipients } = props;

  return recipients.map((recipient, index) => {
    const keyName = `recipientInfo${index}`;
    const { beneFormField, recipientFormField, claimRecipientFormField } =
      recipient;
    return (
      <>
        <IsTruthy
          validationFunction={isDefined}
          value={claimRecipientFormField}
        >
          <ContactInformationCard
            key={keyName}
            formField={claimRecipientFormField}
            useEditHook={useBeneClaimRecipientContactModalProps}
            cardType="claimRecipientCard"
          />
        </IsTruthy>
        <IsTruthy validationFunction={isDefined} value={recipientFormField}>
          <ContactInformationCard
            key={keyName}
            formField={beneFormField}
            checkboxFormField={recipientFormField}
            useEditHook={useBeneClaimRecipientContactModalProps}
            cardType="beneCard"
          />
        </IsTruthy>
      </>
    );
  });
};
const ClaimsPacketAndFormsAnswers = (props) => {
  const { recipients } = useFormRecipientsProps(props);
  const {
    formData,
    questionNumber,
    isHorizontalAnswersDisplay,
    producerRequest,
    disableAddContactButton,
  } = props;

  const { addClaimsRecipientContact } =
    useClaimsPacketAndFormsAnswersProps(props);

  return (
    <>
      <IsTruthy value={questionNumber} validationFunction={(qn) => qn === 2}>
        <LoadingSection
          request={producerRequest}
          loadingType={loadingTypes.SPINNER}
          errorType={errorTypes.NONE}
        >
          <RecipientContactCards recipients={recipients} />
          <div className="col-12">
            <div className="mm-spacing">
              <button
                id="btnAddClaimsRecipientContact"
                type="button"
                className="btn btn-secondary"
                onClick={addClaimsRecipientContact}
                disabled={disableAddContactButton}
              >
                <span className="icon-add" /> Add Contact
              </button>
            </div>
          </div>
        </LoadingSection>
      </IsTruthy>
      <IsFalsy value={questionNumber} validationFunction={(qn) => qn === 2}>
        <div
          className={isHorizontalAnswersDisplay ? `row` : ``}
          id="claims_Packet_Forms_Answers_Container"
        >
          {Object.values(formData).map((claimsAndFormsAnswer) => {
            if (
              claimsAndFormsAnswer.groupName ===
              `claimPacketAndFormsAnswer${questionNumber}Radio`
            ) {
              return (
                <div
                  id={`${claimsAndFormsAnswer.id}Answer`}
                  key={claimsAndFormsAnswer.id}
                  className={isHorizontalAnswersDisplay ? `col col-md-2` : ``}
                >
                  <RadioForm
                    key={claimsAndFormsAnswer.id}
                    formField={claimsAndFormsAnswer}
                    useHook={useClaimsPacketAndFormsRadioButtonProps}
                  />
                </div>
              );
            }
            return null;
          })}
        </div>
      </IsFalsy>
    </>
  );
};
export default React.memo(ClaimsPacketAndFormsAnswers);
