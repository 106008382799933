import React from "react";
import { v4 as uuid } from "uuid";
import Row from "./row";

const Rows = (props) => {
  const { rows, columns, customFunctions } = props;
  const generatedRows = rows.map((row, index) => (
    <Row
      id={`row-${index}`}
      key={uuid()}
      rowIndex={index}
      row={row}
      columns={columns}
      customFunctions={customFunctions}
    />
  ));
  return <tbody className="filterable sortable">{generatedRows}</tbody>;
};

export default React.memo(Rows);
