import React from "react";

const SearchButton = (props) => {
  const { disabled, onClick, formField } = props;
  return (
    <button
      className="btn btn-primary"
      type="submit"
      id={`search-active-addon-${formField.id}`}
      disabled={disabled}
      onClick={onClick}
    >
      <span className="icon-search" aria-hidden="true" title="Search" />
      <span>Search</span>
    </button>
  );
};

export default SearchButton;
