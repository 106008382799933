// eslint-disable-next-line import/prefer-default-export
export const processStatementPreferencesData = (props) => {
  const apiData = props?.response?.data;

  const customers = apiData?.customers || [];

  return {
    customers,
  };
};
