import React from "react";
import { useInitiateDeathClaimDetails } from "../hooks";

export const InitiateDeathClaimDetails = () => {
  const { decedentName, selectedDecedent } = useInitiateDeathClaimDetails();
  return (
    <div className="col-md-5">
      <div className="card--flat mm-spacing-none">
        <div className="card-body">
          <dl className="mm-key-value-pair">
            <dt id="decedent_name_lbl">Decedent Name</dt>
            <dd id="decedent_name_value">{decedentName}</dd>
            <dt id="decedent_information_policy_number_lbl">
              Policy/Contract Number(s)
            </dt>
            <dd id="decedent_information_policy_number_value">
              <ul className="list-unstyled" id="decedentInfo_Policy_Numbers">
                {selectedDecedent.policies.map((policy, index) => (
                  <li
                    key={policy.agreementKeyPrimaryDisplay}
                    id={`policyNumber_${index}`}
                  >
                    {policy.agreementKeyPrimaryDisplay}
                  </li>
                ))}
              </ul>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  );
};
export default React.memo(InitiateDeathClaimDetails);
