import React from "react";
import IntiateDeathClaimDetails from "./intiateDeathClaimDetails";
import ClaimsPacketAndFormsQuestionAndAnswers from "./claimsPacketAndForms/claimsPacketAndFormsQuestionAndAnswers";
import { useClaimsPacketAndFormsProps } from "../hooks";

const ClaimsPacketAndForms = (props) => {
  const { isDiOnlyClaim } = props;
  const {
    claimsPacketAndFormsQuestionsArray,
    producerRequest,
    claimRecipientsContactsCount,
    setClaimRecipientsContactsCount,
    disableAddContactButton,
  } = useClaimsPacketAndFormsProps(props);
  return (
    <>
      <h2>
        <div id="claim_PacketAndForms_Header">
          In this step, you will be asked to provide information about the
          recipient of the claims packet.
        </div>
      </h2>
      <div>
        <div className="row">
          <IntiateDeathClaimDetails />
        </div>
      </div>
      <ClaimsPacketAndFormsQuestionAndAnswers
        claimsPacketAndFormsQuestionsArray={claimsPacketAndFormsQuestionsArray}
        producerRequest={producerRequest}
        claimRecipientsContactsCount={claimRecipientsContactsCount}
        setClaimRecipientsContactsCount={setClaimRecipientsContactsCount}
        disableAddContactButton={disableAddContactButton}
        isDiOnlyClaim={isDiOnlyClaim}
      />
    </>
  );
};

export default ClaimsPacketAndForms;
