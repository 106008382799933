import React from "react";
import { positionCell } from "../../../utils/table";
import IsTruthy from "../logic/isTruthy";
import CustomComponent from "../logic/customComponent";
import { isDefined } from "../../../utils/evaluate";
import {
  LabelWithInformationPopover,
  InformationPopover,
} from "../popovers/informationPopover";
import IsFalsy from "../logic/isFalsy";

const Column = (props) => {
  const {
    column: {
      label,
      alignment,
      sortable,
      accessor,
      component,
      popover,
      sectionAbbreviation,
    },
    sort,
    toggleSort,
  } = props;
  const position = positionCell({ alignment });

  const onClickHandler = (e) => {
    e.preventDefault();
    toggleSort({ columnName: accessor });
  };

  const colorProperty = () => {
    const IS_SORTING_COLUMN = accessor === sort?.columnName;
    return IS_SORTING_COLUMN ? "" : "-boulder";
  };
  const iconProperties = {
    ascending: {
      id: `sortAsc-${accessor}`,
      className: `btn btn-link`,
      iconClass: `icon-sort-up${colorProperty()}`,
      ariaLabel: `Sort ascending by ${accessor}`,
    },
    descending: {
      id: `sortDesc-${accessor}`,
      className: `btn btn-link`,
      iconClass: `icon-sort-down${colorProperty()}`,
      ariaLabel: `Sort descending by ${accessor}`,
    },
    none: {
      id: `sortAsc-${accessor}`,
      className: `btn btn-link`,
      iconClass: `icon-sort-up${colorProperty()}`,
      ariaLabel: `Sort ascending by ${accessor}`,
    },
  };

  const columnDirection =
    sort?.columnName === accessor ? sort?.direction : "none";
  const buttonProperties = iconProperties[columnDirection];

  const columnIdValue = isDefined(sectionAbbreviation)
    ? `${sectionAbbreviation}_column_${accessor}`
    : `column_${accessor}`;

  return (
    <th
      id={columnIdValue}
      scope="col"
      role="columnheader"
      className={`${position}`}
    >
      <CustomComponent component={component} {...props}>
        <div className="col-header">
          <IsFalsy value={sortable} validationFunction={isDefined}>
            <IsTruthy value={popover} validationFunction={isDefined}>
              <span className="col-title">
                <LabelWithInformationPopover
                  label={label}
                  popoverId={popover?.popoverId}
                  text={popover?.text}
                />
              </span>
            </IsTruthy>
            <IsFalsy value={popover} validationFunction={isDefined}>
              <span className="col-title">{label}</span>
            </IsFalsy>
          </IsFalsy>
          <IsTruthy value={sortable} validationFunction={isDefined}>
            <span className="col-title">{label}</span>
            <div className="col-options">
              <IsTruthy value={popover} validationFunction={isDefined}>
                <span className="btn btn-link">
                  <InformationPopover
                    popoverId={popover?.popoverId}
                    text={popover?.text}
                  />
                </span>
              </IsTruthy>
              <button
                id={`${accessor}_sort_btn`}
                data-testid={`${accessor}_sort_btn`}
                type="button"
                className={buttonProperties.className}
                aria-label={buttonProperties.ariaLabel}
                onClick={onClickHandler}
                onKeyPress={onClickHandler}
                tabIndex="0"
              >
                <span className={buttonProperties.iconClass} />
              </button>
            </div>
          </IsTruthy>
        </div>
      </CustomComponent>
    </th>
  );
};

export default React.memo(Column);
