import React from "react";
import { useReviewProps } from "../hooks";
import { isDefined } from "../../../../utils/evaluate";
import IsTruthy from "../../../common/logic/isTruthy";

const Review = (props) => {
  const {
    transferToFundDisplayName,
    financialProfessionalName,
    financialProfessionalEmail,
  } = useReviewProps();
  const {
    owner,
    jointOwner,
    policyId,
    productTypeName,
    marketingName,
    fundValueAmount,
  } = props;

  return (
    <div>
      <div>
        <h1 id="review_header_lbl">Review</h1>
        <div className="row">
          <div className="col-md-7">
            <p>
              By submitting this request, you are transferring the contract
              value from the current MML Asset Allocation Fund into a new MML
              Asset Allocation Fund. Any future purchase payments will be
              allocated to your new MML Asset Allocation Fund.
            </p>
          </div>
          <div className="col-md-7">
            <div>
              <p className="h3" id="review_header_transfer_from_lbl">
                Transfer From
              </p>
            </div>
            <div className="mm-spacing">
              <dl className="mm-key-value-pair">
                <dt id="review_fundname_from_lbl">Fund Name</dt>
                <dd id="review_fundname_from_value">{marketingName}</dd>
                <dt id="review_total_estimated_value_lbl">
                  Total Estimated Value
                </dt>
                <dd id="review_total_estimated_value">{fundValueAmount}</dd>
                <dt id="review_transfer_percent_lbl">Transfer Percent</dt>
                <dd id="review_transfer_percent_value">100%</dd>
              </dl>
            </div>
            <div className="mm-spacing">
              <hr />
            </div>
            <div className="mm-spacing--minor">
              <p className="h3" id="review_header_transfer_to_lbl">
                Transfer To
              </p>
            </div>
            <div className="mm-spacing">
              <dl className="mm-key-value-pair">
                <dt id="review_fundname_to_lbl">Fund Name</dt>
                <dd id="review_fundname_to_value">
                  {transferToFundDisplayName}
                </dd>
              </dl>
            </div>
            <div className="mm-spacing">
              <hr />
            </div>
            <div className="mm-spacing--minor">
              <p className="h3" id="review_header_requestor_lbl">
                Requestor Contact
              </p>
            </div>
            <div className="mm-spacing">
              <dl className="mm-key-value-pair">
                <dt id="review_financial_professional_lbl">
                  Financial Professional
                </dt>
                <dd id="review_financial_professional_value">
                  {financialProfessionalName}
                </dd>
              </dl>
              <dl className="mm-key-value-pair">
                <dt id="review_financial_professional_email_lbl">
                  Financial Professional Email
                </dt>
                <dd id="review_financial_professional_email_value">
                  {financialProfessionalEmail}
                </dd>
              </dl>
            </div>
          </div>
          {/* floating gray box will stack at bottom for mobile phone vp */}
          <div className="col-md-5">
            <div className="card--flat mm-spacing-none">
              <div className="card-body">
                <dl className="mm-key-value-pair">
                  <dt id="review_owner_name_lbl">Owner Name</dt>
                  <dd id="review_owner_name_value">{owner}</dd>
                  <IsTruthy value={isDefined(jointOwner)}>
                    <dt id="review_joint_owner_name_lbl">Joint Owner Name</dt>
                    <dd id="review_joint_owner_name_value">{jointOwner}</dd>
                  </IsTruthy>
                  <dt id="review_product_name_lbl">Product Name</dt>
                  <dd id="review_product_name_value"> {productTypeName}</dd>
                  <dt id="review_contract_number_lbl">Contract Number</dt>
                  <dd id="review_contract_number_value">{policyId}</dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;
