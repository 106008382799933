import React from "react";
import IsTruthy from "../../../common/logic/isTruthy";
import CustomComponent from "../../../common/logic/customComponent";
import LoadingSection, {
  errorTypes,
  loadingTypes,
} from "../../../common/loading";
import { InformationPopover } from "../../../common/popovers/informationPopover";
import IsFalsy from "../../../common/logic/isFalsy";
import { isDefined, isArray } from "../../../../utils/evaluate";

const TitleSection = (props) => {
  const { label, labelId, value, valueId, showTooltip, tooltipText } = props;

  let formattedTooltipText = tooltipText;
  if (isDefined(tooltipText) && isArray(tooltipText)) {
    let tooltipTextList = "";
    tooltipText.forEach((text, index) => {
      const popoverContentId = `popoverContentId_KPI_${index}`;
      tooltipTextList += `<li key=${popoverContentId} id=${popoverContentId}>${text}</li>`;
    });
    formattedTooltipText = `
                    <div class="row">
                      <div class="col-sm">
                        <ul class="list-unstyled">
                          ${tooltipTextList}
                        </ul>
                      </div>
                    </div>
                  `;
  }

  return (
    <CustomComponent {...props}>
      <p id={labelId} className="eyebrow">
        {label}
      </p>
      <p id={valueId} className="data-lg">
        {value}{" "}
        <IsTruthy value={showTooltip}>
          <InformationPopover
            popoverId={`${valueId}_tooltip`}
            text={formattedTooltipText}
          />
        </IsTruthy>
      </p>
    </CustomComponent>
  );
};

const ValueSection = (props) => {
  const { label, labelId, value, valueId, link, onClick = () => {} } = props;
  return (
    <CustomComponent {...props}>
      <dt id={labelId}>{label}</dt>
      <IsFalsy value={link}>
        <dd id={valueId}>{value}</dd>
      </IsFalsy>
      <IsTruthy value={link}>
        <dd id={valueId}>
          <button
            id={`${valueId}_link`}
            onClick={onClick()}
            className="btn btn-inline"
            type="button"
          >
            {value}
          </button>
        </dd>
      </IsTruthy>
    </CustomComponent>
  );
};

const KpiBox = (props) => {
  const {
    kpi: { title, detailOne, detailTwo, detailThree, button, request },
    sizing,
  } = props;

  return (
    <div
      id={`${title.generalId}_kpi_box`}
      className={`col-lg-${sizing} col-md-12`}
    >
      <div className="card--flat">
        <div className="card-body">
          <LoadingSection
            request={request}
            loadingType={loadingTypes.LINE}
            errorType={errorTypes.NONE}
          >
            <TitleSection {...title} />
            <dl
              id={`${title.generalId}_kpi_value_sections`}
              className="mm-key-value-pair--inverted"
            >
              <ValueSection {...detailOne} />
              <ValueSection {...detailTwo} />
              <ValueSection {...detailThree} />
            </dl>
            <IsTruthy value={button.isVisible}>
              <hr />
              <div id={`${title.generalId}_kpi_button_section`}>
                <button
                  id={button.labelId}
                  onClick={button.onClick}
                  className="btn btn-inline"
                  type="button"
                  disabled={button.buttonDisabled}
                >
                  {button.label}
                </button>
                {"  "}
                <IsTruthy value={button.showTooltip}>
                  <InformationPopover
                    popoverId="frequency_options_tooltip"
                    text={button.tooltipText}
                  />
                </IsTruthy>
              </div>
            </IsTruthy>
          </LoadingSection>
          <LoadingSection
            request={request}
            loadingType={loadingTypes.PARAGRAPH}
            errorType={errorTypes.SECTION}
          >
            <span />
          </LoadingSection>
        </div>
      </div>
    </div>
  );
};

export default KpiBox;
