import React from "react";
import { compareStrings } from "../../../../../utils/string";
import IsTruthy from "../../../../common/logic/isTruthy";

const CurrentFrequencyCell = (props) => {
  const {
    row: { frequency, isCurrentBillingMode },
  } = props;

  return (
    <>
      {frequency}
      <IsTruthy
        value={frequency}
        validationFunction={(c) =>
          compareStrings(c, "Monthly (PAC)") ||
          compareStrings(c, "Quarterly (PAC)")
        }
      >
        <sup>1</sup>
      </IsTruthy>
      <br />
      <IsTruthy value={isCurrentBillingMode}>
        <span
          id="current_frequency"
          className="badge badge-pill badge-secondary"
        >
          Current
        </span>
      </IsTruthy>
    </>
  );
};

export default React.memo(CurrentFrequencyCell);
