/* eslint-disable react/destructuring-assignment */
import React, { createContext, useReducer, useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";
import useDeepCompareEffect from "use-deep-compare-effect";
import {
  reducer,
  types,
  initialDevModeState,
} from "../reducers/devModeReducer";
import { setSessionStorageObject, getSessionStorageObject } from "../utils/dom";
import { isDefined } from "../utils/evaluate";

const DevModeContext = createContext(null);

export const useQuery = () => new URLSearchParams(useLocation().search);

const DevModeProvider = (props) => {
  const { pathname } = useLocation();
  const name = `devModeInfo-${pathname}`;

  const storedState = getSessionStorageObject({ name });
  const notStoredState = props.initialState || initialDevModeState;

  const query = useQuery();
  const isVisible = isDefined(query.get("testApi"));

  const initialState = isDefined(storedState) ? storedState : notStoredState;

  const [devModeInfo, dispatchInfo] = useReducer(reducer, {
    ...initialState,
    isVisible,
  });

  useDeepCompareEffect(() => {
    setSessionStorageObject({ name, value: devModeInfo });
  }, [devModeInfo]);

  const values = useMemo(
    () => ({
      devModeInfo,
      dispatchInfo,
    }),
    [devModeInfo, dispatchInfo]
  );

  return (
    <DevModeContext.Provider value={values}>
      {props.children}
    </DevModeContext.Provider>
  );
};

const useDevModeContext = () => useContext(DevModeContext);

export { useDevModeContext, DevModeProvider, types };
