import React, { useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useFormContext } from "../../../../contexts/form";
import { useMakeCatsCreationCall } from "./hooks";
import { createCatsEvents } from "../../../../api/realApi";
import { fireTealiumEvent } from "../../../../utils/tealium";
import { useCancelModal } from "../../../common/popup/cancel";
import { checkForRequestorContactError } from "../../../common/requestorContact/hooks";
import { useRequestorContactEmail } from "../../../../hooks/helper";
import { datePickerInputValidation } from "../../../../utils/validation";
import IsTruthy from "../../../common/logic/isTruthy";
import { isDefined } from "../../../../utils/evaluate";

const FormButtons = ({
  agreementKey = "",
  agreementRequest,
  producersData,
  insured,
  setConfirmationPage,
  setConfirmationProps,
  setShowErrorPage,
  setTransactionId,
  servicingAgent,
  shouldDisableSubmit,
  setError,
}) => {
  const { openCancelModal } = useCancelModal();

  const { formData } = useFormContext();
  const requestorContactEmail = useRequestorContactEmail();
  const { hasRequestorContactError } = checkForRequestorContactError({
    formData,
    setError,
    requestorContactEmail,
  });

  const [checkboxSelected, setCheckboxSelected] = useState(false);
  const [asOfDateError, setAsOfDateError] = useState(null);

  useDeepCompareEffect(() => {
    let foundSelectedCheckBox = false;
    Object.keys(formData).forEach((key) => {
      if (formData[key]?.type === "checkbox" && formData[key]?.value === true) {
        foundSelectedCheckBox = true;
      }
    });
    setCheckboxSelected(foundSelectedCheckBox);
  }, [formData]);

  const {
    requestInformationAsOfDate: { value, minDate, maxDate },
  } = formData;
  const disableSubmitButton = shouldDisableSubmit || false;

  const enableSubmitButton =
    checkboxSelected &&
    formData.requestorNameInput.errors.length === 0 &&
    !hasRequestorContactError &&
    !disableSubmitButton;

  const { requestBody, user, confirmationDate } = useMakeCatsCreationCall({
    agreementKey,
    producersData,
    formData,
    agreementRequest,
    insured,
    servicingAgent,
  });

  const { policyId, lineOfBusinessCode } = agreementRequest?.data || {};

  const onClickHander = (e) => {
    e.preventDefault();

    if (
      datePickerInputValidation({
        selectedDate: value,
        datePickerMinDate: minDate,
        datePickerMaxDate: maxDate,
      })
    ) {
      setAsOfDateError("Please fix input errors.");
    } else {
      const headers = {
        Authorization: `Bearer ${user.accessToken}`,
      };
      createCatsEvents({ headers, apiParams: requestBody })
        .then((result) => {
          setTransactionId(result?.data?.[0]?.transactionId);
          if (result?.status !== 200) {
            throw new Error("non 200 response");
          } else {
            result?.data?.forEach((responseData) => {
              if (responseData?.messages?.[0]?.msgType !== "SUCCESS") {
                throw new Error("one or more of requests failed");
              } else {
                fireTealiumEvent({
                  label: "SVNT",
                  action: `Request Information Submitted`,
                  category: "Request Information",
                  value: policyId,
                  custId: user?.email,
                });
              }
            });
            setConfirmationProps({ insured, formData, confirmationDate });
            setConfirmationPage(true);
          }
        })
        .catch(() => {
          fireTealiumEvent({
            label: "SVNT",
            action: `${lineOfBusinessCode} Request Information Failed`,
            category: "Request Information",
            value: policyId,
            custId: user?.email,
          });
          setShowErrorPage(true);
        });
    }
  };

  return (
    <>
      <IsTruthy value={asOfDateError} validationFunction={isDefined}>
        <div
          className="alert alert-danger alert-dismissible fade show mm-spacing--minor"
          role="alert"
          aria-label={asOfDateError}
        >
          {asOfDateError}
        </div>
      </IsTruthy>
      <button
        type="submit"
        className="btn btn-primary"
        disabled={!enableSubmitButton}
        onClick={onClickHander}
      >
        Submit
      </button>
      <button
        type="button"
        className="btn btn-link"
        onClick={() => openCancelModal()}
      >
        Cancel
      </button>
    </>
  );
};

export default FormButtons;
