import { compareStrings } from "../../../utils/string";

export const FUND_ALLOCATION_NOT_ELIGIBLE_MESSAGE =
  "We're sorry, this contract is not eligible for an Asset Allocation transaction.";
export const FUND_ALLOCATION_AUTHORIZATION_FORM_MESSAGE =
  "Our records indicate you are not authorized to perform this Transfer funds transaction. Please complete a Producer Authorization Form (FR2123) <a href='https://fieldnet.massmutual.com/public/ann/pdfs/fr2123.pdf'>here</a>.";
export const FUND_ALLOCATION_NOT_AUTHORIZED_MESSAGE =
  "We’re sorry it looks like you’re not able to perform this transaction. If you have any questions, contact our Service Center at 1-800-272-2216, Mon. - Fri. 8 AM to 8 PM ET.";

export const FUND_ALLOCATION_ERRORS = [
  { code: "1", displayMessage: FUND_ALLOCATION_NOT_ELIGIBLE_MESSAGE },
  { code: "2", displayMessage: FUND_ALLOCATION_NOT_ELIGIBLE_MESSAGE },
  { code: "3", displayMessage: FUND_ALLOCATION_NOT_ELIGIBLE_MESSAGE },
  { code: "4", displayMessage: FUND_ALLOCATION_NOT_ELIGIBLE_MESSAGE },
  { code: "5", displayMessage: FUND_ALLOCATION_NOT_ELIGIBLE_MESSAGE },
  { code: "6", displayMessage: FUND_ALLOCATION_NOT_ELIGIBLE_MESSAGE },
  { code: "7", displayMessage: FUND_ALLOCATION_AUTHORIZATION_FORM_MESSAGE },
  { code: "8", displayMessage: FUND_ALLOCATION_AUTHORIZATION_FORM_MESSAGE },
  {
    code: "9",
    displayMessage:
      "This contract has a completed transfer funds transaction present for the current date. Please refer to service history section on the contract details page.",
  },
  {
    code: "10",
    displayMessage:
      "This contract has an existing transfer funds transaction. Please refer to Service History section on the Contract Details Page.",
  },
];

export const getDisplayTextForError = (errorCode, defaultReturn = "") =>
  FUND_ALLOCATION_ERRORS.find((error) => compareStrings(error.code, errorCode))
    ?.displayMessage || defaultReturn;
