import React from "react";
import IsTruthy from "../logic/isTruthy";
import { useBannerMessageProps } from "../../../hooks/helper";

const LdBannerContent = () => {
  const { showBannerMessage, bannerMessageToDisplay } = useBannerMessageProps();

  return (
    <IsTruthy value={showBannerMessage}>
      <div dangerouslySetInnerHTML={{ __html: bannerMessageToDisplay }} />
      <br />
    </IsTruthy>
  );
};

export default LdBannerContent;
