import React from "react";
import { NOT_APPLICABLE } from "../../../../../../constants/ui";
import { isEmptyObject } from "../../../../../../utils/evaluate";
import { formatMoney } from "../../../../../../utils/format";
import Table from "../../../../../common/table";

const getLoanStatus = (groupedLoanListValue) => ({
  employee: groupedLoanListValue?.EE?.[0]?.status || NOT_APPLICABLE,
  employer: groupedLoanListValue?.ER?.[0]?.status || NOT_APPLICABLE,
});

const getOriginalLoanAmount = (groupedLoanListValue) => ({
  employee:
    formatMoney(groupedLoanListValue?.EE?.[0]?.origLoanAmt) || NOT_APPLICABLE,
  employer:
    formatMoney(groupedLoanListValue?.ER?.[0]?.origLoanAmt) || NOT_APPLICABLE,
});

const getRemainingLoanAmount = (groupedLoanListValue) => ({
  employee:
    formatMoney(groupedLoanListValue?.EE?.[0]?.remainingBalance) ||
    NOT_APPLICABLE,
  employer:
    formatMoney(groupedLoanListValue?.ER?.[0]?.remainingBalance) ||
    NOT_APPLICABLE,
});

const getQuarterlyPayments = (groupedLoanListValue) => ({
  employee:
    formatMoney(groupedLoanListValue?.EE?.[0]?.nextBillDueAmt) ||
    NOT_APPLICABLE,
  employer:
    formatMoney(groupedLoanListValue?.ER?.[0]?.nextBillDueAmt) ||
    NOT_APPLICABLE,
});

const getLoanDetailInformation = (groupedLoanList) => {
  const groupedLoanListValues = Object.values(groupedLoanList);

  const detailsResult = groupedLoanListValues.reduce(
    (loansDetail, groupedLoanListValue, currentIndex) => {
      const formattedLoanStatus = getLoanStatus(groupedLoanListValue);
      const formattedOriginalLoanAmount =
        getOriginalLoanAmount(groupedLoanListValue);
      const formattedRemainingLoanAmount =
        getRemainingLoanAmount(groupedLoanListValue);
      const formattedQuarterlyPayments =
        getQuarterlyPayments(groupedLoanListValue);

      if (isEmptyObject(loansDetail)) {
        return {
          loanStatus: {
            header: "Loan Status",
            loan1Employee: formattedLoanStatus.employee,
            loan1Employer: formattedLoanStatus.employer,
          },
          originalLoanAmount: {
            header: "Original Loan Amount",
            loan1Employee: formattedOriginalLoanAmount.employee,
            loan1Employer: formattedOriginalLoanAmount.employer,
          },
          remainingLoanAmount: {
            header: "Remaining Loan Amount",
            loan1Employee: formattedRemainingLoanAmount.employee,
            loan1Employer: formattedRemainingLoanAmount.employer,
          },
          quarterlyPayments: {
            header: "Quarterly Payments",
            loan1Employee: formattedQuarterlyPayments.employee,
            loan1Employer: formattedQuarterlyPayments.employer,
          },
        };
      }
      return {
        loanStatus: {
          ...loansDetail.loanStatus,
          [`loan${currentIndex + 1}Employee`]: formattedLoanStatus.employee,
          [`loan${currentIndex + 1}Employer`]: formattedLoanStatus.employer,
        },
        originalLoanAmount: {
          ...loansDetail.originalLoanAmount,
          [`loan${currentIndex + 1}Employee`]:
            formattedOriginalLoanAmount.employee,
          [`loan${currentIndex + 1}Employer`]:
            formattedOriginalLoanAmount.employer,
        },
        remainingLoanAmount: {
          ...loansDetail.remainingLoanAmount,
          [`loan${currentIndex + 1}Employee`]:
            formattedRemainingLoanAmount.employee,
          [`loan${currentIndex + 1}Employer`]:
            formattedRemainingLoanAmount.employer,
        },
        quarterlyPayments: {
          ...loansDetail.quarterlyPayments,
          [`loan${currentIndex + 1}Employee`]:
            formattedQuarterlyPayments.employee,
          [`loan${currentIndex + 1}Employer`]:
            formattedQuarterlyPayments.employer,
        },
      };
    },
    {}
  );

  return [
    detailsResult.loanStatus,
    detailsResult.originalLoanAmount,
    detailsResult.remainingLoanAmount,
    detailsResult.quarterlyPayments,
  ];
};

const getLoanDetailsColumns = (outstandingLoanCount) => {
  const irregularColumns = [
    { label: "", dataCell: true },
    { label: "Loan 1", colSpan: "2" },
  ];
  const loanDetailColumns = [
    {
      accessor: "header",
      label: "",
      alignment: "left",
    },
    {
      accessor: "loan1Employee",
      label: "Employee",
      alignment: "left",
    },
    {
      accessor: "loan1Employer",
      label: "Employer",
      alignment: "left",
    },
  ];
  if (outstandingLoanCount > 1) {
    loanDetailColumns.push(
      {
        accessor: "loan2Employee",
        label: "Employee",
        alignment: "left",
      },
      {
        accessor: "loan2Employer",
        label: "Employer",
        alignment: "left",
      }
    );
    irregularColumns.push({ label: "Loan 2", colSpan: "2" });
  }
  if (outstandingLoanCount > 2) {
    loanDetailColumns.push(
      {
        accessor: "loan3Employee",
        label: "Employee",
        alignment: "left",
      },
      {
        accessor: "loan3Employer",
        label: "Employer",
        alignment: "left",
      }
    );
    irregularColumns.push({ label: "Loan 3", colSpan: "2" });
  }
  return { irregularColumns, loanDetailColumns };
};

const LoanDetailsInfoTable = (props) => {
  const { outstandingLoanCount, groupedLoanList } = props;
  const { irregularColumns, loanDetailColumns } =
    getLoanDetailsColumns(outstandingLoanCount);
  const loanDetailRows = getLoanDetailInformation(groupedLoanList);
  return (
    <Table
      id="loan_detail"
      columns={loanDetailColumns}
      rows={loanDetailRows}
      showPagination={false}
      irregularColumns={irregularColumns}
    />
  );
};
export default LoanDetailsInfoTable;
