import { useEffect } from "react";
import { useMakeRequest } from "../../../hooks/api";
import {
  formatAddress,
  formatLineOfBusiness,
  formatPhoneNumberObject,
  formatStatusCode,
} from "../../../utils/format";
import { compareStrings } from "../../../utils/string";
import { formatDate, getAge } from "../../../utils/dates";
import {
  getUsers,
  getBest,
  getTrimmedPolicyIdFromAgreement,
  getAttentionLineByRole,
  getCustomerByMemberId,
} from "../../../utils/findData";
import { getLink } from "../table/customCells/links";
import {
  removeOwnerAndInsuredDuplicates,
  isEnvisionPolicy,
  isPolicyInForce,
  isPallmPolicy,
  isAnnuityContract,
  isRetireEaseContract,
  isNonEnvisionAnnuity,
  isFlexIVFund1FPOrFund4FP,
  isFoundationFlexiblePremium,
  isFoundationSinglePremium,
} from "../../../utils/policies";
import { useIsFixedAnnuityContract } from "../../../hooks/helper";
import { useLDValue } from "../featureFlags/hooks";
import { verifyChangeAddress } from "../../changeAddress/hooks";
import { isDefined } from "../../../utils/evaluate";

export const usePolicyAndContactInfoDrawerProps = (props) => {
  const agreementData = props.agreementRequest.data;
  const billingData = props.billingRequest.data;
  const {
    customer: { memberGUID, fullName, memberId },
  } = props;

  const customerRequest = useMakeRequest({
    apiRequest: "fetchCustomerData",
    apiParams: { memberGUID },
    immediateRequest: true,
    dataSelector: "processCustomersData",
  });
  const { data: customerData } = customerRequest;

  return {
    customerData,
    agreementData,
    customerOwnerFullName: fullName,
    customerRequest,
    billingData,
    memberId,
  };
};

export const useFormatBasicPolicyInfoProps = (props) => {
  const { agreementData, row, agreementRequest, policyId } = props;

  const { lineOfBusinessCode, lineOfBusinessName, status } = agreementData;

  const formattedStatusCode = formatStatusCode(status);

  const badgeColor =
    formattedStatusCode === "New Business" || formattedStatusCode === "In Force"
      ? "badge-primary"
      : "badge-secondary";
  const viewPolicyDetailsLink = row
    ? getLink({ row, accessor: "policyId" })
    : "";

  const { trimmedPolicyId } = getTrimmedPolicyIdFromAgreement(
    row?.agreementKey
  );

  const envisionPolicy = isEnvisionPolicy(agreementRequest.data);

  const isFixedAnnuityContract = useIsFixedAnnuityContract(
    agreementRequest.data
  );
  const isPallm = isPallmPolicy(agreementData);

  const policyOrContract = isAnnuityContract(lineOfBusinessCode)
    ? "Contract "
    : "Policy ";

  const policyInForce = isPolicyInForce(agreementRequest?.data?.status);

  return {
    policyId,
    lineOfBusinessCode,
    formattedLineOfBusinessCode: isFixedAnnuityContract
      ? "Annuity"
      : formatLineOfBusiness({
          lineOfBusinessCode,
          lineOfBusinessName,
        }),
    formattedStatusCode,
    badgeColor,
    viewPolicyDetailsLink,
    trimmedPolicyId,
    envisionPolicy,
    isFixedAnnuityContract,
    policyInForce,
    isPallm,
    policyOrContract,
  };
};

export const useLandingPageDrawer = (props) => {
  const {
    row: { agreementKey, memberId },
  } = props;
  const agreementRequest = useMakeRequest({
    apiRequest: "fetchAgreementData",
    apiParams: { agreementKey },
    immediateRequest: true,
    dataSelector: "processAgreementRequest",
    defaultReturn: { agreements: [{}] },
  });

  const agreementData = agreementRequest.data;
  const {
    data: { agreementCustomers },
  } = agreementRequest;

  const policyOwners = removeOwnerAndInsuredDuplicates(
    getUsers(agreementCustomers, "OWNR")
  );
  const owner = policyOwners.filter((potentialOwner) =>
    compareStrings(potentialOwner.memberId, memberId)
  );

  const memberGUID = owner[0]?.memberGUID;

  const customerRequest = useMakeRequest({
    apiRequest: "fetchCustomerData",
    apiParams: { memberGUID },
    immediateRequest: false,
    dataSelector: "processCustomersData",
    initiateDevModeDispatch: false,
  });

  const { data: customerData, executeRequest: executeCustomerRequest } =
    customerRequest;

  const producerRequest = useMakeRequest({
    apiRequest: "fetchProducersData",
    apiParams: { agreementKey },
    immediateRequest: true,
    dataSelector: "processProducersData",
  });

  const { data: producerData } = producerRequest;

  const contractNbr = agreementData?.policyId;

  const shouldUseSoaBillingArrangement =
    isFlexIVFund1FPOrFund4FP(agreementData?.kindCode) ||
    isFoundationFlexiblePremium(agreementData) ||
    isFoundationSinglePremium(agreementData);

  const shouldUseSoaService =
    isRetireEaseContract(agreementData) || shouldUseSoaBillingArrangement;

  const isNonEnvisionAnnuityContract = isNonEnvisionAnnuity(agreementRequest);

  const annuityRequest = useMakeRequest({
    apiRequest: shouldUseSoaService
      ? "fetchAnnuityByAgreementKey"
      : "fetchAnnuityByContractNbr",
    apiParams: { contractNbr, agreementKey },
    dataSelector: "processAnnuityData",
    immediateRequest: false,
    initiateDevModeDispatch: isNonEnvisionAnnuityContract,
  });

  const billingRequest = useMakeRequest({
    apiRequest: "fetchBillingInformation",
    apiParams: { agreementKey, showExtended: false },
    dataSelector: "processBillingInformation",
    immediateRequest: true,
  });
  const billingData = billingRequest.data;

  const isAnnuityDataLoaded = isDefined(annuityRequest?.data?.carrierCode);

  const soaBillingRequest = useMakeRequest({
    apiRequest: "fetchSoaBillingArrangement",
    apiParams: { agreementKey, carrierCode: annuityRequest?.data?.carrierCode },
    immediateRequest: false,
    dataSelector: "processSoaBillingArrangement",
    initiateDevModeDispatch: shouldUseSoaBillingArrangement,
  });

  const isAgreementLoaded =
    agreementData?.policyId && agreementData?.lineOfBusinessCode;
  const isSupportedAnnuity = useIsFixedAnnuityContract(agreementData);

  useEffect(() => {
    if (
      isAgreementLoaded &&
      isNonEnvisionAnnuityContract &&
      isSupportedAnnuity
    ) {
      annuityRequest.executeRequest();
    }
  }, [isAgreementLoaded]);

  useEffect(() => {
    if (isAnnuityDataLoaded && shouldUseSoaBillingArrangement) {
      soaBillingRequest.executeRequest();
    }
  }, [isAnnuityDataLoaded]);

  useEffect(() => {
    if (memberGUID) {
      executeCustomerRequest();
    }
  }, [memberGUID]);

  return {
    customerData,
    agreementData,
    producerData,
    customerOwnerFullName: owner[0]?.fullName,
    agreementRequest,
    customerRequest,
    producerRequest,
    annuityRequest,
    billingData,
    billingRequest,
    soaBillingRequest,
    memberId,
  };
};

export const useCustomerInfo = (props) => {
  const {
    customerData: { addresses, emails, birthDate, governmentIdLast4, phones },
    billingData,
    agreementData,
    customerData,
    memberId,
  } = props;

  const customer = getCustomerByMemberId({ memberId, agreementData });

  const { agreementKey, lineOfBusinessCode } = agreementData;

  const address = getBest(addresses) || {};
  const attentionLine = getAttentionLineByRole({
    customerAgreements: address?.customerAgreements,
    roles: ["OWNR"],
    agreementKey,
  });
  const addressWithAttention = { ...address, attentionLine };

  const {
    attnLine,
    addrFirstLine,
    addrSecondLine,
    addrThirdLine,
    addrFourthLine,
  } = formatAddress(addressWithAttention);

  const email =
    emails?.length > 0 && isDefined(getBest(emails).value)
      ? getBest(emails).value
      : "-";
  const lastFourSSN =
    governmentIdLast4?.length > 0 ? `***-**-${governmentIdLast4}` : "-";
  const ageAndDOB = birthDate
    ? `${formatDate(birthDate, "MM/DD/YYYY")} (${getAge(birthDate)} years)`
    : "-";
  const phone = getBest(phones);
  const formattedPhone = formatPhoneNumberObject(phone, "-");

  const transactionSupportedPolicies = useLDValue({
    flagName: ["transactionSupportedPolicies"],
  });
  const changeAddressProps = verifyChangeAddress({
    data: props.agreementData,
    policyId: props.policyId,
    prependPolicyDetails: true,
    transactionSupportedPolicies,
    billingData,
  });
  const isPallm = isPallmPolicy(agreementData);
  const hasAddressFirstandFourthLineAvailable = addrFirstLine && addrFourthLine;

  const { trimmedPolicyId } = getTrimmedPolicyIdFromAgreement(agreementKey);

  return {
    isPallm,
    attnLine,
    addrFirstLine,
    addrSecondLine,
    addrThirdLine,
    addrFourthLine,
    changeAddressProps,
    email,
    lastFourSSN,
    ageAndDOB,
    agreementKey,
    phone: formattedPhone.formattedPhoneNumber,
    trimmedPolicyId,
    hasAddressFirstandFourthLineAvailable,
    customerData,
    customer,
    lineOfBusinessCode,
  };
};
