import React from "react";
import { useFormContext } from "../../contexts/form";
import { useReviewProps } from "./hooks";
import sampleEmail from "../../assets/images/direct_links_sample_email.png";
import { SelectedDirectLinksList } from "./common/selectedDirectLinksList";

const Review = () => {
  const { formData } = useFormContext();
  const { selectedDirectLinks, selectedOwnerFields, selectedOwnerEmailFields } =
    useReviewProps(formData);

  const selectedOwners = selectedOwnerFields.map((owner, index) => {
    const ownerName = owner.label;
    const ownerEmail = selectedOwnerEmailFields[index].value;
    return (
      <>
        {" "}
        <dt id={`review_owner${index}_name_lbl}`}>Owner Full Name:</dt>
        <dd id={`review_owner${index}_name_value}`}>{ownerName}</dd>
        <dt id={`review_owner${index}_email_lbl}`}>Email Address:</dt>
        <dd id={`review_owner${index}_email_value}`}>{ownerEmail}</dd>
      </>
    );
  });

  return (
    <div>
      <div className="row">
        <div className="col-md-7">
          <h1 id="header-review"> Review </h1>
          <p className="h3" id="review_direct_link_recipients_lbl">
            Direct Link Recipient(s)
          </p>
          <div className="mm-spacing">
            <dl className="mm-key-value-pair">{selectedOwners}</dl>
          </div>
          <div className="mm-spacing">
            <hr />
          </div>
          <p className="h3" id="review_selected_direct_link_lbl">
            Selected Direct Links
          </p>
          <div className="mm-spacing">
            <SelectedDirectLinksList
              selectedDirectLinks={selectedDirectLinks}
              isCalledFrom="review"
            />
          </div>
          <div className="mm-spacing">
            <hr />
          </div>
          <p className="h3" id="review_email_sample_lbl">
            Email Preview
          </p>{" "}
          <div className="mm-spacing">
            <img
              id="review_email_sample_image"
              style={{
                maxWidth: "450px",
                padding: "5px",
                backgroundColor: "rgb(228, 231, 233)",
              }}
              alt="Sample Email"
              src={sampleEmail}
            />
          </div>
          <div className="mm-spacing">
            <p id="sample_email_footnote">
              <sup>*</sup>Sample Email
            </p>
          </div>
          <div className="mm-spacing">
            <hr />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;
