import { checkIfUserRegisteredOnMM } from "../../../utils/findData";

export const useUserRegisteredSignifierProps = (props) => {
  const {
    isUserRegistered,
    userRegisteredMessageTooltipText,
    userRegisteredMessage,
  } = checkIfUserRegisteredOnMM(props);

  const userRegisteredIcon = isUserRegistered ? "check-circle" : "cancel";

  return {
    isUserRegistered,
    userRegisteredMessageTooltipText,
    userRegisteredIcon,
    userRegisteredMessage,
  };
};
