import React from "react";
import BasicPolicyAndContactInfo from "./basicPolicyAndContactInfo";
import { useLandingPageDrawer } from "./hooks";

const LandingPageDrawer = (props) => {
  const { row } = props;

  const {
    customerData,
    agreementData,
    producerData,
    customerOwnerFullName,
    agreementRequest,
    customerRequest,
    producerRequest,
    annuityRequest,
    billingData,
    billingRequest,
    soaBillingRequest,
    memberId,
  } = useLandingPageDrawer(props);

  return (
    <div>
      <BasicPolicyAndContactInfo
        customerOwnerFullName={customerOwnerFullName}
        customerData={customerData}
        policyId={row.policyId}
        row={row}
        producerData={producerData}
        agreementData={agreementData}
        target="_blank"
        agreementRequest={agreementRequest}
        customerRequest={customerRequest}
        producerRequest={producerRequest}
        annuityRequest={annuityRequest}
        billingData={billingData}
        billingRequest={billingRequest}
        soaBillingRequest={soaBillingRequest}
        memberId={memberId}
      />
    </div>
  );
};
export default React.memo(LandingPageDrawer);
