import React, { createContext, useReducer, useContext, useMemo } from "react";
import { reducer, types } from "../reducers/formReducer";

const FormContext = createContext(null);

const FormProvider = (props) => {
  const { children, fields } = props;
  const [formData, dispatchForm] = useReducer(reducer, fields);

  const values = useMemo(
    () => ({
      formData,
      dispatchForm,
    }),
    [formData, dispatchForm]
  );

  return <FormContext.Provider value={values}>{children}</FormContext.Provider>;
};

const useFormContext = () => useContext(FormContext);

export { useFormContext, FormProvider, types };
