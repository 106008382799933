import React, { useEffect } from "react";
import { redirectToUrl } from "../../utils/dom";
import { isDefined } from "../../utils/evaluate";
import {
  getBillingModeData,
  getModalPremiumsForPolicy,
} from "../../utils/findData";
import { fireTealiumAndFollowLink } from "../../utils/tealium";
import { verifyChangeBillingFrequency } from "../changeBillingFrequency/restrictions";
import { useLDValue } from "../common/featureFlags/hooks";
import IsFalsy from "../common/logic/isFalsy";
import IsTruthy from "../common/logic/isTruthy";

const EditBillingFrequencyLink = (props) => {
  const {
    lineOfBusinessCode,
    email,
    policyId,
    id,
    className,
    tealiumCategory,
    renderAsListItem,
    target,
    billingData,
    agreementData,
    isEligibilityCheckRequired = true,
    setShowEditBillingFrequencyLink = () => {},
  } = props;

  const { agreementKey } = props;

  const transactionSupportedPolicies = useLDValue({
    flagName: ["transactionSupportedPolicies"],
  });
  let isEligibleForFrequencyChange = true;
  let redirectUrl = `/policyDetails/${encodeURIComponent(
    agreementKey
  )}/changeBillingFrequency`;

  if (isEligibilityCheckRequired) {
    const modalPremiums = getModalPremiumsForPolicy({
      agreementData,
      billingData,
    });
    const { billingModeData } = getBillingModeData(modalPremiums);

    const { redirectLink, isEligible, isTransactionAllowed } =
      verifyChangeBillingFrequency({
        agreementData,
        billingData,
        billingModeData,
        transactionSupportedPolicies,
      });

    isEligibleForFrequencyChange = isTransactionAllowed && isEligible;
    redirectUrl = redirectLink;
  }

  const onClickHandler = () => {
    if (isDefined(tealiumCategory)) {
      if (tealiumCategory === "PDP") {
        fireTealiumAndFollowLink({
          url: redirectUrl,
          action: `${lineOfBusinessCode}: Frequency Change/PAC Removal link clicked on PDP`,
          category: tealiumCategory,
          value: policyId,
          custId: email,
          target,
        });
      } else if (tealiumCategory === "QuickLink") {
        fireTealiumAndFollowLink({
          url: redirectUrl,
          action: `${lineOfBusinessCode}: Frequency Change/PAC Removal link clicked via landing page ellipsis`,
          category: tealiumCategory,
          value: policyId,
          custId: email,
          target,
        });
      } else if (tealiumCategory === "Billing") {
        fireTealiumAndFollowLink({
          url: redirectUrl,
          action: `${lineOfBusinessCode}: Frequency Change/PAC Removal link clicked in PDP billing section`,
          category: tealiumCategory,
          value: policyId,
          custId: email,
          target,
        });
      }
    } else {
      redirectToUrl(redirectUrl, target);
    }
  };
  useEffect(() => {
    setShowEditBillingFrequencyLink(isEligibleForFrequencyChange);
  }, [isEligibleForFrequencyChange]);
  return (
    <IsTruthy value={isEligibleForFrequencyChange}>
      <IsTruthy value={renderAsListItem}>
        <li>
          <button
            type="button"
            id={id}
            onClick={onClickHandler}
            className={className}
          >
            Frequency Change/PAC Removal
          </button>
        </li>
      </IsTruthy>
      <IsFalsy value={renderAsListItem}>
        <button
          type="button"
          id={id}
          onClick={onClickHandler}
          className={className}
        >
          Frequency Change/PAC Removal
        </button>
      </IsFalsy>
    </IsTruthy>
  );
};

export default EditBillingFrequencyLink;
