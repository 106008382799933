import React from "react";

const ParagraphLoading = () => (
  <div className="mm-skeleton--tall mm-spacing">
    <span className="sr-only" aria-busy="true" aria-live="polite">
      Content is loading
    </span>
  </div>
);

export default ParagraphLoading;
