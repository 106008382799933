import React, { useEffect } from "react";
import { setTitle } from "../../utils/dom";
import ModalControl from "./modalControl";
import Form from "../common/forms/form";
import { ModalProvider } from "../../contexts/modal";
import IsTruthy from "../common/logic/isTruthy";
import NewLoanHeader from "./NewLoanHeader";
import { useInitiateLoanProps, useRequestorContactProps } from "./hooks";
import QuestionsAndDisclosures from "./pages/questionsAndDisclosures";
import LoanDetails from "./pages/loanDetails";
import MailingAddress from "./pages/mailingAddress";
import Review from "./pages/review";
import CTAform from "./pages/CTAform";
import Confirmation from "./pages/confirmation";
import IsFalsy from "../common/logic/isFalsy";
import LoadingSection, { errorTypes, loadingTypes } from "../common/loading";
import Header from "../layout/header";
import FiveHundredOneError from "../common/errors/501";
import FiveHundredError from "../common/errors/500";
import FourHundredFourError from "../common/errors/404";
import Footer from "../layout/footer";
import { tealiumPageViewEvent } from "../../utils/tealium";
import LdBannerContent from "../common/molecules/ldBannerContent";
import RequestorContact from "../common/requestorContact";
import ErrorPage from "../common/errors/errorPage";

const InitiateNewLoan = (props) => {
  setTitle("ServiceNet - Initiate New Loan");
  const {
    policyId,
    agreementKey,
    pageNumber,
    nextPage,
    previousPage,
    documentId,
    fields,
    setPageNumber,
    pagesToSkip,
    setPagesToSkip,
    loanRequest,
    onSubmit,
    page1error,
    setPage1Error,
    page3error,
    setPage3Error,
    agreementData,
    agreementRequest,
    setError,
    error,
    page2error,
    setPage2Error,
    shouldDisableSubmit,
    IS_UNAUTHORIZED,
    email,
    loanSubmissionError,
    errorText,
    transactionId,
    showLoadingIcon,
    isEligible,
    isTransactionAllowed,
    isPolicyLoaded,
    isMmsdOnlyUser,
    isAddressOnFile,
    isPolicyNotFound,
    loanRestrictedSwlPolicy,
    eligibilityError,
  } = useInitiateLoanProps(props);
  useEffect(() => {
    tealiumPageViewEvent({
      page: "Initiate New Loan",
      category: agreementKey,
      custId: email,
    });
  }, []);

  if (loanSubmissionError) {
    return (
      <>
        <Header />
        <div className="container">
          <LdBannerContent />
          <FiveHundredError
            turnOffReload
            title={errorText}
            transactionId={transactionId}
            agreementKey={agreementKey}
          />
        </div>
        <Footer />
      </>
    );
  }

  if (IS_UNAUTHORIZED) {
    return (
      <>
        <Header />
        <div className="container">
          <LdBannerContent />
          <FiveHundredOneError />
        </div>
        <Footer />
      </>
    );
  }

  if (isPolicyNotFound) {
    return (
      <>
        <Header />
        <div className="container">
          <LdBannerContent />
          <FourHundredFourError />
        </div>
        <Footer />
      </>
    );
  }

  return (
    <LoadingSection
      request={agreementRequest}
      loadingType={loadingTypes.SPINNER}
      errorType={errorTypes._500}
      addHeaderFooterToError
    >
      <LoadingSection
        request={loanRequest}
        loadingType={loadingTypes.SPINNER}
        errorType={errorTypes._500}
        addHeaderFooterToError
      >
        <IsTruthy value={isPolicyLoaded}>
          <IsFalsy value={isTransactionAllowed}>
            <IsTruthy value={isMmsdOnlyUser}>
              <Header />
              <div className="container">
                <LdBannerContent />
                <ErrorPage
                  errorTitle="Our records indicate you do not have access to this application."
                  agreementKey={agreementRequest?.agreementKey}
                  errorPageFor="mmsdOnly"
                  imageClass="illustration-error_500"
                />
              </div>
              <Footer />
            </IsTruthy>
            <IsFalsy value={isMmsdOnlyUser}>
              <Header />
              <div className="container">
                <LdBannerContent />
                <FourHundredFourError />
              </div>
              <Footer />
            </IsFalsy>
          </IsFalsy>
          <IsTruthy value={isTransactionAllowed}>
            <IsFalsy value={isEligible}>
              <Header />
              <div className="container">
                <LdBannerContent />
                <ErrorPage
                  errorTitle={eligibilityError}
                  agreementKey={agreementRequest?.data?.agreementKey}
                  errorPageFor="404"
                  imageClass="illustration-error_404"
                  lineOfBusinessCode={
                    agreementRequest?.data?.lineOfBusinessCode
                  }
                  displayTransactionId={!loanRestrictedSwlPolicy}
                />
              </div>
              <Footer />
            </IsFalsy>
          </IsTruthy>
          <IsTruthy value={isTransactionAllowed && isEligible}>
            <div className="container">
              <Form fields={fields}>
                <ModalProvider>
                  <NewLoanHeader
                    pageNumber={pageNumber}
                    previousPage={previousPage}
                    shouldDisableSubmit={shouldDisableSubmit}
                  />
                  <IsTruthy value={pageNumber === 0 || pageNumber === 1}>
                    <QuestionsAndDisclosures
                      setPagesToSkip={setPagesToSkip}
                      setPage1Error={setPage1Error}
                      pagesToSkip={pagesToSkip}
                    />
                  </IsTruthy>
                  <IsTruthy value={pageNumber === 2}>
                    <LoanDetails
                      setPage2Error={setPage2Error}
                      loanRequest={loanRequest}
                    />
                  </IsTruthy>
                  <IsTruthy value={pageNumber === 3}>
                    <MailingAddress
                      loanRequest={loanRequest}
                      agreementData={agreementData}
                      formFields={fields}
                      setPage3Error={setPage3Error}
                      nextPage={nextPage}
                    />
                  </IsTruthy>
                  <IsTruthy value={pageNumber === 4}>
                    <RequestorContact
                      useContainerHook={useRequestorContactProps}
                    />
                  </IsTruthy>
                  <IsTruthy value={pageNumber === 5}>
                    <Review
                      loanRequest={loanRequest}
                      setPageNumber={setPageNumber}
                      agreementData={agreementData}
                    />
                  </IsTruthy>
                  <IsTruthy value={pageNumber === 6}>
                    <Confirmation
                      agreementData={agreementData}
                      loanRequest={loanRequest}
                    />
                  </IsTruthy>
                  <IsTruthy value={pageNumber === 7}>
                    <CTAform documentId={documentId} policyId={policyId} />
                  </IsTruthy>
                  <ModalControl
                    pageNumber={pageNumber}
                    nextPage={nextPage}
                    onSubmit={onSubmit}
                    setPageNumber={setPageNumber}
                    page1error={page1error}
                    page3error={page3error}
                    agreementData={agreementData}
                    setError={setError}
                    error={error}
                    page2error={page2error}
                    shouldDisableSubmit={shouldDisableSubmit}
                    setPage3Error={setPage3Error}
                    showLoadingIcon={showLoadingIcon}
                    isAddressOnFile={isAddressOnFile}
                  />
                </ModalProvider>
              </Form>
            </div>
          </IsTruthy>
        </IsTruthy>
      </LoadingSection>
    </LoadingSection>
  );
};
export default React.memo(InitiateNewLoan);
