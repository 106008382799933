import React from "react";
import RadioForm from "../../../common/forms/radioForm";
import IsFalsy from "../../../common/logic/isFalsy";
import IsTruthy from "../../../common/logic/isTruthy";
import { useDecedentQuestionRadioButtonProps } from "../../hooks";

const DecedentInformationAnswers = (props) => {
  const {
    formData,
    questionNumber,
    isYesorNoAnswers = false,
    yesFormField,
    noFormField,
  } = props;

  return (
    <>
      <IsTruthy value={isYesorNoAnswers}>
        <div className="row" id="decedentInfo_Answers_Container">
          <div className="col col-md-2" id={`${yesFormField?.id}Answer`}>
            <RadioForm
              key={yesFormField?.id}
              formField={yesFormField}
              useHook={useDecedentQuestionRadioButtonProps}
            />
          </div>
          <div className="col col-md-2" id={`${noFormField?.id}Answer`}>
            <RadioForm
              key={noFormField?.id}
              formField={noFormField}
              useHook={useDecedentQuestionRadioButtonProps}
            />
          </div>
        </div>
      </IsTruthy>
      <IsFalsy value={isYesorNoAnswers}>
        <div className="row" id="decedentInfo_Answers_Container">
          {Object.values(formData).map((decedentInfoAnswer) => {
            if (
              decedentInfoAnswer.groupName ===
              `decedentInfoQuestion${questionNumber}Radio`
            ) {
              return (
                <div
                  className="col col-md-2"
                  id={`${decedentInfoAnswer.id}Answer`}
                  key={decedentInfoAnswer.id}
                >
                  <RadioForm
                    key={decedentInfoAnswer.id}
                    formField={decedentInfoAnswer}
                    useHook={useDecedentQuestionRadioButtonProps}
                  />
                </div>
              );
            }
            return null;
          })}
        </div>
      </IsFalsy>
    </>
  );
};

export default React.memo(DecedentInformationAnswers);
