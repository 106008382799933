import { addTimeToDate, formatDate, createDateObject } from "../../utils/dates";
import { compareStrings } from "../../utils/string";

export const getGraceDays = (props) => {
  const { jurisdictionState, lineOfBusinessCode } = props;
  const hideAdditionaPaymentColumn =
    compareStrings(jurisdictionState, "CA") &&
    compareStrings(lineOfBusinessCode, "LIFE");

  if (
    hideAdditionaPaymentColumn &&
    compareStrings(lineOfBusinessCode, "LIFE")
  ) {
    return {
      gracePeriodDays: 60,
      additionalPaymentPeriodDays: 0,
      hideAdditionaPaymentColumn,
    };
  }
  return {
    gracePeriodDays: 31,
    additionalPaymentPeriodDays: 62,
    hideAdditionaPaymentColumn,
  };
};

export const getOptionArrangementType = (props) => {
  const {
    sdoMpoApoCd,
    sdoMpoApoPaymentForm,
    optionArrangementTypeOption,
    optionArrangementTypeElection,
  } = props;

  const sdoMpoApoCdExceptions = ["SDO-B", "SDO-P"];
  if (sdoMpoApoCdExceptions.includes(sdoMpoApoCd)) {
    if (
      compareStrings(optionArrangementTypeOption, "None") &&
      compareStrings(optionArrangementTypeElection, "None")
    ) {
      return "None";
    }
    return `${optionArrangementTypeOption} ${optionArrangementTypeElection}`;
  }

  const key = `${sdoMpoApoCd}-${sdoMpoApoPaymentForm}`;
  const optionArrangementTypes = {
    "APO-F-FULL": "FULL",
    "APO-P-PARTIAL": "PARTIAL",
    "MPO-A-LEV": "TARGET/LEVEL",
    "MPO-A-REG": "STANDARD",
    "MPO-A-VAR": "VARY PAY",
    "MPO-F-REG": "STANDARD",
    "MPO-F-LEV": "TARGET/LEVEL",
    "MPO-F-FULL": "FULL",
    "MPO-P-REG": "STANDARD",
    "MPO-P-PARTIAL": "PARTIAL",
    "MPO-P-LEV": "TARGET/LEVEL",
    "MPO-P-VAR": "VARY PAY",
    "MPO-W-VAR": "VARY PAY",
    "MPO-W-REG": "STANDARD",
    "SDO-F-REG": "NONE",
    "SDO-L-REG": "NONE",
    "SDO-T-REG": "NONE",
  };

  return optionArrangementTypes[key] || "None";
};

export const getPaymentDates = (props) => {
  const {
    paidToDateString,
    issueDate,
    jurisdictionState,
    billingMode,
    lineOfBusinessCode,
  } = props;
  if (compareStrings(billingMode, "PDU")) {
    return {
      paidToDate: "None",
      gracePeriod: "None",
      additionalPaymentPeriod: "None",
      hideAdditionaPaymentColumn: false,
    };
  }
  const {
    gracePeriodDays,
    additionalPaymentPeriodDays,
    hideAdditionaPaymentColumn,
  } = getGraceDays({
    issueDate,
    jurisdictionState,
    lineOfBusinessCode,
  });
  const paidToDate = formatDate(paidToDateString, "", "Unavailable");
  const paidToDateUnformatted = createDateObject(paidToDate);
  const gracePeriodUnformatted = addTimeToDate({
    date: paidToDateUnformatted,
    duration: { days: gracePeriodDays },
  });
  const additionalPaymentPeriodUnformatted = addTimeToDate({
    date: paidToDateUnformatted,
    duration: { days: additionalPaymentPeriodDays },
  });
  const gracePeriod = formatDate(
    gracePeriodUnformatted.toString(),
    "",
    "Unavailable"
  );
  const gracePeriodFormatted = compareStrings(gracePeriod, "Unavailable")
    ? gracePeriod
    : `${gracePeriod}, 4 PM ET`;
  const additionalPaymentPeriod = formatDate(
    additionalPaymentPeriodUnformatted.toString(),
    "",
    "Unavailable"
  );
  const additionalPaymentPeriodFormatted = compareStrings(
    additionalPaymentPeriod,
    "Unavailable"
  )
    ? additionalPaymentPeriod
    : `${additionalPaymentPeriod}, 4 PM ET`;

  return {
    paidToDate,
    gracePeriod: gracePeriodFormatted,
    additionalPaymentPeriod: additionalPaymentPeriodFormatted,
    hideAdditionaPaymentColumn,
  };
};
