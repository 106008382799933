// eslint-disable-next-line import/prefer-default-export
export const processProducersData = (props) => {
  const apiData = props?.response?.data;

  const producers = apiData?.producers || [];

  return {
    producers,
  };
};
