import React from "react";
import Switch from "./checkbox";
import Label from "./label";

const SwitchForm = (props) => {
  const { formField, useHook } = props;

  return (
    <div className="form-group custom-control custom-switch">
      <Switch formField={formField} useHook={useHook} />
      <Label className="custom-control-label" formField={formField} />
    </div>
  );
};

export default React.memo(SwitchForm);
